import {createContext, PropsWithChildren, useEffect, useMemo, useState} from "react";
import {HardwareGroupWithContent, HardwareItem, HwOpening} from "../../../../api/Hardware";
import {useReload} from "./ReloadContext";
import {useSyncedRef} from "../../../../misc/SyncedRef";

export type EditHardwareItem = Omit<HardwareItem, "id">;

export type EditHardwareGroupWithContent = Omit<HardwareGroupWithContent, "hardware"|"openings"|"name"> & {
    name?: string;
    openings: (Partial<HwOpening> & {id: number; name: string;})[];
    hardware: EditHardwareItem[];
    archived?: boolean;
}

export const HwEditingContext = createContext({
    creating: null as null | EditHardwareGroupWithContent,
    editing: null as null | EditHardwareGroupWithContent,

    onCreate: (hw: EditHardwareGroupWithContent): void => {throw new Error("no provider")},
    onEdit: (hw: EditHardwareGroupWithContent, opts?: {
        autoScrollEl?: HTMLElement | null
    }): void => {throw new Error("no provider")},
    onUpdate: (opts: {
        autoScrollEl?: HTMLElement | null
    }): void => {throw new Error("no provider")},
    onDone: (): void => {throw new Error("no provider")}
});

export function HwEditingContextProvider(props: PropsWithChildren<{
    onLockScroll(tf: boolean): void;
    onAutoScroll(el: HTMLElement | null): void;
}>) {
    const [creating, setCreating] = useState<EditHardwareGroupWithContent|null>(null)
    const [editing, setEditing] = useState<EditHardwareGroupWithContent|null>(null);

    useReload(() => {
        setEditing(null);
        setCreating(null)
    }, []);

    const active = !!creating || !!editing;
    const onLockScroll = props.onLockScroll;
    useEffect(() => {
        onLockScroll(active)
    }, [onLockScroll, active])

    const onAutoScrollRef = useSyncedRef(props.onAutoScroll)

    const ctx = useMemo(() => {
        return {
            creating: creating,
            editing: editing,
            onCreate: setCreating,
            onEdit: async (hw: EditHardwareGroupWithContent, opts?: {
                autoScrollEl?: HTMLElement | null
            }) => {
                setEditing(hw);

                if(opts?.autoScrollEl) {
                    onAutoScrollRef.current(opts.autoScrollEl);
                }
            },
            onUpdate: (opts: {
                autoScrollEl?: HTMLElement | null
            }) => {
                if(opts?.autoScrollEl) {
                    onAutoScrollRef.current(opts.autoScrollEl);
                }
            },
            onDone: () => {
                setEditing(null);
                setCreating(null);
            }
        }
    }, [creating, editing, onAutoScrollRef]);

    return (
        <HwEditingContext.Provider value={ctx}>
            {props.children}
        </HwEditingContext.Provider>
    )
}
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export function SelectFiltered(props: {
    label: string;
    value: string;
    onChange(value: string): void;
    options: (Opt | null)[];
}) {


    return (
        <FormControl size="small" fullWidth>
            <InputLabel>{props.label}</InputLabel>
            <Select value={props.value} label={props.label} size="small" fullWidth onChange={(e, c) => props.onChange(e.target.value)}>
                {[{ value: "", display: "(none)" }, ...props.options].map(o => {
                    if(!o) return null;
                    return <MenuItem value={o.value}>{o.display}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

type Opt = {
    value: string
    display: string;
}
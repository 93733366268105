import {Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import React from "react";

export function TabSection(props: {
    value: string;
    onChange(value: string): void;
    right?: any;
}) {
    const tab = props.value;
    const setTab = props.onChange;
    return (
        <TabWrapper>
            <Tabs value={tab} onChange={(_, e) => setTab(e)}>
                <Tab value="products" label="Products" />
                <Tab value="manufacturers" label="Manufacturers" />
                <Tab value="catalog" label="Catalogs" />
            </Tabs>
            <div style={{flex: 1}} />
            {props.right}
            <div style={{width: 4}} />
        </TabWrapper>
    )
}
import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";
import {Div10Row} from "./QuoteDiv10";
import {ProductReplacement} from "./QuoteHardware";

export class Div10 extends SubAPI {
    replaceProducts(input: {
        project: number;
        replacements: ProductReplacement[]
    }) {
        return this.fetcher.post("/api/div-10/replace-product", input)
    }

    list(props: {
        project: number,
        tab?: string,
        offset: number,
        previewChangeset?: number
    }) {
        return this.fetcher.get<Paginated<Div10Row>>("/api/div-10", props);
    }

    summary(props: {project: number,}) {
        return this.fetcher.get<{category: string; qty: number}[]>("/api/div-10/summary", props)
    }

    upsert(props: Partial<Div10Row>) {
        return this.fetcher.post<{id: number}>("/api/div-10", props);
    }

    reOrder(props: {
        project: number;
        idOrder: number[]
        tab: string;
    }) {
        return this.fetcher.post<{id: number}>("/api/div-10/re-order", props);
    }
}


type VariableLookupTable = {[k: string]: number};
const sampleEvalTable = {
    "door.width": 1,
    "door.height": 1,
    "door.thickness": 1,
    "frame.width": 1,
    "frame.height": 1,
    "inactive.width": 1,
    "jamb.depth": 1,
};

export class Dimension {
    group: Group;

    constructor(group: Group) {
        this.group = group;
    }

    eval(lookupTable: VariableLookupTable) {
        return this.group.calculate(lookupTable)
    }

    static validate(input: string) {
        Dimension.parse(input).eval(sampleEvalTable)
    }

    static parse(input: string) {
        let parts = split(input);
        parts = sanitizeFractions(parts)

        const rootGroup = new Group();
        let currentGroup = rootGroup;
        let start = 0;

        function cleanupResidue(end: number) {
            if(start > end) return;
            let sub = parts.slice(start, end+1);

            if(!isNumeric(sub[0])) {
                if(sub.length > 1) throw new Error("invalid: " + sub.join(" "));
                currentGroup.children.push(new Variable(sub[0]));
                return;
            }

            if(sub.length === 1) {
                currentGroup.children.push(new Constant().fromValue(sub[0]))
                return;
            }

            if(sub.length === 2) {
                switch(sub[1]) {
                    case "'":
                        currentGroup.children.push(new Constant().fromFtAndInches(sub[0], "0"))
                        break;
                    case "\"":
                        currentGroup.children.push(new Constant().fromValue(sub[0]))
                        break;
                    case "mm":
                        currentGroup.children.push(new Constant().fromMM(sub[0]))
                        break;
                    default:
                        throw new Error("invalid units: " + sub.join(" "));
                }

                return;
            }

            if(sub.length === 3) {
                throw new Error("missing inch units: " + sub.join(" "));
            }

            if(sub.length === 4) {
                if(sub[3] !== "\"") throw new Error("invalid units: " + sub.join(" "));
                currentGroup.children.push(new Constant().fromFtAndInches(sub[0], sub[2]))
                return;
            }

            throw new Error("invalid number: " + sub.join(" "));
        }

        for(let i = 0; i < parts.length; i++) {
            switch(parts[i]) {
                case "(":
                    cleanupResidue(i-1);
                    const grp = new Group(currentGroup);
                    currentGroup.children.push(grp);
                    currentGroup = grp;
                    start = i+1;
                    continue;
                case ")":
                    if(!currentGroup.parent) throw new Error("unmatched ')'");
                    cleanupResidue(i-1);
                    currentGroup = currentGroup.parent
                    start = i+1;
                    continue;
                case "*":
                case "/":
                case "+":
                case "-":
                    cleanupResidue(i-1);
                    currentGroup.children.push(new Operator(parts[i]));
                    start = i+1;
                    continue;
            }

            if(i+1 === parts.length) {
                cleanupResidue(i);
            }
        }

        if(currentGroup !== rootGroup) {
            if(!currentGroup.parent) throw new Error("missing a closing parentheses ')'");
        }

        rootGroup.nestBedmas();

        return new Dimension(rootGroup);
    }
}

function sanitizeFractions(input: string[]) {
    let list: string[] = [];

    for(let i = 0; i < input.length; i++) {
        // #' #/# => #' + #/#
        let match = i+4 < input.length && isNumeric(input[i]) &&
            input[i+1] === "'" &&
            isNumeric(input[i+2]) &&
            input[i+3] === "/" &&
            isNumeric(input[i+4])

        if(match) {
            list.push(input[i])
            list.push(input[i+1])
            list.push("+")
            list.push(input[i+2]);
            list.push(input[i+3])
            list.push(input[i+4])
            i = i + 4;
            continue
        }

        // # #/#" => # + #/#
        match = i+3 < input.length && isNumeric(input[i]) &&
            isNumeric(input[i+1]) &&
            input[i+2] === "/" &&
            isNumeric(input[i+3])

        if(match) {
            list.push(input[i])
            list.push("+")
            list.push(input[i+1])
            list.push(input[i+2])
            list.push(input[i+3])
            i = i + 3;
            continue;
        }

        list.push(input[i]);
    }

    return list
}

function isNumeric(input: string) {
    return !!input.match(/^[0-9]+/)
}

class Constant {
    value: number

    constructor() {
        this.value = NaN;
    }

    fromValue(value: string) {
        this.value = parseFloat(value)
        return this;
    }

    fromMM(mm: string) {
        this.value = parseFloat(mm);
        return this;
    }

    fromFtAndInches(ft: string, inches: string) {
        this.value = parseFloat(ft) * 12 + parseFloat(inches);
        return this;
    }
}

class Operator {
    value: string;

    constructor(value: string) {
        switch (value) {
            case "*":
            case "/":
            case "+":
            case "-":
                break;
            default:
                throw new Error("invalid operator");
        }

        this.value = value;
    }

    isMulOrDiv() {
        switch(this.value) {
            case "*":
            case "/":
                return true;
        }

        return false;
    }

    apply(a: number, b: number) {
        switch (this.value) {
            case "*": return a * b;
            case "/": return a / b;
            case "+": return a + b;
            case "-": return a - b;
            default: throw new Error("invalid operator");
        }
    }
}

class Variable {
    value: string;

    constructor(value: string) {
        this.value = value;
    }

    lookup(input: VariableLookupTable) {
        if(!input.hasOwnProperty(this.value)) {
            throw new Error("invalid lookup property '" + this.value + "'");
        }

        return input[this.value];
    }
}

type GroupChild = Operator|Constant|Variable|Group;

class Group {
    parent?: Group
    children: GroupChild[];

    constructor(parent?: Group) {
        this.parent = parent;
        this.children = [];
    }

    nestBedmas() {
        const list = this.children;
        if(list.length <= 3) return;

        let newList: GroupChild[] = [];

        for(let i = 1; i < list.length; i++) {
            const cur = list[i];

            if(cur instanceof Operator && cur.isMulOrDiv()) {
                const grp = new Group(this);
                grp.children = [list[i-1], cur, list[i+1]];
                newList.pop();
                newList.push(grp);
                i++;
                continue
            }

            newList.push(cur);
        }

        this.children = newList;

        this.children.map(c => {
            if(c instanceof Group) {
                c.nestBedmas();
            }
        });
    }

    calculate(input: VariableLookupTable) {
        const list = this.children;
        let value = 0;

        let op: Operator | undefined = undefined;

        for(let i = 0; i < list.length; i++) {
            const cur = list[i];

            if(cur instanceof Operator) {
                if(op) throw new Error("can't have two operators (+,-,*,/) in a row")
                op = cur;
            } else if(cur instanceof Group) {
                if(op) {
                    value = op.apply(value, cur.calculate(input));
                    op = undefined;
                } else {
                    value = cur.calculate(input)
                }
            } else if(cur instanceof Constant) {
                if(op) {
                    value = op.apply(value, cur.value);
                    op = undefined;
                } else {
                    value = cur.value;
                }
            } else if(cur instanceof Variable) {
                if(op) {
                    value = op.apply(value, cur.lookup(input));
                    op = undefined;
                } else {
                    value = cur.lookup(input);
                }
            } else {
                throw new Error("internal error");
            }
        }

        if(op) throw new Error("invalid operator placement: '" + op.value + "'")

        return value;
    }
}

function split(input: string) {
    let parts: string[] = [];
    let start = 0;

    const pushRemainder = function(end: number) {
        if(start > end) return;
        let slice = input.slice(start, end+1);
        if(slice.trim() === "") return;

        parts.push(slice.trim());
    }

    for(let i = 0; i < input.length; i++) {
        if(start > i) continue;

        switch (input[i]) {
            case "(":
            case ")":
            case "*":
            case "+":
            case "-":
            case "/":
            case "\"":
            case "mm":
            case "'":
                pushRemainder(i-1);
                parts.push(input[i]);
                start = i+1;
                break;
            case " ":
                pushRemainder(i-1);
                start = i+1;
                break;
        }

        if(i + 1 === input.length) {
            pushRemainder(i);
        }
    }

    return parts;
}
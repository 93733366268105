import {css} from "@emotion/css";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import Clear from "@mui/icons-material/Clear"


const searchStyle = css({
    "& .MuiOutlinedInput-root": {
        paddingRight: 0,
    },

    "& input[type=text]": {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 8,
    },
    "& fieldset": {
        paddingRight: 0,
    }
})

export function SmallSearchBox(props: {
    value: string;
    onChange(value: string): void;
    fullWidth?: boolean
}) {
    return (
        <TextField variant="outlined" size="small" placeholder="Search" value={props.value} onChange={e => {
            props.onChange(e.target.value);
        }}
                   fullWidth={props.fullWidth}
                   classes={{
            root: searchStyle
        }} InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton size="small" onClick={() => props.onChange("")}>
                    <Clear />
                </IconButton>
            </InputAdornment>
        }} />
    )
}
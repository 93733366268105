import {useMemo, useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {orderByAscending, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import useWindowSize from "react-use/lib/useWindowSize";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    List,
    ListItemButton, ListItemText, MenuItem, Select,
    TextField
} from "@mui/material";
import {FileItemSelection} from "./ProductCatalogLookup";
import {useFilePicker} from "./FilePicker";
import {grey} from "@mui/material/colors";
import {api} from "../../../../api/API";
import {getAll} from "../openings/Openings";

export function FileLookup(props: {
    title: string;
    manufacturer?: number;
    category?: number;
    uploadFx(input: {
        file: File;
        manufacturer: number
    }): Promise<FileItemSelection>;
    list(input: {
        manufacturer?: number;
        category?: number;
    }): Promise<FileItemSelection[]>;
    fileLink(id: number): string
    onDone(value: FileItemSelection | null): Promise<void>;
    value: FileItemSelection | null;
    placeholder: string;
}) {
    const [category, setCategory] = useState<number | undefined>(props.category);

    const filePicker = useFilePicker({
        accept: ["application/pdf"],
    });
    const uploadFx = useSyncedRef(props.uploadFx);

    const action = useAsyncAction(async (file) => {
        if(!props.manufacturer) throw new Error("Fill out manufacturer first");
        const catalog = await uploadFx.current({
            file: file,
            manufacturer: props.manufacturer,
        });

        setSelected(catalog);
        setPages("")
        fileList.reload()
    }, [filePicker, props.manufacturer]);

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction((input) => onDoneRef.current(input), []);
    const [pages, setPages] = useState(props.value?.pages || "");

    const size = useWindowSize();

    const fileList = useAsync2(input => {
        return props.list(input)
    }, {
        manufacturer: props.manufacturer,
        category: category,
    }, [props.manufacturer, category])

    const [selected, setSelected] = useState<FileItemSelection | null>(props.value);

    const categories = useAsync2(async (input) => {
        return await getAll(null, offset => api.products.categories({offset: offset, kind: "hardware"}))
    }, {}, []);

    const categoryRawList = categories.asList;
    const categoryList = useMemo(() => {
        if(!categoryRawList) return [];

        const roots = categoryRawList.filter(v => !v.parentCategory);
        orderByAscending(roots, r => r.sortOrder || 0)

        const selected = categoryRawList.find(v => v.id === props.category);
        if(selected) {
            let cursor = selected;
            while(cursor.parentCategory) {
                cursor = categoryRawList.find(v => v.id === cursor.parentCategory) || cursor;
            }

            setCategory(cursor.id)
        }

        return roots;
    }, [props.category, categoryRawList])

    return (
        <Dialog open fullWidth maxWidth="lg">
            <form onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                update.callback(Object.assign({}, selected, {
                    pages: pages,
                }));
            }}>
                <DialogTitle>{props.title}</DialogTitle>
                <div style={{width: "100%", height: size.height * 0.75, borderTop: "1px solid " + grey["300"]}}>
                    <Grid container spacing={1} style={{height: "100%"}}>
                        <Grid item xs={3} style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            {fileList.LoadingOrErrorElement}
                            <List dense style={{flex: 1, overflow: "auto"}}>
                                {props.category && categoryList.length > 0 && <Select fullWidth value={category?.toString()}>
                                    {categoryList.map(c =>
                                        <MenuItem key={c.id.toString()} value={c.id.toString()} onClick={() => {
                                            setCategory(c.id)
                                        }}>{c.name}</MenuItem>
                                    )}
                                </Select>}
                                {fileList.asList.map(r =>
                                    <ListItemButton style={{borderBottom: "1px solid " + grey["200"]}} selected={r.id === selected?.id} onClick={e => setSelected(r)}>
                                        <ListItemText primary={r.name} />
                                    </ListItemButton>
                                )}
                            </List>
                            <div style={{
                                paddingLeft: 16,
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <TextField label="Pages"
                                           placeholder="1-5"
                                           helperText={"Use range (e.g. 1-5) or blank to include entire document"}
                                           value={pages}
                                           onChange={e => setPages(e.target.value)}
                                           size="small"
                                />
                                <div style={{width: 16}} />
                                {action.LoadingElement}
                                <Button onClick={async () => {
                                    if(!props.manufacturer) return;
                                    const files = await filePicker();
                                    action.callback(files[0])
                                }}>
                                    Upload File
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={9} style={{
                            display: "flex",
                            height: "100%"
                        }}>
                            {selected ? <iframe
                                src={props.fileLink(selected.id)}
                                style={{
                                    flex: 1,
                                }}
                            ></iframe> : <div style={{
                                backgroundColor: grey["100"],
                                flex: 1,
                                display: "flex", alignItems: "center", justifyContent: "center"
                            }}>
                                <div>
                                    Choose a {props.placeholder} on the right
                                </div>
                            </div>}
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    {update.LoadingElement}
                    <Button onClick={e => props.onDone(props.value as any)}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
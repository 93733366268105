import {ImperialParser} from "./ImperialParser";
import {MetricDistance} from "./MetricDistance";

const precision = 64;

export class ImperialDistance {
    sixtyFourthsOfInch: number;

    // see dim/main.go
    static unitsPerInch = 254 * 64;

    constructor(sixtyFourths: number) {
        this.sixtyFourthsOfInch = Math.round(sixtyFourths);
    }

    simplifySixtyFourths(n: number): string {
        const value = Math.abs(n);
        if(value === 0) return "";
        if(value >= 64) throw new Error("value must be less than 64");

        const div = this.gcd(value, 64);
        return value / div + "/" + (64 / div)
    }

    // gcd get's the greatest common divisor of the two numbers
    gcd(a: number, b: number): number {
        if(a === 0 || b === 0) throw new Error("can't find GCD of zero");
        if(a === b) return a;
        if(b > a) return this.gcd(b, a);

        let total = a;
        let mul = b;

        for(let k = 0; k < a; k++) {
            const remainder = this.gcdGetRemainder(total, mul);
            if(remainder === 0) return mul;

            total = mul;
            mul = remainder;
        }

        throw new Error("unable to calculate gcd")
    }

    gcdGetRemainder(total: number, mul: number): number {
        while(total >= mul) {
            total -= mul;
        }

        return total;
    }

    toString(): string {
        let parts: string[] = [];

        const base = Math.abs(this.sixtyFourthsOfInch);
        const isNeg = this.sixtyFourthsOfInch < 0;

        let totalInches = Math.floor(base / precision);
        const sub = Math.round((base - totalInches * precision) / precision * 64);
        const subStr = this.simplifySixtyFourths(sub);

        const inches = totalInches % 12;
        if (subStr === "") {
            parts.push(inches.toString());
        } else {
            parts.push(inches + " " + subStr);
        }

        totalInches -= inches;
        const ft = Math.floor(totalInches / 12);
        parts.push(ft + "'");

        return (isNeg ? "-" : "") + parts.reverse().join("");
    }

    toInchesString() {
        let parts: string[] = [];

        const base = Math.abs(this.sixtyFourthsOfInch);
        const isNeg = this.sixtyFourthsOfInch < 0;

        let totalInches = Math.floor(base / precision);
        const sub = Math.round((base - totalInches * precision));
        const subStr = this.simplifySixtyFourths(sub);

        const inches = totalInches;
        if (inches !== 0) {
            if (subStr === "") {
                parts.push(inches + '"');
            } else {
                parts.push(inches + " " + subStr + '"');
            }
        } else {
            if (subStr !== "") {
                parts.push(subStr + '"');
            }
        }

        return (isNeg ? "-" : "") + parts.reverse().join(" ");
    }

    universalValue(): number {
        return this.sixtyFourthsOfInch / precision * ImperialDistance.unitsPerInch
    }

    static fromUniversal(n: number) {
        const v = n / ImperialDistance.unitsPerInch * precision
        return new ImperialDistance(v);
    }

    inches() {
        return Math.floor(this.sixtyFourthsOfInch / precision);
    }

    rounded() {
        return new ImperialDistance(Math.round(this.sixtyFourthsOfInch / ImperialDistance.unitsPerInch) * ImperialDistance.unitsPerInch);
    }

    getValue(): number {
        return this.sixtyFourthsOfInch;
    }

    static fromFeetAndInches(input: { feet?: number; inches?: number }) {
        return new ImperialDistance(
            (input.feet || 0) * 12 * precision + (input.inches || 0) * precision
        );
    }

    static parse(value: string): ImperialDistance {
        const parsed = ImperialParser.parse(value);
        return ImperialDistance.fromFeetAndInches(parsed);
    }

    add(other: MetricDistance | ImperialDistance) {
        return ImperialDistance.fromUniversal(
            this.universalValue() + other.universalValue()
        );
    }

    static show(units: UniversalUnits, options?: { rounded: boolean }): string {
        let value = ImperialDistance.fromUniversal(units);

        if (options?.rounded) {
            value = value.rounded();
        }

        return value.toString();
    }

    static showInches(units: UniversalUnits): string {
        const len = ImperialDistance.fromUniversal(units);

        if (units === 0) return '0"';

        let parts: string[] = [];
        let inches = len.inches();
        if (inches !== 0) {
            parts.push(inches.toString());
        }

        let sub = len.sixtyFourthsOfInch % precision;
        if (sub > 0) {
            parts.push(len.simplifySixtyFourths(sub));
        }

        return parts.join(" ") + '"';
    }
}

type UniversalUnits = number;
import {Button, Popover} from "@mui/material";
import React from "react";
import {EditHardwareGroupWithContent, EditHardwareItem} from "../HwEditingContextProvider";
import {red} from "@mui/material/colors";
import WarningIcon from '@mui/icons-material/Warning';
import {HingeAutoDimensionDisplay} from "./HingeAutoDimensionDisplay";

export function AutoForHingeCell(props: {
    group?: EditHardwareGroupWithContent;
    value?: EditHardwareItem;
    part?: "active" | "inactive"
    onOverride?(value: number | null): Promise<void>
}) {
    const [invalidOpeningEl, setInvalidOpeningEl] = React.useState<HTMLElement|null>(null);

    const qtys = props.part === "active" ? props.value?.hingeActiveQtys : props.value?.hingeInactiveQtys;
    const overrideQty = props.part === "active" ? props.value?.overrideHingeActiveQty : props.value?.overrideHingeInActiveQty;
    const prevOverrideQty = props.part === "active" ? props.value?.backup?.overrideHingeActiveQty : props.value?.backup?.overrideHingeInActiveQty;
    const changed = prevOverrideQty !== undefined && overrideQty !== prevOverrideQty;

    const invalidOpenings = props.group?.openings.filter(o => {
        return o.nominalHeight === ""
    }) || [];

    if(invalidOpenings.length > 0) {
        return (
            <td style={{textAlign: "center"}}>
                <div style={{fontSize: "0.8rem", height: "1em", overflow: "hidden", color: red["800"]}} onClick={e => setInvalidOpeningEl(e.currentTarget)}>
                    <WarningIcon fontSize="inherit" />
                </div>
                {invalidOpeningEl && <Popover open={true} anchorEl={invalidOpeningEl} onClose={() => setInvalidOpeningEl(null)}>
                    <div style={{width: 250}}>
                        <div style={{padding: 15}}>
                            The following openings need a height specified for hinge auto-dimensioning
                        </div>

                        {invalidOpenings.map(o => (
                            <div style={{paddingLeft: 18, paddingRight: 18, paddingTop: 6, paddingBottom: 6}}>{o.name || "(blank)"}</div>
                        ))}
                    </div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button size="small" color="primary" onClick={() => setInvalidOpeningEl(null)}>Close</Button>
                    </div>
                </Popover>}
            </td>
        )
    }

    if(props.part === "inactive" && qtys?.length === 0) {
        return (
            <td style={{textAlign: "right", backgroundColor: changed ? "white" : undefined}}>
                <HingeAutoDimensionDisplay qtys={[0]} overrideQty={coalesce(overrideQty, null)} onOverride={props.onOverride} />
            </td>
        )
    }

    // no openings
    if(!props.value || !props.group || props.group.openings.length === 0 || !qtys || qtys.length === 0) {

        return (
            <td style={{textAlign: "right", backgroundColor: changed ? "white" : undefined}}>
                <HingeAutoDimensionDisplay qtys={[]} overrideQty={coalesce(overrideQty, null)} onOverride={props.onOverride} />
            </td>
        )
    }

    return (
        <td style={{textAlign: "right", backgroundColor: changed ? "white" : undefined}}>
            <HingeAutoDimensionDisplay qtys={qtys} overrideQty={coalesce(overrideQty, null)} onOverride={props.onOverride} />
        </td>
    )
}

function coalesce(value: number | null | undefined, fallback: number | null) {
    return value === null || value === undefined ? fallback : value;
}
import {Opening} from "../../../../api/Openings";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {AutoComplete} from "./AutoComplete";
import {api} from "../../../../api/API";
import {HardwareGroup} from "../../../../api/QuoteHardware";
import {useAlternative} from "../../quote/alternative/Alternative";

export function EditHardwareGroup(props: {
    isQuote: true;
    row: QuoteOpening;
    onCancel(): void;
    onDone(value: QuoteOpening): Promise<any>;
} | {
    isQuote: false;
    row: Opening;
    onCancel(): void;
    onDone(value: Opening): Promise<any>;
}) {
    const doneRef = useSyncedRef(props.onDone);
    const callback = useAsyncAction(input => doneRef.current(input), [doneRef]);

    const [group, setGroup] = useState({
        id: props.row.hardwareGroup as number,
        name: props.row.hardwareGroupName as string,
    });

    return (
        <Dialog open={true} onClose={props.onCancel}>
            <DialogTitle>
                Edit Hardware Heading
            </DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                        <HardwareGroupAC label="Group" value={group}
                                         onChange={setGroup} project={props.row.project} isQuote={props.isQuote} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {callback.LoadingElement}
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button onClick={() => callback.callback(Object.assign({}, props.row, {
                    hardwareGroup: group.id,
                    hardwareGroupName: group.name,
                }))}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

function HardwareGroupAC(props: {
    label: string;
    value: any;
    project: number;
    isQuote: boolean;
    onChange(value: HardwareGroup): void;
}) {
    const alternative = useAlternative();

    return (
        <AutoComplete<HardwareGroup>
            label={props.label}
            value={props.value}
            onSelect={props.onChange}
            equals={(a, b) => a.id === b.id}
            makeLabel={a => a.name}
            search={async search => {
                const before = search === "" ? [{name: "(none)", id: null} as any] : [];

                if(props.isQuote) {
                    const rs = await api.quoteHardware.list({
                        project: props.project,
                        alternative: alternative,
                        search: search
                    })
                    return [...before, ...rs.data];
                }

                const rs = await api.hardware.list({project: props.project, search: search})
                return [...before, ...rs.data];
            }}
            fullWidth
        />
    )
}
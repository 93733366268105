import {forwardRef} from "react";
import {useUser} from "../../../../../misc/Permission";
import {Project} from "../../../../../api/Projects";
import {Company} from "../../../../../api/Companies";
import {PDF} from "../../../../../misc/pdf/parts/PDF";
import {PDFProjectContext} from "../../openings/OpeningsPDF";
import {KeyingSchedule} from "./KeyingSchedule";
import {KeyName, KeyOpening} from "../../../../../api/Keying";
import {Legend} from "./Legend";
import {KeyingPDFInfo} from "./KeyingSubmittal";
import {useReadyCheck} from "../../../../../misc/pdf/UseReadyCheck";
import {CoverPage} from "../../../../../misc/pdf/parts/CoverPage";

export const KeyingPDF = forwardRef(function (props: {
    project: Project;
    lastSubmittal: string|null;
    client?: Company;
    rows: KeyOpening[];
    names: KeyName[];
    info: KeyingPDFInfo
    onReady(): void;
}, ref: any) {
    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    return (
        <div ref={ref}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <CoverPage title="KEYING SCHEDULE SUBMITTAL" client={props.client} lastSubmittal={props.lastSubmittal} />
                    <Legend info={props.info} names={props.names} />
                    <KeyingSchedule rows={props.rows} names={props.names} onReady={ready.section()} />
                </PDFProjectContext.Provider>
            </PDF>
        </div>
    )
})

import {InternalValue, PrepCanvasManager} from "./PrepCanvasManager";
import {useEffect, useMemo, useState} from "react";
import * as PIXI from "pixi.js";
import {Graphics} from "pixi.js";
import {ImperialDistance} from "../../../../../misc/ImperialDistance";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {downloadBlob} from "../../../../logistics/purchasing/csvutil";

export type CanvasRef = {
    exportPNG(): Promise<Blob>;
}

export function makePixiApp(props: {
    width: number;
    height: number;
}) {
    return new PIXI.Application({
        backgroundAlpha: 0,
        backgroundColor: 0xffffff,
        antialias: true,
        preserveDrawingBuffer: true,
        resolution: 2,
        width: props.width,
        height: props.height,
    });
}

export function exportPNG(app: PIXI.Application, part: "frame" | "door" | "both" = "both") {
    return new Promise<Blob>((resolve, reject) => {
        try {
            const rect = part === "both" ? new PIXI.Rectangle(0, 0, app.renderer.screen.width, app.renderer.screen.height) :
                part === "frame" ? new PIXI.Rectangle(0, 0, app.renderer.screen.width/2, app.renderer.screen.height) :
                    new PIXI.Rectangle(app.renderer.screen.width/2, 0, app.renderer.screen.width/2, app.renderer.screen.height);

            const canvas = part === "both" ? app.renderer.extract.canvas() : app.renderer.extract.canvas(undefined, rect)
            if (!canvas.toBlob) {
                reject(new Error("Failed to export canvas"))
                return;
            }

            canvas.toBlob((value) => {
                if (value) {
                    resolve(value)
                    return;
                }

                reject(new Error("Failed to export canvas"))
            }, "image/png", 1);
        } catch (e) {
            reject(e);
        }
    })
}

function setupApp(element: HTMLElement, app: PIXI.Application, width: number, height: number) {
    element.innerHTML = "";
    element.appendChild(app.view as any);

    // @ts-ignore
    app.view.style.width = width + "px";

    // @ts-ignore
    app.view.style.height = height + "px";
}

export function PrepCanvas(props: {
    manager: PrepCanvasManager
    onDraw?(): void;
    width?: number;
    height?: number;
    canvasRef: {current: CanvasRef|null}
}) {
    const width = props.width || 800;
    const height = props.height || 500;

    const app = useMemo(() => {
        return makePixiApp({
            width, height
        })
    }, [height, width])

    useEffect(() => {
        props.canvasRef.current = {
            exportPNG: () => exportPNG(app)
        }
    }, [app, props.canvasRef])

    useEffect(() => {
        return () => app.destroy(true);
    }, [app])

    const [element, setElement] = useState<HTMLElement|null>(null);
    const onDrawRef = useSyncedRef(props.onDraw);

    useEffect(() => {
        if(!element) return;

        setupApp(element, app, width, height)

        draw(app, props.manager);
        const sub = props.manager.afterCalculate.subscribe(() => draw(app, props.manager));

        if(onDrawRef.current) onDrawRef.current();

        return () => {
            sub.cancel();
        }
    }, [element, app, props.manager, onDrawRef, height, width])

    return (
        <div style={{height: height, width: width}} ref={setElement}>Loading...</div>
    )
}

const resolution = 2;

function drawDoor(g: PIXI.Graphics, height: number, width: number, offsetLeft: number) {

    g.beginFill(frameFill, 0.8)
    g.lineStyle({
        width: 1,
        color: frameOutline,
    })

    g.drawRect(offsetLeft, 0, width, height)
    g.endFill()
}

async function makePrepDiagrams() {
    const canvasWidth = 800;
    const canvasHeight = 500;

    const element = document.createElement("div")
    document.body.appendChild(element);

    const app = makePixiApp({width: canvasWidth, height: canvasHeight});
    setupApp(element, app, canvasWidth, canvasHeight)

    const heights = [
        ImperialDistance.parse("7'4"), // hinge=3
        ImperialDistance.parse("8'0"), // hinge=4
        ImperialDistance.parse("11'0"),// hinge=5
    ]

    const isPairs = [true, false];
    const dbd = [true, false]
    const parts = ["both", "both", "frame", "door"]

    for(let i = 0; i < heights.length; i++) {
        const height = heights[i];

        for(let j = 0; j < isPairs.length; j++) {
            const isPair = isPairs[j];

            for(let k = 0; k < dbd.length; k++) {

                const width = i === 0 ? ImperialDistance.parse("3'0") : ImperialDistance.parse("4'0")

                try {
                    const manager = new PrepCanvasManager({
                        nominalHeight: height.universalValue(),
                        nominalWidth: width.universalValue(),
                        secondaryWidth: isPair ? width.universalValue() : undefined,
                        pair: isPair,
                        hasDeadbolt: dbd[k],
                        overrideHingeQty: height.toString() === "11'0" ? 5 : 0,
                        overrides: undefined,
                    })

                    manager.calculate();

                    for(let l = 0; l < parts.length; l++) {
                        const part = parts[l];
                        const filename = `${isPair ? "pair" : "single"}-h${manager.getHingeQty()}-${part}${dbd[k] ? "-dbd" : ""}.png`

                        draw(app, manager)
                        const blob = await exportPNG(app, part as any)

                        // for some reason the first export always fails...
                        if(l !== 0) {
                            downloadBlob(blob, filename, "image/png")
                        }
                    }

                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    app.destroy(true);
    element.remove();
}

// @ts-ignore
window["zz_makePrepDiagrams"] = makePrepDiagrams

function draw(app: PIXI.Application, manager: PrepCanvasManager) {
    app.stage.removeChild(...app.stage.children)

    console.log("draw", manager);

    const hPad = 20;
    const vPad = 80;
    const scaleValue = putInBox(manager.nominalWidth + manager.secondaryWidth, manager.nominalHeight, app.view.width/2/resolution - hPad*2, app.view.height/resolution - vPad*2)

    const scale = (v: number) => v * scaleValue;

    const doorSeparation = 4;
    const hingeHeight = scale(ImperialDistance.parse("4.5\"").universalValue());
    const strikeHeight = scale(ImperialDistance.parse("4 7/8\"").universalValue());

    const door = new PIXI.Graphics();
    drawDoor(door, scale(manager.nominalHeight), scale(manager.nominalWidth), 0)
    if(manager.pair) {
        drawDoor(door, scale(manager.nominalHeight), scale(manager.secondaryWidth), scale(manager.nominalWidth) + doorSeparation)
    }
    app.stage.addChild(door);
    door.position.set(centerX(door, app.view.width/2/resolution) + app.view.width/2/resolution, vPad)

    const frame = new PIXI.Graphics();
    drawFrame(frame, scale(manager.nominalHeight), scale(manager.nominalWidth + manager.secondaryWidth))
    app.stage.addChild(frame);
    frame.position.set(centerX(frame, app.view.width/2/resolution), vPad)

    if(manager.values.a.enabled) {
        dimension(frame, {
            from: point(frameThickness + 10, frameThickness),
            to: point(frameThickness + 10, scale(manager.values.a.actualValue)),
            src: manager.values.a,
            shiftTextRight: 40
        })
    }

    hingeSilhouette(frame, scale(manager.values.a.actualValue), frameThickness - hingeDepth, hingeHeight)
    hingeSilhouette(door, scale(manager.values.aStar.actualValue), 0, hingeHeight)

    if(manager.pair) {
        hingeSilhouette(frame, scale(manager.values.a.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) - frameThickness, hingeHeight)
        hingeSilhouette(door, scale(manager.values.aStar.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) + doorSeparation-hingeDepth, hingeHeight)
    }

    if(manager.values.aStar.enabled) {
        dimension(door, {
            from: point(-10, 0),
            to: point(-10, scale(manager.values.aStar.actualValue)),
            src: manager.values.aStar,
            shiftTextRight: -15
        })

    }

    if(manager.values.b.enabled) {
        dimension(frame, {
            from: point(frameThickness + 10, scale(manager.values.a.actualValue)),
            to: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue)),
            src: manager.values.b,
            shiftTextRight: 40
        })

        dimension(door, {
            from: point(-10, scale(manager.values.aStar.actualValue)),
            to: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue)),
            src: manager.values.b,
            shiftTextRight: -15
        })
    }

    hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue), frameThickness - hingeDepth, hingeHeight)
    hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue), 0, hingeHeight)
    if(manager.pair) {
        hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) - frameThickness, hingeHeight)
        hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) + doorSeparation-hingeDepth, hingeHeight)
    }

    if(manager.values.c.enabled) {
        dimension(frame, {
            from: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue)),
            to: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue + manager.values.c.actualValue)),
            src: manager.values.c,
            shiftTextRight: 40
        })

        dimension(door, {
            from: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue)),
            to: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue + manager.values.c.actualValue)),
            src: manager.values.c,
            shiftTextRight: -15
        })
    }

    hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue), frameThickness - hingeDepth, hingeHeight)
    hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue + manager.values.c.actualValue), 0, hingeHeight)

    if(manager.pair) {
        hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) - frameThickness, hingeHeight)
        hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) + doorSeparation-hingeDepth, hingeHeight)
    }

    if(manager.values.d.enabled) {
        dimension(frame, {
            from: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue + manager.values.c.actualValue)),
            to: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue + manager.values.c.actualValue+ manager.values.d.actualValue)),
            src: manager.values.d,
            shiftTextRight: 40
        })

        dimension(door, {
            from: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue + manager.values.c.actualValue)),
            to: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue + manager.values.c.actualValue+ manager.values.d.actualValue)),
            src: manager.values.d,
            shiftTextRight: -15
        })

        hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue), frameThickness - hingeDepth, hingeHeight)
        hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue), 0, hingeHeight)

        if(manager.pair) {
            hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) - frameThickness, hingeHeight)
            hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) + doorSeparation-hingeDepth, hingeHeight)
        }
    }

    if(manager.values.e.enabled) {
        dimension(frame, {
            from: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue + manager.values.c.actualValue + manager.values.d.actualValue)),
            to: point(frameThickness + 10, scale(manager.values.a.actualValue + manager.values.b.actualValue + manager.values.c.actualValue+ manager.values.d.actualValue+ manager.values.e.actualValue)),
            src: manager.values.e,
            shiftTextRight: 40
        })

        dimension(door, {
            from: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue + manager.values.c.actualValue + manager.values.d.actualValue)),
            to: point(-10, scale(manager.values.aStar.actualValue + manager.values.b.actualValue + manager.values.c.actualValue+ manager.values.d.actualValue+ manager.values.e.actualValue)),
            src: manager.values.e,
            shiftTextRight: -15
        })

        hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue + manager.values.e.actualValue), frameThickness - hingeDepth, hingeHeight)
        hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue + manager.values.e.actualValue), 0, hingeHeight)

        if(manager.pair) {
            hingeSilhouette(frame, scale(manager.values.a.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue + manager.values.e.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) - frameThickness, hingeHeight)
            hingeSilhouette(door, scale(manager.values.aStar.actualValue + manager.values.b.actualValue+ manager.values.c.actualValue + manager.values.d.actualValue + manager.values.e.actualValue), scale(manager.nominalWidth + manager.secondaryWidth) + doorSeparation-hingeDepth, hingeHeight)
        }
    }

    if(manager.pair) {
        strikeSilhouette(door, scale(manager.nominalWidth) + doorSeparation, scale(manager.values.cldStar.actualValue), strikeHeight)

        if(manager.values.dbdStar.enabled) {
            strikeSilhouette(door, scale(manager.nominalWidth) + doorSeparation, scale(manager.values.dbfStar.actualValue), strikeHeight)
        }
    } else {
        if (manager.values.clfStar.enabled) {
            dimension(frame, {
                from: point(scale(manager.nominalWidth) + 10, frameThickness),
                to: point(scale(manager.nominalWidth) + 10, scale(manager.values.clfStar.actualValue)),
                src: manager.values.clfStar,
                shiftTextRight: 40,
            })
        }

        if (manager.values.clf.enabled) {
            dimension(frame, {
                from: point(scale(manager.nominalWidth) + 10, scale(manager.values.clfStar.actualValue)),
                to: point(scale(manager.nominalWidth) + 10, scale(manager.nominalHeight)),
                src: manager.values.clf,
                shiftTextRight: 40,
            })
        }

        if (manager.values.dbfStar.enabled) {
            dimension(frame, {
                from: point(scale(manager.nominalWidth) - 10 - frameThickness, frameThickness),
                to: point(scale(manager.nominalWidth) - 10 - frameThickness, scale(manager.values.dbfStar.actualValue)),
                src: manager.values.dbfStar,
                shiftTextRight: -15,
            })
        }

        if (manager.values.dbf.enabled) {
            dimension(frame, {
                from: point(scale(manager.nominalWidth) - 10 - frameThickness, scale(manager.values.dbfStar.actualValue)),
                to: point(scale(manager.nominalWidth) - 10 - frameThickness, scale(manager.nominalHeight)),
                src: manager.values.dbf,
                shiftTextRight: -15,
            })
        }

        strikeSilhouette(frame, scale(manager.nominalWidth)-frameThickness, scale(manager.values.cldStar.actualValue), strikeHeight)

        if(manager.values.dbfStar.enabled) {
            strikeSilhouette(frame, scale(manager.nominalWidth) - frameThickness, scale(manager.values.dbfStar.actualValue), strikeHeight)
        }
    }

    if(manager.values.cldStar.enabled) {
        dimension(door, {
            from: point(scale(manager.nominalWidth) + 10, 0),
            to: point(scale(manager.nominalWidth) + 10, scale(manager.values.cldStar.actualValue)),
            src: manager.values.cldStar,
            shiftTextRight: 40,
        })
    }

    if(manager.values.cld.enabled) {
        dimension(door, {
            from: point(scale(manager.nominalWidth) + 10, scale(manager.values.cldStar.actualValue)),
            to: point(scale(manager.nominalWidth) + 10, scale(manager.nominalHeight)),
            src: manager.values.cld,
            shiftTextRight: 40,
        })
    }

    const radius = scale(ImperialDistance.parse("2 1/8\"").universalValue()/2);

    if(manager.values.dbd.enabled) {
        dimension(door, {
            from: point(scale(manager.nominalWidth - manager.values.bs.actualValue) - radius - 10, scale(manager.values.dbdStar.actualValue)),
            to: point(scale(manager.nominalWidth - manager.values.bs.actualValue) - radius - 10, scale(manager.nominalHeight)),
            src: manager.values.dbd,
            shiftTextRight: -15,
            noTextBackground: true,
        })
    }

    if(manager.values.dbdStar.enabled) {
        dimension(door, {
            from: point(scale(manager.nominalWidth - manager.values.bs.actualValue) - radius - 10, 0),
            to: point(scale(manager.nominalWidth - manager.values.bs.actualValue) - radius - 10, scale(manager.values.dbdStar.actualValue)),
            src: manager.values.dbdStar,
            shiftTextRight: -15,
            noTextBackground: true,
        })
    }

    if(manager.values.bs.enabled) {
        const y = scale(manager.values.dbdStar.actualValue) - 20
        const from = point(scale(manager.nominalWidth), y)
        const to = point(scale(manager.nominalWidth - manager.values.bs.actualValue), y)

        const {text} = dimension(door, {
            from: from,
            to: to,
            src: manager.values.bs,
            noTextBackground: true,
            noBreakText: true,
            rotateTextDeg: -90,
        })

        const b = text.getBounds()
        const c = center(from, to)
        text.position.set(c.x, c.y - b.height/2 - 10)
    }

    cutSilhouette(door, scale(manager.nominalWidth - manager.values.bs.actualValue), scale(manager.values.cldStar.actualValue), radius)
    if(manager.values.dbdStar.enabled) {
        cutSilhouette(door, scale(manager.nominalWidth - manager.values.bs.actualValue), scale(manager.values.dbdStar.actualValue), radius)
    }
}

function cutSilhouette(g: PIXI.Graphics, offsetLeft: number, offsetTop: number, radius: number) {
    g.beginFill(silhouetteColor)
    g.lineStyle({
        width: 1,
        color: frameOutline,
    })

    g.drawCircle(offsetLeft, offsetTop, radius)
    g.endFill()
}

function strikeSilhouette(g: PIXI.Graphics, offsetLeft: number, offsetTop: number, height: number) {
    const depth = 4;

    g.beginFill(silhouetteColor);
    g.drawRect(offsetLeft, offsetTop - height/2, depth, height);
    g.endFill();
}

const silhouetteColor = 0x151515
const hingeDepth = 4;

function hingeSilhouette(g: PIXI.Graphics, offsetTop: number, offsetLeft: number, hingeHeight: number) {

    g.moveTo(offsetLeft, offsetTop);

    g.beginFill(silhouetteColor);
    g.drawRect(offsetLeft, offsetTop, hingeDepth, hingeHeight);
    g.endFill();
}

type XY = {
    x: number;
    y: number;
}

function point(x: number, y: number) {
    return {
        x: x,
        y: y,
    }
}

const hideDimensions = true;

function dimension(graphic: Graphics, opts: {
    src: InternalValue;
    from: { x: number; y: number };
    to: { x: number; y: number },
    shiftTextRight?: number;
    shiftTextFx?: (input: XY) => XY;
    noTextBackground?: boolean;
    noBreakText?: boolean;
    rotateTextDeg?: number
}) {
    const {from, to} = opts;

    let text;

    if(hideDimensions) {
        text = opts.src.label
    } else {
        text = opts.src.label + " " + ImperialDistance.showInches(opts.src.actualValue);
        if (text.length > 10 && !opts.noBreakText) {
            text = opts.src.label + "\n" + ImperialDistance.showInches(opts.src.actualValue);
        }
    }

    graphic.moveTo(from.x, from.y);
    graphic.lineStyle({
        width: 1,
        color: 0x3d3d3d,
    })
    graphic.lineTo(to.x, to.y);

    let v = new Vector(from, to)
    const uv = v.unitVector().rotateDeg(90);

    moveToPoint(graphic, uv.addPoint(to, 5));
    lineToPoint(graphic, uv.addPoint(to, -5))

    moveToPoint(graphic, uv.addPoint(from, 5));
    lineToPoint(graphic, uv.addPoint(from, -5))

    let p = center(from, to);
    if(opts.shiftTextFx) {
        p = opts.shiftTextFx(p)
    } else if(opts.shiftTextRight) {
        p.x += opts.shiftTextRight;
    }

    const txt = makeText(text, p, {
        noTextBackground: opts.noTextBackground,
        rotateDeg: opts.rotateTextDeg,
    })

    graphic.addChild(txt)

    return {
        text: txt,
    };
}

function moveToPoint(graphic: Graphics, point: XY) {
    graphic.moveTo(point.x, point.y);
}

function lineToPoint(graphic: Graphics, point: XY) {
    graphic.lineTo(point.x, point.y);
}

class Vector {
    from: XY;
    to: XY;

    constructor(from: XY, to: XY) {
        this.from = from;
        this.to = to;
    }

    length() {
        return Math.sqrt(Math.pow(this.from.x - this.to.x, 2) + Math.pow(this.from.y - this.to.y, 2))
    }

    unitVector() {
        return new UnitVector({
            x: (this.from.x - this.to.x) / this.length(),
            y: (this.from.y - this.to.y) / this.length(),
        })
    }
}

class UnitVector {
    x: number;
    y: number;

    constructor(value: XY) {
        this.x = value.x;
        this.y = value.y;
    }

    rotateDeg(degrees: number) {
        return new UnitVector({
            x: this.x * Math.cos(degToRad(degrees)) - this.y * Math.sin(degToRad(degrees)),
            y: this.x * Math.sin(degToRad(degrees)) + this.y * Math.cos(degToRad(degrees)),
        })
    }

    addPoint(point: XY, mul: number) {
        return {
            x: point.x + this.x * mul,
            y: point.y + this.y * mul,
        }
    }
}

function degToRad(deg: number) {
    return deg / 180 * Math.PI
}

function makeText(value: string, point: XY, opts?: {
    noTextBackground?: boolean
    rotateDeg?: number
}) {
    const dimStyle = new PIXI.TextStyle({
        fontFamily: 'sans-serif',
        fontWeight: 'lighter',
        fontSize: 10,
        fill: '#131313',
        stroke: '#131313',
        strokeThickness: 1,
    });

    const basicText = new PIXI.Text(value);
    basicText.style = dimStyle;
    basicText.resolution = 4;

    const g = new PIXI.Graphics();
    g.addChild(basicText);

    if(opts?.noTextBackground) {} else {
        const rect = basicText.getLocalBounds()
        g.beginFill(0xffffff);
        g.drawRect(rect.x, rect.y + 2, rect.width, rect.height - 4);
        g.endFill();
    }

    g.pivot.set(basicText.width/2,basicText.height/2)
    g.rotation = degToRad(opts?.rotateDeg || 0);
    g.position.set(point.x - g.width/2, point.y - g.height/2)

    return g;
}

function center(a: XY, b: XY) {
    return {
        x: (a.x + b.x)/2,
        y: (a.y + b.y)/2,
    }
}

function centerX(item: Graphics, width: number) {
    return (width - item.width)/2
}

function putInBox(width: number, height: number, widthWant: number, heightWant: number) {
    const scaleX = widthWant / width;
    const scaleY = heightWant / height;

    return Math.min(scaleX, scaleY);
}

export const frameThickness = 10;
const frameFill = 0xb0b0b0;
const frameOutline = 0x1c1c1c

function drawFrame(frame: Graphics, height: number, width: number) {
    const thickness = frameThickness;
    height = Math.round(height);
    width = Math.round(width);

    frame.lineStyle({
        width: 1,
        color: frameOutline,
    })

    frame.moveTo(0, 0);
    frame.beginFill(frameFill);
    frame.lineTo(0, height);
    frame.lineTo(0 + thickness, height);
    frame.lineTo(0 + thickness, 0 + thickness);
    frame.lineTo(width - thickness, 0 + thickness);
    frame.lineTo(width - thickness, height);
    frame.lineTo(width, height);
    frame.lineTo(width, 0);
    frame.lineTo(0, 0);
    frame.endFill()
}

import {MiscPrice} from "../../../../api/MiscPricing";
import {useIsQuote} from "../QuoteContainer";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";

export function useMiscPrices(props: {
    project: number;
    alternative?: number;
}) {
    const isQuote = useIsQuote();

    return {
        get: async (): Promise<MiscPrice[]> =>  {
            const project = props.project;
            const alternative = props.alternative as any;
            return getAll(null, offset => isQuote ? api.quoteMiscPricing.list({project, alternative, offset}) : api.miscPricing.list({project, offset}))
        }
    }
}
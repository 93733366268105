import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {rowSecondaryTextStyle, rowStyle} from "../driver/RouteList";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Alert, Tab, Tabs} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {ToReceiveItem} from "../../../api/Receiving";
import {tabGroupBig} from "../purchasing/PartsToSource";
import {TabWrapper} from "../../project/shopdrawing/release/Release";

export function Receiving() {
    const [mode, setMode] = useState("purchaseOrder");
    const list = useAsync2(props => api.receiving.list(props as any), {
        mode: mode,
    }, [mode]);

    useInterval(list.reload, 5 * 60 * 1000);
    const nav = useNavigate()

    const action = useAsyncAction(async (input: ToReceiveItem) => {
        if(input.warehouseReceive) {
            nav("/logistics/receiving/" + input.warehouseReceive)
            return;
        }

        if(input.purchaseOrder) {
            const r = await api.receiving.create({
                receivePO: input.purchaseOrder,
            })

            nav("/logistics/receiving/" + r.id)
            return
        }

        throw new Error("Invalid input, refresh page and try again")
    }, []);

    return (
        <MobileContainer topCenter="Receiving">
            {action.LoadingElement}
            <div>
                <TabWrapper>
                    <Tabs className={tabGroupBig} value={mode} onChange={(e, value) => {
                        setMode(value)
                    }}>
                        <Tab label="Trucks" value="default" />
                        <Tab label="Purchase Orders" value="purchaseOrder" />
                        <div style={{flex: 1}} />
                    </Tabs>
                </TabWrapper>
            </div>
            <Loader {...list}>
                {input => <div style={{display: "flex", flexDirection: "column",}}>
                    {input.map(d =>
                        <button style={{
                            background: "none", display: "block", flex: 1,
                            border: "none", padding: 0, margin: 0,
                            textAlign: "inherit", color: "inherit",
                        }} onClick={() => {
                            action.callback(d)
                        }}>
                            <div className={rowStyle}>
                                {d.purchaseOrder ? <div>
                                    {d.supplierName}: {d.name}
                                    <div className={rowSecondaryTextStyle}>
                                        PO Submitted {moment(d.poSubmittedAt).format("YYYY MMM D")}
                                    </div>
                                </div> : <div>
                                    {d.name}
                                    <div className={rowSecondaryTextStyle}>
                                        Returned Delivery
                                    </div>
                                </div>}
                            </div>
                        </button>
                    )}
                    {input.length === 0 && <div style={{padding: 8}}>
                        {mode === "purchaseOrder" ? <Alert color="info">No POs Pending</Alert> :
                            <Alert color="info">No Trucks Pending</Alert>}
                    </div>}
                </div>}
            </Loader>
        </MobileContainer>
    )
}

export function useInterval(cb: () => void, interval: number) {
    const ref = useRef(cb);
    ref.current = cb;

    useEffect(() => {
        const i = setInterval(() => {
            ref.current();
        }, interval);

        return () => clearInterval(i);
    }, [interval]);
}
import {TextField} from "@mui/material";

export function PreambleTab(props: {
    value: string;
    onChange(value: string): void;
}) {

    return (
        <>
            <div style={{height: 8}} />
            <TextField
                label="Preamble (free text)"
                fullWidth
                rows="4"
                multiline
                value={props.value}
                onChange={e => props.onChange(e.target.value)} />
        </>
    )
}
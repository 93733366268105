import {Contact} from "../../../../api/Contacts";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField
} from "@mui/material";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useMemo, useState} from "react";
import {EditContact} from "../summary/EditContact";
import {AutoComplete} from "../../shopdrawing/openings/AutoComplete";
import {grey} from "@mui/material/colors";
import {autoFormat} from "../summary/AutoFormat";
import {Company} from "../../../../api/Companies";
import {combineStrs, decorate} from "../../../../misc/pdf/parts/CoverPage";

export function FindOrCreateContact(props: {
    project?: number;
    onDone(value?: Contact): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async (input) => {
        const contact = await api.contacts.upsert(input)
        onDoneRef.current(contact);
    }, []);

    const [contact, setContact] = useState<Contact|null>(null);
    const [showEdit, setShowEdit] = useState(false);
    const companyCalc = useMemo(() => {
        if(!contact) return null;
        return {id: contact?.company, name: contact?.companyName} as any;
    }, [contact]);
    const [siteContact, setSiteContact] = useState(false);

    if(showEdit && contact) {
        return <EditContact value={contact} onDone={value => {
            setContact(value);
            setShowEdit(false);
        }} />
    }

    return (
        <Dialog open maxWidth="xs" fullWidth>
            <DialogTitle>Find or Create Contact</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />

                <AutoComplete<Contact>
                    fullWidth
                    label="Find Contact"
                    search={async input => {
                        const result = await api.contacts.list({
                            search: input,
                        })

                        return result.data;
                    }}
                    value={contact}
                    equals={(a, b) => a.id === b.id}
                    makeLabelReact={a => (
                        <div>
                            <div>{combineStrs(a.name, decorate(a.title, "(", ")"))}</div>
                            <div style={{fontSize: "0.8em", color: grey["800"]}}>{a.companyName}</div>
                            <div style={{fontSize: "0.8em", color: grey["800"]}}>{a.phone} • {a.email}</div>
                        </div>
                    )}
                    makeLabel={a => a.id ? a.name + " • " + a.companyName : a.name}
                    onSelect={setContact}
                    onCreate={async input => {
                        setContact({
                            id: 0,
                            company: 0,
                            name: input,
                        } as any);
                    }}
                />


                {contact?.id === 0 && <>
                    <div style={{height: 16}} />
                    <AutoComplete<Company>
                        label="Company"
                        fullWidth
                        search={async input => {
                            const result = await api.companies.list({
                                search: input,
                                kind: "client-or-contractor"
                            })

                            return result.data;
                        }}
                        value={companyCalc}
                        equals={(a, b) => a.id === b.id}
                        makeLabel={a => a.name}
                        onSelect={a => setContact(Object.assign({}, contact, {
                            company: a.id,
                            companyName: a.name,
                        }))}
                        onCreate={async input => {
                            const co = await api.companies.upsert({
                                name: input,
                            })

                            setContact(Object.assign({}, contact, {
                                company: co.id,
                                companyName: co.name,
                            }))
                        }}
                    />
                </>}

                {contact?.id === 0 ? <>
                    <div style={{paddingTop: 16, paddingBottom: 8}}>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <TextField label="Title" value={contact.title} onChange={e =>
                                    setContact(Object.assign({}, contact, {
                                        title: e.target.value,
                                    }))
                                } />
                            </Grid>
                            <Grid item>
                                <TextField label="Email" value={contact.email} onChange={e =>
                                    setContact(Object.assign({}, contact, {
                                        email: autoFormat(e.target.value, "email")
                                    }))
                                } />
                            </Grid>
                            <Grid item>
                                <TextField label="Phone" value={contact.phone} onChange={e =>
                                    setContact(Object.assign({}, contact, {
                                        phone: autoFormat(e.target.value, "phone")
                                    }))
                                } />
                            </Grid>
                            {props.project && <Grid item>
                                <FormControlLabel control={<Switch checked={siteContact} onChange={(_, checked) => setSiteContact(checked)} />} label="Site Contact" />
                            </Grid>}
                        </Grid>
                    </div>
                </> : contact ? <div style={{padding: 8}}>
                    <div>{contact.phone || <i>Missing phone</i>}</div>
                    <div>{contact.email || <i>Missing email</i>}</div>
                    <div>
                        <Button onClick={() => setShowEdit(true)}>Edit Details</Button>
                    </div>
                </div> : null}

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={() => props.onDone()}>Cancel</Button>
                <Button disabled={!contact} variant='contained' color="primary" onClick={() => {
                    if(!contact) return

                    let value = contact;
                    if(props.project) {
                        value = Object.assign({}, value, {
                            linkToProject: props.project,
                            siteContact: siteContact,
                        })
                    }

                    update.callback(value);
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
import React from "react";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {QuoteSwitcher} from "./QuoteSwitcher";

export function QuotePricingSwitcher() {
    const projectId = useProjectId();

    return (
        <QuoteSwitcher
            title="Project Pricing"
            url={`/project/${projectId}/shop-drawing/pricing`}
            quoteTitle="Quote Pricing"
            quoteURL={`/project/${projectId}/quote/-1/pricing`} />
    )
}
import {useCheckboxSelection} from "../release/Checkbox";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {Button, Grid, Menu, TextField} from "@mui/material";
import {useState} from "react";
import {StaticDatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useTableRefresher} from "../../../../misc/scroller/Table";
import {logisticsTableName} from "./Logistics";
import { Loader } from "../../../../misc/Loader";
import {useProjectId} from "../ProjectName";

export function BulkEditNeededBy(props: {
    type: string
}) {
    const sel = useCheckboxSelection()
    const [open, setOpen] = useState<HTMLElement|null>(null);
    const [value] = useState<Moment|null>(moment());
    const project = useProjectId();
    const refresher = useTableRefresher(logisticsTableName(project, props.type));

    const update = useAsyncAction(async (input: {ids: number[], due: null | Moment}) => {
        await api.logistics.bulkUpdateRequestDueDates(input);
        setOpen(null);
        refresher()
    }, [refresher]);

    return <div style={{paddingLeft: 8}}>

        <Button onClick={e => setOpen(e.currentTarget)} size="small" variant="outlined" color="inherit" disabled={sel.length === 0} endIcon={<ArrowDropDown />}>
            Update Needed By ({sel.length})
        </Button>

        {open && <Menu open anchorEl={open} onClose={() => setOpen(null)}>
            <Loader {...update} />
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={(newValue) => {
                    update.callback({
                        ids: sel,
                        due: newValue,
                    })
                }}
                renderInput={(params) => <TextField {...params} />}
            />
            <Grid container spacing={1} justifyContent="flex-end" style={{paddingRight: 10, paddingBottom: 8}}>
                <Grid item>
                    <Button onClick={() => setOpen(null)} size="small">Cancel</Button>
                </Grid>
                <Grid item>
                    <Button onClick={() => update.callback({
                        ids: sel,
                        due: null,
                    })} variant="contained" size="small">Set to Blank</Button>
                </Grid>
            </Grid>
        </Menu>}
    </div>
}
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {api} from "../../../../../api/API";
import {first, useAsync2} from "nate-react-api-helpers";
import {useProject, useProjectId} from "../../ProjectName";
import {useCallback, useContext, useRef, useState} from "react";
import {PDFInfo, PrintManagerContext} from "../../../../../misc/pdf/PrintManager";
import {KeyingPDF} from "./KeyingPDF";
import {getAll} from "../../openings/Openings";
import {useSubmittalPreferences} from "../../../../../misc/pdf/useSubmittalPreferences";
import {Project} from "../../../../../api/Projects";
import {Company} from "../../../../../api/Companies";
import {KeyName, KeyOpening} from "../../../../../api/Keying";
import {getPDFClient} from "../../../../../misc/pdf/parts/CoverPage";

export function useKeyingSubmittalProps(props: {
}) {
    const projectId = useProjectId();
    const [keyCount, setKeyCount] = useState<KeyCount[]>([]);
    const keyNameRequest = useAsync2(input => api.keying.listNames(input), {project: projectId}, [projectId]);

    const prefs = useSubmittalPreferences({
        project: projectId,
        kind: "keying-schedule",
        onBlank: () => {
            const names = keyNameRequest.result;
            if(!names) {
                return;
            }

            setKeyCount(makeKeyCounts(names))
        },
        onLoad: (cfg: KeyingPDFInfo) => {
            const names = keyNameRequest.result;
            if(!names) {
                return;
            }

            setKeyCount(makeKeyCounts(names, cfg))
        },
        onLoadDeps: [keyNameRequest.result],
    });

    const prefsSaveRef = useRef(prefs.save);
    prefsSaveRef.current = prefs.save;

    const keyCountRef = useRef(keyCount);
    keyCountRef.current = keyCount;

    const save = useCallback((newCount?: KeyCount[]) => {
        if(newCount) {
            setKeyCount(newCount);
        }

        return prefsSaveRef.current({
            version: 1 as 1,
            keyCount: newCount || keyCountRef.current,
        });
    }, []);

    return {
        loading: prefs.loading,
        keyCount: keyCount,
        setKeyCount: setKeyCount,
        save: save,
        names: keyNameRequest.result || [],
        lastSubmission: prefs.lastSubmission,
    }
}

export function KeyingSubmittal(props: {
    onDone(): void;
}) {
    const pr = useProject();

    const printCtx = useContext(PrintManagerContext);
    const prefs = useKeyingSubmittalProps({});
    const {keyCount, setKeyCount} = prefs;

    if(!pr || prefs.loading) return null;
    const project = pr;

    return (
        <Dialog open maxWidth="md" fullWidth>
            <DialogTitle>Create Keying Submittal</DialogTitle>

            <DialogContent>
                <table>
                    <tbody>
                    <tr>
                        <th>Name</th>
                        <th># of Keys Included</th>
                    </tr>
                    {keyCount.map(n => <tr key={n.keyNameId.toString()}>
                        <td style={{paddingRight: 20}}>{n.name}</td>
                        <td>
                            <TextField variant="outlined"
                                       size="small"
                                       value={n.value}
                                       onChange={e => {
                                           setKeyCount(keyCount.map(c => {
                                               if(c.keyNameId === n.keyNameId) {
                                                   return Object.assign({}, c, {
                                                       value: e.target.value,
                                                   })
                                               }

                                               return c;
                                           }))
                                       }}
                            />
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDone}>Cancel</Button>
                <Button size="small" variant="contained"
                        color="primary" startIcon={<CheckCircleIcon />}
                        onClick={async () => {

                            await prefs.save()

                            const client = await getPDFClient(project)

                            await printCtx.print2(getKeyingPDF({
                                project,
                                client,
                                keyData: async () => {
                                    const keyData = await getAll(null, offset => api.keying.list({offset, project: project.id}))

                                    // sort by floor ascending, then by seqNumber
                                    keyData.sort((a, b) => {
                                        if(a.floor !== b.floor) {
                                            const aFloor = parseInt(a.floor) || 0
                                            const bFloor = parseInt(b.floor) || 0
                                            return aFloor - bFloor
                                        }

                                        return a.seqNumber - b.seqNumber
                                    });

                                    return {
                                        data: keyData,
                                        names: prefs.names || [],
                                        info: {
                                            keyCount: prefs.keyCount,
                                            version: 1 as 1,
                                        },
                                    }
                                },
                                lastSubmission: prefs.lastSubmission,
                            }));

                            props.onDone();
                        }}
                >Create and Download</Button>
            </DialogActions>
        </Dialog>
    )
}

export function makeKeyCounts(names: KeyName[], cfg?: KeyingPDFInfo) {
    return names.map(v => {
        return {
            keyNameId: v.id,
            name: v.short,
            value: first(cfg?.keyCount || [], c => c.keyNameId === v.id)?.value || "",
        }
    })
}

export function getKeyingPDF(props: {
    project: Project;
    client?: Company;
    keyData: () => Promise<{
        data: KeyOpening[]
        names: KeyName[]
        info: KeyingPDFInfo;
    }>;
    lastSubmission: string | null;
}): PDFInfo {
    return {
        name: `keying-schedule.pdf`,
        render: async onReady => {
            const data = await props.keyData()

            return <KeyingPDF
                project={props.project}
                lastSubmittal={props.lastSubmission}
                client={props.client}
                onReady={onReady}
                rows={data.data}
                names={data.names}
                info={data.info}
            />
        },
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "keying-schedule",
        }
    }
}

export type KeyingPDFInfo = {
    version: 1;
    keyCount: KeyCount[]
};

export type KeyCount = {
    keyNameId: number;
    name: string;
    value: string;
}
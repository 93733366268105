import {PropsWithChildren} from "react";
import {blue, grey} from "@mui/material/colors";

export function LogisticsItemHeader(props: PropsWithChildren<{}>) {
    return (
        <div style={{padding: 16, paddingBottom: 24, paddingTop: 24, borderLeft:"3px solid " + blue["600"], borderBottom: "1px solid " + grey["400"]}}>
            <div style={{maxWidth: 800, width: "100%"}}>
                {props.children}
            </div>
        </div>
    )
}
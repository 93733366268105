import {css} from "@emotion/css";
import {deepOrange, grey} from "@mui/material/colors";
import {ucWords} from "../../../../../misc/lists/ListCommon";
import {Link} from "react-router-dom";

export function LogisticsSummaryItem(props: {
    title: string
    status: string;
    link: string;
    subTitle?: string;
}) {
    return (
        <div className={chipCss}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start"}}>
                <div style={{flex: 1, paddingRight: 8}}>
                    <Link to={props.link}>
                        {props.title}
                    </Link>
                </div>
                <div style={{
                    backgroundColor: deepOrange["100"], borderRadius: 3, paddingLeft: 4, paddingRight: 4, fontSize: "0.7rem"
                }}>
                    {ucWords(props.status.replace(/[-_]+/g, " "))}
                </div>
            </div>

            <div style={{fontSize: "0.75rem"}}>
                {props.subTitle}
            </div>
        </div>
    )
}

const chipCss = css({
    borderRadius: 6,
    backgroundColor: grey["200"],
    display: "flex",
    flexDirection: "column",
    margin: 4,
    padding: 6,
    fontSize: "0.9rem",

    "&:hover": {
        backgroundColor: grey["300"],
    },
})
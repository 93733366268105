import {useCallback} from "react";

export function useFilePicker(props: {
    accept?: string[]
}){
    return useCallback(() => {
        return new Promise<File[]>((resolve, reject) => {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = props.accept?.join(",") || "*/*";
            input.style.cssText = "position: absolute; left: -100%;";
            document.body.append(input)

            input.addEventListener("blur", () => {
                reject(new Error("no files picked"));
                input.remove();
            }, false)

            input.addEventListener("change", () => {
                const files = Array.from(input.files || [])
                if(files.length > 0) {
                    resolve(files);
                    input.remove()
                    return;
                }

                reject(new Error("no files picked"));
                input.remove()
            }, false)

            input.click();
        })
    }, [props.accept])
}
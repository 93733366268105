import {blue, grey} from "@mui/material/colors";
import {css} from "@emotion/css";

export function IconCell(props: {
    children: any
    className?: string;
    backgroundColor?: string;
}) {

    return (
        <td style={{padding: 0, backgroundColor: props.backgroundColor}} className={props.className}>
            <div style={{display: "flex",
                flexWrap: "nowrap",
                paddingLeft: 4,
                height: "1rem",}}>

                {props.children}
            </div>
        </td>
    )
}

export function IconButton2(props: {
    children: any;
    style?: any;
    disabled?: boolean;
    onClick?(e: any): void;
}) {
    return (
        <div className={buttonClass} onClick={(e) => {
            if(props.disabled) return;
            if(props.onClick) props.onClick(e);
        }} data-disabled={props.disabled} style={props.style}>
            {props.children}
        </div>
    )
}

const buttonClass = css({
    color: grey["400"],
    cursor: "pointer",
    paddingRight: 4,
    paddingLeft: 4,
    padding: 2,
    fontSize: "1.2rem",
    position: "relative",
    bottom: 5,

    "&[data-disabled]": {
        cursor: "default",
        color: grey["400"],

        "&:hover": {
            color: grey["400"],
        }
    },

    "&:hover": {
        color: blue["800"],
    }
})
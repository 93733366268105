import {Dialog, DialogActions, DialogTitle, DialogContent, Button, Tabs, Tab} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Opening} from "../../../../api/Openings";
import {useContext, useState} from "react";
import {useTableData} from "../../../../misc/scroller/Table";
import {useProject, useProjectId} from "../ProjectName";
import {currentTab, openingsTableName} from "./Openings";
import {OpeningsPDF} from "./OpeningsPDF";
import {PDFInfo, PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import {distinct, first, orderByAscending} from "nate-react-api-helpers";
import {tabGroup} from "../../../logistics/purchasing/PartsToSource";
import {ColumnTab} from "./pdf/ColumnTab";
import {PreambleTab} from "./pdf/PreambleTab";
import {TabWrapper} from "../release/Release";
import {
    currentQuoteTab,
    doorCoreLookup, doorFinishAbbreviations,
    doorSeamLookup,
    frameConstructionLookup,
    handingLookup,
    hollowMetalDoor,
    hollowMetalFrame,
    lookupHollowMetalMaterial,
    quoteOpeningsTableName,
    woodMaterialAbbreviations,
    woodPLAM
} from "../../quote/openings/QuoteOpenings";
import {useSubmittalPreferences} from "../../../../misc/pdf/useSubmittalPreferences";
import {Project} from "../../../../api/Projects";
import {Company} from "../../../../api/Companies";
import {getPDFClient} from "../../../../misc/pdf/parts/CoverPage";
import {api} from "../../../../api/API";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {anchorLookupOptions} from "../extras/CreateProduct";

export type ExportColumn = {
    display: string;
    key: keyof Opening & keyof QuoteOpening;
    render?: (input: Opening|QuoteOpening, drawingInfo: DrawingInfo) => string;
}

export const openingColumns: ExportColumn[] = [{
    display: "Opening",
    key: "name"
},{
    display: "Qty",
    key: "qty"
},{
    display: "Floor",
    key: "floor"
},{
    display: "Location",
    key: "locationOne"
},{
    display: "To\nFrom",
    key: "locationTransition"
},{
    display: "Location 2",
    key: "locationTwo"
},{
    display: "Type",
    key: "openingType"
},{
    display: "Hand",
    key: "handing"
},{
    display: "Label",
    key: "label"
},{
    display: "Frame\nSeries",
    key: "frameSeries"
},{
    display: "Frame\nMaterial",
    key: "frameMaterial"
},{
    display: "Frame\nGauge",
    key: "frameGauge"
},{
    display: "Screen\nElev",
    key: "screenElevation"
},{
    display: "Nominal\nWidth",
    key: "nominalWidth",
    render: input => {
        if(input.openingType === "Pair") {
            return `${input.nominalWidth}, ${input.inactiveDoorWidth}`
        }

        return input.nominalWidth;
    }
},{
    display: "Nominal\nHeight",
    key: "nominalHeight"
},{
    display: "Frame\nProfile",
    key: "frameProfile"
},{
    display: "Anchor",
    key: "frameAnchorProduct",
    render: (input: Opening|QuoteOpening) => {
        const name = input.frameAnchorProductName
        if(!name) return "";
        let parts = name.split(": ")
        return parts[0];
    }
},{
    display: "Wall\nType",
    key: "drawingWallLabel"
},{
    display: "Jamb\nDepth",
    key: "jambDepth"
},{
    display: "Frame\nConstruction",
    key: "frameConstruction"
},{
    display: "Door\nSeries",
    key: "doorSeries"
},{
    display: "Door\nMaterial",
    key: "doorMaterial",
    render: (input: Opening|QuoteOpening, drawingInfo: DrawingInfo) => {
        return drawingInfo.doorMaterialsLookup[input.doorMaterial] || input.doorMaterial
    }
},{
    display: "Door\nFinish",
    key: "doorFinish",
    render: (input, drawingInfo) => {
        return drawingInfo.doorFinishesLookup[input.doorFinish] || input.doorFinish
    }
},{
    display: "Door\nGauge",
    key: "doorGauge"
},{
    display: "Door\nThickness",
    key: "doorThickness"
},{
    display: "Door\nElevation",
    key: "doorElevation"
},{
    display: "Door\nCore",
    key: "doorCore"
},{
    display: "Door\nSeam",
    key: "doorSeam"
},{
    display: "Hardware\nHeading",
    key: "hardwareGroupName"
},{
    display: "Remarks",
    key: "notes"
}]

const defaultColumns = [
    "name",
    "qty",
    "floor",
    "locationOne",
    "locationTransition",
    "locationTwo",
    "openingType",
    "nominalWidth",
    "nominalHeight",
    "handing",
    "label",
    "frameSeries",
    "frameMaterial",
    "frameGauge",
    "screenElevation",
    "frameProfile",
    "jambDepth",
    "frameConstruction",
    "frameAnchorProduct",
    "doorSeries",
    "doorMaterial",
    "doorFinish",
    "doorGauge",
    "doorThickness",
    "doorElevation",
    "doorCore",
    "doorSeam",
    "hardwareGroupName",
    "notes"
]

export function MakeSubmittal(props: {
    onDone(): void;
}) {
    const project = useProjectId();
    const openings = useTableData(openingsTableName(project, currentTab)) as Opening[]

    return (
        <MakeSubmittalInner onDone={props.onDone} openings={openings} />
    )
}

export function MakeQuoteSubmittal(props: {}) {
    const [show, setShow] = useState(false);
    const project = useProjectId();
    const openings = useTableData(quoteOpeningsTableName(project, currentQuoteTab)) as QuoteOpening[]

    return (
        <>
            <SmallGreyButton startIcon={<AddCircleIcon />} onClick={() => setShow(true)}>
                Create Submittal
            </SmallGreyButton>
            {show && <MakeSubmittalInner onDone={() => setShow(false)} openings={openings} />}
        </>
    )
}

export function MakeSubmittalInner(props: {
    openings: (Opening|QuoteOpening)[]
    onDone(): void;
}) {
    const [selected, setSelected] = useState<ExportColumn[]>(
        defaultColumns
            .map(key => openingColumns.find(c => c.key === key))
            .filter(v => !!v) as ExportColumn[]
    );
    const projectId = useProjectId();
    const printCtx = useContext(PrintManagerContext);
    const project = useProject();

    const prefs = useSubmittalPreferences({
        project: projectId,
        kind: "shop-drawing",
        onLoad: (list: ShopDrawingSubmittalPrefs) => {
            if (list instanceof Array) {
                setSelected(list.map(l => first(openingColumns, c => c.key === l))
                    .filter(v => !!v) as ExportColumn[])
            } else if ("version" in list && list.version === 1) {
                setSelected(list.columns.map(l => first(openingColumns, c => c.key === l))
                    .filter(v => !!v) as ExportColumn[])
                setPreamble(list.preamble)
            }
        }
    });

    const [preamble, setPreamble] = useState("")
    const [tab, setTab] = useState("preamble");

    if(prefs.loading) return null;

    return (
        <Dialog open maxWidth="md" fullWidth>
            <DialogTitle>Create Shop Drawing Submittal</DialogTitle>
            <TabWrapper>
                <Tabs className={tabGroup} value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Preamble" value="preamble" />
                    <Tab label="Columns" value="columns" />
                </Tabs>
            </TabWrapper>
            <DialogContent>

                {tab === "columns" ?
                    <ColumnTab value={selected} onChange={setSelected} sample={props.openings[0]} /> :
                    <PreambleTab value={preamble} onChange={setPreamble} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDone}>Cancel</Button>
                <Button size="small" variant="contained"
                        color="primary" startIcon={<CheckCircleIcon />}
                        onClick={async () => {
                            await prefs.save({
                                version: 1,
                                columns: selected.map(c => c.key),
                                preamble: preamble,
                            })

                            if(!project) return;

                            const client = await getPDFClient(project);

                            await printCtx.print2(getShopDrawingPDF({
                                projectId: project.id,
                                getData: async () => {
                                    const openings = props.openings.slice(0)
                                    orderByAscending(openings, o => o.seqNumber)

                                    return {
                                        preamble,
                                        columns: selected,
                                        project,
                                        drawingInfo: makeDrawingInfo(props.openings),
                                        lastSubmission: prefs.lastSubmission,
                                        client,
                                        data: openings,
                                    }
                                }
                            }));

                            props.onDone();
                        }}
                >Create and Download</Button>
            </DialogActions>
        </Dialog>
    )
}

export function getShopDrawingPDF(props: {
    projectId: number;
    getData: () => Promise<{
        preamble: string;
        columns: ExportColumn[]
        project: Project;
        drawingInfo: DrawingInfo;
        lastSubmission: string|null;
        client?: Company;
        data: (Opening|QuoteOpening)[];
    }>
}): PDFInfo {

    return {
        name: `shop-drawing.pdf`,
        render: async (onReady, config) => {
            const d = await props.getData();

            const screenElevations = distinct(d.data.map(d => d.screenElevationFile).filter(v => !!v)) as number[]
            const doorElevations = distinct(d.data.map(d => {
                if("doorElevationFile" in d) return d.doorElevationFile;
                return null;
            }).filter(v => !!v)) as number[]
            const anchorProductsForFiles = distinct(d.data.filter(d => d.frameAnchorDetailFile).map(d => d.frameAnchorProduct).filter(v => !!v)) as number[]

            const screenElevationImgP = Promise.all(screenElevations.map(s => api.print.fileAsImage({file: s})));
            const doorElevationImageP = Promise.all(doorElevations.map(s => api.print.fileAsImage({file: s})));
            const anchorImagesP = Promise.all(anchorProductsForFiles.map(s => api.print.fileAsImage({catalogForProduct: s})));

            const screenElevationImages = await screenElevationImgP;
            const doorElevationImages = await doorElevationImageP;
            const anchorImages = await anchorImagesP;

            return (
                <OpeningsPDF
                    preamble={d.preamble} columns={d.columns}
                    project={d.project}
                    drawingInfo={d.drawingInfo}
                    lastSubmittal={d.lastSubmission}
                    client={d.client} data={d.data} onReady={onReady}
                    screenElevations={screenElevationImages}
                    doorElevations={doorElevationImages}
                    anchorImages={anchorImages}
                />
            );
        },
        serverInfo: {
            submissionForProject: props.projectId,
            submissionType: "shop-drawing",
        }
    }
}

export type DrawingInfo = {
    handings: string[]
    cores: string[];
    seams: string[];
    construction: string[]
    anchors: string[]

    doorMaterialsLookup: {[key: string]: string};
    doorMaterialsAbbreviations: {[key: string]: string};
    doorFinishAbbreviations: {[key: string]: string};
    doorFinishesLookup: {[key: string]: string};
}

function removeBlanks(value: string | null | undefined) {
    return !!value;
}

function withLookup(lookup: (value: string) => {value: string; display: string} | null) {
    return (value: string) => {
        return lookup(value)?.display;
    }
}

type ColumnList = string[]
export type ShopDrawingPrefV1 = {
    version: 1
    columns: string[];
    preamble: string;
}
export type ShopDrawingSubmittalPrefs = ColumnList | ShopDrawingPrefV1;

export function isShopDrawingPrefV1(value: any): value is ShopDrawingPrefV1 {
    return "version" in value && value.version === 1;
}

export function makeDrawingInfo(ctx: (Opening|QuoteOpening)[]): DrawingInfo {
    const handings = distinct(ctx.map(o => o.handing).filter(v => !!v))
        .map(withLookup(handingLookup))
        .filter(removeBlanks) as string[];

    const doorMaterialsLookup: {[key:string]: string} = {};
    const doorMaterialsAbbreviations: {[key:string]: string} = {};

    [...ctx.filter(o => o.doorSeries === hollowMetalDoor)
        .map(o => o.doorMaterial),
    ...ctx.filter(o => o.frameSeries === hollowMetalFrame)
        .map(o => o.frameMaterial),
    ]
        .forEach(value => {
            const mat = withLookup(lookupHollowMetalMaterial)(value);
            if(!mat) return;

            const parts = mat.split(":").map(p => p.trim())
            doorMaterialsAbbreviations[parts[0]] = parts[1]
            doorMaterialsLookup[value] = parts[0]
        });

    let customDoorMaterials: string[] = [];

    ctx
        .filter(o => o.doorSeries !== hollowMetalDoor)
        .forEach(o => {
            const mat = o.doorMaterial
            if(!mat) return;

            if(mat === woodPLAM) {
                doorMaterialsAbbreviations[woodPLAM] = "Plastic Laminate"
                doorMaterialsLookup[mat] = mat
                return;
            }

            let abbr = woodMaterialAbbreviations[mat]
            if(!abbr) {
                let index = customDoorMaterials.indexOf(mat)
                if(index === -1) {
                    customDoorMaterials.push(mat)
                    index = customDoorMaterials.length - 1
                }

                abbr = `M${index}`
            }

            if(doorMaterialsAbbreviations.hasOwnProperty(abbr) && doorMaterialsAbbreviations[abbr] !== mat) {
                throw new Error("Duplicate abbreviation for door material: " + abbr)
            }

            doorMaterialsAbbreviations[abbr] = mat;
            doorMaterialsLookup[mat] = abbr;
        });

    let myDoorFinishAbbreviations: {[key: string]: string} = {}
    let doorFinishesLookup: {[key: string]: string} = {}
    let customDoorFinishes: string[] = [];

    ctx.forEach(v => {
        const finish = v.doorFinish
        if(!finish) return;

        let abbr = doorFinishAbbreviations[finish]
        if(!abbr) {
            let i = customDoorFinishes.indexOf(finish)
            if(i === -1) {
                customDoorFinishes.push(finish)
                i = customDoorFinishes.length - 1
            }

            abbr = `F${i}`
        }

        if(myDoorFinishAbbreviations[abbr] && myDoorFinishAbbreviations[abbr] !== finish) {
            throw new Error("Duplicate abbreviation for door finish: " + abbr)
        }

        myDoorFinishAbbreviations[abbr] = finish;
        doorFinishesLookup[finish] = abbr;
    })

    const cores = distinct(ctx.map(o => doorCoreLookup(o.doorCore, o.doorSeries)?.display))
        .filter(removeBlanks) as string[]
    const seams = distinct(ctx.map(o => o.doorSeam))
        .map(withLookup(doorSeamLookup))
        .filter(removeBlanks) as string[]
    const construction = distinct(ctx.map(o => o.frameConstruction)).map(withLookup(frameConstructionLookup)).filter(removeBlanks) as string[]
    const anchorAbbr = anchorLookupOptions();
    const anchors = (distinct(ctx.map(o => o.frameAnchorProductName)).filter(removeBlanks) as string[])
            .map(o => o.split(":")[0].trim())
            .map(abbr => anchorAbbr.find(a => a.value === abbr)?.display).filter(removeBlanks) as string[];

    return {
        anchors,
        handings,
        doorMaterialsLookup,
        doorMaterialsAbbreviations,
        doorFinishAbbreviations: myDoorFinishAbbreviations,
        doorFinishesLookup,
        cores,
        seams,
        construction,
    }
}
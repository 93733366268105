import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {red} from "@mui/material/colors";
import {categoryEmitter} from "./CategoryTree";
import {EventEmitter} from "nate-react-api-helpers";
import React from "react";

export const customPrep = "Custom"
export const customPrepWithBlankFrame = "Custom w/ Blank Frame"
export const noPrep = "No Prep"
export const noPrepNeeded = "**No prep needed**"
export const contHingePrep = "Cont Hinge Reinforcing"
export const followCloserRule = "**Follow closer rule**"
export const locksetASAPrep = "ASA"
export const dbStrikePrep = "DB Strike"
export const dbPrep = "DB Prep"
export const blankPrep = "BLANK"
export const electricStrikePrep = "Electric Strike"

export enum PrepKey {
    None = "none",
    AllCustom = "all-custom",

    Hinge45Std = "hinge-45-std",
    Hinge5Std = "hinge-5-std",
    Hinge45HW = "hinge-45-hw",
    Hinge5HW = "hinge-5-hw",
    HingeCont = "hinge-cont",
    Lockset161ASA = "lockset-161-asa",
    Deadbolt = "deadbolt",
    ExitDeviceRPReinforce = "exit-device-rp-reinforce",
    ExitTrimCustomWithBlank = "exit-trim-custom-with-blank",
    CloserReinforcing = "closer-reinforcing",
    BoltFBSTrike = "bolt-fb-strike",
    ElectricStrike = "electric-strike",
    ElectricStrikeASA = "electric-strike-asa",
    WiringHarnessRaceway = "wiring-harness-raceway",
    SurfaceAutoOpCloseReinf = "surface-auto-op-close-reinf",
}

export const noPrepOpt = {
    prepKey: PrepKey.None,
    name: noPrep,
    framePrep: noPrepNeeded,
    framePrepIsCustom: false,
    activeDoorPrep: noPrepNeeded,
    activeDoorPrepIsCustom: false,
    inactiveDoorPrep: noPrepNeeded,
    inactiveDoorPrepIsCustom: false,
}

type OptionGroup = {
    category: string;
    options: PrepOption[];
}

export type PrepOption = {
    prepKey: PrepKey;
    name: string;
    needsTemplate?: boolean;
    framePrep: string;
    framePrepIsCustom: boolean;
    activeDoorPrep: string;
    activeDoorPrepIsCustom: boolean;
    inactiveDoorPrep: string;
    inactiveDoorPrepIsCustom: boolean;
}

const hinge45Std = "4.5\" STD"
const hinge5Std = "5\" STD"
const hinge45HW = "4.5\" HW"
const hinge5HW = "5\" HW"

export function isNormalHingePrep(value: string, key: string) {
    switch(key) {
        case PrepKey.Hinge45Std:
        case PrepKey.Hinge5Std:
        case PrepKey.Hinge45HW:
        case PrepKey.Hinge5HW:
            return true;
        default:
            break;
    }

    switch(value) {
        case hinge45Std:
        case hinge5Std:
        case hinge45HW:
        case hinge5HW:
            return true;
        default:
            return false;
    }
}

const options: OptionGroup[] = [
    {
        category: "Hinges",
        options: [{
            prepKey: PrepKey.Hinge45Std,
            name: "4.5\" Standard Weight",
            framePrep: hinge45Std,
            framePrepIsCustom: false,
            activeDoorPrep: hinge45Std,
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: hinge45Std,
            inactiveDoorPrepIsCustom: false,
        },{
            prepKey: PrepKey.Hinge5Std,
            name: "5\" Standard Weight",
            framePrep: hinge5Std,
            framePrepIsCustom: true,
            activeDoorPrep: hinge5Std,
            activeDoorPrepIsCustom: true,
            inactiveDoorPrep: hinge5Std,
            inactiveDoorPrepIsCustom: true,
        },{
            prepKey: PrepKey.Hinge45HW,
            name: "4.5\" Heavy Weight",
            framePrep: hinge45HW,
            framePrepIsCustom: true,
            activeDoorPrep: hinge45HW,
            activeDoorPrepIsCustom: true,
            inactiveDoorPrep: hinge45HW,
            inactiveDoorPrepIsCustom: true,
        },{
            prepKey: PrepKey.Hinge5HW,
            name: "5\" Heavy Weight",
            framePrep: hinge5HW,
            framePrepIsCustom: true,
            activeDoorPrep: hinge5HW,
            activeDoorPrepIsCustom: true,
            inactiveDoorPrep: hinge5HW,
            inactiveDoorPrepIsCustom: true,
        },{
            prepKey: PrepKey.HingeCont,
            name: contHingePrep,
            framePrep: "CONT HINGE REINF",
            framePrepIsCustom: true,
            activeDoorPrep: "**As per template**",
            activeDoorPrepIsCustom: true,
            inactiveDoorPrep: "**As per template**",
            inactiveDoorPrepIsCustom: true,
        }]
    },{
        category: "Locksets",
        options: [{
            prepKey: PrepKey.Lockset161ASA,
            name: "161/ASA",
            framePrep: locksetASAPrep,
            framePrepIsCustom: false,
            activeDoorPrep: "161",
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: locksetASAPrep,
            inactiveDoorPrepIsCustom: true,
        }]
    },{
        category: "Deadbolts",
        options: [{
            prepKey: PrepKey.Deadbolt,
            name: "Deadbolt",
            framePrep: dbStrikePrep,
            framePrepIsCustom: true,
            activeDoorPrep: dbPrep,
            activeDoorPrepIsCustom: true,
            inactiveDoorPrep: dbStrikePrep,
            inactiveDoorPrepIsCustom: true,
        }]
    },{
        category: "Exit Devices",
        options: [{
            prepKey: PrepKey.ExitDeviceRPReinforce,
            name: "RP Reinforcing",
            framePrep: blankPrep,
            framePrepIsCustom: false,
            activeDoorPrep: "RP REINF",
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: "RP REINF",
            inactiveDoorPrepIsCustom: false,
        }]
    },{
        category: "Exit Trims",
        options: [{
            prepKey: PrepKey.ExitTrimCustomWithBlank,
            name: customPrepWithBlankFrame,
            framePrep: blankPrep,
            framePrepIsCustom: false,
            activeDoorPrep: "**As per template**",
            activeDoorPrepIsCustom: true,
            inactiveDoorPrep: "N/A",
            inactiveDoorPrepIsCustom: false,
        }]
    },{
        category: "Closers",
        options: [{
            prepKey: PrepKey.CloserReinforcing,
            name: "Closer Reinforcing",
            framePrep: followCloserRule,
            framePrepIsCustom: false,
            activeDoorPrep: noPrepNeeded,
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: noPrepNeeded,
            inactiveDoorPrepIsCustom: false,
        }]
    },{
        category: "Bolts",
        options: [{
            prepKey: PrepKey.BoltFBSTrike,
            name: "FB",
            framePrep: "FB Strike",
            framePrepIsCustom: true,
            activeDoorPrep: "N/A",
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: "FB Prep",
            inactiveDoorPrepIsCustom: true,
        }]
    },{
        category: "Electric Strikes",
        options: [{
            prepKey: PrepKey.ElectricStrike,
            name: "Electric Strike",
            framePrep: electricStrikePrep,
            framePrepIsCustom: true,
            activeDoorPrep: "N/A",
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: electricStrikePrep,
            inactiveDoorPrepIsCustom: true,
        }, {
            prepKey: PrepKey.ElectricStrikeASA,
            name: "ASA",
            framePrep: locksetASAPrep,
            framePrepIsCustom: false,
            activeDoorPrep: "N/A",
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: locksetASAPrep,
            inactiveDoorPrepIsCustom: true,
        }]
    },{
        category: "Wiring Harness",
        options: [{
            prepKey: PrepKey.WiringHarnessRaceway,
            name: "Raceway",
            framePrep: "N/A",
            framePrepIsCustom: false,
            activeDoorPrep: "N/A",
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: "Raceway",
            inactiveDoorPrepIsCustom: true,
        }]
    },{
        category: "Surface Automatic Operator",
        options: [{
            prepKey: PrepKey.SurfaceAutoOpCloseReinf,
            name: "Closer Reinforcing",
            framePrep: followCloserRule,
            framePrepIsCustom: false,
            activeDoorPrep: noPrepNeeded,
            activeDoorPrepIsCustom: false,
            inactiveDoorPrep: noPrepNeeded,
            inactiveDoorPrepIsCustom: false,
        }]
    }
]

export function PrepMenuOptions(props: {
    value: string;
    category: number | null
    onChange(value: PrepOption): void;
}) {
    const categories = EventEmitter.reactValue(categoryEmitter);
    if(!categories) return <MenuItem key="loading">Loading...</MenuItem>

    if(props.category === null) {
        return <div key="error" style={{color: red["800"]}}>Please choose a category first</div>
    }

    let cat = categories.find(v => v.id === props.category)
    while(cat && cat.parentCategory) {
        cat = categories.find(v => v.id === cat?.parentCategory)
    }

    if(!cat) {
        return <div key="error" style={{color: red["800"]}}>Internal error: can't find category</div>
    }

    const group = options.find(v => v.category === cat?.name);
    const index = group?.options.findIndex(v => v.name === props.value);
    const value = index === -1 || index === undefined ? props.value : index.toString();

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="hw-prep">HW Prep</InputLabel>

                <Select labelId="hw-prep" size="small" fullWidth label="HW Prep"
                        value={value}
                        onChange={e => {
                            const value = e.target.value as any as string;
                            if(value === customPrep) {
                                props.onChange({
                                    prepKey: PrepKey.AllCustom,
                                    name: customPrep,
                                    framePrep: "**As per template**",
                                    framePrepIsCustom: true,
                                    activeDoorPrep: "**As per template**",
                                    activeDoorPrepIsCustom: true,
                                    inactiveDoorPrep: "**As per template**",
                                    inactiveDoorPrepIsCustom: true,
                                })
                            } else if(value === noPrep) {
                                props.onChange(noPrepOpt)
                            } else {
                                const v = parseInt(value)
                                if(!group) return;
                                const opt = group.options[v]
                                props.onChange(opt)
                            }
                        }}>
                    <MenuItem value={noPrep}>No Prep</MenuItem>
                    {group && <Divider />}
                    {group?.options.map((o, index) => <MenuItem key={cat?.id + o.name} value={index.toString()}>
                        {o.name}
                    </MenuItem>)}
                    {group && <Divider />}
                    <MenuItem value={customPrep}>Custom</MenuItem>
                </Select>
            </FormControl>
        </>
    );
}
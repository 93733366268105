import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {getSectionName} from "./BeforeGroup";
import {useContext, useEffect, useState} from "react";
import {formatCents, parseCents, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {TableContext} from "../../../../misc/scroller/Table";
import {Loader} from "../../../../misc/Loader";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useIsQuote} from "../../quote/QuoteContainer";
import {useAlternative} from "../../quote/alternative/Alternative";
import {SectionName} from "../../../../api/QuotePricing";

export function SectionTotal(props: {
    changeIds: number[]
    leaveIds: number[]
    project: number;
    section: SectionName;
    value: number;
    onDone(): void;
}) {
    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(formatCents(props.value));
    }, [props.value]);

    const onDoneRef = useSyncedRef(props.onDone);
    const tbl = useContext(TableContext)
    const tblReloadRef = useSyncedRef(tbl.reload);
    const isQuote = useIsQuote();

    const action = useAsyncAction(async (input) => {
        if(isNaN(input.totalPriceCents)) {
            throw new Error("Invalid total price");
        }

        if(isQuote) {
            await api.quotePricing.scaleSection(input);
        } else {
            await api.pricing.scaleSection(input);
        }

        onDoneRef.current();
        tblReloadRef.current(true);
    }, []);

    const alternative = useAlternative();

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault();
                action.callback({
                    changeIds: isQuote ? undefined : props.changeIds,
                    leaveIds: isQuote ? undefined : props.leaveIds,
                    project: props.project,
                    alternative: alternative,
                    type: props.section,
                    totalPriceCents: parseCents(value),
                })
            }}>
                <DialogTitle>
                    Bulk Adjust {getSectionName(props.section)} Markup
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Adjusting this will adjust each {props.section} item's markup proportionally to make the total price ${value}.
                    </DialogContentText>
                    <div style={{height: 16}} />
                    <TextField label="Total Price" value={value} onChange={e => setValue(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Loader {...action} />
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import React, {useContext} from "react";
import {Product} from "../../../../../api/Products";
import {EditProductContext} from "../EditProduct";
import {ChangePreviewContext} from "../../ShopDrawingChangePreview";
import {DimensionedQty} from "../../../../../api/Hardware";

export function ProductDimensioned(props: {
    product: Product;
} | {
    id: number;
    show: boolean;
    dimensions?: DimensionedQty
}) {
    const show = "show" in props ? props.show : ("product" in props) && (props.product.dimHeightFormula || props.product.dimLengthFormula || props.product.dimWidthFormula)
    const ctx = useContext(EditProductContext)
    const id = "product" in props ? props.product.id : props.id
    const locked = useContext(ChangePreviewContext).enabled;

    if(show) {
        let dims: string | null = null;
        if("dimensions" in props && props.dimensions && props.dimensions.filter(v => !!v.dimension).length > 0) {
            dims = props.dimensions.filter(v => !!v).map(d => d.dimension).join(", ")
        }

        return (
            <>
                {dims ? <span style={{borderBottom: "1px solid", cursor: locked ? undefined : "pointer"}} onClick={() => {
                    if(locked) return;
                    ctx.show(id, true)
                }}>{dims}</span> : <span style={{borderBottom: "1px solid", cursor: locked ? undefined : "pointer"}} onClick={() => {
                    if(locked) return;
                    ctx.show(id, true)
                }}>Yes</span>}
            </>
        )
    }

    return <></>
}
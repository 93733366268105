import {hasPermission, useUser} from "../../../../misc/Permission";
import {
    Table,
} from "../../../../misc/scroller/Table";
import React, {useContext} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize2} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {
    AddRider,
    commonGroupBy2,
    commonPricingColumns2,
    PriceCol,
    PriceRow,
    RiderRowTitle, ViewPriceCol
} from "../../shopdrawing/pricing/Frames";
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import {DoorSectionProvider} from "../../shopdrawing/pricing/DoorSectionProvider";
import {getQuotePricing, quotePriceChange} from "./QuotePriceRiderUtil";

export function QuoteDoors(props: {
    type: "hm" | "wd" | "other"
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();
    const customize = usePriceCellCustomize2();

    if(!u) return null;
    const isWood = props.type === "wd";

    return (
        <DoorSectionProvider value={props.type}>
            <Table<PriceRow>
                key={props.type}
                name={priceTableName(project, "door", props.type)}
                globalPrefsName={priceTableName(0, "door", props.type)}
                locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
                cellCustomize={customize}
                fetch={(ctx) => getQuotePricing(ctx, {
                    project: project,
                    alternative: alternative,
                })}
                columns={[
                    PriceCol({
                        name: "Series",
                        price: (dt: QuotePriceLine) => {
                            if(dt.productType === "door-prep") return `${dt.productName}: ${dt.productCode}`;
                            return dt.door.series;
                        },
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),
                    ViewPriceCol("Material", "door.material"),
                    isWood ? ViewPriceCol("Finish", "door.finish") : null,
                    ViewPriceCol("Gauge", "door.gauge"),
                    ViewPriceCol("Elevation", "door.elevation"),
                    ViewPriceCol("Core", "door.core"),
                    ViewPriceCol("Seam", "door.seam"),
                    ViewPriceCol("Handing", "door.handing"),
                    ViewPriceCol("Label", "door.label"),
                    ViewPriceCol("Height", "door.height"),
                    ViewPriceCol("Width", "door.width"),
                    ViewPriceCol("Thickness", "door.thickness"),
                    ...commonPricingColumns2(u, true),
                ]}
                fetchDeps={[project, alternative]}
                columnDeps={[u, props.type]}
                groupBy={Object.assign({}, commonGroupBy2, {
                    groupFilter: (groupName: string) => {
                        return groupName === "door-" + props.type || groupName === "project" || groupName === "door-totals" || groupName.startsWith("door-prep/" + props.type)
                    },
                })}
                onChange={input => quotePriceChange(input, {
                    project: project,
                    alternative: alternative
                })}
            />
        </DoorSectionProvider>
    )
}

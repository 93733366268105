import {pdfTableStyle} from "../../../../../misc/pdf/parts/Page";
import {useContext} from "react";
import {PDFProjectContext} from "../../openings/OpeningsPDF";
import {KeyName} from "../../../../../api/Keying";
import {first} from "nate-react-api-helpers";
import {KeyingPDFInfo} from "./KeyingSubmittal";
import {css} from "@emotion/css";
import {ContainerPage} from "../../../../../misc/pdf/parts/ContainerPage";
import {TitleTextBlock} from "../../../../../misc/pdf/parts/TitleTextBlock";

export function Legend(props: {
    names: KeyName[];
    info: KeyingPDFInfo;
    noProject?: boolean
}) {
    const project = useContext(PDFProjectContext);

    return (
        <ContainerPage>
            <div>
                <TitleTextBlock title={
                    props.noProject ?
                        "KEYING DETAIL":
                        project.name + " - " + project.addressCity + ", " + project.addressProvince  + "- KEYING SCHEDULE"}>
                </TitleTextBlock>
            </div>

            <table className={pdfTableStyle + " " + legendStyle}>
                <tbody>
                <tr>
                    <th style={{textAlign: "left"}} colSpan={2}>KEYING DESIGNATION DESCRIPTIONS:</th>
                    <th># OF KEYS INCLUDED</th>
                </tr>
                {props.names.map(v => <tr>
                    <th>{v.short}</th>
                    <td>{v.description}</td>
                    <td>{first(props.info.keyCount, k => k.keyNameId === v.id)?.value || null}</td>
                </tr>)}
                </tbody>
            </table>
        </ContainerPage>
    )
}

const legendStyle = css({
    "& th:first-child": {
      borderLeft: "none",
    },
    "& td:first-child": {
      borderLeft: "none",
    },

    "& th:last-child": {
        borderRight: "none",
    },
    "& td:last-child": {
      borderRight: "none",
    },

    "& th, & td": {
        textAlign: "center",
    },

    "& tr:first-child th": {
        borderTop: "1px solid",
    }
})
import {FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PermissionInfo} from "../../api/Users";
import {PermissionsOverride} from "./PermissionsOverride";

export function useRoleAndOverride(props: {
    role: string;
    permissionOverrides: PermissionInfo[];
}) {

    const [role, setRole] = useState(props.role)
    const [permOverride, setPermOverride] = useState<PermissionInfo[]>([]);

    useEffect(() => {
        setRole(props.role)
    }, [props.role])

    useEffect(() => {
        setPermOverride(props.permissionOverrides)
    }, [props.permissionOverrides])

    const Role = (
        <FormControl fullWidth size="small">
            <InputLabel>Role</InputLabel>
            <Select label="Role" value={role} onChange={e => {
                setPermOverride([]);
                setRole(e.target.value)
            }}>
                <MenuItem value="user">
                    <ListItemText
                        primary="User"
                        secondary="Can only view limited financial information and cannot override actions."
                    />
                </MenuItem>
                <MenuItem value="admin">
                    <ListItemText
                        primary="Admin"
                        secondary="Can do anything in the system including viewing financials and overriding actions."
                    />
                </MenuItem>
            </Select>
        </FormControl>
    )

    const Overrides = (
        <PermissionsOverride initialRole={role} value={permOverride} onChange={setPermOverride} />
    )

    return {
        role,
        permOverride,
        Role,
        Overrides,
    }
}
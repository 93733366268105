import {Tab, Tabs} from "@mui/material";
import React from "react";
import {TabWrapper} from "../project/shopdrawing/release/Release";

export function TabSection(props: {
    value: string;
    onChange(value: string): void;
    right?: any;
}) {
    const tab = props.value;
    const setTab = props.onChange;
    return (
        <TabWrapper>
            <Tabs value={tab} onChange={(_, e) => setTab(e)}>
                <Tab value="contractor" label="GCs" />
                <Tab value="client" label="Owners" />
            </Tabs>
            <div style={{flex: 1}} />
            {props.right}
            <div style={{width: 4}} />
        </TabWrapper>
    )
}
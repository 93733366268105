import {forwardRef} from "react";
import {useUser} from "../../../../misc/Permission";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {PDFProjectContext} from "../../shopdrawing/openings/OpeningsPDF";
import {Company} from "../../../../api/Companies";
import {Project} from "../../../../api/Projects";
import {CoverPage} from "../../../../misc/pdf/parts/CoverPage";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";

export const WRAccessoryCutSheetPDF = forwardRef(function (props: {
    project: Project;
    client?: Company;
    lastSubmittal: string|null;
    onReady(): void;
}, ref) {
    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    return (
        <div ref={ref as any}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <CoverPage
                        client={props.client}
                        title="WASHROOM ACCESSORIES - CUT SHEET SUBMITTAL"
                        lastSubmittal={props.lastSubmittal}
                    />
                </PDFProjectContext.Provider>
            </PDF>
        </div>
    )
});
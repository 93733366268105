import {ChangesetContainer} from "../changeset/ChangesetContainer";
import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid} from "@mui/material";
import {AutoComplete} from "../openings/AutoComplete";
import {api} from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {Opening} from "../../../../api/Openings";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useNavigate} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {ChangesetContext} from "../changeset/ChangesetContext";

export function StartReviewReleasedChanges() {
    return (
        <ChangesetContainer stage={"start"} backgroundColor={grey["200"]}>
            <StartInner />
        </ChangesetContainer>
    )
}

function StartInner() {
    const [doors, setDoors] = useState(false)
    const [frames, setFrames] = useState(false)
    const [hardware, setHardware] = useState(false)
    const [div10, setDiv10] = useState(false)
    const [opening, setOpening] = useState<Opening[]>([])
    const project = useProjectId();

    const result = useAsync2((input) => {
        return api.projectChangeset.recalculateProducts(input)
    }, {
        project: project,
    }, [project])

    const deleteSession = useAsyncAction(async (input) => {
        await api.projectChangeset.cancelReleasedSession(input)
        nav("/project/" + project + "/shop-drawing")
    }, []);

    const loadOpeningSuggestion = useAsyncAction(async (input) => {
        const list = await api.projectChangeset.listProductChangesByOpening(input)
        setOpening(list)
    }, []);

    const productsCalculated = !!result.result
    const loadOpeningSuggestionCallback = loadOpeningSuggestion.callback;
    useEffect(() => {
        if(!productsCalculated) return;
        loadOpeningSuggestionCallback({
            project: project,
        })
    }, [project, productsCalculated, loadOpeningSuggestionCallback]);

    const nav = useNavigate();
    const changeCtx = useContext(ChangesetContext)

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <Card style={{padding: 24, backgroundColor: "white", marginTop: 16}}>
                <div style={{fontWeight: "500"}}>
                    What did you want to change? (select all that apply)
                </div>

                <div>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={doors} onChange={(_, checked) => setDoors(checked)} />} label="Doors" />
                        <FormControlLabel control={<Checkbox checked={frames} onChange={(_, checked) => setFrames(checked)} />} label="Frames" />
                        <FormControlLabel control={<Checkbox checked={hardware} onChange={(_, checked) => setHardware(checked)} />} label="Hardware" />
                        <FormControlLabel control={<Checkbox checked={div10} onChange={(_, checked) => setDiv10(checked)} />} label="Div 10" />
                    </FormGroup>
                </div>

                <div style={{height: 32}} />

                <div>
                    <div style={{fontWeight: "500"}}>
                        Filter by specific opening
                    </div>

                    <Grid container spacing={1}>
                        {loadOpeningSuggestion.loading && loadOpeningSuggestion.LoadingElement}
                        {opening.length === 0 ? <Grid item><Chip disabled label="All openings" /></Grid> : null}
                        {opening.map(op => <Grid item key={op.id}>
                            <Chip
                                label={op.name}
                                onDelete={() => setOpening(opening.filter(o => o.id !== op.id))}
                            />
                        </Grid>)}
                    </Grid>

                    <div style={{height: 8}} />

                    <AutoComplete<Opening>
                        label="Search for Opening"
                        search={async input => {
                            const result = await api.openings.list({
                                project: project,
                                search: input,
                                offset: 0,
                            })

                            return result.data;
                        }}
                        value={null}
                        equals={(a, b) => a.id === b.id}
                        makeLabel={a => a.name}
                        onSelect={v => setOpening([...opening, v])}
                        size="small"
                    />
                </div>
                <div style={{marginTop: 24}}>

                    <div>
                        {result.loading &&<div style={{maxWidth: 350}}>
                            Recalculating Products... Please wait for this to complete before proceeding to the next step.
                        </div>}
                        {result.LoadingOrErrorElement}
                    </div>

                    <div style={{display: "flex"}}>
                        <Button color="error" onClick={() => {
                            deleteSession.callback({
                                project: project,
                            })
                        }}>Delete Session</Button>
                        {deleteSession.LoadingElement}
                        <div style={{flex: 1}} />
                        <Button onClick={() => nav(`/project/${project}/shop-drawing`)}>Back</Button>
                        <Button variant="contained" color="primary" disabled={result.loading} onClick={() => {
                            const params = new URLSearchParams();
                            if(doors) params.set("doors", "true")
                            if(frames) params.set("frames", "true")
                            if(hardware) params.set("hardware", "true")
                            if(div10) params.set("div10", "true")
                            if(opening.length > 0) {
                                params.set("opening", opening.map(op => op.id).join(","))
                            }

                            nav(`/project/${project}/shop-drawing/change-released/${changeCtx.sessionId}/review-products?` + params.toString());
                        }}>Next</Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import React, {useContext, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {GetOrCreateManufacturer} from "../../../project/DefaultManufacturer";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {getAll} from "../../../project/shopdrawing/openings/Openings";
import {PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import {PriceRequestPDF} from "./PriceRequestPDF";

export function PriceRequestBtn() {
    const [show, setShow] = useState(false);
    const [mfg, setMfg] = useState<any>(null);

    const ctx = useContext(PrintManagerContext);

    const action = useAsyncAction(async (input: {
        manufacturer: number
    }) => {
        const products = await getAll(null, offset => api.products.list({
            manufacturer: input.manufacturer,
            offset: offset,
            forProductSettings: true,
        }))

        setShow(false);
        ctx.print(`price-request.pdf`, async onReady => <PriceRequestPDF products={products} onReady={onReady} />)

    }, []);

    return (
        <>
            <SmallGreyButton onClick={() => setShow(true)} startIcon={<AddCircleIcon />}>
                Price Request
            </SmallGreyButton>
            {show && <Dialog open onClose={() => setShow(false)}>
                <form onSubmit={e => {
                    e.preventDefault()

                    if(mfg) {
                        action.callback({
                            manufacturer: mfg
                        })
                    }
                }}>
                    <DialogTitle>
                        Price Request
                    </DialogTitle>
                    <DialogContent>
                        <div style={{paddingBottom: 4}}>Choose a manufacturer:</div>
                        <GetOrCreateManufacturer selected={mfg} setSelected={setMfg} create={""} setCreate={() => {}} selectOnly />
                    </DialogContent>
                    <DialogActions>
                        {action.LoadingElement}
                        <Button onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="contained" type="submit" disabled={!mfg}>Export</Button>
                    </DialogActions>
                </form>
            </Dialog>}
        </>
    )
}
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import {useMemo, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {Product} from "../../api/Products";
import {FindOrCreateCompany} from "../project/manage/summary/FindOrCreateCompany";
import {Company} from "../../api/Companies";
import {DimensionInput2} from "./DimensionInput2";
import {
    doorCore,
    doorFinish,
    doorGauge,
    doorMaterial, doorSeam,
    doorSeries,
    doorThicknesses, handingOptions, labels, openingTypes
} from "../project/quote/openings/QuoteOpenings";
import {SelectFiltered} from "./SelectFiltered";
import {dummySelectWrapper} from "../../misc/scroller/Table";

export function DoorBuilder(props: {
    onDone(v: Product): void;
    onCancel(): void;
}) {
    const [core, setCore] = useState("");
    const [elevation, setElevation] = useState("");
    const [finish, setFinish] = useState("");
    const [gauge, setGauge] = useState("");
    const [handing, setHanding] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [label, setLabel] = useState("");
    const [material, setMaterial] = useState("");
    const [series, setSeries] = useState("");
    const [thickness, setThickness] = useState("");
    const [seam, setSeam] = useState("");
    const [openingType, setOpeningType] = useState("")

    const act = useAsyncAction(async (input) => {
        const p = await api.products.buildDoorSpec(input)
        props.onDone(p)
    }, [])

    const [company, setCompany] = useState<Company|null>(null);

    const sys = useMemo(() => {
        if(width.toLowerCase().trim().endsWith("mm")) {
            return "metric"
        }

        return undefined;
    }, [width])

    console.log("company", company)

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault()
                act.callback({
                    manufacturer: company?.id || 0,
                    core,
                    elevation,
                    finish,
                    gauge,
                    handing,
                    height,
                    width,
                    label,
                    material,
                    series,
                    thickness,
                    seam
                })
            }}>
                <DialogTitle>Door Builder</DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <FindOrCreateCompany value={company} kind={"supplier"} label={"Manufacturer Name"}
                                         onChange={setCompany}/>

                    <div style={{height: 8}}/>

                    <Grid container spacing={1} flexDirection="row">
                        <Grid item xs={4}>
                            <DimensionInput2 label="Width" value={width} onChange={setWidth}/>
                        </Grid>
                        <Grid item xs={4}>
                            <DimensionInput2 label="Height" value={height} onChange={setHeight}/>
                        </Grid>
                    </Grid>

                    <div style={{height: 8}}/>

                    <Grid container spacing={1} flexDirection="row">
                        <Grid item xs={4}>
                            <SelectFiltered label="Opening Type" value={openingType} onChange={setOpeningType}
                                            options={openingTypes.map(v => ({value: v, display: v}))}/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Handing" value={handing} onChange={setHanding}
                                            options={handingOptions(dummySelectWrapper({
                                                openingType,
                                            }))}/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Label" value={label} onChange={setLabel}
                                            options={labels.map(v => ({value: v, display: v}))}/>
                        </Grid>
                    </Grid>

                    <div style={{height: 8}}/>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <SelectFiltered label="Series" value={series} onChange={setSeries}
                                            options={doorSeries.map(v => ({value: v, display: v}))}/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Material" value={material} onChange={setMaterial}
                                            options={doorMaterial(dummySelectWrapper({doorSeries: series}))}/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Finish" value={finish} onChange={setFinish}
                                            options={doorFinish(dummySelectWrapper({doorSeries: series}))}/>
                        </Grid>
                    </Grid>

                    <div style={{height: 8}}/>

                    <Grid container spacing={1} flexDirection="row">
                        <Grid item xs={4}>
                            <SelectFiltered label="Gauge" value={gauge} onChange={setGauge}
                                            options={doorGauge(dummySelectWrapper({doorSeries: series}))}/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Thickness" value={thickness} onChange={setThickness}
                                            options={doorThicknesses(sys).map(v => ({value: v, display: v}))}/>
                        </Grid>
                    </Grid>

                    <div style={{height: 8}}/>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField label="Elevation" value={elevation} onChange={e => setElevation(e.target.value)}
                                       fullWidth size="small"/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Core" value={core} onChange={setCore}
                                            options={doorCore(dummySelectWrapper({doorSeries: series}))}/>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFiltered label="Seam" value={seam} onChange={setSeam}
                                            options={doorSeam(dummySelectWrapper({doorSeries: series}))}/>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {act.LoadingElement}
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">Confirm</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
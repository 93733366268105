
export function poUrl(id: number) {
    return `/logistics/purchasing/${id}`
}

export function routeUrl(id: number) {
    return `/logistics/routes/${id}`
}

export function deliveryUrl(id: number) {
    return `/logistics/packing/${id}`
}
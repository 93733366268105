import {LibraryType} from "../../../../../api/Library";
import {createContext, PropsWithChildren, useContext, useMemo} from "react";

export function useHwLibraryType() {
    return useContext(HwLibraryTypeContext).value;
}

const HwLibraryTypeContext = createContext({
    value: "missing-hw-library-context" as LibraryType,
})

export function HwLibraryTypeProvider(props: PropsWithChildren<{value: LibraryType}>) {
    const ctx = useMemo(() => ({
        value: props.value,
    }), [props.value])

    return <HwLibraryTypeContext.Provider value={ctx}>
        {props.children}
    </HwLibraryTypeContext.Provider>
}
import {Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover} from "@mui/material";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useState} from "react";
import {NoteCustomizeDefault} from "./NoteCustomizeDefaults";

export function PriceSectionNoteDefault(props: {
    keyName: string;
    anchor: any;
    onClose(input: string[]): void;
}) {
    const list = useAsync2(async (input) => {
        const list = await api.priceSectionNotes.getDefaults({});
        return list.filter(v => v.keyName === input.keyName)
    }, {
        keyName: props.keyName,
    }, [props.keyName])

    const [value, setValue] = useState<string[]>([]);
    const [showCustomize, setShowCustomize] = useState(false);

    if(showCustomize) {
        return (
            <NoteCustomizeDefault keyName={props.keyName} value={list.asList} onDone={() => {
                list.reload();
                setShowCustomize(false);
            }} />
        );
    }

    return (
        <Popover open={true} anchorEl={props.anchor} onClose={() => {
            props.onClose(value)
        }}>
            <List style={{width: 600}}>
                {list.LoadingOrErrorElement}
                {list.asList.map(v => (
                    <ListItemButton selected={value.indexOf(v.note) !== -1} onClick={() => {
                        const checked = value.indexOf(v.note) === -1;
                        if(checked) {
                            setValue([...value, v.note])
                        } else {
                            setValue(value.filter(vv => vv !== v.note))
                        }
                    }}>
                        <ListItemIcon>
                            <Checkbox checked={value.indexOf(v.note) !== -1} />
                        </ListItemIcon>
                        <ListItemText primary={v.note} />
                    </ListItemButton>
                ))}
                {!list.loadingOrError && list.asList.length === 0 && <ListItem>
                    <ListItemText primary="No defaults found" />
                </ListItem>}
            </List>
            <div style={{padding: 8, display: "flex", justifyContent: "space-between"}}>
                <Button onClick={() => setShowCustomize(true)}>
                    Manage Defaults
                </Button>
                <Button variant="contained" size="small" color="primary" onClick={() => {
                    props.onClose(value);
                }}>Done</Button>
            </div>
        </Popover>
    )
}
import {QtyWithPreview} from "./QtyWithPreview";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import Clear from "@mui/icons-material/Clear";
import {RouteStopGroup} from "../../../api/Logistics";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {RefreshRouteItems} from "./RouteItems";
import {routePopupPanel} from "./RouteList";
import {Completed} from "./Completed";

export function DropDeliveryRow(props: {
    value: RouteStopGroup;
    routeCompleted: boolean;
    canRemove: boolean;
}) {
    const snack = useSnackbar();

    return (
        <tr>
            <td>Delivery from warehouse</td>
            <td>
                <QtyWithPreview data={props.value.items || []} />
            </td>
            <td>
                <Completed contents={props.value.items || []} routeComplete={props.routeCompleted} complete={props.value.completed} />
            </td>
            <td>
                <div className="actions">
                    {props.canRemove && <SimpleIconButton disabled={props.value.completed} onClick={async () => {
                        try {

                            await api.logistics.upsertStopGroup(Object.assign({}, props.value, {
                                archived: true,
                            }))

                        } catch (e: any) {
                            snack.error(e.toString())
                            return;
                        }

                        RefreshRouteItems.emit(null);
                        routePopupPanel.sendToClient("refresh-to-move")
                        snack.success("Updated")
                    }}>
                        <Clear />
                    </SimpleIconButton>}
                </div>
            </td>
        </tr>
    )
}
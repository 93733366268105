import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {SubmissionKind} from "../../api/Submissions";
import {useCallback, useEffect, useState} from "react";
import {useSyncedRef} from "../SyncedRef";

export function useSubmittalPreferences<T>(props: {
    project: number;
    kind: SubmissionKind;
    onBlank?: () => void,
    onLoad: (input: T) => void,
    onLoadDeps?: any[];
}) {

    const [lastSubmission, setLastSubmission] = useState<string|null>(null);
    const result = useAsync2((input) => {
        return getSubmittalPreferences<T>(input)
    }, {project:props.project, kind: props.kind}, [props.project, props.kind]);

    const onLoadRef = useSyncedRef(props.onLoad)
    const onLoadDeps = props.onLoadDeps || [];

    const onBlankRef = useSyncedRef(props.onBlank);

    useEffect(() => {
        if(!result.result) return;

        setLastSubmission(result.result.lastSubmission);

        try {
            if(result.result.value !== null) {
                onLoadRef.current(result.result.value)
            } else if(onBlankRef.current) {
                onBlankRef.current()
            }
        } catch (e: any) {
            console.error(e);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.result, onLoadRef, ...onLoadDeps])

    const save = useCallback(async (input: T) => {
        await api.submission.setPreferences({
            project: props.project,
            kind: props.kind,
            value: JSON.stringify(input),
        });
    }, [props.kind, props.project])

    return {
        lastSubmission,
        loading: result.loading,
        save
    }
}

export async function getSubmittalPreferences<T>(input: {
    project: number
    kind: SubmissionKind;
}) {
    const prefs = await api.submission.getPreferences(input);
    try {
        const value = JSON.parse(prefs.value);
        return {
            lastSubmission: prefs.lastSubmission,
            value: value as T,
        };
    } catch (e: any) {
        console.error(e);
        return {
            lastSubmission: prefs.lastSubmission,
            value: null,
        }
    }
}
import {Alert, Button, LinearProgress} from "@mui/material";
import React from "react";

export function AsyncHelper<T>(props: {
    loading: boolean;
    error: string|null;
    reload: () => any;
    result?: T | null;
    children: (input: T) => any;
}) {

    if(props.loading) {
        return <LinearProgress variant="indeterminate" />
    }

    if(props.error|| !props.result) {
        return (
            <Alert color="error">
                <div>{props.error || "No Data"}</div>
                <div><Button onClick={() => props.reload()}>Retry</Button></div>
            </Alert>
        );
    }

    return props.children(props.result);
}
import {PurchaseOrder, PurchaseOrderItem} from "../../../api/Logistics";
import {
    CentsCol, Column, FreeCell, GroupProps,
    NumberCol,
    StringCol,
    Table,
} from "../../../misc/scroller/Table";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {ItemsFooter} from "./ItemsFooter";
import {byFixedOrder} from "../../project/shopdrawing/pricing/Pricing";
import {formatCents, sum} from "nate-react-api-helpers";
import {css} from "@emotion/css";
import {useContext} from "react";
import {EditContext} from "../../../misc/scroller/EditProvider";
import {useSnackbar} from "../../../misc/Snackbar";
import {grey} from "@mui/material/colors";
import {removeRowOption, RowOptionCol} from "../../logistics/purchasing/RowOptions";

export const payableItemsTableName = "payable.payable-items"

export function Items(props: {
    order: PurchaseOrder;
}) {
    return (
        <>
            <Table<PurchaseOrderItem>
                name={payableItemsTableName}
                columns={[
                    RowOptionCol({
                        options: (dt: PurchaseOrderItem) => [
                            !!dt.product ? {name: "Nothing here...", onClick: () => {}} : removeRowOption(dt, {
                                refreshTables: [payableItemsTableName],
                            })
                        ],
                    }),
                    StringCol("Ref", "reference", 35),
                    EditDisabledIfLinked(StringCol("Description", "description", 200)),
                    EditDisabledIfLinked(StringCol("Code", "code", 80)),
                    EditDisabledIfLinked(NumberCol("Qty", "qty", 60)),
                    CentsCol("Unit Amount", "unitPriceCents"),
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listPurchaseOrderItems({
                        offset,
                        type: "payable",
                        purchaseOrder: props.order.id,
                    }))}
                fetchDeps={[props.order.id]}
                groupBy={{
                    groupFx: row => row.product ? "products" : "extras",
                    fixedGroups: ["products", "extras"],
                    groupSorter: byFixedOrder(["products", "extras"]),
                    beforeGroup: BeforeGroup,
                    afterGroup: p => AfterGroup(p, props.order),
                }}
                onChange={v => api.logistics.updatePurchaseOrderItem(v)}
                noHorizontalScroller
                noFooter
            />
            <ItemsFooter value={props.order} />
        </>
    )
}

function EditDisabledIfLinked(input: Column<PurchaseOrderItem>) {
    return Object.assign(input, {
        disabled: (rw: PurchaseOrderItem) => !!rw.product,
    })
}

function BeforeGroup(props: GroupProps<PurchaseOrderItem>) {
    if(props.name === "products") {
        return (
            <>
                <FreeCell index={0} grow>
                    <div style={{fontWeight: 'bold'}}>Tracked Products</div>
                </FreeCell>
            </>
        )
    }

    return (
        <>
            <FreeCell grow index={0}>
                <div style={{fontWeight: 'bold'}}>Extras</div>
            </FreeCell>
        </>
    )
}

function AfterGroup(props: GroupProps<PurchaseOrderItem>, order: PurchaseOrder) {
    const table = useContext(EditContext);
    const sn = useSnackbar();

    return (
        <>
            <FreeCell index={0} colSpan={2} noPadding>
                {props.name === "extras" && <button onClick={async () => {
                    try {
                        sn.loading();
                        await table.update({
                            purchaseOrder: order.id,
                        } as PurchaseOrderItem);
                        sn.hide();
                    } catch(e: any) {
                        sn.error(e.toString());
                    }

                }} className={btnStyle}>Add Row</button>}
            </FreeCell>
            <FreeCell index={2} colSpan={2}>
                <div style={{fontWeight: 'bold'}}>Total</div>
            </FreeCell>
            <FreeCell index={4}>
                <div style={{textAlign: "right"}}>
                    {sum(props.rows.map(p => p.qty))}
                </div>
            </FreeCell>
            <FreeCell index={5} grow>
                <div style={{textAlign: "right"}}>
                    {formatCents(sum(props.rows.map(p => p.qty * p.unitPriceCents)))}
                </div>
            </FreeCell>
        </>
    )
}

const btnStyle = css({
    background: "transparent",
    border: "1px solid",
    borderRadius: 4,
    color: "black",
    width: "100%",
    textAlign: "center",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: grey["200"]
    }
} as any)
import {useProjectId} from "../../shopdrawing/ProjectName";
import React, {useContext, useRef} from "react";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {useAlternative} from "../alternative/Alternative";
import {sumPriceLines} from "../../shopdrawing/pricing/AfterGroup";
import {CentsCol, FreeCell, LongStringCol, StringCol, Table,} from "../../../../misc/scroller/Table";
import {
    byFixedOrder,
    isNullOrUndefined,
    MarkupEditCell,
    parseMarkup,
    priceTableName, profit
} from "../../shopdrawing/pricing/Pricing";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";
import {MiscPrice} from "../../../../api/MiscPricing";
import {formatCents, sum} from "nate-react-api-helpers";
import {PriceLine} from "../../../../api/Pricing";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {MiscPricingAutoInsert} from "./MiscPricingAutoInsert";
import {removeRowOption, RowOptionCol} from "../../../logistics/purchasing/RowOptions";

export function QuoteMiscPricing() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();

    const otherPricesRef = useRef<(PriceLine[] | QuotePriceLine[])>([])
    const miscPricesRef = useRef<MiscPrice[]>([])

    const tableName = priceTableName(project, "misc")

    if(!u) return null;

    return (
        <Table<MiscPrice>
            key="misc-price"
            name={tableName}
            globalPrefsName={priceTableName(0, "misc")}
            locked={(locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager"))}
            fetch={async (ctx) => {
                const rs = await getAll(ctx, offset => api.quoteMiscPricing.list({project: project, alternative, offset}))
                miscPricesRef.current = rs;

                otherPricesRef.current = await getAll(ctx, offset => api.quotePricing.list({project, alternative, offset}))
                return rs;
            }}
            columns={[
                !locked && RowOptionCol({
                    options: (dt) => [
                        removeRowOption(dt, {
                            refreshTables: [tableName],
                            onComplete: () => {}
                        })
                    ],
                }),
                StringCol("Name", "name"),
                CentsCol("Unit Cost", "unitCostCents"),
                {
                    name: "Markup",
                    render: dt => {
                        if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                        if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                        return "auto"
                    },
                    editable: {
                        type: "custom",
                        copy: dt => {
                            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                            return "auto"
                        },
                        paste: (rw, value) => {
                            Object.assign(rw, parseMarkup(value))
                        },
                        render: MarkupEditCell,
                    },
                    alignRight: true,
                    width: 80,
                },
                CentsCol("Total", "extendedPrice"),
                hasPermission(u, "CanViewProjectPricingProfit") && {
                    name: "Proj. Profit",
                    render: dt => {
                        return formatCents(profit(dt))
                    },
                    alignRight: true,
                    width: 90,
                },
                {
                    name: "Proj. Margin",
                    render: dt => {
                        let profit = dt.extendedPrice - dt.unitCostCents * 1;
                        if(profit === 0) return "0%"
                        let margin = Math.round(profit / dt.extendedPrice * 100);
                        return margin + "%"
                    },
                    alignRight: true,
                    width: 100,
                },
                LongStringCol("Public Note", "publicNote", 400),
                StringCol("Internal Note", "internalNote", 400),
            ]}
            groupBy={{
                groupFx: (input: MiscPrice) => content, // everything is 1 big group
                fixedGroups: [content, total],
                groupSorter: byFixedOrder([content, total]),
                afterGroup: (input) => {
                    const subTotal = sum(miscPricesRef.current.map(a => a.extendedPrice));
                    const otherPrices = sumPriceLines(otherPricesRef.current);

                    if(input.name === total) {
                        return (
                            <>
                                <FreeCell index={0} colSpan={4}>
                                    <b>
                                        Total
                                    </b>
                                </FreeCell>
                                <FreeCell index={4}>
                                    <div style={{textAlign: "right"}}>
                                        {formatCents(otherPrices + subTotal)}
                                    </div>
                                </FreeCell>
                            </>
                        )
                    } else if(input.name === content) {
                        return (
                            <>
                                <FreeCell index={0} colSpan={4}>
                                    <b>
                                        Sub Total
                                    </b>
                                </FreeCell>
                                <FreeCell index={4}>
                                    <div style={{textAlign: "right"}}>
                                        {formatCents(subTotal)}
                                    </div>
                                </FreeCell>
                            </>
                        )
                    }
                }
            }}
            fetchDeps={[project, alternative]}
            onChange={(input) => api.quoteMiscPricing.upsert(input as any)}
            insert={{
                buttonText: "New Row",
                alignX: "left",
                modal: input => <MiscPricingAutoInsert onDone={input} />,
            }}
        />
    )
}

const content = "content-group"
const total = "total-group"
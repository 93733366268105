import {FormControl, InputLabel, Select, SelectProps} from "@mui/material";
import React, {useState} from "react";

let id = 1;

export function Select3(props: SelectProps) {
    const [labelId] = useState(() => "select-label-" + (id++));

    return (
        <FormControl fullWidth={props.fullWidth}>
            <InputLabel id={labelId}>{props.label}</InputLabel>
            <Select {...props} />
        </FormControl>
    )
}
import React, {useContext, useEffect, useRef, useState} from "react";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {Opening} from "../../../../api/Openings";
import {red} from "@mui/material/colors";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {TableClickBoundary} from "../../../../misc/scroller/TableClickBoundary";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {SimpleIconButton} from "../../../../misc/lists/SimpleIconButton";

export function AnchorQty(props: {
    data: Opening | QuoteOpening
}) {
    const data = props.data;
    const [show, setShow] = useState(false);

    return (
        <>
            <SimpleIconButton style={{marginTop: -2, marginBottom: -2}} onClick={(e) => {
                        e.event.stopPropagation();
                        setShow(true)
                }}>
                    {data.frameAnchorQtyOverride ? `${data.frameAnchorQtyOverride}` : <MoreHoriz />}
            </SimpleIconButton>
            {show && <EditAnchorQty data={props.data} onClose={() => setShow(false)} />}
        </>
    )
}

function EditAnchorQty(props: {
    onClose(): void;
    data: Opening | QuoteOpening
}) {
    const ctx = useContext(EditContext)
    const ctxRef = useRef(ctx);
    useEffect(() => {
        ctxRef.current.onSelect.emit(null)
    }, []);


    const updateRef = useRef(ctx.update)
    updateRef.current = ctx.update;

    const closeRef = useRef(props.onClose)
    closeRef.current = props.onClose;

    const [qty, setQty] = useState(props.data.frameAnchorQtyOverride !== null ? props.data.frameAnchorQtyOverride.toString() : "")

    const update = useAsyncAction(async (input: any) => {
        await updateRef.current(input);
        closeRef.current();
    }, [])

    return (
        <Dialog open onClose={props.onClose}>
            <TableClickBoundary>
                <form onSubmit={e => {
                    e.preventDefault();

                    let qty2 = qty ? parseInt(qty) : null;

                    update.callback(Object.assign({}, props.data, {
                        frameAnchorQtyOverride: qty2,
                    }))
                }}>
                    <DialogTitle>
                        Override Anchor Qty
                    </DialogTitle>
                    <DialogContent>
                        <div style={{height: 6}} />
                        <TextField fullWidth label={"Anchor Qty Override"} value={qty} onChange={e => setQty(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <div style={{display: "flex"}}>
                            <Button style={{color: red["800"]}} onClick={() => {
                                update.callback(Object.assign({}, props.data, {
                                    frameAnchorQtyOverride: null
                                }))
                            }}>Remove Override</Button>
                            <div style={{flex: 1}} />

                            {update.LoadingElement}
                            <Button onClick={props.onClose}>Cancel</Button>
                            <Button variant="contained" type="submit">Update</Button>
                        </div>
                    </DialogActions>
                </form>
            </TableClickBoundary>
        </Dialog>
    )
}
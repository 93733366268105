import { KeyOpening} from "../../../../api/Keying";
import {getDisplayProductForOpening} from "./Keying";
import {useContext, useEffect} from "react";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {useSyncedRef} from "../../../../misc/SyncedRef";

export function DisplayKeyingAndAutoFix(props: {
    value: KeyOpening
}) {
    const table = useContext(EditContext);
    const updateRef = useSyncedRef(table.update)
    useEffect(() => {
        if(table.locked) return;
        if(props.value.productsAreSet) return;
        if(props.value.products.length > 0) return;

        const display = getDisplayProductForOpening(props.value)
        const products = props.value.allProducts.filter(v => v.name === display)
        if(products.length === 0) return;

        updateRef.current(Object.assign({}, props.value, {
            products: products,
        }));
    }, [props.value, updateRef, table.locked]);

    const value = (props.value.products || []).map(d => d.name).join(", ")

    return <>{value || <i>None</i>}</>
}
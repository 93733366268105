import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle, Divider, List, ListItemButton,
    ListItemIcon,
    ListItemText, Tab, Tabs
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {api} from "../../../../api/API";
import {EventEmitter, first, sum, distinct} from "nate-react-api-helpers";
import {useProject, useProjectId} from "../../shopdrawing/ProjectName";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";
import {commonGroupBy} from "../../shopdrawing/pricing/Frames";
import {MakePriceTable, PriceKind, PriceRequestPDF} from "./PriceRequestPDF";
import {Div10TypeToName} from "../../shopdrawing/div10/Div10Tabs";
import {PriceLine} from "../../../../api/Pricing";
import {DoorTypeName} from "./DoorTabs";
import {AliasNeeded, AliasUpdate} from "./AliasNeeded";
import {ProjectContext} from "../../ProjectProvider";
import {usePrintParallel} from "../../shopdrawing/changeset/PrintParallel";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {downloadCSV} from "../../../logistics/purchasing/csvutil";
import {SubmissionKind} from "../../../../api/Submissions";

export function PriceRequestSubmittal(props: {
    tab: string;
    subTab: string;
    overrideName?: string;
    submissionTypeOverride?: SubmissionKind
}) {
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState<string[]>([])
    const projectId = useProjectId();

    const propTab = props.tab === "summary" ? "frame" : props.tab;
    const [tab, setTab] = useState(propTab);

    useEffect(() => {
        setTab(propTab);
    }, [propTab])

    const data = EventEmitter.reactValue(priceDetailEmitter);
    const groups = useMemo(() => {
        type Item = {
            key: string;
            parent: string;
            name: string;
            items: (PriceLine|QuotePriceLine)[];
        }

        const obj: {[k: string]: Item} = {};
        let list: Item[] = [];

        (data || []).map(item => {
            const key = commonGroupBy.groupFx(item);
            if(!obj[key]) {
                obj[key] = {
                    key: key,
                    name: nameForKey(key),
                    parent: parentOfKey(key),
                    items: [],
                };
                list.push(obj[key]);
            }

            obj[key].items.push(item)
        });

        return list.filter(t => t.parent === tab);
    }, [data, tab]);

    const project = useProject();
    const [alias, setAlias] = useState<AliasUpdate>(null);
    const projectCtx = useContext(ProjectContext);
    const parrallelPrint = usePrintParallel();

    if(!project) return null;

    const onSubmit = async () => {
        if(alias && alias.updateNeeded) {
            let project = await api.projects.get(projectId);
            await api.projects.upsert(Object.assign({}, project, {
                nameAlias: alias.value,
            }));
            projectCtx.reload();
        }

        const project = await api.projects.get(projectId)

        let listsByKey: {[key: string]: {key: string; list: (PriceLine|QuotePriceLine)[]}} = {};

        groups.map(g => {
            if(g.items && checked.indexOf(g.key) !== -1) {
                if(listsByKey[g.key]) {
                    listsByKey[g.key].list.push(...g.items);
                } else {
                    listsByKey[g.key] = {
                        key: g.key,
                        list: g.items,
                    }
                }
            }
        })

        let kind: PriceKind;
        switch(tab) {
            default:
            case "hardware":
                kind = "hardware";
                break;
            case "div10":
                kind = first(checked, v => !!v) as PriceKind;
                kind = kind.replace("div-10", "div10") as PriceKind;
                break;
            case "door":
                kind = "door";
                break;
            case "frame":
                kind = "frame";
                break;
        }

        console.log("kind", kind, checked, groups);

        return {
            listsByKey,
            project,
            kind,
        }
    }

    return (
        <>
            <SmallGreyButton onClick={() => setShow(true)} startIcon={<AddCircleIcon />}>
                {props.overrideName || "Price Request"}
            </SmallGreyButton>
            {show && <Dialog open onClose={() => setShow(false)}>
                <DialogTitle>{props.overrideName || "Price Request"} Submittal</DialogTitle>

                <AliasNeeded project={project} onChange={a => setAlias(a)} />

                <Tabs value={tab} onChange={(e, value) => {
                    setTab(value)
                    setChecked([]);
                }}>
                    <Tab label="Frames" value="frame" />
                    <Tab label="Doors" value="door" />
                    <Tab label="Hardware" value="hardware" />
                    <Tab label="Div 10" value="div10" />
                </Tabs>
                <Divider />

                <List dense disablePadding>
                    {tab === "div10" && groups.length > 1 && <Alert color="warning">
                        Can only export 1 type of div10 item at a time
                    </Alert>}

                    {groups.map(c => {

                        return (<>
                            <ListItemButton
                                key={c.key}
                                onClick={e => {
                                    let updated = checked.slice(0);

                                    if(checked.indexOf(c.key) !== -1) {
                                        updated = updated.filter(val => val !== c.key);
                                    } else {
                                        if(tab === "div10") {
                                            // can only have 1 div10 sub-type at at time
                                            updated = [c.key];
                                        } else {
                                            updated.push(c.key);
                                        }
                                    }

                                    setChecked(updated);
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        size="small"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={checked.indexOf(c.key) !== -1}
                                    />
                                </ListItemIcon>
                                <ListItemText style={{margin: 0, paddingRight: 16}}
                                              primary={c.name}
                                              secondary={sum(c.items.map(v => v.qty)) + " items"}
                                />
                            </ListItemButton>
                    </>)})}
                </List>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={async () => {

                        const {listsByKey, kind} = await onSubmit();

                        for(let i in listsByKey) {
                            const obj = listsByKey[i];

                            const tbl = MakePriceTable({
                                kind: kind,
                                list: obj.list,
                                keyName: i,
                            });

                            downloadCSV((props.submissionTypeOverride || "price-request") + `-${obj.key}.csv`, tbl.toStringArray());
                        }

                        setShow(false);

                    }}>Download Excel</Button>
                    <div style={{flex: 1}} />
                    <Button onClick={() => setShow(false)}>Cancel</Button>
                    <Button size="small" variant="contained"
                            color="primary" startIcon={<CheckCircleIcon />}
                            onClick={async () => {

                                const print = (key: string, list: (PriceLine|QuotePriceLine)[]) => {
                                    const appendFiles = distinct(list
                                        .map(l => l.frame?.screenElevFile || 0)
                                        .filter(v => !!v) as number[])

                                    parrallelPrint.printer({
                                        name: (props.submissionTypeOverride || "price-request")  + ".pdf",
                                        render: async onReady => <PriceRequestPDF
                                            keyName={key}
                                            kind={kind}
                                            project={project}
                                            onReady={onReady}
                                            items={list}
                                        />,
                                        serverInfo: {
                                            submissionForProject: project.id,
                                            submissionType: props.submissionTypeOverride || "price-request",
                                            ignoreSubmissionDate: true,
                                            appendFiles: appendFiles,
                                        },
                                        download: true,
                                    })
                                }

                                const {listsByKey, project, kind} = await onSubmit();

                                for(let i in listsByKey) {
                                    const obj = listsByKey[i];
                                    print(obj.key, obj.list);
                                }

                                setShow(false);
                            }}
                    >Create and Download</Button>
                </DialogActions>
            </Dialog>}
        </>
    )
}

function parentOfKey(name: string) {
    if(name.startsWith("door-")) return "door"

    if(name.startsWith("hardware/")) return "hardware";
    if(name.startsWith("div-10/")) return "div10";
    
    console.log("parent", name)

    return name;
}

function nameForKey(name: string) {
    if(name.startsWith("door-")) {
        name = name.substring("door-".length);
        return DoorTypeName(name)
    }

    if(name.startsWith("hardware/")) {
        return name.substring("hardware/".length)
    }

    if(name.startsWith("div-10/")) {
        name = name.substring("div-10/".length)
        return Div10TypeToName(name)
    }

    return ucWords(name);
}

// capitalizes the first letter of each word
export function ucWords(input: string) {
    return input.split(" ").map(v => {
        if(v.length > 1) return v[0].toUpperCase() + v.slice(1)
        return v;
    }).join(" ");
}
import {ToSourceItem} from "../../../../api/Logistics";
import {SimpleIconButton} from "../../../../misc/lists/SimpleIconButton";
import Info from "@mui/icons-material/Info";
import {useState} from "react";
import {Popover} from "@mui/material";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import moment from "moment";

export function InfoIcon(props: {
    row: ToSourceItem;
}) {
    const [preview, setPreview] = useState<HTMLElement|null>(null);
    if(!props.row.projectMeta) return null;

    return (
        <>
            <SimpleIconButton
                onMouseEnter={(e: any) => {
                    setPreview(e.target as any);
                }}
                onMouseLeave={() => setPreview(null)}
            >
                <Info />
            </SimpleIconButton>

            {preview && <QtyPreview2 row={props.row} onClose={() => setPreview(null)} anchorEl={preview} />}
        </>
    )
}

export function QtyPreview2(props: {
    row: ToSourceItem;
    anchorEl: any;
    onClose(): void;
}) {
    const dl = props.row.projectMeta;

    return (
        <Popover open={true}
                 anchorEl={props.anchorEl}
                 sx={{
                     pointerEvents: 'none',
                 }}
                 disableRestoreFocus
                 onClose={props.onClose}
                 anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                 }}
                 transformOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}
        >
            <div style={{padding: 4}}>
                <table className={tableClass}>
                    <thead>
                    <tr>
                        <td>Project</td>
                        <td>Qty</td>
                        <td>Date</td>
                    </tr>
                    </thead>
                    <tbody>
                    {dl.map(c => <tr>
                        <td>{c.projectName}</td>
                        <td style={{textAlign: "right"}}>{c.qty}</td>
                        <td style={{textAlign: "right"}}>
                            {c.neededBy ? moment(c.neededBy).format("ddd MMM DD YYYY") : "No due date"}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </Popover>
    )
}

// @ts-ignore
const tableClass = css({
    fontSize: "0.8rem",
    cellSpacing: 0,
    borderCollapse: "collapse",

    "& > thead": {
        fontWeight: "bold",

        "& td": {
            borderBottom: "1px solid " + grey["400"],
        }
    },

    "& > tbody > tr:not(:last-child) > td": {
        borderBottom: "1px solid " + grey["200"]
    },

    "& > * > tr > td": {
        paddingLeft: 2, paddingRight: 4,
    }
})
import {User} from "../../api/Users";
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useRoleAndOverride} from "./RoleAndOverride";
import {useSyncedRef} from "../../misc/SyncedRef";

export function PermissionsModal(props: {
    row: User;
    onDone(): void;
}) {

    const permissions = useAsync2(input => api.users.getPermissionOverrides(input), {user: props.row.id}, [props.row.id])

    const onDoneRef = useSyncedRef(props.onDone);
    const action = useAsyncAction(async (input) => {
        await api.users.updatePermissions(input);
        onDoneRef.current();
    }, [])

    const {Overrides, role, permOverride, Role} = useRoleAndOverride({
        role: props.row.role,
        permissionOverrides: permissions.asList,
    });

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault()
                action.callback({
                    user: props.row.id,
                    role,
                    permissionOverrides: permOverride,
                })
            }}>
                <DialogTitle>
                    Permissions for {props.row.name}
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    {Role}
                    <div style={{height: 16}} />
                    <div style={{fontSize: "0.8rem", paddingLeft: 16}}>
                        Permission Overrides
                    </div>
                    {Overrides}
                </DialogContent>
                <DialogActions>
                    {action.LoadingElement}
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button type="submit">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
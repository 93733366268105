import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {api} from "../../../../api/API";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useAlternative} from "../alternative/Alternative";
import {EditContext} from "../../../../misc/scroller/EditProvider";

export function NewRowMenu() {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("10")
    const projectId = useRef(useProjectId());
    const alt = useAlternative();
    const ctx = useContext(EditContext);
    const ctxRef = useRef(ctx);
    ctxRef.current = ctx;

    const add = useAsyncAction(async (qty: number) => {

        const maxConcurrent = 5;
        let todo = qty;
        let active = [];

        for(let i = 0; i < maxConcurrent; i++) {
            active.push((async () => {
                while(true) {
                    if (todo === 0) return;
                    todo--;

                    if (!alt) {
                        await api.openings.upsert({
                            project: projectId.current,
                        })
                    } else {
                        await api.quoteOpenings.upsert({
                            name: "",
                            project: projectId.current,
                            quoteAlternative: alt,
                        })
                    }
                }
            })());
        }

        try {
            await Promise.all(active);
            setOpen(false);
        } finally {
            ctxRef.current.reload();
        }
    }, []);

    return (
        <>
            <IconButton color="primary" onClick={(e) => setOpen(true)}>
                <MoreVertIcon />
            </IconButton>
            {open && <Dialog open onClose={() => setOpen(false)}>
                <DialogTitle>
                    Add Multiple Rows
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{height: 8}} />
                        <TextField label="Qty" value={value} onChange={e => setValue(e.target.value)} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {add.LoadingElement}
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => add.callback(parseInt(value))}>Add</Button>
                </DialogActions>
            </Dialog>}
        </>
    )
}
import {forwardRef} from "react";
import {Project} from "../../../../api/Projects";
import {useUser} from "../../../../misc/Permission";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {PDFProjectContext} from "../../shopdrawing/openings/OpeningsPDF";
import {PriceLine} from "../../../../api/Pricing";
import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {pdfTableStyle} from "../../../../misc/pdf/parts/Page";
import {ContainerPage} from "../../../../misc/pdf/parts/ContainerPage";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {RedactedContext} from "../../../../misc/pdf/RedactedContext";
import {QuotePriceLine} from "../../../../api/QuotePricing";

export type PriceKind = "hardware" | "frame" | "door" | "div10/wr-accessory" | "div10/wr-partition" | "div10/locker" | "div10/mailbox" | "div10/corner-guard";

export const PriceRequestPDF = forwardRef(function (props: {
    project: Project;
    kind: PriceKind;
    keyName?: string;
    items: (PriceLine|QuotePriceLine)[]
    onReady(): void;
}, ref: any) {
    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    return (
        <div ref={ref}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <RedactedContext.Provider value={true}>
                        <PriceRequestTable keyName={props.keyName} items={props.items} kind={props.kind} onReady={ready.section()} />
                    </RedactedContext.Provider>
                </PDFProjectContext.Provider>
            </PDF>
        </div>
    )
})

export function PriceRequestTable(props: {
    kind: PriceKind;
    keyName?: string;
    items: (PriceLine|QuotePriceLine)[];
    onReady(): void;
}) {
    return (
        <PageRenderProvider data={props.items} onReady={props.onReady}>
            {(list: DataSplitInfo<PriceLine|QuotePriceLine>) =>
                <ContainerPage>
                    <PriceTable kind={props.kind} keyName={props.keyName} list={list} />
                </ContainerPage>}
        </PageRenderProvider>
    )
}

function PriceTable(props: {
    kind: PriceKind;
    keyName?: string;
    list: DataSplitInfo<PriceLine|QuotePriceLine>
}) {
    const tbl = MakePriceTable({
        kind: props.kind,
        keyName: props.keyName,
        list: props.list.rows
    });

    return tbl.toReact();
}

class Table {
    header: string[] = [];
    rows: string[][] = [];

    setHeader(values: string[]) {
        this.header = values;
    }

    addRows(values: string[][]) {
        this.rows.push(...values);
    }

    appendRow(values: string[]) {
        this.rows.push(values);
    }

    toReact() {
        return (
            <table className={pdfTableStyle}>
                <thead>
                <tr>
                    {this.header.map(((h, index) => <th key={index}>{h}</th>))}
                </tr>
                </thead>
                <tbody>
                {this.rows.map((row, index) => <tr key={index}>
                    {row.map((cell, index) => <td key={index}>{cell}</td>)}
                </tr>)}
                </tbody>
            </table>
        )
    }

    toStringArray() {
        return [
            this.header,
            ...this.rows,
        ]
    }
}

export function MakePriceTable(props: {
    kind: PriceKind;
    keyName?: string;
    list: (PriceLine|QuotePriceLine)[]
}) {
    switch(props.kind) {
        case "div10/wr-accessory": {
            const tbl = new Table();
            tbl.setHeader([
                "Qty", "Product", "Description", "Code", "Finish"
            ]);

            tbl.addRows(props.list.map(v => [
                v.qty.toString(),
                v.categoryName,
                v.productName,
                v.productCode,
                v.finish
            ]));

            return tbl;
        }
        case "div10/wr-partition": {
            const tbl = new Table();
            tbl.setHeader([
                "Mounting", "Material", "Description", "Qty"
            ]);

            tbl.addRows(props.list.map(v => [
                v.div10?.partitionBracing || "",
                v.div10?.partitionCoating || "",
                v.div10?.description || "",
                v.qty.toString()
            ]));

            return tbl;
        }
        case "div10/locker": {
            const tbl = new Table();
            tbl.setHeader([
                "Tiers", "Material", "Sloped Tops", "End Panels", "Base Trim", "Width", "Depth", "Height", "Qty"
            ]);

            tbl.addRows(props.list.map(v => [
                v.div10?.lockerTiers || "",
                v.div10?.lockerCoating || "",
                yesNo(v.div10?.lockerSloped),
                yesNo(v.div10?.lockerEndPanels),
                yesNo(v.div10?.lockerBaseTrim),
                v.dimWidth.toString(),
                v.dimLength.toString(),
                v.dimHeight.toString(),
                v.qty.toString()
            ]));

            return tbl;
        }
        case "div10/mailbox": {
            const tbl = new Table();
            tbl.setHeader([
                "Tenant Units", "Parcel Units", "Loading", "Mounting", "Qty"
            ]);

            tbl.addRows(props.list.map(v => [
                v.div10?.mailboxTenantUnits.toString() || "",
                v.div10?.mailboxParcelUnits.toString() || "",
                v.div10?.mailboxLoading || "",
                v.div10?.mailboxMounting || "",
                v.qty.toString()
            ]));

            return tbl;
        }
        case "div10/corner-guard": {
            const tbl = new Table();
            tbl.setHeader([
                "Type", "Code", "Finish", "Width", "Height", "Qty"
            ]);

            tbl.addRows(props.list.map(v => [
                v.div10?.cornerGuardType || "",
                v.productCode,
                v.div10?.cornerGuardFinish || "",
                v.dimWidth.toString(),
                v.dimHeight.toString(),
                v.qty.toString()
            ]));

            return tbl;
        }
        case "frame": {
            const tbl = new Table();
            tbl.setHeader([
                "Qty", "Series", "Material", "Type", "Screen Elev", "Gauge", "Construction", "Profile", "Width", "Height", "Jamb Depth", "Label"
            ]);

            tbl.addRows(props.list.map(v => [
                v.qty.toString(),
                v.frame.series,
                v.frame.material,
                v.frame.type,
                v.frame.screenElev,
                v.frame.gauge,
                v.frame.construction,
                v.frame.profile,
                v.frame.width.toString(),
                v.frame.height.toString(),
                v.frame.jambDepth.toString(),
                v.frame.label
            ]));

            return tbl;
        }
        case "door": {
            const tbl = new Table();
            if(props.keyName === "door-wd") {
                tbl.setHeader([
                    "Qty", "Series", "Material", "Finish", "Core", "Seam", "Elevation", "Width", "Height", "Thickness", "Label"
                ]);

                tbl.addRows(props.list.map(v => [
                    v.qty.toString(),
                    v.door.series,
                    v.door.material,
                    v.door.finish,
                    v.door.core,
                    v.door.seam,
                    v.door.elevation,
                    v.door.width.toString(),
                    v.door.height.toString(),
                    v.door.thickness.toString(),
                    v.door.label
                ]));

            } else if(props.keyName === "door-hm") {
                tbl.setHeader([
                    "Qty", "Series", "Material", "Gauge", "Core", "Seam", "Elevation", "Width", "Height", "Thickness", "Label"
                ]);

                tbl.addRows(props.list.map(v => [
                    v.qty.toString(),
                    v.door.series,
                    v.door.material,
                    v.door.gauge,
                    v.door.core,
                    v.door.seam,
                    v.door.elevation,
                    v.door.width.toString(),
                    v.door.height.toString(),
                    v.door.thickness.toString(),
                    v.door.label
                ]));
            } else {
                tbl.setHeader([
                    "Qty", "Series", "Material", "Core", "Seam", "Elevation", "Width", "Height", "Thickness", "Label"
                ]);

                tbl.addRows(props.list.map(v => [
                    v.qty.toString(),
                    v.door.series,
                    v.door.material,
                    v.door.core,
                    v.door.seam,
                    v.door.elevation,
                    v.door.width.toString(),
                    v.door.height.toString(),
                    v.door.thickness.toString(),
                    v.door.label
                ]));
            }

            return tbl;
        }
        default:
        case "hardware": {
            const tbl = new Table();
            tbl.setHeader([
                "Qty", "Name", "Code", "Finish", "Length", "Width", "Height"
            ]);

            tbl.addRows(props.list.map(v => [
                v.qty.toString(),
                v.productName,
                v.productCode,
                v.finish,
                v.dimLength.toString(),
                v.dimWidth.toString(),
                v.dimHeight.toString()
            ]));

            return tbl;
        }
    }
}

export function yesNo(tf: boolean | undefined) {
    if(tf === undefined) return "-";
    return tf ? "Yes" : "No";
}

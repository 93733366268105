import {Column, keyMatch, lookupNestedProp, NestedKeyOf, sortString} from "../../../../misc/scroller/Table";
import {Popover, TextField, Grid, Button} from "@mui/material";
import {useEffect, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {Loader} from "../../../../misc/Loader";
import {useSyncedRef} from "../../../../misc/SyncedRef";

type HasDimFormula = {
    id: number;
    dimensions: string;
    dimLengthFormula?: string;
    dimWidthFormula?: string;
    dimHeightFormula?: string;
    dimLength: string;
    dimWidth: string;
    dimHeight: string;
}

export function DimensionCol<T extends HasDimFormula>(name: string, key: NestedKeyOf<T>, size = 200): Column<T> {
    return {
        name: name,
        render: dt => lookupNestedProp(dt, key),
        editable: {
            type: "custom",
            paste: (row: T, value: string) => {
                // @ts-ignore
                row[key] = value;
            },
            copy: (row: T) => lookupNestedProp(row, key),
            render: ManualDimensions,
        },
        width: size,
        filter: keyMatch(key),
        sort: sortString(key),
    }
}

export function ManualDimensions<T extends HasDimFormula>(props: {
    anchor: any;
    row: T;
    width: number;
    initialValue?: string;
    onCancel(): void;
    onDone(value: T): Promise<any>;
}) {

    useEffect(() => {
        setLength(props.row.dimLength)
        setWidth(props.row.dimWidth)
        setHeight(props.row.dimHeight);
    }, [props.row]);

    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const onDoneRef = useSyncedRef(props.onDone)
    const callback = useAsyncAction(async input => {
        await onDoneRef.current(input)
    }, []);

    const none = !props.row.dimLengthFormula &&
        !props.row.dimWidthFormula &&
        !props.row.dimHeightFormula

    return (
        <Popover anchorEl={props.anchor} open onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault();

                callback.callback(Object.assign({}, props.row, {
                    dimLength: length,
                    dimWidth: width,
                    dimHeight: height,
                }));
            }}>
                <div style={{fontSize: "0.9rem", padding: 8,}}>
                    Manual Dimensions
                </div>

                <div style={{padding: 8, paddingTop: 0}}>
                    <Grid container spacing={1} direction="column">
                        {props.row.dimLengthFormula &&
                            <Grid item><TextField InputLabelProps={{shrink: true}} label="Length" size="small" value={length} onChange={e => setLength(e.target.value)} /></Grid>}
                        {props.row.dimWidthFormula &&
                            <Grid item><TextField InputLabelProps={{shrink: true}} label="Width" size="small" value={width} onChange={e => setWidth(e.target.value)} /></Grid>}
                        {props.row.dimHeightFormula &&
                            <Grid item><TextField InputLabelProps={{shrink: true}} label="Height" size="small" value={height} onChange={e => setHeight(e.target.value)} /></Grid>}
                        {none && <Grid item>No dimensions are configured for this product</Grid>}
                    </Grid>
                </div>

                <div style={{padding: 8, paddingTop: 0, display: "flex", justifyContent: "flex-end"}}>
                    <Loader {...callback} />
                    <Button size="small" variant="contained" type="submit">Ok</Button>
                </div>
            </form>
        </Popover>
    )
}
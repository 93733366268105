import {WhiteColumn} from "../purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {useEffect, useState} from "react";
import {SmallSearchBox} from "../purchasing/SmallSearchBox";
import {Grid} from "@mui/material";
import {ProjectFilter} from "../purchasing/ProjectFilter";
import {
    BoolCol,
    DateCol,
    NumberCol,
    Option,
    sortString,
    StringCol,
    Table,
    ViewOnly
} from "../../../misc/scroller/Table";
import {Delivery} from "../../../api/Deliveries";
import {removeRowOption, RowOptionCol} from "../purchasing/RowOptions";
import {green, grey, orange, purple} from "@mui/material/colors";
import {CellWithBg} from "../purchasing/CellWithBg";
import {first} from "nate-react-api-helpers";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import {CreateDelivery} from "./CreateDelivery";
import {useNavigate} from "react-router-dom";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import {OpenInNew} from "@mui/icons-material";

export const deliveryListTableName = "deliveries.list"

export const deliveryStatuses = [
    {
        display: "Draft",
        value: "draft",
    },
    {
        display: "To Pick",
        value: "to-pick",
    },
    {
        display: "Picked",
        value: "picked",
    },
    {
        display: "In Truck",
        value: "in-truck",
        disabled: true,
    },
    {
        display: "Delivered",
        value: "delivered",
        disabled: true,
    },
];

export function DeliveryList() {
    const [search, setSearch] = useState("")
    const [project, setProject] = useState<Option|null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        deliveriesPopoutPanel.sendToClient({});

        return () => {
            deliveriesPopoutPanel.close();
        }
    }, []);

    return (
        <WhiteColumn>
            <SectionHeader name="Packing" right={<Grid container spacing={1} flexWrap="nowrap">
                <Grid item>
                    <SmallGreyButton onClick={() => deliveriesPopoutPanel.open()} startIcon={<OpenInNew />}>
                        To Pack
                    </SmallGreyButton>
                </Grid>
                <Grid item>
                    <ProjectFilter value={project} onChange={setProject} />
                </Grid>
                <Grid item>
                    <SmallSearchBox value={search} onChange={setSearch} fullWidth />
                </Grid>
            </Grid>} />

            <Table<Delivery>
                name={deliveryListTableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [deliveryListTableName],
                                confirm: true,
                            })
                        ],
                    }),
                    ViewOnly(NumberCol("#", "id", 80)),
                    ViewOnly(StringCol("Project", "projectName", 250)),
                    {
                        name: "Status",
                        render: (dt, col) => {

                            let bgColor = "";
                            let color = "inherit"
                            switch (dt.status) {
                                default:
                                case "draft":
                                    bgColor = orange["400"];
                                    break;
                                case "picked":
                                    color = "white";
                                    bgColor = green["400"];
                                    break;
                                case "to-pick":
                                    color = "white";
                                    bgColor = purple["400"]
                                    break;
                                case "in-truck":
                                    color = green["800"]
                                    break;
                                case "delivered":
                                    color = grey["400"];
                                    break;
                            }

                            return (
                                <CellWithBg color={color} backgroundColor={bgColor}>
                                    {first(deliveryStatuses, s => s.value === dt.status)?.display}
                                </CellWithBg>
                            );
                        },
                        filter: (row, value) => {
                            value = value.toLowerCase();
                            const matches = deliveryStatuses.filter(o => o.display.toLowerCase().indexOf(value) === 0).map(o => o.value);
                            return matches.indexOf(row.status) !== -1
                        },
                        sort: sortString("status"),
                        width: 70,
                    },
                    ViewOnly(DateCol("Pick By", "pickByDate", "MMM D, YYYY")),
                    ViewOnly(BoolCol("Has Inventory", "hasEnoughInWarehouse", 80)),
                ]}
                fetch={ctx => getAll(ctx, offset => api.deliveries.listDeliveries({
                    offset,
                    search,
                    project: project?.value || undefined,
                }))}
                onChange={input => api.deliveries.updateDelivery(input)}
                fetchDeps={[search, project]}
                onRowClick={(item) => {
                    navigate("/logistics/packing/" + item.id)
                }}
                insert={{
                    modal: (onDone) => <CreateDelivery onDone={onDone} />,
                }}
            />
        </WhiteColumn>
    )
}
import {CircularProgress, Typography} from "@mui/material";
import {red} from "@mui/material/colors";

export function ActionResult(props: {
    action: {
        loading: boolean;
        error: string|null;
    }
}) {

    if(props.action.loading) {
        return (
            <CircularProgress size="1.5rem" />
        )
    }

    if(props.action.error) {
        return (
            <Typography style={{color: red["800"]}}>
                {props.action.error}
            </Typography>
        )
    }

    return null;
}

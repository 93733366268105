import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {Company} from "../../../../../api/Companies";
import {api} from "../../../../../api/API";

export function CompanyShortCode(props: {
    company?: Company | null;
    disabled?: boolean;
}) {
    const [value, setValue] = useState("");

    useEffect(() => {
        if(!props.company) {
            setValue("")
            return;
        }

        setValue(props.company.shortName || "");
    }, [props.company]);

    useEffect(() => {
        if(!props.company) return;
        if(props.company.shortName !== value && !!value) {
            api.companies.upsert(Object.assign({}, props.company, {
                shortName: value,
            }))
        }
    }, [value, props.company])

    return (
        <TextField
            size="small"
            label="MFG Short Code"
            disabled={!props.company || props.disabled}
            value={value}
            style={{width: 120}}
            onChange={e => setValue(e.currentTarget.value as string)} />
    )
}
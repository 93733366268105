import {PropsWithChildren, useContext} from "react";
import {pdfInfo} from "./CoverPage";
import {PDFProjectContext} from "../../../pages/project/shopdrawing/openings/OpeningsPDF";
import {Page} from "./Page";
import {RedactedContext} from "../RedactedContext";

export function ContainerPage(props: PropsWithChildren<{
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
}>) {
    const project = useContext(PDFProjectContext)
    const redacted = useContext(RedactedContext);

    return (
        <Page>
            <div style={{
                flex:1,
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
            }}>
                <div style={{flex: 1, borderBottom: "1px solid black", display: "flex", flexDirection: "column", justifyContent: props.justifyContent}}>
                    {props.children}
                </div>
                <div style={{
                    display: "flex", alignItems: "stretch",
                    fontSize: "0.8rem", fontWeight: "bold",
                }}>
                    <div style={{
                        padding: 20,
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <img alt="Tykel Logo" src={pdfInfo.logo} style={{width: 150}} />
                    </div>
                    <div style={{
                        flex: 1,
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        padding: 20,
                        display: "flex",
                        lineHeight: "1.2em"
                    }}>

                        <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div>{pdfInfo.companyName}</div>
                            <div>{pdfInfo.address1}</div>
                            <div>{pdfInfo.address2}</div>
                            <div>{pdfInfo.postal}</div>
                        </div>

                        <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div>{redacted ? (project.nameAlias || project.name) : project.name}</div>
                            {!redacted && <div>{project.contractorName}</div>}
                            <div>Version: {project.versionCode}</div>
                        </div>

                    </div>
                    <div style={{padding: 20}}>
                        <div>Submittal created using</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img alt="Tykel Logo" src={pdfInfo.handleLogo} style={{width: 50}} />
                            <div style={{paddingLeft: 20}}>
                                <div style={{fontWeight: "bold", fontSize: "1.2rem"}}>Handle</div>
                                <div style={{fontSize: "0.8rem"}}>{tylersPhone}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}

const tylersPhone = "(905) 512-7461"
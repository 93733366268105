import {PrepCanvasManager} from "./PrepCanvasManager";
import {useMemo} from "react";
import {ControlItem} from "./ControlItem";

export function PrepControls(props: {
    manager: PrepCanvasManager;
}) {
    const opts = useMemo(() => {
        return props.manager.options()
    }, [props.manager])

    return (
        <table>
            <tbody>
            {opts.map(o => <ControlItem value={o} />)}
            </tbody>
        </table>
    )
}
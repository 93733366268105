import {ShopContainer} from "../ShopContainer";
import { useEffect} from "react";
import {CheckboxProvider} from "../release/Checkbox";
import { BulkEditNeededBy } from "./BulkEditNeededBy";
import {grey} from "@mui/material/colors";
import {Grid, Tab, Tabs} from "@mui/material";
import {tabGroup} from "../../../logistics/purchasing/PartsToSource";
import {Openings} from "./Openings";
import { Damaged } from "./Damaged";
import {EventEmitter, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {useNavigate, useParams} from "react-router-dom";
import {Div10} from "./Div10";
import {BulkUnrelease} from "./BulkUnrelease";
import {projectTablePrefName} from "../TablePrefName";
import {Waste} from "./Waste";

export function logisticsTableName(project: number, type: string) {
    return projectTablePrefName("project.logistics." + type, project)
}

export const damagedEmitter = new EventEmitter<{count: number}>();
export const wasteEmitter = new EventEmitter<{count: number}>();

export function Logistics() {
    const params = useParams<{subspace: string}>();
    const tab = params.subspace || "frame-hm"

    const project = useProjectId();
    const damageList = useAsync2(input => api.logistics.listDamagedProducts(input), {includeReviewed: false, project}, [project]);
    const wasteList = useAsync2(input => api.logistics.listWasteProducts(input), {includeReviewed: false, project}, [project]);

    useEffect(() => {
        damagedEmitter.emit({count: damageList.result?.count || 0});
    }, [damageList.result?.count])

    useEffect(() => {
        wasteEmitter.emit({count: wasteList.result?.count || 0});
    }, [wasteList.result?.count])

    const damagedCt = EventEmitter.reactValue(damagedEmitter) || {count: 0}
    const wasteCt = EventEmitter.reactValue(wasteEmitter) || {count: 0}
    const navigate = useNavigate();

    return (
        <ShopContainer name="Logistics">
            <CheckboxProvider>
                <div style={{backgroundColor: grey["200"]}}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Tabs className={tabGroup} value={tab} onChange={(e, value) => {
                                navigate(`/project/${project}/shop-drawing/logistics/` + value);
                            }}>
                                <Tab label="HM Frame" value="frame-hm"  />
                                <Tab label="Anchors" value="frame-anchor"  />
                                <Tab label="HM Door" value="door-hm"  />
                                <Tab label="WD Door" value="door-wd"  />
                                <Tab label="Hardware" value="hardware"  />
                                <Tab label="Div 10" value="div10"  />
                                <Tab label={damagedCt.count > 0 ? `Damaged (${damagedCt.count})` : "Damaged"} value="damaged" />
                                <Tab label={wasteCt.count > 0 ? `Waste (${wasteCt.count})` : "Waste"} value="waste" />
                                <div style={{height: "2rem"}} />
                            </Tabs>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            {(tab !== "damaged") && <>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <BulkUnrelease type={tab} />
                                    </Grid>
                                    <Grid item>
                                        <BulkEditNeededBy type={tab} />
                                    </Grid>
                                </Grid>
                            </>}
                        </Grid>
                    </Grid>
                </div>
                {tab === "damaged" ? <Damaged project={project} /> :
                    tab === "waste" ? <Waste project={project} /> :
                        tab === "div10" ? <Div10 /> :
                            <Openings type={tab as any} />}
            </CheckboxProvider>
        </ShopContainer>
    )
}
import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";
import {TID} from "../misc/lists/ListCommon";
import {PrepDetail} from "./QuoteHardware";
import {DrawingWallLabel, ScreenElevation} from "./Openings";

export class QuoteOpenings extends SubAPI {
    list(input: {
        project: number
        alternative: number
        category?: "frame" | "door" | "hardware";
        offset?: number;
        type?: "add-hw-find-opening-number" | "have-no-hardware-group";
        search?: string;
        hwGroup?: number;
    }) {
        return this.fetcher.get<Paginated<QuoteOpening>>("/api/quote-openings", input)
    }

    listDrawingWallLabels(input: {
        project: number;
        alternative: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<DrawingWallLabel>>("/api/quote-opening/drawing-wall-labels", input)
    }

    updateDrawingWallLabel(props: DrawingWallLabel) {
        return this.fetcher.post<{}>("/api/quote-opening/drawing-wall-label", props)
    }

    listScreenElevations(input: {
        project: number;
        alternative: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<ScreenElevation>>("/api/quote-opening/screen-elevations", input)
    }

    updateScreenElevation(input: ScreenElevation) {
        return this.fetcher.post<{}>("/api/quote-opening/screen-elevation", input)
    }

    reSequence(input: {
        project: number;
        alternative: number;
        idOrder: number[]
    }) {
        return this.fetcher.post<{}>("/api/quote-opening/re-sequence", input)
    }

    reOrder(input: {
        project: number;
        alternative: number|null;
        idOrder: number[]
    }) {
        return this.fetcher.post<{}>("/api/quote-opening/re-order", input)
    }

    upsert(input: QuoteOpening | {project: number, name: string, quoteAlternative: number}) {
        return this.fetcher.post<TID>("/api/quote-opening", input)
    }
}

export type QuoteOpening = {
    id: number;
    name: string;
    project: number;
    seqNumber: number;
    qty: number;

    hardwareGroup?: number;
    hardwareGroupName?: string;
    hardwareGroupPrepConfig?: PrepDetail[];
    hardwareGroupPrepConfigIsCustom: boolean;
    hwDescriptiveName?: string;

    locationOne: string;
    locationTransition: "to"|"from"|"";
    locationTwo: string;
    floor: string;
    openingType: string;
    nominalWidth: string;
    inactiveDoorWidth: string;
    nominalHeight: string;
    handing: string;
    label: string;
    frameSeries: string;
    frameMaterial: string;
    frameGauge: string;

    screenElevation: string;
    screenElevationFileName?: string;
    screenElevationFile?: number;
    screenElevationOutsideWidth: string;
    screenElevationOutsideHeight: string;

    frameProfile: string;
    jambDepth: string;

    drawingWallLabel: string;
    drawingWallLabelFile?: number;
    drawingWallLabelFileName?: string;

    frameConstruction: string;
    frameHardwarePreps: string;
    doorSeries: string;
    doorMaterial: string;
    doorGauge: string;
    doorThickness: string;
    doorElevation: string;
    doorCore: string;
    doorSeam: string;
    doorFinish: string;
    notes: string;

    frameAnchorProduct?: number;
    frameAnchorProductName?: string;
    frameAnchorDetailFile?: number;
    frameAnchorQtyOverride: number | null;

    sortOrder: number;

    createdAt: DateString;
    updatedAt: DateString;
    archived: boolean;
}
import { Grid } from "@mui/material";
import {Container} from "../../../misc/Container";
import {ReadyToPurchase} from "./ReadyToPurchase";
import {Restocking} from "./Restocking";
import {OutstandingOrders} from "./OutstandingOrders";
import {DeliveriesToPick} from "./DeliveriesToPick";
import {OutstandingDeliveries} from "./OutstandingDeliveries";
import {DeliveriesToBuild} from "./DeliveriesToBuild";
import {OrdersReadyForPickup} from "./OrdersReadyForPickup";
import { SummaryNumbers } from "./SummaryNumbers";

export function LogisticsDashboard() {
    return (
        <Container>
            <Grid container spacing={0}>
                <ReadyToPurchase />
                <OutstandingOrders />
                <OrdersReadyForPickup />
                <DeliveriesToBuild />
                <DeliveriesToPick />
                <OutstandingDeliveries />
                <SummaryNumbers />
                <Restocking />
            </Grid>
        </Container>
    );
}
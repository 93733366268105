import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import {orderByStrAscending, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {Loader} from "../../../../misc/Loader";
import React, {useRef} from "react";
import {QuoteHwForceUpdate} from "./QuoteHardware";
import {useAlternative} from "../alternative/Alternative";
import {grey} from "@mui/material/colors";
import {css} from "@emotion/css";
import {ProductSelector} from "./ProductSelector";
import {ProductReplacement} from "../../../../api/QuoteHardware";
import {getAll} from "../../shopdrawing/openings/Openings";
import {QuoteDiv10ForceUpdate} from "../div10/Div10";
import {useIsQuote} from "../QuoteContainer";
import {Div10ForceUpdate} from "../../shopdrawing/div10/Div10";
import {HwForceUpdate} from "../../shopdrawing/hardware/Hardware";

export function TransferProductModal(props: {
    div10?: boolean;
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const isDiv10 = useSyncedRef(props.div10);
    const isQuote = useIsQuote()
    const isQuoteRef = useSyncedRef(isQuote);

    const action = useAsyncAction(async (input) => {
        if(isQuoteRef.current) {
            if (isDiv10.current) {
                await api.quoteDiv10.replaceProducts(input)
                QuoteDiv10ForceUpdate.emit(Date.now());
            } else {
                await api.quoteHardware.replaceProducts(input)
                QuoteHwForceUpdate.emit(Date.now());
            }
        } else {
            if (isDiv10.current) {
                await api.div10.replaceProducts(input)
                Div10ForceUpdate.emit(Date.now());
            } else {
                await api.hardware.replaceProducts(input)
                HwForceUpdate.emit(Date.now());
            }
        }

        onDoneRef.current();
    }, []);

    const project = useProjectId();
    const alternative = useAlternative();
    const list = useAsync2(
        async (input): Promise<ProductItem[]> =>  {
            let productList: ProductItem[] = [];

            if(isDiv10.current) {
                const list = await getAll(null, offset =>
                    isQuoteRef.current ?
                        api.quoteDiv10.list(Object.assign({}, input, {
                            offset: offset,
                            tab: "wr-accessory",
                        })) :
                        api.div10.list(Object.assign({}, input, {
                            offset: offset,
                            tab: "wr-accessory",
                        })))

                if(!list) return [];
                let productLookup: {[id: number]: ProductItem} = {};

                list.map(h => {
                    let obj = productLookup[h.product];
                    if(!obj) {
                        obj = {
                            product: h.product,
                            name: h.productName,
                            productCode: h.productCode,
                            manufacturerName: h.manufacturerName,
                            manufacturerShortName: h.manufacturerShortName,
                            qty: h.qty,
                        };

                        productLookup[h.product] = obj;
                        productList.push(obj)
                    } else {
                        obj.qty += h.qty;
                    }
                })

            } else {
                const list = await (isQuoteRef.current ? api.quoteHardware.listWithContents(input) : api.hardware.listWithContents(input))
                if(!list) return [];

                let productLookup: {[id: number]: ProductItem} = {};

                list.map(i => {
                    i.hardware.map(h => {
                        let obj = productLookup[h.product]
                        if(!obj) {
                            obj = {
                                product: h.product,
                                name: h.name,
                                productCode: h.productCode,
                                manufacturerName: h.manufacturerName,
                                manufacturerShortName: h.manufacturerShortName,
                                qty: h.activeQty + h.commonQty + h.inactiveQty,
                            };

                            productLookup[h.product] = obj;
                            productList.push(obj)
                        } else {
                            obj.qty += h.activeQty + h.commonQty + h.inactiveQty
                        }
                    })
                })
            }

            orderByStrAscending(productList, p => p.manufacturerShortName);
            return productList;
        },
        {
            project: project,
            alternative: alternative,
        }, [project, alternative]);

    type ProductItem = {
        product: number;
        name: string;
        productCode: string;
        manufacturerName: string;
        manufacturerShortName: string;
        qty: number;
    }

    let lastMfgName = "";
    const replace = useRef<{[id: number]: {current: ProductReplacement|null}}>({});

    return (
        <Dialog onClose={props.onDone} open>
            <form onSubmit={e => {
                e.preventDefault()

                let obj = replace.current;
                let list: ProductReplacement[] = [];

                for(let i in obj) {
                    const val = obj[i].current;
                    if(!val) continue
                    list.push(val);
                }

                if(list.length === 0) {
                    props.onDone();
                    return
                }

                action.callback({
                    project: project,
                    alternative: alternative,
                    replacements: list,
                })
            }}>
                <DialogTitle>Substitute Products</DialogTitle>
                <Loader {...list}>
                    {() => (<Table className={tableClass} style={{width: "100%"}} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Current</TableCell>
                                    <TableCell>Replace With</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.asList.map(p => {
                                    let mfg = null;

                                    if(p.manufacturerName !== lastMfgName) {
                                        lastMfgName = p.manufacturerName;
                                        mfg = (
                                            <TableRow key={"mfg-" + p.manufacturerName}>
                                                <TableCell style={{fontWeight: "bold"}} colSpan={2}>{p.manufacturerName}</TableCell>
                                            </TableRow>
                                        );
                                    }

                                    if(!replace.current[p.product]) {
                                        replace.current[p.product] = {current: null};
                                    }

                                    return (<>
                                        {mfg}
                                        <TableRow key={p.product.toString()}>
                                            <TableCell>{p.name} - {p.productCode}</TableCell>
                                            <TableCell style={{padding: 0, width: 310}}>
                                                <ProductSelector div10={props.div10} product={p.product} value={replace.current[p.product]} />
                                            </TableCell>
                                        </TableRow>
                                    </>)
                                })}
                            </TableBody>
                        </Table>)}
                </Loader>
                <DialogActions>
                    {action.LoadingElement}
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button type="submit">Apply</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

const tableClass = css({
    "tr": {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: grey["200"],
        },
    }
})
import {Company, CompanyKind} from "../../../../api/Companies";
import {api} from "../../../../api/API";
import {AutoComplete} from "../../shopdrawing/openings/AutoComplete";
import React from "react";
import {CreateClientDialog} from "../../../clients/CreateClientDialog";

export function FindOrCreateCompany(props: {
    value: {id: number; name: string} | null
    kind: CompanyKind;
    label: string;
    onChange(value: Company | null): void;
}) {
    const [showCreate, setShowCreate] = React.useState<{name: string}|null>(null);

    return (
        <>
            <AutoComplete<Company>
                label={props.label}
                search={async input => {
                    const result = await api.companies.list({
                        search: input,
                        kind: props.kind,
                    })

                    return result.data;
                }}
                fullWidth
                value={props.value}
                equals={(a, b) => a.id === b.id}
                makeLabel={a => a.name}
                onSelect={props.onChange}
                onCreate={async input => {
                    setShowCreate({name: input});
                }}
            />

            {showCreate && <CreateClientDialog kind={props.kind} name={showCreate.name} onDone={(value) => {
                setShowCreate(null);
                props.onChange(value);
            }} />}
        </>
    )
}
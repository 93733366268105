import {api} from "../../../api/API";
import {MenuItem, Select} from "@mui/material";
import {useGetAll} from "../../logistics/routes/useGetAll";
import {css} from "@emotion/css";
import {CompanyKind} from "../../../api/Companies";

export function ManufacturerSelector(props: {
    defaultValueTitle: string;
    value: number | null;
    onChange(value: number | null): void;
}) {
    const list = useGetAll(props => api.companies.list(props), {
        kind: "supplier" as CompanyKind
    }, []);

    return (
        <Select className={selectorCss} size="small" value={(props.value || -1).toString()}>
            <MenuItem value="-1" onClick={() => props.onChange(null)}>{props.defaultValueTitle}</MenuItem>
            {list.LoadingOrErrorElement}
            {list.result?.map(d => <MenuItem onClick={() => props.onChange(d.id)} value={d.id.toString()}>{d.name}</MenuItem>)}
        </Select>
    )
}

const selectorCss = css({
    "& .MuiSelect-select": {
        minHeight: "auto !important",
        paddingTop: 2,
        paddingBottom: 2,
    }
})
import {Pagination} from "./Pagination";
import {Grid, styled} from "@mui/material";
import {Divider} from "./Divider";
import {first, useAsyncAction} from "nate-react-api-helpers";
import {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import {green} from "@mui/material/colors";
import {ListHeader} from "./ListHeader";
import {useSnackbar} from "../Snackbar";
import {Cell, EditableCell} from "./EditableCell";
import {TabCommand, useTabber} from "./UseTabber";
import {SimpleIconButton} from "./SimpleIconButton";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined"
import {EditCell} from "./EditCell";
import {Lister, ListProvider} from "./ListProvider";
import {ListContext} from "./ListContext";
import {NoResultsRow} from "./NoResultsRow";
import {TableBody, TableHead, TID, ucWords} from "./ListCommon";

const editContextDefault = {
    upsert: (input: any, opts?: {tab?: TabCommand|null}): Promise<void> => {
        return Promise.reject("invalid context");
    }
};
export type TEditContext = typeof editContextDefault;
export const EditContext = createContext(editContextDefault);

const blankArray: any = [];

export type Field<T> = {
    header: string;
    display: (input: T) => any;
    edit: (input: T) => any;
    isValid?: (input: T) => boolean;
}

function isField<T>(t: Fieldish<T>): t is Field<T> {
    if(typeof t === "object") return true;
    return false;
}

type Fieldish<T> = keyof T | Field<T>;

export const AddId = 0;

export function EditableTable<T extends TID>(props: {
    add?: boolean
    lister: Lister<T>
    upsert: (input: T) => Promise<TID>
    fields: Fieldish<T>[];
    actions?: (input: T) => any;
    extras?: any;
}) {
    return (
        <ListProvider lister={props.lister}>
            <EditableTableInner {...props} />
        </ListProvider>
    )
}

function EditableTableInner<T extends TID>(props: {
    add?: boolean
    lister: Lister<T>
    upsert: (input: T) => Promise<TID>
    fields: Fieldish<T>[];
    actions?: (input: T) => any;
    extras?: any;
}) {
    const listCtx = useContext(ListContext);

    const [add, setAdd] = useState<Partial<T>|null>(null);
    const [editKey, setEditKey] = useState("");

    useEffect(() => {
        const sub = listCtx.onReload.subscribe(() => {
            setAdd(null);
        })

        return sub.cancel();
    }, [listCtx.onReload]);

    const snack = useSnackbar();
    const updating = useRef(false);
    const updateError = useRef<Error|null>(null);

    const update = useAsyncAction(async input => {
        const hasID = input.id && input.id > 0
        updating.current = true;
        snack.loading();

        try {
            await props.upsert(input);
            updating.current = false;
            updateError.current = null;

        } catch (e: any) {
            const str = e.toString();
            if(str.indexOf("pq: duplicate key value violates") !== -1) {
                snack.error("That value is already taken");
            } else {
                snack.error(str);
            }

            updating.current = false;
            updateError.current = e;
            return;
        }

        if(hasID) {
            snack.success("Updated")
            listCtx.reload();
        } else {
            snack.success("Created")
            listCtx.setOffset(0);
            listCtx.setSearch("");
            listCtx.reload()
            setAdd(null);
        }
    }, [listCtx.reload, listCtx.setOffset, listCtx.setSearch]);

    const listData = listCtx.data;

    const listRef = useRef(listData);
    listRef.current = listData;

    const editing = useRef(editKey);
    editing.current = editKey;

    const [fields] = useState(() => {
        return props.fields.map(f => {
            if(isField(f)) {
                return f;
            }

            return {
                header: ucWords(f as any),
                display: row => row[f] as any,
                edit: row => <EditCell row={row} field={f} />
            } as Field<T>
        })
    })

    const fieldsRef = useRef(fields);

    const doTab = useTabber({
        editing,
        fields: fieldsRef,
        listRef,
        setEditKey,
    });

    const updateCallback = update.callback;
    const editCtx = useMemo(() => {
        return {
            upsert: async (input: T, opts?: {tab?: TabCommand|null}) => {
                if(updating.current) return;

                if(opts?.tab === "submit") {
                    const invalid = fieldsRef.current
                        .filter(c => c.isValid && !c.isValid(input));

                    if(invalid.length > 0) {
                        opts.tab = "forward";
                    }
                }

                const tab = opts?.tab
                if(input.id === AddId && tab !== "submit") {
                    setAdd(input);

                    if(tab && editing.current) {
                        doTab(tab);
                    }
                    return;
                }

                const src = listRef.current;
                const srcObj = first(src, i => i.id === input.id)

                let shouldUpdate = JSON.stringify(srcObj) !== JSON.stringify(input);
                if(shouldUpdate) {
                    await updateCallback(input);
                    if(updateError.current !== null) {
                        setEditKey("");
                        throw updateError.current;
                    }
                }

                if(tab && editing.current) {
                    doTab(tab);
                } else {
                    setEditKey("")
                }
            }
        }
    }, [updateCallback, doTab]);

    const colCount = props.fields.length + (props.actions ? 1 : 0);
    const [colWidths, setColWidths] = useState<number[]>(blankArray);
    const [tableRef, setTableRef] = useState<HTMLTableElement|null>();

    useEffect(() => {
        setColWidths(blankArray);
        if(!tableRef) return;

        setTimeout(() => {
            const widths = Array.from(tableRef.children[0].children[0].children)
                .map(c => c.getBoundingClientRect().width);

            setColWidths(widths);
        }, 500)

    }, [tableRef, listData]);
    
    return (
        <EditContext.Provider value={editCtx}>
            <div>
                <ListHeader
                    onAdd={props.add ? () => {
                        setAdd({id: 0} as any);
                        setEditKey("0.0");
                    } : undefined}
                />
                <Divider />
                <div>
                    <table
                        style={{width: "100%", borderSpacing: 0, borderCollapse: "collapse"}}
                        ref={setTableRef}
                    >
                        <TableHead>
                        <tr>
                            {fields.map((f, index) => <th key={f.header} style={{width: colWidths[index] || undefined}}>{f.header}</th>)}
                            {props.actions && <th key="actions" style={{width: colWidths[fields.length] || undefined}}></th>}
                        </tr>
                        </TableHead>
                        <TableBody>
                            <NoResultsRow colSpan={colCount} />
                        {add && <AddRow>
                            {fields.map((f, fieldIndex) => <EditableCell
                                key={add.id + "." + fieldIndex.toString()}
                                row={add as T}
                                fieldIndex={fieldIndex}
                                field={f}
                                editingRow={editKey.startsWith(add.id + ".")}
                                editing={editKey === add.id + "." + fieldIndex.toString()}
                                onEdit={setEditKey} />
                            )}
                            {props.actions && <Cell key="actions" edit="" actions="true" editingRow={editKey.startsWith("0.") ? "true" : ""}>
                                <Grid container>
                                    <Grid item>
                                        <SimpleIconButton onClick={async input => update.callback(add)}>
                                            <CheckCircleOutlined color="primary" />
                                        </SimpleIconButton>
                                    </Grid>
                                    <Grid item>
                                        <SimpleIconButton onClick={() => {
                                            setAdd(null);
                                            setEditKey("");
                                        }}>
                                            <CancelOutlined />
                                        </SimpleIconButton>
                                    </Grid>
                                </Grid>
                            </Cell>}
                        </AddRow>}
                        {listData.map(l => <tr key={l.id}>
                            {fields.map((f, fieldIndex) => <EditableCell
                                key={l.id + "." + fieldIndex.toString()}
                                row={l}
                                fieldIndex={fieldIndex}
                                field={f}
                                editingRow={editKey.startsWith(l.id + ".")}
                                editing={editKey === l.id + "." + fieldIndex.toString()}
                                onEdit={setEditKey} />
                            )}
                            {props.actions && <Cell key="actions" edit="" actions="true" editingRow={editKey.startsWith(l.id + ".") ? "true" : ""}>
                                {props.actions(l)}
                            </Cell>}
                        </tr>)}
                        </TableBody>
                    </table>
                </div>
                <Pagination />
                {props.extras}
            </div>
        </EditContext.Provider>
    )
}

const AddRow = styled("tr")(() => ({
    "& > td": {
        backgroundColor: green["100"],
    }
}))


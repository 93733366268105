import {useWebSocket} from "nate-react-api-helpers";
import {createContext, PropsWithChildren, useMemo, useRef} from "react";
import {useSyncedRef} from "./SyncedRef";

type CancelFunc = () => void;

export const WebSocketContext = createContext({
    send: (msg: any): void => {
        throw new Error("send: invalid context")
    },
    handle: (key: string, callback: (msg: any) => void): CancelFunc => {
        throw new Error("handle: invalid context");
    },
})

export function WebSocketProvider(props: PropsWithChildren<{}>) {
    const handlers = useRef<{key: string, callback: (data: any) => void}[]>([]);

    const ws = useWebSocket("/ws/default", msg => {
        console.log("WebSocket message", msg)
        try {
            const obj = JSON.parse(msg) as {key: string; data: any};
            if("key" in obj && "data" in obj) {
                handlers.current.filter(v => v.key === obj.key)
                    .map(v => {
                        try {
                            v.callback(obj.data)
                        } catch (e: any) {
                            console.error(e);
                        }
                    });
            } else {
                console.error("unexpected message format (missing key and data)", obj);
            }
        } catch (e: any) {
            console.error(e);
        }

    }, [], {
        onClose(socket: WebSocket, e: Event) {
            console.log("WebSocket closed", e)
        },
        onOpen(socket: WebSocket, e: Event) {
            console.log("WebSocket opened", e)
        }
    });

    const wsRef = useSyncedRef(ws);

    const ctx = useMemo(() => ({
        send: (msg: any) => {
            if(!wsRef.current) console.error("missing socket connection")
            wsRef.current?.send(JSON.stringify(msg));
        },
        handle: (key: string, callback: any) => {
            handlers.current.push({key, callback});
            console.log("ws handler added:" + key)
            return () => {
                console.log("ws handler removed:" + key)
                handlers.current = handlers.current.filter(x => x.key !== key && x.callback !== callback);
            }
        }
    }), [wsRef])

    return (
        <WebSocketContext.Provider value={ctx}>
            {props.children}
        </WebSocketContext.Provider>
    )
}
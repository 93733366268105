import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useState} from "react";
import {autoFormat} from "./AutoFormat";

export function AddressEdit(props: {
    value: Project;
    onDone(): void;
}) {

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const [street, setStreet] = useState(props.value.addressStreet);
    const [city, setCity] = useState(props.value.addressCity);
    const [province, setProvince] = useState(props.value.addressProvince);
    const [postal, setPostal] = useState(props.value.addressPostal);

    return (
        <Dialog open>
            <DialogTitle>Project Address</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <Grid container spacing={2} direction="column">
                    <Grid item><TextField fullWidth label="Street Address" value={street} onChange={e => setStreet(e.target.value)} /></Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <TextField label="City" value={city} onChange={e => setCity(e.target.value)} />
                            </Grid>
                            <Grid item>
                                <TextField style={{width: 80}} label="Province" value={province} onChange={e => setProvince(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{width: 100}} label="Postal"
                            value={postal} onChange={e => setPostal(autoFormat(e.target.value, "postal"))}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        addressStreet: street,
                        addressCity: city,
                        addressProvince: province,
                        addressPostal: postal,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
import {useNavigate} from "react-router-dom";
import Confetti from "react-confetti";
import {yellow} from "@mui/material/colors";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import {Button, Grid} from "@mui/material";
import {MobileContainer} from "../../../misc/Container";
import useWindowSize from "react-use/lib/useWindowSize";

export function RouteSuccess() {
    const nav = useNavigate();
    const { width, height } = useWindowSize()

    return (
        <MobileContainer topCenter="Deliveries">
            <div style={{flex: 1, display: "flex"}}>
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                />
                <div style={{flex: 1}} />
                <div style={{color: yellow["800"], display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <WbSunnyIcon style={{fontSize: "15rem"}} fontSize="inherit" color="inherit" />
                    <div style={{fontSize: "2rem", color: "white", fontWeight: "bold"}}>
                        SUCCESS
                    </div>
                </div>
                <div style={{flex: 1}} />
            </div>
            <div style={{padding: 16, textAlign: "center"}}>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Button color="secondary" fullWidth variant="contained" onClick={() => {
                            nav("/logistics/driver");
                        }}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </MobileContainer>
    )
}
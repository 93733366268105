import {GetOrCreateManufacturer, useGetOrCreateManufacturerProps} from "../../project/DefaultManufacturer";
import {useContext} from "react";
import {EditContext} from "../../../misc/scroller/EditProvider";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import moment from "moment";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useNavigate} from "react-router-dom";

export function CreatePurchaseOrder(props: {
    onDone(): void;
}) {

    const {getManufacturer, ...mfgProps} = useGetOrCreateManufacturerProps();
    const ctx = useContext(EditContext);
    const nav = useNavigate();

    const run = useAsyncAction(async () => {
        const mfg = await getManufacturer();

        const value = await api.logistics.updatePurchaseOrder({
            status: "draft",
            submittedAt: moment().toDate(),
            supplier: mfg,
        } as any);

        await ctx.reload();

        ctx.onSelect.emit({
            id: value.id,
            index: 100,
            value: value,
            editing: false,
        })

        nav("/logistics/purchasing/" + value.id);

    }, [nav]);

    return (
        <Dialog open>
            <DialogTitle>
                Create Purchase Order
            </DialogTitle>
            <DialogContent>
                <GetOrCreateManufacturer {...mfgProps} name="Supplier" />
            </DialogContent>
            <DialogActions>
                {run.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button onClick={() => {
                    run.callback({});
                }}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}
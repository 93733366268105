import {Field} from "./EditableTable";
import {alpha, styled} from "@mui/material";
import {TID} from "./ListCommon";
import { grey } from "@mui/material/colors";

export const Cell = styled("td")<{
    edit?: string,
    editingRow?: string;
    actions?: string;
    noClick?: string;
}>(props => ({
    padding: 0,

    fontSize: "0.9rem",
    border: "1px solid " + grey["300"],
    cursor: "pointer",
    paddingLeft: props.edit ? 0 : 4,
    paddingRight: props.edit ? 0 : 4,
    backgroundColor: props.editingRow ? alpha(props.theme.palette.primary.main, 0.1) : "",

    "&:hover": (props.actions || props.noClick) ? {} :  {
        backgroundColor: props.edit ? "none" : alpha(props.theme.palette.primary.main, 0.15),
    },

    "&:first-of-type": {
        paddingLeft: props.edit ? 0 : 10,
    },
    "&:last-of-type": {
        paddingRight: props.edit ? 0 : 10,
    },
}))

export function EditableCell<T extends TID>(props: {
    row: T
    field: Field<T>;
    fieldIndex: number;
    editing: boolean;
    editingRow: boolean;
    onEdit(value: string): void;
}) {

    if(props.editing) {
        return (
            <Cell edit="true">
                {props.field.edit(props.row)}
            </Cell>
        )
    }

    return (
        <Cell editingRow={props.editingRow ? "true" : ""} edit="" onClick={() => props.onEdit(props.row.id + "." + props.fieldIndex.toString())}>
            {props.field.display(props.row)}
        </Cell>
    )
}
import {EditCell} from "./EditCell";
import {ProductDimensioned} from "./ProductDimensioned";
import {AutoForHingeCell} from "./AutoForHingeCell";
import {IconButton2, IconCell} from "./IconCell";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {ProductLookup} from "../../extras/ProductLookup";
import {ProductSearchResult} from "../../../../../api/Products";
import React, {useContext, useEffect, useState} from "react";
import {ShortCode, ShortCodeItem} from "../../../../../api/Library";
import {EditProductContext} from "../EditProduct";
import {useHwLibraryType} from "./HwLibraryType";
import {useAsyncAction} from "nate-react-api-helpers";
import {ConfirmContext} from "../../../../../misc/Confirm";
import {useFocusMode} from "../UseFocusMode";
import {Button, Grid} from "@mui/material";
import {api} from "../../../../../api/API";
import {ReloadContext} from "../ReloadContext";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {ChangesetContext} from "../../changeset/ChangesetContext";
import {css} from "@emotion/css";
import {cellInActive} from "../../pricing/cellconst";
import {useSnackbar} from "../../../../../misc/Snackbar";
import {ChangePreviewContext} from "../../ShopDrawingChangePreview";

export function ShortCodeEdit(props: {
    value: ShortCode
    disabled?: boolean;
    mode?: "create"
    onDone?: () => void
}) {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const type = useHwLibraryType();
    const r = value;
    const items = value.items || [];
    const ctx = useContext(EditProductContext);
    const reloadCtx = useContext(ReloadContext)
    const reloadRef = useSyncedRef(reloadCtx.triggerReload)
    const onDoneRef = useSyncedRef(props.onDone)

    const snack = useSnackbar();
    const update = useAsyncAction(async (input: ShortCode) => {
        try {
            await api.hardwareLibrary.upsertShortcode(input);
        } catch (e: any) {
            snack.error(e.toString())
            return;
        }

        onDoneRef.current?.();
        reloadRef.current();
    }, []);

    const confirm = useContext(ConfirmContext);
    const editing = value !== props.value || props.mode === "create";

    const [rowRef, setRowRef] = useState<HTMLTableRowElement | null>(null);

    useFocusMode({
        enabled: editing,
        focusRef: rowRef,
        adjustFocus: (input: HTMLElement, retryLater: () => void) => {
            const tbl = input.parentElement;
            if(!tbl) {
                retryLater()
                return [];
            }

            return Array.from(tbl.children).filter(f => {
                // @ts-ignore
                return f.dataset.shortcode?.toString() === value.id.toString()
            })
        }
    })

    const changeset = useContext(ChangesetContext);
    const previewChangeset = useContext(ChangePreviewContext);
    const greyed = props.mode !== "create" && (changeset.proposing || previewChangeset.enabled) && (!value.backup || value.archived === false);

    function checkBg(key: keyof ShortCode) {
        if(!props.value.backup) return undefined;
        return props.value[key] !== props.value.backup[key] ? "white" : undefined
    }

    return (
        <>
            <tr data-shortcode={value.id} ref={setRowRef} style={{backgroundColor: greyed ? cellInActive: undefined}} key="create-name">
                <EditCell
                    colSpan={1}
                    disabled={props.disabled}
                    onChange={name => {
                        setValue(Object.assign({}, value, {
                            value: name,
                        }))
                    }}
                    placeholder="Shortcode (e.g. SK)"
                    className="multi-row-cell" value={r.value}
                    style={{
                        backgroundColor: checkBg("value"),
                    }}
                />
                <EditCell
                    colSpan={5}
                    disabled={props.disabled}
                    onChange={description => {
                        setValue(Object.assign({}, value, {
                            description: description,
                        }))
                    }}
                    placeholder="Description"
                    className="multi-row-cell" value={r.description}
                    style={{
                        backgroundColor: checkBg("description"),
                    }}
                />
                <IconCell>
                    <IconButton2 disabled={props.disabled} onClick={async () => {
                        const ok = await confirm.confirm("Are you sure you want to delete this shortcode?")
                        if(!ok) return;

                        update.callback(Object.assign({}, value, {
                            archived: true,
                        }))
                    }}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton2>
                </IconCell>
            </tr>
            <tr data-shortcode={value.id} style={{backgroundColor: greyed ? cellInActive: undefined}} key="opening-type">
                <td></td>
                <td colSpan={6}>
                    {r.openingType ? r.openingType + " openings" : <i>Either opening type</i>}
                </td>
            </tr>
            {items.filter(i => i.qty > 0).map((itm, index) => {
                function checkBgInner(key: keyof ShortCodeItem) {
                    if(!itm.backup) return undefined;
                    return itm[key] !== itm.backup?.[key] ? "white" : undefined
                }

                return (
                    <tr data-shortcode={value.id}
                        key={index.toString()}
                        className={greyed ? greyedRowCss : undefined}
                    >
                        <td />
                        <td>
                            <span style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }} onClick={() => itm.id && ctx.show(itm.product)}>{itm.productName}</span>
                        </td>
                        <td>{itm.productCode}</td>
                        <td>{itm.finish}</td>
                        <td>
                            <ProductDimensioned id={itm.product} show={itm.hasDimension}/>
                        </td>
                        {itm.productIsAutoQtyHinge ? <AutoForHingeCell/> :
                            <EditCell
                                disabled={props.disabled}
                                style={{
                                    textAlign: "right",
                                    backgroundColor: checkBgInner("qty"),
                                }}
                                  inputStyle={{textAlign: "right"}}
                                      value={itm.qty.toString()}
                                      onChange={strVal => {
                                          const newQty = parseInt(strVal);

                                          setValue(Object.assign({}, value, {
                                              items: items.map(i => Object.assign({}, i, {
                                                  qty: itm.id === i.id ? newQty : i.qty,
                                              })),
                                          }))
                                      }}
                            />}
                        <IconCell>
                            <IconButton2 disabled={props.disabled} onClick={() => {
                                setValue(Object.assign({}, value, {
                                    items: items.map(i => Object.assign({}, i, {
                                        qty: itm.id === i.id ? 0 : i.qty,
                                    })),
                                }))
                            }}>
                                <RemoveCircleOutlineIcon fontSize="inherit"/>
                            </IconButton2>
                        </IconCell>
                    </tr>)
            })}
            <tr key={r.id + "-add"} data-shortcode={value.id} className={greyed ? greyedRowCss : undefined}>
                <td />
                <td colSpan={5} className="no-pad">
                    <ProductLookup
                        disabled={props.disabled}
                        kind={type === "hardware" ? "hardware" : "div-10"}
                        placeholder="Start typing to add product"
                        align="right"
                        onSelect={async (v: ProductSearchResult) => {
                            const existing = items.find(i => i.product === v.id);
                            if(existing) {
                                setValue(Object.assign({}, value, {
                                    items: items.map(i => Object.assign({}, i, {
                                        qty: v.id === i.product ? i.qty+1: i.qty,
                                    })),
                                }))

                                return
                            }

                            setValue(Object.assign({}, value, {
                                items: items.concat(toShortCodeItem(v)),
                            }))
                        }} />
                </td>
                <td />
            </tr>
            {editing && <tr data-shortcode={value.id}>
                <td colSpan={7} style={{padding: 8}}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            {update.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="outlined" color="primary"
                                    onClick={() => {
                                        setValue(props.value)
                                        if(props.onDone) props.onDone();
                                    }}
                            >Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" variant="contained"
                                    color="primary"
                                    onClick={() => update.callback(value)}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </td>
            </tr>}
        </>
    )
}

function toShortCodeItem(input: ProductSearchResult): ShortCodeItem {
    return {
        id: 0,
        shortcode: 0,
        product: input.id,
        productName: input.categoryName,
        manufacturer: input.manufacturer,
        manufacturerName: input.manufacturerName,
        supplier: input.supplier,
        supplierName: input.supplierName,
        defaultHwGroup: input.defaultHwGroup,
        productCode: input.productCode,
        finish: input.finish,
        hasDimension: !!(input.dimHeightFormula || input.dimLengthFormula || input.dimWidthFormula),
        productIsAutoQtyHinge: input.isAutoQtyHinge,
        qty: 1,
    }
}

const greyedRowCss = css({
    "& td": {
        backgroundColor: cellInActive,
    },
    "& input": {
        backgroundColor: "transparent",
    }
})
import {ContainerPage} from "../../../../misc/pdf/parts/ContainerPage";
import {TitleTextBlock} from "../../../../misc/pdf/parts/TitleTextBlock";
import {grey} from "@mui/material/colors";

export function ImageTilePage(props: {
    title: string;
    images: string[];
}) {
    const nPerPage = 6;
    return (
        <>
            {range(0, Math.ceil(props.images.length / nPerPage)-1).map((row, index) =>
                <ContainerPage>
                    <TitleTextBlock title={props.title}>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{
                                flex: 1,
                                display: "flex", flexDirection: "row",
                            }}>
                                {/* first 3 */}
                                {range(row * nPerPage, row * nPerPage + 2).map((index) =>
                                    <div key={index.toString()} style={{
                                        flex: 1,
                                        border: index < props.images.length ? "1px solid " + grey["200"] : "none",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        backgroundImage: index < props.images.length ? "url(" + props.images[index] + ")" : "none",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        height: "100%",
                                        width: "33%",
                                    }}></div>
                                )}
                            </div>
                            <div style={{
                                flex: 1,
                                display: "flex", flexDirection: "row",
                                paddingTop: 8,
                            }}>
                                {/* second 3 */}
                                {range(row * nPerPage + 3, row * nPerPage + 5).map((index) =>
                                    <div key={index.toString()} style={{
                                        flex: 1,
                                        border: index < props.images.length ? "1px solid " + grey["200"] : "none",
                                        backgroundImage: index < props.images.length ? "url(" + props.images[index] + ")" : "none",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        height: "100%",
                                        width: "33%",
                                    }}>
                                    </div>
                                )}
                            </div>
                        </div>
                    </TitleTextBlock>
                </ContainerPage>
            )}
        </>
    )
}

function range(start: number, end: number) {
    let list: number[] = [];

    for(let i = start; i <= end; i++) {
        list.push(i)
    }

    return list;
}
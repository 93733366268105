import {AutoComplete} from "../openings/AutoComplete";
import {useProjectId} from "../ProjectName";
import {HardwareGroup} from "../../../../api/QuoteHardware";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {useHwAPI} from "./HardwareGeneric";
import {useAlternative} from "../../quote/alternative/Alternative";

export function GroupNumberLookup(props: {
    onAssign(id: number): void;
    onCreate(name: string): void;
}) {
    const project = useProjectId();
    const api = useHwAPI();
    const alternative = useAlternative();

    return (
        <AutoComplete<HardwareGroup>
            useNativeInput={true}
            placeholder="Assign to group by entering group #"
            search={async search => {
                const list = await api.list({
                    project,
                    alternative: alternative,
                    search
                })

                return list.data;
            }}
            className={groupNumberClass}
            value={null}
            equals={(a, b) => a.id === b.id}
            makeLabel={v => v.name}
            onSelect={v => props.onAssign(v.id)}
            onCreate={v => props.onCreate(v)} />
    )
}

const groupNumberClass = css({
    "&::placeholder": {
        fontStyle: "italic",
        color: grey["500"]
    }
})
import {useUser} from "./Permission";
import {orange} from "@mui/material/colors";

export function TestingBanner() {
    const u = useUser();
    if(!u) return null;
    if(u.isProductionEnv) return null;

    return (
        <div style={{width: "100%", padding: 4, textAlign: "center", backgroundColor: orange["800"], color: "white"}}>
            This is a testing environment
        </div>
    )
}
import {SubAPI} from "./SubAPI";
import {Div10} from "./Pricing";
import {Paginated} from "./Users";
import {ProductReplacement} from "./QuoteHardware";

export class QuoteDiv10 extends SubAPI {
    replaceProducts(input: {
        project: number;
        replacements: ProductReplacement[]
    }) {
        return this.fetcher.post("/api/quote-div-10/replace-product", input)
    }

    list(props: {
        project: number,
        alternative: number,
        tab?: string,
        offset: number
        previewChangeset?: number
    }) {
        return this.fetcher.get<Paginated<Div10Row>>("/api/quote-div-10", props);
    }

    summary(props: {project: number, alternative: number}) {
        return this.fetcher.get<{category: string; qty: number}[]>("/api/quote-div-10/summary", props)
    }

    upsert(props: Partial<Div10Row>) {
        return this.fetcher.post<{id: number}>("/api/quote-div-10", props);
    }

    reOrder(props: {
        project: number;
        quoteAlternative: number
        idOrder: number[]
        tab: string;
    }) {
        return this.fetcher.post<{id: number}>("/api/quote-div-10/re-order", props);
    }
}

export type Div10Row = {
    id: number;

    category: string;
    room: string;

    released?: boolean;

    note: string;
    product: number;

    hwPrepFile?: number;
    status: string;

    productCategoryName: string;
    productCategory: number;
    productCode: string;
    productName: string;
    finish: string;
    manufacturer: number;
    manufacturerName: string;
    manufacturerShortName: string;
    dimensions: string;
    dimLength: string;
    dimHeight: string;
    dimWidth: string;
    qty: number;
    archived: boolean;

    sortOrder: number;

    div10: Div10;

    project: number;
    alternative: number;

    backup?: Div10Row;
    backupDiv10?: Div10

    overrideForChangeReleased?: boolean;
}
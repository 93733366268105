import {Grid, Switch, TextField, Select, MenuItem} from "@mui/material";
import {useEffect, useState} from "react";
import {Dimension} from "./Dimension";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {RoundType} from "../../../../api/Products";

export function DimensionInput(props: {
    name: string;
    value: string;
    onChange(value: string): void;

    roundType: RoundType;
    onRoundTypeChange(value: RoundType): void;

    roundBy: string;
    onRoundByChange(value: string): void;
}) {
    const [lengthEnabled, setLengthEnabled] = useState(props.value !== "");
    const [lengthHelpText, setLengthHelpText] = useState("");
    const [focused, setFocused] = useState(false);

    const onChangeRef = useSyncedRef(props.onChange);
    const value = props.value;

    useEffect(() => {
        if(!lengthEnabled) {
            onChangeRef.current("");
            setLengthHelpText("");
            return;
        }

        if(focused) {
            return;
        }

        try {
            Dimension.validate(value)
            setLengthHelpText("")
        } catch (e: any) {
            setLengthHelpText(e.toString());
        }

    }, [value, lengthEnabled, focused, onChangeRef]);


    return (
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <TextField
                    fullWidth
                    label={props.name}
                    InputLabelProps={{shrink: true}}
                    disabled={!lengthEnabled}
                    size="small"
                    variant="outlined"
                    value={value}
                    onFocus={e => setFocused(true)}
                    onBlur={e => setFocused(false)}
                    onChange={e => props.onChange(e.target.value)}
                    helperText={lengthHelpText}
                    error={!!lengthHelpText}
                    onClick={e => {
                        if(!lengthEnabled) {
                            setLengthEnabled(true);
                            const target = e.currentTarget;
                            setTimeout(( )=> {
                                Array.from(target.getElementsByTagName("input")).map(i => i.focus())
                            }, 10)
                        }
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Select fullWidth size="small" value={props.roundType} onChange={(e) => {
                    props.onRoundTypeChange(e.target.value as any);

                    if(e.target.value === "none") {
                        props.onRoundByChange("")
                    }
                }}>
                    <MenuItem value="none">No Rounding</MenuItem>
                    <MenuItem value="round-up-by-fixed-interval">Fixed interval</MenuItem>
                    <MenuItem value="round-up-by-list-interval">List of sizes</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={2}>
                <Switch checked={lengthEnabled} onChange={(e, checked) => setLengthEnabled(checked)} />
            </Grid>
            {props.roundType === "round-up-by-fixed-interval" && <>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <TextField
                        style={{marginTop: 8}}
                        label={"Round up to nearest"}
                        fullWidth
                        placeholder={"1/2\""}
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        value={props.roundBy}
                        onChange={e => props.onRoundByChange(e.target.value)}
                    />
                </Grid>
            </>}
            {props.roundType === "round-up-by-list-interval" && <>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <TextField
                        style={{marginTop: 8}}
                        label={"Round up to nearest size (comma separate)"}
                        fullWidth
                        placeholder={"1/2\", 3/4\", 2\""}
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        value={props.roundBy}
                        onChange={e => props.onRoundByChange(e.target.value)}
                    />
                </Grid>
            </>}
        </Grid>
    )
}
import {CategoryKind, CreateProduct} from "./CreateProduct";
import {ProductFind} from "./ProductLookup";
import {Product} from "../../../../api/Products";

export function ProductEdit(props: {
    value: ProductFind;
    mode: CategoryKind;
    skipToDimensionScreen?: boolean;
    onClose(value?: Product): void;
}) {

    return <CreateProduct
        edit={props.value}
        mode={props.mode}
        skipToDimensionScreen={props.skipToDimensionScreen}
        onDone={(value: Product) => props.onClose(value)}
        onCancel={() => props.onClose()} />
}
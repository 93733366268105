import {
    Button,
    DialogActions,
    DialogTitle,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    Switch, FormControlLabel
} from "@mui/material";
import React, {useState} from "react";
import {DimensionInput} from "./DimensionInput";
import {Loader} from "../../../../misc/Loader";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {Dimension} from "./Dimension";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {Product, RoundType} from "../../../../api/Products";
import {CategoryKind} from "./CreateProduct";

export function SetupAutoDimension(props: {
    mode: CategoryKind;
    product: Product;
    onCancel(): void;
    onDone(): void;
}) {
    const product = props.product;

    const [length, setLength] = useState(product.dimLengthFormula || "");
    const [roundLength, setRoundLength] = useState(product.dimLengthRoundInterval || "")
    const [roundTypeLength, setRoundTypeLength] = useState(product.dimLengthRoundType || "none");

    const [width, setWidth] = useState(product.dimWidthFormula || "");
    const [roundWidth, setRoundWidth] = useState(product.dimWidthRoundInterval || "")
    const [roundTypeWidth, setRoundTypeWidth] = useState(product.dimWidthRoundType || "none");

    const [height, setHeight] = useState(product.dimHeightFormula || "");
    const [roundHeight, setRoundHeight] = useState(product.dimHeightRoundInterval || "")
    const [roundTypeHeight, setRoundTypeHeight] = useState(product.dimHeightRoundType || "none");

    const onDoneRef = useSyncedRef(props.onDone);
    const submit = useAsyncAction(async input => {
        try {
            if (input.length) Dimension.validate(input.length);
            if (input.roundTypeLength && input.roundTypeLength !== "none") validateRound(input.roundTypeLength, input.roundLength);

            if (input.width) Dimension.validate(input.width);
            if (input.roundTypeWidth && input.roundTypeWidth !== "none") validateRound(input.roundTypeWidth, input.roundWidth)

            if (input.height) Dimension.validate(input.height);
            if (input.roundTypeHeight && input.roundTypeHeight !== "none") validateRound(input.roundTypeHeight, input.roundHeight);
        } catch(e) {
            // error will be reported by input
            throw new Error("Forumla error");
        }

        await api.products.setAutoDimensionRules(input)
        onDoneRef.current()
    }, []);

    if(props.mode === "div-10") {
        return (
            <Dialog open={true}>
                <DialogTitle>Dimensions</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <Grid container spacing={1} direction="column" style={{fontSize: "0.9rem"}}>
                        <Grid item>
                            {props.product.categoryName} ({props.product.manufacturerName} {props.product.productCode})
                        </Grid>
                        <Grid item style={{width: "100%"}}>
                            <div style={{height: 16}} />
                            <FormControlLabel control={
                                <Switch checked={length !== ""} onChange={(e, checked) => setLength(checked ? "0.1234\"" : "")} />
                            } label="Length" />

                            <div style={{height: 16}} />
                            <FormControlLabel control={
                                <Switch checked={width !== ""} onChange={(e, checked) => setWidth(checked ? "0.1234\"" : "")} />
                            } label="Width" />

                            <div style={{height: 16}} />
                            <FormControlLabel control={
                                <Switch checked={height !== ""} onChange={(e, checked) => setHeight(checked ? "0.1234\"" : "")} />
                            } label="Height" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <Loader {...submit} />
                        </Grid>
                        <Grid item>
                            <Button onClick={props.onCancel}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => submit.callback({
                                product: props.product.id,
                                length: length || null,
                                width: width || null,
                                height: height || null,
                            })}>Update</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Configure Auto-Dimension</DialogTitle>
            <DialogContent>
                <DialogContentText>

                </DialogContentText>
                <Grid container spacing={1} direction="column" style={{fontSize: "0.9rem"}}>
                    <Grid item>
                        {props.product.categoryName} ({props.product.manufacturerName} {props.product.productCode})
                    </Grid>
                    <Grid item style={{width: "100%"}}>
                        <div style={{height: 16}} />

                        <DimensionInput
                            name="Length"
                            value={length}
                            onChange={setLength}
                            roundType={roundTypeLength}
                            onRoundTypeChange={setRoundTypeLength}
                            roundBy={roundLength}
                            onRoundByChange={setRoundLength}
                        />
                        <div style={{height: 16}} />
                        <DimensionInput
                            name="Width"
                            value={width}
                            onChange={setWidth}
                            roundType={roundTypeWidth}
                            onRoundTypeChange={setRoundTypeWidth}
                            roundBy={roundWidth}
                            onRoundByChange={setRoundWidth}
                        />
                        <div style={{height: 16}} />
                        <DimensionInput
                            name="Height"
                            value={height}
                            onChange={setHeight}
                            roundType={roundTypeHeight}
                            onRoundTypeChange={setRoundTypeHeight}
                            roundBy={roundHeight}
                            onRoundByChange={setRoundHeight}
                        />
                    </Grid>
                    <Grid item>
                        This creates rules so the software can automatically calculate dimensions for you.
                        This works just like Excel formulas.
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                Seal example:<br />
                                Length: door.width + door.height * 2 + 5”
                            </Grid>
                            <Grid item xs={6}>
                                Threshold example:<br />
                                Width: frame.width - 3”<br />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <Loader {...submit} />
                    </Grid>
                    <Grid item>
                        <Button onClick={props.onCancel}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => submit.callback({
                            product: props.product.id,

                            length: length || null,
                            roundLength: roundLength || null,
                            roundTypeLength: roundTypeLength || null,

                            width: width || null,
                            roundWidth: roundWidth || null,
                            roundTypeWidth: roundTypeWidth || null,

                            height: height || null,
                            roundHeight: roundHeight || null,
                            roundTypeHeight: roundTypeHeight || null,

                        })}>Update</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}


function validateRound(type: RoundType, length: string | null) {
    if(type === "round-up-by-fixed-interval") {
        if(!length) throw new Error("Missing rounding interval");
        Dimension.validate(length)
        return;
    }

    if(type === "round-up-by-list-interval") {
        if(!length) throw new Error("Missing rounding list");
        length.split(",").map(i => Dimension.validate(i))
        return;
    }

    return
}
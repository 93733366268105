import {Button, ButtonProps, Menu, MenuItem} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {useState} from "react";

type Option = {
    name: string;
    disabled?: boolean;
    onClick: () => void;
}

export function MenuButton(props: ButtonProps & {
    options: (Option|null)[]
}) {
    const {options, ...other} = props;
    const [open, setOpen] = useState<HTMLElement|null>(null);

    return (
        <>
            <Button color="inherit" size="small" variant="outlined" endIcon={<ArrowDropDown />} onClick={e => setOpen(e.currentTarget)} {...other} />
            {open && <Menu open anchorEl={open} onClose={() => setOpen(null)}>
                {(options.filter(v => !!v) as Option[]).map((o: Option, index) => (
                    <MenuItem disabled={o.disabled} key={index} onClick={() => {
                        setOpen(null);
                        o.onClick();
                    }}>{o.name}</MenuItem>
                ))}
            </Menu>}
        </>
    );
}
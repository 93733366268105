import {stopName, useRouteInfo} from "./RouteDetail";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {Alert, Button, styled} from "@mui/material";
import {cardStyle} from "./RouteStop";
import {grey} from "@mui/material/colors";
import {EditReceivingItem} from "../receiving/EditReceivingItem";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import React, {useEffect, useState} from "react";
import {RouteStopGroupItem} from "../../../api/Logistics";
import {api} from "../../../api/API";
import {ReceiveItem} from "./ReceiveItem";
import {DamagedBox} from "./DamagedBox";

const blankArray: any[] = [];

export function RouteStopGroup() {
    const {
        stops,
        route,
        id
    } = useRouteInfo();
    const nav = useNavigate();
    const params = useParams<{stopId: string, groupId: string}>();
    const stopId = parseInt(params.stopId || "0");
    const groupId = parseInt(params.groupId || "0");
    const stop = stops.asList.find(v => v.id === stopId);
    const groups = stop?.groups || []
    const group = groups.find(v => v.id === groupId);
    const rawItems = group?.items || blankArray
    const [contextMenu, setContextMenu] = useState<RouteStopGroupItem | null>(null);

    const notDeliverProducts = useAsync2(async (input) => {
        if(!input.purchaseOrder) return [];
        if(!input.atGroup) return [];

        return api.logistics.listSplitRouteItemsToSkip({
            atGroup: input.atGroup
        })
    }, {
        purchaseOrder: group?.dropoffPurchaseOrder,
        atGroup: groupId,
    }, [group?.dropoffPurchaseOrder, groupId]);

    const [items, setItems] = useState(rawItems)
    useEffect(() => {
        setItems(rawItems)
    }, [rawItems]);

    const submit = useAsyncAction(async (input: {
        group: number;
        items: RouteStopGroupItem[]
    }) => {
        await api.logistics.upsertStopGroupItems(input)
        nav(`/logistics/driver/${id}/${stopId}`);
    }, []);

    const submitText = group && stop ?
        group.delivery ?
            stop.tykelWarehouse ? "Pickup Delivery" :
                "Drop off Delivery"
            :
        group.pickupPoNumber ? "Pickup PO"
            : "Drop off PO"
        : "Submit";

    const [showDamagedBox, setShowDamagedBox] = useState<RouteStopGroupItem | null>(null);

    return (
        <MobileContainer topCenter={route.result ? "#" + route.result.id : "Loading.."} back={`/logistics/driver/${id}`}>
            <div style={{flex: 1, overflow: "auto", display: "flex", flexDirection: "column"}}>
                <div className={cardStyle} style={{borderBottom: "1px solid " + grey["500"]}}>
                    <div style={{fontSize: "1.5rem", fontWeight: 500}}>{stop && stopName(stop)}</div>
                    <div style={{fontSize: "1rem"}}>{group?.name}</div>
                </div>

                <div style={{flex: 1}}>
                    <Loader {...stops} />
                    <Loader {...route}>
                        {() => {
                            if (!group) {
                                return (
                                    <div style={{padding: 8}}><Alert>Invalid URL, please go back and try again</Alert></div>
                                )
                            }

                            return (
                                <>
                                    <div style={{backgroundColor: "white"}}>
                                        <div style={{padding: 4, fontSize: "0.9rem"}}>Long press items for more options
                                        </div>
                                        <table style={{width: "100%", borderCollapse: "collapse"}}>
                                            <tbody>
                                            {items.map(v => (
                                                <ReceiveItem
                                                    key={v.id}
                                                    value={v}
                                                    onLongPress={() => {
                                                        setContextMenu(v);
                                                    }}
                                                    onEdit={updated => {
                                                        setItems(old => old.map(o => o.id === updated.id ? updated : o))
                                                        if(updated.qtyDamaged && updated.qtyDamaged > 0) {
                                                            if(updated.deliveryBox) {
                                                                setShowDamagedBox(updated);
                                                            }
                                                        }
                                                    }}
                                                    isPickup={!!group.pickupPurchaseOrder || (stop?.tykelWarehouse && !!group.delivery)}
                                                />
                                            ))}
                                            </tbody>
                                        </table>
                                        <Loader {...notDeliverProducts} />
                                        {notDeliverProducts.asList.length > 0 &&
                                            <>
                                                <BlueSpace style={{height: 16}}/>
                                                <TitleBlue style={{padding: 8, textAlign: "center"}}>
                                                    <div style={{fontSize: "1.2rem", fontWeight: 800}}>Don't deliver to site
                                                    </div>
                                                    <div style={{marginTop: 0, lineHeight: "1em", fontSize: "0.8rem"}}>Part
                                                        of
                                                        PO,
                                                        but need to go back to warehouse
                                                    </div>
                                                </TitleBlue>
                                                <table style={{width: "100%", borderCollapse: "collapse"}}>
                                                    <tbody>
                                                    {notDeliverProducts.asList.map((d, index) =>
                                                        <ReceiveItem key={d.id} value={d} />)}
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                    </div>
                                </>
                            )
                        }}
                    </Loader>
                </div>

                {showDamagedBox && <DamagedBox value={showDamagedBox} onDone={updated => {
                    if(!showDamagedBox) return;
                    setItems(old => old.map(o => o.id === showDamagedBox.id ? updated : o))
                    setShowDamagedBox(null);
                }} />}

                <div className={cardStyle} style={{padding: 16}}>
                    <Loader {...submit} />
                    <div style={{height: 16}}/>
                    <Button color="secondary" variant="contained"
                            fullWidth
                            disabled={!!items.find(v => v.qtyReceived === null)}
                            onClick={() => {
                                submit.callback({
                                    group: groupId,
                                    items: items
                                })
                            }}>{submitText}</Button>
                </div>

                {contextMenu && <EditReceivingItem<RouteStopGroupItem>
                    value={contextMenu}
                    onChange={v => {
                        setItems(old => old.map(o => o.id === v.id ? v : o))
                        setContextMenu(null);
                    }}
                    onCancel={() => setContextMenu(null)}
                />}
            </div>
        </MobileContainer>
    )
}

const TitleBlue = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.main,
    color: "white",
}))

const BlueSpace = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.light,
}))
import {formatCents} from "nate-react-api-helpers";
import {useTableData} from "../../../misc/scroller/Table";
import {payableItemsTableName} from "./Items";
import {PurchaseOrder, PurchaseOrderItem} from "../../../api/Logistics";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import React from "react";
import {taxRate} from "../../logistics/purchasing/pdf/PurchaseOrderPDF";

export function ItemsFooter(props: {
    value: PurchaseOrder;
}) {
    const data = useTableData<PurchaseOrderItem>(payableItemsTableName)
    const subTotal = data.reduce((acc, value) => acc + value.unitPriceCents * value.qty, 0);

    const taxAmount = Math.round(subTotal * taxRate / 100);
    const total = subTotal + taxAmount;

    return (
        <>
            <table className={tableCls} style={{width: "100%"}}>
                <tr>
                  <td></td>
                  <td style={{width: 100}}>Taxes</td>
                  <td style={{width: 50}}>{taxRate}%</td>
                  <td style={{width: 80}}>{formatCents(taxAmount)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td>{formatCents(total)}</td>
                </tr>
            </table>
        </>
    )
}

const tableCls = css({
    fontSize: 13,
    borderCollapse: "collapse",

    "& td": {
        border: "1px solid " + grey["300"],
        paddingLeft: 8,
        paddingRight: 8,
    },

    "& td:not(:first-child)": {
        textAlign: "right",
    }
})
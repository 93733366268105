import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {Title} from "../files/Files";
import {Button, Card} from "@mui/material";
import {Loader} from "../../../../misc/Loader";
import {TimelineItem} from "../../../../api/ProjectTimeline";
import React, {useContext, useState} from "react";
import {EditTimelineItem} from "./EditTimelineItem";
import {MenuPopout} from "../links/MenuPopout";
import {ConfirmContext} from "../../../../misc/Confirm";
import {useSnackAction} from "../../shopdrawing/hardware/SnackAction";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import {Indicator} from "./Indicator";

export function Timeline() {
    const project = useProjectId();
    const listRequest = useAsync2(async input => {
        const list = await api.projectTimeline.list(input)
        return list || [];
    }, {project: project}, [project])
    const [showAdd, setShowAdd] = useState(false);
    const [edit, setEdit] = useState<TimelineItem|null>(null);
    const confirm = useContext(ConfirmContext);

    const reloadRef = useSyncedRef(listRequest.reload)
    const archive = useSnackAction(async (input) => {
        await api.projectTimeline.upsert(input)
        reloadRef.current();
    }, {
        success: "Archived",
    });

    const now = moment.utc();

    return (
        <Card style={{padding: 16, flex: 1}}>
            <Title>Timeline</Title>
            <Loader {...listRequest}>
                {(list: TimelineItem[]) => <>

                    <div style={{
                        marginLeft: 0,
                        marginRight: -16,
                        paddingRight: 16,
                        borderLeft: "1px solid black",
                        position: "relative",
                        overflow: "auto",
                        height: 150,
                    }}>
                        {list.length > 0 && moment.utc(list[0].date).isAfter(now) &&
                            <Indicator now={now} before={now} after={moment.utc(list[0].date)} />}

                        {list.map((l: TimelineItem, index: number) => <div
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                fontSize: "0.8rem",
                                alignItems: "flex-start",
                                position: "relative",
                            }}>

                            {moment.utc(l.date).isSameOrBefore(now)
                                && hasNext(list, index) && moment.utc(list[index+1].date).isAfter(now) &&
                                <Indicator now={now} before={moment.utc(l.date)} after={moment.utc(list[index+1].date)} />}

                            {moment.utc(l.date).isSameOrBefore(now)
                                && !hasNext(list, index) &&
                                <Indicator now={now} before={moment.utc(l.date)} after={now} />}

                            <div style={{
                                width: 10, borderBottom: "1px solid black", marginRight: 5,
                                position: "relative", top: "0.75em",
                            }}></div>

                            <div style={{width: 60}}>
                                {moment.utc(l.date).format("MMM D")}
                            </div>
                            <div style={{flex: 1, paddingRight: 4}}>
                                {l.name}
                            </div>

                            <MenuPopout style={{fontSize: "1rem"}} options={[
                                {
                                    display: "Edit",
                                    onClick: () => setEdit(l),
                                },
                                {
                                display: "Archive",
                                danger: true,
                                onClick: async () => {
                                    const ok = await confirm.confirm("Are you sure you want to remove this item?")
                                    if(!ok) return;

                                    archive.callback(Object.assign({}, l, {
                                        archived: true,
                                    }))
                                }
                            }]}>
                                <MoreHorizIcon fontSize="inherit" />
                            </MenuPopout>
                        </div>)}
                    </div>
                    {list.length === 0 && <div style={{fontSize: "0.75rem"}}>
                        Your timeline is empty
                    </div>}
                </>}
            </Loader>

            <div style={{height: 8}} />

            <Button onClick={() => setShowAdd(true)} size="small" fullWidth variant="outlined">Add Item</Button>
            {showAdd && <EditTimelineItem project={project} onDone={value => {
                setShowAdd(false);
                if(value) listRequest.reload();
            }} />}
            {edit && <EditTimelineItem project={project} value={edit} onDone={value => {
                setEdit(null);
                if(value) listRequest.reload();
            }} />}
        </Card>
    )
}

function hasNext<T>(list: T[], index: number) {
    return index+1 < list.length
}
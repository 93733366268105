import { SubAPI } from "./SubAPI";
import {DateString} from "./Projects";

export class Tasks extends SubAPI {
    list() {
        return this.fetcher.get<Task[]>("/api/tasks");
    }

    markAllAsRead() {
        return this.fetcher.post("/api/tasks/mark-all-as-read", {});
    }

    markAsRead(id: number) {
        return this.fetcher.post("/api/task/mark-as-read", {
            id: id,
        });
    }

    archive(id: number) {
        return this.fetcher.post("/api/task/archive", {
            id: id,
        });
    }
}

export type Task = {
    id: number;
    content: string;
    read: boolean;
    createdAt: DateString;
    url: string;
    userArchiveable: boolean;
}
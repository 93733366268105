import {isProjectFile} from "./Rename";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import React from "react";
import {ProjectDir, ProjectFile} from "../../../../api/ProjectFiles";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

export function Icon(props: {
    value: ProjectFile|ProjectDir;
}) {
    if(isProjectFile(props.value)) {
        if(props.value.linkedChangeSet) {
            return <BrowserUpdatedIcon />
        }

        return <InsertDriveFileIcon />
    }

    return <FolderIcon />;
}
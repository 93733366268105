import React, {useCallback, useContext, useMemo, useState} from "react";
import {ChangesetContext} from "../changeset/ChangesetContext";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {AdjustCol, CellCustomize, Column, lookupNestedProp} from "../../../../misc/scroller/Table";
import {blueGrey, deepOrange, green, grey, yellow} from "@mui/material/colors";
import {cellInActive} from "./cellconst";
import {PriceLine} from "../../../../api/Pricing";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {RowActions} from "./RowActions";
import {isPriceLine2, PriceLine2, PriceRow, ProposingTag} from "./Frames";
import {groupByArr, orderByAscending, selectMany} from "nate-react-api-helpers";
import {css} from "@emotion/css";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";

export function useProposingKit() {
    const ctx = useContext(ChangesetContext);
    const previewChangeset = useContext(ChangePreviewContext);
    const proposing = ctx.proposing || previewChangeset.enabled;

    const proposingRef = useSyncedRef(proposing);
    const [expanded, setExpanded] = useState<number[]>([])
    const expandedRef = useSyncedRef(expanded);

    const cellCustomize = useMemo(() => {
        const value: CellCustomize<PriceRow> = {
            backgroundColor: (row: PriceRow, col: Column<PriceRow>) => {
                const key = col.editKey as any;
                let changed = false;

                if(row.kind === "price-rider") {
                    const val = row.value
                    if(val.backup) {
                        val.name !== val.backup.name && (changed = true);
                        val.qty !== val.backup.qty && (changed = true);
                        val.unitCostCents !== val.backup.unitCostCents && (changed = true);
                        val.markupPercent !== val.backup.markupPercent && (changed = true);
                        val.markupCents !== val.backup.markupCents && (changed = true);
                        val.extendedPrice !== val.backup.extendedPrice && (changed = true);
                    }
                }

                if(row.kind === "price") {
                    const input = row.value;

                    if (key) {
                        if (input.kind === "current") {
                            // eslint-disable-next-line eqeqeq
                            changed = lookupNestedProp(input, key) != lookupNestedProp(input.previous, key)
                            if (!changed && input.previous.qty === 0) changed = true;
                        }
                    } else {
                        if (input.kind === "current") {
                            let value = col.render(row, col)?.toString().trim()
                            let other = col.render({
                                kind: "price",
                                value: input.previous as any,
                            } as any, col)?.toString().trim()

                            changed = value !== other;
                        }
                    }

                    if (input.kind === "previous") {
                        return grey["300"];
                    }

                    // @ts-ignore
                    if (col.customizeKeepWhite) {
                        return "white"
                    }

                    if (changed) {
                        return "white"
                    }

                    switch (input.proposingTag) {
                        case "new":
                            return green["100"];
                        case "replace":
                            return yellow["100"]
                        case "remove":
                            return deepOrange["100"];
                    }
                }

                if (changed) {
                    return "white"
                }

                if(row.kind === "price-rider") {
                    return blueGrey["100"]
                }

                return cellInActive;
            },
        }

        return value;
    }, []);

    const transformRows = useCallback((input: PriceLine[], options?: {
        printout?: boolean
    }): PriceLine2[] => {
        if(!proposingRef.current) return input as any

        const list = groupByArr(input, v => v.productType + "." + v.categoryName + "." + v.opening + "." + v.div10?.category + "." + v.productCode);

        const forPrint = options?.printout === true;

        // break apart doors into separate lines for pairs
        list.forEach(l => {
            try {
                if (l.values.length !== 6) return;
                if (l.values.some(v => v.productType !== "door")) return;

                let current = l.values.filter(v => !!v.projectChangeset);
                let zeros = current.filter(v => !!v.baseProjectPrice);
                let news = current.filter(v => !v.baseProjectPrice);
                let base = l.values.filter(v => !v.projectChangeset);

                if (zeros.length !== 2 && news.length !== 2 && base.length !== 2) return;

                const diffA = doorDiffKeys(news[0], zeros[0]);
                const diffB = doorDiffKeys(news[1], zeros[0]);
                if (zeros[0].baseProjectPrice !== base[0].id) {
                    // swap 0 and 1
                    const t = base[0]
                    base[0] = base[1]
                    base[1] = t;
                }

                if (diffA.length < diffB.length) {
                    list.push({
                        key: l.key,
                        values: [news[0], zeros[0], base[0]],
                    })

                    l.values = [news[1], zeros[1], base[1]];
                } else {
                    list.push({
                        key: l.key,
                        values: [news[1], zeros[0], base[0]]
                    })

                    l.values = [news[0], zeros[1], base[1]];
                }
            } catch (e) {
                debugger;
            }
        })

        // order by sequence number so the final output will be sorted that way too
        orderByAscending(list, o => {
            // just grab the first one b/c all openings should be the same in the group
            return o.values[0].openingSeqNumber || 1000000;
        });

        let out = list.map(v => {
            let current = v.values.filter(v => !!v.projectChangeset);
            let result: PriceLine2[] = [];
            let used: PriceLine[] = [];

            if(current.length === 0) return;

            // check for [removal, addition, base]
            // and build [current, base]
            if(current.length === 2 && v.values.length === 3) {
                const removal = v.values.find(v => v.qty < 0 && !!v.baseProjectPrice)
                const base = v.values.find(v => v.id === removal?.baseProjectPrice);
                const addition = v.values.find(v =>
                    v !== base && v !== removal && v.qty > 0
                    && (v.productType === "frame" || v.productType === "door" || v.productType === "hardware")
                );

                if(isFrameIgnoreChange(removal, base, addition) || isDoorHandingChange(removal, base, addition)) {
                    // ignore change, it's just a handing update (no pricing should be changed)
                    return;
                }

                if(removal && base && addition && removal.qty === -base.qty) {
                    const extPrice = addition.extendedPrice - base.extendedPrice;
                    const unitCost = addition.unitCostCents - base.unitCostCents;
                    const markupCents = (extPrice - base.qty * unitCost);

                    const pricingUpdate = options?.printout ? {} : {
                        extendedPrice: extPrice,
                        unitCostCents: unitCost,
                        markupCents: markupCents,
                        markupPercent: null,
                    }

                    result.push(Object.assign({}, addition, {
                        kind: "current" as "current",
                        proposingTag: "replace" as "replace",
                        initial: addition,
                        removal: removal,

                        previous: Object.assign({}, removal, {
                            extendedPrice: -base.extendedPrice,
                            unitCostCents: -base.unitCostCents,
                        }),
                    }, pricingUpdate))

                    result.push(Object.assign({}, base, {
                        kind: "previous" as "previous",
                        proposingTag: "replace" as "replace",
                        current: addition,
                        qty: -base.qty,
                        extendedPrice: -base.extendedPrice,
                        unitCostCents: -base.unitCostCents,
                    }))

                    return result;
                }
            }

            current.map(cur => {
                let base = v.values.find(v => v.id === cur.baseProjectPrice)

                // can only be 1 frame, so assume these are paired
                if(!base && cur.productType === "frame") {
                    base = v.values.find(v => v !== cur);
                }

                // if single door, assume these are paired
                if(!base && cur.productType === "door" && v.values.length === 2) {
                    base = v.values.find(v => v !== cur);
                }

                if(base) {
                    // no change, ignore
                    if(cur.qty === 0 && base.product === cur.product) {
                        used.push(cur);
                        used.push(base);
                        return;
                    }

                    const isNew = base.qty > 0 && cur.qty === 0;
                    const isRemoval = cur.qty < 0;
                    const isPartialRemoval = cur.qty !== -base.qty;

                    if(forPrint) {
                        if(isRemoval) {
                            result.push(Object.assign({}, cur, {
                                kind: "current" as "current",
                                proposingTag: "remove" as "remove",
                                qty: cur.qty,
                                // current should be negative
                                extendedPrice: cur.extendedPrice,
                                initial: cur,
                                previous: base,
                            }))

                        } else if(isNew) {

                            result.push(Object.assign({}, cur, {
                                kind: "current" as "current",
                                proposingTag: "new" as "new",
                                initial: cur,
                                previous: base,
                            }))

                        } else {
                            result.push(Object.assign({}, cur, {
                                kind: "current" as "current",
                                proposingTag: "replace" as "replace",
                                initial: cur,
                                previous: base,
                            }))

                            result.push(Object.assign({}, base, {
                                kind: "previous" as "previous",
                                proposingTag: "replace" as "replace",
                                current: cur,
                            }));
                        }

                    } else {

                        let pricingUpdate: any = {};
                        const isReplace = cur.qty === -base.qty;
                        let tag: ProposingTag = "change"
                        let prevPricingUpdate: any = {
                            extendedPrice: -base.extendedPrice,
                            unitCostCents: -base.unitCostCents,
                        }

                        if(isRemoval) {
                            tag = "remove"

                            if(isPartialRemoval) {
                                prevPricingUpdate = {}
                                pricingUpdate = {}
                            } else {
                                pricingUpdate = {
                                    extendedPrice: cur.extendedPrice,
                                    unitCostCents: cur.unitCostCents,
                                    markupCents: cur.markupCents,
                                    markupPercent: null,
                                }
                            }
                        } else if(isNew) {
                            tag = "new"
                            pricingUpdate = {};
                        } else if(isReplace) {
                            tag = "replace"
                            pricingUpdate = {
                                extendedPrice: -cur.extendedPrice,
                                markupCents: !!cur.markupCents ? -cur.markupCents : cur.markupCents,
                            };
                        } else {
                            tag = "change"
                            pricingUpdate = {
                                qty: base.qty + base.qty,
                            };
                            prevPricingUpdate = {};
                        }

                        result.push(Object.assign({}, cur, {
                            kind: "current" as "current",
                            proposingTag: tag,

                            initial: cur,
                            previous: Object.assign({}, base, prevPricingUpdate),
                        }, pricingUpdate))

                        if (used.indexOf(base) === -1) {
                            result.push(Object.assign({}, base, {
                                kind: "previous" as "previous",
                                proposingTag: tag,
                                current: cur,
                            }, prevPricingUpdate));
                        }
                    }

                    used.push(cur);
                    used.push(base);

                    return;
                }

                used.push(cur);
                result.push(Object.assign({}, cur, {
                    kind: "current" as "current",
                    initial: cur,
                    proposingTag: "new" as "new",
                    previous: Object.assign({}, cur, {
                        qty: 0,
                        extendedPrice: 0,
                    }),
                }))
            })

            v.values.filter(v => used.indexOf(v) === -1)
                .map(v => {
                    result.push(Object.assign({}, v, {
                        kind: "current" as "current",
                        proposingTag: "remove" as "remove",
                        extendedPrice: v.extendedPrice,
                        initial: v,
                        qty: 0,
                        previous: v,
                    }))
                })

            return result;
        })

        // @ts-ignore
        const list2 = selectMany(out.filter(v => !!v), v => v) as any[];

        if(forPrint) return list2;

        const result = list2.filter(v => {
            if(v.kind === "current") return true;
            return expandedRef.current.indexOf(v.current.id) !== -1;
        })

        console.log("transformed", "proposing=" + proposing, result);

        return result;

    }, [expandedRef, proposingRef, proposing])

    const rowAction = useCallback((input: PriceLine|PriceLine2, tab: string, tab2: string) => {
        if(proposingRef.current && isPriceLine2(input)) {
            if(input.kind === "current") {
                const isExpanded = expandedRef.current.indexOf(input.id) !== -1;

                // check that the entities are different and we actually can expand
                if (input.previous.id !== input.id) {
                    return (
                        <div className={expanderClass} onClick={() => {
                            if (isExpanded) {
                                setExpanded(old => old.filter(v => v !== input.id));
                            } else {
                                setExpanded(old => [...old, input.id]);
                            }
                        }}>
                            <ChevronRightIcon style={{
                                transformOrigin: "center center",
                                transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                            }} fontSize="inherit"/>
                        </div>
                    )
                }
            }

            // make an object returned so that the background customizer won't detect a difference
            return <></>;
        }

        return <RowActions tab={tab} tab2={tab2} row={input}/>
    }, [expandedRef, proposingRef])

    const modifyColumns = useCallback((columns: any[] /* Column<PriceLine2>[] */) => {
        return columns.map(c => AdjustCol(c, {
            disabled: (row: PriceLine2, col: any) => {
                if(row.kind === "previous") return true;
                return false;
            }
        } as any)) as any[]
    }, [])

    const processUpdate = useCallback((input: PriceLine2) => {
        if(!isPriceLine2(input)) return input;
        if(input.kind === "previous") throw new Error("can't update previous value");

        // no qty changes allowed
        input.qty = input.initial.qty;

        if(input.proposingTag === "remove") {

            // do nothing

        } else if(input.proposingTag === "replace") {

            let lastMarkupCents = input.extendedPrice - (input.unitCostCents * input.qty);

            input.extendedPrice = -input.previous.extendedPrice + input.extendedPrice;
            input.unitCostCents = -input.previous.unitCostCents + input.unitCostCents;

            const unitCostAndExtNoChange = input.extendedPrice === input.initial.extendedPrice && input.unitCostCents === input.initial.unitCostCents;

            if(unitCostAndExtNoChange) {

                if(isNum(input.markupPercent)) {
                    input.markupCents = input.markupPercent * 100;
                    input.markupPercent = undefined;
                }

                // @ts-ignore
                let markupCentsDiff = (input.markupCents - lastMarkupCents);

                // @ts-ignore
                input.markupCents = isNum(input.markupCents) ? input.initial.markupCents + markupCentsDiff : input.initial.markupCents;
                input.markupPercent = undefined;
            } else {
                input.markupPercent = undefined;
                input.markupCents = input.initial.markupCents;
            }

        } else if(input.proposingTag === "new") {

            if(input.extendedPrice === -input.initial.extendedPrice) {
                // extendedPrice wasn't changed, but we flipped the sign for display purposes, flip it back.
                input.extendedPrice = input.initial.extendedPrice;
            } else if(input.qty < 0 && input.extendedPrice > 0) {
                // extended price updated, but qty is negative, so flip the sign
                input.extendedPrice = -input.extendedPrice;
            }

            if(input.markupCents && input.qty < 0 && input.markupCents > 0) {
                input.markupCents = -input.markupCents;
            }
        } else {
            input.extendedPrice = input.extendedPrice - input.previous.extendedPrice;
        }

        return input;
    }, [])

    const renderItemName = useCallback((input: PriceLine2, key: keyof PriceLine2) => {
        if(!proposingRef.current) {
            return lookupNestedProp(input, key);
        }

        if(input.qty === 0) {
            return <>{lookupNestedProp(input, key) + " (deleted)"}</>;
        }

        return <>{lookupNestedProp(input, key)}</>;
    }, [proposingRef])

    return {
        renderItemName,
        proposing,
        expanded,
        cellCustomize,
        transformRows,
        rowAction,
        modifyColumns,
        processUpdate,
    }
}

export function isNum(v: any): v is number {
    return v !== null && v !== undefined;
}

function isFrameIgnoreChange(removal?: PriceLine, base?: PriceLine, addition?: PriceLine) {
    if(!removal || !addition) return false;
    if(removal.productType !== "frame") return false;
    if(addition.productType !== "frame") return false;

    const diffKeys = frameDiffKeys(removal, addition);

    if(arrayRemove(diffKeys, ["id", "handing", "screenElevFile"]).length === 0) return true;
    return false;
}

function frameDiffKeys(a: PriceLine, b: PriceLine) {
    return Object.keys(a.frame).map((k: any) => {
        // @ts-ignore
        if(a.frame[k] !== b.frame[k]) return k;
        return "";
    }).filter(v => !!v)
}

function doorDiffKeys(a: PriceLine, b: PriceLine) {
    return Object.keys(a.door).map((k: any) => {
        // @ts-ignore
        if(a.door[k] !== b.door[k]) return k;
        return "";
    }).filter(v => !!v)
}

function isDoorHandingChange(removal?: PriceLine, base?: PriceLine, addition?: PriceLine) {
    if(!removal || !addition) return false;
    if(removal.productType !== "door") return false;
    if(addition.productType !== "door") return false;

    const diffKeys = doorDiffKeys(removal, addition);

    if(arrayRemove(diffKeys, ["id", "handing"]).length === 0) return true;
    return false;
}

function arrayRemove<T>(a: T[], b: T[]) {
    return a.filter(v => b.indexOf(v) === -1);
}

const expanderClass = css({
    fontSize: "1rem",

    "& svg": {
        cursor: "pointer",
        borderRadius: "50%",

        "&:hover": {
            backgroundColor: grey["200"],
        }
    }
})
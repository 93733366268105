import {useContext} from "react";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import PrintIcon from '@mui/icons-material/Print';
import {useSubmittalPreferences} from "../../../../misc/pdf/useSubmittalPreferences";
import {useProject, useProjectId} from "../../shopdrawing/ProjectName";
import {WRAccessoryCutSheetPDF} from "./WRAccessoryCutSheetPDF";
import {api} from "../../../../api/API";
import {PDFInfo, PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import {useIsQuote} from "../QuoteContainer";
import {useAlternative} from "../alternative/Alternative";
import {Company} from "../../../../api/Companies";
import {Project} from "../../../../api/Projects";
import {Div10Row} from "../../../../api/QuoteDiv10";
import {getAll} from "../../shopdrawing/openings/Openings";
import {distinct} from "nate-react-api-helpers";
import {getPDFClient} from "../../../../misc/pdf/parts/CoverPage";

export function WRAccessoryCutSheetBtn() {
    const project = useProject();
    const projectId = useProjectId();

    const prefs = useSubmittalPreferences({
        project: projectId,
        kind: "div10-hardware-template",
        onLoad: (list: {
            version: 1
        }) => {}
    });

    const printCtx = useContext(PrintManagerContext);
    const alternative = useAlternative();
    const isQuote = useIsQuote();

    if(prefs.loading) return null;

    return (<>
        <SmallGreyButton startIcon={<PrintIcon />} onClick={async () => {
            await prefs.save({
                version: 1,
            })

            if(!project) return;
            const client = await getPDFClient(project)

            const hw = isQuote ?
                await getAll(null, offset => api.quoteDiv10.list({
                    project: projectId, alternative: alternative,
                    offset: offset,
                    tab: "wr-accessory"
                })) :
                await getAll(null, offset => api.div10.list({
                    project: projectId, offset,
                    tab: "wr-accessory"
                }))

            await printCtx.print2(hardwarePDF({
                lastSubmittal: prefs.lastSubmission,
                project: project,
                client: client,
                hardware: hw,
            }))

        }}>Cut Sheet Submittal</SmallGreyButton>
    </>)
}

export function hardwarePDF(props: {
    lastSubmittal: string | null;
    project: Project;
    client?: Company;
    hardware: Div10Row[]
}): PDFInfo {
    const products = distinct(props.hardware.map(h => h.product).filter(v => !!v));

    return {
        name: "wr-acc-cut-sheet.pdf",
        render: async onReady => {

            return <WRAccessoryCutSheetPDF
                project={props.project}
                lastSubmittal={props.lastSubmittal}
                client={props.client} onReady={onReady} />
        },
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "div10-hardware-template",
            appendCatalogPagesForProducts: products,
        }
    }
}
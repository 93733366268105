import {Column, lookupNestedProp, NestedKeyOf, setNestedProp, TID} from "./Table";

export class DependencyFinder<T extends TID> {
    before: T;
    after: T;
    toClear: NestedKeyOf<T>[] = [];

    constructor(before: T, after: T) {
        this.before = before;
        this.after = after;
    }

    forColumn(col: Column<T>) {
        return {
            props: (...k: (NestedKeyOf<T> | keyof T)[]) => {
                const out = {} as any;

                k.map(k => {
                    // @ts-ignore
                    setNestedProp(out, k, lookupNestedProp(this.before, k));

                    if(!col.editKey) return;

                    // @ts-ignore
                    if (lookupNestedProp(this.before, k) !== lookupNestedProp(this.after, k)) {
                        this.toClear.push(col.editKey)
                    }
                })

                return out as T;
            }
        }
    }

    keysToClear() {
        return this.toClear;
    }
}
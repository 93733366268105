import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useContext} from "react";
import {ChangesetContext} from "./ChangesetContext";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useProjectId} from "../ProjectName";

export function SessionTakeover(props: {
    onClose(): void;
}) {
    const ctx = useContext(ChangesetContext);
    const projectId = useProjectId();
    const projectIdRef = useSyncedRef(projectId);
    const onCloseRef = useSyncedRef(props.onClose);
    const reloadChangeset = useSyncedRef(ctx.reload);

    const action = useAsyncAction(async () => {
        await api.projectChangeset.takeOver({
            project: projectIdRef.current,
        })

        reloadChangeset.current();
        onCloseRef.current();
    }, [])

    return (
        <Dialog open>
            <DialogTitle>Take over Changeset</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to take over this change set? This will lock out {ctx.lockedToName}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => {
                    action.callback(null)
                }}>Take Over</Button>
            </DialogActions>
        </Dialog>
    )
}
import {useProjectId} from "../ProjectName";
import {useContext, useEffect, useRef} from "react";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAlternative} from "../../quote/alternative/Alternative";

export function AutoInsertOpening(props: {
    onDone(): void;
}) {
    const projectId = useRef(useProjectId());
    const alt = useAlternative();

    return <AutoInsert getInput={() => {
        return {
            quoteAlternative: alt ? alt : undefined,
            project: projectId.current,
        }
    }} onDone={props.onDone} />
}

export function AutoInsert(props: {
    getInput(): any;
    onDone(): void;
}) {
    const ctx = useContext(EditContext);
    const ctxRef = useRef(ctx);
    ctxRef.current = ctx;
    const onDone = useRef(props.onDone)
    onDone.current = props.onDone;
    const getInput = useSyncedRef(props.getInput)

    const action = useAsyncAction(async () => {
        const result = await ctxRef.current.update(getInput.current())

        ctxRef.current.onSelect.emit({
            id: result.id,
            index: 1,
            value: result,
            editing: true,
        });

        onDone.current();
    }, []);

    const actionRef = useSyncedRef(action.callback)
    useEffect(() => {
        actionRef.current(null);
    }, [actionRef])

    return (
        <Dialog open>
            <DialogContent>
                {action.LoadingElement}
            </DialogContent>
            <DialogActions>
                {action.error && <>
                    <Button onClick={() => props.onDone()}>Cancel</Button>
                    <Button onClick={() => action.callback(null)}>Try again</Button>
                </>}
            </DialogActions>
        </Dialog>
    )
}
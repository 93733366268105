import {ShopContainer} from "../ShopContainer";
import React from "react";
import {MiscSwitcher} from "../../quote/misc/MiscSwitcher";
import {MiscGeneric} from "../../quote/misc/MiscGeneric";


export function Misc() {

    return (
        <ShopContainer name={<MiscSwitcher />}>
            <MiscGeneric />
        </ShopContainer>
    )
}
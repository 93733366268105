import {DamageReport} from "../../../../api/Logistics";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel, Grid, InputAdornment,
    Radio,
    RadioGroup, TextField,
    Typography
} from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import moment from "moment";
import {Link} from "react-router-dom";
import {formatCents, parseCents, useAsyncAction} from "nate-react-api-helpers";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {useEffect, useState} from "react";
import {api} from "../../../../api/API";
import {Loader} from "../../../../misc/Loader";
import {useSyncedRef} from "../../../../misc/SyncedRef";

export function DamageReportItem(props: {
    value: DamageReport;
    onComplete(): void;
    onCancel(): void;
}) {
    const v = props.value;
    const [splitMethod, setSplitMethod] = useState("we-100");
    const [theyPay, setTheyPay] = useState(props.value.theyPayPerUnitCents);
    const [percent, setPercent] = useState("50");
    const [usable, setUsable] = useState(props.value.usableQty || 0);
    const [wePayPercent, setWePayPercent] = useState<number|null>(50);

    const onNextRef = useSyncedRef(props.onComplete);

    const act = useAsyncAction(async (input) => {
        await api.logistics.updateDamageReport(input);
        onNextRef.current();
    }, []);

    useEffect(() => {
        if(v.reviewed) {
            if(v.wePayPercent) {
                if(v.wePayPercent < 100 && v.wePayPercent > 0) {
                    setSplitMethod("other-%");
                } else if(v.wePayPercent === 100) {
                    setSplitMethod("we-100");
                } else if(v.wePayPercent === 0) {
                    setSplitMethod("supplier-100");
                }

                setWePayPercent(v.wePayPercent);
                setPercent((100 - v.wePayPercent).toString());
                const they = Math.round((1-v.wePayPercent / 100) * v.receivedUnitPrice);
                setTheyPay(they);
                return
            }

            if(v.wePayPerUnitCents === 0) {
                setSplitMethod("supplier-100");
                return;
            }

            if(v.theyPayPerUnitCents === 0) {
                setSplitMethod("we-100");
                return;
            }

            setSplitMethod("other-$");
            return;
        }

        switch(splitMethod) {
            case "we-100":
                setTheyPay(0);
                setWePayPercent(100);
                break;
            case "supplier-100":
                setWePayPercent(0);
                setTheyPay(v.receivedUnitPrice);
                break;
            case "other-$":
                setWePayPercent(null);
                break;
            case "other-%":
                try {
                    let percentNum = parseFloat(percent);
                    if(isNaN(percentNum)) return;
                    percentNum = Math.round(percentNum)

                    const they = Math.round(percentNum / 100 * v.receivedUnitPrice);
                    setWePayPercent(100 - percentNum);
                    setTheyPay(they);
                } catch(e) {}
                break;
        }
    }, [splitMethod, v.reviewed, v.receivedUnitPrice, v.wePayPercent, percent, v.wePayPerUnitCents, v.theyPayPerUnitCents]);

    const disabled = props.value.reviewed;

    return (
        <div style={{padding: 16, backgroundColor: "white"}}>
            <Button size="small" startIcon={<ChevronLeft />} variant="outlined" onClick={props.onCancel}>Back to List</Button>
            <div style={{height: 16}} />
            <Typography variant="h6">
                {v.productName}
            </Typography>
            <Typography>
                Supplied by {v.supplier}
            </Typography>
            <Typography>
                Reported by {v.createdByName} on {moment(v.createdAt).format("YYYY MMM D [at] H:mma")}
            </Typography>
            <Typography>
                <Link target="_blank" to={`/logistics/purchasing/${v.purchaseOrder}`}>PO #{v.purchaseOrderNumber}</Link>
            </Typography>

            <div style={{height: 24}} />

            <FormControl disabled={v.reviewed}>
                <FormLabel>Update Purchase Price on Damaged Goods for Costing</FormLabel>
                <RadioGroup value={splitMethod} onChange={(_, value) => {
                    setSplitMethod(value);
                }}>
                    <FormControlLabel value="we-100" control={<Radio />} label="We pay 100% of cost for damaged goods" />
                    <FormControlLabel value="supplier-100" control={<Radio />} label="Supplier covers 100% of damaged goods" />
                    <FormControlLabel value="other-%" control={<Radio />} label="Other %" />
                    {splitMethod === "other-%" && <div style={{paddingLeft: 32}}>
                        <TextField label="Discount" disabled={disabled} size="small" value={percent} InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}} onChange={e => setPercent(e.target.value)}/>
                    </div>}
                    <FormControlLabel value="other-$" control={<Radio />} label="Other $" />
                    {splitMethod === "other-$" && <div style={{paddingLeft: 32}}>
                        <TextField disabled={disabled} label="Discount" size="small" InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} onChange={e => {
                            try {
                                let v = parseCents(e.target.value)
                                if (isNaN(v)) {
                                    v = 0;
                                }

                                setTheyPay(v);
                            } catch (e) {
                                setTheyPay(0);
                            }

                        }}/>
                    </div>}
                </RadioGroup>
            </FormControl>

            <div style={{height: 24}} />

            <table className={tableStyle}>
                <thead>
                <tr>
                    <th>List Unit Price</th>
                    <th>Discount per Unit</th>
                    <th>Updated Cost per Unit</th>
                    <th>Extended Cost</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{formatCents(v.receivedUnitPrice)}</td>
                    <td>{formatCents(theyPay)}</td>
                    <td>{formatCents(v.receivedUnitPrice - theyPay)}</td>
                    <td>{formatCents((v.receivedUnitPrice - theyPay) * v.qty)}</td>
                </tr>
                </tbody>
            </table>
            <div style={{height: 24}} />

            <Typography variant="body1">Inventory Adjustment</Typography>
            <div style={{height: 8}} />

            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <TextField size="small" disabled={v.reviewed} defaultValue="0" onChange={e => setUsable(parseInt(e.target.value))} label="#" style={{width: 100}} />
                </Grid>
                <Grid item>
                    of {v.qty} items are usable and available in the warehouse
                </Grid>
            </Grid>
            <div style={{height: 16}} />
            <Loader {...act} />
            <Button disabled={disabled} variant='contained' color='primary' onClick={() => {
                act.callback(Object.assign({}, props.value, {
                    reviewed: true,
                    wePayPercent: wePayPercent,
                    wePayPerUnitCents: props.value.receivedUnitPrice - theyPay,
                    theyPayPerUnitCents: theyPay,
                    usableQty: usable,
                }))
            }}>Submit</Button>
        </div>
    );
}

const tableStyle = css({
    borderCollapse: "collapse",

    "& th, & td": {
        fontSize: "0.9rem",
        border: "1px solid " + grey["200"],
        padding: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    "& th": {
        fontWeight: "normal",
    },
    "& td": {
        textAlign: "right",
    }
})

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import React, {useContext, useState} from "react";
import {api} from "../../../../api/API";
import {Alternative} from "../../../../api/QuoteAlternatives";
import {ConfirmContext} from "../../../../misc/Confirm";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAlternative} from "./Alternative";

export function ManageAlternatives(props: {
    value: Alternative;
    onDone(): void;
    onCancel(): void;
}) {
    const [name, setName] = useState(props.value.name);
    const [description, setDescription] = useState(props.value.description);
    const confirm = useContext(ConfirmContext);
    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async (input) => {
        await api.quoteAlternatives.upsert(input)
        onDoneRef.current();
    }, [])

    const alternative = useAlternative();
    const isCurrent = alternative === props.value.id

    return (
        <Dialog open onClose={props.onDone}>
            <form onSubmit={e => {
                e.preventDefault()

                update.callback(Object.assign({}, props.value, {
                    name: name,
                    description: description,
                }))
            }}>
                <DialogTitle>
                    Manage {props.value.name}
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} />
                    <div style={{height: 8}} />
                    <TextField
                        fullWidth
                        label="Description"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        multiline
                        rows="3"
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isCurrent} color="error" onClick={async () => {
                        const ok = await confirm.confirm("Are you sure you want to archive this?")
                        if(!ok) return;

                        update.callback(Object.assign({}, props.value, {
                            archived: true,
                        }))
                    }}>Archive {isCurrent && <>(Can't archive while active)</> }</Button>
                    <div style={{flex: 1}} />
                    {update.LoadingElement}
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
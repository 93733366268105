import {api} from "../../../../../api/API";
import {useProjectId} from "../../ProjectName";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {Loader} from "../../../../../misc/Loader";
import {Grid, MenuItem} from "@mui/material";
import React, {useContext, useState} from "react";
import {ShortCodes} from "./ShortCodes";
import {CopyLibraryFromProject} from "./CopyLibraryFromProject";
import {useHwLibraryType} from "./HwLibraryType";
import {useAlternative} from "../../../quote/alternative/Alternative";
import {PopoutMenu} from "./PopoutMenu";
import {red} from "@mui/material/colors";
import {ConfirmContext} from "../../../../../misc/Confirm";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {useReloader} from "../ReloadContext";
import {ChangePreviewContext} from "../../ShopDrawingChangePreview";

export function Library() {
    const project = useProjectId();
    const alternative = useAlternative();

    const type = useHwLibraryType();

    // use upsert to auto-create if not already present
    const lib = useAsync2((input) => api.hardwareLibrary.upsert(input), {
        project: project,
        quoteAlternative: alternative ? alternative : undefined,
        libraryType: type,
    }, [project, alternative]);

    const [showCopy, setShowCopy] = useState(false);
    const [reloadKey, setReloadKey] = useState(0);
    const confirm = useContext(ConfirmContext);

    const reloadRef = useSyncedRef(lib.reload);
    const hwDataReload = useReloader();

    const clear = useAsyncAction(async (input) => {
        await api.hardwareLibrary.clear(input);
        reloadRef.current();
        hwDataReload.current();
        setReloadKey(Date.now())
    }, [])

    const previewChangeset = useContext(ChangePreviewContext);
    const locked = previewChangeset.enabled;

    return (
        <>
            <div>
                {showCopy && <CopyLibraryFromProject onDone={() => {
                    setShowCopy(false)
                    lib.reload();
                    hwDataReload.current();
                    setReloadKey(Date.now());
                }} />}
                <Loader {...lib}>
                    {data => (<div style={{paddingLeft: 16}}>
                            <Grid container spacing={1} style={{fontSize: "0.9rem"}} justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <div style={{paddingTop: 16, paddingBottom: 16, fontSize: "1rem", fontWeight: 600}}>
                                        Shortcodes & Product Library
                                    </div>
                                </Grid>
                                <Grid item>
                                    {clear.LoadingElement}
                                    <PopoutMenu>
                                        {onClose => <>
                                            <MenuItem disabled={locked} onClick={() => {
                                                setShowCopy(true)
                                                onClose();
                                            }}>Copy From</MenuItem>
                                            <MenuItem disabled={locked} onClick={async () => {
                                                onClose();

                                                const ok = await confirm.confirm("Are you sure you want to delete all items from this library?")
                                                if(!ok) return;

                                                clear.callback({
                                                    id: lib.result?.id,
                                                })
                                            }} style={{color: red["800"]}}>Reset</MenuItem>
                                        </>}
                                    </PopoutMenu>
                                </Grid>
                            </Grid>
                    </div>)}
                </Loader>
            </div>
            <ShortCodes key={reloadKey.toString()} />
        </>
    )
}
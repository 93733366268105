import React, {createContext, PropsWithChildren, useContext} from "react";
import { Container } from "../../../misc/Container";
import {ProjectProvider} from "../ProjectProvider";
import {SectionHeader} from "../../../misc/SectionHeader";
import { Grid } from "@mui/material";
import {ProjectName} from "../shopdrawing/ProjectName";
import {QuoteNav} from "./QuoteNav";
import {deepPurple} from "@mui/material/colors";
import {QuoteAlternative} from "./QuoteAlternative";
import {ReloadContextProvider} from "../shopdrawing/hardware/ReloadContext";

export const QuoteContext = createContext({
    isQuote: false,
})

export function useIsQuote() {
    return useContext(QuoteContext).isQuote;
}

const yesQuote = {
    isQuote: true,
}

export function QuoteContainer(props: PropsWithChildren<{
    actions?: JSX.Element
    name: any;
}>) {
    return (
        <ProjectProvider>
            <ReloadContextProvider>
                <QuoteContext.Provider value={yesQuote}>
                    <Container topCenter={<QuoteNav />} color={deepPurple["700"]}>
                        <div style={{
                            backgroundColor: "white",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}>
                            <SectionHeader name={props.name}
                                inFromRight={props.actions}
                                right={
                                    <Grid container alignItems="center" spacing={1} direction="row">
                                        <QuoteAlternative />
                                        <ProjectName />
                                    </Grid>}
                            />

                            {props.children}
                        </div>
                    </Container>
                </QuoteContext.Provider>
            </ReloadContextProvider>
        </ProjectProvider>
    )
}
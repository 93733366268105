import {
    createContext,
    MutableRefObject,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {EventEmitter, first, selectMany, sum} from "nate-react-api-helpers";
import {TablePersonalizeContext} from "./TablePersonalize";
import {Column, Group, TID} from "./Table";
import {useQueryParam} from "../../pages/project/quote/pricing/QueryParam";
import {useSyncedRef} from "../SyncedRef";

export const FilterContext = createContext({
    valueRef: {} as {[k: string]: (input: any) => boolean},
    sortedRef: {current: []} as MutableRefObject<Group<any>[]>,
    onClear: new EventEmitter(),
    onFilter: new EventEmitter<FilterDetail>(),
    srcData: new EventEmitter<any[]>(),
})

export type FilterDetail = {total: number, visible: number}
type FilterFx<T> = (input: T) => boolean;

type FilterSpec = {
    type: "eq",
    search: {
        [key: string]: string | number;
    }
} | {
    type: "contains",
    search: {
        [key: string]: (string | number)[];
    }
}

export function buildFilterLink(input: {
    tableName: string;
    filter: FilterSpec // key => value
}) {
    let p = new URLSearchParams();
    p.append(input.tableName, JSON.stringify(input.filter));
    return p.toString();
}



export function FilterProvider<T extends TID>(props: PropsWithChildren<{
    tableName: string;
    data: Group<T>[];
    groupFilter: (input: string) => boolean;
    columns: Column<T>[]
}>) {
    const onClear = useMemo(() => new EventEmitter(), []);
    const onFilter = useMemo(() => new EventEmitter<FilterDetail>(), []);

    const [filter, setFilter] = useState<FilterFx<T>[]>([]);
    const {sortBy, customSort} = useContext(TablePersonalizeContext);
    const value = useRef({}as {[key: string]: FilterFx<T>});

    const searchParamsFilterInit = useRef(false);
    const [searchParams, setSearchParams] = useQueryParam(props.tableName, "");

    // auto clear search params if filters are changed
    useEffect(() => {
        if(!searchParamsFilterInit.current) return;
        setSearchParams("")
    }, [filter, props.tableName, setSearchParams])

    useEffect(() => {
        const sub = onClear.subscribe(() => setSearchParams(""))
        return () => sub.cancel();
    }, [onClear, setSearchParams])

    useEffect(() => {
        if(!searchParams) {
            delete value.current.fromSearchParams;
            return;
        }

        let filter = JSON.parse(searchParams) as FilterSpec;
        const filterFx = (input: T) => {
            if(filter.type === "eq") {
                for(let i in filter.search) {
                    // @ts-ignore
                    if(input[i] !== filter.search[i]) return false;
                }
            } else if(filter.type === "contains") {
                for(let i in filter.search) {
                    // @ts-ignore
                    if(filter.search[i].indexOf(input[i]) === -1) return false;
                }
            }

            return true;
        };

        value.current = {
            fromSearchParams: filterFx,
        }

        setFilter([filterFx]);

        setTimeout(() => {
            searchParamsFilterInit.current = true;
        }, 100)
    }, [props.tableName, searchParams])

    const data = props.data;

    const srcData = useMemo(() => new EventEmitter<T[]>(), []);
    useEffect(() => {
        srcData.emit(selectMany(data, d => d.rows));
    }, [srcData, data])

    const sortFx = customSort || sortBy && first(props.columns, c => c.name === sortBy.column)?.sort as (a: T, b: T) => number;
    const sorted = useMemo(() => {
        if(!sortFx) return data;

        let sorter = sortFx;
        if(sortBy?.reverse) {
            sorter = (a, b) => -1 * sortFx(a, b) as any;
        }

        return data.map(v => {
            v.rows = v.rows.slice(0);
            v.rows.sort(sorter)
            return v;
        })
    }, [sortFx, sortBy, data]);

    const sortedRef = useSyncedRef(sorted);

    const groupFilter = props.groupFilter;
    const filteredAndSorted = useMemo(() => {
        let dt = sorted.filter(v => groupFilter(v.key))

        if(filter.length > 0) {
            return dt.map(grp => {
                return Object.assign({}, grp, {
                    rows: grp.rows.filter(d => {
                        for(let i = 0; i < filter.length; i++) {
                            if(!filter[i](d)) return false;
                        }

                        return true;
                    }),
                })
            })
        }

        return dt
    }, [sorted, filter, groupFilter]);

    useEffect(() => {
        onFilter.emit({
            total: sum(props.data.map(d => d.rows.length)),
            visible: sum(filteredAndSorted.map(d => d.rows.length)),
        });
    }, [filteredAndSorted, props.data, onFilter]);

    const filterRef = useRef(filter);
    filterRef.current = filter;

    useEffect(() => {
        const int = setInterval(() => {
            const newValues = Object.values(value.current);

            if(newValues.length === filterRef.current.length) {
                const hasChange = filterRef.current.filter((v, index) => newValues[index] !== v).length > 0;
                if(!hasChange) return;
            }

            setFilter(newValues);
        }, 800);

        return () => clearInterval(int);
    }, []);

    const ctx = useMemo(() => ({
        valueRef: value.current,
        sortedRef: sortedRef,
        onClear: onClear,
        onFilter: onFilter,
        srcData: srcData,
    }), [value, onClear, onFilter, srcData, sortedRef]);

    return (
        <FilterContext.Provider value={ctx}>
            <FilterContextPrivate.Provider value={filteredAndSorted}>
                {props.children}
            </FilterContextPrivate.Provider>
        </FilterContext.Provider>
    )
}

export const FilterContextPrivate = createContext([] as Group<any>[]);
import {SubAPI} from "./SubAPI";
import {Opening} from "./Openings";

export class ProjectChangeset extends SubAPI {

    clearAttachmentDir(input: {
        session: number
    }) {
        return this.fetcher.post("/api/project-changeset/clear-attachment-dir", input);
    }

    listOpeningChanges(input: {
        session: number;
    }) {
        return this.fetcher.get<OpeningChange[]>("/api/project-changeset/opening-changes", input);
    }

    listProductChangesByOpening(input: {
        project: number;
    }) {
        return this.fetcher.get<Opening[]>("/api/project-changeset/product-changes-by-opening", input);
    }

    list(input: {
        project: number;
        status: "pending"
    }) {
        return this.fetcher.get<ChangesetItem[]>("/api/project-changeset/list", input);
    }

    get(input: {
        id: number
    } | {
        activeForProject: number;
    }) {
        return this.fetcher.get<ChangesetItem>("/api/project-changeset", input);
    }

    start(input: {
        project: number;
    }) {
        return this.fetcher.post<ChangesetItem>("/api/project-changeset/start", input);
    }

    takeOver(input: {
        project: number;
    }) {
        return this.fetcher.post("/api/project-changeset/take-over", input)
    }

    save(input: {
        project: number;
        name: string;
        content: string;
    }) {
        return this.fetcher.post("/api/project-changeset", input)
    }

    close(input: {
        project: number;
        ignoreValidation?: boolean;
    }) {
        return this.fetcher.post("/api/project-changeset/close", input)
    }

    cancel(input: {
        project: number;
        force: boolean;
        ignoreValidation?: boolean;
    }) {
        return this.fetcher.post("/api/project-changeset/cancel", input)
    }

    reopen(input: {
        project: number;
        file: number;
    }) {
        return this.fetcher.post("/api/project-changeset/reopen", input)
    }

    cancelReleasedSession(input: {
        project: number
    }) {
        return this.fetcher.post("/api/change-released/cancel", input)
    }

    listChangeReleasedItems(props: {
        changeReleasedGroup: number;
        project: number;
    }) {
        return this.fetcher.get<{
            oldItems: ChangeReleasedReviewItem[];
            newItems: ChangeReleasedReviewItem[];
        }>("/api/change-released/review-items", props)
    }

    updateChangedReleaseList(props: {
        project: number;
        session: number;
        list: InventoryItemUpdate[]
    }) {
        return this.fetcher.post("/api/change-released/review-items", props)
    }

    recalculateProducts(props: {
        project: number;
    }) {
        return this.fetcher.post("/api/change-released/recalculate-products", props)
    }

    updateCustomerSummary(props: {
        project: number;
        sections: Section[];
        notes: string
        title: string;
    }) {
        return this.fetcher.post("/api/project-changeset/customer-summary", props)
    }

    getCustomerSummary(props: {
        project: number;
    }) {
        return this.fetcher.get<{
            sections: Section[]
            notes: string;
            title: string;
        }>("/api/project-changeset/customer-summary", props)
    }
}

export type Section = {
    id: number;
    name: string;
    description: string;
    amountCents: number;
}

export type ChangeReleasedReviewItem = {
    id: number;
    opening:      number;
    openingName:  string
    kind:         string
    name:         string
    productCode:  string
    productId:    number;
    dimensions:   string
    qty:          number;
    released:     boolean;
    status:       string; // logistics status
    archived:     boolean;
}

export type OpeningChange = {
    id: number;

    opening: number;
    diff: {[k: string]: any}
    changeset: number
    original: Opening

}

export type ChangesetItem = {
    id: number;
    project: number;
    name: string;
    content: string;
    createdAt: string;
    createdByName: string;
    createdBy: number;
    isActive: boolean;
    approved: boolean | null;
    approvalFile: number | null;
    changeReleasedGroup: number | null
}

export type ReviewAction = "keep" | "order-more" | "remove" | "accept-update" | "not-needed";

export type InventoryItemUpdate = {
    id: number;
    action: ReviewAction;
    qty: number;
    otherItems: number[]
}
import {Container} from "../../../misc/Container";
import {SectionTitle} from "../../../misc/SectionTitle";
import React from "react";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import {SelectCol, sortStringInner, StringCol, Table} from "../../../misc/scroller/Table";
import {Checkbox2Col} from "../../project/shopdrawing/ShopTableCol";
import {TabSection} from "./Tabs";
import {Company} from "../../../api/Companies";
import {AutoInsert} from "../../project/shopdrawing/openings/AutoInsertOpening";
import {removeRowOption, RowOptionCol} from "../../logistics/purchasing/RowOptions";
import {DistributorSelector} from "./DistributorSelector";

export function Manufacturers(props: {
    tab: string;
    onTabChange(value: string): void;
}) {

    const tableName = "company";

    return <Container>
        <div style={{
            backgroundColor: "white",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
        }}>
            <div style={{padding: 16}}>
                <SectionTitle>
                    Product Settings
                </SectionTitle>
            </div>
            <TabSection value={props.tab} onChange={props.onTabChange} />

            <Table<Company>
                name={tableName}
                fetch={ctx => getAll(ctx, offset => api.companies.list({
                    offset: offset,
                    kind: "supplier",
                }))}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [tableName],
                                confirm: true,
                            })
                        ],
                    }),
                    StringCol("Name", "name", 200),
                    StringCol("Short Name", "shortName", 200),
                    {
                        name: "Default Distributor",
                        width: 200,
                        editable: {
                            type: "custom",
                            render: DistributorSelector,
                        },
                        render: d => d.defaultDistributorName || "",
                        sort: (a, b) => sortStringInner(a.defaultDistributorName || "", b.defaultDistributorName || ""),
                    },
                    Checkbox2Col("Hardware", "sourceHardware"),
                    Checkbox2Col("Frames", "sourceFrames"),
                    Checkbox2Col("Doors", "sourceDoors"),
                    Checkbox2Col("WD Doors", "sourceDoorsWood"),
                    Checkbox2Col("Div10", "sourceDiv10"),
                    StringCol("Street", "addressStreet", 200),
                    StringCol("City", "addressCity", 200),
                    SelectCol("Province", "addressProvince", () => provinces),
                    StringCol("Postal", "addressPostal", 200),
                ]}
                fetchDeps={[]}
                onChange={input => api.companies.upsert(input)}
                insert={{
                    modal: input => <AutoInsert onDone={input} getInput={() => {
                        return ({
                            kind: "supplier",
                        })
                    }} />
                }}
            />
        </div>
    </Container>
}

export const provinces = [
    {value: "AB", display: "Alberta"},
    {value: "BC", display: "British Columbia"},
    {value: "MB", display: "Manitoba"},
    {value: "NB", display: "New Brunswick"},
    {value: "NL", display: "Newfoundland and Labrador"},
    {value: "NS", display: "Nova Scotia"},
    {value: "ON", display: "Ontario"},
    {value: "PE", display: "Prince Edward Island"},
    {value: "QC", display: "Quebec"},
    {value: "SK", display: "Saskatchewan"},
    {value: "NT", display: "Northwest Territories"},
    {value: "NU", display: "Nunavut"},
    {value: "YT", display: "Yukon"},
    {value: "", display: "---"},

    {value: "AL", display: "Alabama"},
    {value: "AK", display: "Alaska"},
    {value: "AZ", display: "Arizona"},
    {value: "AR", display: "Arkansas"},
    {value: "CA", display: "California"},
    {value: "CO", display: "Colorado"},
    {value: "CT", display: "Connecticut"},
    {value: "DE", display: "Delaware"},
    {value: "FL", display: "Florida"},
    {value: "GA", display: "Georgia"},
    {value: "HI", display: "Hawaii"},
    {value: "ID", display: "Idaho"},
    {value: "IL", display: "Illinois"},
    {value: "IN", display: "Indiana"},
    {value: "IA", display: "Iowa"},
    {value: "KS", display: "Kansas"},
    {value: "KY", display: "Kentucky"},
    {value: "LA", display: "Louisiana"},
    {value: "ME", display: "Maine"},
    {value: "MD", display: "Maryland"},
    {value: "MA", display: "Massachusetts"},
    {value: "MI", display: "Michigan"},
    {value: "MN", display: "Minnesota"},
    {value: "MS", display: "Mississippi"},
    {value: "MO", display: "Missouri"},
    {value: "MT", display: "Montana"},
    {value: "NE", display: "Nebraska"},
    {value: "NV", display: "Nevada"},
    {value: "NH", display: "New Hampshire"},
    {value: "NJ", display: "New Jersey"},
    {value: "NM", display: "New Mexico"},
    {value: "NY", display: "New York"},
    {value: "NC", display: "North Carolina"},
    {value: "ND", display: "North Dakota"},
    {value: "OH", display: "Ohio"},
    {value: "OK", display: "Oklahoma"},
    {value: "OR", display: "Oregon"},
    {value: "PA", display: "Pennsylvania"},
    {value: "RI", display: "Rhode Island"},
    {value: "SC", display: "South Carolina"},
    {value: "SD", display: "South Dakota"},
    {value: "TN", display: "Tennessee"},
    {value: "TX", display: "Texas"},
    {value: "UT", display: "Utah"},
    {value: "VT", display: "Vermont"},
    {value: "VA", display: "Virginia"},
    {value: "WA", display: "Washington"},
    {value: "WV", display: "West Virginia"},
    {value: "WI", display: "Wisconsin"},
    {value: "WY", display: "Wyoming"},
    {value: "DC", display: "District of Columbia"},
    {value: "AS", display: "American Samoa"},
    {value: "GU", display: "Guam"},
    {value: "MP", display: "Northern Mariana Islands"},
    {value: "PR", display: "Puerto Rico"},
    {value: "UM", display: "United States Minor Outlying Islands"},
    {value: "VI", display: "Virgin Islands, U.S."},
];
import {useContext} from "react";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import PrintIcon from '@mui/icons-material/Print';
import {useSubmittalPreferences} from "../../../../misc/pdf/useSubmittalPreferences";
import {useProject, useProjectId} from "../../shopdrawing/ProjectName";
import {HardwareTemplatePDF} from "./HardwareTemplatePDF";
import {api} from "../../../../api/API";
import {PDFInfo, PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import {useIsQuote} from "../QuoteContainer";
import {useAlternative} from "../alternative/Alternative";
import {Company} from "../../../../api/Companies";
import {Project} from "../../../../api/Projects";
import {HardwareGroupWithContent} from "../../../../api/Hardware";
import {getPDFClient} from "../../../../misc/pdf/parts/CoverPage";

export function HardwareTemplateBtn() {
    const project = useProject();
    const projectId = useProjectId();

    const prefs = useSubmittalPreferences({
        project: projectId,
        kind: "hardware-template",
        onLoad: (list: {
            version: 1
        }) => {}
    });

    const printCtx = useContext(PrintManagerContext);
    const alternative = useAlternative();
    const isQuote = useIsQuote();

    if(prefs.loading) return null;

    return (<>
        <SmallGreyButton startIcon={<PrintIcon />} onClick={async () => {
            await prefs.save({
                version: 1,
            })

            if(!project) return;
            const client = await getPDFClient(project)

            const hw = isQuote ? await api.quoteHardware.listWithContents({project: projectId, alternative: alternative})
                : await api.hardware.listWithContents({project: projectId})

            await printCtx.print2(hardwarePDF({
                lastSubmittal: prefs.lastSubmission,
                project: project,
                client: client,
                hardware: hw,
            }))

        }}>HW Cut Sheet</SmallGreyButton>
    </>)
}

export function hardwarePDF(props: {
    lastSubmittal: string | null;
    project: Project;
    client?: Company;
    hardware: HardwareGroupWithContent[]
}): PDFInfo {
    const products: number[] = [];

    props.hardware.map(h => h.hardware.map(item => {
        if(!products.includes(item.product)) {
            products.push(item.product);
        }
    }))

    return {
        name: "hardware-template.pdf",
        render: async onReady => {

            return <HardwareTemplatePDF
                project={props.project}
                lastSubmittal={props.lastSubmittal}
                client={props.client} onReady={onReady} />
        },
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "hardware-template",
            appendCatalogPagesForProducts: products,
        }
    }
}
import {Button, Dialog, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useState} from "react";

export function ForgotPasswordPage() {

    const [done, setDone] = useState(false)
    const [email, setEmail] = useState("")
    const action = useAsyncAction(async (input) => {
        await api.users.sendResetLink(input)
        setDone(true);
    }, []);

    return <Dialog open>
        <div style={{padding: 32, paddingLeft: 16, paddingRight: 16, display: "flex", flexDirection: "column", alignItems: "center"}}>
            <img src="/images/handle.png" style={{width: 150}}  alt="Logo" />
            {done ? <div style={{width: 300, paddingTop: 30}}>
                A password reset link has been sent to {email}.
                You should receive the email within 2 minutes. If you don't see it, it might be in your spam folder.
            </div> : <form style={{display: "block", width: 250}} onSubmit={e => {
                e.preventDefault();
                action.callback({
                    email: email,
                });
            }}>
                <div style={{height: 16}} />
                <TextField label="Email" onChange={e => setEmail(e.target.value)} autoComplete="username" fullWidth />
                <div style={{height: 16}}/>
                <Button type="submit" variant="contained" fullWidth>Send Reset Link</Button>
                {action.LoadingElement}
                <div style={{height: 12}}/>
                <Link to="/login" style={{color: grey["600"]}}>Back to Login</Link>
            </form>}
        </div>
    </Dialog>
}
import {api} from "../../../../api/API";
import {useEffect, useState} from "react";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {damagedEmitter} from "./Logistics";
import {DamageReport} from "../../../../api/Logistics";
import {getAll} from "../openings/Openings";
import {
    DateCol,
    NumberCol,
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import { DamageReportItem } from "./DamageReportItem";
import {projectTablePrefName} from "../TablePrefName";

export function damagedTableName(project: number) {
    return projectTablePrefName("project.logistics.damaged", project)
};

export function Damaged(props: {
    project?: number;
}) {

    const project = props.project;
    const list = useGetAll(input => api.logistics.listDamagedProducts(input), {includeReviewed: false, project}, [project]);
    useEffect(() => {
        damagedEmitter.emit({count: list.asList.length});
    }, [list.asList.length]);

    const [selection, setSelected] = useState<DamageReport>();

    return (
        <>
            {selection ? <DamageReportItem value={selection} onCancel={() => setSelected(undefined)} onComplete={() => {
                    list.reload();
                    setSelected(undefined);
            }} /> :
                <div style={{flex: 1, display: "flex", backgroundColor: "white"}}>
                    <Table<DamageReport>
                        name={damagedTableName(props.project || 0)}
                        fetch={ctx => getAll(ctx, offset => api.logistics.listDamagedProducts({
                            project: props.project,
                            includeReviewed: !!props.project,
                            offset: offset
                        }))}
                        fetchDeps={[props.project]}
                        columns={[
                            ViewOnly(DateCol("Created At", "createdAt", "YYYY-MMM-D", 100)),
                            ViewOnly(StringCol("PO #", "purchaseOrderNumber", 100)),
                            ViewOnly(StringCol("Product", "productName", 300)),
                            ViewOnly(NumberCol("Qty", "qty", 50)),
                        ]}
                        onRowClick={setSelected}
                        onChange={input => noop()}
                    />
                </div>
            }
        </>
    );
}

function noop() {
    return Promise.reject(new Error("not supported"));
}
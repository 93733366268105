import {tableClass} from "./library/ShortCodes";
import {HardwareGroup} from "./HardwareGroup";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {colCount} from "./HardwareTable";
import {useProjectId} from "../ProjectName";
import {orderByAscending, useAsync2} from "nate-react-api-helpers";
import {useReload} from "./ReloadContext";
import {EditCell} from "./library/EditCell";
import {Tooltip} from "@mui/material";
import {useHwAPI} from "./HardwareGeneric";
import {useAlternative} from "../../quote/alternative/Alternative";
import {HwEditingContext} from "./HwEditingContextProvider";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {HardwareViewModeContext, ViewMode} from "../../quote/hardware/HardwareViewMode";
import {HardwareGroupWithContent} from "../../../../api/Hardware";

export type HWFilter = {
    opening: string;
    code: string;
    finish: string;
    dimensions: string;
    activeQty: string;
    inactiveQty: string;
    commonQty: string;
    note: string;
};

const blankFilter = {
    opening: "",
    code: "",
    finish: "",
    dimensions: "",
    activeQty: "",
    inactiveQty: "",
    commonQty: "",
    note: "",
};

export const qtyWidth = 50;

export function convertHardwareForViewMode(data: HardwareGroupWithContent[], viewMode: ViewMode) {
    if(viewMode === "default") {
        return data
    }

    let result: HardwareGroupWithContent[] = [];

    data.map(d => {
        d.openings.map(op => {
            result.push(Object.assign({}, d, {
                id: -(d.id + 1/op.id), // fractional id to preserve uniqueness, but cause errors if someone tries to submit it
                name: "#" + op.seqNumber,
                openings: [op],
                hardware: d.hardware.map(h => Object.assign({}, h, {
                    calcByDimension: h.calcByDimension ? h.calcByDimension.map(c => Object.assign({}, c, {
                        qty: c.qty / d.openings.length,
                    })) : undefined,
                    calcTotalQty: h.calcTotalQty / d.openings.length,
                }))
            }))
        })
    })

    orderByAscending(result, item => item.openings[0].seqNumber)

    return result;
}

export function WorkingHardware(props: {
    onFilter(enabled: boolean): void
}) {
    const project = useProjectId();
    const hapi = useHwAPI();
    const alternative = useAlternative();
    const previewChangeset = useContext(ChangePreviewContext);
    const data = useAsync2(async (input) => hapi.listWithContents(input),
        {
            project: project,
            alternative: alternative,
            previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined
        },
        [project, hapi, alternative, previewChangeset.enabled, previewChangeset.sessionId]);

    useReload(data.reload, [data.reload]);

    const [filter, setFilter] = useState<HWFilter>(blankFilter);

    const filterEn = !!(filter.opening || filter.code || filter.finish || filter.dimensions || filter.activeQty || filter.inactiveQty || filter.commonQty || filter.note);
    const onFilter = props.onFilter;
    useEffect(() => {
        onFilter(filterEn);
    }, [filterEn, onFilter])

    const editCtx = useContext(HwEditingContext);
    const viewMode = useContext(HardwareViewModeContext).viewMode;
    const list = useMemo(() => {
        return convertHardwareForViewMode(data.asList, viewMode);
    }, [data.asList, viewMode]);

    return (
        <div>
            <table className={tableClass}>
                <thead>
                <tr>
                    <th />
                    <th style={{width: 100}} colSpan={2}>Opening</th>
                    <th style={{width: 170}}>Code</th>
                    <th style={{width: 100}}>Finish</th>
                    <th style={{width: 100}}>Dimensions</th>
                    <th style={{width: qtyWidth}}>
                        <Tooltip title="Active Quantity">
                            <div>A.Qty</div>
                        </Tooltip>
                    </th>
                    <th style={{width: qtyWidth}}>
                        <Tooltip title="Inactive Door Quantity">
                            <div>I.Qty</div>
                        </Tooltip>
                    </th>
                    <th style={{width: qtyWidth}}>
                        <Tooltip title="Common Quantity (thresholds, seals etc)">
                            <div>C.Qty</div>
                        </Tooltip>
                    </th>
                    <th></th>
                    <th style={{width: "auto"}}>Note</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td />
                    <EditCell inline inputStyle={{width: 100}} colSpan={2} onChange={v => setFilter(Object.assign({}, filter, {
                        opening: v,
                    }))} value={filter.opening} placeholder="Filter" />
                    <EditCell inline inputStyle={{width: 100}} onChange={v => setFilter(Object.assign({}, filter, {
                        code: v,
                    }))} value={filter.code} placeholder="Filter" />
                    <EditCell inline inputStyle={{width: 100}} onChange={v => setFilter(Object.assign({}, filter, {
                        finish: v,
                    }))} value={filter.finish} placeholder="Filter" />
                    <EditCell inline inputStyle={{width: 100}} onChange={v => setFilter(Object.assign({}, filter, {
                        dimensions: v,
                    }))} value={filter.dimensions} placeholder="Filter" />
                    <EditCell inputStyle={{width: qtyWidth}} inline onChange={v => setFilter(Object.assign({}, filter, {
                        activeQty: v,
                    }))} value={filter.activeQty} placeholder="Filter" />
                    <EditCell inputStyle={{width: qtyWidth}} inline onChange={v => setFilter(Object.assign({}, filter, {
                        inactiveQty: v,
                    }))} value={filter.inactiveQty} placeholder="Filter" />
                    <EditCell inputStyle={{width: qtyWidth}} inline onChange={v => setFilter(Object.assign({}, filter, {
                        commonQty: v,
                    }))} value={filter.commonQty} placeholder="Filter" />
                    <td></td>
                    <EditCell inputStyle={{width: "100%"}} inline onChange={v => setFilter(Object.assign({}, filter, {
                        note: v,
                    }))} value={filter.note} placeholder="Filter" />
                </tr>
                {data.loadingOrError && <tr key="loading"><td colSpan={colCount}>{data.LoadingOrErrorElement}</td></tr>}
                {list.map(l => (
                    <HardwareGroup hasFilter={filterEn} filter={filter} key={l.id} value={l} />
                ))}
                {editCtx.creating && <HardwareGroup hasFilter={false} filter={blankFilter} key="create-item" value={editCtx.creating} />}
                </tbody>
            </table>
        </div>
    )
}
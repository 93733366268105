
export function autoFormat(value: string, type: "phone" | "email" | "postal") {
    switch(type) {
        case "phone": {
            let digits = value.replace(/[^0-9x]+/g, "");
            const tk = new Taker(digits);
            tk.append("(")
            tk.take(3)
            tk.append(") ")
            tk.take(3)
            tk.append("-")
            tk.take(4)
            tk.append(" ")
            tk.takeAll();
            return tk.toString() + leaveTrailingSpace(value)
        }
        case "email": {
            value = value.replace(/^[^A-z0-9]+/g, "");
            if(value.match(/@[A-z0-9]+\.[A-z]{2,5}/)) {
                value = value.replace(/[^A-z0-9]+$/g, "");
            }

            return value.toLowerCase();
        }
        case "postal": {
            const chars = value.replace(/[^A-z0-9]+/g, "").toUpperCase();
            const tk = new Taker(chars)
            tk.take(3)
            tk.append(" ")
            tk.take(3);
            return tk.toString() + leaveTrailingSpace(value);
        }
    }
}

class Taker {
    src: string;
    out: string;

    constructor(src: string) {
        this.src = src;
        this.out = "";
    }

    take(value: number) {
        if(this.src === "") return;

        const content = this.src.substring(0, value)
        this.src = this.src.substring(value);
        this.out = this.out + content;
    }

    append(value: string) {
        if(this.src === "") return;

        this.out = this.out + value;
    }

    takeAll() {
        if(this.src === "") return;

        this.out = this.out + this.src;
        this.src = "";
    }

    toString() {
        return this.out;
    }
}

function leaveTrailingSpace(value: string) {
    if(value.endsWith(" ")) return " "
    return "";
}
import {MenuItem, Select} from "@mui/material";
import {css} from "@emotion/css";
import {SourceMode} from "../../../api/Logistics";

export function InventoryModeToggle(props: {
    value: SourceMode;
    onChange: (value: SourceMode) => void;
}) {
    return (
        <>
            <Select size="small" value={props.value} className={selectCls} onChange={e => {
                props.onChange(e.target.value as any)
            }}>
                <MenuItem value="to-order">To Order</MenuItem>
                <MenuItem value="stock">Stocking</MenuItem>
            </Select>
        </>
    )
}

const selectCls = css({
    "& div.MuiInputBase-input": {
        minHeight: "0.2rem",
        paddingTop: 1,
        paddingBottom: 1,
        fontSize: "0.8rem",
    }
})
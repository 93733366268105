import {forwardRef, useMemo} from "react";
import {Page, pdfTableStyleNoAlternating} from "../../../../misc/pdf/parts/Page";
import moment from "moment/moment";
import {first, formatCents} from "nate-react-api-helpers";
import {PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {Project} from "../../../../api/Projects";
import {PriceLine2} from "./Frames";
import {formatDescription} from "../../../logistics/purchasing/toSource/SubmitButton";
import {deepBlue, fixShortForms, Item, subHeaderCss, toDescriptionInput, useData} from "../../quote/pricing/QuotePDF";
import {Grid} from "@mui/material";
import {PDFHeader2} from "../../../../misc/pdf/parts/PDFHeader2";
import {Company} from "../../../../api/Companies";
import {PriceSectionNote} from "../../../../api/PriceSectionNotes";
import {MiscPrice} from "../../../../api/MiscPricing";
import {grey} from "@mui/material/colors";
import {PriceRider} from "../../../../api/Pricing";

export const PricingPDF = forwardRef(function(props: {
    data: {
        data: PriceLine2[]
        project: Project;
        contractor: Company|null;
        currentQuoteNumber: string;
        notes: PriceSectionNote[];
        misc: MiscPrice[]
        riders: PriceRider[]
    }
    onReady(): void;
}, ref: any) {
    const data = useData({
        prices: props.data.data,
        miscPrices: props.data.misc,
        notes: props.data.notes,
        riders: props.data.riders,
    });

    const d = useMemo(() => {
        return {
            data: data,
            project: props.data.project,
            contractor: props.data.contractor,
            currentQuoteNumber: props.data.currentQuoteNumber,
        }
    }, [props.data, data])

    return <PricingPDFInner data={d} onReady={props.onReady} ref={ref} />
});

export const PricingPDFInner = forwardRef(function(props: {
    data: {
        title?: string;
        data: Item[]
        project: Project;
        contractor: Company|null;
        currentQuoteNumber: string;
    }
    onReady(): void;
}, ref: any) {

    const data = props.data.data;
    const ready = useReadyCheck(props.onReady);

    // @ts-ignore
    const total = data.find(v => v.type === "total")?.totalCents || 0;

    return (
        <div ref={ref}>
            <PDF>
                <PageRenderProvider<Item> data={data} onReady={ready.section()}>
                    {(info)=> {

                        const hasTableRows = !!info.rows.find(v => v.type === "row" || v.type === "title" || v.type === "footer" || v.type === "total")

                        return (<Page orientation="portrait">
                            <div style={{flex: 1, fontSize: 14}}>
                                {info.pageIndex === 0 && <>
                                    <PDFHeader2 title={total > 0 ? "Extra" : "Credit"} sales />

                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <div>
                                                <div style={subHeaderCss}>Quote To:</div>
                                                <div>{props.data.project.contractorName}</div>
                                                {props.data.contractor && <>
                                                    <div>{props.data.contractor.addressStreet}</div>
                                                    <div>{props.data.contractor.addressCity}, {props.data.contractor.addressProvince}</div>
                                                    <div>{props.data.contractor.addressPostal}</div>
                                                </>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div>
                                                <div style={subHeaderCss}>Ship To:</div>
                                                <div>{props.data.project.name}</div>
                                                {props.data.project && <>
                                                    <div>{props.data.project.addressStreet}</div>
                                                    <div>{props.data.project.addressCity}, {props.data.project.addressProvince}</div>
                                                    <div>{props.data.project.addressPostal}</div>
                                                </>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td style={{fontWeight: "bold", textAlign: 'right'}}>Quote No:</td>
                                                    <td>#{props.data.currentQuoteNumber}</td>
                                                </tr><tr>
                                                    <td style={{fontWeight: "bold", textAlign: 'right'}}>Date:</td>
                                                    <td>{moment().format("MMMM D, YYYY")}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Grid>

                                    <div style={{height: 16}} />
                                </>}

                                {hasTableRows && <table className={pdfTableStyleNoAlternating} style={{fontSize: 12}}>
                                    <thead>
                                    <tr>
                                        <th style={{borderRight: "none", textAlign: "left"}}>{props.data.title || "Description"}</th>
                                        <th style={{borderLeft: "none", width: "150px"}}>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {info.rows.map((i, index) => {
                                        switch(i.type) {
                                            case "title":
                                                return (
                                                    <tr key={index.toString()} style={{borderBottom: "none", borderTop: "1px solid"}}>
                                                        <td colSpan={2} style={{fontWeight: "bold"}}>
                                                            {i.value}
                                                        </td>
                                                    </tr>
                                                )
                                            case "row":
                                                return (
                                                    <tr key={index.toString()}>
                                                        <td style={{borderRight: "none"}}>{i.value.qty} @ {fixShortForms(formatDescription(toDescriptionInput(i.value), i.value.qty > 1))}</td>
                                                        <td style={{textAlign: "right", borderLeft: "none"}}></td>
                                                    </tr>
                                                )
                                            case "footer":
                                                return (
                                                    <tr key={index.toString()} style={{borderBottom: "1px solid"}}>
                                                        <td style={{borderRight: "none"}}>
                                                            {i.notes.map(n => <div style={{whiteSpace: "pre-wrap"}}>{n}</div>)}
                                                        </td>
                                                        <td style={{borderLeft: "none"}} valign="bottom">
                                                            <div style={{textAlign: "right", borderLeft: "1px solid " + grey["800"]}}>
                                                                ${formatCents(i.totalCents)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            case "total":
                                                return (
                                                    <tr key={index.toString()} style={{border: "none"}}>
                                                        <td style={{fontWeight: "bold", textAlign: "right", color: deepBlue, border: "none"}}>TOTAL</td>
                                                        <td style={{textAlign: "right", fontWeight: "bold", border: "none", paddingLeft: 0, paddingTop: 0}}>
                                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                                <div>${formatCents(i.totalCents)}</div>
                                                                <div style={{fontSize: "0.8em", paddingLeft: 4, paddingBottom: 1, alignSelf: "flex-end"}}>+HST</div>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid black", borderTop: "1px solid black", width: "100%", height: 4}} />
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    })}
                                    </tbody>
                                </table>}

                                {first(info.rows, i => i.type === "total") &&
                                    <div>
                                        <div style={{marginTop: 30,
                                            display: "flex", flexDirection: "column", alignItems: "center", width: 300}}>
                                            <div>Thank you for your business!</div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                fontSize: "0.7em",
                                                fontWeight: "600",
                                                color: deepBlue,
                                            }}>
                                                <div>Tykel Commercial Door Supply Inc</div>
                                                <div>HST #713417319RT0001</div>
                                            </div>

                                        </div>
                                        <div style={{color: deepBlue, textAlign: "center", paddingTop: 10, fontWeight: "600"}}>

                                        </div>

                                    </div>}

                                {info.rows.map(r => {
                                    if(r.type === "generalNoteSection") {
                                        const onSamePageAsTable = !!info.rows.find(v => v.type === "total")
                                        return (
                                            <>
                                                {r.isFirst && <div style={{marginTop: onSamePageAsTable ? 30 : 8, fontWeight: "700"}}>General Notes</div>}
                                                <p style={{whiteSpace: "pre-wrap", lineHeight: "1.4em", margin: 0}}>
                                                    {r.value || <>&nbsp;</>}
                                                </p>
                                            </>
                                        )
                                    }
                                })}
                            </div>
                        </Page>)
                    }}
                </PageRenderProvider>
            </PDF>
        </div>
    )
});

import {KeyValue} from "./HardwareSchedulePDF";
import {Page} from "../../../../misc/pdf/parts/Page";

export function HwScheduleFinishesPage(props: {
    manufacturers: KeyValue[];
    finishes: KeyValue[];
}) {

    return (
        <Page orientation="portrait">
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{fontWeight: "bold", fontSize: "1.2rem", textAlign: "center", paddingTop: 20}}>
                    Manufacturers & Finishes
                </div>

                <div style={{fontWeight: "bold", paddingTop: 20}}>
                    Manufacturers
                </div>
                <div>
                    {props.manufacturers.map(m => {
                        return <div key={m.key} style={{paddingBottom: 4}}>
                            <b>{m.key}</b> - {m.value}
                        </div>
                    })}
                </div>

                <div style={{fontWeight: "bold", paddingTop: 20}}>
                    Finishes
                </div>
                <div>
                    {props.finishes.map(m => {
                        return <div key={m.key} style={{paddingBottom: 4}}>
                            <b>{m.key}</b> - {m.value}
                        </div>
                    })}
                </div>
            </div>
        </Page>
    )
}
import {PopoutInteractor} from "../../../misc/PopoutInteractor";
import {useContext, useEffect, useState} from "react";
import {ToDeliver, ToDeliverProps, toDeliverTableName} from "./ToDeliver";
import {TableSelectionContext} from "../../../misc/scroller/Table";
import {useSyncedRef} from "../../../misc/SyncedRef";

export const deliveriesPopoutPanel = new PopoutInteractor<ToDeliverProps | "refresh-to-source", "refresh-delivery">("packing.to-pack", "/logistics/packing/to-pack-popout");

export function ToDeliverPopout() {
    const ctx = useContext(TableSelectionContext);
    const refreshRef = useSyncedRef(ctx.refresh)
    const [props, setProps] = useState<ToDeliverProps>({});

    useEffect(() => {
        deliveriesPopoutPanel.sendToServer("ping");

        const sub = deliveriesPopoutPanel.fromServer.subscribeAndFireLast(input => {
            if(input === "refresh-to-source") {
                refreshRef.current(toDeliverTableName);
            } else {
                setProps(input);
            }
        })

        return () => sub.cancel();
    }, [refreshRef]);

    return (
        <ToDeliver {...props} />
    )
}
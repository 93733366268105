import {useEffect, useRef} from "react";
import {lassoCss, Rect} from "./Cropper";
import {Rotation, rotationAbs} from "./ExtractorSession";

export function useCropLassoCreator(props: {
    imageRef: HTMLImageElement|null;
    rotation: Rotation;
    zoom: number;
    enable: boolean;
    onCreate(rect: Rect): void;
}) {

    const {imageRef} = props;
    const onCreateRef = useRef(props.onCreate);
    onCreateRef.current = props.onCreate;

    // lasso creator
    useEffect(() => {
        if(!props.enable) return;

        if(!imageRef) return;
        const el = imageRef.parentElement;
        if(!el) return;
        const wrapper = el;

        let mouseEngaged = false;
        let anchor = {x: 0, y: 0};
        let mouseStart = {x: 0, y: 0};
        let mouseEnd = {x: 0, y: 0};

        const isTooSmall = () => {
            const deltaY = mouseEnd.y - mouseStart.y;
            const deltaX = mouseEnd.x - mouseStart.x;

            if(Math.abs(deltaX) < 10 || Math.abs(deltaY) < 10) {
                return true;
            }

            return false;
        }

        let lasso: HTMLDivElement| null = null;

        function removeLasso() {
            if(lasso) lasso.remove();
            lasso = null;
        }

        function createLasso() {
            if(lasso) return;

            lasso = document.createElement("div");
            lasso.classList.add("create-lasso");
            lasso.classList.add(lassoCss);
            wrapper.append(lasso);
        }

        const updateLasso = () => {
            if(!lasso) return;

            const point = {
                x: mouseEnd.x - mouseStart.x,
                y: mouseEnd.y - mouseStart.y,
            }

            let delta = rotatePoint(point, {width: 0, height: 0}, props.rotation)
            delta = scalePoint(delta, props.zoom);

            if(isTooSmall()) {
                lasso.style.display = "none";
                return;
            }


            lasso.style.display = "block";
            lasso.style.top = Math.min(anchor.y, anchor.y + delta.y) + "px";
            lasso.style.left = Math.min(anchor.x, anchor.x + delta.x) + "px";
            lasso.style.width = Math.abs(delta.x) + "px";
            lasso.style.height = Math.abs(delta.y) + "px";
        }

        const mouseDown = (e: MouseEvent) => {
            mouseEngaged = true;
            const rect = wrapper.getBoundingClientRect();

            let point = {x: e.clientX - rect.x, y: e.clientY - rect.y};

            point = rotatePoint(point, {width: rect.width, height: rect.height}, props.rotation)
            point = scalePoint(point, props.zoom);

            anchor = point;
            mouseStart = {x: e.clientX, y: e.clientY};
            createLasso();
        }

        const doneDrag = () => {
            mouseEngaged = false;

            if(mouseEnd.x < mouseStart.x) {
                anchor.x -= Math.abs(mouseEnd.x - mouseStart.x);
            }

            if(mouseEnd.y < mouseStart.y) {
                anchor.y -= Math.abs(mouseEnd.y - mouseStart.y);
            }

            let delta  = {
                x: Math.abs(mouseEnd.x - mouseStart.x),
                y: Math.abs(mouseEnd.y - mouseStart.y),
            };

            delta = scalePoint(delta, props.zoom);

            const start = {
                x: anchor.x,
                y: anchor.y,
            }

            let end = addPoint(start,
                rotatePoint(delta, {width: 0, height: 0},
                    props.rotation));

            let {topLeft, bottomRight} = fixRectPoints(start, end)
            removeLasso();

            if(isTooSmall()) {
                return;
            } else {
                const imgSize = imageRef.getBoundingClientRect();

                onCreateRef.current({
                    start: topLeft,
                    end: bottomRight,
                    totalWidth: imgSize.width / props.zoom,
                    totalHeight: imgSize.height / props.zoom,
                });
            }
        }

        const mouseMove = (e: MouseEvent) => {
            if(!mouseEngaged) return
            mouseEnd = {x: e.clientX, y: e.clientY};
            updateLasso()

            if(e.target === wrapper || isChildOf(wrapper, e.target as any)) return true;
            doneDrag();
        }

        const mouseUp = (e: MouseEvent) => {
            if(!mouseEngaged) return

            mouseEnd = {x: e.clientX, y: e.clientY};
            updateLasso()
            doneDrag();
        }

        const keyDown = (e: KeyboardEvent) => {
            if(e.key === "Escape") {
                doneDrag();
            }
        }

        wrapper.addEventListener("mousedown", mouseDown);
        document.addEventListener("mousemove", mouseMove);
        document.addEventListener("mouseup", mouseUp);
        document.addEventListener("keydown", keyDown)

        return () => {
            wrapper.removeEventListener("mousedown", mouseDown);
            document.removeEventListener("mousemove", mouseMove);
            document.removeEventListener("mouseup", mouseUp);
            document.removeEventListener("keydown", keyDown)
            removeLasso();
        }
    }, [imageRef, props.rotation, props.zoom, props.enable]);
}

export function isChildOf(possibleParent: HTMLElement, b: HTMLElement): boolean  {
    let cursor = b;
    while(cursor.parentElement) {
        if(cursor.parentElement === possibleParent) return true;
        cursor = cursor.parentElement;
    }

    return false;
}

export function clamp(value: number, min: number, max: number) {
    if(value < min) return min;
    if(value > max) return max;
    return value;
}

type XY = {
    x: number;
    y: number;
}

export function rotatePoint(point: XY, rect: {width: number;height: number}, rotation: number) {
    switch(rotationAbs(rotation)) {
        case 0:
            return point;
        case 90:
            return {
                x: point.y,
                y: rect.width - point.x,
            }
        case 180:
            return {
                x: rect.width - point.x,
                y: rect.height - point.y,
            }
        case 270:
            return {
                x: rect.height - point.y,
                y: point.x,
            }
        default:
            throw new Error("invalid rotation");
    }
}

export function scalePoint(point: XY, zoom: number) {
    return {
        x: point.x / zoom,
        y: point.y / zoom,
    }
}

function addPoint(a: XY, b: XY) {
    return {
        x: a.x + b.x,
        y: a.y + b.y,
    }
}

export function fixRectPoints(a: XY, b: XY) {
    return {
        topLeft: {
            x: Math.min(a.x, b.x),
            y: Math.min(a.y, b.y),
        },
        bottomRight: {
            x: Math.max(a.x, b.x),
            y: Math.max(a.y, b.y),
        }
    }
}
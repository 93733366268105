import React, {useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {DateField} from "../../../misc/DateField";
import {TextField2} from "../../../misc/TextField2";

export type DetailInput<T> = {
    title: string;
    message: string;
    inputs: {
        type: "date" | "text";
        label: string;
        key: keyof T;
        required?: boolean
    }[];
    onUpdate: (value: Partial<T>) => Promise<any>;
}

export function useRequireDetails() {
    let Element: any = null;
    const [state, setState] = useState<DetailInput<any>>();
    const [obj, setObj] = useState<any>({});
    const promise = useRef<{
        resolve: (input: any) => void;
        reject: (input: any) => void;
    }>();

    function show<T>(input: DetailInput<T>) {
        setState(input)
        setObj({});

        return new Promise<void>((resolve, reject) => {
            promise.current = {
                resolve,
                reject
            }
        });
    }

    if(state) {
        Element = (
            <Dialog open>
                <form onSubmit={async (e) => {
                    e.preventDefault()
                    await state?.onUpdate(obj);
                    promise.current?.resolve(null);
                    setObj(undefined)
                    setState(undefined);
                }}>
                    <DialogTitle>{state.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {state.message}
                        </DialogContentText>
                        <div style={{height: 8}} />

                        {state.inputs.map(inp => {
                            if(inp.type === "date") {
                                return (
                                    <DateField fullWidth
                                               label={inp.label}
                                               initialValue={null}
                                               required={inp.required}
                                               onChange={value => {
                                                      setObj(Object.assign({}, obj, {
                                                            [inp.key]: value
                                                      }));
                                               }} />
                                )
                            } else {
                                return (
                                    <TextField2 fullWidth label={inp.label}
                                                required={inp.required}
                                                initialValue={""} onChange={value => {
                                                    setObj(Object.assign({}, obj, {
                                                        [inp.key]: value
                                                    }));
                                                }} />
                                )
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setState(undefined);
                            promise.current?.reject(new Error("User cancelled"));
                        }}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

    return {
        show,
        Element
    }
}
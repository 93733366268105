import {Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, Button} from "@mui/material";
import {MiscProduct} from "../../../../api/MiscProducts";
import {useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {Loader} from "../../../../misc/Loader";
import {useSyncedRef} from "../../../../misc/SyncedRef";

export function ManualDimension(props: {
    value: MiscProduct;
    onDone(input: MiscProduct): Promise<void>;
    onCancel(): void;
}) {

    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const onDoneRef = useSyncedRef(props.onDone)
    const callback = useAsyncAction(async input => {
        await onDoneRef.current(input)
    }, []);

    return (
        <Dialog open onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault();

                callback.callback(Object.assign({}, props.value, {
                    dimLength: length,
                    dimWidth: width,
                    dimHeight: height,
                }));
            }}>
                <DialogTitle>
                    Manual Dimensions
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <Grid container spacing={1} direction="column">
                        {props.value.dimLengthFormula &&
                            <Grid item><TextField InputLabelProps={{shrink: true}} label="Length" size="small" value={length} onChange={e => setLength(e.target.value)} /></Grid>}
                        {props.value.dimWidthFormula &&
                            <Grid item><TextField InputLabelProps={{shrink: true}} label="Width" size="small" value={width} onChange={e => setWidth(e.target.value)} /></Grid>}
                        {props.value.dimHeightFormula &&
                            <Grid item><TextField InputLabelProps={{shrink: true}} label="Height" size="small" value={height} onChange={e => setHeight(e.target.value)} /></Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Loader {...callback} />
                    <Button size="small" onClick={props.onCancel}>Cancel</Button>
                    <Button size="small" variant="contained" type="submit">Ok</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import {ToDeliverProps, toDeliverTableName} from "./ToDeliver";
import {useContext} from "react";
import {
    DateCol,
    Nullable, NumberCol,
    PrefixSearch,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../misc/scroller/Table";
import {SnackContext} from "../../../misc/Snackbar";
import {ToDeliverItem} from "../../../api/Deliveries";
import {ProjectCol} from "../purchasing/PartsToSource";
import {CheckboxSelectorCol} from "../../project/shopdrawing/release/Checkbox";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {AddCol} from "./Frame";

export function Hardware(props: ToDeliverProps) {
    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);

    return (
        <Table<ToDeliverItem>
            key="hardware"
            name={toDeliverTableName}
            overridePrefsName={toDeliverTableName + ".hardware"}
            columns={[
                props.delivery ? CheckboxSelectorCol({}) : null,
                ViewOnly(ProjectCol("Project", "projectName", 100)),
                ViewOnly(StringCol("Opening", "openingName", 100)),
                ViewOnly(StringCol("Floor", "openingFloor", 70)),
                ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                ViewOnly(StringCol("Mfg", "manufacturerName", 200)),
                ViewOnly(StringCol("Name", "name", 200)),
                ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                ViewOnly(StringCol("Dim.", "dimensions", 60)),
                ViewOnly(NumberCol("Qty", "qty", 60)),
                AddCol(props, {
                    ctx, snack
                }),
            ]}
            fetch={ctx => getAll(ctx, offset => api.deliveries.partsToDeliver({
                offset,
                project: props.project,
                type: "hardware",
            }))}
            fetchDeps={[props.project]}
        />
    )
}
import {EventEmitter} from "nate-react-api-helpers";
import {PriceLine} from "../../../../api/Pricing";
import {useEffect, useState} from "react";
import {grey} from "@mui/material/colors";
import WarningIcon from '@mui/icons-material/Warning';
import {hollowMetalDoor, woodDoor} from "../../quote/openings/QuoteOpenings";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {isPriceLine2, PriceLine2} from "./Frames";

export const priceDetailEmitter = new EventEmitter<PriceLine[]|QuotePriceLine[]|PriceLine2[]>();
priceDetailEmitter.emit([]);

export type PriceDetail = {
    frames: number;
    doors: number;
    doorsDetail: {
        hm: { total: number; unpriced: number };
        wd: { total: number; unpriced: number };
        other: { total: number; unpriced: number };
    }
    div10: number;
    hardware: number;
    div10Detail: {
        "wr-accessory": { total: number; unpriced: number };
        "wr-partition": { total: number; unpriced: number };
        "locker": { total: number; unpriced: number };
        "mailbox": { total: number; unpriced: number };
        "corner-guard": { total: number; unpriced: number };
    }
}

export function useUnPricedItems() {
    const [pending, setPending] = useState(0);
    const [details, setDetails] = useState<PriceDetail>({
        frames: 0,
        doors: 0,
        doorsDetail: {
            hm: {unpriced: 0, total: 0},
            wd:{unpriced: 0, total: 0},
            other: {unpriced: 0, total: 0},
        },
        div10: 0,
        hardware: 0,
        div10Detail: {
            "wr-accessory": {unpriced: 0, total: 0},
            "wr-partition": {unpriced: 0, total: 0},
            "locker": {unpriced: 0, total: 0},
            "mailbox": {unpriced: 0, total: 0},
            "corner-guard": {unpriced: 0, total: 0},
        }
    })

    useEffect(() => {
        const isPending = (r: TRow) => {
            if(isPriceLine2(r) && r.kind === "previous") return false;
            if(r.qty === 0) return false;
            return !r.extendedPrice
        };

        type TRows = PriceLine[]|PriceLine2[]|QuotePriceLine[]
        type TRow = PriceLine2|PriceLine|QuotePriceLine

        const filterRows = (rows: TRows, condition: (input: TRow) => boolean) => rows.filter(r => condition(r) && isPending(r)).length;
        const filterRows2 = (rows: TRows, condition: (input: TRow) => boolean) => {
            return {
                total: rows.filter(condition).length,
                unpriced: rows.filter(r => condition(r) && isPending(r)).length,
            }
        };

        const sub = priceDetailEmitter.subscribeAndFireLast(rows => {
            const doorRows = (r: TRow) => r.productType === "door" || r.productType === "door-prep";
            const frameRows = (r: TRow) => r.productType === "frame" || r.productType === "frame-anchor" || r.productType === "frame-prep"
            const div10Rows = (r: TRow, category: string) => r.productType === "div-10" && (r.div10?.category || "wr-accessory") === category;

            setDetails({
                doors: filterRows(rows, doorRows),
                doorsDetail: {
                    hm: filterRows2(rows, r => doorRows(r) && (r.doorSeries || r.door?.series) === hollowMetalDoor),
                    wd: filterRows2(rows, r => doorRows(r) && (r.doorSeries || r.door?.series) === woodDoor),
                    other: filterRows2(rows, r => doorRows(r) && (r.doorSeries || r.door?.series) !== hollowMetalDoor && (r.doorSeries || r.door?.series) !== woodDoor),
                },
                frames: filterRows(rows, frameRows),
                div10: filterRows(rows, r => r.productType === "div-10" && isPending(r)),
                div10Detail: {
                    "wr-accessory": filterRows2(rows, r => div10Rows(r, "wr-accessory")),
                    "wr-partition": filterRows2(rows, r => div10Rows(r, "wr-partition")),
                    "locker": filterRows2(rows, r => div10Rows(r, "locker")),
                    "mailbox": filterRows2(rows, r => div10Rows(r, "mailbox")),
                    "corner-guard": filterRows2(rows, r => div10Rows(r, "corner-guard")),
                },
                hardware: filterRows(rows, r => r.productType === "hardware" && isPending(r))
            });

            setPending(filterRows(rows, r => isPending(r)));
        });

        return () => sub.cancel();
    }, []);

    return {
        pending,
        details
    }
}

export function UnPricedItems() {
    const {pending} = useUnPricedItems();
    if(!pending) return null;

    return (
        <div style={{
            fontSize: "0.8rem",
            color: grey["700"],
            display: "flex",
            alignItems: "center",
        }}>
            <WarningIcon color="warning" fontSize="inherit" />
            <div style={{width: 2}} />
            <div>{pending} un-priced items</div>
        </div>
    )
}
import {TextField} from "@mui/material";
import {ImperialDistance} from "../../misc/ImperialDistance";

export function DimensionInput2(props: {
    label: string;
    value: string;
    onChange(value: string): void;

}) {

    return (
        <TextField
            fullWidth
            label={props.label}
            InputLabelProps={{shrink: true}}
            size="small"
            variant="outlined"
            value={props.value}
            onBlur={e => {
                try {
                    props.onChange(ImperialDistance.parse(e.target.value).toString())
                } catch (e) {}
            }}
            onChange={e => props.onChange(e.target.value)}
        />
    )
}
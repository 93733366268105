import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export function ProvinceSelector(props: {
    size?: "small" | "medium";
    value?: string;
    onChange(value: string): void;
}) {
    return (
        <FormControl fullWidth size={props.size}>
            <InputLabel>Province</InputLabel>
            <Select
                value={props.value || "-"}
                label="Province"
                onChange={(e, value) => props.onChange(e.target.value as any)}
            >
                <MenuItem value="-">Choose Province</MenuItem>
                <Divider />
                <MenuItem value="ON">Ontario</MenuItem>
                <MenuItem value="AB">Alberta</MenuItem>
                <Divider />
                <MenuItem value="BC">British Columbia</MenuItem>
                <MenuItem value="MB">Manitoba</MenuItem>
                <MenuItem value="NB">New Brunswick</MenuItem>
                <MenuItem value="NL">Newfoundland and Labrador</MenuItem>
                <MenuItem value="NS">Nova Scotia</MenuItem>
                <MenuItem value="NT">Northwest Territories</MenuItem>
                <MenuItem value="NU">Nunavut</MenuItem>
                <MenuItem value="PE">Prince Edward Island</MenuItem>
                <MenuItem value="QC">Quebec</MenuItem>
                <MenuItem value="SK">Saskatchewan</MenuItem>
                <MenuItem value="YT">Yukon</MenuItem>
            </Select>
        </FormControl>
    )
}


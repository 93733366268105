import PrintIcon from "@mui/icons-material/Print";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import {useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {StringCol, Table} from "../../../../misc/scroller/Table";
import {api} from "../../../../api/API";
import {getAll} from "../openings/Openings";
import {DistributorDetail} from "../../../../api/Hardware";
import {useProjectId} from "../ProjectName";
import {HwDistributorSelector} from "../../../settings/productsAndManufacturers/DistributorSelector";
import {projectTablePrefName} from "../TablePrefName";

export function HardwareDistributorOverride() {
    const [show, setShow] = useState(false);

    return (
        <>
            <SmallGreyButton startIcon={<PrintIcon />} onClick={() => setShow(true)}>
                Distributors
            </SmallGreyButton>
            {show && <HardwareDistributorOverrideInner onClose={() => setShow(false)} />}
        </>
    )
}

function HardwareDistributorOverrideInner(props: {
    onClose(): void;
}) {
    const onCloseRef = useRef(props.onClose)
    onCloseRef.current = props.onClose;
    const project = useProjectId();

    const bufferRef = useRef<DistributorDetail[]|null>(null)
    const originalRef = useRef<DistributorDetail[]|null>(null)

    const update = useAsyncAction(async (input) => {
        await api.hardware.updateDistributors(input)
        onCloseRef.current()
    }, [])

    return (
        <Dialog open onClose={props.onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Hardware Distributors
            </DialogTitle>
            <DialogContent style={{height: window.innerHeight * 0.7, display: "flex", flexDirection: 'column'}}>

                <Table<DistributorDetail>
                    name={projectTablePrefName("hardware-distributors", project)}
                    columns={[
                        StringCol("Name", "name"),
                        StringCol("Code", "productCode"),
                        StringCol("Finish", "finish"),
                        {
                            name: "Distributor",
                            render: row => row.distributorName || "",
                            filter: (row, search) => {
                                if(!row.distributorName) return false;
                                return row.distributorName.toLowerCase().indexOf(search) !== -1
                            },
                            sort: (a, b) => {
                                if(!a.distributorName) return -1;
                                if(!b.distributorName) return 1;
                                return a.distributorName.localeCompare(b.distributorName)
                            }
                        },
                        {
                            name: "Distributor Override",
                            render: row => row.distributorOverrideName || "",
                            filter: (row, search) => {
                                if(!row.distributorOverrideName) return false;
                                return row.distributorOverrideName.toLowerCase().indexOf(search) !== -1
                            },
                            sort: (a, b) => {
                                if(!a.distributorOverrideName) return -1;
                                if(!b.distributorOverrideName) return 1;
                                return a.distributorOverrideName.localeCompare(b.distributorOverrideName)
                            },
                            editable: {
                                type: "custom",
                                render: HwDistributorSelector,
                            }
                        }
                    ]}
                    fetch={async ctx => {
                        if(bufferRef.current) {
                            return bufferRef.current
                        }

                        const result = await getAll(ctx, offset => api.hardware.listDistributorDetails({
                            project,
                            offset,
                        }))

                        bufferRef.current = result;
                        // clone
                        originalRef.current = result.slice(0).map(r => Object.assign({}, r));

                        return result;
                    }}
                    fetchDeps={[]}
                    onChange={async value => {

                        const buf = bufferRef.current;
                        if(buf) {
                            bufferRef.current = buf.map(b => {
                                if(b.id === value.id) {
                                    return value;
                                }

                                return b;
                            })
                        }
                    }}
                />

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" onClick={() => update.callback({
                    project: project,
                    changes: bufferRef.current!.filter(v => {
                        // only ones that actually changed
                        const prev = originalRef.current!.find(o => o.id === v.id);
                        return prev?.distributorOverride !== v.distributorOverride
                    }).map(b => {
                        return {
                            product: b.product,
                            overrideDistributor: b.distributorOverride,
                        }
                    })
                })}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
import { SubAPI } from "./SubAPI";

export class Users extends SubAPI {

    ping() {
        return this.fetcher.get<User | null>("/api/ping")
    }

    sendResetLink(input: {email: string}) {
        return this.fetcher.post("/api/users/forgot-password", input)
    }

    checkPasswordReset(input: {code: string}) {
        return this.fetcher.post("/api/users/check-password-reset", input)
    }

    resetPassword(input: {email: string; code: string, password: string}) {
        return this.fetcher.post("/api/users/reset-password", input)
    }

    login(input: {
        username: string;
        password: string;
    }) {
        return this.fetcher.postForAuth("/api/auth/login", input)
    }

    list(input: {
        search?: string;
        offset: number;
        permission?: Permission
    }) {
        return this.fetcher.get<Paginated<User>>("/api/users/list", input)
    }

    getPermissionOverrides(input: {
        user: number;
    }) {
        return this.fetcher.get<PermissionInfo[]>("/api/users/permission-overrides", input)
    }

    updatePermissions(input: {
        user: number;
        permissionOverrides: PermissionInfo[];
        role: string;
    }) {
        return this.fetcher.post("/api/users/permissions", input)
    }

    upsert(input: User & {
        permissionOverrides?: PermissionInfo[]
        updatePermissions?: boolean;
    }) {
        return this.fetcher.post<User>("/api/users", input)
    }

    effectivePermissions(input: {
        role: string;
    } | {
        user: number;
    }) {
        return this.fetcher.get<PermissionInfo[]>("/api/users/effective-permissions", input)
    }
}

export type PermissionInfo = {
    permission: Permission;
    enabled: boolean;
}

export type Paginated<T> = {
    data: T[];
    count: number;
}

export type User = {
    id: number;
    name: string;
    email: string;
    phone: string;
    role: string;
    archived: boolean
    permissions: Permission[];
    unreadTasks: number;

    isProductionEnv: boolean;
}

export type Permission = "CanReleaseAndPurchase"
    | "CanViewProjectPricingProfit"
    | "EditShopDrawingWhenLockedToManager" | "ProjectManager" | "Estimator";
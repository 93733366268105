

export class MetricParser {
  value: string;

  mm: number = 0;

  constructor(value: string) {
    this.value = value;
  }

  do(): { mm: number } {
    if (this.value.trim() === "") return {mm: 0};
    if (this.value.trim() === "0") return {mm: 0};

    const raw = this.value.trim().toLowerCase().replace(/,/g, "");
    const matches = raw.match(/^([0-9.]+)[ ]*(m|mm|cm|)$/);
    if(!matches) throw new Error("invalid format for metric unit system")

    let flt = parseFloat(matches[1])
    if(isNaN(flt)) throw new Error("invalid format for metric unit system")

    switch(matches[2]) {
      case "m":
        flt = flt * 1000;
        break
      case "cm":
        flt = flt * 10;
        break
      case "mm":
      case "":
        break;
      default:
        throw new Error("invalid format for metric unit system")
    }

    return {
      mm: Math.round(flt),
    }
  }

  static parse(value: string): { mm: number; } {
    const parser = new MetricParser(value);
    return parser.do();
  }
}

import {useProjectId} from "../../shopdrawing/ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {useIsQuote} from "../QuoteContainer";
import {useAlternative} from "../alternative/Alternative";
import {MiscPrice} from "../../../../api/MiscPricing";
import {ChangePreviewContext} from "../../shopdrawing/ShopDrawingChangePreview";
import {Column, LongStringCol, lookupNestedProp, StringCol, Table} from "../../../../misc/scroller/Table";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";
import {removeRowOption, RowOptionCol} from "../../../logistics/purchasing/RowOptions";
import {MiscPricingAutoInsert} from "../pricing/MiscPricingAutoInsert";
import {cellInActive} from "../../shopdrawing/pricing/cellconst";
import {PreviousValuePopup} from "../../shopdrawing/openings/PreviousValuePopup";
import {ChangesetContext} from "../../shopdrawing/changeset/ChangesetContext";
import {projectTablePrefName} from "../../shopdrawing/TablePrefName";

export function MiscGeneric() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const isQuote = useIsQuote();
    const alternative = useAlternative();

    const changeset = useContext(ChangesetContext);

    const previewChangeset = useContext(ChangePreviewContext);
    const tableName = projectTablePrefName("misc", project);

    if(!u) return null;

    return (
        <Table<MiscPrice>
            key="misc-price"
            name={tableName}
            locked={(locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")) || previewChangeset.enabled}
            fetch={async (ctx) => {
                return await getAll(ctx, offset =>
                    isQuote ?
                        api.quoteMiscPricing.list({project: project, alternative, offset}) :
                        api.miscPricing.list({project: project, offset}))
            }}
            cellCustomize={{
                backgroundColor: (row: MiscPrice, c: Column<MiscPrice>) => {
                    if(!changeset.proposing) return null;

                    let changed = false;

                    if("isChangedSinceBackup" in c && c.isChangedSinceBackup) {
                        changed = c.isChangedSinceBackup(row)
                    } else if(c.editKey) {
                        changed = !!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)
                    } else {
                        changed = false;
                    }

                    if(changed) {
                        return "white"
                    }

                    return cellInActive;
                },
                onFocus: (row: MiscPrice, c: Column<MiscPrice>, anchor: any) => {
                    if(!changeset.proposing) return null;
                    if(!c.editKey) return null

                    if(!!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)) {
                        return (
                            <PreviousValuePopup anchor={anchor} width={c.width} value={c.render(row.backup, c)} />
                        )
                    }

                    return null;
                }
            }}
            onDrag={async (input) => {
                const details = {
                    project: project,
                    alternative: alternative,
                    idOrder: input.idOrder,
                }

                const updateP = isQuote ?
                    api.quoteMiscPricing.reOrder(details as any) :
                    api.miscPricing.reOrder(details)


                await updateP

                return {
                    sortByCol: null
                }
            }}
            columns={[
                !locked && RowOptionCol({
                    draggable: true,
                    options: (dt) => [
                        removeRowOption(dt, {
                            refreshTables: [tableName],
                            onComplete: () => {}
                        })
                    ],
                }),
                StringCol("Category", "name"),
                LongStringCol("Description", "publicNote", 400),
                StringCol("Notes", "internalNote"),
            ]}
            fetchDeps={[project, alternative]}
            onChange={(input) => isQuote ?
                api.quoteMiscPricing.upsert(input as any) :
                api.miscPricing.upsert(input)
            }
            insert={previewChangeset.enabled ? undefined : {
                buttonText: "New Row",
                alignX: "left",
                modal: input => <MiscPricingAutoInsert onDone={input} />,
            }}
        />
    )
}
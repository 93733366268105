import {useProjectId} from "../../shopdrawing/ProjectName";
import {QuoteSwitcher} from "../pricing/QuoteSwitcher";
import React from "react";

export function HardwareSwitcher() {
    const projectId = useProjectId();

    return (
        <QuoteSwitcher
            title="Hardware"
            url={`/project/${projectId}/shop-drawing/hardware`}
            quoteTitle="Quote Hardware"
            quoteURL={`/project/${projectId}/quote/-1/hardware`} />
    )
}
import {css} from "@emotion/css";
import {PropsWithChildren} from "react";

const background = css({
    position: "absolute",
    top: -1,
    left: -1,
    right: -1,
    bottom: -1, // -1 for border
    zIndex: 1,
})

export function CellBackground(props: {color?: string}) {
    return <div className={background} style={{backgroundColor: props.color}}></div>
}

const value = css({
    position: "relative",
    zIndex: 2,
    textAlign: "center",
})

export function CellValue(props: PropsWithChildren<{color?: string}>) {
    return (
        <div className={value} style={{color: props.color}}>
            {props.children}
        </div>
    )
}

export function CellWithBg(props: PropsWithChildren<{
    backgroundColor?: string
    color?: string;
}>) {
    return (
        <>
            <CellBackground color={props.backgroundColor} />
            <CellValue color={props.color}>
                {props.children}
            </CellValue>
        </>
    )
}
import {deepOrange, green, red} from "@mui/material/colors";
import {css} from "@emotion/css";

export function DriverLogDetailRow(props: {
    got: number;
    damaged: number;
    expected: number;
}) {
    return (
        <div className={containerClass}>
            {props.damaged > 0 && <div style={{color: red["800"]}}>-{props.damaged}</div>}
            <div style={{color: props.got >= props.expected ? green["800"] : deepOrange["600"]}}>
                {Math.min(props.expected, props.got)}
            </div>
            {props.got > props.expected && <div style={{color: deepOrange["600"]}}>
                +{props.got - props.expected}
            </div>}
        </div>
    )
}

const containerClass = css({
    display: "flex",
    justifyContent: "flex-end",

    "& > div": {
        marginLeft: 8,
    }
})
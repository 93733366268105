import {Moment} from "moment";
import {css} from "@emotion/css";
import {red} from "@mui/material/colors";
import React from "react";


export function Indicator(props: {
    now: Moment;
    before: Moment;
    after: Moment;
}) {
    const spanDay = props.after.diff(props.before, "day");
    const offsetTop = props.now.diff(props.before, "day");

    const indicatorTop = (Math.min(100, Math.round(offsetTop / (spanDay || 1) * 100)) + 15) + "%";

    return <div className={indicator} style={{top: indicatorTop}} />
}

const indicator = css({
    position: "absolute",
    top: 10,
    left: -8,
    width: 0,
    height: 0,
    border: "6px solid transparent",
    borderLeftColor: red["500"],
})
import {PricingGeneric} from "../../shopdrawing/pricing/Pricing";
import React from "react";
import {QuoteContainer} from "../QuoteContainer";
import {QuotePricingSwitcher} from "./QuotePricingSwitcher";
import {useQueryParam} from "./QueryParam";
import {PriceRequestSubmittal} from "./PriceRequestSubmittal";
import {Grid} from "@mui/material";
import {QuoteSubmittal} from "./QuoteSubmittal";

export function QuotePricing() {
    const [tab, setTab] = useQueryParam("tab", "summary");
    const [subTab, setSubTab] = useQueryParam("tab2", "");

    return (
        <QuoteContainer name={<QuotePricingSwitcher />} actions={
            <Grid container spacing={1}>
                <Grid item>
                    <QuoteSubmittal />
                </Grid>
                <Grid item>
                    <PriceRequestSubmittal tab={tab} subTab={subTab} />
                </Grid>
            </Grid>
        }>
            <PricingGeneric
                tab={tab} onTabChange={setTab}
                subTab={subTab} onSubTabChange={setSubTab}
            />
        </QuoteContainer>
    )
}
import {Button, Grid, Popover, TextField, Typography} from "@mui/material";
import {formatCents, parseCents, useAsyncAction} from "nate-react-api-helpers";
import {useRef, useState} from "react";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {PriceLine2} from "../Frames";
import {isNum} from "../ProposingKit";

export const tableCls = css({
    fontSize: "0.8rem",
    width: "100%",
    borderCollapse: "collapse",

    "& th": {
        padding: 2,
        paddingLeft: 16,
        textAlign: "left",
        borderBottom: "1px solid " + grey["200"]
    },
    "& td": {
        padding: 2,
        paddingRight: 4,
        paddingLeft: 4,
        textAlign: "right",
        borderBottom: "1px solid " + grey["200"]
    },

    "& tr > *:last-child": {
        paddingRight: 16,
    },
    "& tr:first-child > *": {
        borderTop: "1px solid " + grey["200"]
    }
})

export function Removal(props: {
    value: PriceLine2;
    onDone(update: PriceLine2): Promise<void>
    onCancel(): void;
    anchor: any;
}) {
    const v = props.value.kind === "current" ? props.value : null;
    // @ts-ignore
    const isPartial = v?.initial?.qty !== -v?.previous?.qty;
    // @ts-ignore
    const defaultCredit = v?.extendedPrice;
    const [credit, setCredit] = useState(formatCents(defaultCredit || 0));

    const onDoneRef = useRef(props.onDone);
    onDoneRef.current = props.onDone;

    const action = useAsyncAction((input) => onDoneRef.current(input), []);
    if(v === null) return null;

    const qty = isPartial ? v.initial.qty + v.previous.qty : v.previous.qty;
    const newTotal = isPartial ? v.previous.extendedPrice - parseCents(credit) : -v.previous.extendedPrice - parseCents(credit)

    return (
        <Popover open={true} anchorEl={props.anchor} onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault();
            }}>
                <div style={{padding: 16, paddingBottom: 8}}>
                    <Typography style={{fontSize: "0.9rem", fontWeight: "700"}}>Removal</Typography>
                </div>

                <table className={tableCls}>
                    <tbody>
                    <tr>
                        <th>Qty</th>
                        <td>{v.previous.qty}</td>
                        <td></td>
                    </tr><tr>
                        <th>Unit Cost</th>
                        <td>{formatCents(v.initial.unitCostCents)}</td>
                        <td></td>
                    </tr><tr>
                        <th>Markup</th>
                        <td>{formatMarkup({
                            cents: v.initial.markupCents,
                            percent: v.initial.markupPercent,
                            basePrice: qty * v.initial.unitCostCents,
                        })}</td>
                        <td></td>
                    </tr><tr>
                        <th>Total</th>
                        <td></td>
                        <td>{formatCents(v.previous.extendedPrice)}</td>
                    </tr><tr>
                        <th>Adjust Qty</th>
                        <td>{v.initial.qty}</td>
                        <td></td>
                    </tr><tr>
                        <th>Credit</th>
                        <td></td>
                        <td>{formatCents(-parseCents(credit))}</td>
                    </tr>
                    <tr>
                        <th>New Total</th>
                        <td></td>
                        <td>{formatCents(newTotal)}</td>
                    </tr>
                    <tr>
                        <th></th>
                        <td colSpan={2}>{newTotal === 0 ? "Full Refund" : "Partial Credit"}</td>
                    </tr>
                    </tbody>
                </table>

                <div style={{padding: 16}}>

                    <TextField
                        label="Credit"
                        value={credit}
                        onChange={e => setCredit(e.target.value)}
                        fullWidth
                    />
                </div>
                <div style={{padding: 8}}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            {action.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={props.onCancel}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" type="submit" onClick={() => {
                                const cents = parseCents(credit)

                                action.callback(Object.assign({}, props.value, {
                                    extendedPrice: cents,
                                }));
                            }}>Save</Button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </Popover>
    )
}

export function formatMarkup(p: {
    cents: number | null | undefined;
    percent: number | null | undefined;
    basePrice: number;
}) {
    if(isNum(p.cents)) return "$" + formatCents(p.cents);
    if(isNum(p.percent)) {
        return (
            <>
                <div>{p.percent}%</div>
                <div>${formatCents(p.basePrice * p.percent / 100)}</div>
            </>
        );
    }

    return "-"
}
import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {WebSocketContext} from "../../../../misc/WebSocketProvider";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {ChangesetItem} from "../../../../api/ProjectChangeset";
import {projectInfoReload} from "../../ProjectProvider";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {useUser} from "../../../../misc/Permission";

export const ChangesetContext = createContext({
    proposing: false,
    isMine: true,
    lockedToName: "",
    canChangeReleased: false,
    changeReleasedGroup: null as number | null | undefined,
    sessionId: null as number |null|undefined,
    loading: false,
    reload: async () => {
        return null as ChangesetItem | null;
    }
})

type Info = {
    proposing: boolean
    lockedToId: number;
    lockedToName: string;
    canChangeReleased: boolean;
    changeReleasedGroup: number | null;
    sessionId: null | number;
}

export function ChangesetProvider(props: PropsWithChildren<{}>) {
    const [info, setInfo] = useState<Info>()

    const projectId = useProjectId();
    const lookup = useAsync2(async (input) => {
            const result = await api.projectChangeset.get(input)
            if(result) {
                setInfo({
                    proposing: true,
                    lockedToId: result.createdBy,
                    lockedToName: result.createdByName || "",
                    canChangeReleased: !!result.changeReleasedGroup,
                    changeReleasedGroup: result.changeReleasedGroup,
                    sessionId: result.id
                })
            } else {
                setInfo(undefined);
            }

            return result;
        },
        {activeForProject: projectId}, [projectId])
    const loading = lookup.loading

    const ws = useContext(WebSocketContext);
    const wsHandle = ws.handle;
    useEffect(() => {
        return wsHandle("shopDrawing:changesetStatusUpdate", (data: {
            enabled: boolean,
            createdBy?: number;
            createdByName?: string;
            sessionId?: number
            changeReleasedGroup?: number | null;
        }) => {
            if(data.enabled) {
                setInfo({
                    proposing: true,
                    lockedToId: data.createdBy || 0,
                    lockedToName: data.createdByName || "",
                    canChangeReleased: !!data.changeReleasedGroup,
                    changeReleasedGroup: data.changeReleasedGroup || null,
                    sessionId: data.sessionId || null
                })
            } else {
                setInfo(undefined);
            }

            projectInfoReload.emit(true)
        })
    }, [wsHandle])

    const reloadRef = useSyncedRef(lookup.reload);
    const userId = useUser()?.id;

    const ctx = useMemo(() => {
        return ({
            sessionId: info?.sessionId,
            isMine: userId === info?.lockedToId,
            lockedToName: info?.lockedToName || "",
            canChangeReleased: !!info?.changeReleasedGroup,
            changeReleasedGroup: info?.changeReleasedGroup,
            proposing: !!info,
            loading,
            reload: () => {
                reloadRef.current()
                return api.projectChangeset.get({
                    activeForProject: projectId
                })
            }
        })
    }, [info, userId, reloadRef, projectId, loading])

    if(ctx.loading) {
        return (
            <>
                {lookup.LoadingOrErrorElement}
            </>
        )
    }

    return (
        <ChangesetContext.Provider value={ctx}>
            {lookup.LoadingOrErrorElement}
            {props.children}
        </ChangesetContext.Provider>
    )
}

export function WithChangesetMeta(props: {
    children: (input: {proposing: boolean}) => any;
}) {
    const ctx = useContext(ChangesetContext);

    const previewCtx = useContext(ChangePreviewContext);
    if(previewCtx.enabled) {
        return (
            <>
                {props.children({
                    proposing: true,
                })}
            </>
        )
    }

    return (
        <>
            {props.children(ctx)}
        </>
    )
}
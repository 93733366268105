import {Button, Alert, Grid, Typography} from "@mui/material";
import React from "react";
import {ProjectFile} from "../../../../api/ProjectFiles";
import {api} from "../../../../api/API";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useSnackbar} from "../../../../misc/Snackbar";
import {useAsync2} from "nate-react-api-helpers";
import {Loader} from "../../../../misc/Loader";
import {useNavigate} from "react-router-dom";

export function FileDetailChangesetActions(props: {
    file: ProjectFile;
}) {

    const projectId = useProjectId();
    const snack = useSnackbar();
    const nav = useNavigate();

    const info = useAsync2(async (input) => {
        if(!input.id) return null;
        return api.projectChangeset.get({
            id: input.id,
        })
    }, {id: props.file.linkedChangeSet}, [props.file.linkedChangeSet]);

    return (
        <>
            <div style={{flex: 1}} />
            <Grid container spacing={1}
                  direction="column"
                  style={{width: "100%"}}>
                <Grid item style={{width: "100%"}}>
                    <div>
                        <Alert severity="info" icon={false}>
                            This file is linked to a set of project changes.
                            By editing the approval on this file, you can apply or reject those changes.
                        </Alert>
                    </div>
                </Grid>
                <Grid item style={{width: "100%"}}>
                    <Loader {...info}>
                        {v => <div>
                            <Typography variant="caption">Details</Typography>
                            <div>{v.name}</div>
                            <div>{v.content}</div>
                        </div>}
                    </Loader>
                </Grid>
                    {props.file.status !== "approved" && <>
                    <Grid item style={{width: "100%"}}>
                        <Button size="small" fullWidth variant="outlined"
                                color="primary"
                                onClick={async () => {
                                    snack.loading();

                                    try {
                                        await api.projectChangeset.reopen({
                                            project: projectId,
                                            file: props.file.id,
                                        })
                                        window.open(`/project/${projectId}/shop-drawing`, "_blank");
                                        snack.success("Changes re-opened");
                                    } catch (e: any) {
                                        snack.error(e.toString());
                                    }
                                }}>
                            Edit Change Set</Button>
                    </Grid>
                    </>}
                <Grid item>
                    <Button
                        size="small"
                        fullWidth variant="outlined"
                        color="primary"
                        onClick={() => nav(`/project/${projectId}/shop-drawing/changeset/${props.file.linkedChangeSet || 0}/preview`)}
                    >
                        Preview Changes
                    </Button>
                </Grid>
            </Grid>

        </>
    )
}
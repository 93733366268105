import {pdfTableStyle} from "../../../../../misc/pdf/parts/Page";
import {KeyName, KeyOpening} from "../../../../../api/Keying";
import {DataSplitInfo, PageRenderProvider} from "../../../../../misc/pdf/parts/PageContext";
import {getDisplayProductForOpening, isDisabled} from "../Keying";
import {ContainerPage} from "../../../../../misc/pdf/parts/ContainerPage";

export function KeyingSchedule(props: {
    rows: KeyOpening[];
    names: KeyName[];
    onReady(): void;
}) {
    return (
        <PageRenderProvider data={props.rows} onReady={props.onReady}>
            {(list: DataSplitInfo<KeyOpening>) =>
                <ContainerPage>
                    <table className={pdfTableStyle}>
                        <thead>
                        <tr>
                            <th>Door #</th>
                            <th>Room Type</th>
                            <th>Lock Type</th>
                            {props.names.map(k => <th key={k.short}>{k.short}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th style={{
                                paddingLeft: 40,
                                backgroundColor: "#F8CBAD",
                                fontWeight: "bold",
                            }} colSpan={3 + props.names.length}>{convertToNth(list.rows.length ? list.rows[0].floor : "1")} Floor</th>
                        </tr>
                        {list.rows.map((v, index) => {
                            const isNewFloor = index > 0 && list.rows[index-1].floor !== v.floor;
                            return (
                                <>
                                    {isNewFloor && <tr>
                                        <th style={{
                                            paddingLeft: 40,
                                            backgroundColor: "#F8CBAD",
                                            fontWeight: "bold",
                                        }} colSpan={3 + props.names.length}>{convertToNth(v.floor)} Floor</th>
                                    </tr>}
                                    <tr>
                                        <td>{v.name}</td>
                                        <td>{v.locationTwo}</td>
                                        <td>{getDisplayProductForOpening(v)}</td>
                                        {(isDisabled(v) || v.keyed === false) ?
                                            <td colSpan={4 + props.names.length} key="not-keyed" style={{textAlign: "center", backgroundColor: "#FFE699"}}>****NOT KEYED****</td> :
                                            props.names.map(k => <th key={k.short} style={{textAlign: "center"}}>{
                                                v.keying.indexOf(k.id) !== -1 ? "X" : ""
                                            }</th>)}
                                    </tr>
                                </>
                            )
                        })}
                        </tbody>
                    </table>
                </ContainerPage>}
        </PageRenderProvider>
    )
}

function convertToNth(value: string) {
    value = (value || "").trim()
    const val = parseInt(value);

    if(val.toString() !== value) return value;
    if(isNaN(val)) return value;

    return toOrdinal(val);
}

function toOrdinal(val: number): any {
    switch(val) {
        case 1:return "First";
        case 2:return "Second";
        case 3:return "Third";
        case 4:return "Fourth";
        case 5:return "Fifth";
        case 6:return "Sixth";
        case 7:return "Seventh";
        case 8:return "Eighth";
        case 9:return "Nineth";
        case 10:return "Tenth";
        case 11:return "Eleventh";
        case 12:return "Twelfth";
        case 13:return "Thirteenth";
        case 14:return "Fourteenth";
        case 15:return "Fifteenth";
        case 16:return "Sixteenth";
        case 17:return "Seventeenth";
        case 18:return "Eighteenth";
        case 19:return "Nineteenth";
    }

    if(val > 100) {
        return "Hundred and " + toOrdinal(val % 100)
    } else if(val === 100) {
        return "Hundredth"
    }

    if(val > 90) {
        return "Ninety-" + toOrdinal(val % 90);
    } else if(val === 90) {
        return "Ninetieth"
    }

    if(val > 80) {
        return "Eighty-" + toOrdinal(val % 80);
    } else if(val === 80) {
        return "Eightieth"
    }

    if(val > 70) {
        return "Seventy-" + toOrdinal(val % 70);
    } else if(val === 70) {
        return "Seventieth"
    }

    if(val > 60) {
        return "Sixty-" + toOrdinal(val % 60);
    } else if(val === 60) {
        return "Sixtieth"
    }

    if(val > 50) {
        return "Fifty-" + toOrdinal(val % 50);
    } else if(val === 50) {
        return "Fiftieth"
    }

    if(val > 40) {
        return "Forty-" + toOrdinal(val % 40);
    } else if(val === 40) {
        return "Fortieth"
    }

    if(val > 30) {
        return "Thirty-" + toOrdinal(val % 30);
    } else if(val === 30) {
        return "Thirtieth"
    }

    if(val > 20) {
        return "Twenty-" + toOrdinal(val % 20);
    } else if(val === 20) {
        return "Twentieth"
    }

    return "invalid(" + val + ")";
}
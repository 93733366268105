import {api} from "../../../../api/API";
import {Button} from "@mui/material";
import React, {useContext, useState} from "react";
import {ProjectDir, ProjectFile} from "../../../../api/ProjectFiles";
import {Rename} from "./Rename";
import {archive} from "./FileDetail";
import {ConfirmContext} from "../../../../misc/Confirm";
import {useSnackbar} from "../../../../misc/Snackbar";

export function FileDetailActions(props: {
    file: ProjectFile|ProjectDir;
    onChange(): void;
}) {
    const [showRename, setShowRename] = useState(false);

    const confirm = useContext(ConfirmContext);
    const snack = useSnackbar();

    return (
        <>
            <a style={{width: "100%"}} target="_blank" href={api.projectFiles.downloadLink(props.file.id, "inline")}>
                <Button size="small" fullWidth variant="outlined" color="primary">View</Button>
            </a>
            <div style={{height: 4}} />
            <a style={{width: "100%"}} href={api.projectFiles.downloadLink(props.file.id, "attachment")}>
                <Button size="small" fullWidth variant="outlined" color="primary">Download</Button>
            </a>
            <div style={{height: 4}} />
            <div style={{flex: 1}} />
            <Button onClick={() => setShowRename(true)} size="small" fullWidth variant="outlined" color="primary">Rename</Button>
            <div style={{height: 4}} />
            <Button size="small" onClick={async () => {
                archive({
                    confirm: confirm.confirm,
                    file: props.file,
                    snack: snack,
                    onChange: props.onChange,
                })
            }} fullWidth variant="outlined" color="error">Archive</Button>

            {showRename && <Rename value={props.file} onDone={() => {
                setShowRename(false);
                props.onChange();
            }} onCancel={() => {
                setShowRename(false);
                props.onChange();
            }} />}
        </>
    )
}
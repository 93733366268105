import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import {ManufacturerSelector} from "./ManufacturerSelector";
import React, {useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useTableRefresher} from "../../../misc/scroller/Table";
import {catalogTable} from "./Catalogs";

export function CreateCatalog(props: {
    onDone(): void;
}) {
    const [manufacturer, setManufacturer] = useState<number|null>(null);
    const [file, setFile] = useState<File>();
    const upload = useAsyncAction(input => api.productCatalogs.upload(input), []);
    const rfsh = useTableRefresher(catalogTable)

    if(upload.result) {
        return (
            <Dialog open>
                <DialogTitle>Upload Catalog</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Contact your administrator to link up the product SKUs with specific pages.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained" color="primary" onClick={() => {
                        props.onDone()
                        rfsh();
                    }}>Done</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open>
          <DialogTitle>Upload Catalog</DialogTitle>
          <DialogContent>
              <DialogContentText>
                  Upload your content and ask your administrator to map the product pages
                  to SKUs programmatically.
              </DialogContentText>

              <div style={{height: 16}} />
              <ManufacturerSelector defaultValueTitle="Choose Manufacturer" value={manufacturer} onChange={(v) => {
                  setManufacturer(v);
              }} />

              <div style={{height: 16}} />
              <div>File: {file?.name}</div>
              <FileUploader
                  multiple={false}
                  handleChange={(input: File | Array<File> | FileList) => {
                      if(input instanceof Array) {
                          setFile(input[0])
                      } else if(input instanceof FileList) {
                          setFile(input[0])
                      } else {
                          setFile(input)
                      }
                  }}
                  name="file"
                  types={["pdf"]}
              />
          </DialogContent>
          <DialogActions>
              {upload.LoadingElement}
              <Button onClick={props.onDone}>Cancel</Button>
              <Button
                  disabled={!file || !manufacturer}
                  variant="contained" color="primary" onClick={() => {
                  upload.callback({
                      file: file,
                      manufacturer: manufacturer,
                  })
              }}>Upload</Button>
          </DialogActions>
        </Dialog>
    )

}
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {deepOrange, green} from "@mui/material/colors";
import WarningIcon from '@mui/icons-material/Warning';
import {ProductPreview} from "../../../api/Logistics";
import {useMemo, useState} from "react";
import {css} from "@emotion/css";
import {isNumber, QtyPreview} from "./QtyWithPreview";

export function Completed(props: {
    contents: ProductPreview[];
    complete: boolean;
    routeComplete: boolean;
}) {
    const hasWarn = useMemo(() => {
        if(!props.complete) return false;

        const hasErr = props.contents.find(v => {
            if(v.qtyReceived !== v.qty) return true;
            if(isNumber(v.qtyDamaged) && v.qtyDamaged > 0) return true;
            return false;
        })

        return !!hasErr;
    }, [props.contents, props.complete]);

    const [preview, setPreview] = useState<HTMLElement|null>(null);

    if(hasWarn) {
        return (
            <>
                <div className={wrapperClass} style={{cursor: "pointer", backgroundColor: deepOrange["700"]}} onMouseOver={e => setPreview(e.currentTarget)} onMouseLeave={() => setPreview(null)}>
                    <div>Missing/Extra Items</div>
                    <div style={{width: 4}} />
                    <div style={{fontSize: "0.8rem", height: "1.2em"}}>
                        <WarningIcon color="inherit" fontSize="inherit"/>
                    </div>
                </div>
                {preview && <QtyPreview contents={props.contents} anchorEl={preview} onClose={() => setPreview(null)} />}
            </>
        )
    }

    if(props.complete) {
        return (
            <div className={wrapperClass} style={{backgroundColor: green["700"]}}>
                <div>Completed</div>
                <div style={{width: 4}} />
                <div style={{fontSize: "0.8rem", height: "1.2em"}}>
                    <CheckCircleIcon color="inherit" fontSize="inherit"/>
                </div>
            </div>
        )
    }

    if(props.routeComplete) {
        return (
            <div className={wrapperClass} style={{backgroundColor: deepOrange["700"]}}>
                <div>Skipped</div>
                <div style={{width: 4}} />
                <div style={{fontSize: "0.8rem", height: "1.2em"}}>
                    <WarningIcon color="inherit" fontSize="inherit"/>
                </div>
            </div>
        )
    }

    return null;
}

const wrapperClass = css({
    color: "white",
    backgroundColor: deepOrange["700"], borderRadius: 8, padding: 0, paddingLeft: 6, paddingRight: 4,
    fontSize: "0.7rem",
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 8
})
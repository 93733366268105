import {RouteStopGroupItem} from "../../../api/Logistics";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {api} from "../../../api/API";
import {useGetAll} from "../routes/useGetAll";
import {ReceiveItem} from "./ReceiveItem";
import React, {useEffect, useState} from "react";
import {EditReceivingItem} from "../receiving/EditReceivingItem";

const blankList: any = [];

export function DamagedBox(props: {
    value: RouteStopGroupItem;
    onDone(value: RouteStopGroupItem): void;
}) {
    const parent = props.value;
    const list = useGetAll(input => {
        return api.deliveries.listDeliveryItems(input)
    }, {
        box: props.value.deliveryBox || 0,
    }, [props.value.deliveryBox])

    const itemListRaw = list.asList || blankList
    const [items, setItems] = useState<RouteStopGroupItem[]>([]);

    useEffect(() => {
        setItems(itemListRaw.map(r => {
            return {
                id: -1,
                name: r.description,
                qty: r.qty,
                qtyReceived: null,
                qtyDamaged: null,
            } as RouteStopGroupItem
        }))
    }, [itemListRaw]);

    const [contextMenu, setContextMenu] = useState<RouteStopGroupItem | null>(null);

    return (
        <Dialog open>
            <DialogTitle>
                Damaged {parent.uom}: {parent.name}
            </DialogTitle>
            <DialogContent>
                {list.LoadingOrErrorElement}

                <table style={{width: "100%", borderCollapse: "collapse"}}>
                    <tbody>
                    {items.map((v, index) => (
                        <ReceiveItem
                            key={index.toString()}
                            value={v}
                            onLongPress={() => {
                                setContextMenu(v)
                            }}
                            onEdit={updated => {
                                setItems(old => old.map(o => o.id === updated.id ? updated : o))
                            }}
                            isPickup={false}
                        />
                    ))}
                    </tbody>
                </table>

                {contextMenu && <EditReceivingItem<RouteStopGroupItem>
                    value={contextMenu}
                    onChange={v => {
                        setItems(old => old.map(o => o.id === v.id ? v : o))
                        setContextMenu(null);
                    }}
                    onCancel={() => setContextMenu(null)}
                />}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" fullWidth onClick={() => {
                    props.onDone(Object.assign({}, parent, {
                        boxDamageDetail: items,
                    }))
                }}>Save</Button>
                <div style={{height: 16}}/>
                <Button color="secondary" variant="contained" fullWidth onClick={() => {
                    // revert damaged since they're cancelling out
                    props.onDone(Object.assign({}, parent, {
                        qtyDamaged: null,
                        qtyReceived: null,
                    }))
                }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
import {PropsWithChildren} from "react";

export function TitleTextBlock(props: PropsWithChildren<{title: string}>) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingLeft: "15%",
            paddingRight: "15%",
        }}>
            <div style={{height: 20}} />
            <div style={{
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                fontSize: "1.2rem",
                textAlign: "center",
                fontWeight: "bold",
                width: "100%",
            }}>
                {props.title}
            </div>
            <div style={{height: 16}} />

            <div style={{paddingLeft: 10, paddingRight: 10, display: "flex", flexDirection: "column", flex: 1}}>
                {props.children}
            </div>
        </div>
    )
}
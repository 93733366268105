import {useQueryParam} from "../../project/quote/pricing/QueryParam";

import React from "react";
import {Products} from "./Products";
import {Manufacturers} from "./Manufacturers";
import {Catalogs} from "./Catalogs";

export function ProductsAndManufacturers() {
    const [tab, setTab] = useQueryParam("tab", "products")

    switch(tab) {
        case "catalog":
            return <Catalogs tab={tab} onTabChange={setTab} />
        case "products":
            return <Products tab={tab} onTabChange={setTab} />
        case "manufacturers":
        default:
            return <Manufacturers tab={tab} onTabChange={setTab} />
    }
}
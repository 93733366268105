import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import React, {useRef, useState} from "react";
import {Popover} from "@mui/material";

export function EditCell(props: {
    placeholder?: string;
    className?: string;
    value: string;
    style?: any;
    inputStyle?: any;
    rowSpan?: any;
    colSpan?: any;
    inline?: boolean;
    disabled?: boolean;
    onChange(value: string): void;
}) {
    const [anchor, setAnchor] = useState<HTMLElement>();
    const [value, setValue] = useState(props.value);
    const hasFocusedRef = useRef(false)

    if(props.inline) {
        return (
            <td
                style={Object.assign({padding: 0}, props.style)}
                colSpan={props.colSpan}
                rowSpan={props.rowSpan}
                className={(props.className || "") + " " + cls}
            >
                <input type="text"
                       disabled={props.disabled}
                       style={props.inputStyle}
                       value={props.value}
                       className={inlineInputStyle}
                       onChange={e => props.onChange(e.target.value)}
                       placeholder={props.placeholder}
                />
            </td>
        )
    }

    return (
        <td
            style={props.style}
            colSpan={props.colSpan}
            rowSpan={props.rowSpan}
            className={(props.className || "") + " " + cls}
            onClick={e => {
                if(props.disabled) return;

                hasFocusedRef.current = false;
                setAnchor(e.currentTarget);
                setValue(props.value);
            }}
        >
            {props.value ? props.value : props.placeholder ? <i>{props.placeholder}</i> : null}
            {anchor && <Popover anchorEl={anchor}
                                open={!!anchor}
                                onClose={() => setAnchor(undefined)}>
                <form onSubmit={e => {
                    e.preventDefault()
                    setAnchor(undefined);
                    props.onChange(value);
                }}>
                    <input
                        style={props.inputStyle}
                        ref={r => {
                            if(r && !hasFocusedRef.current) {
                                hasFocusedRef.current = true;
                                r.setSelectionRange(0, value.length)
                                r.focus();
                            }
                        }}
                        type="text"
                        disabled={props.disabled}
                        onChange={e => setValue(e.target.value)}
                        value={value}
                        onBlur={() => {
                            setAnchor(undefined);
                            props.onChange(value);
                        }}
                        onKeyDown={e => {
                            if(e.key === "Escape") {
                                setAnchor(undefined);
                            }
                        }}
                    />
                </form>
            </Popover>}
        </td>
    )
}

const cls = css({
    position: "relative",
    cursor: "pointer",
    wordBreak: "break-all",

    "&:hover": {
        backgroundColor: grey["200"]
    }
})

const inlineInputStyle = css({
    border: "none",

    "&::placeholder": {
        color: grey["500"],
        fontStyle: "italic",
    }
})
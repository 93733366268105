import {hasPermission, useUser} from "../../../../misc/Permission";
import {
    Table,
} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {
    AddRider,
    commonGroupBy2,
    commonPricingColumns2, getPricing, priceChange,
    PriceCol,
    PriceLine2,
    PriceRow,
    proposingGroupBy2,
    RiderRowTitle, ViewPriceCol
} from "./Frames";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {QuotePriceRider} from "../../../../api/QuotePricing";
import {defaultPriceSortFunc} from "../../quote/pricing/QuotePriceRiderUtil";

export function Hardware() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const proposal = useProposingKit();
    const previewChangeset = useContext(ChangePreviewContext);

    if(!u) return null;

    return (
        <Table<PriceRow>
            name={priceTableName(project, "hardware")}
            globalPrefsName={priceTableName(0, "hardware")}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            cellCustomize={proposal.cellCustomize}
            fetch={async (ctx) => getPricing(ctx, {
                project,
                transformRows: proposal.transformRows,
                previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined,
                customSort: (rs) => {
                    rs.sort((a, b) => {
                        if(a.productType !== b.productType || a.productType !== "hardware") {
                            return defaultPriceSortFunc(a, b)
                        }

                        if(a.manufacturer === b.manufacturer) {
                            const bigNumber = 1000000;
                            return (a.categorySortOrder || bigNumber) - (b.categorySortOrder || bigNumber)
                        }

                        if(a.manufacturerName < b.manufacturerName) return -1;
                        return 1
                    })
                }
            })}
            columns={[
                PriceCol<PriceLine2>({
                    name: "",
                    price: o => proposal.rowAction(o, "hardware", ""),
                    rider: (dt: QuotePriceRider, row: PriceRow) => {
                        return <RiderRowTitle row={row} />
                    },
                    createRider: (row) => {
                        return <AddRider row={row} />
                    },
                    width: 150,
                }),
                proposal.proposing && PriceCol<PriceLine2>({
                    name: "Opening",
                    price: d => {
                        return proposal.renderItemName(d, "openingName")
                    },
                    width: 150,
                }),
                proposal.proposing && PriceCol<PriceLine2>({
                    name: "Name",
                    price: d => {
                        return proposal.renderItemName(d, "productName")
                    },
                    width: 150,
                }),

                ViewPriceCol("Code", "productCode", 80),
                ViewPriceCol("Finish", "finish", 80),
                ViewPriceCol("Length", "dimLength", 50),
                ViewPriceCol("Width", "dimWidth", 50),
                ViewPriceCol("Height", "dimHeight", 50),
                ...commonPricingColumns2(u, false, proposal.proposing) as any,
            ]}
            fetchDeps={[project, proposal.proposing, proposal.expanded, previewChangeset.enabled, previewChangeset.sessionId]}
            columnDeps={[proposal.proposing, u]}
            groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy2 : commonGroupBy2, {
                groupFilter: (groupName: string) =>
                    groupName.startsWith("hardware") || groupName === "project",
            })}
            onChange={(input) => priceChange(input, {
                processUpdate: proposal.processUpdate,
                project: project,
            })}
        />
    )
}

import {HardwareGroupWithContent} from "../../../../api/Hardware";
import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {table} from "../../../../misc/pdf/parts/TableStyle";
import {useMemo, useRef} from "react";
import {Page} from "../../../../misc/pdf/parts/Page";
import {PrepCanvasManager, InternalValue} from "../../../project/shopdrawing/hardware/prepCanvas/PrepCanvasManager";
import {ImperialDistance} from "../../../../misc/ImperialDistance";
import {convertPrepConfig} from "../../../project/shopdrawing/hardware/prepCanvas/PrepManager";
import {grey} from "@mui/material/colors";
import {groupByArr} from "nate-react-api-helpers";
import {PDFHeader} from "../../../../misc/pdf/parts/PDFHeader";
import {FrameAndDoorDetail} from "./FrameDetails";
import {dbPrep, dbStrikePrep, locksetASAPrep} from "../../../project/shopdrawing/extras/PrepMenuOptions";

type Item = {
    openingId: number;
    name: string;
    manager: PrepCanvasManager;
    hasDeadbolt: boolean;
    hasStrike: boolean;
    has161: boolean;
}

export function poOpeningNameLink(id: number) {
    return "line-item-" + id;
}

export function poOpeningDetailLink(id: number, kind: "preps" | "frame") {
    return "detail-" + id + "-" + kind;
}

export function getHwItemDetail(value: HardwareGroupWithContent[],
                                frames: FrameAndDoorDetail[] = [],
                                doors: FrameAndDoorDetail[] = []) {
    let out: Item[] = [];

    value.map(v => {
        let overrideHingeQty = 0;
        v.hardware.map(h => {
            if(h.overrideHingeInActiveQty) {
                overrideHingeQty = h.overrideHingeInActiveQty;
            }

            if(h.overrideHingeActiveQty) {
                overrideHingeQty = h.overrideHingeActiveQty;
            }
        })

        v.openings.filter(v => {
            const needsForFrame = frames.find(fr => fr.opening === v.id)
            if(needsForFrame) return true;

            const needsForDoor = doors.find(dr => dr.opening === v.id)
            return !!needsForDoor;
        }).map(o => {
            const height = o.nominalHeight;
            const config = convertPrepConfig(v.prepConfig);
            const isPair = o.openingType === "Pair";

            const frameNeedsDeadbolt = !!frames.find(f => f.opening === o.id && !!f.frameHardwarePreps.find(v =>
                v.name.indexOf(dbPrep) !== -1 || v.name.indexOf(dbStrikePrep) !== -1))
            const doorNeedsDeadbolt = !!doors.find(f => f.opening === o.id && !!f.doorHardwarePreps.find(v =>
                v.name.indexOf(dbPrep) !== -1 || v.name.indexOf(dbStrikePrep) !== -1))


            const manager = new PrepCanvasManager({
                nominalHeight: ImperialDistance.parse(height).universalValue(),
                nominalWidth: ImperialDistance.parse(o.nominalWidth).universalValue(),
                secondaryWidth: isPair ? ImperialDistance.parse(o.inactiveDoorWidth).universalValue() : undefined,
                overrides: config,
                pair: isPair,
                overrideHingeQty: overrideHingeQty,
                hasDeadbolt: frameNeedsDeadbolt || doorNeedsDeadbolt,
            })

            const frameNeedsStrike = !!frames.find(f => f.opening === o.id && !!f.frameHardwarePreps.find(v => v.name.includes(locksetASAPrep)))
            const doorNeedsStrike = !!doors.find(f => f.opening === o.id && !!f.doorHardwarePreps.find(v => v.name.includes(locksetASAPrep)))

            out.push({
                openingId: o.id,
                name: o.name,
                manager: manager,
                hasDeadbolt: frameNeedsDeadbolt || doorNeedsStrike,
                hasStrike: frameNeedsStrike || doorNeedsDeadbolt,
                has161:
                    !!doors.find(f => f.opening === o.id && !!f.doorHardwarePreps.find(v => v.name.includes("161")))
                    || !!frames.find(f => f.opening === o.id && !!f.frameHardwarePreps.find(v => v.name.includes("161"))),
            })
        })
    })

    return out;
}

export function HardwarePrepTable(props: {
    value: HardwareGroupWithContent[];
    frames: FrameAndDoorDetail[]
    doors: FrameAndDoorDetail[]
    onReady(): void
}) {
    const hasAnyDeadboltRef = useRef(false);
    const hasDoors = props.doors.length > 0
    const hasFrames = props.frames.length > 0

    let rows = useMemo(() => {
        const out = getHwItemDetail(props.value, props.frames, props.doors);

        out.map(o => {
            if(o.hasDeadbolt) {
                hasAnyDeadboltRef.current = true;
            }
        })

        function makeKey(input: InternalValue) {
            if(!input.enabled) return "false";
            return input.actualValue.toString();
        }

        const grp = groupByArr(out, v => [
            v.manager.pair.toString(),
            v.hasDeadbolt.toString(),
            v.hasStrike.toString(),
            v.has161.toString(),
            makeKey(v.manager.values.a),
            makeKey(v.manager.values.aStar),
            makeKey(v.manager.values.b),
            makeKey(v.manager.values.c),
            makeKey(v.manager.values.d),
            makeKey(v.manager.values.e),
            v.hasDeadbolt ? makeKey(v.manager.values.dbf) : "",
            v.hasDeadbolt ? makeKey(v.manager.values.dbfStar) : "",
            v.hasStrike || v.has161 ? makeKey(v.manager.values.clf) : "",
            v.hasStrike || v.has161 ? makeKey(v.manager.values.clfStar) : "",
            v.hasStrike || v.has161 ? makeKey(v.manager.values.cld) : "",
            v.hasStrike || v.has161 ? makeKey(v.manager.values.cldStar): "",
            v.hasDeadbolt ? makeKey(v.manager.values.dbd) : "",
            v.hasDeadbolt ? makeKey(v.manager.values.dbdStar) : "",
            makeKey(v.manager.values.bs),
        ].join(","))

        const rows = grp.map(v => {
            return v.values
        });

        let rows2: Item[][] = [];

        rows.forEach(r => {
            for(let i = 0; i < r.length; i += 5) {
                rows2.push(r.slice(i, i + 5));
            }
        })

        return rows2;
    }, [props.value, props.frames, props.doors]);

    // must happen after all the useMemo/useState/useRef b/c it's modified there.
    // yes, I know, this could be designed better.
    const hasAnyDeadbolt = hasAnyDeadboltRef.current;

    let hasD = false;
    let hasE = false;
    let hasBS = false;
    rows.map(r => {
        r.map((v: Item) => {
            if(v.manager.values.d.enabled) {
                hasD = true;
            }

            if(v.manager.values.e.enabled) {
                hasE = true;
            }

            if(v.manager.values.bs.enabled) {
                hasBS = true;
            }
        })
    })

    return (
        <PageRenderProvider key="hw-page-render" debugKey="hw-page" data={rows} onReady={props.onReady}>
            {(info: DataSplitInfo<Item[]>) => (<Page orientation="landscape" key={info.pageIndex.toString()}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                    <PDFHeader noAddress title="Hardware Prep Table" />
                    <table className={table}>
                        <thead>
                        <tr>
                            <th>Opening</th>
                            <th>Qty</th>
                            <th>Type</th>
                            <th>A</th>
                            <th>A*</th>
                            <th>B</th>
                            <th>C</th>
                            {hasD && <th>D</th>}
                            {hasE && <th>E</th>}
                            {hasFrames && hasAnyDeadbolt && <th>DBF</th>}
                            {hasFrames && hasAnyDeadbolt && <th>DBF*</th>}
                            {hasFrames && <th>CLF</th>}
                            {hasFrames && <th>CLF*</th>}
                            {hasDoors && <th>CLD</th>}
                            {hasDoors && <th>CLD*</th>}
                            {hasDoors && hasAnyDeadbolt && <th>DBD</th>}
                            {hasDoors && hasAnyDeadbolt && <th>DBD*</th>}
                            {hasBS && <th>BS</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {info.rows.map((items, index) => {
                            const r = items[0];

                            return (<tr key={r.name + "-" + index}>
                                <td>
                                    <div style={{maxWidth: 200, display: "flex", flexWrap: "wrap"}}>
                                        {items.map(i =>
                                            <a style={{margin: 2}}
                                               href="#"
                                                id={poOpeningDetailLink(i.openingId, "preps")}>{i.name} </a>
                                        )}
                                    </div>
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {items.length}
                                </td>
                                <td>{r.manager.pair ? "Pair" : "Single"}</td>
                                <ValueCell value={r.manager.values.a}/>
                                <ValueCell value={r.manager.values.aStar}/>
                                <ValueCell value={r.manager.values.b}/>
                                <ValueCell value={r.manager.values.c}/>
                                {hasD && <ValueCell value={r.manager.values.d}/>}
                                {hasE && <ValueCell value={r.manager.values.e}/>}
                                {hasFrames && hasAnyDeadbolt && <ValueCell value={r.manager.values.dbf} disabled={r.manager.pair || !r.hasDeadbolt}/>}
                                {hasFrames && hasAnyDeadbolt && <ValueCell value={r.manager.values.dbfStar} disabled={r.manager.pair || !r.hasDeadbolt}/>}
                                {hasFrames && <ValueCell value={r.manager.values.clf} disabled={r.manager.pair || (!r.hasStrike && !r.has161)}/>}
                                {hasFrames && <ValueCell value={r.manager.values.clfStar} disabled={r.manager.pair || (!r.hasStrike && !r.has161)}/>}
                                {hasDoors && <ValueCell value={r.manager.values.cld} disabled={!r.hasStrike && !r.has161}/>}
                                {hasDoors && <ValueCell value={r.manager.values.cldStar} disabled={!r.hasStrike && !r.has161}/>}
                                {hasDoors && hasAnyDeadbolt && <ValueCell value={r.manager.values.dbd} disabled={!r.hasDeadbolt}/>}
                                {hasDoors && hasAnyDeadbolt && <ValueCell value={r.manager.values.dbdStar} disabled={!r.hasDeadbolt}/>}
                                {hasBS && <ValueCell value={r.manager.values.bs}/>}

                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                </Page>
            )}
        </PageRenderProvider>

    )
}

export function ValueCell(props: {
    value: InternalValue;
    disabled?: boolean;
}) {

    if(props.disabled) {
        return <td></td>
    }

    if(props.value.value === undefined || props.value.value === props.value.cValue) {
        return <td style={{color: grey["700"]}}>{ImperialDistance.showInches(props.value.actualValue)}</td>
    }

    return <td style={{backgroundColor: grey["200"], color: "black", textDecoration: "underline", fontWeight: "bold"}}>{ImperialDistance.showInches(props.value.actualValue)}</td>
}
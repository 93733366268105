import {SubAPI} from "./SubAPI";
import {DateString} from "./Projects";

export class Receiving extends SubAPI {
    list(input: {
        mode: "purchaseOrder" | "default"
    }) {
        return this.fetcher.get<ToReceiveItem[]>("/api/logistics/receiving/pending", input)
    }

    create(input: {
        receivePO: number;
    }) {
        return this.fetcher.post<{id: number}>("/api/logistics/receiving", input)
    }

    get(input: {id: number}) {
        return this.fetcher.get<WarehouseReceive>("/api/logistics/receiving", input)
    }

    listItems(input: {
        receive: number
    }) {
        return this.fetcher.get<WarehouseReceiveItem[]>("/api/logistics/receiving/items", input)
    }

    updateItems(input: {
        receive: number;
        receivedBy: number;
        items: WarehouseReceiveItem[];
    }) {
        return this.fetcher.post("/api/logistics/receiving/items", input)
    }
}

export type WarehouseReceive = {
    id: number;
    name: string;
    deliveredPo: number | null;
    route: number | null;
    status: string;
    updatedAt: DateString;
    updatedBy: number;
    receivedAt: DateString | null;
    receivedBy: number | null;
    receivedByName: string | null;
}

export type WarehouseReceiveItem = {
    id: number;
    name: string;
    qtyExpected: number;
    qtyReceived: number | null;
    qtyDamaged: number | null;
    deliveryItem: number | null;
    purchaseOrderItem: number | null;
    receive: number;
    archived: boolean;
}

export type ToReceiveItem = {
    id: number;
    name: string;
    supplierName: string;
    purchaseOrder: number | null;
    delivery: number | null;
    warehouseReceive: number | null;
    poSubmittedAt?: DateString;
}
import {Grid} from "@mui/material";
import moment from "moment";
import {useContext} from "react";
import {PDFProjectContext} from "../../../pages/project/shopdrawing/openings/OpeningsPDF";
import {Company} from "../../../api/Companies";
import {api} from "../../../api/API";
import {Project} from "../../../api/Projects";
import {RedactedContext} from "../RedactedContext";
import {Page} from "./Page";
import {fixPdfPath} from "./FullSizeImage";

export const pdfInfo = {
    logo: fixPdfPath("/images/logo.png"),
    handleLogo: fixPdfPath("/images/handle.png"),
    companyName: "Tykel Commercial Door Supply Inc",
    address1: "345 Baptist Church Rd",
    address2: "Caledonia, ON",
    postal: "N3W 2G9"
}

export function CoverPage(props: {
    title: string;
    client?: Company;
    lastSubmittal: string|null;
    portrait?: boolean;
}) {
    const project = useContext(PDFProjectContext);
    const redacted = useContext(RedactedContext);
    const client = props.client;
    const contact = getPDFClientContact(project);

    return (
        <Page orientation={props.portrait ? "portrait" : undefined}>
            <div style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                fontSize: "1rem",
                height: "100%",
            }}>
                <div style={{height: props.portrait ? 150 : 40}} />
                <div style={{fontWeight: "bold", textAlign: "center", fontSize: "2rem"}}>
                    {props.title}
                </div>

                <div style={{flex: props.portrait ? undefined : 1, height: props.portrait ? 100 : undefined}} />

                <div style={{
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <div style={{fontSize: "1.6rem"}}>
                        {redacted ? (project.nameAlias || project.name) : project.name}
                    </div>
                    {!redacted && <>
                        <div>{project.addressStreet}</div>
                        <div>{project.addressCity}, {project.addressProvince}</div>
                        <div>{project.addressPostal}</div>
                    </>}
                    <div>
                        Version: {project.versionCode}
                    </div>
                </div>
                <div style={{flex: props.portrait ? undefined : 1, height: props.portrait ? 100 : undefined}} />

                <Grid container>
                    {!redacted && client && <>
                        <Grid item xs={6}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{fontWeight: "bold"}}>
                                    {client.name}
                                </div>
                                <div>{client.addressStreet}</div>
                                <div>{client.addressCity}, {client.addressProvince}</div>
                                <div>{client.addressPostal}</div>
                                {contact && <div>{contact.name} {contact.phone}</div>}
                            </div>
                        </Grid>
                    </>}
                    <Grid item xs={6}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={{fontWeight: "bold"}}>{pdfInfo.companyName}</div>
                            <div>{pdfInfo.address1}</div>
                            <div>{pdfInfo.address2}</div>
                            <div>{pdfInfo.postal}</div>
                            {project.projectManager ?
                                <><div>{combineStrs(project.pmName, decorate(project.pmTitle, "(", ")"), decorate(project.pmPhone, "- "))}</div></> :
                                <><div>{combineStrs(project.internalRepName, decorate(project.internalRepTitle, "(", ")"), decorate(project.internalRepPhone, "- "))}</div></>}
                        </div>
                    </Grid>
                </Grid>

                <div style={{flex: props.portrait ? undefined : 1, height: props.portrait ? 50 : undefined}} />

                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div>Previous Submission: {props.lastSubmittal ? moment.utc(props.lastSubmittal).format("MMM D, YYYY") : "(none)"}</div>
                    <div style={{fontWeight: "bold"}}>Submittal Date: {moment().format("MMM D, YYYY")}</div>
                </div>

                <div style={{height: props.portrait ? 100 : 10}} />

                <div style={{
                    width: "100%",
                    display: "flex", justifyContent: "flex-end", paddingRight: 40
                }}>
                    <img alt="Tykel Logo" src={pdfInfo.logo} style={{width: props.portrait ? 250 : 300}} />
                </div>
            </div>
        </Page>
    )
}

export function decorate(value: string|undefined|null, before: string, after: string = "") {
    if(!value) return undefined;
    return before + value + after;
}

export function combineStrs(...input: (string|undefined)[]) {
    return input.filter(v => !!v).join(" ")
}

export async function getPDFClient(project: Project) {
    if(project.contractor) {
        return api.companies.get({id: project.contractor})
    }

    if(project.client) {
        return api.companies.get({id: project.client})
    }

    return undefined;
}

export function getPDFClientContact(project: Project) {
    if(project.contractor) {
        return {
            id: project.contractorContact,
            name: project.contractorContactName,
            email: project.contractorContactEmail,
            phone: project.contractorContactPhone
        };
    }

    if(project.client) {
        return {
            id: project.clientContact,
            name: project.clientContactName,
            email: project.clientContactEmail,
            phone: project.clientContactPhone
        };
    }

    return undefined;
}
import {distinct, formatCents, selectMany} from "nate-react-api-helpers";
import {useTableData} from "../../../misc/scroller/Table";
import {purchaseOrderItemsTableName} from "./Items";
import {PurchaseOrder, PurchaseOrderItem} from "../../../api/Logistics";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {Grid} from "@mui/material";
import {useContext} from "react";
import {PrintManagerContext} from "../../../misc/pdf/PrintManager";
import {MenuButton} from "./MenuButton";
import { downloadCSV } from "./csvutil";
import {api} from "../../../api/API";
import {HardwareGroupWithContent} from "../../../api/Hardware";
import {purchaseOrderCSV, PurchaseOrderPDF} from "./pdf/PurchaseOrderPDF";
import {useSnackbar} from "../../../misc/Snackbar";
import {blankPrep} from "../../project/shopdrawing/extras/PrepMenuOptions";
import {doorDetailsCSV, useDoorDetails} from "./pdf/DoorDetails";
import {frameDetailsCSV, useFrameDetails} from "./pdf/FrameDetails";

const taxRate = 15;

export function ItemsFooter(props: {
    value: PurchaseOrder;
}) {
    const data = useTableData<PurchaseOrderItem>(purchaseOrderItemsTableName)
    const subTotal = data.reduce((acc, value) => acc + value.unitPriceCents * value.qty, 0);

    const taxAmount = Math.round(subTotal * taxRate / 100);
    const total = subTotal + taxAmount;

    const ctx = useContext(PrintManagerContext);
    const snack = useSnackbar();

    const frameDetails = useFrameDetails(data)
    const doorDetails = useDoorDetails(data)

    return (
        <>
            <table className={tableCls} style={{width: "100%"}}>
                <tbody>
                <tr>
                  <td></td>
                  <td style={{width: 100}}>Taxes</td>
                  <td style={{width: 50}}>{taxRate}%</td>
                  <td style={{width: 80}}>{formatCents(taxAmount)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td>{formatCents(total)}</td>
                </tr>
                </tbody>
            </table>

            <div style={{padding: 8, backgroundColor: grey["200"]}}>
                <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        <MenuButton
                                options={[{
                                    name: "Export PDF",
                                    onClick: async () => {
                                        try {
                                            const cutTemplates = distinct(data
                                                .filter(item => item.kind !== "hardware")
                                                .filter(item => {
                                                    // ignore cut templates when the frame has a blank prep
                                                    if(item.kind !== "frame") return true;

                                                    if(!item.openings) return true;

                                                    const blank = item.openings.find(op => op.frameHardwarePreps.find(v => v.name === blankPrep))
                                                    if(blank) {
                                                        return false;
                                                    }

                                                    return true;
                                                })
                                                .map(item => item.hwPrepCutFile)
                                                .filter(v => !!v)) as number[];

                                            const prepTemplates = distinct(selectMany(data
                                                .filter(item => item.kind !== "hardware")
                                                .filter(item => {
                                                    // ignore cut templates when the frame has a blank prep
                                                    if(item.kind !== "frame") return true;

                                                    if(!item.openings) return true;

                                                    const blank = item.openings.find(op => op.frameHardwarePreps.find(v => v.name === blankPrep))
                                                    if(blank) {
                                                        return false;
                                                    }

                                                    return true;
                                                })
                                                .map(item => {
                                                    return selectMany(item.openings || [], v => v.hardwarePrepFiles)
                                                }), v => v))

                                            const screenElevations = distinct(data
                                                .map(item => item.kind === "frame" ? item.screenElevationFile || 0 : 0)
                                                .filter(v => !!v)) as number[];

                                            const doorElevations = distinct(data
                                                .map(item => item.kind === "door" ? item.doorElevationFile || 0 : 0).filter(v => !!v))

                                            const keyingInfo = await api.keying.getDetailsForPO({id: props.value.id})

                                            const groups = distinct(selectMany(data, i => i.hwPrepGroupNumbers || []).filter(v => !!v))
                                            let hwGroups: HardwareGroupWithContent[] = [];

                                            if (groups.length > 0) {
                                                hwGroups = await api.hardware.listWithContents({
                                                    groups: groups as number[]
                                                });
                                            }

                                            const po = await api.logistics.getPO({id: props.value.id})

                                            await ctx.print(`PO${props.value.poNumber}.pdf`,
                                                async onReady => <PurchaseOrderPDF value={po}
                                                                                   items={data}
                                                                                   hwGroups={hwGroups}
                                                                                   onReady={onReady}
                                                                                   keyDetails={keyingInfo}
                                                />, {
                                                    appendFiles: [
                                                        ...cutTemplates,
                                                        ...screenElevations,
                                                        ...doorElevations,
                                                        ...prepTemplates
                                                    ]
                                                })
                                        } catch (e: any) {
                                            snack.error(e.toString())
                                        }
                                    },
                                }, {
                                    name: "Export CSV",
                                    onClick: () => {
                                        downloadCSV(`PO${props.value.poNumber}.csv`, purchaseOrderCSV({
                                            items: data,
                                            value: props.value,
                                        }))
                                    },
                                }, {
                                    name: "Door Detail CSV",
                                    disabled: doorDetails.length === 0,
                                    onClick: () => {
                                        downloadCSV(`PO${props.value.poNumber}-door-detail.csv`, doorDetailsCSV(doorDetails))
                                    },
                                }, {
                                    name: "Frame Detail CSV",
                                    disabled: frameDetails.length === 0,
                                    onClick: () => {
                                        downloadCSV(`PO${props.value.poNumber}-frame-detail.csv`, frameDetailsCSV(frameDetails))
                                    },
                                }]}
                        >
                            Export
                        </MenuButton>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

const tableCls = css({
    fontSize: 13,
    borderCollapse: "collapse",

    "& td": {
        border: "1px solid " + grey["300"],
        paddingLeft: 8,
        paddingRight: 8,
    },

    "& td:not(:first-child)": {
        textAlign: "right",
    }
})
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {Opening} from "../../../../api/Openings";
import {Divider, ListItemText, MenuItem, Switch, Typography} from "@mui/material";
import {useCallback, useContext, useRef, useState} from "react";
import {useSnackbar} from "../../../../misc/Snackbar";
import { api } from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {TableContext} from "../../../../misc/scroller/Table";
import { Popover } from "../../../../misc/Popover";
import {css} from "@emotion/css";
import {orange} from "@mui/material/colors";
import {ChangeHistory} from "./ChangeHistory";
import {Link} from "react-router-dom";
import {logisticsTableName} from "../logistics/Logistics";
import {buildFilterLink} from "../../../../misc/scroller/Filter";
import {DragIcon, wrapperClass} from "../../quote/openings/RowActions";
import {ConfirmContext} from "../../../../misc/Confirm";


export function RowActions(props: {
    row: Opening
}) {
    const [showMore, setShowMore] = useState<HTMLDivElement|null>(null);
    const [showChangeHistory, setShowChangeHistory] = useState(false);

    const project = useProjectId();
    const close = useCallback(() => setShowMore(null), []);
    const snack = useSnackbar();
    const tbl = useContext(TableContext);

    return <div className={wrapperClass}>
        <DragIcon />
        <div className={BtnClass} onClick={e => setShowMore(e.currentTarget)}>
            <MoreHoriz fontSize="inherit" />
        </div>

        {showMore && <Popover
                open={true}
                anchorEl={showMore}
                onClose={() => setShowMore(null)}
            >
            <div style={{height: 4}} />
            <Link to={`/project/${project}/shop-drawing/logistics?${buildFilterLink({
                tableName:logisticsTableName(project, "openings"),
                filter: {
                    type: "eq",
                    search: {
                        opening: props.row.id
                    },
                }
            })}`} style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem onClick={() => {
                    close();
                }}>
                    <ListItemText>View in Logistics</ListItemText>
                </MenuItem>
            </Link>
            <Divider />
            <Release onDone={close} name="Frame" released={!!props.row.frameRelease} onRelease={(release, confirmUnreleasePODraft) => api.openings.release({
                project: project,
                neededBy: null,
                authorizedBy: "",
                note: "",
                frameForOpenings: [props.row.id],
                unRelease: !release,
                confirmUnreleasePODraft: confirmUnreleasePODraft,
            })} />
            <Release onDone={close} name="Door" released={!!props.row.doorRelease} onRelease={(release, confirmUnreleasePODraft) => api.openings.release({
                project: project,
                neededBy: null,
                authorizedBy: "",
                note: "",
                doorForOpenings: [props.row.id],
                unRelease: !release,
                confirmUnreleasePODraft: confirmUnreleasePODraft,
            })} />
            <Release onDone={close} name="Hardware" released={!!props.row.isHardwareReleased} onRelease={(release, confirmUnreleasePODraft) => api.openings.release({
                project: project,
                neededBy: null,
                authorizedBy: "",
                note: "",
                hardwareForOpenings: [props.row.id],
                unRelease: !release,
                confirmUnreleasePODraft: confirmUnreleasePODraft,
            })} />
            <Divider />
            <MenuItem onClick={() => {
                setShowChangeHistory(true)
                close();
            }}>
                <ListItemText>Change History</ListItemText>
                {props.row.historyCount > 0 && <Typography variant="body2" color="text.secondary" className={changeHistoryCircleNumber}>
                    {props.row.historyCount}
                </Typography>}
            </MenuItem>
            <MenuItem onClick={async () => {
                snack.loading();
                try {
                    await api.openings.upsert(Object.assign({}, props.row, {
                        id: 0,
                        name: props.row.name + " (duplicate)",
                        sortOrder: props.row.sortOrder+1,
                    }));

                    snack.success("Duplicated")
                    await tbl.reload(true);
                } catch (e: any) {
                    snack.error(e.toString());
                }
                close();
            }}>
                <ListItemText>Duplicate Row</ListItemText>
            </MenuItem>
            <div style={{height: 4}} />
        </Popover>}

        {showChangeHistory && <ChangeHistory opening={props.row} onDone={() => setShowChangeHistory(false)} />}
    </div>
}

export const changeHistoryCircleNumber = css({
    color: orange["700"] + " !important",
    border: "1px solid " + orange["700"],
    textAlign: "center",
    width: "1.5em",
    height: "1.5em",
    borderRadius: "50%",
    marginLeft: "1em !important",
} as any)

export function Release(props: {
    name: string;
    released: boolean;
    onRelease(tf: boolean, confirmUnreleasePODraft?: boolean): Promise<{confirmUnreleasePODraft?: string}>;
    onDone(): void;
}) {
    const snack = useSnackbar();
    const loading = useRef(false);
    const tbl = useContext(TableContext);
    const confirm = useContext(ConfirmContext);

    return (
        <MenuItem style={{minWidth: 185}} onClick={async () => {
            if(loading.current) return;
            loading.current = true;

            try {
                snack.loading();

                const result = await props.onRelease(!props.released);
                if(result.confirmUnreleasePODraft) {
                    const ok = await confirm.confirm("Unreleasing will delete following items from draft POs: " + result.confirmUnreleasePODraft)
                    if(ok) {
                        await props.onRelease(!props.released, true);
                    } else {
                        tbl.reload(true);

                        snack.success("Cancelled");
                        props.onDone();
                        loading.current = false;

                        return;
                    }
                }

                tbl.reload(true);

                snack.success("Updated");
                props.onDone();
            } catch (e: any) {
                snack.error(e.toString());
            }

            loading.current = false;
        }}>
            <ListItemText style={{paddingRight: 15}}>{props.name} Released</ListItemText>
            <Switch size="small" checked={props.released} />
        </MenuItem>
    )
}

const BtnClass = css({
    fontSize: "1rem",
    height: "1.2rem",
    width: "1.2rem",
    borderRadius: "50%",

    marginTop: 5,
    marginLeft: 5,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    cursor: "pointer",

    "&:hover": {
        backgroundColor: "hsla(0,0%,0%,0.1)"
    }
})
import {PropsWithChildren} from "react";

export function Center(props: PropsWithChildren<{ }>) {
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{maxWidth: 500, width: "100%"}}>
                {props.children}
            </div>
        </div>
    )
}
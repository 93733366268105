import {DeliveryBox, DeliveryItem} from "../../../../api/Deliveries";
import {SimpleIconButton} from "../../../../misc/lists/SimpleIconButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {MutableRefObject, useMemo, useState} from "react";
import {UpsertBox} from "./UpsertBox";
import {useTableRefresher} from "../../../../misc/scroller/Table";
import {deliveryItemsTableName} from "../DeliveryItems";

export function EditBoxColumn(props: {
    box: DeliveryBox;
    rows: MutableRefObject<DeliveryItem[]>
}) {
    const [show, setShow] = useState(false);
    const refresh = useTableRefresher(deliveryItemsTableName)
    const rows = useMemo(() => {
        if(show) return props.rows.current.filter(r => r.deliveryBox === props.box.id);
        return [];
    }, [show, props.box, props.rows])

    return (
        <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
        }}>
            <SimpleIconButton onClick={(e) => {
                setShow(true);
            }}>
                <OpenInNewIcon />
            </SimpleIconButton>
            {show && <UpsertBox box={props.box} rows={rows} delivery={props.box.delivery} onClose={(shouldRefresh) => {
                setShow(false);

                if(shouldRefresh) {
                    refresh();
                }
            }} />}
        </div>
    )
}
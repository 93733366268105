import {Table, ViewOnly} from "../../../../misc/scroller/Table";
import {InventoryRequest} from "../../../../api/Logistics";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {useUser} from "../../../../misc/Permission";
import {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {getColumns} from "../div10/Div10Table";
import {useProjectUnitSystem} from "../ProjectName";
import {byFixedOrder} from "../pricing/Pricing";
import {projectTablePrefName} from "../TablePrefName";

export function Div10Table(props: {
    project: number
    tab: string;
}) {
    const {project, tab} = props;

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    const sys = useProjectUnitSystem();
    const tableName = projectTablePrefName("project.release.div10", project)

    if(!u) return null;
    if(!sys) return null;

    return (
        <Table<InventoryRequest>
            key={tab}
            locked={locked}
            name={tableName}
            globalPrefsName={projectTablePrefName("project.release.div10", 0)}
            columns={getColumns(tab, project, sys, true, tableName).map(v => ViewOnly(v))}
            fetch={async (ctx) => getAll(ctx, offset => api.logistics.listInventoryRequest({
                project: project,
                offset: offset,
                kind: "div-10",
                state: "not-released",
            }))}
            fetchDeps={[project, tab]}
            groupBy={{
                fixedGroups: [
                    'div-10/corner-guard', 'div-10/locker', 'div-10/mailbox', 'div-10/wr-accessory', 'div-10/wr-partition',
                ],
                groupFx: row => {
                    return "div-10/" + (row?.category || "wr-accessory");
                },
                groupSorter: byFixedOrder([

                    'div-10/corner-guard', 'div-10/locker', 'div-10/mailbox', 'div-10/wr-accessory', 'div-10/wr-partition',
                ]),
                groupFilter: (groupName: string) => groupName === "div-10/" + props.tab,
            }}
        />
    )
}
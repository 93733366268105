import {Dialog, DialogTitle, Grid, DialogContent, DialogActions, Button} from "@mui/material";
import {useProjectId} from "./ProjectName";
import {Loader} from "../../../misc/Loader";
import {api} from "../../../api/API";
import {useGetAll} from "../../logistics/routes/useGetAll";
import moment from "moment";
import {grey} from "@mui/material/colors";
import {css} from "@emotion/css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useState} from "react";
import {Submission} from "../../../api/Submissions";
import {SubmissionDateEditor} from "../../../misc/pdf/SubmissionDate";

export function SubmittalHistory(props: {
    onClose(): void;
}) {
    const project = useProjectId();
    const data = useGetAll(input => api.submission.list(input),
        {project: project},
        [project]);

    const [showCalendarEditFor, setShowCalendarEditFor] = useState<Submission|null>(null);
    if(showCalendarEditFor) {
        return <SubmissionDateEditor id={showCalendarEditFor.id}
                                     previous={showCalendarEditFor}
                                     onDone={() => {
                                         setShowCalendarEditFor(null);
                                         data.reload();
                                     }} />
    }

    return (
        <Dialog open>
            <DialogTitle>Submittal History</DialogTitle>
            <DialogContent>
                <Loader {...data}>
                    {rows =>
                        <table className={tblClass}>
                            <thead>
                            <tr>
                                <th style={{width: 160}}>Date</th>
                                <th>Author</th>
                                <th>Name</th>
                                <th style={{width: 40}}>Rev</th>
                                <th style={{whiteSpace: "nowrap"}}>To Client</th>
                                <th style={{width: 40}}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map(r => <tr>
                                <td>{moment(r.createdAt).format("MMM D [at] h:mma")}</td>
                                <td>{r.createdByName}</td>
                                <td>{submissionName[r.kind]}</td>
                                <td style={{textAlign: "right"}}>{r.versionNumber}</td>
                                <td style={{textAlign: "right"}}>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <div>
                                            {r.submittedDate ?
                                            moment.utc(r.submittedDate).format("YYYY-MMM-DD") : "-"
                                        }
                                        </div>
                                        <div style={{width: 8}} />
                                        <div style={{cursor: "pointer"}} onClick={() => setShowCalendarEditFor(r)}>
                                            <CalendarTodayIcon fontSize="inherit" color="inherit" />
                                        </div>
                                    </div>
                                </td>
                                <td style={{textAlign: "center", padding: 0}}>
                                    <Button size="small" onClick={() => api.submission.download(r.id)}>Download</Button>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    }
                </Loader>
            </DialogContent>
            <DialogActions>
                <Grid container alignItems="center">
                    <Grid item style={{fontSize: "0.9rem", color: grey["600"]}} xs>
                        <div style={{paddingLeft: 16}}>
                            The most recent submittal is also available in the project files section.
                        </div>
                    </Grid>
                    <Grid item>
                        <Button onClick={props.onClose}>Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

const submissionName: any = {
    "quote": "Quote",
    "shop-drawing": "Shop Drawing"
}

const tblClass = css({
    minWidth: 450,
    width: "100%",
    borderCollapse: "collapse",

    "& th": {
        border: "1px solid " + grey["300"],
        textAlign: "left",
        padding: 2,
        paddingLeft: 6, paddingRight: 6,
    },
    "& td": {
        border: "1px solid " + grey["300"],
        padding: 2,
        paddingLeft: 6, paddingRight: 6,
        fontSize: "0.8rem"
    }
})
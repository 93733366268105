import {useAuthenticated} from "nate-react-api-helpers";
import { useLocation } from "react-router-dom";
import { Redirect } from "./Redirect";

export function LoginRedirector(props: {
    ignore: string[]
}) {
    const usr = useAuthenticated();
    const loc = useLocation();

    if(usr.authenticated) return null;
    const hasIgnore = props.ignore.filter(i => loc.pathname === i).length > 0
    if(hasIgnore) return null;

    const ret = new URLSearchParams();
    let route = window.location.pathname;
    if(window.location.search) {
        route = route + "?" + window.location.search;
    }

    ret.set("r", route)

    return <Redirect to={"/login?" + ret.toString()} />
}
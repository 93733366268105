import LinkIcon from '@mui/icons-material/Link';
import {grey} from "@mui/material/colors";
import React from "react";
import {Grid, Tooltip} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export function StatusCell<T extends {
    shortcodeItem?: number;
    shortcodeName?: string;
    shortcodeDescription?: string;
    customerSupplied: boolean;
}>(props: {
    value: T;
}) {
    return (

            <td style={{cursor: "pointer"}}>
                <Grid container spacing={1} alignItems="center">
                    {props.value.shortcodeName &&
                        <Tooltip title={`This row is linked to library shortcode '${props.value.shortcodeName}': ${props.value.shortcodeDescription}`}>
                            <Grid item>
                                <span style={{width: 8}} />
                                <span style={{fontSize: "0.75rem", color: grey["800"], display: "inline-block", transform: "rotate(-45deg)"}}>
                                    <LinkIcon fontSize="inherit" color="inherit" />
                                </span>
                                <span style={{color: grey["800"], fontStyle: "italic"}}>
                                    {props.value.shortcodeName}
                                </span>
                            </Grid>
                        </Tooltip>}
                    {props.value.customerSupplied &&
                    <Tooltip title={`This row is supplied by the customer and not included in pricing or logistics`}>
                        <Grid item>
                            <span style={{fontSize: "0.75rem", color: grey["800"], display: "inline-block"}}>
                                <InfoIcon fontSize="inherit" color="inherit" />
                            </span>
                        </Grid>
                    </Tooltip>
                    }
                </Grid>
            </td>
    )
}
import {Project, UnitSystem} from "../../../../api/Projects";
import {Company} from "../../../../api/Companies";
import {Opening} from "../../../../api/Openings";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {useUser} from "../../../../misc/Permission";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import React, {forwardRef} from "react";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {PDFProjectContext} from "../openings/OpeningsPDF";
import {PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {ContainerPage} from "../../../../misc/pdf/parts/ContainerPage";
import {AutoShrinkTable} from "../openings/pdf/OpeningsPage";
import {ImperialDistance} from "../../../../misc/ImperialDistance";
import {MetricDistance} from "../../../../misc/MetricDistance";
import {
    kdFrameConstruction,
    weldFrameConstruction,
} from "../../quote/openings/QuoteOpenings";

export const OpeningSizePDF = forwardRef(function OpeningSizePDF(props: {
    project: Project;
    client?: Company;
    data: (Opening|QuoteOpening)[]
    sys: UnitSystem;
    onReady(): void;
}, ref: any) {

    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    const parser = props.sys === "imperial" ?
        (input: string) => ImperialDistance.parse(input) :
        (input: string) => MetricDistance.parse(input);

    return (
        <div ref={ref}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <PageRenderProvider<Opening|QuoteOpening> data={props.data} onReady={ready.section()}>
                        {(info)=> {
                            return (<ContainerPage>
                                <AutoShrinkTable>
                                    <thead>
                                    <tr>
                                        {["Opening", "Frame Construction", "Screen Elevation", "Rough Opening Width", "Rough Opening Height"].map((c, index) => <th key={index.toString()} style={{fontSize: "8px", lineBreak: "anywhere"}}>
                                            {c}
                                        </th>)}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {info.rows.map((i, index) => {

                                        let width = "";
                                        let height = "";

                                        if(i.screenElevation) {
                                            width = i.screenElevationOutsideWidth
                                            height = i.screenElevationOutsideHeight
                                        } else {
                                            let dWidth =  parser(i.nominalWidth).add(ImperialDistance.parse("4\""));
                                            let dHeight = parser(i.nominalHeight).add(ImperialDistance.parse("2\""));

                                            if (i.frameConstruction === kdFrameConstruction) {
                                                dWidth = dWidth.add(ImperialDistance.parse("-2\""));
                                                dHeight = dHeight.add(ImperialDistance.parse("-1\""));
                                            } else if(i.frameConstruction === weldFrameConstruction) {
                                                dWidth = dWidth.add(ImperialDistance.parse("1/4\""));
                                                dHeight = dHeight.add(ImperialDistance.parse("1/8\""));
                                            } else {
                                                dWidth = parser("0")
                                                dHeight = parser("0")
                                                console.error("missing frame profile")
                                            }

                                            width = dWidth.toString();
                                            height = dHeight.toString();
                                        }

                                        return (
                                            <tr key={index.toString()}>
                                                <td style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{i.name}</td>
                                                <td style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{i.frameConstruction}</td>
                                                <td style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{i.screenElevation}</td>
                                                <td style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{width}</td>
                                                <td style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{height}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </AutoShrinkTable>
                            </ContainerPage>)
                        }}
                    </PageRenderProvider>
                </PDFProjectContext.Provider>
            </PDF>
        </div>
    )
});
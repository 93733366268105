import {lassoCss, Rect} from "./Cropper";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {useState} from "react";
import {useDragger} from "./LassoDragger";

export function Lasso(props: {
    value: Rect;
    selected: boolean;
    rotation: number;
    zoom: number;
    onClick(): void;
    onChange(value: Rect): void;
}) {
    const {start, end} = props.value;

    const [lasso, setLasso] = useState<HTMLDivElement|null>(null);
    const [topLeft, setTopLeft] = useState<HTMLDivElement|null>(null);
    const [topRight, setTopRight] = useState<HTMLDivElement|null>(null);
    const [bottomLeft, setBottomLeft] = useState<HTMLDivElement|null>(null);
    const [bottomRight, setBottomRight] = useState<HTMLDivElement|null>(null);

    useDragger({
        lasso,
        handle: topLeft,
        vertical: "top",
        horizontal: "left",
        value: props.value,
        onChange: props.onChange,
        rotation: props.rotation,
        zoom: props.zoom,
    });

    useDragger({
        lasso,
        handle: topRight,
        vertical: "top",
        horizontal: "right",
        value: props.value,
        onChange: props.onChange,
        rotation: props.rotation,
        zoom: props.zoom,
    });

    useDragger({
        lasso,
        handle: bottomLeft,
        vertical: "bottom",
        horizontal: "left",
        value: props.value,
        onChange: props.onChange,
        rotation: props.rotation,
        zoom: props.zoom,
    });

    useDragger({
        lasso,
        handle: bottomRight,
        vertical: "bottom",
        horizontal: "right",
        value: props.value,
        onChange: props.onChange,
        rotation: props.rotation,
        zoom: props.zoom,
    });

    useDragger({
        lasso,
        handle: lasso,
        vertical: "both",
        horizontal: "both",
        value: props.value,
        onChange: props.onChange,
        disable: !props.selected,
        rotation: props.rotation,
        zoom: props.zoom,
    });

    return <div
        style={{
            top: start.y,
            left: start.x,
            width: end.x - start.x,
            height: end.y - start.y,
            position: "absolute",

        }}
        className={lassoCss + " " + lasso2Css + " " + (props.selected ? "lasso-selected" : "lasso-not-selected") + " existing-lasso"}
        ref={setLasso}
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault()
            if(!props.selected) props.onClick();
        }}
    >
        {props.selected && <>
            <div style={{top: -6, left: -6}} className={handleCss} ref={setTopLeft} />
            <div style={{top: -6, right: -6}} className={handleCss} ref={setTopRight} />
            <div style={{bottom: -6, left: -6}} className={handleCss} ref={setBottomLeft} />
            <div style={{bottom: -6, right: -6}} className={handleCss} ref={setBottomRight} />
        </>}
    </div>
}

const lasso2Css = css({

    "&.lasso-selected": {
        cursor:"move",
    },

    "&.lasso-not-selected": {
        cursor: "pointer",
        borderColor: "transparent",
    }
})

const handleCss = css({
    position: "absolute",
    border: "1px solid " + grey["800"],
    borderRadius: 2,
    backgroundColor: "white",
    width: 10,
    height: 10,
    cursor: "grab",
})
import {useAuthenticated} from "nate-react-api-helpers/lib/Auth";
import {Permission, User} from "../api/Users";
import {useCallback} from "react";

export function useUser() {
    const u = useAuthenticated();
    const usr = u.lastPingResult as User | null
    return usr;
}

export function usePermission() {
    const u = useUser();

    return useCallback((input: Permission, render: (tf: boolean) => any) => {
        return render(hasPermission(u, input));
    }, [u]);
}

export function hasPermission(input: User|null, perm: Permission) {
    return !!input && input.permissions.indexOf(perm) !== -1;
}
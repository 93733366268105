

// findValues extracts values from an array and only returns the values that are not null or undefined.
export function findValues<T, V>(input: T[], mapper: (value: T) => V | null | undefined): V[] {
    return input.map(mapper).filter(v => v !== null && v !== undefined) as any as V[];
}

export function firstValue<T, V>(input: T[], mapper: (value: T) => V | null | undefined): V | null {
    const list = findValues<T, V>(input, mapper)
    return list.length ? list[0] : null
}
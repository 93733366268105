import React, {useContext} from "react";
import {ShopContainer} from "../ShopContainer";
import {orderByAscending, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {Loader} from "../../../../misc/Loader";
import {
    Option, SelectInput,
    StringCol,
    Table, ViewOnly
} from "../../../../misc/scroller/Table";
import {getAll} from "../openings/Openings";
import {useUser} from "../../../../misc/Permission";
import {ManagerLockContext} from "../ProjectActions";
import {KeyName, KeyOpening} from "../../../../api/Keying";
import {AddColumn} from "./AddColumn";
import {Checkbox2Col, CheckboxEl} from "../ShopTableCol";
import {EditableColHeader} from "./EditableColHeader";
import {css} from "@emotion/css";
import {KeyingSubmittal} from "./submittal/KeyingSubmittal";
import {DisplayKeyingAndAutoFix} from "./DisplayKeyingAndAutoFix";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {projectTablePrefName} from "../TablePrefName";

export function Keying() {
    const project = useProjectId();
    const columnReq = useAsync2(input => api.keying.listNames(input), {project: project}, [project])

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    const changesetPreview = useContext(ChangePreviewContext);

    if(!u) return null;

    const tableName = projectTablePrefName(`project.keying`, project);

    return (
        <ShopContainer name="Keying" submittalCreator={KeyingSubmittal}>
            <Loader {...columnReq}>
                {(columns: KeyName[]) => (
                    <div style={{
                        flex: 1, display: "flex",
                        flexDirection: "column",
                        overflow: "auto"
                    }}>
                        <Table<KeyOpening>
                            key="-"
                            locked={locked || changesetPreview.enabled}
                            name={tableName}
                            globalPrefsName={projectTablePrefName(`project.keying`, 0)}
                            columns={[
                                ViewOnly(StringCol("Seq #", "seqNumber")),
                                StringCol("Name", "name"),
                                StringCol("To", "locationTwo"),
                                {
                                    name: "Products",
                                    editable: {
                                        type: "lookup",
                                        displayKey: "select",
                                        getSelected: (input: KeyOpening) => {
                                            return input.products.map(v=> v.id);
                                        },
                                        multiple: (list: Option[]) => {
                                            return {
                                                value: list.map(l => ({id: l.value, name: l.display})),
                                                display: list.map(l => l.value).join(", "),
                                            };
                                        },
                                        options: (search: string, value: SelectInput<KeyOpening>) => {
                                            let list = (value.props("allProducts").allProducts || []).map(a => ({
                                                value: a.id,
                                                display: a.name,
                                            }))

                                            if(search !== "") {
                                                search = search.toLowerCase();
                                                list = list.filter(l => l.display.toLowerCase().indexOf(search) === 0)
                                                return list;
                                            }

                                            return list
                                        }
                                    },
                                    editKey: "products",
                                    render: d => {
                                        return <DisplayKeyingAndAutoFix value={d} />
                                    },
                                },
                                Checkbox2Col("Keyed", "keyed", {
                                    disabled: (d: KeyOpening) => isDisabled(d),
                                }),
                                ...columns.map(col => ({
                                    name: col.short,
                                    renderName: () => {
                                      return <EditableColHeader column={col} tableName={tableName} onChange={() => {
                                          columnReq.reload();
                                      }} />
                                    },
                                  width: 80,
                                  render: (data: KeyOpening, index: number) => {
                                      const checked = data.keying.indexOf(col.id) !== -1;
                                      return <CheckboxEl
                                          disabled={isDisabled(data)}
                                          checked={checked}
                                          onChange={checked => {
                                          return Object.assign({}, data, {
                                              keyed: true,
                                              keying: checked ? [...data.keying, col.id] :
                                                  data.keying.filter(v => v !== col.id)
                                          })
                                      }} />
                                  }
                                })),
                                {
                                    renderName: () => <AddColumn onChange={() => columnReq.reload()} tableName={tableName} />,
                                    render: () => null,
                                }
                            ]}
                            fetch={ctx => getAll(ctx, offset => api.keying.list({
                                project: project,
                                offset,
                                previewChangeset: changesetPreview.enabled ? changesetPreview.sessionId : undefined
                            }))}
                            fetchDeps={[project, changesetPreview.enabled, changesetPreview.sessionId]}
                            onChange={(input) => api.keying.set(input)}
                            groupBy={{
                                groupFx: i => i.manufacturerName,
                                groupSorter: (a, b) => {
                                    if(a < b) return 1;
                                    if(a > b) return -1;
                                    return 0;
                                },
                                beforeGroup: props => {
                                    return (
                                        <div className={beforeGroupClass}>
                                            {props.name}
                                        </div>
                                    )
                                }
                            }}

                        />
                    </div>
                )}
            </Loader>
        </ShopContainer>
    )
}

export function isDisabled(d: KeyOpening) {
    return !d.allProducts || d.allProducts.filter(p => p.keyed).length === 0
}

export const beforeGroupClass = css({fontSize: "0.75rem", paddingLeft: 4, fontWeight: "bold"});

export function getDisplayProductForOpening(d: KeyOpening) {
    if (d.products && d.products.length > 0) {
        return (d.products || []).map(d => d.name).join(", ")
    }

    if (d.allProducts && d.allProducts.length > 0) {
        orderByAscending(d.allProducts, v => {
            if (v.category2 === "Deadbolts") return 0;
            if (v.category3 === "Locksets") return 1;
            if (v.category3 === "Exit Device") return 2;
            if (v.category3 === "Exit Trims") return 3;

            return 10;
        });

        const first = d.allProducts[0];

        if (first.category2 === "Deadbolts") return first.name;
        if (first.category3 === "Locksets") return first.name;
        if (first.category3 === "Exit Device") return first.name;
        if (first.category3 === "Exit Trims") return first.name;

        return ""
    }

    return "";
}
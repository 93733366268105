import {Table} from "../../../../misc/scroller/Table";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";
import {useAlternative} from "../alternative/Alternative";
import {AnchorCol, JambDepthCol} from "./QuoteOpenings";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {DrawingWallLabel} from "../../../../api/Openings";
import {useIsQuote} from "../QuoteContainer";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import {Grid, IconButton} from "@mui/material";
import {DrawingWallLabelPopup} from "./DrawingWallLabelPopup";
import {TextEditCell} from "./TextEditCell";

export function ReviewWallTypeTitle(props: {
    onDone(): void;
}) {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <IconButton onClick={props.onDone}>
                    <ChevronLeft />
                </IconButton>
            </Grid>
            <Grid item>
                Review Wall Types
            </Grid>
        </Grid>
    )
}

export function ReviewWallTypes() {
    const alt = useAlternative();
    const project = useProjectId();
    const isQuote = useIsQuote();

    return (
        <Table<DrawingWallLabel>
            name={"wall-types"}
            fetch={ctx => {
                return getAll(ctx, offset => isQuote ?
                    api.quoteOpenings.listDrawingWallLabels({
                        project: project,
                        alternative: alt,
                        offset,
                    }) :
                    api.openings.listDrawingWallLabels({
                        project: project,
                        offset,
                    })
                )
            }}
            fetchDeps={[project, alt, isQuote]}
            columns={[
                {
                    name: "Wall Type",
                    editable: {
                        type: "custom",
                        copy: data => data.drawingWallLabel?.toString() || "",
                        paste: (data, value) => {
                            data.drawingWallLabel = value;
                        },
                        render: (props) => {
                            if(!props.row.drawingWallLabel) {
                                return (
                                    <TextEditCell keyName="drawingWallLabel" {...props} />
                                )
                            }

                            return <DrawingWallLabelPopup {...props} />
                        }
                    },
                    editObj: (dst, src, value: string) => {
                        dst.drawingWallLabel = value;
                        dst.drawingWallLabelFile = undefined;
                    },
                    render: (data, col) => {
                        return data.drawingWallLabel || "";
                    },
                    width: 100,
                },
                {
                    name: "Has Attachment",
                    width: 100,
                    render: data => {
                        return data.drawingWallLabelFile ? "Yes" : "No";
                    }
                },
                JambDepthCol("Jamb Depth", "jambDepth"),
                AnchorCol<any>({
                    disableQty: true,
                }),
            ]}
            onChange={v => {
                if(isQuote) return api.quoteOpenings.updateDrawingWallLabel(v)
                return api.openings.updateDrawingWallLabel(v)
            }}
        />
    )
}
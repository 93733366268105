import {Button, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {red} from "@mui/material/colors";
import {formatCents, parseCents} from "nate-react-api-helpers";
import {Section} from "../../../../api/ProjectChangeset";

export const SummarizeSection = React.memo(function(props: {
    value: Section
    onChange(cb: (value: Section[]) => Section[]): void;
}) {
    const [cents, setCents] = useState(formatCents(props.value.amountCents))
    const [centsFocused, setCentsFocused] = useState(false);

    useEffect(() => {
        if(!centsFocused) {
            setCents(formatCents(props.value.amountCents));
        }
    }, [props.value, centsFocused]);

    const change = (v: keyof Section, value: any) => {
        props.onChange(old => {
            return old.map(o => {
                if(o === props.value) {
                    return {...o, [v]: value}
                }

                return o;
            })
        })
    }

    return (
        <div style={{paddingTop: 16, paddingBottom: 16}}>
            <TextField label={"Section Name"} fullWidth size="small" value={props.value.name} onChange={e => change("name", e.target.value)} />
            <div style={{height: 8}} />
            <TextField label={"Description"} fullWidth multiline rows={"4"} size="small" value={props.value.description} onChange={e => change("description", e.target.value)} />
            <div style={{height: 8}} />
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div>
                    <Button size="small" onClick={() => {
                        props.onChange(v => {
                            const index = v.findIndex(s => s === props.value);
                            return v.slice(0, index).concat([{
                                id: -1,
                                name: "",
                                description: "",
                                amountCents: 0,
                            }], v.slice(index))
                        })
                    }}>Add Above</Button>
                    <Button size="small" onClick={() => {
                        props.onChange(v => {
                            const index = v.findIndex(s => s === props.value);
                            return v.slice(0, index+1).concat([{
                                id: -1,
                                name: "",
                                description: "",
                                amountCents: 0,
                            }], v.slice(index+1))
                        })
                    }}>Add Below</Button>
                    <Button style={{color: red["800"]}} size="small" onClick={() => {
                        props.onChange(v => v.filter(s => s !== props.value))
                    }}>Remove</Button>
                </div>

                <TextField label={"Amount"} size="small"
                           style={{width: 150}}
                           onFocus={e => setCentsFocused(true)}
                           onBlur={e => setCentsFocused(false)}
                           value={cents}
                           onChange={e => {
                                setCents(e.target.value);
                                change("amountCents", parseCents(e.target.value))
                            }} />
            </div>
        </div>
    )
});
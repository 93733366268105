import {
    Divider,
    Grid, InputAdornment,
    ListItemIcon,
    ListItemText, Menu,
    MenuItem,
    TextField
} from "@mui/material";
import {api} from "../../../api/API";
import {useProjectId} from "../shopdrawing/ProjectName";
import {Loader} from "../../../misc/Loader";
import {useAlternative} from "./alternative/Alternative";
import {useGetAll} from "../../logistics/routes/useGetAll";
import {useState} from "react";
import {CreateAlternative} from "./alternative/CreateAlternative";
import {useNavigate, useParams} from "react-router-dom";
import {ManageAlternatives} from "./alternative/ManageAlternative";
import EditIcon from '@mui/icons-material/Edit';
import {Alternative} from "../../../api/QuoteAlternatives";
import {first} from "nate-react-api-helpers";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export function QuoteAlternative() {
    const project = useProjectId();
    const alt = useAlternative();

    const list = useGetAll(ctx => api.quoteAlternatives.list(ctx),
        {
        project: project,
    }, [project]);

    const [showCreate, setShowCreate] = useState(false);
    const [showManage, setShowManage] = useState<Alternative|null>(null);
    const nav = useNavigate();
    const params = useParams<{workspace: string; project: string}>();
    const [menuAnchor, setMenuAnchor] = useState<any>(null);

    return (
        <Grid item>
            <Loader {...list}>
                {items => <>
                    {menuAnchor && <Menu
                        anchorEl={menuAnchor}
                        onClose={() => setMenuAnchor(null)} open
                        style={{width: 300}}
                    >

                        {items.map(i =>
                            <MenuItem key={i.id.toString()}
                                      onClick={() => {
                                          setMenuAnchor(null)
                                          nav(`/project/${params.project}/quote/${i.id}/${params.workspace}`)
                                      }}
                            >
                                <ListItemText>
                                    {i.name}
                                    <div style={{
                                        fontSize: "0.75rem",
                                        whiteSpace: "normal",
                                        lineHeight: "1em",
                                    }}>{i.description}</div>
                                </ListItemText>

                                <ListItemIcon
                                    style={{width: 20, minWidth: 20, paddingLeft: 10}}
                                    onClick={e => {
                                    e.stopPropagation()
                                    setShowManage(i);
                                    setMenuAnchor(null)
                                }}>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                            </MenuItem>)}
                        <Divider />
                        <MenuItem value="-" onClick={() => {
                            setShowCreate(true)
                        }}>
                            Create New
                        </MenuItem>
                    </Menu>}
                    <TextField
                        fullWidth
                        size="small"
                        value={first(items||[], i => i.id === alt)?.name || ""} label="Alternative"
                        onClick={e => setMenuAnchor(e.currentTarget)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <ArrowDropDownIcon />
                            </InputAdornment>
                        }}
                    />
                </>}
            </Loader>

            {showCreate && <CreateAlternative onCancel={() => setShowCreate(false)} onDone={() => {
                list.reload();
                setShowCreate(false)
            }} />}
            {showManage && <ManageAlternatives value={showManage}
                                               onCancel={() => setShowManage(null)}
                                               onDone={() => {
                                                   list.reload();
                                                   setShowManage(null)
                                               }} />}
        </Grid>
    )
}
import React, {useState} from "react";
import {useFilePicker} from "./FilePicker";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import useWindowSize from "react-use/lib/useWindowSize";
import {AutoComplete} from "../openings/AutoComplete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";
import {FileInfo} from "../../../../api/Files";
import {RelatedFileType} from "../../../../api/Products";

type Item = {
    id: number;
    name: string;
}

export function ProductFileLookup(props: {
    title: string;
    type: RelatedFileType;
    manufacturer: number;
    value: Item | null,
    onDone(value: Item | null): void;
}) {
    const filePicker = useFilePicker({
        accept: ["application/pdf"]
    });
    const [value, setValue] = useState(props.value);

    const action = useAsyncAction(async (file: File) => {
        const fileObj = await api.files.upload({
            file: file,
        });

        setValue({
            id: fileObj.id,
            name: file.name,
        });
    }, [filePicker]);

    const size = useWindowSize();

    return (
        <Dialog open fullWidth maxWidth="lg">
            <form onSubmit={e => {
                e.preventDefault()
                props.onDone(value);
            }}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            {value ? <iframe
                                src={api.files.downloadLink(value.id, "inline")}
                                style={{
                                    height: size.height * 0.75,
                                    width: "100%",
                                    border: "1px solid black",
                                }}
                            ></iframe> : <div>Choose a file on the right</div>}
                        </Grid>
                        <Grid item xs={4}>
                            <AutoComplete<FileInfo>
                                label="Template"
                                size="small"
                                InputProps={{
                                    endAdornment: <>
                                        <IconButton size="small" onClick={async () => {
                                            const files = await filePicker();
                                            action.callback(files[0])
                                        }}>
                                            <FileUploadIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => setValue(null)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </>,
                                }}
                                search={async search => {
                                    const list = await api.products.relatedFiles({
                                        manufacturer: props.manufacturer,
                                        type: props.type,
                                    })

                                    if(search === "") return list;

                                    return list.filter(l => l.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                                }}
                                value={value}
                                equals={(a, b) => a.id === b.id}
                                makeLabel={a => a.name}
                                onSelect={v => {
                                    setValue(v);
                                }}
                                fullWidth
                            />
                            {action.LoadingElement}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={e => props.onDone(value as any)}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
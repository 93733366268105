import {ProjectDir, ProjectFile} from "../../../../api/ProjectFiles";
import {useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

export function isProjectFile(input: ProjectFile|ProjectDir): input is ProjectFile {
    return input.hasOwnProperty("status");
}

function splitExtension(name: string, isFile: boolean) {
    if(!isFile) {
        return {
            base: name,
            ext: "",
        }
    }

    const parts = name.split(".");
    const ext = parts.pop();
    const base = parts.join(".");

    return {
        base,
        ext,
    }
}

function addExt(base: string, ext?: string) {
    if(!ext) return base;
    return base + "." + ext;
}

export function Rename(props: {
    value: ProjectFile|ProjectDir;
    onCancel(): void;
    onDone(): void;
}) {

    const isFile = isProjectFile(props.value);
    const {base, ext} = splitExtension(props.value.name, isFile);
    const [name, setName] = useState(base);

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        if(isProjectFile(props.value)) {
            await api.projectFiles.upsert(input)
        } else {
            await api.projectFiles.upsertDir(input)
        }

        onDoneRef.current();
    }, []);

    return (
        <Dialog open onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault();
                update.callback(Object.assign({}, props.value, {
                    name: addExt(name, ext),
                }))
            }}>
            <DialogTitle>
                Rename {props.value.name}
            </DialogTitle>
            <DialogContent>

                <div style={{height: 8}} />
                <TextField label="Name"
                           fullWidth
                           value={name}
                           onChange={e => setName(e.target.value as any)}
                            InputProps={{
                                endAdornment: isFile && <div style={{paddingLeft: 8}}>.{ext}</div>
                            }}
                />

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button type="submit">Update</Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
import {PurchaseOrderItem} from "../../../../api/Logistics";
import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {Page} from "../../../../misc/pdf/parts/Page";
import {table} from "../../../../misc/pdf/parts/TableStyle";
import {PDFHeader} from "../../../../misc/pdf/parts/PDFHeader";
import {distinct, orderByAscending, selectMany} from "nate-react-api-helpers";
import {
    dbStrikePrep,
    followCloserRule,
    locksetASAPrep,
    noPrepNeeded, PrepKey
} from "../../../project/shopdrawing/extras/PrepMenuOptions";
import {useMemo} from "react";
import {TblCfg} from "./DoorDetails";

export type FrameAndDoorDetail = {
    opening: number;
    openingSequence: number;
    openingLocationTransition: string;
    openingType: string;
    name: string;
    qty: number;
    frameHardwarePreps: POHwPrepItem[];
    doorHardwarePreps: POHwPrepItem[];
    type: string;
    purchaseOrderItem: PurchaseOrderItem;
}

export type POHwPrepItem = {
    prepKey: string;
    name: string;
    productCode: string;
}

export function FrameDetails(props: {
    value: FrameAndDoorDetail[]
    onReady(tf: boolean): void;
}) {
    if(props.value.length === 0) {
        setTimeout(() => props.onReady(true), 100);
        return null;
    }

    return (
        <PageRenderProvider key="frame-details" data={props.value} onReady={props.onReady}>
            {(info: DataSplitInfo<FrameAndDoorDetail>) =>
                <Page orientation="landscape" key={info.pageIndex.toString()}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                        <PDFHeader noAddress title="Frame Detail"/>
                        <table className={table}>
                            <thead>
                            <tr>
                                {tableCfg.map(r => <th key={r.name}>{r.name}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {info.rows.map((r, index) => <tr key={info.pageIndex.toString() + "-" + index.toString()}>
                                {tableCfg.map(col => <td key={col.name}>
                                    {col.selector(r)}
                                </td>)}
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                </Page>
            }
        </PageRenderProvider>
    )
}

const tableCfg = [
    {name: "Opening", selector: (r) => r.name},
    {name: "Qty", selector: (r) => r.qty.toString()},
    {name: "Type", selector: (r) => r.type},
    {name: "Width", selector: (r) => r.purchaseOrderItem.dimWidth},
    {name: "Height", selector: (r) => r.purchaseOrderItem.dimHeight},
    {name: "Handing", selector: (r) => r.purchaseOrderItem.frameHanding},
    {name: "Label", selector: (r) => r.purchaseOrderItem.frameLabel},
    {name: "Series", selector: (r) => r.purchaseOrderItem.frameSeries},
    {name: "Material", selector: (r) => r.purchaseOrderItem.frameMaterial},
    {name: "Gauge", selector: (r) => r.purchaseOrderItem.frameGauge},
    {name: "Screen Elev", selector: (r) => r.purchaseOrderItem.frameScreenElev},
    {name: "Profile", selector: (r) => r.purchaseOrderItem.frameProfile},
    {name: "Jamb depth", selector: (r) => r.purchaseOrderItem.frameJambDepth},
    {name: "Construction", selector: (r) => r.purchaseOrderItem.frameConstruction},
    {name: "Hardware Preps", selector: (r) => makeHardware(r)},
    {name: "Anchor", selector: (r) => makeAnchors(r)},
] as TblCfg<FrameAndDoorDetail>[]

function makeAnchors(r: FrameAndDoorDetail) {
    return (r.purchaseOrderItem.openings || [])
        .filter(o => o.id === r.opening && o.kind === "frame-anchor")
        .map(o => {
            const qty = o.frameAnchorQtyOverride
            if (qty !== null && qty !== undefined) {
                return `${o.frameAnchorProductCode} - Qty:${qty}`
            }

            return o.frameAnchorProductCode
        }).join(", ")
}

function makeHardware(r: FrameAndDoorDetail) {
    return distinct(r.frameHardwarePreps.filter(v => {
        // leave v.name for historical products
        if (v.name === noPrepNeeded || v.prepKey === PrepKey.None) return false;

        // these preps don't apply to frames when it's a double door since those
        // preps go into the inactive door
        if (r.openingType === "Pair") {

            // leave v.name comparison for historical products
            if (v.name === locksetASAPrep || v.prepKey === PrepKey.Lockset161ASA || v.prepKey === PrepKey.ElectricStrikeASA || v.prepKey === PrepKey.ElectricStrike) {
                return false;
            }

            // leave v.name comparison for historical products
            if (v.name === dbStrikePrep || v.prepKey === PrepKey.Deadbolt) {
                return false;
            }
        }

        return true;
    }).map(v => {
        if (v.name === followCloserRule || v.prepKey === PrepKey.CloserReinforcing || v.prepKey === PrepKey.SurfaceAutoOpCloseReinf) {

            if (!r.openingLocationTransition || r.openingLocationTransition === "" || r.openingLocationTransition === "To") {
                return "Reg Closer Reinf"
            } else {
                return "PA Closer Reinf"
            }
        }

        if (v.name.includes("Custom")) {
            return v.name + ": " + v.productCode;
        }

        return v.name
    })).join(", ")
}

export function useFrameDetails(items: PurchaseOrderItem[]): FrameAndDoorDetail[] {
    return useMemo(() => {
        const fr: FrameAndDoorDetail[] = selectMany(items.filter(v => v.kind === "frame").map(i => {
            if (!i.openings || i.openings.length === 0) return [];

            return i.openings
                .filter(op => op.kind === "frame")
                .map((op): FrameAndDoorDetail => {
                    return {
                        opening: op.id,
                        openingSequence: op.seqNumber,
                        openingLocationTransition: op.locationTransition,
                        openingType: op.type,
                        name: op.name,
                        qty: op.qty,
                        purchaseOrderItem: i,
                        type: op.type,
                        doorHardwarePreps: [],
                        frameHardwarePreps: op.frameHardwarePreps,
                    }
                })
        }), v => v);

        orderByAscending(fr, v => v.openingSequence)

        return fr;
    }, [items]);
}

export function frameDetailsCSV(items: FrameAndDoorDetail[]): string[][] {
    return [
        tableCfg.map(r => r.name),
        ...items.map(r =>
            tableCfg.map(col => col.selector(r))
        )
    ]
}
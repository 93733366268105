import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import {useState} from "react";
import {TransferProductModal} from "./TransferProductModal";

export function TransferProducts(props: {
    div10?: boolean;
}) {
    const [show, setShow] = useState(false)

    return (<>
        <SmallGreyButton startIcon={<FindReplaceIcon />} onClick={() => setShow(true)}>Substitute Products</SmallGreyButton>
        {show && <TransferProductModal div10={props.div10} onDone={() => setShow(false)} />}
    </>)
}
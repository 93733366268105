import {AdjustCol, NumberCol, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import {CheckboxSelectorCol} from "./Checkbox";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {useUser} from "../../../../misc/Permission";
import {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {projectTablePrefName} from "../TablePrefName";
import {InventoryRequest} from "../../../../api/Logistics";

export function FrameAnchorTable(props: {
    project: number
}) {
    const {project} = props;

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    if(!u) return null;

    return (
        <Table<InventoryRequest>
            key="frame-anchor"
            locked={locked}
            name={projectTablePrefName("project.release.frameAnchors", project)}
            globalPrefsName={projectTablePrefName("project.release.frameAnchors", 0)}
            columns={[
                CheckboxSelectorCol(),
                ViewOnly(StringCol("Floor", "floor", 80)),
                AdjustCol(ViewOnly(StringCol("Opening", "openingName", 80)), {
                    fixed: true
                }),
                ViewOnly(StringCol("Name", "name", 150)),
                ViewOnly(NumberCol("Qty", "qty", 50)),
            ]}
            fetch={async (ctx) => getAll(ctx, offset => api.logistics.listInventoryRequest({
                project: project,
                offset: offset,
                kind: "independent-frame-anchors",
                state: "not-released",
            }))}
            fetchDeps={[project]}
        />
    )
}
import {QtyWithPreview} from "./QtyWithPreview";
import {RouteStopGroup} from "../../../api/Logistics";
import {Completed} from "./Completed";

export function DropPoItemRow(props: {
    value: RouteStopGroup;
    routeCompleted: boolean;
}) {

    return (
        <tr>
            <td>PO #{props.value.dropoffPoNumber}</td>
            <td>
                <QtyWithPreview data={props.value.items || []} />
            </td>
            <td>
                <Completed contents={props.value.items || []} routeComplete={props.routeCompleted} complete={props.value.completed} />
            </td>
            <td></td>
        </tr>
    )
}

function arrayToCsv(data: string[][]){
    return data.map(row =>
        row
            .map(String)  // convert every value to String
            .map(v => v.replaceAll('"', '""'))  // escape double colons
            .map(v => `"${v}"`)  // quote it
            .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines
}

export function downloadCSV(name: string, data: string[][]) {
    const content = arrayToCsv(data)
    downloadBlob(content, name, "text/csv")
}

export function downloadBlob(content: any, filename: string, contentType: string) {
    // Create a blob
    const blob = new Blob([content], {type: contentType});
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 10)
}
import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";

export class Submissions extends SubAPI {
    list(props?: {
        search?: string;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<Submission>>("/api/submissions", props);
    }

    setSubmitted(input: {
        uuid: string;
        date: string | null;
    }) {
        return this.fetcher.post("/api/submission/set-submitted", input)
    }

    download(id: number) {
        const c = new URLSearchParams();
        c.set("id", id.toString());
        window.open("/api/submission/download?" + c.toString(), "_blank");
    }

    setPreferences(props: {
        project: number;
        kind: SubmissionKind;
        value: string;
    }) {
        return this.fetcher.post("/api/submission/preferences", props)
    }

    getPreferences(props: {
        project: number;
        kind: SubmissionKind;
    }) {
        return this.fetcher.get<SubmissionPref>("/api/submission/preferences", props)
    }
}

type SubmissionPref = {
    value: string,
    lastSubmission: string|null;
}

export type SubmissionKind = "shop-drawing" | "keying-schedule" | "div10" | "hardware-template" | "quote" | "price-request" | "div10-hardware-template" | "route-packing-slip" | "hardware-schedule" | "product-summary" | "frame-openings";

export type Submission = {
    id: number;
    createdAt: DateString;
    createdBy: number;
    createdByName: string;
    kind: SubmissionKind;
    versionNumber: number;
    submittedDate?: string;
}
import {useParams} from "react-router-dom";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Container} from "../../../misc/Container";
import {WhiteColumn} from "../../logistics/purchasing/WhiteColumn";
import {Loader} from "../../../misc/Loader";
import {EditPayable} from "./EditPayable";

export function Payable() {
    const params = useParams<{id: string}>();
    const id = parseInt(params["id"] || "0")
    const data = useAsync2(input => api.logistics.getPO(input), {id: id}, [id]);

    return (
        <Container>
            <WhiteColumn>
                <Loader {...data}>
                    {value => <EditPayable value={value} onReload={data.reload} />}
                </Loader>
            </WhiteColumn>
        </Container>
    )
}
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {EventEmitter} from "nate-react-api-helpers";
import {grey} from "@mui/material/colors";
import {css} from "@emotion/css";
import {Column, TID} from "../../../../misc/scroller/Table";

export function CheckboxSelectorCol<T extends TID>(props?: {
    disabled?(input: T): boolean
}) {
    return {
        name: "",
        renderName: () => <div style={{marginTop: -4, marginBottom: -4}}>
            <CheckboxContent id="all" />
        </div>,
        render: o => <div style={{marginTop: -4, marginBottom: -4}}>
            <CheckboxContent id={o.id} disabled={props?.disabled && props.disabled(o)} />
        </div>,
        width: 50,
    } as Column<T>
}

export function Checkbox(props: {
    checked: boolean
    onClick?: (e: any) => void,
    size?: "small";
    disabled?: boolean
}) {
    if(props.disabled) {
        return (
            <div onClick={props.onClick} className={disabledStyle}>
                <svg viewBox="0 0 24 24" style={{color: "inherit"}}>
                    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
                </svg>
            </div>
        );
    }

    if(props.checked) {
        return (
            <div onClick={props.onClick} className={checkedStyle}>
                <svg viewBox="0 0 24 24">
                    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                </svg>
            </div>
        );
    }

    return (
        <div onClick={props.onClick} className={unCheckedStyle}>
            <svg viewBox="0 0 24 24">
                <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
            </svg>
        </div>
    );
}

const checkedStyle = css({
    paddingLeft: 6, paddingRight: 6, paddingTop: 2, paddingBottom: 2,
    fontSize: "1.1rem",
    height: "1.4rem",

    "& svg": {
        width: "1em",
        height: "1em",
    }
})
const unCheckedStyle = css({
    paddingLeft: 6, paddingRight: 6, paddingTop: 2, paddingBottom: 2,
    fontSize: "1.1rem",
    height: "1.4rem",

    "& svg": {
        width: "1em",
        height: "1em",
    }
})
const disabledStyle = css({
    paddingLeft: 6, paddingRight: 6, paddingTop: 2, paddingBottom: 2,
    fontSize: "1.1rem",
    height: "1.4rem",

    "& svg": {
        width: "1em",
        height: "1em",

        "& path": {
            stroke: grey["300"],
            fill: grey["300"],
        }
    }
})

export function CheckBoxHeader() {
    const [selectAll, setSelectAll] = useState(false);
    const ctx = useContext(CheckboxContext);

    return <th key="checkbox" style={{padding: 0}} onClick={() => {
        setSelectAll(!selectAll)
        ctx.selectAll(!selectAll)
    }}>
        <Checkbox size="small" checked={selectAll} />
    </th>
}

export const CheckboxContext = createContext({
    visibleIds: {current: [] as number[]},
    listen: (id: number, callback: (tf: boolean) => void, disabled: boolean) => {
        return {
            cancel: () => {},
        }
    },
    listenAll: (callback: (selected: number[]) => void) => {
        return {
            cancel: () => {},
        }
    },
    set: (id: number, value: boolean) => {

    },
    selectAll: (tf: boolean) => {}
})

export function useCheckboxSelection() {
    const {listenAll} = useContext(CheckboxContext);
    const [selected, setSelected] = useState<number[]>([]);

    useEffect(() => {
        const sub = listenAll(input => setSelected(input));
        return () => sub.cancel();
    }, [listenAll])

    return selected;
}

export function useIsCheckboxSelected(id: number | "all", notCheckable: boolean = false): [boolean, (e: Event) => void] {
    const ctx = useContext(CheckboxContext);
    const [selected, setSelected] = useState(false);
    const {listen} = ctx;

    useEffect(() => {
        if(id === "all") {
            return;
        }

        const sub = listen(id, setSelected, notCheckable);
        return () => {
            sub.cancel();
        }
    }, [listen, id, notCheckable]);

    const selectedRef = useRef(selected);
    selectedRef.current = selected;
    const {set, selectAll} = ctx;

    const onClick = useCallback((e: Event) => {
        e.stopPropagation();

        if(id === "all") {
            selectAll(!selectedRef.current);
            setSelected(!selectedRef.current)
        } else {
            set(id, !selectedRef.current);
        }
    }, [id, set, selectAll]);

    return [selected, onClick];
}

export function CheckboxProvider(props: PropsWithChildren<{}>) {

    const obj = useRef<{[id: number]: boolean}>({})
    const emit = useMemo(() => new EventEmitter<{id: number, value: boolean}>(), []);

    const selected = useCallback(() => {
        const list: number[] = [];

        for(let i in obj.current) {
            if(obj.current[i]) {
                if(typeof i === "number") {
                    list.push(i);
                } else {
                    list.push(parseInt(i))
                }
            }
        }

        return list;
    }, [obj]);

    const visible = useRef([]);
    const disabled = useRef<{[id: number]: boolean}>({});

    const listen = useCallback((id: number, callback: (tf: boolean) => void, isDisabled: boolean) => {
        const sub = emit.subscribe(value => {
            if(value.id === id) callback(value.value);
        })

        if(!obj.current.hasOwnProperty(id)) {
            obj.current[id] = false;
        }

        disabled.current[id] = isDisabled;
        callback(obj.current[id]);
        return sub;
    }, [emit]);

    const listenAll = useCallback((callback: (selected: number[]) => void) => {
        return emit.subscribe(() => callback(selected()))
    }, [emit, selected]);

    const set = useCallback((id: number, value: boolean) => {
        if(disabled.current[id] && value) {
            console.log("disabled")
            return;
        }

        obj.current[id] = value;
        emit.emit({id: id, value: value});
    }, [emit]);

    const selectAll = useCallback((tf: boolean) => {
        visible.current.map(id => {
            if(tf && disabled.current[id]) {
                return; // disabled
            }

            if (obj.current[id] !== tf) {
                obj.current[id] = tf;
                emit.emit({id: id, value: tf});
            }

            return null;
        })
    }, [emit])

    const ctx = useMemo(() => ({
        visibleIds: visible,
        listen: listen,
        listenAll: listenAll,
        set: set,
        selectAll: selectAll,
    }), [listen, listenAll, selectAll, set]);

    return (
        <CheckboxContext.Provider value={ctx}>
            {props.children}
        </CheckboxContext.Provider>
    );
}

export function CheckboxContent(props: {
    id: number | "all";
    disabled?: boolean
}) {
    const [selected, onClick] = useIsCheckboxSelected(props.id, !!props.disabled);

    return (
        <Checkbox size="small" checked={selected} onClick={onClick} disabled={props.disabled} />
    )
}
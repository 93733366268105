import {PriceLine} from "../../../../api/Pricing";
import {FreeCell, Group} from "../../../../misc/scroller/Table";
import {getSectionName, groupTitle} from "./BeforeGroup";
import {EventEmitter, formatCents, range, selectMany, sum} from "nate-react-api-helpers";
import {profit, profit2} from "./Pricing";
import {useContext, useState} from "react";
import {FilterContext} from "../../../../misc/scroller/Filter";
import { css } from "@emotion/css";
import {blue, grey} from "@mui/material/colors";
import { SectionTotal } from "./SectionTotal";
import {useProjectId} from "../ProjectName";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {QuotePriceLine, SectionName} from "../../../../api/QuotePricing";
import {useAlternative} from "../../quote/alternative/Alternative";
import {useIsQuote} from "../../quote/QuoteContainer";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {api} from "../../../../api/API";
import {isPriceLine2, PriceLine2, PriceRow, RemovalSuffix} from "./Frames";
import {DoorSectionContext} from "./DoorSectionProvider";
import {useColumnIndex} from "../../../../misc/scroller/GroupRowUtil";
import {IndexCounter} from "./IndexCounter";

export function AfterGroup2(props: {
    name: string;
    rows: PriceRow[];
    all: Group<PriceRow>[];
}) {
    const doorSection = useContext(DoorSectionContext)

    if(props.name.endsWith(RemovalSuffix))  {
        const prefix = props.name.replace(RemovalSuffix, "");
        const rows = selectMany(props.all.filter(p => p.key.startsWith(prefix)), p => p.rows);

        return <AfterGroupInner2 name={prefix as any} rows={rows} />
    }

    let name = (props.name as any) as SectionName
    if(props.name === "hardware-after") {
        name = "hardware";
    }

    if(name.startsWith("door-prep")) {
        name = "door-prep";
    }

    switch(name as any) {
        case "hardware": {

            const rows = selectMany(props.all
                .filter(p => p.key.startsWith("hardware/")), p => p.rows);

            return <AfterGroupInner2 name={name} rows={rows}/>
        }

        case "door-hm":
        case "door-wd":
        case "door-other":
        case "door-prep":

        case 'div-10/corner-guard':
        case 'div-10/locker':
        case 'div-10/mailbox':
        case 'div-10/wr-accessory':
        case 'div-10/wr-partition':
        case "frame-anchor":
        case "frame-prep":
        case "frame": {
            let rows = props.rows;
            return <AfterGroupInner2 name={name} rows={rows}/>
        }

        case "door-totals": {
            let rows = selectMany(props.all.filter(r => r.key === "door-" + doorSection.tab || r.key === "door-prep/" + doorSection.tab), v => v.rows);
            return <AfterGroupInner2 name={name} rows={rows}/>
        }
        case "frame-totals": {
            let rows = selectMany(props.all.filter(r => r.key === "frame" || r.key === "frame-anchor" || r.key === "frame-prep"), v => v.rows);
            return <AfterGroupInner2 name={name} rows={rows}/>
        }
        case "project":
            return <Project2 />
        default:
            return null;
    }
}

export function AfterGroup(props: {
    name: string;
    rows: PriceLine[] | QuotePriceLine[] | PriceLine2[];
    all: Group<PriceLine|QuotePriceLine|PriceLine2>[];
}) {
    const doorSection = useContext(DoorSectionContext)

    if(props.name.endsWith(RemovalSuffix))  {
        const prefix = props.name.replace(RemovalSuffix, "");
        const rows = selectMany(props.all.filter(p => p.key.startsWith(prefix)), p => p.rows);

        return <AfterGroupInner name={prefix as any} rows={rows} />
    }

    let name = (props.name as any) as SectionName
    if(props.name === "hardware-after") {
        name = "hardware";
    }

    if(name.startsWith("door-prep")) {
        name = "door-prep";
    }

    switch(name as any) {
        case "hardware": {

            const rows = selectMany(props.all
                .filter(p => p.key.startsWith("hardware/")), p => p.rows);

            return <AfterGroupInner name={name} rows={rows}/>
        }

        case "door-hm":
        case "door-wd":
        case "door-other":
        case "door-prep":

        case 'div-10/corner-guard':
        case 'div-10/locker':
        case 'div-10/mailbox':
        case 'div-10/wr-accessory':
        case 'div-10/wr-partition':
        case "frame-anchor":
        case "frame-prep":
        case "frame": {
            let rows = props.rows;
            return <AfterGroupInner name={name} rows={rows}/>
        }

        case "door-totals": {
            let rows = selectMany(props.all.filter(r => r.key === "door-" + doorSection.tab || r.key === "door-prep/" + doorSection.tab), v => v.rows);
            return <AfterGroupInner name={name} rows={rows}/>
        }
        case "frame-totals": {
            let rows = selectMany(props.all.filter(r => r.key === "frame" || r.key === "frame-anchor" || r.key === "frame-prep"), v => v.rows);
            return <AfterGroupInner name={name} rows={rows}/>
        }
        case "project":
            return <Project />
        default:
            return null;
    }
}

const blankArray = [] as any;

export function sumPriceLines(rows: PriceLine2[]|PriceLine[]|QuotePriceLine[]) {

    const result =  sum(rows.map(r => {
        if(isPriceLine2(r)) {
            // we'll catch previous on the current one
            if(r.kind === "previous") return 0;
            if(!r.projectChangeset) return 0;

            // ignore total on lines where the amount is totally removed
            if(r.proposingTag === "remove") {
                // return r.previous.extendedPrice - r.initial.extendedPrice;
            }

            return r.extendedPrice;
        }

        return r.extendedPrice
    }));

    console.log("total", result)

    return result;
}

export function sumPriceLines2(rows: PriceRow[]) {

    const result =  sum(rows.map(row => {
        if(row.kind === "price" || row.kind === "quote-price") {
            const r = row.value;

            if (isPriceLine2(r)) {
                // we'll catch previous on the current one
                if (r.kind === "previous") return 0;
                if (!r.projectChangeset) return 0;

                // ignore total on lines where the amount is totally removed
                if (r.proposingTag === "remove") {
                    // return r.previous.extendedPrice - r.initial.extendedPrice;
                }

                return r.extendedPrice;
            }

            return r.extendedPrice
        }

        if(row.kind === "quote-price-rider" || row.kind === "price-rider") {
            return row.value.extendedPrice;
        }

        return 0;
    }));

    console.log("total", result)

    return result;
}

export const costEachColumnName = "Cost (ea)"

function Project(props: {}) {
    const startIndex = useColumnIndex(costEachColumnName)
    const tbl = useContext(FilterContext);
    const rows = (EventEmitter.reactValue(tbl.srcData) || blankArray) as (PriceLine[]|PriceLine2[]);

    let profitValue = sum(rows.map(profit));
    const price = sumPriceLines(rows)

    let margin = profitValue === 0 ? 0 : Math.round(profitValue / price * 100);

    const u = useUser();

    const project = useProjectId();
    const alternative = useAlternative();
    const isQuote = useIsQuote();

    const list = useGetAll((input) => isQuote ? api.quoteMiscPricing.list(input) : api.miscPricing.list(input),
        {project, alternative}, [project, alternative, isQuote]);

    const miscProposing = list.asList.length > 0 && list.asList.find(v => !!v.backup);
    const isProposing = rows.length > 0 && isPriceLine2(rows[0]) || miscProposing;
    const miscSum = sum(list.asList.map(l => {
        if(!isProposing) return l.extendedPrice;

        if(!l.backup)  {
            return 0;
        }

        return l.extendedPrice - l.backup.extendedPrice;
    }))

    const counter = new IndexCounter();

    return (
        <>
            <FreeCell index={counter.incrBy(2)} colSpan={2}>
                <div className={groupTitle}>Total</div>
            </FreeCell>
            {range(startIndex-2).map(key => <FreeCell key={key.toString()} index={counter.incrBy(1)}></FreeCell>)}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(sum(rows.map(cost)) + miscSum)}
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {Math.round((price / fixZero(sum(rows.map(cost))) - 1) * 100)}%
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}></FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                <div style={{textAlign: "right"}}>
                    {formatCents(price + miscSum)}
                </div>
            </FreeCell>
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(profitValue)}
                </div>}
            </FreeCell>}
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(sum(rows.map(r => (r.revenueDelivered || 0) - (r.costDelivered || 0))))}
                </div>}
            </FreeCell>}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {margin}%
                </div>}
            </FreeCell>
        </>
    )
}

function Project2(props: {}) {
    const startIndex = useColumnIndex(costEachColumnName)
    const tbl = useContext(FilterContext);
    const rows = (EventEmitter.reactValue(tbl.srcData) || blankArray) as (PriceRow[]);

    let profitValue = sum(rows.map(profit2));
    const price = sumPriceLines2(rows)

    let margin = profitValue === 0 ? 0 : Math.round(profitValue / price * 100);

    const u = useUser();

    const project = useProjectId();
    const alternative = useAlternative();
    const isQuote = useIsQuote();

    const list = useGetAll((input) => isQuote ? api.quoteMiscPricing.list(input) : api.miscPricing.list(input),
        {project, alternative}, [project, alternative, isQuote]);

    const miscProposing = list.asList.length > 0 && list.asList.find(v => !!v.backup);
    const isProposing = isProposing2(rows) || miscProposing;
    const miscSum = sum(list.asList.map(l => {
        if(!isProposing) return l.extendedPrice;

        if(!l.backup)  {
            return 0;
        }

        return l.extendedPrice - l.backup.extendedPrice;
    }))

    const counter = new IndexCounter();

    return (
        <>
            <FreeCell index={counter.incrBy(2)} colSpan={2}>
                <div className={groupTitle}>Total</div>
            </FreeCell>
            {range(startIndex-2).map(key => <FreeCell key={key.toString()} index={counter.incrBy(1)}></FreeCell>)}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(sum(rows.map(unitCost2)) + miscSum)}
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {Math.round((price / fixZero(sum(rows.map(unitCost2))) - 1) * 100)}%
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}></FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                <div style={{textAlign: "right"}}>
                    {formatCents(price + miscSum)}
                </div>
            </FreeCell>
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(profitValue)}
                </div>}
            </FreeCell>}
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(sum(rows.map(row => {
                        if(row.kind === "price" || row.kind === "quote-price") {
                            const r = row.value;
                            return (r.revenueDelivered || 0) - (r.costDelivered || 0)
                        }

                        return 0;
                    })))}
                </div>}
            </FreeCell>}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {margin}%
                </div>}
            </FreeCell>
        </>
    )
}

function fixZero(value: number) {
    if(value === 0) return 1;
    return value;
}

const clickableCellClass = css({
    textAlign: "right",
    cursor: "pointer",
    border: "2px solid " + grey["400"],
    margin: -6,
    padding: 6,
    paddingTop: 4,
    paddingBottom: 4,

    "&:hover": {
        borderColor: blue["600"]
    }
})

export const qtyColumnName = "Qty"

function AfterGroupInner(props: {
    name: SectionName;
    rows: PriceLine[]|PriceLine2[]|QuotePriceLine[];
}) {
    const startIndex = useColumnIndex(qtyColumnName)
    let profitValue = sum(props.rows.map(profit));

    const price = sumPriceLines(props.rows)

    let margin = profitValue === 0 ? 0 : Math.round(profitValue / price * 100);
    const [showSectionTotal, setShowSectionTotal] = useState(false)
    const project = useProjectId();
    const ctx = useContext(EditContext)
    const doorSection = useContext(DoorSectionContext)
    const u = useUser();
    const isProposing = props.rows.length > 0 && isPriceLine2(props.rows[0]);
    const colSpanFirst = props.name === "hardware" ? 3 : 1;
    const noClick = [
        "frame-anchor", "frame", "frame-prep", "door-prep", "door-hm", "door-wd", "door-other"
    ];
    const canClickSectionTotal = noClick.indexOf(props.name) === -1;
    let name: any = props.name;

    // @ts-ignore
    if(props.name === "door-totals") {
        name = "door-" + doorSection.tab;
    }

    const counter = new IndexCounter();

    return (
        <>
            {showSectionTotal && <SectionTotal
                changeIds={(props.rows as any[]).filter(r => {
                    if(isProposing) return isPriceLine2(r) && !!r.projectChangeset;
                    return true;
                }).map(r => r.id)}
                leaveIds={(props.rows as any[]).filter(r => {
                    if(isProposing) return isPriceLine2(r) && !!r.projectChangeset && r.kind === "current";
                    return true;
                }).map(r => isPriceLine2(r) && r.kind === "current" && r.removal ? r.removal.id : 0)}
                project={project}
                section={name}
                value={price}
                onDone={() => setShowSectionTotal(false)} />}
            <FreeCell index={counter.incrBy(colSpanFirst)} colSpan={colSpanFirst}>
                <div style={{textAlign: colSpanFirst > 1 ? "left" : "right"}} className={groupTitle}>{props.name === "frame-anchor" ? "Anchor" : getSectionName(props.name)} Totals</div>
            </FreeCell>
            {range(startIndex - colSpanFirst).map(id => <FreeCell key={id.toString()} index={counter.incrBy(1)}></FreeCell>)}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {sum(props.rows.map(qty))}
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(sum(props.rows.map(cost)))}
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                <div style={{textAlign: "right"}}>
                    {isProposing ?  <></> : <>{Math.round((price / fixZero(sum(props.rows.map(cost))) - 1) * 100)}%</>}
                </div>
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}></FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                <div className={canClickSectionTotal ? clickableCellClass : undefined} style={{textAlign: "right"}} onClick={() => {
                    if(!canClickSectionTotal) return;

                    ctx.onSelect.emit(null)
                    setShowSectionTotal(true)
                }}>
                    {formatCents(price)}
                </div>
            </FreeCell>
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {/* projected profit */formatCents(profitValue)}
                </div>}
            </FreeCell>}
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {/* actual profit */formatCents(sum(props.rows.map(r => (r.revenueDelivered || 0) - (r.costDelivered || 0))))}
                </div>}
            </FreeCell>}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {margin}%
                </div>}
            </FreeCell>
        </>
    )
}

function isProposing2(rows: PriceRow[]) {
    return rows.length > 0 && rows.find(v => v.kind === "price" && isPriceLine2(v.value))
}

function AfterGroupInner2(props: {
    name: SectionName;
    rows: PriceRow[];
}) {
    const startIndex = useColumnIndex(qtyColumnName)
    let profitValue = sum(props.rows.map(profit2));

    const price = sumPriceLines2(props.rows)

    let margin = profitValue === 0 ? 0 : Math.round(profitValue / price * 100);
    const [showSectionTotal, setShowSectionTotal] = useState(false)
    const project = useProjectId();
    const ctx = useContext(EditContext)
    const doorSection = useContext(DoorSectionContext)
    const u = useUser();
    const isProposing = isProposing2(props.rows);
    const colSpanFirst = props.name === "hardware" ? 3 : 1;
    const noClick = [
        "frame-anchor", "frame", "frame-prep", "door-prep", "door-hm", "door-wd", "door-other"
    ];
    const canClickSectionTotal = noClick.indexOf(props.name) === -1;
    let name: any = props.name;

    // @ts-ignore
    if(props.name === "door-totals") {
        name = "door-" + doorSection.tab;
    }

    const counter = new IndexCounter();

    return (
        <>
            {showSectionTotal && <SectionTotal
                changeIds={(props.rows as any[]).filter(r => {
                    if(isProposing) return isPriceLine2(r) && !!r.projectChangeset;
                    return true;
                }).map(r => r.id)}
                leaveIds={(props.rows as any[]).filter(r => {
                    if(isProposing) return isPriceLine2(r) && !!r.projectChangeset && r.kind === "current";
                    return true;
                }).map(r => isPriceLine2(r) && r.kind === "current" && r.removal ? r.removal.id : 0)}
                project={project}
                section={name}
                value={price}
                onDone={() => setShowSectionTotal(false)} />}
            <FreeCell index={counter.incrBy(colSpanFirst)} colSpan={colSpanFirst}>
                <div style={{textAlign: colSpanFirst > 1 ? "left" : "right"}} className={groupTitle}>{props.name === "frame-anchor" ? "Anchor" : getSectionName(props.name)} Totals</div>
            </FreeCell>
            {range(startIndex - colSpanFirst).map(id => <FreeCell key={id.toString()} index={counter.incrBy(1)}></FreeCell>)}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {sum(props.rows.map(qty2))}
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {formatCents(sum(props.rows.map(cost2)))}
                </div>}
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                <div style={{textAlign: "right"}}>
                    {isProposing ?  <></> : <>{Math.round((price / fixZero(sum(props.rows.map(cost2))) - 1) * 100)}%</>}
                </div>
            </FreeCell>
            <FreeCell index={counter.incrBy(1)}></FreeCell>
            <FreeCell index={counter.incrBy(1)}>
                <div className={canClickSectionTotal ? clickableCellClass : undefined} style={{textAlign: "right"}} onClick={() => {
                    if(!canClickSectionTotal) return;

                    ctx.onSelect.emit(null)
                    setShowSectionTotal(true)
                }}>
                    {formatCents(price)}
                </div>
            </FreeCell>
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {/* projected profit */formatCents(profitValue)}
                </div>}
            </FreeCell>}
            {hasPermission(u, "CanViewProjectPricingProfit") && <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {/* actual profit */formatCents(sum(props.rows.map(row => {
                        if(row.kind === "price") {
                            const r = row.value;
                            return (r.revenueDelivered || 0) - (r.costDelivered || 0)
                        }

                        return 0;
                    })))}
                </div>}
            </FreeCell>}
            <FreeCell index={counter.incrBy(1)}>
                {!isProposing && <div style={{textAlign: "right"}}>
                    {margin}%
                </div>}
            </FreeCell>
        </>
    )
}

const cost = (r: PriceLine|PriceLine2|QuotePriceLine) => {
    if(isPriceLine2(r)) {
        // disabled for priceline2
        return 0;
    }

    return r.unitCostCents * r.qty
}

const cost2 = (r: PriceRow) => {
    if(r.kind === "price" && isPriceLine2(r.value)) {
        // disabled for priceline2
        return 0;
    }

    if(r.kind === "price" || r.kind === "quote-price") {
        return r.value.unitCostCents * r.value.qty
    }

    return 0;
}

const qty = (r : PriceLine|PriceLine2|QuotePriceLine) => r.qty;

const unitCost2 = (row: PriceRow) => {
    if(row.kind === "price" && isPriceLine2(row.value)) {
        // disabled for priceline2
        return 0;
    }

    if(row.kind === "price" || row.kind === "quote-price") {
        const r = row.value;
        return r.unitCostCents
    }

    if(row.kind === "quote-price-rider" || row.kind === "price-rider")  {
        return row.value.unitCostCents;
    }

    return 0;
}

const qty2 = (r : PriceRow) => {
    if(r.kind === "price" || r.kind === "quote-price") {
        return r.value.qty;
    }

    return 0;
};
import {EventEmitter} from "nate-react-api-helpers";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {DeliveryItem} from "../../../../api/Deliveries";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {blue, grey} from "@mui/material/colors";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

let shiftKeyPressed = false;
let mouseDown = false;
document.addEventListener("keydown", (e) => {
    if(e.key === "Shift") {
        shiftKeyPressed = true;
    }
})

document.addEventListener("keyup", (e) => {
    if(e.key === "Shift") {
        shiftKeyPressed = true;
    }
})

document.addEventListener("mousedown", (e) => {
    mouseDown = true;
})

document.addEventListener("mouseup", (e) => {
    mouseDown = false;
});

export function BoxingColumn(props: {
    id: number;
    selectionManager: EventEmitter<number[]>;
}) {

    const {id, selectionManager} = props;
    const [selected, setSelected] = useState<boolean>(false);
    const selectedRef = useSyncedRef(selected);
    const sel = useContext(EditContext);

    useEffect(() => {
        selectionManager.subscribeAndFireLast(list => {
            const selected = list.includes(id)
            if(selectedRef.current === selected) return;
            setSelected(selected);
        })
    }, [selectionManager, id, selectedRef]);

    const updateSel = (checked?: boolean) => {
        const last = (selectionManager.lastValue||[]);

        if(checked === undefined) {
            checked = !last.includes(id);
        }

        if (checked) {
            selectionManager.emit([...last, id])
        } else {
            selectionManager.emit(last.filter(v => v !== id))
        }
    }

    const selectRange = () => {
        const lastSelection = (selectionManager.lastValue||[]).slice(0);
        if(lastSelection.length === 0) return false

        const prevId = lastSelection[lastSelection.length - 1];
        const rows = sel.dataRef.current[0].rows as DeliveryItem[];
        const myIndex = rows.findIndex(r => r.id === id);
        const prevIndex = rows.findIndex(r => r.id === prevId);

        const minIndex = Math.min(myIndex, prevIndex)
        const maxIndex = Math.max(myIndex, prevIndex)

        for(let i = minIndex; i <= maxIndex; i++) {
            const id = rows[i].id;
            if(lastSelection.includes(id)) continue;
            lastSelection.push(id);
        }

        selectionManager.emit(lastSelection)
        return;
    }

    const mouseDownOnThisRef = useRef(false)

    return (
        <div
            style={{
                width: "100%", display: "flex", justifyContent: "center",
                fontSize: "0.9rem", cursor: "pointer",
                color: selected ? blue["600"] : grey["300"],
                borderRadius: 5,
                position: "relative",
            }}
        >
            {/* This div is used to capture mouse events */}
            <div
                style={{
                    position: "absolute",
                    top: 0, left: 0, right: 0, bottom: 0,
                }}
                 onMouseOver={() => {
                     if(mouseDown) {
                         updateSel(!selected);
                     }
                 }}
                 onMouseDown={() => {
                     mouseDownOnThisRef.current = true
                 }}
                 onMouseOut={() => {
                     if(mouseDown && mouseDownOnThisRef.current) {
                         updateSel(!selected);
                     }

                     mouseDownOnThisRef.current = false;
                 }}
                 onClick={() => {
                     if(shiftKeyPressed) {
                         if(selectRange()) return
                     }

                     updateSel(!selected)
                 }}
            ></div>
            {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </div>
    )
}
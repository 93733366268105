import {EditLink} from "../summary/EditLink";
import {ProjectDefaultContactEdit} from "../summary/ProjectDefaultContactEdit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, {useContext, useState} from "react";
import {badge} from "./ProjectLinks";
import {Contact} from "../../../../api/Contacts";
import {EditContact} from "../summary/EditContact";
import {MenuPopout} from "./MenuPopout";
import {useSnackAction} from "../../shopdrawing/hardware/SnackAction";
import {api} from "../../../../api/API";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {ConfirmContext} from "../../../../misc/Confirm";
import {combineStrs, decorate} from "../../../../misc/pdf/parts/CoverPage";

export function ProjectContact(props: {
    project: number;
    value?: Contact;
    isClientContact?: boolean;
    onReload(): void;
}) {
    const [edit, setEdit] = useState(false);
    const onReloadRef = useSyncedRef(props.onReload);
    const remove = useSnackAction(async input => {
        await api.contacts.setProjectLink(input)
        onReloadRef.current();
    }, {
        success: "Removed",
    })

    const confirm = useContext(ConfirmContext);

    return (
        <div style={{display: "flex",
            flexWrap: "nowrap", fontSize: "0.8rem",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 16,
        }}>
            <div>
                {props.value ? <>
                    <div style={{fontSize: "0.9rem", fontWeight: "500"}}>{combineStrs(props.value.name, decorate(props.value.title, "(", ")"))}</div>
                    <div>{props.value.phone ? <a style={{textDecoration: "none", color: "initial"}} href={"tel:" + props.value.phone}>{props.value.phone}</a> : <i>Missing phone</i>}</div>
                    <div>{props.value.email ? <a style={{textDecoration: "none", color: "initial"}} href={"mailto:" + props.value.email}>{props.value.email}</a> : <i>Missing email</i>}</div>
                    {props.value.siteContact && <>
                        <div style={{height: 4}}/>
                        <div className={badge}>Site Contact</div>
                    </>}
                    {props.isClientContact && <>
                        <div style={{height: 4}} />
                        <div className={badge}>Client Contact</div>
                    </>}
                </> : null}
            </div>
            <div style={{fontSize: "1rem"}}>
                {props.isClientContact ? <EditLink editor={ProjectDefaultContactEdit}>
                    <MoreHorizIcon fontSize="inherit" />
                </EditLink> : <MenuPopout options={[
                    {display: "Edit", onClick: () => setEdit(true) },
                    {display: "Remove", danger: true, onClick: async () => {
                        const ok = await confirm.confirm("Are you sure you want to remove this contact?")
                        if(!ok) return;

                        remove.callback({
                                project: props.project,
                                contact: props.value?.id || 0,
                                link: false,
                            })

                        }},
                ]}>
                    <MoreHorizIcon fontSize="inherit" />
                </MenuPopout>}
            </div>
            {edit && props.value && <EditContact value={props.value} onDone={() => {
                setEdit(false);
                props.onReload()
            }} />}
        </div>
    )
}

import {Container} from "../../misc/Container";
import {api} from "../../api/API";
import {User} from "../../api/Users";
import { SetPasswordModal } from "./SetPasswordModal";
import {StringCol, Table} from "../../misc/scroller/Table";
import {getAll} from "../project/shopdrawing/openings/Openings";
import {OnClickParams, RowOptionCol} from "../logistics/purchasing/RowOptions";
import {Archive} from "../logistics/purchasing/Archive";
import {CreateUser} from "./CreateUser";
import {WhiteColumn} from "../logistics/purchasing/WhiteColumn";
import {PermissionsModal} from "./PermissionsModal";

const tableName = "users";

export function UsersPage() {

    return <Container>
        <WhiteColumn>
            <Table<User>
                name={tableName}
                columns={[
                    RowOptionCol<User>({
                        options: (dt) => [
                            SetPassword(dt),
                            ChangePermissions(dt),
                            ArchiveOption(dt, {
                                refreshTables: [tableName],
                                confirm: true,
                            }),
                        ],
                    }),
                    StringCol("Name", "name", 200),
                    StringCol("Title", "title", 200),
                    StringCol("Email", "email", 200),
                    StringCol("Phone", "phone", 200),
                ]}
                fetch={ctx => getAll(ctx, offset => api.users.list({offset: offset}))}
                fetchDeps={[]}
                onChange={u => api.users.upsert(u)}
                insert={{
                    buttonText: "Add User",
                    modal: (onDone) => <CreateUser onDone={onDone} />,
                }}
            />
        </WhiteColumn>
    </Container>
}

function ChangePermissions(row: User) {
    return {
        name: "Permissions",
        danger: false,
        onClick: async (input: OnClickParams<User>) => {
            input.onClose();
            input.display((onDone) => <PermissionsModal row={row} onDone={() => {
                input.refreshTable(tableName);
                onDone();
            }} />)
        }
    }
}

function SetPassword(row: User) {
    return {
        name: "Set Password",
        danger: false,
        onClick: async (input: OnClickParams<User>) => {
            input.onClose();
            input.display((onDone) => <SetPasswordModal row={row} onDone={onDone} />)
        }
    }
}

function ArchiveOption(dt: User, opts: {
    refreshTables: string[],
    confirm?: boolean;
}) {
    return {
        name: dt.archived ? "Un-Archive" : "Archive",
        danger: true,
        onClick: async (input: OnClickParams<User>) => {
            input.onClose();

            const doArchive = async () => {
                await input.update(Object.assign({}, dt, {
                    archived: !dt.archived,
                }))

                opts.refreshTables.map(name => input.refreshTable(name))
            }

            if(opts.confirm) {
                input.display((onDone) => <Archive onDone={onDone} onArchive={doArchive} />)
                return;
            }

            await doArchive();
        }
    }
}
import {hasPermission, useUser} from "../../../../misc/Permission";
import {
    Table,
} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {
    AddRider,
    commonGroupBy2,
    commonPricingColumns2, getPricing, priceChange,
    PriceCol,
    PriceLine2,
    PriceRow,
    proposingGroupBy2,
    RiderRowTitle, ViewPriceBoolCol, ViewPriceCol
} from "./Frames";
import {TDiv10Type} from "../div10/Div10Tabs";
import {User} from "../../../../api/Users";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";

export function Div10(props: {
    type: TDiv10Type;
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const proposal = useProposingKit();
    const changesetPreview = useContext(ChangePreviewContext);

    if(!u) return null;

    const colInfo = getColumns2(props.type, false, u, proposal.renderItemName as any, proposal.proposing);

    return (
        <Table<PriceRow>
            key={props.type}
            cellCustomize={proposal.cellCustomize}
            name={priceTableName(project, "div10", props.type)}
            globalPrefsName={priceTableName(0, "div10", props.type)}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={async (ctx) => getPricing(ctx, {
                project,
                transformRows: proposal.transformRows,
                previewChangeset: changesetPreview.enabled ? changesetPreview.sessionId : undefined,
            })}
            columns={[
                PriceCol<PriceLine2>({
                    name: "",
                    price: o => proposal.rowAction(o, "div10", ""),
                    rider: (dt: QuotePriceRider, row: PriceRow) => {
                        return <RiderRowTitle row={row} />
                    },
                    createRider: (row) => {
                        return <AddRider row={row} />
                    },
                    width: 150,
                }),
                ...colInfo.columns
            ]}
            columnDeps={[props.type, u, proposal.proposing]}
            fetchDeps={[project, proposal.proposing, proposal.expanded, changesetPreview.enabled, changesetPreview.sessionId]}
            groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy2 : commonGroupBy2, {
                groupFilter: (groupName: string) => groupName === "div-10/" + props.type || groupName.startsWith("div-10/" + props.type + "/") || groupName === "project",
            })}
            onChange={(input) => priceChange(input, {
                processUpdate: proposal.processUpdate,
                project: project,
            })}
        />
    )
}

export function getColumns2(type: TDiv10Type, isQuote: boolean, u: User, adjustName: (row: any, key: string) => any, proposing: boolean): {shift: number, columns: any} {

    switch (type) {
        case "wr-accessory":
            return {
                shift: isQuote ? 6 : 7,
                columns: [
                    PriceCol({
                        name: "Product",
                        price: (dt: QuotePriceLine|PriceLine2) => {
                            return adjustName(dt, "categoryName")
                        },
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),

                    ViewPriceCol("Code", "productCode", 150),
                    ViewPriceCol("Description", "productName", 150),
                    ViewPriceCol("Manufacturer", "manufacturerName", 150),
                    ViewPriceCol("Finish", "finish", 150),
                    ...commonPricingColumns2(u, isQuote, proposing)
                ]
            }
        case "wr-partition":
            return {
                shift: isQuote ? 5 : 6,
                columns: [
                    PriceCol({
                        name: "Mounting",
                        price: (dt: QuotePriceLine|PriceLine2) => {
                            return adjustName(dt, "div10.partitionBracing")
                        },
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),

                    ViewPriceCol("Material", "div10.partitionCoating", 150),
                    ViewPriceCol("Description", "div10.description", 150),
                    ViewPriceCol("Manufacturer", "manufacturerName", 150),
                    ...commonPricingColumns2(u, isQuote, proposing)
                ]
            }
        case "locker":
            return {
                shift: isQuote ? 10 : 11,
                columns: [
                    PriceCol({
                        name: "Tiers",
                        price: (dt: QuotePriceLine|PriceLine2) => {
                            return adjustName(dt, "div10.lockerTiers")
                        },
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),

                    ViewPriceCol("Material", "div10.lockerCoating", 150),
                    ViewPriceBoolCol("Sloped Tops", "div10.lockerSloped", 60),
                    ViewPriceBoolCol("End Panels", "div10.lockerEndPanels", 60),
                    ViewPriceBoolCol("Base Trim", "div10.lockerBaseTrim", 60),
                    ViewPriceCol("Width", "dimWidth", 80),
                    ViewPriceCol("Depth", "dimLength", 80),
                    ViewPriceCol("Height", "dimHeight", 80),
                    ViewPriceCol("Manufacturer", "manufacturerName", 150),
                    ...commonPricingColumns2(u, isQuote, proposing)
                ]
            }
        case "mailbox":
            return {
                shift: isQuote ? 6 : 7,
                columns: [
                    PriceCol({
                        name: "Tenant Units",
                        price: (dt: QuotePriceLine|PriceLine2) => {
                            return adjustName(dt, "div10.mailboxTenantUnits")
                        },
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),

                    ViewPriceCol("Parcel Units", "div10.mailboxParcelUnits", 80),
                    ViewPriceCol("Loading", "div10.mailboxLoading", 100),
                    ViewPriceCol("Mounting", "div10.mailboxMounting", 100),
                    ViewPriceCol("Manufacturer", "manufacturerName", 150),
                    ...commonPricingColumns2(u, isQuote, proposing)
                ]
            }
        case "corner-guard":
            return {
                shift: isQuote ? 7 : 8,
                columns: [
                    PriceCol({
                        name: "Type",
                        price: (dt: QuotePriceLine|PriceLine2) => {
                            return adjustName(dt, "div10.cornerGuardType")
                        },
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),

                    ViewPriceCol("Code", "productCode", 150),
                    ViewPriceCol("Finish", "div10.cornerGuardFinish", 60),
                    ViewPriceCol("Width", "dimWidth", 60),
                    ViewPriceCol("Height", "dimHeight", 60),
                    ViewPriceCol("Manufacturer", "manufacturerName", 150),
                    ...commonPricingColumns2(u, isQuote, proposing)
                ]
            }
        default:
            return {
                shift: 0,
                columns: commonPricingColumns2(u, isQuote, proposing),
            };
    }
}

import React, {createContext, PropsWithChildren, useContext} from "react";
import {Container} from "../../../../misc/Container";
import {WhiteColumn} from "../../../logistics/purchasing/WhiteColumn";
import {SectionHeader} from "../../../../misc/SectionHeader";
import {Step, StepLabel, Stepper} from "@mui/material";
import {ChangesetContext, ChangesetProvider} from "./ChangesetContext";

type Stage = "review-products" | "review-pricing" | "print-submissions" | "receive-acceptance" | "save-session" | "summarize-changes" | "start"

const stages: {key: Stage, name: string; type: "both" | "change-released" | "changeset"}[] = [
    {key: "start", name: "Start", type: "change-released"},
    {key: "review-products", name: "Review Products", type: "change-released"},
    {key: "review-pricing", name: "Review Pricing", type: "both"},
    {key: "summarize-changes", name: "Summarize Changes", type: "both"},
    {key: "save-session", name: "Save Session", type: "both"},
    {key: "print-submissions", name: "Print & Close Session", type: "both"},
]

export const ChangesetReviewContext = createContext(false);

export function ChangesetContainer(props: PropsWithChildren<{
    stage: Stage
    backgroundColor?: string;
}>) {
    let activeStep = stages.findIndex(stage => stage.key === props.stage);
    if(props.stage === "receive-acceptance") {
        activeStep = stages.length;
    }

    return (
        <ChangesetProvider>
            <Container noSidebar>
                <WhiteColumn backgroundColor={props.backgroundColor}>
                    <ChangesetHeader activeStep={activeStep} />

                    <ChangesetReviewContext.Provider value={true}>
                        {props.children}
                    </ChangesetReviewContext.Provider>
                </WhiteColumn>
            </Container>
        </ChangesetProvider>
    )
}

function ChangesetHeader(props: {
    activeStep: number;
}) {
    const ctx = useContext(ChangesetContext);

    return (
        <SectionHeader
            name={ctx.changeReleasedGroup ? "Complete Released Change": "Complete Change Proposal"}
            right={
                <Stepper activeStep={props.activeStep}>
                    {stages.filter(s => {
                        if(ctx.changeReleasedGroup) {
                            return s.type === "change-released" || s.type === "both";
                        }

                        return s.type === "changeset" || s.type === "both"
                    }).map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={label.key} {...stepProps}>
                                <StepLabel {...labelProps}>{label.name}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            }
        />
    )
}
import {Paginated} from "../../../api/Users";
import {useAsync2} from "nate-react-api-helpers";
import {DashCard} from "./DashCard";
import {Loader} from "../../../misc/Loader";
import {Grid} from "@mui/material";
import {BlankList} from "../../../misc/BlankList";
import {Link} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {css} from "@emotion/css";


export const logisticsCardHeight = 200;
const cardItem = css({
    paddingLeft: 8,
    paddingRight: 24,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: "0.9rem",
})

export function DashCardData<T>(props: {
    lookup: () => Promise<Paginated<T>>;
    title: string;
    itemTitle: (item: T) => any;
    itemSubtext: (item: T) => any;
    itemRight: (item: T) => any;
    link: string;
}) {
    const list = useAsync2(props.lookup, {}, []);

    return (
        <DashCard title={props.title} xs={4} height={logisticsCardHeight}>
            <div style={{flex: 1, overflow: "auto"}}>
                <Loader {...list}>
                    {data => <div>
                        {data.data.map(d => <div className={cardItem}>
                            <Grid container style={{width: "100%"}} alignItems="center">
                                <Grid item xs={8}>
                                    <div>{props.itemTitle(d)}</div>
                                    <div style={{fontSize: "0.8rem", lineHeight: "1em"}}>
                                        {props.itemSubtext(d)}
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right", fontSize: "0.8rem"}}>
                                    {props.itemRight(d)}
                                </Grid>
                            </Grid>
                        </div>)}
                        {data.data.length === 0 && <BlankList />}
                    </div>}
                </Loader>
            </div>

            <div style={{paddingLeft: 8}}>
                <Link to={props.link} style={{color: grey["700"], fontSize: "0.8rem"}}>
                    View full list
                </Link>
            </div>
        </DashCard>
    );
}
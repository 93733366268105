import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Divider, Grid, IconButton, MenuItem} from "@mui/material";
import {useState} from "react";
import { Popover } from "./Popover";
import {useUser} from "./Permission";

export function CurrentUser() {
    const usr = useUser();
    const [anchor, setAnchor] = useState<HTMLElement|null>(null);
    const [open, setOpen] = useState(false);

    if(!usr) return null;

    return (
        <>
            <IconButton size="small" style={{color: "white"}} ref={r => setAnchor(r)}
                        onClick={() => setOpen(true)}>
                <AccountCircleIcon />
            </IconButton>
            <Popover
                open={open} anchorEl={anchor} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      onClose={() => setOpen(false)}>
                <div style={{padding: 10, width: 250}}>
                    <Grid container spacing={2} wrap="nowrap">
                        <Grid item>
                            <div style={{fontSize: "1.5rem"}}>
                                <AccountCircleIcon color="inherit" fontSize="inherit" />
                            </div>
                        </Grid>
                        <Grid item>
                            <div>{usr.name}</div>
                            <div style={{fontSize: "0.75rem"}}>{usr.email}</div>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <a style={{textDecoration: "none", color: "initial"}} href="/api/auth/logout"><MenuItem>Logout</MenuItem></a>
            </Popover>
        </>
    )
}
import {useProjectId} from "../../shopdrawing/ProjectName";
import {QuoteSwitcher} from "../pricing/QuoteSwitcher";
import React from "react";

export function MiscSwitcher() {
    const projectId = useProjectId();

    return (
        <QuoteSwitcher
            title="Extras"
            url={`/project/${projectId}/shop-drawing/extras`}
            quoteTitle="Quote Extras"
            quoteURL={`/project/${projectId}/quote/-1/extras`} />
    )
}


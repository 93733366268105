import {ContainerPage} from "./ContainerPage";
import {TitleTextBlock} from "./TitleTextBlock";

export function TodoPage(props: {
    title: string;
}) {
    return (
        <ContainerPage>
            <TitleTextBlock title={props.title}>
            </TitleTextBlock>
        </ContainerPage>
    )
}
import {useRef} from "react";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useAlternative} from "../alternative/Alternative";
import {AutoInsert} from "../../shopdrawing/openings/AutoInsertOpening";

export function MiscPricingAutoInsert(props: {
    onDone(): void;
}) {
    const projectId = useRef(useProjectId());
    const alt = useAlternative();

    return <AutoInsert getInput={() => {
        return {
            quoteAlternative: alt ? alt : undefined,
            project: projectId.current,
        }
    }} onDone={props.onDone} />
}
import {PropsWithChildren} from "react";
import {red} from "@mui/material/colors";
import {Place} from "@mui/icons-material";
import {cardStyle} from "./RouteStop";

export function StopHeader(props: PropsWithChildren<{ }>) {
    return (
        <div className={cardStyle}>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{paddingRight: 8, fontSize: "1.5rem", color: red["600"], top: 3, position: "relative"}}>
                    <Place fontSize="inherit" />
                </div>
                <div style={{fontSize: "1.75rem", fontWeight: 500, flex: 1}}>{props.children}</div>
            </div>
        </div>
    )
}
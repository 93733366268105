import {Project} from "../../../../api/Projects";
import {Alert, TextField} from "@mui/material";
import {useState} from "react";

export type AliasUpdate = {
    updateNeeded: boolean;
    value: string;
} | null

export function AliasNeeded(props: {
    project: Project;
    onChange(value: AliasUpdate): void;
}) {

    const [alias, setAlias] = useState<string>(props.project.nameAlias || '');

    if(props.project.nameAlias) return null;

    return <Alert severity="warning">

        <div style={{paddingBottom: 10}}>
            This project does not have a name alias.  This is the real project name will be displayed to vendors if you don't set this.
        </div>

        <TextField label="Choose Alias" variant="outlined" value={alias}
                   onChange={e => {
                       const value = e.currentTarget.value;
                       setAlias(value)

                       if(!value) {
                           props.onChange(null);
                       } else {
                           props.onChange({
                               updateNeeded: true,
                               value: value,
                           })
                       }
                   }} />
    </Alert>
}
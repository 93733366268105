import {PriceLine} from "../../../../api/Pricing";
import {ListItemText, MenuItem} from "@mui/material";
import {RowActionsBase} from "../../quote/openings/RowActions";
import {buildFilterLink} from "../../../../misc/scroller/Filter";
import {priceTableName} from "./Pricing";
import {Link} from "react-router-dom";

export function RowActions(props: {
    row: PriceLine
    tab: string;
    tab2: string;
}) {
    const disabled = !props.row.linkedQuotePrice || props.row.linkedQuotePrice.length === 0

    return <RowActionsBase>
        {onClose => <>
            <Link to={disabled ? "" : `/project/${props.row.project}/quote/pricing?tab=${props.tab}&tab2=${props.tab2}&${buildFilterLink({
                tableName: priceTableName(props.row.project, props.tab, props.tab2),
                filter: {
                    type: "contains",
                    search: {
                        id: props.row.linkedQuotePrice,
                    }
                }
            })}`} style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem onClick={onClose} disabled={disabled}>
                    <ListItemText
                        primary="Show Quoted Numbers"
                        secondary={disabled ? "Product/Opening added after quote" : undefined}
                    />
                </MenuItem>
            </Link>
        </>}
    </RowActionsBase>
}

import { SubAPI } from "./SubAPI";
import {DateString} from "./Projects";
import {Paginated} from "./Users";

export class MiscProducts extends SubAPI {
    list(input: {
        project: number
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<MiscProduct>>("/api/misc-products", input)
    }

    upsert(input: MiscProduct) {
        return this.fetcher.post("/api/misc-product", input)
    }
}


export type MiscProduct = {
    id: number;
    product: number;
    productName: string;

    dimensions: string;
    dimLengthFormula?: string;
    dimWidthFormula?: string;
    dimHeightFormula?: string;

    dimLength: string;
    dimWidth: string;
    dimHeight: string;

    qty: number;
    neededBy?: DateString;
    unitCostCents: number;
    extendedPrice: number;
    markupPercent?: number;
    markupCents?: number;

    revenueDelivered: number;
    costDelivered: number;

    project: number;

    updatedAt: DateString;
    updatedBy: number;

    historyCount: number;
}
import {useProjectId} from "../ProjectName";
import React, {useContext, useRef} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {sumPriceLines} from "./AfterGroup";
import {
    CentsCol,
    Column,
    FreeCell,
    LongStringCol,
    lookupNestedProp,
    StringCol,
    Table,
} from "../../../../misc/scroller/Table";
import {
    byFixedOrder,
    isNullOrUndefined,
    MarkupEditCell,
    parseMarkup,
    priceTableName, profit
} from "./Pricing";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {MiscPrice} from "../../../../api/MiscPricing";
import {formatCents, sum} from "nate-react-api-helpers";
import {PriceLine} from "../../../../api/Pricing";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {removeRowOption, RowOptionCol} from "../../../logistics/purchasing/RowOptions";
import {PreviousValuePopup} from "../openings/PreviousValuePopup";
import {cellInActive} from "./cellconst";
import {MiscPricingAutoInsert} from "../../quote/pricing/MiscPricingAutoInsert";

export function MiscPricing() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();

    const otherPricesRef = useRef<(PriceLine[])>([])
    const miscPricesRef = useRef<MiscPrice[]>([])

    const proposal = useProposingKit()
    const previewChangeset = useContext(ChangePreviewContext);
    const tableName = priceTableName(project, "misc")

    if(!u) return null;

    return (
        <Table<MiscPrice>
            key="misc-price"
            name={tableName}
            globalPrefsName={priceTableName(0, "misc")}
            locked={(locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")) || previewChangeset.enabled}
            fetch={async (ctx) => {

                const rs = await getAll(ctx, offset => api.miscPricing.list({project: project, offset}))
                miscPricesRef.current = rs;

                const others = await getAll(ctx, offset => api.pricing.list({project, offset}))
                otherPricesRef.current = proposal.transformRows(others as any)

                return rs;
            }}
            cellCustomize={{
                backgroundColor: (row: MiscPrice, c: Column<MiscPrice>) => {
                    if(!proposal.proposing) return null;

                    let changed = false;

                    if("isChangedSinceBackup" in c && c.isChangedSinceBackup) {
                        changed = c.isChangedSinceBackup(row)
                    } else if(c.editKey) {
                        changed = !!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)
                    } else {
                        changed = false;
                    }

                    if(changed) {
                        return "white"
                    }

                    return cellInActive;
                },
                onFocus: (row: MiscPrice, c: Column<MiscPrice>, anchor: any) => {
                    if(!proposal.proposing) return null;
                    if(!c.editKey) return null

                    if(!!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)) {
                        return (
                            <PreviousValuePopup anchor={anchor} width={c.width} value={c.render(row.backup, c)} />
                        )
                    }

                    return null;
                }
            }}
            columns={[
                !locked && RowOptionCol({
                    options: (dt) => [
                        removeRowOption(dt, {
                            refreshTables: [tableName],
                            onComplete: () => {}
                        })
                    ],
                }),
                StringCol("Name", "name"),
                CentsCol("Unit Cost", "unitCostCents"),
                {
                    name: "Markup",
                    render: dt => {
                        if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                        if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                        return "auto"
                    },
                    editable: {
                        type: "custom",
                        copy: dt => {
                            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                            return "auto"
                        },
                        paste: (rw, value) => {
                            Object.assign(rw, parseMarkup(value))
                        },
                        render: MarkupEditCell,
                    },
                    alignRight: true,
                    width: 80,
                },
                CentsCol("Total", "extendedPrice"),
                hasPermission(u, "CanViewProjectPricingProfit") && {
                    name: "Proj. Profit",
                    render: dt => {
                        return formatCents(profit(dt))
                    },
                    alignRight: true,
                    width: 90,
                },
                {
                    name: "Proj. Margin",
                    render: dt => {
                        let profit = dt.extendedPrice - dt.unitCostCents * 1;
                        if(profit === 0) return "0%"
                        let margin = Math.round(profit / dt.extendedPrice * 100);
                        return margin + "%"
                    },
                    alignRight: true,
                    width: 100,
                },
                LongStringCol("Public Note", "publicNote", 400),
                StringCol("Internal Note", "internalNote", 400),
            ]}
            groupBy={{
                groupFx: (input: MiscPrice) => content, // everything is 1 big group
                fixedGroups: [content, total],
                groupSorter: byFixedOrder([content, total]),
                afterGroup: (input) => {
                    const subTotal = sum(miscPricesRef.current.map(a => a.extendedPrice - (a.backup?.extendedPrice || 0)));
                    const otherPrices = sumPriceLines(otherPricesRef.current);

                    if(input.name === total) {
                        return (
                            <>
                                <FreeCell index={0} colSpan={4}>
                                    <b>
                                        Total
                                    </b>
                                </FreeCell>
                                <FreeCell index={4}>
                                    <div style={{textAlign: "right"}}>
                                        {formatCents(otherPrices + subTotal)}
                                    </div>
                                </FreeCell>
                            </>
                        )
                    } else if(input.name === content) {
                        return (
                            <>
                                <FreeCell index={0} colSpan={4}>
                                    <b>
                                        Sub Total
                                    </b>
                                </FreeCell>
                                <FreeCell index={4}>
                                    <div style={{textAlign: "right"}}>
                                        {formatCents(subTotal)}
                                    </div>
                                </FreeCell>
                            </>
                        )
                    }
                }
            }}
            fetchDeps={[project]}
            onChange={(input) => api.miscPricing.upsert(input)}
            insert={previewChangeset.enabled ? undefined : {
                buttonText: "New Row",
                alignX: "left",
                modal: input => <MiscPricingAutoInsert onDone={input} />,
            }}
        />
    )
}

const content = "content-group"
const total = "total-group"
import {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {grey, orange} from "@mui/material/colors";
import {css} from "@emotion/css";
import {Button, Grid} from "@mui/material";
import {PrintManagerContext} from "./PrintManager";

const classname = css({
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: "2px solid " + orange["500"],
    zIndex: 1000,
    backgroundColor: grey["200"],
    display: "flex",
    flexDirection: "column",

    "& .pdf-page-element": {
        border: "1px solid grey",
        background: "white",
    }
})

export function DebugPDF(props: {
    render: (input: () => void) => Promise<ReactElement>;
    onClose(): void;
}) {
    const {print} = useContext(PrintManagerContext)
    const instance = useRef<number>(0);
    const [rendered, setRendered] = useState<ReactElement|null>(null);

    const render = props.render;
    useEffect(() => {
        const id = Date.now();
        instance.current = id;

        (async () => {
            const value = await render(() => {});
            if(id === instance.current) {
                setRendered(value);
            }
        })();
    }, [render]);

    return (
        <div className={classname}>
            <Grid container spacing={1} justifyContent="space-between" style={{borderBottom: "1px solid grey"}}>
                <Grid item>DEBUG PDF</Grid>
                <Grid item>
                    <Button onClick={() => {
                        print("test.pdf", props.render)
                    }}>Print</Button>
                    <Button onClick={props.onClose}>Close</Button>
                </Grid>
            </Grid>
            <div style={{flex: 1, overflow: "auto"}}>
                {rendered}
            </div>
        </div>
    )
}
import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useState} from "react";
import {AutoComplete} from "../../shopdrawing/openings/AutoComplete";
import {User} from "../../../../api/Users";

export function UsrEdit(props: {
    value: Project;
    onDone(): void;
}) {

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const [contact, setContact] = useState<User|null>(props.value.internalRep ? {
        id: props.value.internalRep,
        name: props.value.internalRepName,
    } as User : null);

    const isEstimator = props.value.stage === "quote"

    return (
        <Dialog open>
            <DialogTitle>Choose {isEstimator ? "Estimator" : "Project Manager"}</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />

                <AutoComplete<User>
                    label="Find Person"
                    search={async input => {
                        const result = await api.users.list({
                            search: input,
                            offset: 0,
                            permission: isEstimator ? "Estimator" : "ProjectManager",
                        })

                        return result.data;
                    }}
                      value={contact}
                      equals={(a, b) => a.id === b.id}
                      makeLabel={a => a.name}
                      onSelect={setContact}
                />

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    if(isEstimator) {
                        update.callback(Object.assign({}, props.value, {
                            internalRep: contact?.id || null,
                        }));
                    } else {
                        update.callback(Object.assign({}, props.value, {
                            projectManager: contact?.id || null,
                        }));
                    }
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
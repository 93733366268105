import {PropsWithChildren} from "react";
import { Container } from "../../../misc/Container";
import { ShopNav } from "./ShopNav";
import {ProjectName} from "./ProjectName";
import {ProjectProvider} from "../ProjectProvider";
import {SectionHeader} from "../../../misc/SectionHeader";
import {ProjectActions, SubmittalCreator} from "./ProjectActions";
import { Grid } from "@mui/material";
import {ChangesetProvider} from "./changeset/ChangesetContext";
import {ReloadContextProvider} from "./hardware/ReloadContext";

export function ShopContainer(props: PropsWithChildren<{
    actions?: JSX.Element
    name: any;
    submittalCreator?: SubmittalCreator
}>) {
    return (
        <ProjectProvider>
            <ReloadContextProvider>
                <ChangesetProvider>
                    <Container topCenter={<ShopNav />}>
                        <div style={{
                            backgroundColor: "white",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}>
                            <SectionHeader name={props.name}
                                inFromRight={props.actions}
                                right={
                                    <Grid container alignItems="center" spacing={1}>
                                        <ProjectActions submittalCreator={props.submittalCreator} />
                                        <ProjectName />
                                    </Grid>}
                            />

                            {props.children}
                        </div>
                    </Container>
                </ChangesetProvider>
            </ReloadContextProvider>
        </ProjectProvider>
    )
}
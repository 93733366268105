import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import React, {useState} from "react";

export function AliasEdit(props: {
    value: Project;
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const [alias, setAlias] = useState<string>(props.value.nameAlias || '');

    return (
        <Dialog open>
            <DialogTitle>Project Name Alias</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This is the name that will be displayed to vendors when requesting quotes.
                </DialogContentText>
                <div style={{height: 8}} />

                <TextField label="Alias"
                           value={alias}
                           onChange={e => setAlias(e.target.value)} />

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        nameAlias: alias || null,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
import {useContext, useEffect} from "react";
import {CostHelperContext} from "./CostHelperContext";
import {PriceLine} from "../../../../api/Pricing";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {useSyncedRef} from "../../../../misc/SyncedRef";

export function CostFocuser(props: {
    anchorEl: any;
    row: PriceLine;
}) {
    const {show} = useContext(CostHelperContext);
    const edit = useContext(EditContext)
    const editRef = useSyncedRef(edit);

    useEffect(() => {
        return show(props.row, (value) => {
            editRef.current.update(Object.assign({}, props.row, {
                unitCostCents: value.unitPriceCents,
            }));

            editRef.current.onSelect.emit(null);
        });
    }, [show, props.row, editRef])

    return null;
}
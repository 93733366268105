import {Product} from "../../../api/Products";
import {useGetAll} from "../../logistics/routes/useGetAll";
import {api} from "../../../api/API";
import {Company, CompanyKind} from "../../../api/Companies";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useRef, useState} from "react";
import {first, useAsyncAction} from "nate-react-api-helpers";
import {AutoComplete} from "../../project/shopdrawing/openings/AutoComplete";
import {red} from "@mui/material/colors";
import {DistributorDetail} from "../../../api/Hardware";

export function DistributorSelector(props: {
    anchor: any;
    row: Product | Company;
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: Product | Company): Promise<any>;
}) {
    return (
        <DistributorSelectorInner value={props.row.defaultDistributor || null}
                                  onDone={v => {
                                        return props.onDone(Object.assign({}, props.row, {defaultDistributor: v ? v.id : null}) as any);
                                  }}
                                  onCancel={props.onCancel}
        />
    )
}

export function HwDistributorSelector(props: {
    anchor: any;
    row: DistributorDetail;
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: DistributorDetail): Promise<any>;
}) {
    return (
        <DistributorSelectorInner value={props.row.distributorOverride || null}
                                  onDone={v => {
                                      return props.onDone(Object.assign({}, props.row, {
                                          distributorOverride: v?.id || null,
                                          distributorOverrideName: v?.name || null,
                                      }) as any);
                                  }}
                                  onCancel={props.onCancel}
        />
    )
}

export function DistributorSelectorInner(props: {
    value: number | null
    onCancel(): void;
    onDone(value: Company | null): Promise<any>;
}) {
    const companies = useGetAll(props => api.companies.list(props), {
        kind: "supplier" as CompanyKind
    }, []);

    const [value, setValue] = useState(props.value);
    const list = companies.asList || [];

    const onDoneRef = useRef(props.onDone);
    onDoneRef.current = props.onDone;

    const update = useAsyncAction(async (input: Company | null) => {
        await onDoneRef.current(input);
    }, [])

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault();
                update.callback(list.find(v => v.id === value) || null);
            }}>
                <DialogTitle>
                    Distributor
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 4}} />
                    <AutoComplete<Company>
                        label="Distributor"
                        value={first(list, l => l.id === value)}
                        onSelect={v => {
                            setValue(v.id);
                        }}
                        equals={(a, b) => a.id === b.id}
                        makeLabel={a => a.name}
                        search={async search => {
                            return list.filter(v => v.name.toLowerCase().indexOf(search.toLowerCase()) === 0);
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: 'space-between'}}>
                        <Button onClick={() => {
                            update.callback(null)
                        }} style={{color: red["800"]}}>Remove Distributor</Button>

                        <div style={{paddingLeft: 30}}>
                            {update.LoadingElement}
                            <Button onClick={props.onCancel}>Cancel</Button>
                            <Button variant="contained" type="submit">Save</Button>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import {AppBar, Grid} from "@mui/material";
import {SectionTitle} from "./SectionTitle";
import {useContext} from "react";
import {ChangePreviewContext} from "../pages/project/shopdrawing/ShopDrawingChangePreview";

export function SectionHeader(props: {
    name: any;
    underName?: any;
    inFromRight?: any;
    right?: any;
    primary?: boolean;
}) {
    const preview = useContext(ChangePreviewContext);
    const hasActions = !preview.enabled && !!props.inFromRight

    let inner = (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={hasActions ? undefined : true}>
                <SectionTitle>
                    {props.name}
                    {props.underName && <div style={{fontSize: "0.8rem", fontWeight: "normal"}}>{props.underName}</div>}
                </SectionTitle>
            </Grid>
            {hasActions && <Grid item xs style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                {props.inFromRight}
            </Grid>}
            <Grid item>
                {props.right}
            </Grid>
        </Grid>
    )

    if(props.primary) {
        return (
            <AppBar position="relative" style={{
                padding: 20, paddingTop: 10, paddingBottom: 10,
            }}>
                {inner}
            </AppBar>
        )
    }

    return (
        <div style={{
            padding: 20, paddingTop: 10, paddingBottom: 10,
        }}>
            {inner}
        </div>
    )
}
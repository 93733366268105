import {ToMove, ToMoveProps} from "./ToMove";
import {useContext, useEffect, useState} from "react";
import {TableSelectionContext} from "../../../misc/scroller/Table";
import {routePopupPanel} from "./RouteList";
import {pickupTableName} from "./PickupTable";
import {dropTableName} from "./DropTable";
import {useSyncedRef} from "../../../misc/SyncedRef";

export function ToMovePopout() {
    const ctx = useContext(TableSelectionContext);
    const refreshRef = useSyncedRef(ctx.refresh)
    const [props, setProps] = useState<ToMoveProps>({});

    useEffect(() => {
        routePopupPanel.sendToServer("ping");

        const sub = routePopupPanel.fromServer.subscribeAndFireLast(input => {
            if(input === "refresh-to-move") {
                refreshRef.current(pickupTableName);
                refreshRef.current(dropTableName);
            } else {
                setProps(input);
            }
        })

        return () => sub.cancel();
    }, [refreshRef]);

    return (
        <ToMove key={props.route?.toString() || "no-route"} {...props} />
    )
}
import { useAsync2} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useContext} from "react";
import {PDFInfo, PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import {Company} from "../../../../api/Companies";
import {Opening} from "../../../../api/Openings";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {Project, UnitSystem} from "../../../../api/Projects";
import {OpeningSizePDF} from "./OpeningSizePDF";
import {useProjectId} from "../ProjectName";
import {api} from "../../../../api/API";
import {useIsQuote} from "../../quote/QuoteContainer";
import {getAll} from "../openings/Openings";
import {useAlternative} from "../../quote/alternative/Alternative";

export function SzSubmittalLoader(props: {
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const printCtxRef = useSyncedRef(useContext(PrintManagerContext));
    const projectId = useProjectId();
    const alternative = useAlternative();
    const isQuote = useIsQuote();

    useAsync2(async () => {
        await printCtxRef.current.print2(getOpeningSizeSubmittal({
            async getData(): Promise<OpeningSizeSubData> {
                const projectP = api.projects.get(projectId)
                const openingP = isQuote ?
                    getAll(null, offset => api.quoteOpenings.list({project: projectId, alternative, offset})) :
                    getAll(null, offset => api.openings.list({project: projectId, offset}));

                const project = await projectP;
                const clientP = project.client ? api.companies.get({id: project.client}) : Promise.resolve(undefined);
                const client = await clientP;
                let openings = await openingP;
                openings = openings.filter(o => !!o.frameSeries && o.frameConstruction);

                return {
                    client: client,
                    data: openings,
                    sys: project.unitSystem,
                    project: project,
                }
            },
            projectId: projectId,
        }));

        onDoneRef.current();
    }, {}, []);

    return null
}

type OpeningSizeSubData = {
    client?: Company;
    data: (Opening|QuoteOpening)[]
    project: Project;
    sys: UnitSystem;
}

export function getOpeningSizeSubmittal(props: {
    projectId: number;
    getData(): Promise<OpeningSizeSubData>
}): PDFInfo {
    return {
        name: "opening-size-submittal.pdf",
        render: async (onReady, config) => {
            const d = await props.getData();

            return (
                <OpeningSizePDF
                    onReady={onReady}
                    client={d.client}
                    data={d.data}
                    project={d.project}
                    sys={d.sys}
                />
            )
        },
        serverInfo: {
            submissionForProject: props.projectId,
            submissionType: "frame-openings",
        }
    }
}
import {Project} from "../../../../api/Projects";
import {PropsWithChildren, useContext, useState} from "react";
import {css} from "@emotion/css";
import {useProject} from "../../shopdrawing/ProjectName";
import {ProjectContext} from "../../ProjectProvider";

export function EditLink(props: PropsWithChildren<{
    editor: (props: {
        value: Project;
        onDone(): void;
    }) => any;
}>) {
    const [show, setShow] = useState(false);
    const project = useProject();
    const proj = useContext(ProjectContext);
    const EditEl = props.editor;

    return (
        <>
            <div className={editLinkClass} onClick={() => setShow(true)}>
                {props.children}
            </div>
            {show && project && <EditEl value={project as any} onDone={() => {
                proj.reload();
                setShow(false)
            }} />}
        </>
    )
}

export const editLinkClass = css({
    display: "inline-block",
    textDecoration: "underline",
    cursor: "pointer",

    "&:hover": {
        opacity: "0.9",
    }
})
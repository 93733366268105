import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {useState} from "react";

export function HingeAutoDimensionOverride(props: {
    qtys: number[]
    onClose(): void;
    onDone(qty: number | null): Promise<void>
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const onCloseRef = useSyncedRef(props.onClose);

    const action = useAsyncAction(async (input: {qty: number | null}) => {
        await onDoneRef.current(input.qty);
        onCloseRef.current();
    }, []);

    const [value, setValue] = useState(props.qtys.length > 0 ? props.qtys[0].toString() : "");

    return (
        <Dialog open maxWidth="xs" fullWidth>
            <form onSubmit={e => {
                e.preventDefault()

                action.callback({
                    qty: value === "" ? null : parseInt(value)
                })
            }}>
                <DialogTitle>Override Hinge Qty</DialogTitle>
                <DialogContent>
                    <div style={{height: 6}} />
                    <TextField label="Override Qty" fullWidth variant="outlined" value={value} onChange={e => setValue(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} style={{width: "100%"}}>
                        <Grid item>
                            <Button onClick={() => action.callback({qty: null})} color="error">Clear Override</Button>
                        </Grid>
                        <Grid item xs>
                            {action.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={props.onClose}>Cancel</Button>
                            <Button variant="contained" color="primary" type="submit">Update</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import React from "react";
import {QuoteContainer} from "../QuoteContainer";
import {Div10Inner} from "../../shopdrawing/div10/Div10";
import {Div10Switcher} from "./Div10Switcher";
import {WRAccessoryCutSheetBtn} from "./WRAccessoryCutSheetBtn";
import {TransferProducts} from "../hardware/TransferProducts";
import {Grid} from "@mui/material";
import {EventEmitter} from "nate-react-api-helpers";

export const QuoteDiv10ForceUpdate = new EventEmitter<number>();
export function Div10() {
    const cacheBustKey = EventEmitter.reactValue(QuoteDiv10ForceUpdate);

    return (
        <QuoteContainer
            name={<Div10Switcher />}
                        actions={<>
                            <Grid item>
                                <TransferProducts div10 />
                            </Grid>
                            <div style={{width: 8}} />
                            <Grid item>
                                <WRAccessoryCutSheetBtn />
                            </Grid>
                        </>}>
            <Div10Inner key={cacheBustKey} />
        </QuoteContainer>
    )
}
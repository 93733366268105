import {hasPermission, useUser} from "../../../../misc/Permission";
import {
    Table,
} from "../../../../misc/scroller/Table";
import React, {useContext, useEffect} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize2} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {
    AddRider,
    commonGroupBy2,
    commonPricingColumns2, PriceCol,
    PriceRow, RiderRowTitle,
    ViewPriceCol
} from "../../shopdrawing/pricing/Frames";
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import {defaultPriceSortFunc, getQuotePricing, quotePriceChange} from "./QuotePriceRiderUtil";

export function QuoteHardware() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();
    const customize = usePriceCellCustomize2();

    useEffect(() => {
     console.log("render hardware")
    });

    if(!u) return null;

    return (
        <Table<PriceRow>
            name={priceTableName(project, "hardware")}
            globalPrefsName={priceTableName(0, "hardware")}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            cellCustomize={customize}
            fetch={(ctx) => getQuotePricing(ctx, {
                project: project,
                alternative: alternative,
                customSort: (rs) => {
                    rs.sort((a, b) => {
                        if(a.productType !== b.productType || a.productType !== "hardware") {
                            return defaultPriceSortFunc(a, b)
                        }

                        if(a.manufacturer === b.manufacturer) {
                            const bigNumber = 1000000;
                            return (a.categorySortOrder || bigNumber) - (b.categorySortOrder || bigNumber)
                        }

                        if(a.manufacturerName < b.manufacturerName) return -1;
                        return 1
                    })
                }
            })}
            columns={[
                PriceCol({
                    name: "Name",
                    price: (dt: QuotePriceLine) => {
                        return dt.productName
                    },
                    rider: (dt: QuotePriceRider, row: PriceRow) => {
                        return <RiderRowTitle row={row} />
                    },
                    createRider: (row) => {
                        return <AddRider row={row} />
                    },
                    width: 150,
                }),

                ViewPriceCol("Code", "productCode", 80),
                ViewPriceCol("Finish", "finish", 80),
                ViewPriceCol("Length", "dimLength", 50),
                ViewPriceCol("Width", "dimWidth", 50),
                ViewPriceCol("Height", "dimHeight", 50),
                ...commonPricingColumns2(u, true),
            ]}
            fetchDeps={[project, alternative]}
            columnDeps={[u]}
            groupBy={Object.assign({}, commonGroupBy2, {
                groupFilter: (groupName: string) =>
                    groupName.startsWith("hardware") || groupName === "project",
            })}
            onChange={input => quotePriceChange(input, {
                project: project,
                alternative: alternative
            })}
        />
    )
}

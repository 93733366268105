import {Badge, IconButton, styled} from "@mui/material";
import Notifications from "@mui/icons-material/Notifications";
import {useUser} from "../Permission";
import {useState} from "react";
import { TaskMenu } from "./TaskMenu";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 4,
        top: 4,
        border: `0px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        fontSize: "0.6rem",
        minWidth: "18px",
        height: "18px",
    },
}));

export function HeaderTasks() {

    const usr = useUser();
    const [open, setOpen] = useState<HTMLElement|null>(null)

    return (
        <>
            <IconButton size="small" style={{color: "white"}} onClick={e => setOpen(e.currentTarget)}>
                <StyledBadge badgeContent={usr?.unreadTasks|| 0} color="error">
                  <Notifications color="inherit" />
                </StyledBadge>
            </IconButton>
            {open && <TaskMenu anchorEl={open} onClose={() => setOpen(null)} />}
        </>
    )
}
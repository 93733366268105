import { ShopContainer } from "../ShopContainer";
import {api} from "../../../../api/API";
import {useProjectId, useProjectUnitSystem} from "../ProjectName";
import {Opening} from "../../../../api/Openings";
import React, {useContext, useState} from "react";
import { RowActions } from "./RowActions";
import {
    AdjustCol,
    FetchContext,
    DistanceCol, NominalWidth,
    NumberCol,
    Select2Col, SelectCol,
    StringCol,
    Table, SeqNumber, Column, lookupNestedProp
} from "../../../../misc/scroller/Table";
import {blue, cyan, deepOrange, green, grey, indigo, lime, orange, purple, red} from "@mui/material/colors";
import { Paginated } from "../../../../api/Users";
import {CellWithBg} from "../../../logistics/purchasing/CellWithBg";
import {EditHardwareGroup} from "./EditHardwareGroup";
import {useUser} from "../../../../misc/Permission";
import {ManagerLockContext} from "../ProjectActions";
import {MakeSubmittal} from "./MakeSubmittal";
import {ProjectProperties} from "../ProjectProperties";
import {AutoInsertOpening} from "./AutoInsertOpening";
import {
    AnchorCol,
    doorCore, doorFinish, doorGauge, doorMaterial, doorSeam,
    doorSeries, doorThicknesses, frameConstruction, frameGauge, frameMaterial, frameProfile,
    frameSeries,
    handingOptions, isWindow, JambDepthCol,
    labels,
    locationTransitions,
    openingTypes, PrepLocationCol, updateFrameProfileBasedOnHanding
} from "../../quote/openings/QuoteOpenings";
import {Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../release/Release";
import {useQueryParam} from "../../quote/pricing/QueryParam";
import {OpeningSwitcher} from "./OpeningSwitcher";
import { WithChangesetMeta} from "../changeset/ChangesetContext";
import {cellInActive} from "../pricing/cellconst";
import {PreviousValuePopup} from "./PreviousValuePopup";
import {ScreenElevationPopup} from "../../quote/openings/ScreenElevationPopup";
import {DoorElevationPopup} from "../../quote/openings/DoorElevationPopup";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {NewRowMenu} from "../../quote/openings/NewRowMenu";
import {BreakHardwareLinkContext, BreakHardwareLinkProvider} from "../../quote/openings/BreakHardwareLinkProvider";
import {ReleasedAdjustmentContext, ReleasedAdjustmentProvider} from "../../quote/openings/OnsiteAdjustmentsProvider";
import {projectTablePrefName} from "../TablePrefName";
import {DrawingWallLabelPopup} from "../../quote/openings/DrawingWallLabelPopup";
import {TextEditCell} from "../../quote/openings/TextEditCell";
import {ReviewWallTypes, ReviewWallTypeTitle} from "../../quote/openings/ReviewWallTypes";
import {ReviewScreenElevations, ReviewScreenElevationTitle} from "../../quote/openings/ReviewScreenElevations";
import {ReviewDoorElevations, ReviewDoorElevationTitle} from "../../quote/openings/ReviewDoorElevations";

const wordMap = {
    "released":"Released",
    "not-released":"Not Released",
    "ordered":"Ordered",
    "in-warehouse":"In Warehouse",
    "in-truck":"In Truck",
    "delivered":"Delivered",

    "po-draft": "PO Draft",
    "ready-for-pickup": "Ready for Pickup",
    "back-order": "Back Order",
    "scheduled-for-delivery": "Scheduled for Delivery",
    "not-active": "-",

    "waiting-for-truck": "Waiting for Truck",
    "ready-to-deliver": "Ready to Deliver",
    "to-pick": "To Pick",
}

export function statusWords(key: string): string {
    // @ts-ignore
    return wordMap[key] || "unknown (" + key + ")"
}

export function StatusCol<T>(name: string, key: keyof T) {
    return {
        name: name,
        render: function(o: T): any {
            // @ts-ignore
            const word = wordMap[o[key] as any];

            switch(o[key] as any) {
                case "not-released":
                    return <CellWithBg backgroundColor={grey["200"]}>{word}</CellWithBg>
                case "released":
                    return <CellWithBg backgroundColor={blue["100"]}>{word}</CellWithBg>
                case "po-draft":
                case "to-pick":
                    return <CellWithBg backgroundColor={cyan["200"]}>{word}</CellWithBg>
                case "ordered":
                    return <CellWithBg backgroundColor={purple["200"]}>{word}</CellWithBg>
                case "ready-to-deliver":
                case "ready-for-pickup":
                    return <CellWithBg backgroundColor={deepOrange["200"]}>{word}</CellWithBg>
                case "back-order":
                    return <CellWithBg backgroundColor={red["200"]}>{word}</CellWithBg>
                case "waiting-for-truck":
                case "in-warehouse":
                    return <CellWithBg backgroundColor={orange["200"]}>{word}</CellWithBg>
                case "scheduled-for-delivery":
                    return <CellWithBg backgroundColor={lime["200"]}>{word}</CellWithBg>
                case "in-truck":
                    return <CellWithBg backgroundColor={indigo["200"]}>{word}</CellWithBg>
                case "delivered":
                    return <CellWithBg backgroundColor={green["200"]}>{word}</CellWithBg>
                case "not-active":
                    return <CellWithBg backgroundColor={grey["100"]}>{word}</CellWithBg>
                default:
                    return <CellWithBg backgroundColor={grey["100"]}>Unknown ({o[key] as any})</CellWithBg>
            }
        },
        width: 100,
        filter: (row: T, input: string) => {

            // @ts-ignore
            let word = wordMap[row[key]] as string;
            if(!word) {
                return false;
            }

            return word.toLowerCase().indexOf(input.toLowerCase()) === 0
        },
        sort: (a: T, b: T) => {
            // @ts-ignore
            let aWord = wordMap[a[key]] || "";

            // @ts-ignore
            let bWord = wordMap[b[key]] || "";

            return aWord.localeCompare(bWord)
        }
    };
}

export function openingsTableName(project: number, tab = "all") {
    return projectTablePrefName("project.openings." + tab, project)
}

export let currentTab = "";

export function Openings() {
    const [tab, setTab] = useQueryParam("tab", "doors");

    const previewChangeset = useContext(ChangePreviewContext);
    const u = useUser();
    if(!u) return null;

    if(tab === "review-wall-type") {
        return (
            <ShopContainer name={<ReviewWallTypeTitle onDone={() => {
                setTab("frames")
            }} />}>
                <ReviewWallTypes />
            </ShopContainer>
        );
    }

    if(tab === "review-screen-elevation") {
        return (
            <ShopContainer name={<ReviewScreenElevationTitle onDone={() => {
                setTab("frames")
            }} />}>
                <ReviewScreenElevations />
            </ShopContainer>
        );
    }

    if(tab === "review-door-elevation") {
        return (
            <ShopContainer name={<ReviewDoorElevationTitle onDone={() => {
                setTab("doors")
            }} />}>
                <ReviewDoorElevations />
            </ShopContainer>
        );
    }

    return <ShopContainer name={<OpeningSwitcher />}
                          submittalCreator={MakeSubmittal}>
        <div style={{
            flex: 1, display: "flex",
            flexDirection: "column",
            overflow: "auto"
        }}>
            <TabWrapper>
                <Tabs value={tab} onChange={(e, value) => {
                    setTab(value)
                }}>
                    <Tab label="Frames" value="frames" />
                    <Tab label="Doors" value="doors" />
                    <Tab label="All" value="all" />
                </Tabs>
            </TabWrapper>

            <BreakHardwareLinkProvider>
                <ReleasedAdjustmentProvider>
                    <OpeningTable tab={tab} onTabChange={setTab} />
                </ReleasedAdjustmentProvider>
            </BreakHardwareLinkProvider>
        </div>
        {!previewChangeset.enabled && <ProjectProperties />}
    </ShopContainer>
}

function OpeningTable(props: {
    tab: string;
    onTabChange(value: string): void;
}) {
    const tab = props.tab;
    const project = useProjectId();
    const sys = useProjectUnitSystem();
    const [key] = useState("-")

    const previewChangeset = useContext(ChangePreviewContext);
    const hwContext = useContext(BreakHardwareLinkContext);
    const onsiteContext = useContext(ReleasedAdjustmentContext);

    const {locked} = useContext(ManagerLockContext)

    const showDoor = (tab === "doors" || tab === "all");
    const showFrame = (tab === "frames" || tab === "all");
    const tableName = openingsTableName(project, tab);

    currentTab = tab;

    if(!sys) return null;

    return (
        <WithChangesetMeta>
            {changeset => <Table<Opening>
                key={key+ tab}
                locked={locked}
                name={tableName}
                globalPrefsName={openingsTableName(0, tab)}
                onDrag={async (input) => {
                    await api.openings.reOrder({
                        project: project,
                        idOrder: input.idOrder,
                    })

                    return {
                        sortByCol: null
                    }
                }}
                cellCustomize={{
                    backgroundColor: (row: Opening, c: Column<Opening>) => {
                        if(!changeset.proposing) return null;

                        let changed = false;

                        if("isChangedSinceBackup" in c && c.isChangedSinceBackup) {
                            changed = c.isChangedSinceBackup(row)
                        } else if(c.editKey) {
                            changed = !!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)
                        } else {
                            changed = false;
                        }

                        if(changed) {
                            return "white"
                        }

                        return cellInActive;
                    },
                    onFocus: (row: Opening, c: Column<Opening>, anchor: any) => {
                        if(!changeset.proposing) return null;

                        let changed = false;

                        if("isChangedSinceBackup" in c && c.isChangedSinceBackup) {
                            changed = c.isChangedSinceBackup(row)
                        } else if(c.editKey) {
                            changed = !!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)
                        } else {
                            changed = false;
                        }

                        if(changed) {
                            const value = row.backup as any;
                            return (
                                <PreviousValuePopup anchor={anchor} width={c.width} value={c.render(value, c)} />
                            )
                        }

                        return null;
                    }
                }}
                columns={[{
                    name: "",
                    render: o => <RowActions row={o} />,
                    width: 100,
                },
                    SeqNumber("seqNumber", tableName, (ids) => api.openings.reSequence({
                        project: project,
                        idOrder: ids,
                    })),
                    StringCol("Opening", "name"),
                    NumberCol("Qty", "qty"),
                    StringCol("Floor", "floor"),
                    StringCol("Location", "locationOne"),
                    Select2Col<Opening>("To/From", "locationTransition", locationTransitions),
                    StringCol<Opening>("Location 2", "locationTwo"),
                    Select2Col<Opening>("Type", "openingType", openingTypes),
                    NominalWidth<Opening>("Nominal Width", sys, "nominalWidth", "inactiveDoorWidth"),
                    DistanceCol("Nominal Height", sys, "nominalHeight"),
                    AdjustCol(StringCol("Hand", "handing"), {
                        editable: {
                            type: "select",
                            options: handingOptions,
                            onChangeBeforeSave: (row: Opening, value: string) => {
                                updateFrameProfileBasedOnHanding(row)
                            }
                        },
                        disabled: isWindow as any,
                    }),
                    Select2Col<Opening>("Label", "label", labels),
                    showFrame && Select2Col<Opening>("Frame Series", "frameSeries", frameSeries),
                    showFrame && SelectCol<Opening>("Frame Material", "frameMaterial", frameMaterial),
                    showFrame && AdjustCol(SelectCol<Opening>("Frame Gauge", "frameGauge", frameGauge), {
                        alignRight: true,
                    }),
                    showFrame && {
                        name: "Screen Elevation",
                        isChangedSinceBackup: (row: Opening) => {
                            if(!row.backup) return false;
                            return row.screenElevation !== row.backup.screenElevation || row.screenElevationFile !== row.backup.screenElevationFile
                        },
                        editable: {
                            type: "custom",
                            copy: data => data.screenElevation?.toString() || "",
                            paste: (data, value) => {
                                data.screenElevation = value;
                            },
                            render: (props) => {
                                return <ScreenElevationPopup {...props} />
                            }
                        },
                        editObj: (dst, src, value: string) => {
                            dst.screenElevation = value;
                            dst.screenElevationFile = undefined;
                        },
                        render: (data, col) => {
                            return data.screenElevation || "";
                        },
                        headerMenuItems: () => {
                            return [{
                                name: "Review Screen Elevations",
                                onClick: () => {
                                    props.onTabChange("review-screen-elevation")
                                }
                            }]
                        }
                    },
                    showFrame && SelectCol<Opening>("Frame Profile", "frameProfile", frameProfile),
                    showFrame && {
                        name: "Wall Type",
                        isChangedSinceBackup: (row: Opening) => {
                            if(!row.backup) return false;
                            return row.drawingWallLabel !== row.backup.drawingWallLabel || row.drawingWallLabelFile !== row.backup.drawingWallLabelFile
                        },
                        editable: {
                            type: "custom",
                            copy: data => data.drawingWallLabel?.toString() || "",
                            paste: (data, value) => {
                                data.drawingWallLabel = value;
                            },
                            render: (props) => {
                                if(!props.row.drawingWallLabel) {
                                    return (
                                        <TextEditCell keyName="drawingWallLabel" {...props} />
                                    )
                                }

                                return <DrawingWallLabelPopup {...props} />
                            }
                        },
                        editObj: (dst, src, value: string) => {
                            dst.drawingWallLabel = value;
                            dst.drawingWallLabelFile = undefined;
                        },
                        render: (data, col) => {
                            return data.drawingWallLabel || "";
                        },
                        width: 100,
                        headerMenuItems: () => {
                            return [{
                                name: "Review Wall Types",
                                onClick: () => {
                                    props.onTabChange("review-wall-type")
                                }
                            }]
                        }
                    },
                    showFrame && JambDepthCol("Jamb Depth", "jambDepth"),
                    showFrame && SelectCol<Opening>("Frame Construction", "frameConstruction", frameConstruction),
                    showFrame && AnchorCol(),
                    showDoor && AdjustCol(Select2Col<Opening>("Door Series", "doorSeries", doorSeries), {
                        disabled: isWindow as any,
                    }),
                    showDoor && AdjustCol(SelectCol<Opening>("Door Material", "doorMaterial", doorMaterial), {
                        disabled: isWindow as any,
                    }),
                    showDoor && AdjustCol(SelectCol<Opening>("Door Finish", "doorFinish", doorFinish, {
                        freeSolo: true
                    }), {
                        disabled: isWindow as any,
                    }),
                    showDoor && AdjustCol(SelectCol<Opening>("Door Gauge", "doorGauge", doorGauge), {
                        alignRight: true,
                    }),
                    showDoor && AdjustCol(Select2Col<Opening>("Door Thickness", "doorThickness",doorThicknesses(sys)), {
                        disabled: isWindow as any,
                    }),
                    showDoor && AdjustCol<Opening>({
                        name: "Door Elevation",
                        width: 200,
                        isChangedSinceBackup: (row) => {
                            if(!row.backup) return false;
                            return row.doorElevation !== row.backup.doorElevation || row.doorElevationFile !== row.backup.doorElevationFile
                        },
                        editable: {
                            type: "custom",
                            copy: data => data.doorElevation?.toString() || "",
                            paste: (data, value) => {
                                data.doorElevation = value;
                            },
                            render: (props) => {
                                return <DoorElevationPopup {...props} />
                            }
                        },
                        editObj: (dst, src, value: string) => {
                            dst.doorElevation = value;
                            dst.doorElevationFile = undefined;
                        },
                        render: (data, col) => {
                            return data.doorElevation || "";
                        },
                        headerMenuItems: () => {
                            return [{
                                name: "Review Door Elevations",
                                onClick: () => {
                                    props.onTabChange("review-door-elevation")
                                }
                            }]
                        }
                    }, {
                        disabled: isWindow as any,
                    }),
                    showDoor && AdjustCol(SelectCol<Opening>("Door Core", "doorCore", doorCore), {
                        disabled: isWindow as any,
                    }),
                    showDoor && AdjustCol(SelectCol<Opening>("Door Seam", "doorSeam", doorSeam), {
                        disabled: isWindow as any,
                    }),
                    AdjustCol(PrepLocationCol<Opening>(), {
                        disabled: isWindow as any,
                    }),
                    {
                        name: "Hardware Heading",
                        render: (data, col) => {
                            return data.hardwareGroupName || "";
                        },
                        editKey: "hardwareGroupName",
                        isChangedSinceBackup: (row) => {
                            if(row.backup) {
                                return row.hardwareGroup !== row.backup.hardwareGroup
                            }

                            return false;
                        },
                        editable: {
                            type: "custom",
                            render: props => <EditHardwareGroup isQuote={false} {...props} />
                        },
                        filter: (data, search) => {
                            return data.hardwareGroupName?.indexOf(search) === 0
                        },
                        disabled: isWindow as any,
                    },
                    showDoor && StatusCol("Door Status", "doorStatus"),
                    showFrame && StatusCol("Frame Status", "frameStatus"),
                    StringCol("Notes", "notes", 300),
                ]}
                fetch={async ctx => {
                    return await getAll(ctx, offset => api.openings.list({
                        project: project,
                        offset,
                        previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined,
                    }))
                }}
                fetchDeps={[project]}
                insert={previewChangeset.enabled ? undefined : {
                    buttonText: "New Row",
                    alignX: "left",
                    modal: input => <AutoInsertOpening onDone={input} />,
                    options: () => <NewRowMenu />,
                }}
                onChange={previewChangeset.enabled ? undefined : async (input) => {
                    try {
                        return await api.openings.upsert(input)
                    } catch (e: any) {
                        try {
                            return await hwContext.onError(input, e, async (input) => {
                                return await api.openings.upsert(input)
                            })
                        } catch (e: any) {
                            return await onsiteContext.onError(input, e, async (input) => {
                                return await api.openings.upsert(input)
                            })
                        }
                    }
                }}
            />}
        </WithChangesetMeta>
    )
}

export async function getAll<T>(ctx: FetchContext | null, method: (offset: number) => Promise<Paginated<T>>): Promise<T[]> {
    let data: (T|undefined)[] = []; // sorted by offset
    let expectedCount = 0;
    let chunkSize = 0; // assume to start
    let claimedOffsets: number[] = [];
    let cancelled = false;

    // parallelize
    const maxParallel = 3;
    let parallel = 0;

    const get = (offset: number) => {
        if(claimedOffsets.includes(offset)) return Promise.resolve();
        claimedOffsets.push(offset);

        while (data.length < offset) {
            data.push(undefined);
        }

        return method(offset)
            .then(r => {
                if(cancelled || ctx?.cancelled) return;

                r.data.map((r, index) => {
                    if (data.length === offset + index) {
                        data.push(r);
                    } else {
                        data[offset + index] = r;
                    }
                })

                while(data.length < r.count) {
                    data.push(undefined);
                }

                expectedCount = r.count;

                if (r.data.length > chunkSize) {
                    chunkSize = r.data.length;
                }
            })
    }

    const availableOffsets = () => {
        // calculate next 2 missing offsets so we can multiply workers if needed
        let offsets: number[] = [];
        let baseIndex = 0;
        const parallelOpts = Math.min(2, maxParallel - parallel + 1); // grow by 1 parallel per iteration if available

        while(offsets.length < parallelOpts) {
            // null entries mean that data is missing for that chunk
            const i = data.indexOf(undefined, baseIndex);
            if(i >= expectedCount) return offsets; // no more data to fetch

            if(i === -1) {
                if(expectedCount > 0) return offsets; // no more data to fetch, have the end and no nulls
                if(chunkSize === 0) return offsets; // don't have enough information to start parallel fetches, if offset=0 request finds data it will multiply workers

                let offset = data.length;

                while(offsets.length < parallelOpts) {
                    if(!claimedOffsets.includes(offset)) {
                        offsets.push(offset);
                    }

                    offset += chunkSize;
                }

                return offsets;
            }

            if (!claimedOffsets.includes(i)) {
                offsets.push(i);
            }

            if(chunkSize === 0) return offsets; // don't have enough information to start parallel fetches, if offset=0 request finds data it will multiply workers

            baseIndex = i + chunkSize;
            if(expectedCount > 0 && baseIndex >= data.length) {
                return offsets;
            }
        }

        return offsets;
    }

    const worker = async (startOffset: number) => {
        if(parallel + 1 > maxParallel) return;
        parallel++;

        // prevent duplicate fetches
        if(claimedOffsets.includes(startOffset)) return;
        await get(startOffset);
        if(cancelled || ctx?.cancelled) return;

        for(let i = 0; i < 100; i++) {
            const offsets = availableOffsets()
            if (offsets.length === 0) return;

            let todo: Promise<void>[] = [];
            for(let j = 0; j < offsets.length; j++) {
                if(j === 0) {
                    todo.push(get(offsets[j]));
                } else {
                    todo.push(worker(offsets[j]))
                }
            }

            await Promise.all(todo);
            if(cancelled || ctx?.cancelled) return;
        }
    }

    // get starting point to check data layout before starting too many parallel
    let workers: Promise<any>[] = [];
    workers.push(worker(0));
    workers.push(worker(100));
    workers.push(worker(200));

    try {
        await Promise.all(workers)
    } finally {
        cancelled = true;
    }

    return data.filter(v => v !== undefined) as T[];
}
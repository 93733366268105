import Info from "@mui/icons-material/Info";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import {useState} from "react";
import {Popover} from "@mui/material";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {ProductPreview} from "../../../api/Logistics";
import {DriverLogDetailRow} from "./DriverLogDetail";
import {distinct, sum} from "nate-react-api-helpers";

export function QtyWithPreview(props: {
    data: ProductPreview[];
}) {
    const [preview, setPreview] = useState<HTMLElement|null>(null);
    const qty = sum(
        props.data.map(c => {
            if(c.boxId) return 0
            return c.qty;
        })
    ) + distinct(props.data.filter(c => !!c.boxId).map(c => c.boxId)).length;

    return (
        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            {qty} pcs&nbsp;

            <SimpleIconButton
                onMouseEnter={(e: any) => {
                    setPreview(e.target as any);
                }}
                onMouseLeave={() => setPreview(null)}
            >
                <Info />
            </SimpleIconButton>

            {preview && <QtyPreview contents={props.data} onClose={() => setPreview(null)} anchorEl={preview} />}
        </div>
    );
}

export function isNumber(value: any): value is number {
    return typeof value === "number";
}

type ProductPreviewSimple = {
    name: string;
    qty: number;

    qtyReceived?: number | null;
    qtyDamaged?: number | null;
}


export function QtyPreview(props: {
    contents: ProductPreview[];
    anchorEl: any;
    onClose(): void;
}) {
    const hasResult = props.contents.some(c => isNumber(c.qtyReceived));

    const aggregated: ProductPreviewSimple[] = [];
    const byBox: {[key: number]: {
        preview: ProductPreviewSimple,
        contentQty: number,
    }} = {};

    props.contents.forEach(c => {
        if(!c.boxId) {
            aggregated.push(c)
            return;
        }

        c.boxType = c.boxType || "unknown";
        c.boxLabel = c.boxLabel || "unknown";

        const box = byBox[c.boxId]
        if(!box) {
            byBox[c.boxId] = {
                preview: {
                    name: c.boxType + ": " + c.boxLabel,
                    qty: 1,
                    qtyReceived: c.qtyReceived && c.qtyReceived > 0 ? 1 : 0,
                    qtyDamaged: c.qtyDamaged && c.qtyDamaged > 0 ? 1 : 0,
                },
                contentQty: c.qty
            }

            aggregated.push(byBox[c.boxId].preview)
            return;
        }

        box.contentQty += c.qty;
    });

    for(let k in byBox) {
        const obj = byBox[k]
        obj.preview.name += " (" + obj.contentQty + " items)"
    }

    return (
        <Popover open={true}
                 anchorEl={props.anchorEl}
                 sx={{
                     pointerEvents: 'none',
                 }}
                 disableRestoreFocus
                 onClose={props.onClose}
                 anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                 }}
                 transformOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}
        >
            <div style={{padding: 4}}>
                <table className={tableClass}>
                    <thead>
                    <tr>
                        <td>Item</td>
                        <td>Qty</td>
                        {hasResult && <td>Received</td>}
                    </tr>
                    </thead>
                    <tbody>
                    {aggregated.map(c => <tr>
                        <td>{c.name}</td>
                        <td style={{textAlign: "right"}}>{c.qty}</td>
                        {hasResult && <td style={{textAlign: "right"}}>
                            <DriverLogDetailRow got={c.qtyReceived || 0} damaged={c.qtyDamaged || 0} expected={c.qty} />
                        </td>}
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </Popover>
    )
}

// @ts-ignore
const tableClass = css({
    fontSize: "0.8rem",
    cellSpacing: 0,
    borderCollapse: "collapse",

    "& > thead": {
        fontWeight: "bold",

        "& td": {
            borderBottom: "1px solid " + grey["400"],
        }
    },

    "& > tbody > tr:not(:last-child) > td": {
        borderBottom: "1px solid " + grey["200"]
    },

    "& > * > tr > td": {
        paddingLeft: 2, paddingRight: 4,
    }
})
import {useCallback, useContext, useEffect, useState} from "react";
import {AdjustConfig, PDFGenContext} from "../../../../misc/pdf/PDFGenerator";
import {api} from "../../../../api/API";
import {sleep} from "nate-react-api-helpers";
import {PDFInfo} from "../../../../misc/pdf/PrintManager";
import {PrintArgs} from "../../../../api/Print";

type Info = PDFInfo & {download?: boolean};

export function usePrintParallel() {
    const {isPrinting, isPrintingRef, print} = useContext(PDFGenContext);
    const [queue, setQueue] = useState<Info[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [loadingCount, setLoadingCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if(isPrinting) return;
        if(isPrintingRef.current) return;

        const item = queue.shift();
        if(!item) return;

        (async () => {
            setLoadingCount(old => old + 1);
            setTotalCount(old => old + 1);

            const done = () => {
                setLoadingCount(old => old - 1);
            }

            try {
                console.log("print: call()")
                const content = await print(item.render, makeConfigurator(item.serverInfo, v => {
                    item.serverInfo = v;
                }));

                const info = await api.print.print({
                    content: content,
                    name: item.name,
                    args: item.serverInfo ? JSON.stringify(item.serverInfo) : "",
                })

                while(true) {
                    await sleep(1000);
                    const status = await api.print.getStatus({uuid: info.uuid})
                    if(status.failed) {
                        setErrors(old => [...old, "PDF failed: " + item.name]);
                        done()
                        return;
                    } else if(status.ready) {
                        if(item.download) {
                            api.print.download({uuid: info.uuid});
                        }

                        done()
                        return;
                    }
                }
            } catch (e: any) {
                setErrors(old => [...old, e.toString()]);
                done()
            }
        })()
    }, [isPrinting, queue, loadingCount, print, isPrintingRef]);

    return {
        printer: useCallback((input: Info) => {
            setQueue(old => [...old, input]);
        }, []),
        reset: useCallback(() => {
            setTotalCount(0);
            setErrors([]);
            setLoadingCount(0);
        }, []),
        loading: loadingCount > 0,
        errors: errors,
        loadingCount: loadingCount,
        totalCount: totalCount,
    }
}

export function makeConfigurator(cfg: PrintArgs, onChange: (input: PrintArgs) => void) : AdjustConfig {
    let base = cfg;

    return {
        adjust(cb: (input: PrintArgs) => PrintArgs) {
            base = cb(base)
            onChange(base);
        }
    }
}
import {Option} from "../../../misc/scroller/Table";
import {PurchaseOrder} from "../../../api/Logistics";
import {Button, Grid} from "@mui/material";
import {useCallback, useContext, useEffect, useState} from "react";
import {DateField} from "../../../misc/DateField";
import {TextField2} from "../../../misc/TextField2";
import {paginated2options, Select2, select2list} from "../../../misc/Select2";
import {deliveryMethods, purchaseOrderStatuses, purchasingPopoutPanel} from "./PurchaseOrderList";
import {api} from "../../../api/API";
import { Items } from "./Items";
import {SnackContext} from "../../../misc/Snackbar";
import {contains} from "nate-react-api-helpers";
import {isEditable} from "./PurchaseOrder";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {usePopout} from "../../../misc/PopoutInteractor";
import {LogisticsItemHeader} from "./LogisticsItemHeader";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {SourceTab} from "./PartsToSource";

export function EditPurchaseOrder(props: {
    value: PurchaseOrder;
    onReload(): void;
}) {
    const value = props.value;
    const [lastUpdate, setLastUpdate] = useState<number>();

    const snack = useContext(SnackContext);

    const update = useCallback((prop: keyof PurchaseOrder) => {
        return async (newValue: any) => {
            // @ts-ignore
            value[prop] = newValue;
            setLastUpdate(Date.now());
        }
    }, [value]);

    const reloadRef = useSyncedRef(props.onReload)

    useEffect(() => {
        const tm = setTimeout(async () => {
            if(Object.keys(value).length === 0) return;
            if(value.id <= 0) return;
            if(!lastUpdate) return;

            try {
                const updated = await api.logistics.updatePurchaseOrder(value);
                Object.assign(props.value, updated)
                reloadRef.current();
            } catch (e: any) {
                snack.error(e.toString())
            }
        }, 500);

        return () => clearTimeout(tm);
    }, [lastUpdate, value, snack, props.value, reloadRef]);

    const notifyWindowOfPO = useCallback((value: PurchaseOrder) => {
        let tab: SourceTab = "hardware";
        if(value.supplierSellsHardware) {
            tab = "hardware";
        } else if(value.supplierSellsDoors) {
            tab = "door";
        } else if(value.supplierSellsFrames) {
            tab = "frame";
        } else if(value.supplierSellsDiv10) {
            tab = "div10";
        }

        purchasingPopoutPanel.sendToClient({
            purchaseOrder: value.id,
            isDraft: value.status === "draft",
            supplier: {
                id: value.supplier,
                name: value.supplierName,
            },
            defaultTab: tab,
        });
    }, []);

    useEffect(() => {
        notifyWindowOfPO(value)
    }, [value, lastUpdate, notifyWindowOfPO]);

    const ctrl = usePopout(purchasingPopoutPanel);

    return (
        <form onSubmit={e => {
            e.preventDefault();
        }} style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
        }}>
            <LogisticsItemHeader>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3}>
                        <TextField2 disabled={value.status !== "draft"} fullWidth label="PO #" initialValue={value.poNumber} value={value.poNumber} onChange={update("poNumber")} />
                    </Grid>
                    <Grid item xs={3}>
                        <DateField disabled={value.status !== "draft"} fullWidth nullable label="Submitted" initialValue={value.submittedAt} onChange={update("submittedAt")} />
                    </Grid>
                    <Grid item xs={3}>
                        <DateField fullWidth nullable label="Due" initialValue={value.due} onChange={update("due")} />
                    </Grid>
                    <Grid item xs={3}>
                        <Button fullWidth variant="outlined" endIcon={<OpenInNewIcon />}
                                onClick={() => {
                                    ctrl.open()
                                    notifyWindowOfPO(props.value)
                                }} disabled={!isEditable(props.value)}>
                            To Source
                        </Button>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Select2 label="Supplier" initialValue={value.supplier as any} initialDisplay={value.supplierName}
                                 lookup={paginated2options("name", "id", search => api.companies.list({search: search}))}
                                 onChange={e => update("supplier")(e.value)}
                                 disabled={value.status !== "draft"}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select2 label="Status"
                                 initialValue={value.status}
                                 disabled={value.status === "complete"}
                                 lookup={select2list(
                                     disableValues(purchaseOrderStatuses,
                                         "complete",
                                         value.deliveryMethod === "delivery" && "ready-for-pickup",
                                     )
                                 )}
                                 onChange={e => update("status")(e.value)} />
                    </Grid>
                    <Grid item xs={3}>
                        <Select2
                            label="Delivery Method" initialValue={value.deliveryMethod}
                            lookup={select2list(deliveryMethods)}
                            onChange={e => update("deliveryMethod")(e.value)}
                            disabled={value.status !== "draft"}
                        />
                    </Grid>
                </Grid>

            </LogisticsItemHeader>
            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                <Items order={props.value} />
            </div>
        </form>
    )
}

function disableValues(input: Option[], ...disableValues: (string|boolean|null)[]): Option[] {
    return input.map(i => {
        if(contains(disableValues, i.value)) {
            return Object.assign({}, i, {
                disabled: true,
            })
        }

        return i;
    })
}
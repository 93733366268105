import {TableCellTextPopper} from "../../../../misc/scroller/TableEditCell";
import React, {useState} from "react";

export function TextEditCell<T>(props: {
    anchor: any;
    row: T;
    width: number;
    initialValue: string;
    keyName: keyof T;
    onCancel(): void;
    onDone(value: T): Promise<any>;
}) {
    const [value, setValue] = useState<string>(props.initialValue);
    const doneRef = React.useRef(false);

    return (
        <TableCellTextPopper
            anchor={props.anchor}
            alignRight={false}
            onEnter={(e) => {
                if(doneRef.current) return;
                doneRef.current = true;

                props.onDone(Object.assign({}, props.row, {
                    [props.keyName]: value
                }))
            }}
            onEsc={(e) => {
                if(doneRef.current) return;
                doneRef.current = true;

                props.onCancel();
            }}
            onTab={(e) => {
                if(doneRef.current) return;
                doneRef.current = true;

                props.onDone(Object.assign({}, props.row, {
                    [props.keyName]: value
                }))
            }}
            onArrow={dir => {
                if(doneRef.current) return;
                doneRef.current = true;

                props.onDone(Object.assign({}, props.row, {
                    [props.keyName]: value
                }))
            }}
            onBlur={() => {
                if(doneRef.current) return;
                doneRef.current = true;

                props.onDone(Object.assign({}, props.row, {
                    [props.keyName]: value
                }))
            }}
            value={value}
            onChange={setValue}
        />
    )
}
import {Checkbox, TextField} from "@mui/material";
import {PrepInternalOption} from "./PrepCanvasManager";
import {ImperialDistance} from "../../../../../misc/ImperialDistance";
import {useCallback, useEffect, useRef, useState} from "react";

export function ControlItem(props: {
    value: PrepInternalOption;
}) {
    const v = props.value;
    const [value, setValue] = useState("");
    const [checked, setChecked] = useState(false);
    const [placeholder, setPlaceholder] = useState("");
    const focusedRef = useRef(false);

    const refresh = useCallback(() => {
        if(focusedRef.current) return;

        setChecked(v.value.enabled);
        setValue(v.value.value ? ImperialDistance.showInches(v.value.value) : "");
        setPlaceholder(ImperialDistance.showInches(v.value.actualValue));
    }, [v.value]);

    useEffect(() => {
        refresh();

        const sub = props.value.value.changed.subscribe(refresh);
        return () => sub.cancel();
    }, [props.value.value.changed, refresh]);

    return (
        <tr>
            <td>
                <Checkbox checked={checked} onChange={(e, checked) => {
                  v.onChange(undefined, checked);
                }} />
            </td>
            <td>
                {v.value.label}
            </td>
            <td>
                <TextField style={{width: 100}}
                           size="small"
                           fullWidth
                           placeholder={placeholder}
                           value={value}
                           onChange={e => {
                                 const val = e.target.value;
                                 setValue(val);

                                 try {
                                     const dist = ImperialDistance.parse(val);
                                     v.onChange(dist.universalValue(), true);
                                 } catch (e) {

                                 }
                           }}
                           onFocus={e => {
                               focusedRef.current = true
                           }}
                           onBlur={e => {
                               focusedRef.current = false;
                               refresh();
                           }}
                />
            </td>
        </tr>
    )
}
import {Delivery} from "../../../api/Deliveries";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {SnackContext} from "../../../misc/Snackbar";
import {api} from "../../../api/API";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select, Tab,
    Tabs
} from "@mui/material";
import {paginated2options, Select2, select2list} from "../../../misc/Select2";
import {deliveryStatuses} from "./DeliveryList";
import { DeliveryItems } from "./DeliveryItems";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {usePopout} from "../../../misc/PopoutInteractor";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";
import {LogisticsItemHeader} from "../purchasing/LogisticsItemHeader";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {DateField} from "../../../misc/DateField";
import {DeliveryNotes} from "./DeliveryNotes";
import {ItemsFooter} from "./ItemsFooter";
import {useGetAll} from "../routes/useGetAll";
import {useUser} from "../../../misc/Permission";
import moment from "moment";
import {BoxSetup} from "./boxing/BoxSetup";
import {EventEmitter} from "nate-react-api-helpers";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import {useQueryParam} from "../../project/quote/pricing/QueryParam";

export function DeliveryDetail(props: {
    value: Delivery | null;
    onReload(): void;
}) {
    const onReload = useSyncedRef(props.onReload);
    const value = props.value;
    const valueRef = useSyncedRef(value);
    const [lastUpdate, setLastUpdate] = useState<number>();

    const snack = useContext(SnackContext);

    const update = useCallback((prop: keyof Delivery) => {
        return async (newValue: any) => {
            // @ts-ignore
            value[prop] = newValue;
            setLastUpdate(Date.now());
        }
    }, [value]);

    useEffect(() => {
        if(!lastUpdate) return;

        const tm = setTimeout(async () => {
            if(!valueRef.current) return;

            if(Object.keys(valueRef.current).length === 0) return;
            if(valueRef.current.id <= 0) return;

            try {
                await api.deliveries.updateDelivery(valueRef.current);
                onReload.current();
            } catch (e: any) {
                snack.error(e.toString())
            }
        }, 500);

        return () => clearTimeout(tm);
    }, [lastUpdate, snack, valueRef, onReload]);

    const ctrl = usePopout(deliveriesPopoutPanel);
    const hasWarning = !value?.hasEnoughInWarehouse || value?.isMissingAnchors;
    const [showPickedBy, setShowPickedBy] = useState(false);

    const userRequest = useGetAll((input) => api.users.list(input), {}, []);
    const users = userRequest.asList;
    const [pickedBy, setPickedBy] = useState("0");
    const usr = useUser();
    const userId = usr?.id || 0
    useEffect(() => {
        setPickedBy(userId.toString())
    }, [userId]);

    const [tab, setTab] = useQueryParam("t", "combined");
    const selection = useMemo(() => new EventEmitter<number[]>(), []);

    if(!value) return null;

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <form onSubmit={e => {
                e.preventDefault();
            }} style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <LogisticsItemHeader>
                    {showPickedBy && <Dialog open>
                        <DialogTitle>
                            Picked By
                        </DialogTitle>
                        <DialogContent>
                            <div style={{height: 8}} />
                            <Select style={{minWidth: 300}} value={pickedBy || "-"} onChange={(e) => {
                                // @ts-ignore
                                setPickedBy(e.target.value)
                            }}>
                                <MenuItem value="-">Choose Picker</MenuItem>
                                {users.map(u => <MenuItem value={u.id.toString()}>
                                    {u.name}
                                </MenuItem>)}
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                update("pickedByUser")(parseInt(pickedBy, 10));
                                setShowPickedBy(false)
                            }}>Save</Button>
                        </DialogActions>
                    </Dialog>}
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2}>
                            Pack #{value.id}
                        </Grid>
                        <Grid item xs={2}>
                            <Select2 disabled={true} label="Project" initialValue={value.project as any} initialDisplay={value.projectName}
                                     lookup={paginated2options("name", "id", search => api.projects.list({search: search, offset: 0}))}
                                     onChange={e => update("project")(e.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <Select2 label="Status"
                                      disabled={["draft", "to-pick", "picked"].indexOf(value.status) === -1}
                                     initialValue={value.status}
                                     lookup={select2list(deliveryStatuses)}
                                     onChange={e => {
                                         if(e.value === "picked" && value?.status !== "picked") {
                                             setShowPickedBy(true);
                                         }

                                         update("status")(e.value);
                                     }}
                              />
                        </Grid>
                        <Grid item xs={4}>
                            <DateField disabled={["draft", "to-pick", "picked"].indexOf(value.status) === -1}
                                       fullWidth nullable
                                       label="Pick By"
                                       initialValue={value.pickByDate}
                                       onChange={update("pickByDate")} />
                        </Grid>
                        {hasWarning && <Grid item>
                            {!value.hasEnoughInWarehouse && <Chip size="small" label="Missing Products" />}
                            {value.isMissingAnchors && <Chip color="warning" label="Frames are missing their anchors" />}
                        </Grid>}
                        <Grid item xs={2}>
                            <Button variant="outlined" fullWidth endIcon={<OpenInNewIcon />}
                                    onClick={() => {
                                        ctrl.open();
                                        ctrl.sendToClient({
                                            delivery: props.value?.id,
                                            project: props.value?.project,
                                        });
                                    }} disabled={!props.value || !isEditable(props.value)}>
                                To Pack
                            </Button>
                        </Grid>
                        <Grid item style={{fontSize: "0.8rem"}} xs={3}>
                            {value.pickedByUser ? <>
                                <div>Picked by {value.pickedByUserName}</div>
                                <div>Picked on {moment(value.pickedAt).format("MMM D [at] hh:mma")}</div>
                            </> : <>
                                Pick Pending
                            </>}
                        </Grid>
                    </Grid>
                </LogisticsItemHeader>
                <div style={{flex: 1, display: "flex"}}>
                </div>
            </form>
            <TabWrapper>
                <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab value="combined" label="Combined" />
                    <Tab value="items" label="Items" />
                    <Tab value="notes" label="Notes" />
                </Tabs>
            </TabWrapper>
            {tab === "combined" ?
                <DeliveryItems key="combined" delivery={value} selection={selection} onReload={onReload.current} combined /> :
            tab === "items" ?
                <DeliveryItems key="items" delivery={value} selection={selection} onReload={onReload.current} /> :
            tab === "notes" ?
                <DeliveryNotes id={value.id} /> : null
            }
            <ItemsFooter
                left={<BoxSetup selector={selection} delivery={value.id} />}
                delivery={value} />
        </div>
    )
}

export function isEditable(value: Delivery) {
    return value.status === "draft"
}
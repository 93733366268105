import {IconButton} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import {useState} from "react";
import {EditColumn} from "./EditColumn";
import {useTableRefresher} from "../../../../misc/scroller/Table";

export function AddColumn(props: {
    tableName: string;
    onChange(): void;
}) {
    const [show, setShow] = useState(false);
    const refresh = useTableRefresher(props.tableName)

    return (
        <div>
            <IconButton size="small" onClick={() => setShow(true)}>
                <AddCircle />
            </IconButton>
            {show && <EditColumn onCancel={() => setShow(false)} onDone={() => {
                refresh();
                props.onChange();
                setShow(false);
            }} />}
        </div>
    )
}
import {createContext} from "react";
import {ShopDrawing} from "./ShopDrawing";
import {useParams} from "react-router-dom";

export const ChangePreviewContext = createContext({
    enabled: false,
    sessionId: 0,
})

export function ShopDrawingChangePreview() {
    const params = useParams<{changesetId: string}>();
    const session = parseInt(params.changesetId || "0")

    return (
        <ChangePreviewContext.Provider value={{
            enabled: true,
            sessionId: session,
        }}>
            <ShopDrawing />
        </ChangePreviewContext.Provider>
    )
}
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {Button, ButtonProps} from "@mui/material";
import {forwardRef} from "react";

const buttonStyle = css({
    "&.MuiButton-root": {
        backgroundColor: grey["300"],
        color: grey["800"],
        fontWeight: "normal",
        textTransform: "none",
        paddingBottom: 0,
        paddingTop: 2,

        "&:hover": {
            backgroundColor: grey["500"],
            color: grey["900"],
        }
    }
})

export const SmallGreyButton = forwardRef(function SmallGreyButton(props: ButtonProps, ref: any) {
    return (
        <Button ref={ref} {...props} disableElevation className={buttonStyle} size="small" variant="contained" />
    )
});
import {useCallback, useState} from "react";

export function useRetry() {
    const [variable, setVariable] = useState(Date.now());

    const inMs = useCallback((ms: number) => {
        const tm = setTimeout(() => {
            setVariable(Date.now());
        }, ms);

        return () => {
            clearTimeout(tm);
        }
    }, [])

    return {
        inMs: inMs,
        variable: variable,
    }
}
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup, TextField,
    Typography
} from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import moment from "moment";
import {Link} from "react-router-dom";
import {formatCents, parseCents, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {Loader} from "../../../../misc/Loader";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {WasteReport} from "../../../../api/Logistics";
import {useState} from "react";

export function WasteReportItem(props: {
    value: WasteReport;
    onComplete(): void;
    onCancel(): void;
}) {
    const v = props.value;
    const onNextRef = useSyncedRef(props.onComplete);

    const act = useAsyncAction(async (input) => {

        const obj = Object.assign({}, input.value, {
            logisticsDirective: input.action,
            restockingFee: parseCents(input.restockingFee),
        });

        await api.logistics.updateWasteReport(obj);
        onNextRef.current();
    }, []);

    const [action, setAction] = useState(props.value.logisticsDirective);
    const [restockingFee, setRestockingFee] = useState(formatCents(v.extendedPrice) || "0.00");

    return (
        <div style={{padding: 16, backgroundColor: "white", display: "flex", flexDirection: 'column'}}>
            <div>
                <Button size="small" startIcon={<ChevronLeft/>} variant="outlined" onClick={props.onCancel}>Back to
                    List</Button>
            </div>
            <div style={{height: 16}}/>
            <Typography variant="h6">
                {v.productName}
            </Typography>
            <Typography>
                Supplied by {v.supplier}
            </Typography>
            <Typography>
                Reported by {v.createdByName} on {moment(v.createdAt).format("YYYY MMM D [at] H:mma")}
            </Typography>
            <Typography>
                <Link target="_blank" to={`/logistics/purchasing/${v.purchaseOrder}`}>PO #{v.poNumber}</Link>
            </Typography>

            <div style={{height: 24}}/>

            <FormControl>
                <FormLabel>What to do with product</FormLabel>
                <RadioGroup value={action} onChange={e => {
                    setAction(e.target.value)
                }}>
                    <FormControlLabel
                        value="warehouse"
                        control={<Radio/>}
                        label={<div>
                            <div>Accept delivery as inventory</div>
                            <div style={{fontSize: "0.8rem"}}>Item will be queued for route pickup and non-stocking
                                inventory item will created if an inventory type doesn't exist yet
                            </div>
                        </div>}
                    />

                    <FormControlLabel value="leave_at_supplier" control={<Radio/>} label={
                        <div>
                            <div>Leave with supplier/onsite</div>
                            <div style={{fontSize: "0.8rem"}}>
                                Assume we don't need to move this product and it's no longer our responsibility.
                            </div>
                        </div>
                    }/>
                </RadioGroup>
            </FormControl>

            <div style={{height: 24}}/>

            <div>
                <TextField
                    label="Restocking Fee (to customer)"
                    value={restockingFee}
                    onChange={e => setRestockingFee(e.target.value)}
                />

                <div style={{color: "red"}}>DISCUSS WHERE THIS IS USED</div>
            </div>

            <div style={{height: 24}}/>


            <div style={{height: 16}}/>
            <div>
                <Loader {...act} />
                <Button variant='contained' color='primary' onClick={() => {
                    act.callback({
                        value: props.value,
                        restockingFee,
                        action,
                    })
                }}>Submit</Button>
            </div>
        </div>
    );
}
import {useParams} from "react-router-dom";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Container} from "../../../misc/Container";
import {Loader} from "../../../misc/Loader";
import {EditPurchaseOrder} from "./EditPurchaseOrder";
import {useEffect} from "react";
import {purchasingPopoutPanel} from "./PurchaseOrderList";
import {WhiteColumn} from "./WhiteColumn";
import {PurchaseOrder as TPurchaseOrder} from "../../../api/Logistics";

export function poURL(id: number) {
    return "/logistics/purchasing/" + id;
}

export function isEditable(input: TPurchaseOrder) {
    return !(input.status === 'submitted' || input.status === 'complete' || input.status === 'ready-for-pickup' || input.status === 'backorder')
}

export function PurchaseOrder() {
    const params = useParams<{id: string}>();
    const id = parseInt(params["id"] || "0")
    const data = useAsync2(input => api.logistics.getPO(input), {id: id}, [id]);
    const poIsEditable = data.result && isEditable(data.result);

    useEffect(() => {
        if(!poIsEditable) return;

        purchasingPopoutPanel.open();

        return () => {
            purchasingPopoutPanel.close();
        }
    }, [poIsEditable]);

    return (
        <Container>
            <WhiteColumn>
            <Loader {...data}>
                {value => <EditPurchaseOrder value={value} onReload={data.reload} />}
            </Loader>
            </WhiteColumn>
        </Container>
    )
}
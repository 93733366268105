import {Button, Dialog, TextField} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useMemo, useState} from "react";

export function LoginPage() {
    const loc = useLocation();

    const redirectTo = useMemo(() => {
            const sr = new URLSearchParams(loc.search);
            return sr.get("r") || "/";
    }, [loc.search]);
    const action = useAsyncAction(async (input) => {
        await api.users.login(input);

        // @ts-ignore
        window.location = redirectTo;
    }, [redirectTo]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return <Dialog open>
        <div style={{padding: 32, paddingLeft: 16, paddingRight: 16, display: "flex", flexDirection: "column", alignItems: "center"}}>
            <img src="/images/handle.png" style={{width: 150}}  alt="Logo" />
            <form style={{display: "block", width: 250}} onSubmit={e => {
                e.preventDefault();
                action.callback({
                    username: email,
                    password: password,
                })
            }}>
                <div style={{height: 16}} />
                <TextField label="Email" autoComplete="username" fullWidth onChange={e => setEmail(e.target.value)} />
                <div style={{height: 8}}/>
                <TextField label="Password" autoComplete="current-password" type="password" fullWidth onChange={e => setPassword(e.target.value)} />
                <div style={{height: 16}}/>
                <Button type="submit" variant="contained" fullWidth>Login</Button>
                {action.LoadingElement}
                <div style={{height: 12}}/>
                <Link to="/forgot-password" style={{color: grey["600"]}}>Forgot Password</Link>
            </form>
        </div>
    </Dialog>
}
import {forwardRef, useMemo} from "react";
import {useUser} from "../../../../misc/Permission";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {PDFProjectContext} from "../../shopdrawing/openings/OpeningsPDF";
import {Company} from "../../../../api/Companies";
import {Project} from "../../../../api/Projects";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {CoverPage} from "../../../../misc/pdf/parts/CoverPage";
import {HwScheduleFinishesPage} from "./HwScheduleFinishesPage";
import {HardwareGroupWithContent} from "../../../../api/Hardware";
import {PDFInfo} from "../../../../misc/pdf/PrintManager";
import {Page} from "../../../../misc/pdf/parts/Page";
import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import * as React from "react";
import {rebuildSplitPrefItems, SourceItem} from "../../../../misc/pdf/parts/RebuildSplitPref";

export function hardwareSchedulePDF(props: {
        lastSubmittal: string | null;
        project: Project;
        client?: Company;
        hardware: HardwareGroupWithContent[]
    }): PDFInfo {

    const products: number[] = [];
    const finishObj: {[k: string]: string} = {};
    const mfgObj: {[k: string]: string} = {};

    props.hardware.map(h => h.hardware.map(item => {
        if(!products.includes(item.product)) {
            products.push(item.product);
        }

        if(item.finish && item.finishName) {
            finishObj[item.finish] = item.finishName;
        }

        if(item.manufacturerShortName && item.manufacturerName) {
            mfgObj[item.manufacturerShortName] = item.manufacturerName
        }
    }))

    const finishes = Object.keys(finishObj).map(f => {
        return {
            key: f,
            value: finishObj[f]
        }
    })

    const manufacturers = Object.keys(mfgObj).map(m => {
        return {
            key: m,
            value: mfgObj[m]
        }
    });

    return {
        name: "hardware-schedule.pdf",
        render: async onReady => {
            return <HardwareSchedulePDF
                finishes={finishes}
                manufacturers={manufacturers}
                hardware={props.hardware}

                project={props.project}
                lastSubmittal={props.lastSubmittal}
                client={props.client} onReady={onReady} />
        },
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "hardware-template",
            appendCatalogPagesForProducts: products,
        }
    }
}

export type KeyValue = {
    key: string;
    value: string;
}



type RenderKey = "header" | "hardware"| "opening" | "doorName"

export const HardwareSchedulePDF = forwardRef(function (props: {
    project: Project;

    manufacturers: KeyValue[];
    finishes: KeyValue[];
    hardware: HardwareGroupWithContent[];

    client?: Company;
    lastSubmittal: string|null;
    onReady(): void;
}, ref) {
    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    const rows = useMemo(() => {
        let list: SourceItem<HardwareGroupWithContent, RenderKey>[] = [];
        props.hardware.map(h => {
            list.push({
                type: "header",
                index: 0,
                obj: h,
            })

            h.openings.map((o, index) => {
                list.push({
                    type: "opening",
                    index: index,
                    obj: h,
                })
            })

            list.push({
                type: "doorName",
                index: 0,
                obj: h,
            })

            h.hardware.map((o, index) => {
                list.push({
                    type: "hardware",
                    index: index,
                    obj: h,
                })
            })
        })

        return list
    }, [props.hardware])

    return (
        <div ref={ref as any}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <CoverPage
                        client={props.client}
                        title="HARDWARE SCHEDULE SUBMITTAL"
                        lastSubmittal={props.lastSubmittal}
                        portrait
                    />
                    <HwScheduleFinishesPage manufacturers={props.manufacturers} finishes={props.finishes} />

                    <PageRenderProvider data={rows} onReady={ready.section()} preferCombinedKey={row => row.obj.id.toString()}>
                        {(info: DataSplitInfo<SourceItem<HardwareGroupWithContent, RenderKey>>) => (
                            <Page orientation="portrait">
                                <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", width: "100%"}}>
                                {rebuildSplitPrefItems<HardwareGroupWithContent, RenderKey>(info.rows)
                                    .map((r, index) => {
                                        return (
                                            <React.Fragment key={"frag-" + index.toString()}>
                                                {index !== 0 && <div style={{height: 20}} />}
                                                {r.header && <div style={{fontWeight: "bold", fontSize: "1.1rem", paddingTop: 10, paddingBottom: 10}}>Heading {r.obj.name}</div>}
                                                <table className={pdfTable} style={{width: "100%"}} key={"tbl-" + index.toString()}>
                                                    {r.opening && r.opening.slice(r.obj.openings)
                                                        .map(o => {
                                                            return (
                                                                <tr key={"opening" + o.id}>
                                                                    <td colSpan={6} style={{fontWeight: "bold"}}>
                                                                        <div style={{display: "flex", flexWrap: "nowrap"}}>
                                                                            <div style={{flex: 1}}>{o.descriptiveName}</div>
                                                                            <div>
                                                                                {o.handing}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    {r.doorName && <tr key="door">
                                                        <td colSpan={6} style={{fontWeight: "bold"}}>
                                                            {r.obj.doorName}
                                                        </td>
                                                    </tr>}

                                                    {r.hardware && r.hardware.slice(r.obj.hardware)
                                                        .map(h => {
                                                            const list = h.calcByDimension && h.calcByDimension.length > 0 ? h.calcByDimension : [{dimension: "", qty: h.calcTotalQty}];

                                                            return list.map(l => {
                                                                return (
                                                                    <tr key={h.id.toString() + l.dimension}>
                                                                        <td style={{width: 50}}>{l.qty}</td>
                                                                        <td style={{width: 250}}>{h.name}{h.customerSupplied ? " by others" : ""}</td>
                                                                        <td>{h.productCode}</td>
                                                                        <td style={{width: 100}}>{l.dimension}</td>
                                                                        <td style={{width: 50}}>{h.finish}</td>
                                                                        <td style={{width: 50}}>{h.manufacturerShortName}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        })}
                                                </table>
                                            </React.Fragment>
                                        )
                                })}
                                </div>
                            </Page>
                        )}
                    </PageRenderProvider>
                </PDFProjectContext.Provider>
            </PDF>
        </div>
    )
});

export const pdfTable = css({
    width: "100%",
    borderSpacing: 0,
    borderCollapse: "collapse",

    "& > * > * > *": { // all cells
        paddingLeft: 4,
        paddingRight: 4,
    },

    "& > tbody > * > *": {
        border: "1px solid " + grey["700"],
    },

    "& .pdf-header": {
        fontWeight: "bold",
        fontSize: "0.8rem",
    },
})
import {DeliveryBox, DeliveryItem} from "../../../../api/Deliveries";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    Select, TextField
} from "@mui/material";
import {useState} from "react";
import {Close} from "@mui/icons-material"
import {css} from "@emotion/css";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";

export function UpsertBox(props: {
    box?: DeliveryBox;
    delivery: number;
    rows: DeliveryItem[]
    onClose(doRefresh?: boolean): void;
}) {
    const [boxType, setBoxType] = useState<string>(props.box?.boxType || "-");
    const [label, setLabel] = useState<string>(props.box?.label || "");

    const update = useAsyncAction(async (input) => {
        await api.deliveries.updateBox(input)
        props.onClose(true);
    }, []);

    const [rows, setRows] = useState<DeliveryItem[]>(props.rows);

    return (
        <Dialog open>
            <DialogTitle>
                Box Detail
            </DialogTitle>
            <form onSubmit={e => {
                e.preventDefault()
                update.callback(Object.assign({}, props.box, {
                    delivery: props.delivery,
                    boxType: boxType,
                    label: label,
                    setItems: rows.map(row => row.id),
                }))
            }}>
                <DialogContent>
                    <TextField
                        label="Box Label"
                        value={label}
                        onChange={e => setLabel(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                    <div style={{height: 16}}/>
                    <Select size="small" style={{minWidth: 300}} fullWidth value={boxType} onChange={e => setBoxType(e.target.value)}>
                        <MenuItem value="-">Choose Box Type</MenuItem>
                        <MenuItem value="Pallet">Pallet</MenuItem>
                        <MenuItem value="Box">Box</MenuItem>
                    </Select>

                    <div style={{height: 16}}/>

                    <div style={{marginLeft: -24, marginRight: -24}}>
                        <Divider/>
                    </div>

                    <div style={{height: 8}}/>

                    <div style={{marginLeft: -24, marginRight: -24}}>
                        <table className={tableCss} style={{width: "100%"}}>
                            <thead>
                            <tr>
                                <td>
                                   Description
                                </td>
                                <td>
                                    Code
                                </td>
                                <td>
                                    Manufacturer
                                </td>
                                <td>
                                    Qty
                                </td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map(row => (
                                <tr key={row.id}>
                                    <td>{row.description}</td>
                                    <td>{row.code}</td>
                                    <td>{row.manufacturerName}</td>
                                    <td>{row.qty}</td>
                                    <td>
                                        <IconButton disableRipple size="small" onClick={() => {
                                            setRows(rows.filter(r => r.id !== row.id))
                                        }}>
                                            <Close/>
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    {update.LoadingElement}
                    <Button onClick={() => props.onClose()}>Cancel</Button>
                    <Button disabled={boxType === "-" || rows.length === 0} variant="contained" color="primary" type="submit">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

const tableCss = css({
    "& td": {
        paddingLeft: 6,
        paddingRight: 6,
        fontSize: "0.9rem",
    },
    "& td:first-child": {
        paddingLeft: 24,
    }, "& td:last-child": {
        paddingRight: 16,
    },
    "& thead td": {
        fontWeight: "bold",
        fontSize: "0.8rem",
    }
})
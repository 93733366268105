import {PopoverProps, Popover as MPopover} from "@mui/material";
import {useEffect} from "react";

export function Popover(props: PopoverProps) {
    useEffect(() => {
        if(!props.open) return;
        // trigger fake resize event to get the popover to reposition correctly
        // timeout is related to expected transition time
        const tm = setTimeout(() => window.dispatchEvent(new Event("resize")), 400);

        return () => clearTimeout(tm);
    }, [props.open])

    return <MPopover {...props} />
}
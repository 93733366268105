import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Tab, Tabs
} from "@mui/material";
import {useState} from "react";
import {ProjectManagement} from "./changeHistory/ProjectManagement";
import {Logistics} from "./changeHistory/Logistics";
import {StatusCol} from "./Openings";

export function ChangeHistory(props: {
    opening?: {
        id: number;
        name: string;
    };

    miscProduct?: {
        id: number;
        name: string;
    };

    inventoryRequest?: {
        id: number;
        status: string;
        qty: number;
    };

    onDone(): void;
}) {

    const [tab, setTab] = useState("logistics");
    const ir = props.inventoryRequest;

    return (
        <Dialog open onClose={props.onDone}>
            <DialogTitle>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{flexGrow: 1}}>
                        {props.inventoryRequest ? "Req #" + props.inventoryRequest.id : (props.opening?.name || props.miscProduct?.name)} Change History
                    </div>
                    {ir && <div style={{fontSize: "0.8rem", fontWeight: "normal", display: "flex", flexDirection: "column", alignItems: "flex-end", paddingLeft: 12}}>
                        <div style={{position: 'relative', paddingLeft: 6, paddingRight: 6, borderRadius: 4, overflow: "hidden"}}>
                            {StatusCol("Status", "status").render(ir)}
                        </div>
                        <div style={{height: 2}} />
                        <div>
                            {ir.qty} items
                        </div>
                    </div>}
                </div>
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
            <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                    <Tab label="Logistics" value="logistics" />
                    <Tab label="Project Management" value="project" />
                </Tabs>
                <Divider />
                {tab === "logistics" ?
                    <Logistics opening={props.opening?.id} miscProduct={props.miscProduct?.id} inventoryRequest={props.inventoryRequest?.id} /> :
                    <ProjectManagement opening={props.opening?.id} miscProduct={props.miscProduct?.id} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDone}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
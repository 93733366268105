import React, {createContext, PropsWithChildren, useMemo, useState} from "react";
import {Opening} from "../../../../api/Openings";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {Button, Card, Popper} from "@mui/material";
import {orange} from "@mui/material/colors";
import { PopperProps } from '@mui/material/Popper';
import {errShowNoSnackInTable} from "../../../../misc/scroller/EditProvider";


export const BreakHardwareLinkContext = createContext({
    onError: async <T extends (Opening | QuoteOpening)>(obj: T, e: Error, onRetry: (input: T) => Promise<any>): Promise<any> => {
        throw e;
    }
})

type ShowInfo = {
    error: string;
    opening: Opening|QuoteOpening;
    cancel(): void;
    approve(): void;
}

export function BreakHardwareLinkProvider(props: PropsWithChildren<{}>) {
    const [show, setShow] = useState<ShowInfo|null>(null)

    const ctx = useMemo(() => {
        return {
            onError: async <T extends (Opening | QuoteOpening)>(obj: T, e: Error, onRetry: (input: T) => Promise<any>) => {
                if(e.toString().toLowerCase().indexOf("hardware group") === -1) {
                    return Promise.reject(e);
                }

                return new Promise<any>((resolve, reject) => {
                    setAnchorEl({
                        getBoundingClientRect: () => {
                            const body = document.body.getBoundingClientRect();
                            const bottom = 50;
                            const left = 10;

                            return {
                                top: body.top + body.height - bottom,
                                left: body.left + left,
                                right: body.right + left,
                                bottom: bottom,
                                width: 1,
                                height: 1,
                            } as DOMRect;
                        }
                    });

                   setShow({
                          error: e.toString(),
                          opening: obj,
                          cancel: () => {
                            reject(new Error(errShowNoSnackInTable));
                          },
                          approve: async () => {
                              try {
                                  const updated = Object.assign({}, obj, {
                                      hardwareGroup: null,
                                  })

                                  const result = await onRetry(updated);
                                  resolve(result);
                              } catch (e) {
                                  reject(e);
                              }
                          }
                   })
                });
            }
        }
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<PopperProps['anchorEl']>(null);


    return (
        <BreakHardwareLinkContext.Provider value={ctx}>
            {props.children}
            {show && <Popper open placement="bottom-start"
                             anchorEl={anchorEl}
                             style={{zIndex: 14}}
            >

                <Card style={{padding: 16, backgroundColor: orange["600"], zIndex: 1000, color: "white", fontWeight: "500"}}>
                    <div>
                        {show.error}
                    </div>

                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button style={{color: "white"}} onClick={() => {
                            show?.cancel();
                            setShow(null);
                        }}>Ignore</Button>
                        <Button style={{color: "white"}} onClick={() => {
                            show?.approve();
                            setShow(null);
                        }}>Unlink Hardware Group</Button>
                    </div>
                </Card>
            </Popper>}
        </BreakHardwareLinkContext.Provider>
    )
}
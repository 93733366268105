import {hasPermission, useUser} from "../../../../misc/Permission";
import {
    Table,
} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {
    AddRider,
    commonGroupBy2,
    commonPricingColumns2,
    getPricing, priceChange,
    PriceCol,
    PriceLine2,
    PriceRow,
    proposingGroupBy2, RiderRowTitle, ViewPriceCol
} from "./Frames";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {QuotePriceRider} from "../../../../api/QuotePricing";
import {DoorSectionProvider} from "./DoorSectionProvider";

export function Doors(props: {
    type: "hm" | "wd" | "other"
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const proposal = useProposingKit()
    const changesetPreview = useContext(ChangePreviewContext);

    if(!u) return null;

    const isWood = props.type === "wd"

    return (
        <DoorSectionProvider value={props.type}>
            <Table<PriceRow>
                key={props.type}
                name={priceTableName(project, "door", props.type)}
                globalPrefsName={priceTableName(0, "door", props.type)}
                locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
                cellCustomize={proposal.cellCustomize}
                fetch={async (ctx) => getPricing(ctx, {
                    project,
                    previewChangeset: changesetPreview.enabled ? changesetPreview.sessionId : undefined,
                    transformRows: proposal.transformRows,
                })}

                columns={[
                    PriceCol<PriceLine2>({
                        name: "",
                        price: o => proposal.rowAction(o, "door", ""),
                        rider: (dt: QuotePriceRider, row: PriceRow) => {
                            return <RiderRowTitle row={row} />
                        },
                        createRider: (row) => {
                            return <AddRider row={row} />
                        },
                        width: 150,
                    }),
                    proposal.proposing && PriceCol<PriceLine2>({
                        name: "Opening",
                        price: d => {
                            return proposal.renderItemName(d, "openingName")
                        },
                        width: 150,
                    }),
                    PriceCol<PriceLine2>({
                        name: "Series",
                        price: dt => {
                            if(dt.productType === "door-prep") return `${dt.productName}: ${dt.productCode}`;
                            return dt.door.series;
                        },
                        width: 150,
                    }),
                    ViewPriceCol("Material", "door.material"),
                    isWood ? ViewPriceCol("Finish", "door.finish") : null,
                    ViewPriceCol("Gauge", "door.gauge"),
                    ViewPriceCol("Elevation", "door.elevation"),
                    ViewPriceCol("Core", "door.core"),
                    ViewPriceCol("Seam", "door.seam"),
                    ViewPriceCol("Handing", "door.handing"),
                    ViewPriceCol("Label", "door.label"),
                    ViewPriceCol("Height", "door.height"),
                    ViewPriceCol("Width", "door.width"),
                    ViewPriceCol("Thickness", "door.thickness"),
                    ...commonPricingColumns2(u, false, proposal.proposing),
                ]}
                fetchDeps={[project, proposal.proposing, proposal.expanded, changesetPreview.enabled, changesetPreview.sessionId]}
                columnDeps={[props.type, u, proposal.proposing]}
                groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy2 : commonGroupBy2, {
                    groupFilter: (groupName: string) => {
                        return groupName === "door-" + props.type || groupName.startsWith("door-" + props.type + "/") || groupName === "project"
                            || groupName.startsWith("door-prep/" + props.type)
                    },
                })}
                onChange={(input) => priceChange(input, {
                    processUpdate: proposal.processUpdate,
                    project: project,
                })}
            />
        </DoorSectionProvider>
    )
}

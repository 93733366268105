import {Button, Dialog, TextField} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {grey} from "@mui/material/colors";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useMemo, useState} from "react";

export function ResetPasswordPage() {

    const [done, setDone] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const loc = useLocation();
    const code = useMemo(() => {
        const params = new URLSearchParams(loc.search);
        return params.get("c") || "";
    }, [loc.search])

    const check = useAsync2((input) => api.users.checkPasswordReset(input), {code: code}, [code])
    const reset = useAsyncAction(async input => {
        await api.users.resetPassword(input);
        setDone(true);
    }, []);

    if(check.loadingOrError) {
        return (
            <Dialog open>
                {check.LoadingOrErrorElement}
            </Dialog>
        )
    }

    return <Dialog open>
        <div style={{padding: 32, paddingLeft: 16, paddingRight: 16, display: "flex", flexDirection: "column", alignItems: "center"}}>
            <img src="/images/handle.png" style={{width: 150}}  alt="logo" />
            {done ? <div style={{width: 300, paddingTop: 30}}>
                Your password has been updated.
                <div style={{height: 16}}/>
                <Link to="/login">Login</Link>
            </div> : <form style={{display: "block", width: 250}} onSubmit={e => {
                e.preventDefault();
                reset.callback({
                    email: email,
                    password: password,
                    code: code,
                });
            }}>
                <div style={{height: 16}} />
                <TextField label="Code" disabled value={code} fullWidth />
                <div style={{height: 16}}/>
                <TextField label="Email" onChange={e => setEmail(e.target.value)} autoComplete="username" fullWidth />
                <div style={{height: 16}}/>
                <TextField label="Password" type="password" onChange={e => setPassword(e.target.value)} autoComplete="new-password" fullWidth />
                <div style={{height: 16}}/>
                <Button type="submit" variant="contained" fullWidth>Update</Button>
                {reset.LoadingElement}
                <div style={{height: 12}}/>
                <Link to="/login" style={{color: grey["600"]}}>Back to Login</Link>
            </form>}
        </div>
    </Dialog>
}
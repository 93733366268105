import {CSSProperties, forwardRef, useMemo} from "react";
import {Page, pdfTableStyleNoAlternating} from "../../../../misc/pdf/parts/Page";
import moment from "moment/moment";
import {PriceLine, PriceRider} from "../../../../api/Pricing";
import {first, formatCents, orderByAscending, sum} from "nate-react-api-helpers";
import { grey } from "@mui/material/colors";
import {PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {PDFInfo} from "../../../../misc/pdf/PrintManager";
import {QuoteMiscPrice} from "../../../../api/QuoteMiscPricing";
import {PriceSectionNote} from "../../../../api/QuotePriceSectionNotes";
import {Project} from "../../../../api/Projects";
import {Company} from "../../../../api/Companies";
import {DescriptionInput, formatDescription} from "../../../logistics/purchasing/toSource/SubmitButton";
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import {PDFHeader2} from "../../../../misc/pdf/parts/PDFHeader2";
import {Grid} from "@mui/material";
import {doorCore, hollowMetalDoor, woodDoor} from "../openings/QuoteOpenings";
import {div10TabKeys, Div10TypeToName} from "../../shopdrawing/div10/Div10Tabs";
import {dummySelectWrapper} from "../../../../misc/scroller/Table";
import {MiscPrice} from "../../../../api/MiscPricing";
import {PriceLine2} from "../../shopdrawing/pricing/Frames";

export function getQuotePDF(props: {
    project: number;
    data: () => Promise<QuotePDFData>;
}): PDFInfo {
    return {
        name: `quote.pdf`,
        render: async onReady => {
            const data = await props.data();

            return <QuotePDF
                project={props.project}
                data={data}
                onReady={onReady}
            />
        },
        serverInfo: {
            submissionForProject: props.project,
            submissionType: "quote",
        }
    }
}

export type SubCategories = {
    name: string;
    filter: (i: PriceLine|QuotePriceLine) => boolean;
    filterRider: (i: PriceRider|QuotePriceRider) => boolean;
    children?: SubCategories[];
}

export type Item = {
    type: 'title';
    value: string;
    group: string;
} | {
    type: "section-meta";
    value: string;
    group: string;
    rows: (PriceLine[] | PriceLine2[] | QuotePriceLine[]);
    riders: QuotePriceRider[]
    categories: SubCategories[]
    totalCents: number;
} | {
    type: "row",
    value: PriceLine | QuotePriceLine;
    group: string;
} | {
    type: "footer",
    notes: string[];
    totalCents: number;
    group: string;
} | {
    type: "total",
    totalCents: number;
    group: string | undefined;
} | {
    type: "generalNoteSection",
    value: string
    isFirst: boolean;
    group: string | undefined;
}

export const deepBlue = "#1F3864"

function replaceMap(value: string|null, obj: {[k: string]: string}) {
    if(!value) return value;
    const rpl = obj[value];
    if(rpl) {
        return rpl;
    }

    return value;
}

export function humanizeDoorCore(core: string | null, series: string | undefined | null) {
    const cores = doorCore(dummySelectWrapper({
        doorSeries: series || "",
    }))

    return replaceMap(core, {
        "HC": cores.find(c => c?.value === "HC")?.description || "HC",
        "PART": "Solid Core",
        "MIN": "Mineral Core",
    })
}

export function toDescriptionInput(input: PriceLine|QuotePriceLine): DescriptionInput {

    let material = input.door?.material || null
    if(material && input.door?.series === woodDoor) {
        if(material === "Hardboard") {
            // do nothing
        } else {
            material = material + " Veneer";
        }
    } else if(input.door?.series === hollowMetalDoor) {
        material = "";
    }

    return {
        doorCore: humanizeDoorCore(input.door?.core || null, input.door?.series),
        doorLabel: input.door?.label || null,
        doorElevation: input.door?.elevation || null,
        doorThickness: input.door?.thickness || null,
        doorMaterial: material,
        doorSeries: input.door?.series || null,
        doorGauge: input.door?.gauge || null,
        doorSeam: input.door?.seam || null,

        frameSeries: input.frame?.series || null,
        frameConstruction:  input.frame?.construction || null,
        frameType: input.frame?.type || null,
        frameMaterial: input.frame?.material || null,
        frameHanding: input.frame?.handing || null,
        frameLabel: input.frame?.label || null,
        frameGauge: input.frame?.gauge || null,
        frameScreenElev: input.frame?.screenElev || null,
        frameJambDepth: input.frame?.jambDepth || null,

        div10Category: input.div10?.category || null,
        div10LockerTiers: input.div10?.lockerTiers || null,
        div10LockerCoating: input.div10?.lockerCoating || null,
        div10LockerSloped: input.div10?.lockerSloped || null,
        div10LockerEndPanels: input.div10?.lockerEndPanels || null,
        div10MailboxLoading: input.div10?.mailboxLoading || null,
        div10MailboxMounting: input.div10?.mailboxMounting || null,
        div10MailboxTenantUnits: input.div10?.mailboxTenantUnits || null,
        div10MailboxParcelUnits: input.div10?.mailboxParcelUnits || null,
        div10PartitionBracing: input.div10?.partitionBracing || null,
        div10PartitionCoating: input.div10?.partitionCoating || null,
        div10Description: input.div10?.description || null,
        div10Note: input.div10?.note || null,

        description: input.productType === "frame-anchor" ? "Anchor: " + input.productName : input.productName,
        productCode: input.productCode,
        finish: input.finish,
        dimensions: input.productDimensions,
        dimWidth: input.dimWidth,
        dimHeight: input.dimHeight,
        dimLength: input.dimLength,
    }
}

export type QuotePDFData = {
    prices: (PriceLine[]|QuotePriceLine[])
    riders: QuotePriceRider[];
    miscPrices: (QuoteMiscPrice[]|MiscPrice[])
    notes: PriceSectionNote[];
    project: Project;
    contractor: Company|null;
    currentQuoteNumber: string;
}

export function fixShortForms(value: string): string {
    const list = [
        {search: /( |^)(KD)( |$)/g, value: "Knockdown"},
        {search: /( |^)(WELD)( |$)/g, value: "Welded"},
        {search: /( |^)(HC)( |$)/g, value: "Honeycomb"},
        {search: /( |^)(PS)( |$)/g, value: "Insulated"},
    ]

    list.map(v => {
        value = value.replace(v.search, (match: string, p1: string, p2: string, p3: string) => {
            return p1 + v.value + p3;
        });
    })

    return value;
}

export const subHeaderCss: CSSProperties = {
    fontWeight: "bold",
    color: deepBlue,
    textTransform: 'uppercase',
    fontSize: 12,
    borderBottom: "1px solid " + grey["500"],
    paddingBottom: 2,
    marginBottom: 2,
}

export function useData(props: {
    prices: (PriceLine[])|(QuotePriceLine[])
    notes: PriceSectionNote[];
    miscPrices: (QuoteMiscPrice[]|MiscPrice[]);
    riders: (QuotePriceRider[])|(PriceRider[]);
}) {
    return useMemo(() => {
        return getDataInner({
            prices: props.prices,
            notes: props.notes,
            miscPrices: props.miscPrices,
            riders: props.riders,
        });
    }, [props.prices, props.notes, props.miscPrices, props.riders])
}

export function getDataInner(props: {
    prices: (PriceLine[])|(QuotePriceLine[])
    notes: PriceSectionNote[];
    miscPrices: (QuoteMiscPrice[]|MiscPrice[]);
    riders: QuotePriceRider[];
}) {
    let lastGroup: string | undefined = undefined;

    const out: Item[] = [];
    let totalCents = 0;
    let ridersTouched: QuotePriceRider[] = [];

    function addSection(input: {title: string, note: string[], subTotal?: number,
        categories: SubCategories[]
        includeIfNoRows?: boolean,
    }, rows: PriceLine[]|QuotePriceLine[], riders: QuotePriceRider[]) {
        console.log("section", input, rows);
        if(rows.length === 0 && !input.includeIfNoRows) return;

        lastGroup = input.title;

        let price = input.subTotal || sum(rows.map(r => r.extendedPrice));
        price += riders.reduce((acc, r) => acc + r.extendedPrice, 0);

        ridersTouched.push(...riders);

        out.push({
            type: "title",
            value: input.title,
            group: input.title,
        })

        out.push({
            type: "section-meta",
            value: input.title,
            group: input.title,
            rows: rows,
            riders: riders,
            categories: input.categories,
            totalCents: price,
        })

        out.push(...rows
            .filter((r: PriceLine|QuotePriceLine|PriceLine2) => r.productType !== "frame-prep" && r.productType !== "door-prep")
            .map(r => ({
                type: "row" as "row",
                value: r,
                group: input.title,
            } as Item)))

        if(rows.length > 0 && !!input.subTotal) throw new Error("Can't have both rows and subTotal");

        totalCents += price;
        out.push({
            type: "footer",
            totalCents: price,
            notes: input.note,
            group: input.title,
        })

    }

    const prices = props.prices as PriceLine[];
    const miscPrices = props.miscPrices;
    const notes = props.notes.filter(v => !!v.note);

    const hmNotes = notes.filter(n => n.keyName === "door" || n.keyName === "frame");
    orderByAscending(hmNotes, i => i.keyName === "frame" ? 0 : 1)

    const isHmDoor = (input: PriceLine|QuotePriceLine) => input.productType === "door" && input.door.series !== woodDoor;
    const isHmDoorRider = (input: PriceRider|QuotePriceRider) => input.productType === "door" && input.sectionKey !== "door-wd";
    const isHmDoorPrep = (input: PriceLine|QuotePriceLine) => input.productType === "door-prep" && input.doorSeries !== woodDoor;
    const isHmDoorPrepRider = (input: PriceRider|QuotePriceRider) => input.productType === "door-prep" && input.sectionKey !== "door-prep/wd";
    const isHmFrame = (input: PriceLine|QuotePriceLine) => input.productType === "frame";
    const isHmFrameRider = (input: PriceRider|QuotePriceRider) => input.productType === "frame";
    const isHmFramePrep = (input: PriceLine|QuotePriceLine) => input.productType === "frame-prep";
    const isHmFramePrepRider = (input: PriceRider|QuotePriceRider) => input.productType === "frame-prep";
    const isHmFrameAnchor = (input: PriceLine|QuotePriceLine) => input.productType === "frame-anchor";
    const isHmFrameAnchorRider = (input: PriceRider|QuotePriceRider) => input.productType === "frame-anchor"

    const hmPrices = prices.filter(i =>
        isHmDoor(i) ||
        isHmFrame(i) ||
        isHmFramePrep(i) ||
        isHmFrameAnchor(i) ||
        isHmDoorPrep(i)
    );
    orderByAscending(hmPrices, i => i.productType === "frame" ? 0 : 1)

    const hmRiders = props.riders.filter(r =>
        isHmDoorRider(r) ||
        isHmFrameRider(r) ||
        isHmFramePrepRider(r) ||
        isHmFrameAnchorRider(r) ||
        isHmDoorPrepRider(r)
    );

    addSection({
            title: "Hollow Metal",
            note: hmNotes.map(n => n.note),

            categories: [{
                name: "Doors",
                filter: (i: PriceLine|QuotePriceLine) => isHmDoor(i) || isHmDoorPrep(i),
                filterRider: (i: PriceRider|QuotePriceRider) => isHmDoorRider(i) || isHmDoorPrepRider(i),

                children: [{
                    name: "Doors",
                    filter: isHmDoor,
                    filterRider: isHmDoorRider,
                }, {
                    name: "Door Preps",
                    filter: isHmDoorPrep,
                    filterRider: isHmDoorPrepRider,
                }]
            }, {
                name: "Frames",
                filter: (i: PriceLine|QuotePriceLine) => isHmFrame(i) || isHmFramePrep(i) || isHmFrameAnchor(i),
                filterRider: (i: PriceRider|QuotePriceRider) => isHmFrameRider(i) || isHmFramePrepRider(i) || isHmFrameAnchorRider(i),
                children: [
                    {
                        name: "Frames",
                        filter: isHmFrame,
                        filterRider: isHmFrameRider,
                    }, {
                        name: "Frame Preps",
                        filter: isHmFramePrep,
                        filterRider: isHmFramePrepRider,
                    }, {
                        name: "Frame Anchors",
                        filter: isHmFrameAnchor,
                        filterRider: isHmFrameAnchorRider,
                    }
                ]
            }],
        },
        hmPrices,
        hmRiders
    )

    const isWoodDoor = (input: PriceLine|QuotePriceLine) => input.productType === "door" && input.door.series === woodDoor;
    const isWoodDoorRider = (input: PriceRider|QuotePriceRider) => input.productType === "door" && input.sectionKey === "door-wd";
    const isWoodDoorPrep = (input: PriceLine|QuotePriceLine) => input.productType === "door-prep" && input.doorSeries === woodDoor;
    const isWoodDoorPrepRider = (input: PriceRider|QuotePriceRider) => input.productType === "door-prep" && input.sectionKey === "door-prep/wd";
    const wdPrices = prices.filter(i =>
        isWoodDoor(i) ||
        isWoodDoorPrep(i)
    );

    const wdRiders = props.riders.filter(r => isWoodDoorRider(r) || isWoodDoorPrepRider(r));

    if(wdPrices.length > 0) {
        addSection(
            {
                title: "Wood Doors",
                note: notes.filter(n => n.keyName === "door-wd" || n.keyName === "door/wd").map(n => n.note),
                categories: [{
                    name: "Door",
                    filter: isWoodDoor,
                    filterRider: isWoodDoorRider,
                }, {
                    name: "Door Preps",
                    filter: isWoodDoorPrep,
                    filterRider: isWoodDoorPrepRider,
                }],
            },
            wdPrices, wdRiders);
    }

    const hardware = prices.filter(v => v.productType === "hardware");
    const hardwareRiders = props.riders.filter(v => v.productType === "hardware");
    addSection(
        {
            title: "Finishing Hardware",
            note: notes.filter(n => n.keyName === "hardware").map(v => v.note),
            categories: [],
        }, hardware, hardwareRiders)

    div10TabKeys.map(k => {
        const riders = props.riders.filter(r => r.productType === "div-10" && r.sectionKey === "div-10/" + k);

        addSection(
            {
                title: "Div 10: " + Div10TypeToName(k),
                note: notes.filter(n => n.keyName === "div10/" + k).map(v => v.note),
                categories: [],
            },
                (prices as PriceLine[]).filter(i => {
                    if(k === "wr-accessory") return i.productType === "div-10" && !i.div10;
                    return i.productType === "div-10" && i.div10?.category === k
                }),
                riders,
            )
    })

    miscPrices.map(m => {
        if(!m.publicNote && !m.extendedPrice) return;

        addSection({
            title: m.name,
            note: [m.publicNote],
            includeIfNoRows: true,
            subTotal: m.extendedPrice,
            categories: []
        }, [], [])
    })

    const skippedRiders = props.riders.filter(v => !ridersTouched.includes(v));
    if(skippedRiders.length > 0) {
        throw new Error("Skipped riders: " + skippedRiders.map(v => v.sectionKey).join(", "));
    }

    out.push({
        type: "total",
        totalCents: totalCents,
        group: lastGroup,
    })

    const nt = props.notes.find(n => n.keyName === "summary")
    if(nt && !!nt.note) {
        const notes = nt.note.split("\n");


        notes.map((nt, index) => {
            out.push({
                type: "generalNoteSection",
                isFirst: index === 0,
                value: nt,
                group: undefined,
            });
        })
    }

    return out;
}

export const QuotePDF = forwardRef(function(props: {
    project: number;
    data: QuotePDFData;
    onReady(): void;
}, ref: any) {
    const data = useData({
        prices: props.data.prices,
        miscPrices: props.data.miscPrices,
        notes: props.data.notes,
        riders: props.data.riders,
    });

    const ready = useReadyCheck(props.onReady);

    return (
        <div ref={ref}>
            <PDF>
                <PageRenderProvider<Item> data={data} onReady={ready.section()} preferCombinedKey={item => item.group || null}>
                    {(info)=> {

                        const hasTableRows = !!info.rows.find(v => v.type === "row" || v.type === "title" || v.type === "footer" || v.type === "total")

                        return (<Page orientation="portrait">
                            <div style={{flex: 1, fontSize: 14}}>
                                {info.pageIndex === 0 && <>
                                    <PDFHeader2 title="Quotation" sales />

                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <div>
                                                <div style={subHeaderCss}>Quote To:</div>
                                                <div>{props.data.project.contractorName}</div>
                                                {props.data.contractor && <>
                                                    <div>{props.data.contractor.addressStreet}</div>
                                                    <div>{props.data.contractor.addressCity}, {props.data.contractor.addressProvince}</div>
                                                    <div>{props.data.contractor.addressPostal}</div>
                                                </>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div>
                                                <div style={subHeaderCss}>Ship To:</div>
                                                <div>{props.data.project.name}</div>
                                                {props.data.project && <>
                                                    <div>{props.data.project.addressStreet}</div>
                                                    <div>{props.data.project.addressCity}, {props.data.project.addressProvince}</div>
                                                    <div>{props.data.project.addressPostal}</div>
                                                </>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td style={{fontWeight: "bold", textAlign: 'right'}}>Quote No:</td>
                                                    <td>#{props.data.currentQuoteNumber}</td>
                                                </tr><tr>
                                                    <td style={{fontWeight: "bold", textAlign: 'right'}}>Date:</td>
                                                    <td>{moment().format("MMMM D, YYYY")}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Grid>

                                    <div style={{height: 16}} />
                                </>}

                                {hasTableRows && <table className={pdfTableStyleNoAlternating} style={{fontSize: 12}}>
                                    <thead>
                                    <tr>
                                        <th style={{borderRight: "none"}}>Description</th>
                                        <th style={{borderLeft: "none"}}>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {info.rows.map((i, index) => {
                                        switch(i.type) {
                                            case "title":
                                                return (
                                                    <tr key={index.toString()} style={{borderBottom: "none", borderTop: "1px solid"}}>
                                                        <td colSpan={2} style={{fontWeight: "bold"}}>
                                                            {i.value}
                                                        </td>
                                                    </tr>
                                                )
                                            case "row":
                                                if(i.value.productType === "hardware") return null;
                                                if(i.value.productType === "frame-anchor") return null;

                                                return (
                                                    <tr key={index.toString()}>
                                                        <td style={{borderRight: "none"}}>{i.value.qty} @ {fixShortForms(formatDescription(toDescriptionInput(i.value), i.value.qty > 1))}</td>
                                                        <td style={{textAlign: "right", borderLeft: "none"}}></td>
                                                    </tr>
                                                )
                                            case "footer":
                                                return (
                                                    <tr key={index.toString()} style={{borderBottom: "1px solid"}}>
                                                        <td style={{borderRight: "none"}}>
                                                            {i.notes.map(n => <div style={{whiteSpace: "pre-wrap"}}>{n}</div>)}
                                                        </td>
                                                        <td style={{textAlign: "right", verticalAlign: "bottom", borderLeft: "none"}}>
                                                            ${formatCents(i.totalCents)}
                                                        </td>
                                                    </tr>
                                                )
                                            case "total":
                                                return (
                                                    <tr key={index.toString()} style={{border: "none"}}>
                                                        <td style={{fontWeight: "bold", textAlign: "right", color: deepBlue, border: "none"}}>TOTAL</td>
                                                        <td style={{textAlign: "right", fontWeight: "bold", border: "none", paddingLeft: 0, paddingTop: 0}}>
                                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                                <div>${formatCents(i.totalCents)}</div>
                                                                <div style={{fontSize: "0.8em", paddingLeft: 4, paddingBottom: 1, alignSelf: "flex-end"}}>+HST</div>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid black", borderTop: "1px solid black", width: "100%", height: 4}} />
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    })}
                                    </tbody>
                                </table>}

                                {first(info.rows, i => i.type === "total") &&
                                    <div>
                                        <div style={{marginTop: 30,
                                            display: "flex", flexDirection: "column", alignItems: "center", width: 300}}>
                                            <div>Thank you for your business!</div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                fontSize: "0.7em",
                                                fontWeight: "600",
                                                color: deepBlue,
                                            }}>
                                                <div>Tykel Commercial Door Supply Inc</div>
                                                <div>HST #713417319RT0001</div>
                                            </div>

                                        </div>
                                        <div style={{color: deepBlue, textAlign: "center", paddingTop: 10, fontWeight: "600"}}>
                                            **ALL QUOTED ITEMS MUST BE ORDERED WITHIN 90 DAYS OF QUOTE DATE**
                                        </div>

                                    </div>}

                                {info.rows.map(r => {
                                    if(r.type === "generalNoteSection") {
                                        const onSamePageAsTable = !!info.rows.find(v => v.type === "total")
                                        return (
                                            <>
                                                {r.isFirst && <div style={{marginTop: onSamePageAsTable ? 30 : 8, fontWeight: "700"}}>General Notes</div>}
                                                <p style={{whiteSpace: "pre-wrap", lineHeight: "1.4em", margin: 0}}>
                                                    {r.value || <>&nbsp;</>}
                                                </p>
                                            </>
                                        )
                                    }
                                })}
                            </div>
                        </Page>)
                    }}
                </PageRenderProvider>
            </PDF>
        </div>
    )
});

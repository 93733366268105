import React, {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import {EventEmitter} from "nate-react-api-helpers";
export const PageRenderContext = createContext({
    AddPage: () => {
        return new Page(-2, () => {}, () => {});
    },
});

export function useSyncedRef<T>(input: T) {
    const ref = useRef(input);
    ref.current = input;
    return ref;
}

export function usePageInstance() {
    const ctx = useContext(PageRenderContext);

    const [page, setPage] = useState(() => ctx.AddPage());
    const lastPageRef = useSyncedRef(page)
    const addPage = useSyncedRef(ctx.AddPage)

    useEffect(() => {
        lastPageRef.current.remove();

        let pg = addPage.current()
        setPage(pg)

        return () => {
            pg.remove();
        }
    }, [lastPageRef, addPage]);

    return page;
}

export type DataSplitInfo<T> = {
    pageIndex: number;
    rows: T[];
    isLastPage: boolean;
}

type PageStage = "over-commit" | "pull-back" | "toe-line-over" | "locked"
class Page {
    id: number;

    rendering: boolean = true; // is in the middle of rendering or has a pending render
    stage: PageStage = "over-commit";

    lastGoodToeLineQty: number = -1;
    lastActualHeight: number = -1;
    lastMaxAllowed: number = -1;

    onRemove: () => void
    onRender: () => void

    constructor(id: number, onRemove: () => void, onRender: () => void) {
        this.id = id;
        this.onRemove = onRemove;
        this.onRender = onRender;

        this.reset();
    }

    reset() {
        this.rendering = true;
        this.stage = "over-commit";
        this.lastGoodToeLineQty = -1;
        this.lastActualHeight = -1;
        this.lastMaxAllowed = -1;
    }

    renderStart() {
        this.rendering = true;
    }

    renderComplete(actualHeight: number, maxAllowed: number) {
        this.rendering = false;
        this.lastActualHeight = actualHeight;
        this.lastMaxAllowed = maxAllowed;
        this.onRender();
    }

    remove() {
        this.onRemove();
    }
}

enum State {
    INIT,
    NEW_DATA,
    SMOOTHING,
    READY
}

type PageDiv = {
    startIndex: number;
}

export function PageRenderProvider<T = any>(props: {
    data: T[];
    debugKey?: string;
    preferCombinedKey?: (input: T) => string | null;
    children: (input: DataSplitInfo<T>) => any
    onReady: (tf: boolean) => void;
}) {
    const pageList = useRef<Page[]>([]);
    const [state, setState] = useState(State.INIT);
    const [pageDivs, setPageDivs] = useState<PageDiv[]>([]);
    const onReady = useSyncedRef(props.onReady);
    const pageIdRef = useRef(0)
    const pagesUpdated = useMemo(() => new EventEmitter(), []);

    const propsOnReady = props.onReady
    useEffect(() => {
        if(state === State.READY) {
            propsOnReady(true);
        }
    }, [propsOnReady, state]);

    const debugKeyRef = useSyncedRef(props.debugKey || "ndbg")

    useEffect(() => {
        if(state === State.READY) return;

        const int = setInterval(() => {
            console.log("page-context", debugKeyRef.current, "still calculating")
        }, 10 * 1000);

        return () => clearInterval(int)
    }, [state, debugKeyRef]);

    const ctx = useMemo(() => {
        return {
            AddPage: () => {
                const p = new Page(pageIdRef.current++, () => {
                    console.log("page-context", debugKeyRef.current, p.id, "page removed")
                    pageList.current = pageList.current.filter(l => l !== p)
                    pagesUpdated.emit(null);
                }, () => pagesUpdated.emit(null));

                console.log("page-context", debugKeyRef.current, p.id, "page created")
                pageList.current.push(p);
                pagesUpdated.emit(null);
                return p;
            },
        }
    }, [pagesUpdated, pageIdRef, debugKeyRef]);

    const divRef = useSyncedRef(pageDivs);
    const dataRef = useSyncedRef(props.data);

    const prefKeyRef = useRef(props.preferCombinedKey)
    prefKeyRef.current = props.preferCombinedKey;

    useEffect(() => {
        console.log("page-context", debugKeyRef.current, "got new data, ready=false")
        pageList.current.map(p => p.reset())
        setState(State.SMOOTHING);
        setPageDivs([{
            startIndex: 0,
        }]);
        onReady.current(false)
    }, [props.data, onReady, debugKeyRef])

    const stateRef = useSyncedRef(state)

    useEffect(() => {
        const sub = pagesUpdated.subscribeAndFireLast(() => {
            if(stateRef.current !== State.SMOOTHING) return;

            const divs = divRef.current;
            const updateDivs: PageDiv[] = [];

            function pageDataLength(pageIndex: number) {
                const start = divs[pageIndex].startIndex
                if(divs[pageIndex+1]) {
                    return divs[pageIndex+1].startIndex - start
                }

                return dataRef.current.length - start
            }

            function pullbackCalcDataLength(pageIndex: number) {
                const start = divs[pageIndex].startIndex
                const end = divs[pageIndex+1] ? divs[pageIndex+1].startIndex : dataRef.current.length;

                // half the data
                let length = Math.floor((end - start) / 2)
                if(length < 1) return 1;

                // aim for 100 or less (rule of thumb for how many rows on a page)
                if(length > 100) {
                    length = 100;
                }

                // rounding by splitKey
                const prefKey = prefKeyRef.current
                if(!prefKey) return length;

                let key = prefKey(dataRef.current[start + length])
                if(!key) return length;

                // explore lower half of data (try to pullback slowly)
                let lastI: number | null = null;
                for(let i = length; i > 0; i--) {
                    if(prefKey(dataRef.current[start + i]) !== key) {
                        if(lastI === null) break;
                        return lastI;
                    }

                    lastI = i;
                }

                // explore upper half of data
                const half = length;
                lastI = null;

                for(let i = 0; i < length; i++) {
                    if(prefKey(dataRef.current[start + half + i]) !== key) {
                        if(lastI === null) break;
                        return lastI;
                    }

                    lastI = i + half;
                }

                // have to split the pref key
                return length;
            }

            function toeLineCalcDataLength(pageIndex: number) {
                const start = divs[pageIndex].startIndex
                const end = divs[pageIndex+1] ? divs[pageIndex+1].startIndex : dataRef.current.length;
                const maxLength = dataRef.current.length - start - 1;

                // increment by 1, rounding by prefKey
                let newLength = end - start + 1;
                if(newLength > maxLength) return maxLength;

                const prefKey = prefKeyRef.current
                if(!prefKey) return newLength;

                let key = prefKey(dataRef.current[start + newLength])
                for(let i = newLength + 1; i <= maxLength; i++) {
                    if(prefKey(dataRef.current[start + i]) !== key) {
                        return i;
                    }
                }

                return maxLength;
            }

            if(pageList.current.length === 0) return;

            console.log("page-context", debugKeyRef.current, pageList.current, divs);

            for(let i = 0; i < pageList.current.length; i++) {
                const pg = pageList.current[i];

                // ignore locked pages
                if(pg.stage === "locked") {
                    console.log("page-context", debugKeyRef.current, pg.id, "locked")
                    updateDivs.push(divs[i]);

                    if(i + 1 === pageList.current.length) {
                        if(divs.length === pageList.current.length) {
                            // done
                            console.log("page-context", debugKeyRef.current, "everything locked, onReady(true)")
                            setState(State.READY);
                        } else {
                            console.log("page-context", debugKeyRef.current, "everything locked, missing pages/divs")
                        }

                        // don't update divs if we're at the end and everything is locked
                        return;
                    }

                    continue;
                }

                // nothing to do while we wait for it to render
                if(pg.rendering) {
                    console.log("page-context", debugKeyRef.current, pg.id, "rendering")
                    return
                }

                if(pg.stage === "over-commit") {
                    pg.stage = "pull-back";
                    console.log("page-context", debugKeyRef.current, pg.id, "over-commit")

                    // done, it fits and it's the last page
                    if(pg.lastActualHeight <= pg.lastMaxAllowed) {
                        pg.stage = "locked";
                        console.log("page-context", debugKeyRef.current, pg.id, "locked2")

                        if(i + 1 === pageList.current.length) {
                            if(divs.length === pageList.current.length) {
                                // done
                                console.log("page-context", debugKeyRef.current, "everything locked, onReady(true)")
                                setState(State.READY);
                            } else {
                                console.log("page-context", debugKeyRef.current, "everything locked, missing pages/divs")
                            }

                            // don't update divs if we're at the end and everything is locked
                            return;
                        }

                        continue;
                    }

                    // overflowed, pull-back by halving the data and re-rendering
                    const newLength = pullbackCalcDataLength(i);
                    if(newLength !== pageDataLength(i)) {
                        updateDivs.push(divs[i])
                        updateDivs.push({
                            startIndex: divs[i].startIndex + newLength,
                        })

                        console.log("page-context", debugKeyRef.current, pg.id, "update-length", newLength)
                    } else if(newLength === 1) {
                        // if there's only 1 row, lock it in, we can't reduce further
                        pg.stage = "locked";
                        console.log("page-context", debugKeyRef.current, pg.id, "locked3")

                        if(i + 1 === pageList.current.length) {
                            if(divs.length === pageList.current.length) {
                                // done
                                console.log("page-context", debugKeyRef.current, "everything locked, onReady(true)")
                                setState(State.READY);
                            } else {
                                console.log("page-context", debugKeyRef.current, "everything locked, missing pages/divs")
                            }

                            // don't update divs if we're at the end and everything is locked
                            return;
                        }

                        continue;
                    }

                    pg.rendering = true
                    break;
                }

                if(pg.stage === "pull-back") {
                    console.log("page-context", debugKeyRef.current, pg.id, "pull-back")

                    if(pg.lastActualHeight > pg.lastMaxAllowed) {
                        // overflowed, pull-back by halving the data and re-rendering
                        const newLength = pullbackCalcDataLength(i)
                        if(newLength !== pageDataLength(i)) {
                            console.log("page-context", debugKeyRef.current, pg.id, "update-length2", newLength)

                            updateDivs.push(divs[i])
                            updateDivs.push({
                                startIndex: divs[i].startIndex + newLength,
                            })
                            pg.rendering = true
                            break;
                        }
                    }

                    pg.stage = "toe-line-over";
                    pg.lastGoodToeLineQty = pageDataLength(i);
                    const newLength = toeLineCalcDataLength(i)
                    console.log("page-context", debugKeyRef.current, pg.id, "toe-line-over", pg.lastGoodToeLineQty)

                    if(newLength !== pageDataLength(i)) {
                        console.log("page-context", debugKeyRef.current, pg.id, "update-length3", newLength)

                        // increment by 1 until we overflow
                        updateDivs.push(divs[i])
                        updateDivs.push({
                            startIndex: divs[i].startIndex + newLength,
                        })
                        pg.rendering = true
                        break;
                    }
                }

                if(pg.stage === "toe-line-over") {
                    console.log("page-context", debugKeyRef.current, pg.id, "toe-line-over")

                    if(pageDataLength(i) === 1) {
                        console.log("page-context", debugKeyRef.current, pg.id, "locked-1")
                        pg.stage = "locked"
                        continue;
                    }

                    if(pg.lastActualHeight > pg.lastMaxAllowed) {
                        console.log("page-context", debugKeyRef.current, pg.id, "locked-revert")
                        // overflowed, pull-back by one, this is done
                        updateDivs.push(divs[i])
                        updateDivs.push({
                            startIndex: divs[i].startIndex + pg.lastGoodToeLineQty,
                        })

                        pg.stage = "locked"
                        break;
                    }

                    pg.lastGoodToeLineQty = pageDataLength(i);

                    // increment by until we overflow
                    const newLength = toeLineCalcDataLength(i);

                    // at end of data, locked, done
                    if(newLength === pageDataLength(i)) {
                        console.log("page-context", debugKeyRef.current, pg.id, "locked-end-data")
                        pg.stage = "locked"
                        continue
                    }

                    console.log("page-context", debugKeyRef.current, pg.id, "new-length3", newLength)

                    updateDivs.push(divs[i])
                    updateDivs.push({
                        startIndex: divs[i].startIndex + newLength,
                    })

                    pg.rendering = true
                    break;
                }

                throw new Error(`unexpected stage ${pg.stage} ${pg.id}`)
            }

            if(updateDivs.length > 0) {
                console.log("page-context", debugKeyRef.current, "update divs", updateDivs)
                setPageDivs(updateDivs);
                divRef.current = updateDivs; // update ref just in-case there's another sub-render before it's updated
            }
        })

        return () => sub.cancel()
    }, [dataRef, divRef, pagesUpdated, stateRef, debugKeyRef]);

    return (
        <PageRenderContext.Provider value={ctx}>
            <PageRenderCtrl<T> {...props} pageDivs={pageDivs} />
        </PageRenderContext.Provider>
    );
}

function PageRenderCtrl<T>(props: {
    data: T[];
    children: (input: DataSplitInfo<T>) => any
    pageDivs: PageDiv[];
}) {
    const sections = useMemo(() => {
        let list: DataSplitInfo<T>[] = [];

        for(let i = 0; i < props.pageDivs.length; i++) {
            const start = props.pageDivs[i].startIndex;

            if(i+1 < props.pageDivs.length) {
                const end = props.pageDivs[i+1].startIndex;

                list.push({
                    pageIndex: i,
                    rows: props.data.slice(start, end),
                    isLastPage: false,
                })
            } else {
                list.push({
                    pageIndex: i,
                    rows: props.data.slice(start),
                    isLastPage: true,
                })
            }
        }

        return list;
    }, [props.data, props.pageDivs])

    console.log("page-render", props.pageDivs.map(i => i.startIndex).join(","), sections.map(s => "length: " + s.rows.length));

    return (
        <>
            {sections.map((sect, index) =>
                <React.Fragment key={index}>{props.children(sect)}</React.Fragment>
            )}
        </>
    );
}


import {QuoteContainer} from "../QuoteContainer";
import {MiscSwitcher} from "./MiscSwitcher";
import {MiscGeneric} from "./MiscGeneric";

export function QuoteMisc() {
    return (
        <QuoteContainer name={<MiscSwitcher />}>
            <MiscGeneric />
        </QuoteContainer>
    )
}
import {Container} from "../misc/Container";
import {Alert} from "@mui/material";
import {useLocation} from "react-router-dom";
import BugReport from "@mui/icons-material/BugReport";

export function NotFoundPage() {
    const location = useLocation();

    return (
        <Container>
            <div style={{padding: 30}}>
                <Alert variant="filled" color="error" icon={<BugReport />}>
                    Hrm, we couldn't find that page <br />
                    <div>
                        {location.pathname + location.search}
                    </div>
                </Alert>
            </div>
        </Container>
    )
}
import {QuoteContainer} from "../QuoteContainer";
import {HardwareGeneric} from "../../shopdrawing/hardware/HardwareGeneric";
import {HardwareSwitcher} from "./HardwareSwitcher";
import {TransferProducts} from "./TransferProducts";
import {EventEmitter} from "nate-react-api-helpers";
import {Grid} from "@mui/material";
import {HardwareTemplateBtn} from "./HardwareTemplateBtn";
import {HardwareScheduleBtn} from "./HardwareScheduleBtn";
import {HardwareReSequence} from "./HardwareReSequence";
import {HardwareViewMode, HardwareViewModeProvider} from "./HardwareViewMode";
import {MergeGroupsBtn} from "./MergeGroups";
import React from "react";

export const QuoteHwForceUpdate = new EventEmitter<number>();

export function QuoteHardware() {
    const cacheBustKey = EventEmitter.reactValue(QuoteHwForceUpdate);

    return (
        <HardwareViewModeProvider>
            <QuoteContainer name={<HardwareSwitcher />} actions={<Grid container spacing={1}>
                <Grid item>
                    <HardwareScheduleBtn />
                </Grid>
                <Grid item>
                    <HardwareTemplateBtn />
                </Grid>
                <Grid item>
                    <TransferProducts />
                </Grid>
                <Grid item>
                    <HardwareReSequence />
                </Grid>
                <Grid item>
                    <MergeGroupsBtn />
                </Grid>
                <Grid item>
                    <HardwareViewMode />
                </Grid>
            </Grid>}>
                <HardwareGeneric key={cacheBustKey?.toString() || "-"} />
            </QuoteContainer>
        </HardwareViewModeProvider>
    )
}
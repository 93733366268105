import {useParams} from "react-router-dom";
import {Grid, Tooltip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useContext} from "react";
import {currentProject, ProjectContext} from "../ProjectProvider";
import {EventEmitter} from "nate-react-api-helpers";

export function ProjectName() {
    const project = useProject();

    return (<Grid item>
        <Tooltip title={project?.name || ""}>
            <Typography variant="h3" style={{
                fontSize: "1.2rem", fontWeight: 500, color: grey["600"],
                maxWidth: 200, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
            }}>{project?.name}</Typography>
        </Tooltip>
    </Grid>)
}

export function useProjectId() {
    const params = useParams<{project: string}>();
    try {
        if(!params.project) return 0;

        const v = parseInt(params.project, 10);
        if(isNaN(v)) return 0;
        return v;

    } catch (e) {
        return 0;
    }
}

export function useProject() {
    const proj = useContext(ProjectContext);

    const projEmit = EventEmitter.reactValue(currentProject);
    if(proj.value.id !== 0) return proj.value;

    // if not inside of <ProjectProvider> fallback
    return projEmit;
}

export function useProjectUnitSystem() {
    const proj = useProject();
    if(!proj) return null;
    return proj.unitSystem || "imperial";
}
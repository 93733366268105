import {Project} from "../../../../api/Projects";
import React, {PropsWithChildren, useEffect} from "react";
import {Card, Grid} from "@mui/material";
import {grey} from "@mui/material/colors";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {Loader} from "../../../../misc/Loader";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {ProjectFileKind} from "../../../../api/ProjectFiles";
import {FileColumns} from "./FileColumns";
import {useProjectId} from "../../shopdrawing/ProjectName";

export function Files(props: {
    title: string;
    project: Project;
    kind: ProjectFileKind
    noApproval?: boolean;
    small?: boolean;
}) {
    const projectId = useProjectId();
    const fileRequest = useAsync2(input => api.projectFiles.list(input), {
        project: projectId,
        kind: props.kind,
    }, [projectId, props.kind]);

    const fileRequestRef = useSyncedRef(fileRequest);
    useEffect(() => {
        if(!fileRequestRef.current.loading) {
            fileRequestRef.current.reload();
        }
    }, [fileRequestRef])

    return (
        <Card style={{
            padding: 16, flex: 1, paddingBottom:5,
            margin: 0, width: "100%",
        }}>
            <Grid container spacing={1}
                  alignItems="stretch"
                  style={{height: "100%", width: "100%"}}
                  flexDirection="column"
                  justifyContent="flex-start"
            >
                <Grid item>
                    <Title>{props.title}</Title>
                </Grid>
                <Grid item style={{
                    flex: 1,
                    width: "100%",
                }}>
                    <Loader {...fileRequest} />
                    {fileRequest.result && <FileColumns
                        small={props.small} kind={props.kind} list={fileRequest.result} onChange={fileRequest.reload}
                        project={projectId}
                        noApproval={props.noApproval}
                    />}
                </Grid>
            </Grid>
        </Card>
    )
}

export function Title(props: PropsWithChildren<{}>) {
    return (
        <div style={{fontSize: "0.9rem", fontWeight: "600"}}>
            {props.children}
        </div>
    )
}

export function Title2(props: PropsWithChildren<{}>) {
    return (
        <div style={{fontSize: "0.9rem", fontWeight: "600", color: grey["500"]}}>
            {props.children}
        </div>
    )
}
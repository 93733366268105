import {Tab, Tabs} from "@mui/material";
import React from "react";
import {TabWrapper} from "../../shopdrawing/release/Release";
import {showNumber} from "../../shopdrawing/pricing/Pricing";
import {PriceDetail} from "../../shopdrawing/pricing/UnPricedItems";

export const defaultDoorTab = "hm";

export function DoorTabs(props: {
    unpriced?: PriceDetail;
    tab: string;
    onChange(value: string): void;
}) {
    return (
        <TabWrapper>
            <Tabs value={props.tab} onChange={(e, value) => props.onChange(value)}>
                {doorTabKeys.map(k =>
                    <Tab key={k} label={<>{showNumber(DoorTypeName(k), props.unpriced?.doorsDetail[k].unpriced)}</>} value={k} />
                )}
            </Tabs>
        </TabWrapper>
    )
}

type TDoorTab = "hm" | "wd" | "other"

export const doorTabKeys: TDoorTab[] = [
    "hm",
    "wd"
]

export function DoorTypeName(name: string) {
    switch(name) {
        case "hm": return "Hollow Metal"
        case "wd": return "Wood";
        default: return `error: ${name}`;
    }
}
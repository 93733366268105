import React from "react";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {QuoteSwitcher} from "../pricing/QuoteSwitcher";

export function Div10Switcher() {
    const projectId = useProjectId();

    return (
        <QuoteSwitcher
            title="Div 10"
            url={`/project/${projectId}/shop-drawing/div10`}
            quoteTitle="Quote Div 10"
            quoteURL={`/project/${projectId}/quote/-1/div-10`} />
    )
}


import {styled, Tooltip} from "@mui/material";
import {grey} from "@mui/material/colors";
import {Link, useLocation} from "react-router-dom";

const Outer = styled("div")<{selected: boolean, disabled?: boolean}>(props => ({
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    overflow: "hidden",
    marginRight: 10,
    minWidth: 180,
    opacity: props.disabled ? 0.6 : undefined,
    backgroundColor: props.disabled ? grey["100"] : props.selected ? grey["300"] : "",
    border: "1px solid " + (props.selected ? grey["300"] : "transparent"),
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderLeft: "none",

    "&:hover": {
        backgroundColor: grey["200"],
    }
}))

export function SidebarItem(props: {
    icon: any;
    name: string;
    link: string;
    indent?: number
    disabled?: boolean;
    noMatchChildren?: boolean;
    forceSelected?: boolean;
}) {
    const loc = useLocation();
    let childMatches = loc.pathname.startsWith(props.link + "/") || loc.pathname.startsWith(props.link.replace(/s$/, "") + "/")
    if(props.noMatchChildren) childMatches = false;
    const selected = loc.pathname === props.link || childMatches;

    return (
        <Link to={props.disabled ? "" : props.link} style={{color: "inherit", textDecoration: "none"}}>
            <Outer selected={selected} disabled={props.disabled}>
                <Tooltip title={props.name || ""} placement="right">
                    <div style={{display: "flex", paddingLeft: props.indent ? props.indent * 18 : undefined}}>
                        <div style={{paddingRight: 16, overflow: "hidden", fontSize: "1.3rem", height: "1em"}}>
                            {props.icon}
                        </div>
                        <div style={{maxWidth: 100, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            {props.name}
                        </div>
                    </div>
                </Tooltip>
            </Outer>
        </Link>
    );
}
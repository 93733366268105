import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../../api/API";
import {useProjectId} from "../../ProjectName";
import {Loader} from "../../../../../misc/Loader";
import {ShortCode} from "../../../../../api/Library";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import React, {useContext, useEffect, useState} from "react";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {useHwLibraryType} from "./HwLibraryType";
import {useAlternative} from "../../../quote/alternative/Alternative";
import {ReloadContext} from "../ReloadContext";
import {ShortCodeEdit} from "./ShortCodeEdit";
import {Button} from "@mui/material";
import {cellInActive} from "../../pricing/cellconst";
import {ChangePreviewContext} from "../../ShopDrawingChangePreview";

export function ShortCodes(props: {}) {
    const type = useHwLibraryType();
    const project = useProjectId();
    const alternative = useAlternative();
    const previewChangeset = useContext(ChangePreviewContext)
    const locked = previewChangeset.enabled;
    const list = useAsync2(input => api.hardwareLibrary.getShortCodes(input), {
        project, type: type,
        alternative: alternative,
        previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined
    }, [project, type, alternative, previewChangeset.enabled, previewChangeset.sessionId]);

    const reloadListRef = useSyncedRef(list.reload);

    const reloadCtx = useContext(ReloadContext);
    const reloadEmitter = reloadCtx.emitter
    useEffect(() => {
        const sub = reloadEmitter.subscribe(() => reloadListRef.current());
        return () => sub.cancel();
    }, [reloadEmitter, reloadListRef])

    const [createValue, setCreateValue] = useState<ShortCode|null>(null)

    return (
        <div style={{flex: 1, overflow: "hidden", display: "flex", flexDirection: "column"}}>
            <div style={{overflow: "auto", flex: 1, backgroundColor: cellInActive}}>
                <Loader<ShortCode[]> {...list}>
                    {data => <table className={tableClass}>
                        <thead>
                            <tr>
                                <th style={{width: 40}}>Code</th>
                                <th>Product</th>
                                <th>Code</th>
                                <th>Finish</th>
                                <th>Dim.</th>
                                <th style={{width: 40}}>Qty</th>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {(data as ShortCode[]).map(r => {
                                return <ShortCodeEdit disabled={locked} key={r.id.toString()} value={r} />
                            })}
                            {createValue && !locked && <ShortCodeEdit key="create" value={createValue} mode="create" onDone={() => {
                                setCreateValue(null)
                            }} />}
                        </tbody>
                    </table>}
                </Loader>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", padding: 16, background: "white"}}>
                <Button variant="outlined" disabled={locked} size="small" onClick={() => setCreateValue({
                    id: 0,
                    value: "",
                    description: "",
                    project: project,
                    library: 0,
                    libraryType: type,
                    quoteAlternative: alternative,
                    archived: false,

                    items: [],
                })}>Create Short Code</Button>
            </div>
        </div>
    )
}

export const tableBorderColor = grey["300"]

export const tableClass = css({
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "white",

    "& thead tr": {
        borderBottom: "1px solid " + tableBorderColor,
    },

    "& th": {
        fontSize: "0.8rem",
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        textAlign: "left",
    },
    "& td": {
        fontSize: "0.8rem",
        padding: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },

    "& tr": {
        border: "1px solid transparent",
        borderBottom: "1px solid " + tableBorderColor,
    },

    "& td.no-pad": {
        padding: 0
    },
    "& td.multi-row-cell": {
        borderRight: "1px solid " + tableBorderColor,
        verticalAlign: "top",
    },
})

import {api} from "../../api/API";
import {green, grey} from "@mui/material/colors";
import {Button, Grid, IconButton} from "@mui/material";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {css} from "@emotion/css";
import {Rotation, Value} from "./ExtractorSession";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {ConfirmContext} from "../../misc/Confirm";
import {useCropImageAutoResize} from "./CropImageResize";
import {useCropLassoCreator} from "./CropLassoCreator";
import {Lasso} from "./Lasso";
import Close from "@mui/icons-material/Close";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PanToolIcon from '@mui/icons-material/PanTool';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import {useCropDrag} from "./CropDrag";

export type Point = {
    x: number;
    y: number;
}

export type Rect = {
    start: Point;
    end: Point;

    totalWidth: number;
    totalHeight: number;
}

export function Cropper(props: {
    session: number;
    index: number;
    value: Value | null;
    onChange(value: Value): void;
    hasNext: boolean;
    onNext(): void;
    onDone(): void;
    onApplyToAll(value: Rect, rotation: Rotation): void;
}) {
    const [zoom, setZoom] = useState(1);
    const [translate, setTranslate] = useState({x: 0, y: 0});
    const [panMode, setPanMode] = useState(false);

    const [imageRef, setImageRef] = useState<HTMLImageElement|null>(null);
    const [selectedLasso, setSelectedLasso] = useState<number>();
    const selectLastRef = useRef(false);
    const value = useMemo(() => {
        return props.value || {
            rects: [],
            rotation: 0,
            ignore: false,
            checked: false,
        } as Value
    }, [props.value]);

    const confirmCtx = useContext(ConfirmContext);

    const onChangeRef = useRef(props.onChange);
    onChangeRef.current = props.onChange;

    useCropImageAutoResize(imageRef);
    useCropLassoCreator({
        imageRef,
        rotation: value.rotation,
        zoom,
        enable: !panMode,
        onCreate: rect => {
            const rects = value.rects || [];
            props.onChange(Object.assign({}, value, {
                ignore: false,
                checked: false,
                rects: [...rects, rect],
            }));

            selectLastRef.current = true;
        }
    });

    useCropDrag({
        imageRef,
        enable: panMode,
        value: translate,
        onChange: setTranslate,
    })

    const rects = value.rects;
    useEffect(() => {
        if(!selectLastRef.current) return;
        if(!rects || rects.length === 0) return;

        setSelectedLasso(rects.length - 1);
        selectLastRef.current = false;
    }, [rects])

    useEffect(() => {
        if(selectedLasso === undefined) return;

        const keyPress = (e: KeyboardEvent) => {
            if(e.key === "Delete" || e.key === "Backspace") {
                let rects = value.rects || [];
                rects = rects.filter((_, i) => i !== selectedLasso);

                onChangeRef.current(Object.assign({}, value, {
                    rects: rects,
                }));

                setSelectedLasso(undefined);
            }
        }

        document.addEventListener("keydown", keyPress);

        return () => {
            document.removeEventListener("keydown", keyPress);
        }
    }, [selectedLasso, value])

    return (
        <div style={{
            flex: 1,
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
        }}
             onClick={() => {
                 if(selectedLasso === undefined) return;
                 if(selectLastRef.current) return;

                 setSelectedLasso(undefined);
             }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: grey["400"],
                    paddingRight: 20,
                }}>
                    <div style={{paddingLeft: 8, paddingTop: 6, paddingBottom: 6}}>
                        <div style={{fontWeight: "bold", fontSize: "0.75rem"}}>Extractor Session</div>
                        <div style={{fontSize: "0.75rem"}}>Crop images to remove unnecessary items</div>
                    </div>

                    <div style={{flex: 1}} />

                    <Button variant="outlined" size="small"
                            startIcon={<RemoveCircleIcon />}
                            color="error"
                            onClick={() => {
                                props.onChange(Object.assign({}, value, {
                                    checked: true,
                                    ignore: true,
                                }))

                                props.onNext();
                            }}>Ignore Page</Button>
                    <div style={{width: 8}} />
                    <div style={{color: green["800"]}}>
                        <Button variant="outlined" size="small"
                                startIcon={<CheckCircleIcon />}
                                color="inherit"
                                onClick={() => {
                                    props.onChange(Object.assign({}, value, {
                                        checked: true,
                                        ignore: false,
                                    }))

                                    props.onNext();
                                }}
                            >Approve Page</Button>
                    </div>
                    <div style={{width: 8}} />
                    <Button variant="contained" size="small"
                            startIcon={<ArrowCircleUpIcon />}
                            onClick={() => props.onDone()}>Submit Document</Button>
                    <div style={{width: 8}} />
                    <Button variant="outlined" size="small"
                            startIcon={<Close />}
                            onClick={() => {
                                window.location.reload();
                            }}>Close Document</Button>

                </div>
                <div style={{
                    backgroundColor: grey["300"],
                    paddingRight: 20,
                }}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                            <IconButton size="small" onClick={() => {
                                props.onChange(Object.assign({}, value, {
                                    rotation: (value.rotation || 0) - 90,
                                    ignore: false,
                                }))
                            }}>
                                <RotateLeftIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => {
                                props.onChange(Object.assign({}, value, {
                                    rotation: (value.rotation || 0) + 90,
                                    ignore: false,
                                }))
                            }}>
                                <RotateRightIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => {
                                props.onChange(Object.assign({}, value, {
                                    ignore: false,
                                }))
                                setZoom(zoom + zoomStep);
                            }}>
                                <ZoomInIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => {
                                props.onChange(Object.assign({}, value, {
                                    ignore: false,
                                }))
                                setZoom(zoom - zoomStep);
                            }}>
                                <ZoomOutIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => {
                                setPanMode(!panMode)
                            }}>
                                {panMode ? <HighlightAltIcon /> : <PanToolIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: grey["300"],
                flex: 1,
                overflow: "hidden",
                position: "relative"
            }}>

                <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    transform: `translate(${translate.x}px, ${translate.y}px) rotate(${value.rotation || 0}deg) scale(${zoom})`,
                    transition: "transform 0.1s ease-in-out",
                    cursor: panMode ? "move" : "crosshair",
                }}>

                    <img
                        draggable={false}
                        ref={setImageRef}
                        alt="selected image"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            userSelect: "none",
                            position: "relative",
                        }}
                        src={api.extractor.imageURL({session: props.session, index: props.index})}
                    />

                    {value.rects.map((r, index) =>
                        <Lasso
                            value={r}
                            rotation={value.rotation || 0}
                            zoom={zoom}
                            onClick={() => setSelectedLasso(index)}
                            selected={index === selectedLasso}
                            onChange={rect => {
                                if(!value) return;

                                let rects = value.rects.slice(0);
                                rects[index] = rect;

                                props.onChange(Object.assign({}, value, {
                                    rects: rects,
                                }))

                            }} />)}
                </div>
            </div>

            <div style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: 8,
                backgroundColor: grey["300"],
            }}>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        <Button disabled={!value.rects.length}
                                variant="outlined"
                                size="small"
                                onClick={async () => {
                                    const rect = value.rects[selectedLasso ?? 0];
                                    if(!rect) return;

                                    const ok = await confirmCtx.confirm("This will apply the current mask to all pages in this document. You will have to re-review any approved pages. Are you sure you want to continue?");
                                    if(!ok) return;

                                    props.onApplyToAll(rect, value.rotation || 0);
                                }}
                        >
                            Apply This Mask To All</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="error"
                            variant="outlined"
                            size="small"
                            disabled={selectedLasso === undefined}
                            onClick={() => {
                                const rects = value.rects;
                                if(!rects) return;

                                props.onChange(Object.assign({}, value, {
                                    rects: rects.filter((_, index) => index !== selectedLasso),
                                }));

                                setSelectedLasso(undefined);
                            }}>
                                Remove selected mask
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const zoomStep = 0.1;

export const lassoCss = css({
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.2)",
    border: "2px dashed black",
})
import React, {useCallback, useContext, useState} from "react";
import {useProjectId} from "../ProjectName";
import {useSnackbar} from "../../../../misc/Snackbar";
import {TableContext} from "../../../../misc/scroller/Table";
import {BtnClass, DragIcon, wrapperClass} from "../../quote/openings/RowActions";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {Popover} from "../../../../misc/Popover";
import {Link} from "react-router-dom";
import {buildFilterLink} from "../../../../misc/scroller/Filter";
import {logisticsTableName} from "../logistics/Logistics";
import {Divider, ListItemText, MenuItem} from "@mui/material";
import {Div10Row} from "../../../../api/QuoteDiv10";
import {useDiv10Api} from "./Div10Table";
import {api} from "../../../../api/API";
import {useIsQuote} from "../../quote/QuoteContainer";
import {Release} from "../openings/RowActions";

export function RowActions(props: {
    row: Div10Row
}) {
    const [showMore, setShowMore] = useState<HTMLDivElement|null>(null);

    const project = useProjectId();
    const close = useCallback(() => setShowMore(null), []);
    const snack = useSnackbar();
    const tbl = useContext(TableContext);
    const dapi = useDiv10Api();
    const isQuote = useIsQuote();

    return <div className={wrapperClass}>
        <DragIcon />
        <div className={BtnClass} onClick={e => setShowMore(e.currentTarget)}>
            <MoreHoriz fontSize="inherit" />
        </div>

        {showMore && <Popover
            open={true}
            anchorEl={showMore}
            onClose={() => setShowMore(null)}
        >
            <div style={{height: 4}} />
            <Link to={`/project/${project}/shop-drawing/logistics/div10?${buildFilterLink({
                tableName: logisticsTableName(project, "div10"),
                filter: {
                    type: "eq",
                    search: {
                        roomName: props.row.room,
                    },
                }
            })}`} style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem onClick={() => {
                    close();
                }}>
                    <ListItemText>View in Logistics</ListItemText>
                </MenuItem>
            </Link>
            {!isQuote && <Release onDone={() => {
                close()
                tbl.reload(false);
            }} name="" released={!!props.row.released} onRelease={(release, confirmUnreleasePODraft) => api.openings.release({
                project: project,
                neededBy: null,
                unRelease: !release,
                authorizedBy: "",
                div10Items: [props.row.id],
                note: "",
                confirmUnreleasePODraft: confirmUnreleasePODraft,
            })} />}
            <Divider />

            <MenuItem onClick={async () => {
                snack.loading();
                try {
                    await dapi.upsert(Object.assign({}, props.row, {
                        archived: true,
                    }));

                    snack.success("Archived")
                    await tbl.reload(false);
                } catch (e: any) {
                    snack.error(e.toString());
                }
                close();
            }}>
                <ListItemText>Archive</ListItemText>
            </MenuItem>
            <div style={{height: 4}} />
        </Popover>}

    </div>
}
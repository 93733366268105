import {Checkbox} from "@mui/material";
import {css} from "@emotion/css";
import {deepOrange, grey} from "@mui/material/colors";
import {isNumber} from "../routes/QtyWithPreview";
import {WarehouseReceiveItem} from "../../../api/Receiving";

export function ReceiveItem2(props: {
    isPo: boolean;
    value: WarehouseReceiveItem
    onLongPress?(): void;
    onEdit?(item: WarehouseReceiveItem): void;
}) {
    const v = props.value;
    const isBackOrder = v.qtyReceived !== null && v.qtyReceived < v.qtyExpected && v.qtyDamaged === 0;
    const isDamaged = isNumber(v.qtyReceived) && !isBackOrder && isNumber(v.qtyDamaged) && v.qtyDamaged > 0;
    const isOddQty = !isBackOrder && !isDamaged && v.qtyReceived !== null && v.qtyReceived !== v.qtyExpected && v.qtyDamaged === 0;

    return (
        <tr className={rowStyle + " " + (!props.onEdit ? disabledStyle : "")}
            onContextMenu={e => {
                e.preventDefault();
                if(props.onLongPress) props.onLongPress();
            }}
            onClick={() => {
                const onEdit = props.onEdit;
                if(!onEdit) return;

                if(props.value.qtyReceived === null || props.value.qtyReceived === 0) {
                    onEdit(Object.assign({}, props.value, {
                        qtyReceived: props.value.qtyExpected,
                        qtyDamaged: 0,
                    }))
                } else {
                    onEdit(Object.assign({}, props.value, {
                        qtyReceived: 0,
                        qtyDamaged: 0,
                    }));
                }
            }}>
            <td>
                <div>{v.name}</div>
                {props.isPo && isBackOrder && isNumber(v.qtyReceived) && <div className={itemDetails}>Received {v.qtyReceived} ({v.qtyExpected - v.qtyReceived} on back order)</div>}
                {!props.isPo && isBackOrder && isNumber(v.qtyReceived) && <div className={itemDetails}>Received {v.qtyReceived} ({v.qtyExpected - v.qtyReceived} missing)</div>}
                {isDamaged && <div className={itemDetails}>{v.qtyReceived} correct, {v.qtyDamaged} damaged </div>}
                {isOddQty && <div className={itemDetails}>{v.qtyReceived} items </div>}
            </td>
            <td style={{textAlign: "right", width: 40}}>
                <div style={{width: 40}}>
                    {v.qtyExpected}
                </div>
            </td>
            <td style={{width: 54}}>
                <div style={{width: 54}}>
                    {!!props.onEdit && <>
                        {(isDamaged || isBackOrder || isOddQty) ?
                            <Checkbox color="error" indeterminate={true} /> :
                            <Checkbox checked={v.qtyExpected === v.qtyReceived} />
                        }
                    </>}
                </div>
            </td>
        </tr>
    )
}

const itemDetails = css({
    fontSize: "0.9rem",
    color: deepOrange["700"],
})

const rowStyle = css({
    fontSize: "1.3rem",

    "&:nth-child(even)": {
        backgroundColor: grey["100"],
    },

    "& > td": {
        paddingTop: 4,
        paddingBottom: 4,
    },

    "& > td:nth-child(1)": {
        paddingLeft: 4,
        paddingRight: 4,
    }
})

const disabledStyle = css({
    backgroundColor: grey["100"],
})
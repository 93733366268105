import {Alert, Button, Grid, LinearProgress, Paper, Popper} from "@mui/material";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import {green, grey} from "@mui/material/colors";
import {formatCents, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import moment from "moment";
import {Link} from "react-router-dom";
import { poURL } from "../../../logistics/purchasing/PurchaseOrder";
import {useContext} from "react";
import {css} from "@emotion/css";
import {CostHelperContext} from "./CostHelperContext";
import {useEventEmitter} from "../../../../misc/UseEventEmitter";

export function CostHelper() {
    const ctx = useContext(CostHelperContext);
    const value = useEventEmitter(ctx.value);

    const data = useAsync2(async (input) => {
        if(!input.product) return [];
        return api.pricing.getCostsOfSimilarProduct({
            product: input.product,
        })
    }, {product: value?.product}, [value?.product])

    return (
        <Popper open={!!value} style={{ position: 'fixed', bottom: 10, right: 10, top: 'unset', left: 'unset', zIndex: 1000 }}>
            <Paper elevation={3}>
                <div style={{padding: 8, minWidth: 300}}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div style={{fontSize: "0.75rem"}}>Cost Helper</div>
                            <div style={{fontSize: "0.6rem", color: grey["700"]}}>Similar Recent Purchases</div>
                        </Grid>
                        <Grid item style={{color: green["700"]}}>
                            <LightbulbOutlinedIcon color="inherit" />
                        </Grid>
                    </Grid>
                </div>

                <div style={{borderTop: "1px solid " + grey["200"]}}>
                    {data.loading ? <LinearProgress /> :
                        data.error ? <Alert color="error">
                                <div>{data.error}</div>
                                <Button onClick={data.reload}>Retry</Button>
                            </Alert> :
                        <>
                            {data.asList.map(rw => (
                                <div className={rowClass} onClick={() => {
                                    ctx.onSelectPrice(rw)
                                }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs>
                                            {rw.productCode} {rw.name}
                                        </Grid>
                                        <Grid item>
                                            {formatCents(rw.unitPriceCents)}
                                        </Grid>
                                    </Grid>

                                    <div style={{color: grey["700"], fontSize: "0.75rem"}}>
                                        {moment(rw.date).format("MMM D, YYYY")} -
                                        <Link onClick={e => {
                                            e.stopPropagation();
                                        }} style={{color: "inherit"}} to={poURL(rw.poId)}>PO#{rw.poNumber}</Link>
                                    </div>

                                </div>
                            ))}
                            {data.asList.length === 0 && <div style={{backgroundColor: grey["100"], padding: 4, textAlign: "center", fontSize: "0.8rem",color: grey["800"]}}>No results</div>}
                        </>}
                </div>
            </Paper>
        </Popper>
    );
}

const rowClass = css({
    fontSize: "0.9rem",
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderBottom: "1px solid " + grey["200"],
    cursor: "pointer",

    "&:hover": {
        backgroundColor: grey["200"],
    }
})
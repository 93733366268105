import {hasPermission, useUser} from "../../../../misc/Permission";
import {
    lookupNestedProp,
    Table,
} from "../../../../misc/scroller/Table";
import React, {useContext} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize2} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {commonGroupBy2, PriceRow} from "../../shopdrawing/pricing/Frames";
import {TDiv10Type} from "../../shopdrawing/div10/Div10Tabs";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {getColumns2} from "../../shopdrawing/pricing/Div10";
import {getQuotePricing, quotePriceChange} from "./QuotePriceRiderUtil";

export function QuoteDiv10(props: {
    type: TDiv10Type;
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative()
    const customize = usePriceCellCustomize2();


    if(!u) return null;

    const nameRender = (row: QuotePriceLine, key: keyof QuotePriceLine) => lookupNestedProp(row, key)
    const colInfo = getColumns2(props.type, true, u, nameRender as any, false);

    return (
        <Table<PriceRow>
            key={props.type}
            cellCustomize={customize}
            name={priceTableName(project, "div10", props.type)}
            globalPrefsName={priceTableName(0, "div10", props.type)}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={(ctx) => getQuotePricing(ctx, {
                project: project,
                alternative: alternative,
            })}
            columns={colInfo.columns}
            columnDeps={[props.type, u]}
            fetchDeps={[project, alternative]}
            groupBy={Object.assign({}, commonGroupBy2, {
                groupFilter: (groupName: string) => groupName === "div-10/" + props.type || groupName === "project",
            })}
            onChange={(input) => quotePriceChange(input, {
                project: project,
                alternative: alternative
            })}
        />
    )
}
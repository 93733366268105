import {api} from "../../../api/API";
import moment from "moment";
import {DashCardData} from "./DashCardData";;

export function Restocking() {
    return (
        <DashCardData
            lookup={() => api.logistics.listInventoryToRestock({
                belowMin: true,
            })}
            title="Restocking"
            itemTitle={d => d.name}
            itemSubtext={d => `Ready since ${moment(d.belowMinSince || undefined).format("MMMM D")}`}
            itemRight={d => d.qty + " items"}
            link="/logistics/inventory"
        />
    )
}


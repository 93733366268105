import {PriceLine, PriceRider} from "../../../../api/Pricing";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {
    centsParseValue,
    Column, FetchContext, Group,
    GroupByProp, lookupNestedProp,
    renderCents, sortStringInner,
    Table
} from "../../../../misc/scroller/Table";
import {formatCents} from "nate-react-api-helpers";
import {BeforeGroup, BeforeGroup2, markupColumnName} from "./BeforeGroup";
import {AfterGroup, AfterGroup2, costEachColumnName, qtyColumnName} from "./AfterGroup";
import {isNullOrUndefined, MarkupEditCell2, parseMarkup, priceTableName, profit} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext, useState} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {User} from "../../../../api/Users";
import {hollowMetalDoor, woodDoor} from "../../quote/openings/QuoteOpenings";
import {priceDetailEmitter} from "./UnPricedItems";
import {QuotePriceLine, QuotePriceRider} from "../../../../api/QuotePricing";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {ExtendedPriceEditor} from "./ExtendedPriceEditor";
import {deepOrange, green, red} from "@mui/material/colors";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {TableMoreBtn} from "../../quote/openings/RowActions";
import {defaultPriceSortFunc} from "../../quote/pricing/QuotePriceRiderUtil";

export type ProposingTag = "replace" | "new"| "remove" | "change"

export type PriceLine2 = PriceLine & ({
    kind: "current";
    proposingTag?: ProposingTag;
    previous: PriceLine;
    removal?: PriceLine;
    initial: PriceLine; // raw price line before display modifications
}  | {
    kind : "previous";
    proposingTag?: ProposingTag;
    current: PriceLine;
});


export function Frames() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();

    const proposal = useProposingKit()
    const previewChangeset = useContext(ChangePreviewContext);

    if(!u) return null;

    return (
        <Table<PriceRow>
            name={priceTableName(project, "frame")}
            globalPrefsName={priceTableName(0, "frame")}
            cellCustomize={proposal.cellCustomize}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={async (ctx) => getPricing(ctx, {
                project,
                transformRows: proposal.transformRows,
                previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined,
                customSort: rs => {
                    rs.sort((a, b) => {
                        if(a.productType === b.productType && a.productType === "frame-anchor") return 0;
                        if(a.productType === "frame-anchor") return 1;
                        if(b.productType === "frame-anchor") return -1;
                        return defaultPriceSortFunc(a, b);
                    });
                }
            })}
            columns={[
                PriceCol<PriceLine2>({
                    name: "",
                    price: o => proposal.rowAction(o, "frame", ""),
                    rider: (dt: QuotePriceRider, row: PriceRow) => {
                        return <RiderRowTitle row={row} />
                    },
                    createRider: (row) => {
                        return <AddRider row={row} />
                    },
                    width: 150,
                }),
                proposal.proposing && PriceCol<PriceLine2>({
                    name: "Opening",
                    price: d => {
                        return proposal.renderItemName(d, "openingName")
                    },
                    width: 150,
                }),
                PriceCol<PriceLine2>({
                    name: "Series",
                    price: dt => {
                        if(dt.productType === "frame-anchor") return `Anchor: ${dt.productCode}`;
                        if(dt.productType === "frame-prep") return `${dt.productName}: ${dt.productCode}`;
                        return dt.frame.series
                    },
                    width: 200,
                }),
                ViewPriceCol("Material", "frame.material"),
                ViewPriceCol("Gauge", "frame.gauge"),
                ViewPriceCol("Screen Elev", "frame.screenElev"),
                ViewPriceCol("Profile", "frame.profile"),
                ViewPriceCol("Jamb Depth", "frame.jambDepth"),
                ViewPriceCol("Construction", "frame.construction"),
                ViewPriceCol("Handing", "frame.handing"),
                ViewPriceCol("Label", "frame.label"),
                ViewPriceCol("Type", "frame.type"),
                ViewPriceCol("Height", "frame.height"),
                ViewPriceCol("Width", "frame.width"),
                ...commonPricingColumns2(u, false, proposal.proposing),
            ]}
            fetchDeps={[project, proposal.proposing, proposal.expanded, previewChangeset.enabled, previewChangeset.sessionId]}
            columnDeps={[u, proposal.proposing]}
            groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy2 : commonGroupBy2, {
                groupFilter: (groupName: string) => {
                    return groupName === "frame" || groupName.startsWith("frame/") || groupName === "project" ||
                        groupName === "frame-anchor" || groupName === "frame-totals" ||
                        groupName.startsWith("frame-anchor/") || groupName.startsWith("frame-prep")
                }
            })}
            onChange={(input) => priceChange(input, {
                processUpdate: proposal.processUpdate,
                project: project,
            })}
        />
    )
}

export async function priceChange(input: PriceRow, opts: {
    project: number;
    processUpdate: (input: PriceLine2) => PriceLine2
}) {
    if(input.kind === "price") {
        const result = await api.pricing.upsert(opts.processUpdate(input.value))
        return Object.assign({}, input, {
            value: result,
        })
    }

    if(input.kind === "price-rider") {
        const result = await api.pricing.upsertRider(input.value)
        return Object.assign({}, input, {
            value: result,
        })
    }

    if(input.kind === "price-rider-create") {
        const result = await api.pricing.upsertRider({
            id: 0,
            project: opts.project,
            productType: input.productType,
            sectionKey: input.sectionKey,
            name: input.name,
        } as any)

        return Object.assign({}, input, {
            value: result,
        })
    }

    throw new Error("unexpected type")
    return input
}

export async function getPricing(ctx: FetchContext, props: {
    project: number,
    previewChangeset?: number;
    transformRows: (input: PriceLine[]) => PriceLine2[],
    customSort?: (rs: PriceLine2[]) => void
}) {
    const {project} = props;
    const pricesP = getAll(ctx, offset =>
        api.pricing.list({project: project, offset, previewChangeset: props.previewChangeset})
    )

    const ridersP = getAll(ctx, offset =>
        api.pricing.listRiders({project: project, offset, previewChangeset: props.previewChangeset})
    )

    const rs = props.transformRows(await pricesP);
    const riders = await ridersP;

    if(props.customSort) {
        props.customSort(rs);
    } else {
        rs.sort(defaultPriceSortFunc)
    }

    priceDetailEmitter.emit(rs);
    const list = makeList(rs, riders, {
        includeCreate: !props.previewChangeset,
    });
    console.log("list", list);

    return list
}

function makeList(prices: PriceLine2[], riders: PriceRider[], opts: {
    includeCreate: boolean
}) {
    let out: PriceRow[] = [];
    let productType: string|null = null;
    let lastKey: SectionKey | null = null;
    let usedRiders: PriceRider[] = [];

    prices.forEach(p => {
        let key = getRowCategoryBase(p) as any

        if(p.productType === "hardware") {
            key = "hardware-after";
        }

        if(productType !== null && lastKey !== null && (productType !== p.productType || lastKey !== key)) {
            riders.filter(v => v.productType === productType && v.sectionKey === lastKey).forEach(r => {
                usedRiders.push(r);
                out.push({
                    id: out.length * -1,
                    defaultSort: out.length,
                    kind: "price-rider",
                    value: r,
                })
            })

            if(opts.includeCreate) {
                out.push({
                    defaultSort: out.length,
                    id: out.length * -1,
                    kind: "price-rider-create",
                    productType: productType,
                    sectionKey: lastKey,
                    name: "",
                })
            }
        }

        lastKey = key;
        productType = p.productType
        out.push({
            defaultSort: out.length,
            id: p.id,
            kind: "price",
            value: p,
        })
    })

    if(productType !== null && lastKey !== null) {
        riders.filter(v => v.productType === productType && v.sectionKey === lastKey).forEach(r => {
            usedRiders.push(r);
            out.push({
                defaultSort: out.length,
                id: out.length * -1,
                kind: "price-rider",
                value: r,
            })
        })

        if(opts.includeCreate) {
            out.push({
                defaultSort: out.length,
                id: out.length * -1,
                kind: "price-rider-create",
                productType: productType,
                sectionKey: lastKey,
                name: "",
            })
        }
    }

    riders.filter(v => !usedRiders.includes(v))
        .forEach(r => {
            out.push({
                defaultSort: out.length,
                id: out.length * -1,
                kind: "price-rider",
                value: r,
            })
        });

    return out
}


const prefixSort = byPrefix([
    "frame", "frame-prep", "frame-totals", "door", "door-prep", "door-totals", "hardware-before", "hardware", "hardware-after", "div-10", "project",
]);

export const AdditionSuffix = "/addition"
export const ChangeSuffix = "/change"
export const RemovalSuffix = "/removal"

function splitGroup(base: string) {
    return [base + AdditionSuffix, base + ChangeSuffix, base + RemovalSuffix];
}

function categorizeChange(base: string, row: PriceLine2) {
    switch(row.proposingTag) {
        case "new":
            return base + AdditionSuffix;
        case "replace":
        case "change":
            return base + ChangeSuffix;
        case "remove":
            return base + RemovalSuffix;
    }

    return base;
}

function numericCategory(value: string) {
    if(value.endsWith(AdditionSuffix)) return 1;
    if(value.endsWith(ChangeSuffix)) return 2;
    if(value.endsWith(RemovalSuffix)) return 3;
    return 4;
}

function hasChangeSuffix(value: string) {
    if(value.endsWith(AdditionSuffix)) return true;
    if(value.endsWith(ChangeSuffix)) return true;
    if(value.endsWith(RemovalSuffix)) return true;
    return false;
}

function removeSuffix(a: string) {
    return a.replace(AdditionSuffix, "").replace(ChangeSuffix, "").replace(RemovalSuffix, "");
}

function categorySort(a: string, b: string) {

    if(hasChangeSuffix(a) && hasChangeSuffix(b)) {
        const prefixA = removeSuffix(a)
        const prefixB = removeSuffix(b)

        if(prefixA !== prefixB) {
            return prefixA < prefixB ? 1 : -1;
        }

        return numericCategory(a) - numericCategory(b)
    }

    return prefixSort(a, b)
}

export const proposingGroupBy2: GroupByProp<PriceRow> = {
    fixedGroups: [
        ...splitGroup("frame"),
        ...splitGroup("door-hm"),
        ...splitGroup("door-wd"),
        ...splitGroup("door-other"),
        // hardware only has additions and removals, no changes
        ...splitGroup("hardware").filter(v => !v.endsWith(ChangeSuffix)),
        ...splitGroup('div-10/corner-guard'), ...splitGroup('div-10/locker'), ...splitGroup('div-10/mailbox'), ...splitGroup('div-10/wr-accessory'), ...splitGroup('div-10/wr-partition'),
        "project"
    ],
    groupFx: row => {
        if (row.kind === "price") {
            const value = row.value;
            const base = getRowCategoryBase(value, false);
            const group = categorizeChange(base, value)
            console.log('group', group)
            return group;
        }

        if(row.kind === "price-rider-create") {
            return row.sectionKey;
        }

        if(row.kind === "price-rider")  {
            return row.value.sectionKey + ChangeSuffix;
        }

        return "-"
    },
    groupSorter: (a, b, aGroup, bGroup) => {
        // can't change keys b/c it's too entrenched, but update the naming here so we can fix the sort order
        a = a.replace(/door-(wd|other|hm)/, "door/$1").replace(/^frame\//, "frame//")
        b = b.replace(/door-(wd|other|hm)/, "door/$1").replace(/^frame\//, "frame//")

        return categorySort(a, b)
    },
    beforeGroup: BeforeGroup2,
    afterGroup: AfterGroup2,
    hasBeforeGroup: (group: Group<PriceRow>) => {
        if(group.key === "project") return false;
        return true;
    },
    hasAfterGroup: (group: Group<PriceRow>) => {
        if(group.key.endsWith(AdditionSuffix)) return false;
        if(group.key.endsWith(ChangeSuffix)) return false;
        if(group.key.endsWith(RemovalSuffix)) return true;
        return true;
    }
}

export function getRowCategoryBase(row: PriceLine2 | PriceLine | QuotePriceLine, splitHardwareByMfg: boolean = true) {
    if(row.productType === "door") {
        if(row.door.series === hollowMetalDoor) return doorHm
        if(row.door.series === woodDoor) return doorWd
        return doorOther;
    }

    if(row.productType === "door-prep") {
        if(row.doorSeries === hollowMetalDoor) return "door-prep/hm"
        if(row.doorSeries === woodDoor) return "door-prep/wd"
        return "door-prep/other";
    }

    if(row.productType === "div-10") {
        return "div-10/" + (row.div10?.category || "wr-accessory");
    }

    if(row.productType === "hardware") {
        if(splitHardwareByMfg) {
            return "hardware/" + row.supplierName;
        }

        return "hardware";
    }

    return row.productType;
}

export const commonGroupBy: GroupByProp<PriceLine|QuotePriceLine> = {
    fixedGroups: [
        "frame", "frame-anchor", "frame-prep", "frame-totals",
        "door-hm", "door-wd", "door-other", "door-prep", "door-totals",
        "hardware-before", "hardware-after",
        'div-10/corner-guard', 'div-10/locker', 'div-10/mailbox', 'div-10/wr-accessory', 'div-10/wr-partition',
        "project"
    ],
    groupFx: row => {
        return getRowCategoryBase(row)
    },
    groupSorter: (a, b, aGroup, bGroup) => {
        if(a.startsWith("hardware/") && b.startsWith("hardware/")) {
            return bGroup.rows.length - aGroup.rows.length;
        }

        return prefixSort(a, b)
    },
    beforeGroup: BeforeGroup,
    afterGroup: AfterGroup,
    hasBeforeGroup: (group: Group<PriceLine|QuotePriceLine>) => {
        if(group.key === "project") return false;
        if(group.key === "hardware-after") return false;
        if(group.key === "frame-totals") return false;
        if(group.key === "door-totals") return false;
        return true;
    },
    hasAfterGroup: (group: Group<PriceLine|QuotePriceLine>) => {
        if(group.key === "hardware-before") return false;
        if(group.key.startsWith("hardware/")) return false
        return true;
    }
}

export type PriceRow = {
    id: number
    defaultSort: number;
} & ({
    kind: "price"
    value: PriceLine2
} | {
    kind: "quote-price"
    value: QuotePriceLine
} | {
    kind: "quote-price-rider";
    value: QuotePriceRider;
} | {
    kind: "quote-price-rider-create";
    productType: string;
    sectionKey: SectionKey;
    name: string;
} | {
    kind: "price-rider";
    value: PriceRider;
} | {
    kind: "price-rider-create";
    productType: string;
    sectionKey: SectionKey;
    name: string;
})

export type SectionKey = "frame"| "frame-anchor"| "frame-prep"| "frame-totals"|
    "door-hm"| "door-wd"| "door-other"| "door-prep"| "door-totals"|
    "hardware-before"| "hardware-after"|
    'div-10/corner-guard'| 'div-10/locker'| 'div-10/mailbox'| 'div-10/wr-accessory'| 'div-10/wr-partition'

export const commonGroupBy2: GroupByProp<PriceRow> = {
    fixedGroups: [
        "frame", "frame-anchor", "frame-prep", "frame-totals",
        "door-hm", "door-wd", "door-other", "door-prep", "door-totals",
        "hardware-before", "hardware-after",
        'div-10/corner-guard', 'div-10/locker', 'div-10/mailbox', 'div-10/wr-accessory', 'div-10/wr-partition',
        "project"
    ],
    groupFx: row => {
        if(row.kind === "quote-price-rider-create" || row.kind === "price-rider-create") return row.sectionKey;
        if(row.kind === "quote-price-rider" || row.kind === "price-rider") return row.value.sectionKey;
        return getRowCategoryBase(row.value)
    },
    groupSorter: (a, b, aGroup, bGroup) => {
        if(a.startsWith("hardware/") && b.startsWith("hardware/")) {
            return bGroup.rows.length - aGroup.rows.length;
        }

        return prefixSort(a, b)
    },
    beforeGroup: BeforeGroup2,
    afterGroup: AfterGroup2,
    hasBeforeGroup: (group: Group<PriceRow>) => {
        if(group.key === "project") return false;
        if(group.key === "hardware-after") return false;
        if(group.key === "frame-totals") return false;
        if(group.key === "door-totals") return false;
        return true;
    },
    hasAfterGroup: (group: Group<PriceRow>) => {
        if(group.key === "hardware-before") return false;
        if(group.key.startsWith("hardware/")) return false
        return true;
    }
}

export function isPriceLine2(input: PriceLine|PriceLine2|QuotePriceLine): input is PriceLine2 {
    return "kind" in input
}

export const doorHm = "door-hm";
export const doorWd = "door-wd";
export const doorOther = "door-other";

function byPrefix(prefixes: string[]) {
    const matchIndex = (search: string) => {
        let matchedI = Infinity;
        let matchedLen = 0;

        // try to find exact match first, then prefix match by longest prefix
        for(let i = 0; i < prefixes.length; i++) {
            if(search === prefixes[i])
                return i;

            if(search.startsWith(prefixes[i])) {
                if(prefixes[i].length >= matchedLen) {
                    matchedLen = prefixes[i].length;
                    matchedI = i;
                } else {
                    // ignore b/c we have a longer prefix match already
                }
            }
        }

        if(matchedI < prefixes.length+1) {
            return matchedI;
        }

        return prefixes.length + 1;
    }

    return (a: string, b: string) => {
        return matchIndex(a) - matchIndex(b)
    }
}

export const commonPricingColumns2: (u: User, isQuote: boolean, proposing?: boolean) => (Column<PriceRow>|false)[] = (u: User, isQuote: boolean, proposing: boolean = false) => [
    PriceCol({
        name: qtyColumnName,
        width: 100,
        alignRight: true,
        // @ts-ignore
        customizeKeepWhite: true,
        price: (o) => {
            const val = (lookupNestedProp(o, "qty") as any)
            if(!isQuote && val === 0) return "";
            return val?.toString()
        },
        rider: (dt, row) => {
            return dt.qty.toString();
        },
        editableFunc: (input: PriceRow) => {
            if(input.kind === "quote-price-rider" || input.kind === "price-rider") {
                return {
                    editable: {
                        type: "number",
                    },
                    editKey: "value.qty" as any,
                }
            }

            return null;
        }
    }),
    PriceCol({
        name: costEachColumnName,
        disabled: row => {
            if(row.kind === "price" || row.kind === "quote-price") {
                const dt = row.value;
                if (proposing && isPriceLine2(dt) && dt.kind === "current") {
                    if (dt.proposingTag === "remove") return true;
                    if (dt.proposingTag === "replace") return true;
                    if (dt.proposingTag === "change") return true;
                }
            }

            return false
        },

        price: (dt) => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return ""
                if(dt.proposingTag === "replace") return ""
                if(dt.proposingTag === "change") return ""
            }

            return renderCents(dt, "unitCostCents")
        },
        rider: (dt, row) => {
            return formatCents(dt.unitCostCents)
        },
        editableFunc: (dt: PriceRow) => {
            if(dt.kind === "price" || dt.kind === "quote-price") {
                return {
                    editable: {
                        type: "number",
                        parseValue: centsParseValue,
                    },
                    editKey: "value.unitCostCents" as any,
                }
            }

            if(dt.kind === "quote-price-rider" || dt.kind === "price-rider") {
                return {
                    editable: {
                        type: "number",
                        parseValue: centsParseValue,
                    },
                    editKey: "value.unitCostCents" as any,
                }
            }

            return null;
        },
        width: 80,
        alignRight: true,
    }),

    PriceCol({
        name: markupColumnName,
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return ""
                if(dt.proposingTag === "replace") return ""
                if(dt.proposingTag === "change") return ""
            }

            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
            return "auto"
        },
        rider: (dt, row) => {
            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
            return "auto"
        },
        disabled: row => {
            if(row.kind === "price" || row.kind === "quote-price") {
                const dt = row.value;
                if (proposing && isPriceLine2(dt) && dt.kind === "current") {
                    if (dt.proposingTag === "remove") return true;
                    if (dt.proposingTag === "replace") return true;
                    if (dt.proposingTag === "change") return true;
                }
            }

            return false
        },
        editableFunc: (dt: PriceRow) => {
            if(dt.kind === "price" || dt.kind === "quote-price" || dt.kind === "price-rider" || dt.kind === "quote-price-rider") {
                return {
                    editable: {
                        type: "custom",
                        copy: row => {
                            if(row.kind !== "price" && row.kind !== "quote-price" && row.kind !== "price-rider" && row.kind !== "quote-price-rider") return "";
                            const dt = row.value;

                            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                            return "auto"
                        },
                        paste: (row, value) => {
                            if(row.kind !== "price" && row.kind !== "quote-price" && row.kind !== "price-rider" && row.kind !== "quote-price-rider") return;
                            const rw = row.value;

                            Object.assign(rw, parseMarkup(value))
                        },
                        render: MarkupEditCell2 as any,
                    },
                    editKey: "" as any,
                }
            }

            return null;
        },
        alignRight: true,
        width: 80,
    }),
    PriceCol({
        name: "Unit Price",
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return ""
                if(dt.proposingTag === "replace") return ""
                if(dt.proposingTag === "change") return ""
            }

            return formatCents(dt.extendedPrice / fixZero(dt.qty))
        },
        rider: (dt, row) => {
            return ""
        },
        alignRight: true,
        width: 80,
    }),

    PriceCol({
        name: "Price",
        // @ts-ignore
        customizeKeepWhite: true,
        alignRight: true,
        width: 70,
        price: (dt) => {
            return formatCents(dt.extendedPrice)
        },
        rider: (dt) => {
            return formatCents(dt.extendedPrice)
        },
        editableFunc: (row: PriceRow) => {
            if(row.kind === "price" || row.kind === "quote-price" || row.kind === "price-rider" || row.kind === "quote-price-rider") {
                if (isQuote && !proposing) {
                    return {
                        editable: {
                            type: "number",
                            parseValue: centsParseValue,
                        },
                        editKey: "value.extendedPrice" as any,
                    }
                }

                return {
                    editable: {
                        type: "custom",
                        paste: (row, value) => {
                            // do nothing
                        },
                        copy: (row) => {
                            if(row.kind !== "price" && row.kind !== "quote-price") return "";
                            return formatCents(row.value.extendedPrice)
                        },
                        render: (props) => {
                            if(props.row.kind !== "price") return null as any;
                            const row = props.row;
                            const initialValue = props.row.value;

                            return <ExtendedPriceEditor value={initialValue as any} onDone={value => {
                                return props.onDone(Object.assign({}, row, {
                                    value: value as any,
                                }))
                            }} onCancel={props.onCancel} anchor={props.anchor} />
                        }
                    },
                    editKey: "" as any,
                }
            }

            return null;
        }
    }),

    proposing && PriceCol({
        name: "Price Desc.",
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            if(!isPriceLine2(dt)) return "";
            if(dt.kind !== "current") return "";
            switch(dt.proposingTag) {
                case "remove":
                    if(dt.extendedPrice === dt.previous.extendedPrice) {
                        return <div style={{fontSize: "0.8rem", color: red["700"], fontWeight: "700"}}>Full Refund</div>
                    }

                    return <div style={{fontSize: "0.8rem", color: deepOrange["700"], fontWeight: "700"}}>Partial Credit</div>
                case "new":
                    return <div style={{fontSize: "0.8rem", color: green["700"], fontWeight: "700"}}>Extra</div>
                case "change":
                case "replace":
                    if(dt.extendedPrice < 0) {
                        return <div style={{fontSize: "0.8rem", color: deepOrange["700"], fontWeight: "700"}}>Credit</div>
                    }

                    return <div style={{fontSize: "0.8rem", color: green["700"], fontWeight: "700"}}>Extra</div>
                default:
                    return "-"
            }
        },
        rider: () => "",
        width: 100,
    }),

    hasPermission(u, "CanViewProjectPricingProfit") && PriceCol({
        name: "Proj. Profit",
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            if(isPriceLine2(dt)) {
                if(dt.kind === "previous") return "";
            }

            return formatCents(profit(dt))
        },
        rider: () => "",
        alignRight: true,
        width: 90,
    }),

    hasPermission(u, "CanViewProjectPricingProfit") && PriceCol({
        name: "Act. Profit",
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            if(isNullOrUndefined(dt.revenueDelivered) || isNullOrUndefined(dt.costDelivered)) return "";
            return formatCents(dt.revenueDelivered - dt.costDelivered);
        },
        rider: () => "",
        alignRight: true,
        width: 90,
    }),
    PriceCol({
        name: "Proj. Margin",
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            let profit = dt.extendedPrice - dt.unitCostCents * dt.qty;
            if(profit === 0) return "0%"
            let margin = Math.round(profit / dt.extendedPrice * 100);
            return margin + "%"
        },
        rider: () => "",
        alignRight: true,
        width: 100,
    }),
    PriceCol({
        name: "Suggested Markup",
        // @ts-ignore
        customizeKeepWhite: true,
        price: dt => {
            return "35%"
        },
        rider: () => "",
        alignRight: true,
        width: 150,
    }),
    !isQuote && PriceCol({
        name: "Proposal Name",
        price: renderProposalName,
        rider: () => "",

        // @ts-ignore
        customizeKeepWhite: true,
        filter: (dt, str) => {
            if(dt.kind !== "price") return false;
            return renderProposalName(dt.value).toLowerCase().includes(str.toLowerCase())
        },
        width: 200,
    })
]

function fixZero(value: number) {
    if(value === 0) return 1;
    return value;
}

function renderProposalName(dt: PriceLine| QuotePriceLine) {
    const value = dt.projectChangesetActive ? "(current change proposal)" : dt.projectChangesetNote
    if(!value) return ""
    return value;
}

export function ViewPriceCol(name: string, key: string, width: number = 100) {
    return {
        name: name,
        width: width,
        render: (dt: PriceRow) => {
            if(dt.kind !== "quote-price") return null;
            return lookupNestedProp(dt.value, key as any)
        },
        sort: (a: PriceRow, b: PriceRow) => {
            if(a.kind === "quote-price" && b.kind === "quote-price") {
                return sortStringInner(lookupNestedProp(a.value, key as any) || "", lookupNestedProp(b.value, key as any) || "")
            }

            return makeSortValue(b.defaultSort - a.defaultSort);
        }
    }
}

function makeSortValue(value: number) {
    if(value < 0) return -1;
    if(value > 0) return 1;
    return 0;
}

export function ViewPriceBoolCol(name: string, key: string, width: number = 100) {
    return {
        name: name,
        width: width,
        render: (dt: PriceRow) => {
            if(dt.kind !== "quote-price") return null;
            return lookupNestedProp(dt.value, key as any) ? "Yes" : "No"
        }
    }
}

export function PriceCol<TPrice = QuotePriceLine | PriceLine2, TRider = QuotePriceRider>(props: {
    name: string;
    price?: (dt: TPrice) => any;
    rider?: (dt: TRider, row: PriceRow) => any;
    createRider?: (row: PriceRow) => any;
    width: number;
} & Partial<Column<PriceRow>>): Column<PriceRow> {
    const {price, rider, ...others} = props

    return {
        ...others,
        sort: (a, b) => {
            if(a.kind === "price" && b.kind === "price") {
                if(price) {
                    return sortStringInner(price(a.value as any)?.toString() || "", price(b.value as any)?.toString() || "")
                }

                return makeSortValue(b.defaultSort - a.defaultSort);
            }

            return makeSortValue(b.defaultSort - a.defaultSort);
        },
        render: (dt: PriceRow) => {
            if(dt.kind === "quote-price" || dt.kind === "price") {
                if(!price) return null
                return price(dt.value as any)
            }

            if(dt.kind === "quote-price-rider" || dt.kind === "price-rider") {
                if(!rider) return null
                return rider(dt.value as any, dt)
            }

            if(dt.kind === "quote-price-rider-create" || dt.kind === "price-rider-create") {
                if(props.createRider) {
                    return props.createRider(dt as any)
                }
            }

            return null
        },
    }
}

export function AddRider(props: {
    row: PriceRow
}) {
    const ctx = useContext(EditContext)
    const [show, setShow] = useState(false)
    const name = props.row.kind === "quote-price-rider" || props.row.kind === "price-rider" ? props.row.value.name : "";

    return (
        <div>
            {show && <EditRiderName onDone={async e => {
                await ctx.update(Object.assign({}, props.row, {
                    name: e,
                }))
                ctx.reload();
                setShow(false);
            }} onCancel={() => setShow(false)} name={name} />}

            <button style={{paddingLeft: 16, background: "none", border: "none", cursor: "pointer"}} onClick={() => setShow(true)}>Add Rider</button>
        </div>
    );
}

function EditRiderName(props: {
    name: string;
    onDone(name: string): void;
    onArchive?(): void;
    onCancel(): void;
}) {
    const [value, setValue] = useState(props.name);
    return (
        <Dialog open={true} onClose={props.onCancel}>
            <DialogTitle>
                Rider
            </DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <TextField label="Name" value={value} onChange={e => setValue(e.target.value)} />
            </DialogContent>
            <DialogActions>
                {props.onArchive && <Button onClick={props.onArchive} color="error">Archive</Button>}
                <div style={{flex: 1, minWidth: 40}} />
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button onClick={() => props.onDone(value)}>Done</Button>
            </DialogActions>
        </Dialog>
    )
}

export function RiderRowTitle(props: {
    row: PriceRow
}) {
    const ctx = useContext(EditContext)
    const [show, setShow] = useState(false)
    const row = props.row;
    if(row.kind !== "quote-price-rider" && row.kind !== "price-rider") return null;
    const name = row.value.name;

    return (
        <div style={{
            display: "flex",
            flexDirection: 'row',
            alignItems: "center",
            height: 14,
            overflow: "hidden",
        }}>
            {show && <EditRiderName
                onDone={e => {
                    ctx.update(Object.assign({}, row, {
                        value: Object.assign({}, row.value, {
                            name: e,
                        })
                    }))
                    setShow(false)
                }}
                onArchive={() => {
                    ctx.update(Object.assign({}, row, {
                        value: Object.assign({}, row.value, {
                            archived: true,
                        })
                    }))
                    setShow(false)
                }}
                onCancel={() => setShow(false)}
                name={name} />}

            <TableMoreBtn onClick={e => setShow(true)} />
            <div style={{width: 6}} />

            {name}
        </div>
    );
}
import {useContext, useState} from "react";
import {EditContext} from "../../../misc/scroller/EditProvider";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ProjectChooser} from "../purchasing/ProjectFilter";
import {Option} from "../../../misc/scroller/Table";
import {useNavigate} from "react-router-dom";
import {useSyncedRef} from "../../../misc/SyncedRef";

export function CreateDelivery(props: {
    onDone(): void;
}) {

    const [project, setProject] = useState<Option|null>(null);
    const projectRef = useSyncedRef(project);
    const ctx = useContext(EditContext);
    const navigate = useNavigate();

    const run = useAsyncAction(async () => {
        const value = await api.deliveries.updateDelivery({
            status: "draft",
            project: projectRef.current?.value as number,
        } as any);

        await ctx.reload();

        ctx.onSelect.emit({
            id: value.id,
            index: 100,
            value: value,
            editing: false,
        })

        navigate("/logistics/packing/" + value.id)

    }, [navigate]);

    return (
        <Dialog open>
            <DialogTitle>
                Create Delivery
            </DialogTitle>
            <DialogContent>
                <ProjectChooser value={project} onChange={setProject} />
            </DialogContent>
            <DialogActions>
                {run.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button disabled={!project} onClick={() => {
                    run.callback({});
                }}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}
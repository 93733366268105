import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {ReceiveItem2} from "./ReceiveItem2";
import {WarehouseReceiveItem} from "../../../api/Receiving";
import {EditReceivingItem} from "./EditReceivingItem";
import {cardStyle} from "../driver/RouteStop";
import {useGetAll} from "../routes/useGetAll";
import {useUser} from "../../../misc/Permission";

export function Receive() {
    const params = useParams<{id: string}>();
    const id = parseInt(params.id || "0");
    const receive = useAsync2(props => api.receiving.get(props as any), {id}, [id]);
    const items = useAsync2(props => api.receiving.listItems(props as any), {receive: id}, [id]);
    const receiveObj = receive.result;

    const [list, setList] = useState<WarehouseReceiveItem[]>([]);
    const [contextMenu, setContextMenu] = useState<WarehouseReceiveItem | null>(null);

    const srcList = items.asList;
    useEffect(() => {
        setList(srcList)
    }, [srcList]);

    const nav = useNavigate();

    const submit = useAsyncAction(async (input) => {
        await api.receiving.updateItems(input)
        nav("/logistics/receiving")
    }, []);

    const userRequest = useGetAll((input) => api.users.list(input), {}, []);
    const users = userRequest.asList;

    const [receivedBy, setReceivedBy] = useState(0);
    const usr = useUser();
    const usrId = usr?.id || 0;

    useEffect(() => {
        setReceivedBy(usrId)
    }, [usrId]);

    return (
        <MobileContainer topCenter={receive.result?.name || "Loading..."} back={`/logistics/receiving`}>
            {receive.LoadingElement}
            <Loader {...items}>
                {input => <>
                    {input.length === 0 &&
                        <div style={{padding: 8}}><Alert color="info">No items found (weird)</Alert></div>}
                </>}
            </Loader>

            <div style={{flex: 1, overflow: "auto"}}>
                <div style={{backgroundColor: "white"}}>
                    <div style={{padding: 4, fontSize: "0.9rem"}}>Long press items for more options
                    </div>
                    <table style={{width: "100%", borderCollapse: "collapse"}}>
                        <tbody>
                        {list.map(d =>
                            <ReceiveItem2
                                key={d.id.toString()}
                                value={d}
                                isPo={!!receiveObj?.deliveredPo}
                                onLongPress={() => setContextMenu(d)}
                                onEdit={item => {
                                    setList(list.map(i => i.id === item.id ? item : i))
                                }}
                            />
                        )}
                        </tbody>
                    </table>
                </div>
            </div>


            <div className={cardStyle} style={{padding: 16}}>
                <Loader {...submit} />
                <Select value={receivedBy || "-"} onChange={(e, value) => setReceivedBy(e.target.value as any)}>
                    <MenuItem value="-">Choose Receiver</MenuItem>
                    {users.map(u => <MenuItem value={u.id}>
                        {u.name}
                    </MenuItem>)}
                </Select>
                <div style={{height: 16}}/>
                <Button color="secondary" variant="contained"
                        fullWidth
                        disabled={!!list.find(v => v.qtyReceived === null)}
                        onClick={() => {
                            if(!receiveObj) return;
                            submit.callback({
                                items: list,
                                receivedBy: receivedBy,
                                receive: receiveObj.id
                            })
                    }}>
                    Receive
                </Button>
            </div>

            {contextMenu && <EditReceivingItem
                value={contextMenu}
                onChange={v => {
                    setList(old => old.map(o => o.id === v.id ? v : o))
                    setContextMenu(null);
                }}
                onCancel={() => setContextMenu(null)}
            />}
        </MobileContainer>
    )
}

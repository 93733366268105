import {PropsWithChildren} from "react";
import {Grid} from "@mui/material";
import {pdfInfo} from "./CoverPage";

export function PDFHeader(props: PropsWithChildren<{
    title: string;
    noAddress?: boolean
}>) {
    return (
        <>
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <img alt="Tykel Logo" src={pdfInfo.logo} style={{width: 150}} />
                    {props.noAddress ? <></> : <>
                        <div style={{height: 16}} />
                        <div>{pdfInfo.address1}</div>
                        <div>{pdfInfo.address2}</div>
                        <div>{pdfInfo.postal}</div>
                    </>}
                </Grid>
                <Grid item>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                        <div style={{fontSize: 30, fontWeight: "bold", marginBottom: 20}}>{props.title}</div>
                        {props.children}
                    </div>
                </Grid>
            </Grid>

            <div style={{height: 16}} />
        </>
    )
}

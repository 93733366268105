import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";

export class Contacts extends SubAPI {
    list(props?: {
        offset?: number;
        search?: string;
        company?: number
    }) {
        return this.fetcher.get<Paginated<Contact>>("/api/contacts", props);
    }

    upsert(input: Partial<Contact>) {
        return this.fetcher.post<Contact>("/api/contact", input)
    }

    get(props: {
        id: number;
    }) {
        return this.fetcher.get<Contact>("/api/contact", props);
    }

    setProjectLink(input: {
        contact: number;
        project: number;
        link: boolean;
    }) {
        return this.fetcher.post("/api/contact/set-project-link", input)
    }
}

export type Contact = {
    id: number;
    company: number;
    companyName: string;

    name: string;
    title: string;

    phone: string;
    email: string;

    linkToProject?: number
    siteContact: boolean;

    archived: boolean;
}
import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import React, {useState} from "react";

export function ProjectNameEdit(props: {
    value: Project;
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const [name, setName] = useState<string>(props.value.name || '');

    return (
        <Dialog open>
            <DialogTitle>Project Name</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This is the name that will be used to refer to this project throughout the system.
                </DialogContentText>
                <div style={{height: 8}} />

                <TextField label="Name"
                           value={name}
                           fullWidth
                           onChange={e => setName(e.target.value)} />

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        name: name || null,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
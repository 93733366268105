import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";

export class QuoteAlternatives extends SubAPI {

    getDefault(input: {
        project: number;
    }) {
        return this.fetcher.post<{id: number}>("/api/quote-alternative/default", input)
    }

    upsert(input: Partial<Alternative> & {
        from?: {
            id: number;
            sections: string[]
        }[]
    }) {
        return this.fetcher.post<Alternative>("/api/quote-alternative/upsert", input)
    }

    list(input: {
        project: number
        offset?: number;
        limit?: number
    }) {
        return this.fetcher.get<Paginated<Alternative>>("/api/quote-alternatives", input)
    }
}

export type Alternative = {
    id: number;
    name: string;
    description: string;
    archived: boolean;
}
import {useContext, useMemo} from "react";
import {
    BoolCol,
    Column,
    DateCol,
    Nullable, NumberCol,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {SnackContext} from "../../../../misc/Snackbar";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {SourceMode, ToSourceItem} from "../../../../api/Logistics";
import {ProjectCol, toSourceTableName} from "../PartsToSource";
import {CheckboxSelectorCol} from "../../../project/shopdrawing/release/Checkbox";
import {SimpleIconButton} from "../../../../misc/lists/SimpleIconButton";
import {api} from "../../../../api/API";
import {formatDescription} from "./SubmitButton";
import {purchasingPopoutPanel} from "../PurchaseOrderList";
import AddCircle from "@mui/icons-material/AddCircle";
import {getAll} from "../../../project/shopdrawing/openings/Openings";
import {TDiv10Type} from "../../../project/shopdrawing/div10/Div10Tabs";
import {InfoIcon} from "./InfoIcon";
import {DistributionMode} from "../DistributionModeToggle";

export function Div10(props: {
    purchaseOrder?: number;
    supplier?: {
        id: number;
        name: string;
    };
    sourceMode: SourceMode;
    distributionMode: DistributionMode;
    tab: TDiv10Type;
}) {
    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);
    const usr = useUser();

    const mfg = props.supplier?.id || 0;
    const key = useMemo(() => {
        return props.purchaseOrder + "-" + mfg + "-" + props.tab
    }, [props.purchaseOrder, mfg, props.tab])

    const supplier: any = [];
    if(!props.supplier) {
        supplier.push(ViewOnly(StringCol<ToSourceItem>("Supplier", "supplierName", 200)));
    }

    const isRestock = props.sourceMode === "stock"

    return (
        <Table<ToSourceItem>
            key={key}
            name={toSourceTableName}
            overridePrefsName={toSourceTableName + ".div10." + props.tab + "." + props.sourceMode}
            columns={[
                props.purchaseOrder ? CheckboxSelectorCol({
                    disabled: (row: ToSourceItem) => !hasPermission(usr, "CanReleaseAndPurchase") && usr?.id === row.releasedBy,
                }) : null,
                !isRestock && ViewOnly(ProjectCol("Project", "projectName", 100)),
                ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                ...supplier,
                ViewOnly(NumberCol("Qty", "qty", 60)),
                isRestock && ViewOnly(NumberCol("Stock", "stockQty", 60)),
                isRestock && ViewOnly(NumberCol("Rsrvd", "reservedQty", 60)),
                isRestock && ViewOnly(NumberCol("Pend.PO", "purchasedQty", 90)),
                isRestock && ViewOnly(NumberCol("Stock Min", "stockMinQty", 90)),
                isRestock && ViewOnly(NumberCol("Ideal Qty", "idealQty", 90)),
                ...perSectionColumns(props.tab),
                {
                    name: "Actions",
                    render: (dt: ToSourceItem, col: Column<ToSourceItem>) => (<div style={{display: "flex"}}>
                        <InfoIcon row={dt} />
                        {props.purchaseOrder && <div>
                            <SimpleIconButton disabled={!hasPermission(usr, "CanReleaseAndPurchase") && usr?.id === dt.releasedBy} onClick={async () => {
                                if(!props.purchaseOrder) return;

                                try {
                                    snack.loading();
                                    await api.logistics.updatePurchaseOrderItem({
                                        id: 0,
                                        purchaseOrder: props.purchaseOrder,
                                        reference: "",
                                        description: formatDescription(dt),
                                        code: dt.productCode,
                                        qty: dt.qty,
                                        unitPriceCents: dt.lastPrice || dt.lastPriceAtOthers || 0,
                                        inventoryRequests: dt.inventoryRequestId ? [dt.inventoryRequestId] : [],
                                        archived: false,
                                        updatedAt: (new Date()) as any,
                                        kind: dt.kind as any,
                                        qtyOutstanding: 0,
                                        createStockingInventoryRequestForProduct: isRestock ? dt.product : undefined,
                                    });

                                    purchasingPopoutPanel.sendToServer("refresh-po");
                                    ctx.refresh(toSourceTableName);
                                    snack.success("Added")
                                } catch(e: any) {
                                    snack.error(e.toString())
                                }
                            }}>
                                <AddCircle />
                            </SimpleIconButton>
                        </div>}
                    </div>),
                    width: 60,
                },
            ]}
            fetch={ctx => getAll(ctx, offset => api.logistics.listToSourceItems({
                offset,
                supplier: props.supplier?.id,
                type: "div10",
                subType: props.tab,
                sourceMode: props.sourceMode,
                distributionMode: props.distributionMode,
            }))}
            fetchDeps={[props.supplier, props.sourceMode, props.distributionMode]}
        />
    )
}

function perSectionColumns(tab: TDiv10Type): Column<ToSourceItem>[] {
    switch(tab) {
        case "wr-accessory":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Code", "productCode")),
                ViewOnly(StringCol("Category", "name")),
                ViewOnly(StringCol("Description", "description")),
                ViewOnly(StringCol("Finish", "finish")),
                ViewOnly(StringCol("Note", "div10Note")),
            ]
        case "wr-partition":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Mounting", "div10PartitionBracing")),
                ViewOnly(StringCol("Material", "div10PartitionCoating")),
                ViewOnly(StringCol("Description", "div10Description")),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        case "locker":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Tiers", "div10LockerTiers")),
                ViewOnly(StringCol("Material", "div10LockerCoating")),
                ViewOnly(BoolCol("Sloped Tops", "div10LockerSloped")),
                ViewOnly(BoolCol("End Panels", "div10LockerEndPanels")),
                ViewOnly(BoolCol("Base Trim", "div10LockerBaseTrim")),
                ViewOnly(StringCol("Width", "dimWidth", 60)),
                ViewOnly(StringCol("Height", "dimHeight", 60)),
                ViewOnly(StringCol("Depth", "dimLength", 60)),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        case "mailbox":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(NumberCol("Tenant Units", "div10MailboxTenantUnits")),
                ViewOnly(NumberCol("Parcel Units", "div10MailboxParcelUnits")),
                ViewOnly(StringCol("Loading", "div10MailboxLoading")),
                ViewOnly(StringCol("Mounting", "div10MailboxMounting")),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        case "corner-guard":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Type", "div10CornerGuardType")),
                ViewOnly(StringCol("Code", "productCode")),
                ViewOnly(StringCol("Finish", "div10CornerGuardFinish")),
                ViewOnly(StringCol("Width", "dimWidth")),
                ViewOnly(StringCol("Height", "dimHeight")),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        default:
            return [];
    }
}
import {PropsWithChildren, useContext, useState} from "react";
import {Grid, Radio} from "@mui/material";
import RadioGroupContext from "@mui/material/RadioGroup/RadioGroupContext";
import {blue} from "@mui/material/colors";

export function ShortCodeDetailSection(props: PropsWithChildren<{
    title: string;
    value: string;
}>) {
    const [radioRef, setRadioRef] = useState<any>(null)
    const radioCtx = useContext(RadioGroupContext);
    const selected = radioCtx?.value === props.value;

    return (
        <div
            style={{
                padding: 16,
                borderRadius: 8,
                backgroundColor: "white",
                paddingLeft: 8,
                width: "100%",
                marginBottom: 8,
                boxShadow: "1px 1px 5px 0px hsla(0, 0%, 0%, 0.2)",
                cursor: "pointer",
                border: selected ?  "1px solid " + blue["500"] : "1px solid transparent"
            }}
            onClick={e => {
                radioRef.getElementsByTagName("input")[0].click();
            }}
        >
            <Grid container spacing={1} flexWrap="nowrap">
                <Grid item>
                    <Radio ref={setRadioRef} value={props.value} onClick={e => e.stopPropagation()} />
                </Grid>
                <Grid item>
                    <div style={{
                        fontSize: "1rem",
                        marginBottom: 0,
                        marginTop: 6,
                    }}>{props.title}</div>
                    <div style={{cursor: "initial"}} onClick={e => e.stopPropagation()}>
                        {props.children}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
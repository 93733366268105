import {Legend} from "../../../project/shopdrawing/keying/submittal/Legend";
import {KeyingSchedule} from "../../../project/shopdrawing/keying/submittal/KeyingSchedule";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {KeyDetails} from "../../../../api/Keying";
import {KeyingPDFInfo} from "../../../project/shopdrawing/keying/submittal/KeyingSubmittal";
import {useMemo} from "react";

export function KeyingDetails(props: {
    value: KeyDetails;
    onReady(): void;
}) {
    const ready = useReadyCheck(props.onReady);
    const {info, names, rows} = props.value;

    const infoObj = useMemo((): KeyingPDFInfo => {


        if(!info) {
            return {
                version: 1,
                keyCount: [],
            }
        }

        return JSON.parse(info)
    }, [info])

    return (
        <>
            <Legend info={infoObj} names={names} noProject />
            <KeyingSchedule rows={rows} names={names} onReady={ready.section()} />
        </>
    )
}
import {ChangesetContainer} from "./ChangesetContainer";
import {useContext, useEffect, useMemo, useState} from "react";
import {ChangesetContext} from "./ChangesetContext";
import {formatCents, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {useProject, useProjectId} from "../ProjectName";
import {SummarizeSection} from "./SummarizeSection";
import {Button, Divider, TextField} from "@mui/material";
import {getContractor} from "./PrintSubmissions";
import {getPricingPDFCustom, sectionToItem} from "../pricing/PricingActions";
import {PrintManagerContext, PrintManagerProvider} from "../../../../misc/pdf/PrintManager";
import {ProjectProvider} from "../../ProjectProvider";
import {useNavigate} from "react-router-dom";
import {Section} from "../../../../api/ProjectChangeset";
import {useProposingKit} from "../pricing/ProposingKit";

export function SummarizeChanges() {
    return (
        <ChangesetContainer stage={"summarize-changes"}>
            <PrintManagerProvider>
                <ProjectProvider>
                    <SummarizeChangesInner />
                </ProjectProvider>
            </PrintManagerProvider>
        </ChangesetContainer>
    )
}


function SummarizeChangesInner() {
    const changeCtx = useContext(ChangesetContext)
    const projectId = useProjectId();
    const priceLines = useGetAll(input => api.pricing.list(input), {project: projectId}, [projectId])
    const proposingKit = useProposingKit();
    const proposingKitTransformRows = proposingKit.transformRows;

    const expected = useMemo(() => {
        const rows = proposingKitTransformRows(priceLines.asList)
        return rows.reduce((acc, line) => {
                return acc + line.extendedPrice
            }, 0);
    }, [proposingKitTransformRows, priceLines.asList]);

    const [sections, setSections] = useState<Section[]>([])
    const [notes, setNotes] = useState("")
    const [title, setTitle] = useState("")

    useEffect(() => {
        if(sections.length === 0) {
            setSections([{
                id: -1,
                name: "",
                description: "",
                amountCents: 0,
            }])
        }
    }, [sections]);

    const actual = sections.reduce((acc, s) => acc + s.amountCents, 0)
    const project = useProject();
    const printCtx = useContext(PrintManagerContext);
    const nav = useNavigate();
    const save = useAsyncAction(async (input) => {
        await api.projectChangeset.updateCustomerSummary(input)
        await api.projectChangeset.save({
            project: projectId,
            name: input.title,
            content: "",
        });

        nav(`/project/${projectId}/shop-drawing/changeset/${changeCtx.sessionId}/save-session`)
        return null;
    }, [projectId]);

    return (
        <div style={{padding: 24, maxWidth: 600, width: "100%", marginLeft: "auto", marginRight: "auto",
            display: "flex", flexDirection: "column", alignItems: "stretch",
            flex: 1,
        }}>
            <div style={{fontWeight: "500", fontSize: "1.2rem"}}>
                Customer Change Summary
            </div>
            <div style={{flex: 1, overflow: "auto"}}>
                <div style={{paddingTop: 8}}>
                    <TextField fullWidth label="Title"
                               size="small"
                               onChange={e => setTitle(e.target.value)}
                               value={title} />
                </div>

                {sections.map((s, index) => <SummarizeSection key={index.toString()} value={s}
                                                              onChange={setSections}/>)}

                <div>
                    <TextField fullWidth multiline minRows={4} label="Notes" onChange={e => setNotes(e.target.value)}
                               value={notes}
                               size="small"
                    />
                </div>
            </div>
            <Divider/>
            <div style={{display: "flex", justifyContent: "flex-end", paddingTop: 8}}>
                <table>
                    <tr>
                    <th style={{textAlign: "left", paddingRight: 10}}>Expected Total</th>
                        <td style={{textAlign: "right"}}>{formatCents(expected)}</td>
                    </tr>
                    <tr>
                        <th style={{textAlign: "left", paddingRight: 10}}>Actual Total</th>
                        <td style={{textAlign: "right"}}>{formatCents(actual)}</td>
                    </tr>
                    {expected !== actual && <tr>
                        <th style={{textAlign: "left", paddingRight: 10}}>Difference</th>
                        <td style={{textAlign: "right"}}>{formatCents(expected - actual)}</td>
                    </tr>}
                </table>
            </div>
            <div style={{display: "flex", paddingTop: 16}}>
                <Button onClick={() => {
                    if(!project) return;

                    printCtx.print2(getPricingPDFCustom({
                        project: project,
                        data: async () => {

                            const contractor = getContractor(project)
                            const quoteNumber = api.projects.getProjectNumber({project: project.id});
                            const data = sectionToItem(sections)
                            if(notes.trim() !== "") {
                                data.push({
                                    type: "generalNoteSection",
                                    value: notes,
                                    isFirst: false,
                                    group: undefined,
                                })
                            }

                            return {
                                title: title,
                                data: data,
                                project: project,
                                contractor: await contractor,
                                currentQuoteNumber: await quoteNumber,
                            }
                        },
                    }))
                }}>Export Sample PDF</Button>
                <div style={{flex: 1}} />
                {save.LoadingElement}
                <Button onClick={() => {
                    nav(`/project/${projectId}/shop-drawing/changeset/${changeCtx.sessionId}/review-pricing`)
                }}>Back</Button>
                <Button variant="contained" onClick={() => {
                    save.callback({
                        project: projectId,
                        sections: sections,
                        notes: notes,
                        title: title,
                    })
                }}>Save & Next</Button>
            </div>
        </div>
    )
}
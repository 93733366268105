import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../../api/API";
import {useGetAll} from "../../../../logistics/routes/useGetAll";
import {AsyncHelper} from "../AsyncHelper";
import {LogisticsLogItem, LogisticsSummary} from "../../../../../api/Logistics";
import {LogisticsSummaryItem} from "./LogisticsSummaryItem";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import moment from "moment";
import {Link} from "react-router-dom";
import {deliveryUrl, poUrl, routeUrl} from "./links";

export function Logistics(props: {
    opening?: number;
    miscProduct?: number;
    inventoryRequest?: number;
}) {

    const summaryReq = useAsync2((input) => api.logistics.logGetSummary(input),
        {
            opening: props.opening,
            miscProduct: props.miscProduct,
            inventoryRequest: props.inventoryRequest,
        },
        [props.opening, props.miscProduct, props.inventoryRequest]);

    const listItemReq = useGetAll((input) => api.logistics.logGetItems(input),
        {
            opening: props.opening,
            miscProduct: props.miscProduct,
            inventoryRequest: props.inventoryRequest,
        },
        [props.opening, props.miscProduct, props.inventoryRequest]);

    return (
        <div>
            <AsyncHelper<LogisticsSummary[]> {...summaryReq}>
                {sum => <>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}>
                        {sum.map((s, index) => {
                            if(s.po) {
                                return (
                                    <LogisticsSummaryItem key={index.toString()}
                                                          title={"PO #" + s.po.poNumber}
                                                          subTitle={moment(s.lastUpdated).format("MMM D, YYYY [at] ha")}
                                                          status={s.po.status}
                                                          link={poUrl(s.po.id)} />
                                )
                            }

                            if(s.route) {
                                return (
                                    <LogisticsSummaryItem
                                        key={index.toString()}
                                          title={"Route " + s.route.name}
                                          subTitle={moment(s.lastUpdated).format("MMM D, YYYY [at] ha")}
                                          status={s.route.completed ? "completed" : "pending"}
                                          link={routeUrl(s.route.id)} />
                                )
                            }

                            if(s.delivery) {
                                return (
                                    <LogisticsSummaryItem
                                        key={index.toString()}
                                          title={"Packing " + s.delivery.name}
                                          subTitle={moment(s.lastUpdated).format("MMM D, YYYY [at] ha")}
                                          status={s.delivery.status}
                                          link={deliveryUrl(s.delivery.id)} />
                                )
                            }

                            return null;
                        })}
                    </div>
                </>}
            </AsyncHelper>

            <AsyncHelper<LogisticsLogItem[]> {...listItemReq}>
                {list => <>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Ref</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Detail</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map(l => (<TableRow key={l.id}>
                                <TableCell style={{textAlign: "right"}}>{l.id}</TableCell>
                                <TableCell>{moment(l.createdAt).format("MMM D [at] h:mma")}</TableCell>
                                <TableCell>{l.createdByName}</TableCell>
                                <TableCell>
                                    {l.description}
                                </TableCell>
                                <TableCell>
                                    {l.route ? <Link to={routeUrl(l.route)}>Route #{l.route}</Link> :
                                        l.delivery ? <Link to={deliveryUrl(l.delivery)}>Pack #{l.delivery}</Link> :
                                            l.purchaseOrder ? <Link to={poUrl(l.purchaseOrder)}>PO #{l.poNumber}</Link> :
                                        null
                                    }
                                </TableCell>
                            </TableRow>))}
                            {list.length === 0 && <TableRow key="no-data">
                                <TableCell colSpan={5}>
                                    No History
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </>}
            </AsyncHelper>
        </div>
    )
}
import {SubAPI} from "./SubAPI";

export class Files extends SubAPI {
    downloadLink(id: number, type: "inline" | "attachment" = "attachment") {
        return "/api/file/download?id=" + id + "&type=" + type;
    }

    upload(input: {
        file: File;
    }) {
        return this.fetcher.postFormData<{id: number}>("/api/file", {
            file: input.file,
        })
    }
}

export type FileInfo = {
    id: number;
    name: string;
}
import {useContext} from "react";
import {useProject, useProjectId} from "../../ProjectName";
import {getAll} from "../../openings/Openings";
import {PDFInfo, PrintManagerContext} from "../../../../../misc/pdf/PrintManager";
import {distinct} from "nate-react-api-helpers";
import {api} from "../../../../../api/API";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Div10PDF} from "./Div10PDF";
import {useSubmittalPreferences} from "../../../../../misc/pdf/useSubmittalPreferences";
import {Project} from "../../../../../api/Projects";
import {Company} from "../../../../../api/Companies";
import {Div10Row} from "../../../../../api/QuoteDiv10";
import {getPDFClient} from "../../../../../misc/pdf/parts/CoverPage";

export function MakeSubmittal(props: {
    onDone(): void;
}) {
    const projectId = useProjectId();
    const printCtx = useContext(PrintManagerContext);
    const project = useProject();

    const prefs = useSubmittalPreferences({
        project: projectId,
        kind: "div10",
        onLoad: (list: {}) => {}
    });

    if(prefs.loading) return null;

    return (
        <Dialog open maxWidth="md" fullWidth>
            <DialogTitle>Create Div 8/10 Cut Sheets Submittal</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    No settings for this submittal
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDone}>Cancel</Button>
                <Button size="small" variant="contained"
                        color="primary" startIcon={<CheckCircleIcon />}
                        onClick={async () => {
                            await prefs.save({});

                            if(!project) return;

                            const client = await getPDFClient(project)

                            const items = await getAll(null, offset => api.div10.list({
                                project: project.id,
                                offset: offset,
                            }));

                            await printCtx.print2(div10PDF({
                                lastSubmittal: prefs.lastSubmission,
                                project: project,
                                client: client,
                                items: items,
                            }))

                            props.onDone();
                        }}
                >Create and Download</Button>
            </DialogActions>
        </Dialog>
    )
}

export function div10PDF(props: {
    project: Project
    client?: Company
    lastSubmittal: string | null;
    items: Div10Row[];
}): PDFInfo {

    return {
        name: `div-10-cut-sheets.pdf`,
        render: async onReady => <Div10PDF
            lastSubmittal={props.lastSubmittal}
            project={props.project}
            client={props.client} onReady={onReady}/>,
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "div10",
            appendCatalogPagesForProducts: distinct(props.items.map(i => i.product).filter(i => !!i)),
        }
    }
}
import {useParams} from "react-router-dom";
import { Hardware } from "./hardware/Hardware";
import { Logistics } from "./logistics/Logistics";
import {Openings} from "./openings/Openings";
import { Pricing } from "./pricing/Pricing";
import {Release} from "./release/Release";
import {ManagerLockProvider} from "./ProjectActions";
import {Misc} from "./misc/Misc";
import {Keying} from "./keying/Keying";
import {Div10} from "./div10/Div10";
import {Extra} from "./extras/Extra";

export function ShopDrawing() {
    return (
        <ManagerLockProvider>
            <Inner />
        </ManagerLockProvider>
    )
}

function Inner() {
    const params = useParams<{workspace: string}>();

    switch(params.workspace) {
        case "hardware":
            return <Hardware />
        case "logistics":
            return <Logistics />
        case "release":
            return <Release />
        case "pricing":
            return <Pricing />
        case "extras":
            return <Extra />
        case "misc":
            return <Misc />
        case "keying":
            return <Keying />
        case "div10":
            return <Div10 />
        default:
            return <Openings />
    }
}
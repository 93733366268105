import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react"
import {printStatus} from "./PrintManager";
import moment, {Moment} from "moment";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {Submission} from "../../api/Submissions";
import {useSyncedRef} from "../SyncedRef";
export function SubmissionDate() {
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        const sub = printStatus.subscribeAndFireLast(e => {
            if(!e.ignoreSubmissionDate) {
                setId(e.submission || null);
            }
        })

        return () => sub.cancel();
    }, [])

    if (!id) return null;

    return (<SubmissionDateEditor id={id} onDone={() => {
        printStatus.emit({done: true})
    }} />)
}

export function SubmissionDateEditor(props: {
    id: number;
    previous?: Submission;
    onDone(): void;
}) {

    const id = props.id;
    const [date, setDate] = useState<null | Moment>(() => {
        const date = props.previous?.submittedDate;
        if(date) {
            return moment.utc(date);
        }

        return moment()
    });
    const [enabled, setEnabled] = useState(props.previous && !props.previous.submittedDate ? "no" : "yes");
    const onDoneRef = useSyncedRef(props.onDone);

    const setSubmitted = useAsyncAction(async (input) => {
        await api.submission.setSubmitted(input)
        onDoneRef.current();
    }, []);

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault()
                if(enabled === "yes") {
                    if(!date) return;

                    setSubmitted.callback({
                        id: id,
                        date: date.format("YYYY-MMM-DD")
                    })

                    return
                }

                setSubmitted.callback({
                    id: id,
                    date: null,
                })
            }}>
                <DialogTitle>Was this sent to a client?</DialogTitle>
                <DialogContent>

                    <Divider />
                    <Tabs value={enabled} onChange={(e, value) => setEnabled(value)}>
                        <Tab label="Yes" value="yes" />
                        <Tab label="No" value="no" />
                    </Tabs>
                    <Divider />


                    <div style={{height: 8}}></div>
                    {enabled === "yes" && <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />}
                </DialogContent>
                <DialogActions>
                    {setSubmitted.LoadingElement}

                    <Button disabled={enabled === "yes" && !date} color="primary" variant="contained" type="submit">
                        {props.previous ? "Update" : "Submit"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import {EventEmitter} from "nate-react-api-helpers";
import {useEffect} from "react";

export class PopoutInteractor<TServerMessage, TClientMessage> {
    chan: BroadcastChannel;
    fromServer = new EventEmitter<TServerMessage>();
    fromClient = new EventEmitter<TClientMessage>();
    window: WindowProxy | null = null;
    url: string;
    name: string;

    constructor(name: string, url: string) {
        this.url = url;
        this.name = name;
        this.chan = new BroadcastChannel(name);
        this.chan.addEventListener("message", (e) => {
            console.log(e.data.from, e.data.payload);

            if(e.data.from === "server") {
                this.fromServer.emit(e.data.payload);
            } else if(e.data.from === "client") {

                if(e.data.payload === "ping" && this.fromServer.lastValue) {
                    this.sendToClient(this.fromServer.lastValue);
                    return;
                }

                this.fromClient.emit(e.data.payload);
            }
        })
    }

    sendToClient(input: TServerMessage) {
        console.log("send", input);
        this.fromServer.emit(input);

        this.chan.postMessage({
            source: "tykel",
            from: "server",
            payload: input,
        })
    }

    sendToServer(input: TClientMessage | "ping") {
        this.chan.postMessage({
            source: "tykel",
            from: "client",
            payload: input,
        })
    }

    close() {

        if(this.window) {
            try {
                this.window.close();
            } catch (e) {}

            this.window = null;
            return;
        }
    }

    open() {
        if(this.window && !this.window.closed) {
            this.window.focus();
            return;
        }

        this.window = window.open(this.url, this.name, `left=50,top=50,width=${Math.min(Math.round(window.screen.availWidth* 3/4), 1000)},height=${Math.round(window.screen.availHeight * 2/3)}`)
        window.addEventListener("unload", () => {
            this.window?.close();
        })
    }
}

export function usePopout<T,U>(panel: PopoutInteractor<T,U>) {

    useEffect(() => {
        return () => {
            panel.close();
        }
    }, [panel])

    return panel;
}
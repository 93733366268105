import {Alert, Button, LinearProgress} from "@mui/material";
import React, {PropsWithChildren} from "react";

export function Loader<T>(props: {
    loading: boolean;
    error: any;
    reload?(): void;
    result?: T|null;
} & ({
    children?: (input: T) => any;
} | {})) {
    if(props.loading) {
        return (
            <div style={{minWidth: 100}}>
                <LinearProgress variant="indeterminate"/>
            </div>
        )
    }

    if(props.error) {
        return (
                <Alert color="error">
                    <div>{props.error}</div>
                    {props.reload && <div><Button onClick={props.reload}>Retry</Button></div>}
                </Alert>
        )
    }

    if(!props.result) return null;
    // @ts-ignore
    if(!props.children) return null;

    // @ts-ignore
    return <>{props.children(props.result)}</>;
}

export function SmoothLoader<T>(props: {
    loading: boolean;
    error: any;
    reload?(): void;
    result?: T|null;
} & ({
    children?: (input: T) => any;
} | {})) {

    // @ts-ignore
    return <React.Fragment key="outer">
        {props.loading && <div style={{minWidth: 100}} key="loading">
            <LinearProgress variant="indeterminate"/>
        </div>}
        {!props.loading && !!props.error && <Alert color="error" key="error">
            <div>{props.error}</div>
            {props.reload && <div><Button onClick={props.reload}>Retry</Button></div>}
        </Alert>}
        {// @ts-ignore
            !!props.children && !!props.result && <React.Fragment key="data">
            {// @ts-ignore
                props.children(props.result)}
        </React.Fragment>}
    </React.Fragment>;
}

export function ActionLoading(props: PropsWithChildren<{
    loading: boolean;
    error: any;
}>) {
    if(props.loading) {
        return (
            <div style={{minWidth: 100}}>
                <LinearProgress variant="indeterminate"/>
            </div>
        )
    }

    return <>
        {!!props.error && <Alert color="error">
            <div>{props.error}</div>
        </Alert>}
        {props.children}
    </>
}
import {CreateProduct} from "../../project/shopdrawing/extras/CreateProduct";
import {api} from "../../../api/API";
import {EditContext} from "../../../misc/scroller/EditProvider";
import {useContext} from "react";

export function CreateStockingHardwareProduct(props: {
    onDone(): void
}) {
    const edit = useContext(EditContext)

    return <CreateProduct mode="hardware" onCancel={props.onDone} onDone={async (p) => {
        p.isStocking = true;
        await api.products.upsert(p)

        edit.reload();
        props.onDone();
    }} />;
}
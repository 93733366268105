import {ProjectDir, ProjectFile, ProjectFileKind} from "../../../../api/ProjectFiles";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FileColumn} from "./FileColumn";
import {first} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {FileDetail} from "./FileDetail";
import {useFileStickySelected} from "./FileStickyState";

export type ProjectFileSystem = {
    files: ProjectFile[]
    directories: ProjectDir[];
}

export function FileColumns(props: {
    list: ProjectFileSystem;
    project: number;
    kind: ProjectFileKind;
    noApproval?: boolean;
    small?: boolean;
    onChange(): void;
}) {
    const [selected, setSelected] = useState<ProjectDir[]>([]);
    const [nextSelect, setNextSelect] = useState<null|number>(null);
    const [fileSelected, setFileSelected] = useState<number|null>(null);
    const [selectedId, setSelectedId] = useState<number|null>(null);

    const listRef = useSyncedRef(props.list);
    const selectId = useCallback((id: number) => {
        let file: ProjectFile|null = null;
        let list: ProjectDir[] = [];
        let cursor = first(listRef.current.directories, l => l.id === id);
        if(!cursor) {
            file = first(listRef.current.files, l => l.id === id);
            if(file) {
                cursor = first(listRef.current.directories, l => l.id === file?.directory);
            }

            if(!cursor) return false;
        }

        while(true) {
            list.push(cursor)
            if(!cursor.parent) break;

            const parent = first(listRef.current.directories, d => d.id === cursor?.parent)
            if(!parent) break;

            cursor = parent;
        }

        setSelectedId(id);
        setSelected(list.reverse());
        setFileSelected(file?.id || null);
        return true;
    }, [listRef]);

    useFileStickySelected({
        project: props.project,
        kind: props.kind,
        list: props.list,
        selectedId: selectedId,
        selectId: selectId,
    });

    const fsRoot = useMemo(() =>  first(props.list.directories, d => !d.parent) as ProjectDir|null, [props.list])

    useEffect(() => {
        if(!fsRoot) return;

        if(nextSelect) {
            selectId(nextSelect);
            return;
        }

        setSelected(old => {
            if(old.length === 0) return [fsRoot]

            const dirs = old.map(prevDir => {
                return first(props.list.directories, d => d.id === prevDir.id)
            })

            if(dirs.indexOf(null) !== -1) return [fsRoot]
            return dirs as ProjectDir[];
        });
        setFileSelected(null);
    }, [props.list, nextSelect, selectId, fsRoot]);

    const fileSelectedObj = useMemo(() => first(props.list.files, f => f.id === fileSelected),
        [fileSelected, props.list.files]);

    return (
        <div style={{
            height: props.small ? 300 : 400,
            display: "flex", alignItems: "stretch", justifyContent: "flex-start", flexWrap: "nowrap",
            overflowX: "auto",
            width: "100%",
        }}>

            {selected.map((s, index) =>
                <FileColumn project={props.project}
                            content={props.list}
                            selected={selected}
                            onChange={(select) => {
                                setNextSelect(select||null);
                                props.onChange();
                            }}
                            key={index.toString()}
                            kind={props.kind}
                            onDirSelect={(item) => {
                                selectId(item.id);
                                setNextSelect(item.id);
                            }}
                            onFileSelect={(file) => {
                                setNextSelect(file.id);
                                selectId(file.id);
                            }}
                            selectedFile={fileSelectedObj}
                            value={s} />)}

            {selected.length > 0 && !fileSelected && selected[selected.length-1] !== fsRoot && <FileDetail
                file={selected[selected.length-1]} onChange={(nextId) => {
                    setNextSelect(nextId || fileSelected||null);
                    props.onChange();
                }}
            />}

            {fileSelectedObj && <FileDetail noApproval={props.noApproval} onChange={(nextId?) => {
                setNextSelect(nextId || fileSelected||null);
                props.onChange()
            }} file={fileSelectedObj} />}
        </div>
    )
}


import {Container} from "../../../misc/Container";
import {useQueryParam} from "../../project/quote/pricing/QueryParam";
import {useParams} from "react-router-dom";
import {api} from "../../../api/API";
import {WhiteColumn} from "../purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import {CentsCol, NumberCol, PrefixSearch, StringCol, Table, ViewOnly} from "../../../misc/scroller/Table";
import {StockCountItem} from "../../../api/Logistics";
import {Checkbox2Col} from "../../project/shopdrawing/ShopTableCol";
import {RestockStatus} from "../warehouse/Restocking";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import React from "react";
import {useAsync2} from "nate-react-api-helpers";
import moment from "moment";

export function StockCountReview() {
    return (
        <Container>
            <StockCountReviewInner />
        </Container>
    )
}

function StockCountReviewInner() {
    const [tab2, setTab2] = useQueryParam("tab2", "hardware");
    const params = useParams<{id: string}>();
    const stockCount = parseInt(params.id || "0");
    const info = useAsync2(async input => {
        const list = await getAll(null, offset => api.logistics.listStockCounts({offset}))
        return list.find(v => v.id === input.id)
    }, {
        id: stockCount,
    }, [stockCount])

    const r = info.result;
    const date = r ? moment(r.createdAt).format("MMM D, YYYY") : "";
    const createdByName = r ? "By " + r.createdByName : "";

    return (
        <WhiteColumn>
            <SectionHeader
                name={date + " Stock Count"}
                underName={createdByName}
            />
            <TabWrapper>
                <Tabs value={tab2} onChange={(_, v) => setTab2(v)}>
                    <Tab value="frame" label="Frames" />
                    <Tab value="door" label="Doors" />
                    <Tab value="hardware" label="Hardware" />
                    <Tab value="div-10" label="Div 10" />
                    <Tab value="frame-anchor" label="Anchors" />
                    <Tab value="component" label="Components" />
                </Tabs>
            </TabWrapper>
            <Table<StockCountItem>
                key={"stockCount." + tab2}
                name={"stockCount." + tab2}
                columns={[
                    ViewOnly(StringCol("Mfg", "manufacturerName", 100)),
                    ViewOnly(StringCol("Description", "name", 100)),
                    ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                    ViewOnly(StringCol("Dim.", "dimensions", 200)),
                    ViewOnly(NumberCol("Expect Qty", "qtyExpected", 60)),
                    ViewOnly(NumberCol("Actual Qty", "qtyCounted", 60)),
                    Checkbox2Col<StockCountItem>("Checked", "checked"),
                    ViewOnly(CentsCol("Cost", "changeCostCents", 60)),
                    RestockStatus(),
                    ViewOnly(NumberCol("Min Qty", "stockMinQty", 40)),
                    ViewOnly(NumberCol("Ideal Qty", "idealQty", 40)),
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listStockCountItems({
                    offset,
                    kind: tab2 as any,
                    stockCount,
                }))}
                fetchDeps={[tab2, stockCount]}
            />
        </WhiteColumn>
    )
}
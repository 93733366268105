import {MenuItem, TextField} from "@mui/material";
import {Popover} from "../Popover";
import {Option} from "./Table";
import {useState} from "react";

export function SelectEditor(props: {
    anchor: any;
    onBlur(): void;
    onSelect(value: string): void;

    freeSolo?: boolean;
    options: Option[];
    initialValue: string;
    value: any;
}) {
    const [search, setSearch] = useState("");
    const [showInitial, setShowInitial] = useState(true);

    const opts = props.options.filter(opt => {
        return (search === "" || opt.display.toLowerCase().indexOf(search.toLowerCase()) === 0);
    });
    const valueIndex = opts.findIndex(o => o.value === props.value);
    const [keyIndex, setKeyIndex] = useState(valueIndex);
    const hasFreeSolo = !!props.freeSolo && !!search

    return (
        <Popover
            anchorEl={props.anchor}
            open
            style={{
                fontSize: "0.5rem",
            }}
            PaperProps={{
                style: {
                    minWidth: props.anchor.getBoundingClientRect().width,
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={props.onBlur}
        >
            <form onSubmit={e => {
                e.preventDefault()
                const val = opts[keyIndex];
                if(val) {
                    props.onSelect(val.value);
                    return;
                }
            }}>
                <TextField fullWidth autoComplete="off"
                   autoFocus
                   onKeyDown={e => {
                        if(e.key === "ArrowUp") {
                            if(keyIndex < 0) {
                                return; // do nothing
                            }

                            setKeyIndex(keyIndex-1);
                            return;
                        } else if(e.key === "ArrowDown") {
                            setKeyIndex(keyIndex+1);
                        }

                    }}
                   onChange={(e) => {
                        const val = e.target.value;
                        setSearch(val);
                        setShowInitial(false);

                        if(val !== "") {
                            setKeyIndex(0);
                        } else {
                            setKeyIndex(valueIndex)
                        }

                    }} value={showInitial ? (props.initialValue || "") : search} />
                {opts.map((o, index) =>
                    <MenuItem
                        onClick={() => props.onSelect(o.value)}
                        selected={keyIndex === index}
                        key={o.value}
                        value={o.value}
                        style={{fontSize: "0.8rem"}}>{o.display}</MenuItem>)}
                {hasFreeSolo && <MenuItem
                    key="free-solo"
                    onClick={() => props.onSelect(search)}
                    value={search}
                    selected={keyIndex === opts.length}
                    style={{fontSize: "0.8rem"}}
                >
                        {search}
                </MenuItem>}
                {search && opts.length === 0 && !hasFreeSolo && <div style={{padding: 8, fontSize: "0.8rem", textAlign: "center"}}>No matches</div>}
            </form>
        </Popover>
    )
}
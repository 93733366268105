import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import HistoryIcon from "@mui/icons-material/History";
import {SubmittalHistory} from "../../shopdrawing/SubmittalHistory";
import {useState} from "react";

export function QuoteSubmittalHistory() {
    const [show, setShow] = useState(false)

    return (
        <>
            <SmallGreyButton onClick={() => setShow(true)} startIcon={<HistoryIcon />}>
                Submittal History
            </SmallGreyButton>
            {show && <SubmittalHistory onClose={() => setShow(false)} />}
        </>
    )
}
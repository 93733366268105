import React, {useEffect, useMemo, useRef} from "react";
import {grey} from "@mui/material/colors";
import {css} from "@emotion/css";
import {usePageInstance} from "./PageContext";
import ReactDOM from "react-dom";

export const pdfPageElementClassName = "pdf-page-element";

export const pagePortraitInnerHeight = 800;

export const pdfTableStyle = css({
    width: "100%",
    borderSpacing: 0,
    borderCollapse: "collapse",
    "& > * > * > *": { // all cells
        paddingLeft: 4,
        paddingRight: 4,
    },

    "& > thead > * > *": {
        border: "1px solid " + grey["700"],
        textAlign: "center",
        fontSize: "0.8rem",
        fontWeight: "bold",
    },

    "& > tbody > * > *": {
        borderLeft: "1px solid " + grey["700"],
        borderRight: "1px solid " + grey["700"],
    },

    "& > tbody > *:last-child > *": {
        borderBottom: "1px solid " + grey["700"],
    },

    "& > tfoot > * > *": {
        border: "1px solid " + grey["700"],
    },

    "& > tbody > .table-footer > *": {
        border: "1px solid " + grey["700"],
    },

    "& .pdf-header": {
        fontWeight: "bold",
        fontSize: "0.8rem",
    },

    "& > tbody > tr:nth-child(2n+1):not(.table-footer) > *": {
        backgroundColor: grey["300"]
    }
})

export const pdfTableStyleNoAlternating = css({
    width: "100%",
    borderSpacing: 0,
    borderCollapse: "collapse",
    "& > * > * > *": { // all cells
        paddingLeft: 4,
        paddingRight: 4,
    },

    "& > thead > * > *": {
        border: "1px solid " + grey["700"],
        textAlign: "center",
        fontSize: "0.8rem",
        fontWeight: "bold",
    },

    "& > tbody > * > *": {
        borderLeft: "1px solid " + grey["700"],
        borderRight: "1px solid " + grey["700"],
    },

    "& > tbody > *:last-child > *": {
        borderBottom: "1px solid " + grey["700"],
    },

    "& > tfoot > * > *": {
        border: "1px solid " + grey["700"],
    },

    "& > tbody > .table-footer > *": {
        border: "1px solid " + grey["700"],
    },

    "& .pdf-header": {
        fontWeight: "bold",
        fontSize: "0.8rem",
    },
})

export const pageClass = css({
    breakAfter: "page" as "page",
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
})

export const breakStyle = {
    portrait: css({
        page: "narrow",
    }),
    landscape: css({
        page: "wide",
    })
}

const landscapeHeight = "7.6in";
const portraitHeight = "9.5in";

function getMaxHeight(orientation: "landscape" | "portrait") {
    const measureDiv = document.createElement("div");
    measureDiv.style.cssText = "position: absolute; top: -10000000px;";

    if(orientation === "landscape") {
        measureDiv.style.width = landscapeHeight;
    } else {
        measureDiv.style.width = portraitHeight;
    }

    document.body.appendChild(measureDiv);
    const width = measureDiv.offsetWidth;
    measureDiv.remove();

    return width;
}

export const inner = {
    portrait: css({
        minHeight: portraitHeight,
        width: landscapeHeight,
        overflow: "hidden",
        flex: 1,
    }),
    landscape: css({
        minHeight: landscapeHeight,
        width: "10.5in", // different from portraitHeight b/c we buffer that to prevent overflows
        overflow: "hidden",
        display: "flex",
    })
}

export const innerStyle = {
    "landscape": css({
        flex: 1,
        display: "flex",
    }),
    "portrait": css({
        flex: 1,
        display: "flex",
    })
}

const watermark = css({
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    fontSize: "8.5vw",
    fontWeight: "bold",
    color: grey["200"],
    zIndex: -1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(-45deg)",
})

export function Page(
  props: React.PropsWithChildren<{
    orientation?: "portrait" | "landscape";
    watermark?: JSX.Element;
    skipPageNumbers?: boolean;
    debug?: string;
  }>
) {

  const orientation = props.orientation || "landscape";
  const innerClass = innerStyle[orientation];
  const pageRef = useRef<HTMLDivElement|null>(null);
  const page = usePageInstance();

  const max = useMemo(() => getMaxHeight(orientation), [orientation]);

  useEffect(() => {
      page.renderStart();

      const tm = setInterval(() => {
          const cur = pageRef.current
          if(!cur) return;

          clearInterval(tm);

          // force rendering of the DOM before we measure heights
          ReactDOM.flushSync(() => {});

          const scrollHeight = cur.scrollHeight
          page.renderComplete(scrollHeight, max);
      }, 100);

      return () => clearInterval(tm);
  })

  return (
    <div
        className={breakStyle[orientation] + " " + pdfPageElementClassName + " " + pageClass}
    >
        {props.watermark && (
          <div className={watermark}>
            <div>{props.watermark}</div>
          </div>
        )}
        <div className={inner[orientation]} ref={r => {
            pageRef.current = r;
        }}>
            <div className={innerClass}>
                {props.children}
            </div>
        </div>
    </div>
  );
}
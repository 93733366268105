import {Paginated} from "../../../api/Users";
import {AsyncResult2, useAsync2} from "nate-react-api-helpers";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {useRef} from "react";

export function useGetAll<T, U>(
    input: (input: U & {offset: number}) => Promise<Paginated<T>>,
    props: U,
    propDeps: any[]
): AsyncResult2<T[]> {
    const lastCtx = useRef({cancelled: false});

    return useAsync2((props) => {
        lastCtx.current.cancelled = true;
        const ctx = {
            cancelled: false,
        };

        lastCtx.current = ctx;
        return getAll(ctx, offset => input(Object.assign({},  props, {offset: offset})));
    }, props, propDeps)
}
import {grey} from "@mui/material/colors";
import {Grid, IconButton} from "@mui/material";
import {PropsWithChildren} from "react";
import {css} from "@emotion/css";
import {RefreshRouteItems} from "./RouteItems";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {RouteStop} from "../../../api/Logistics";

export function RouteItemRow(props: PropsWithChildren<{
    icon: any;
    title: any;
    value: RouteStop;
    next: RouteStop | null;
    prev: RouteStop | null;
    disabled?: boolean;
}>) {
    const snack = useSnackbar();
    const updateSort = async (newSort: number) => {
        try {
            snack.loading();
            await api.logistics.upsertStop(Object.assign({}, props.value, {
                sortOrder: newSort,
            }));

            RefreshRouteItems.emit(null);

            snack.success("Updated!")
        } catch (e: any) {
            snack.error(e.toString());
        }
    }

    return (
        <div style={{fontSize: "0.8rem"}}>
            <div style={{paddingLeft: 12, backgroundColor: grey["100"], paddingBottom: 4, paddingTop: 4}}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <div style={{fontSize: "1.1rem", height: "1em", overflow: "hidden"}}>
                            {props.icon}
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div style={{paddingBottom: 4, paddingTop: 4}}>
                            {props.title}
                        </div>
                    </Grid>
                    <Grid item>
                        {props.prev && <IconButton size="small" disabled={props.disabled} onClick={() => {
                            if(!props.prev) return;
                            updateSort(props.prev.sortOrder);
                        }}>
                            <KeyboardArrowUp />
                        </IconButton>}
                        {props.next && <IconButton size="small" disabled={props.disabled} onClick={async () => {
                            if(!props.next) return;
                            updateSort(props.next.sortOrder);
                        }}>
                            <KeyboardArrowDown />
                        </IconButton>}
                    </Grid>
                </Grid>
            </div>
            <div style={{paddingLeft: 37}} className={rowItemsClass}>
                {props.children}
            </div>
        </div>
    )
}

const rowItemsClass = css({
    "& table": {
        width: "100%",
        "& td:nth-child(1)": {
            width: 200,
        },
        "& td:nth-child(2)": {
            width: 80,
            textAlign: "right",
        },
        "& td:nth-child(4)": {
            textAlign: "right",

            "& .actions": {
                display: "inline-flex",
                height: 16,
                alignItems: "center",
            }
        },
    }
})
import {SourceHeader, tabGroup} from "../purchasing/PartsToSource";
import React, {useState} from "react";
import {Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import {CheckboxProvider} from "../../project/shopdrawing/release/Checkbox";
import {Door} from "./Door";
import {Frame} from "./Frame";
import {Hardware} from "./Hardware";
import {SubmitButton} from "./SubmitButton";
import {defaultDiv10Tab, Div10Tabs, TDiv10Type} from "../../project/shopdrawing/div10/Div10Tabs";
import {css} from "@emotion/css";
import {FrameAnchors} from "./FrameAnchors";
import {Div10} from "./Div10";

export const toDeliverTableName = "logistics.to-deliver"
export type ToDeliverProps = {
    delivery?: number;
    project?: number;
};

export function ToDeliver(props: ToDeliverProps) {
    const [tab, setTab] = useState("hardware")
    const [tab2, setTab2] = useState<TDiv10Type>(defaultDiv10Tab)

    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            <SourceHeader>
                Parts to Deliver
            </SourceHeader>
            <CheckboxProvider>
                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                }}>
                    <TabWrapper>
                        <Tabs className={tabGroup} value={tab} onChange={(e, value) => {
                            setTab(value)
                            setTab2(defaultDiv10Tab)
                        }}>
                            <Tab label="Frames" value="frame" />
                            <Tab label="Doors" value="door" />
                            <Tab label="Hardware" value="hardware" />
                            <Tab label="Div 10" value="div10" />
                            <Tab label="Anchors" value="frame-anchors" />
                            <div style={{flex: 1}} />
                        </Tabs>
                        <div className={rightGroup}>
                            <SubmitButton delivery={props.delivery} />
                        </div>
                    </TabWrapper>
                    {tab === "div10" && tab2 && <Div10Tabs tab={tab2} onChange={setTab2} />}

                    <div style={{flex: 1, display: "flex", overflow: "hidden"}}>
                        {tab === "frame" ? <Frame key={props.delivery || "(null)"} {...props} /> :
                            tab === "door" ? <Door key={props.delivery || "(null)"} {...props} /> :
                                tab === "hardware" ? <Hardware key={props.delivery || "(null)"} {...props} /> :
                                    tab === "frame-anchors" ? <FrameAnchors key={props.delivery || "(null)"} {...props} /> :
                                        tab === "div10" ? <Div10 key={props.delivery || "(null)"} div10Type={tab2} {...props} /> : null
                        }
                    </div>
                </div>
            </CheckboxProvider>
        </div>
    )
}

const rightGroup = css({
    display: "flex",

    "& .MuiButtonBase-root": {
        paddingTop: 4,
        paddingBottom: 4,
        minHeight: 0,
        fontSize: "0.7rem",
    }
})


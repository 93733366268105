import {ProductQuery} from "../../../../api/ProjectTimeline";
import React, {useEffect, useMemo} from "react";
import {Autocomplete, createFilterOptions, MenuItem, Select, TextField} from "@mui/material";
import {distinct, useAsync2} from "nate-react-api-helpers";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {api} from "../../../../api/API";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {Category} from "../../../../api/Products";

export function MakeProductQuery(props: {
    value: ProductQuery;
    onChange(value: ProductQuery): void;
    onMinMatchingDate(value: Date|null): void;
}) {

    const projectId = useProjectId();

    const list = useAsync2(async (input) => {
        return api.projectTimeline.queryProducts(input)
    }, {
        query: props.value,
        project: projectId,
    }, [props.value]);

    const onMinMatchingDate = props.onMinMatchingDate;
    useEffect(() => {
        let minDate: Date | null = null;

        list.asList.map(l => {
            if(!l.neededBy) return;

            const dt = new Date(l.neededBy);
            if(minDate === null) {
                minDate = dt;
                return;
            } else if(dt.valueOf() < minDate.valueOf()) {
                minDate = dt;
            }
        })

        onMinMatchingDate(minDate)
    }, [list.asList, onMinMatchingDate])

    const [propertyList, setPropertyList] = React.useState<string[]>([]);

    useEffect(() => {
        if(propertyList.length === 0) {
            setPropertyList(Object.keys(props.value)
                // @ts-ignore
                .filter(k => !!props.value[k]));
        }
    }, [props.value, propertyList.length]);

    const hwCategories= useGetAll((input) => api.products.categories(input), {
        project: projectId,
        kind: "hardware" as any,
    }, [projectId]);

    const div10Categories= useGetAll((input) => api.products.categories(input), {
        project: projectId,
        kind: "div-10" as any,
    }, [projectId]);

    return (
        <div style={{paddingTop: 8}}>
            <table className={tableCls}><tbody>
                {propertyList.map((prop: any, index) => <tr key={prop}>
                    <td style={{width: 150}}>
                        <PropertySelect used={propertyList} value={prop} onChange={v => {
                            if(v === "-") {
                                setPropertyList(propertyList.filter(v => v !== prop));
                                return;
                            }

                            const copyList = propertyList.slice(0)
                            copyList[index] = v;
                            setPropertyList(copyList);
                        }} />
                    </td>
                    <td style={{width: 400}}>
                        <QueryAutoComplete
                            prop={prop} value={props.value} onChange={props.onChange}
                            hwCategories={hwCategories.asList}
                            div10Categories={div10Categories.asList}
                        />
                    </td>
                </tr>)}
                <tr>
                    <td>
                        <PropertySelect used={propertyList} value={"-"} onChange={v => {
                            if(v === "-") return;
                            setPropertyList([...propertyList, v]);
                        }} />
                    </td>
                    <td />
                </tr>
            </tbody></table>

            <div style={{marginTop: 8}}>
                Sample of Matches:
            </div>
            {list.LoadingOrErrorElement}
            <table className={resultTableCss}>
                <thead>
                <tr>
                    <th>Opening</th>
                    <th>Type</th>
                    <th>Product</th>
                    <th>Code</th>
                    <th>Qty</th>
                </tr>
                </thead>
                <tbody>
                {list.asList.slice(0, 3).map(l => <tr key={l.inventoryRequest}>
                    <td>
                        {l.openingName || "-"}
                    </td><td>
                        {l.kind}
                    </td><td>
                        {l.productName || l.productCategoryName}
                    </td><td>
                        {l.productCode}
                    </td><td style={{textAlign: "right"}}>
                        {l.qty}
                    </td>
                </tr>)}
                </tbody>
            </table>
            <div style={{paddingLeft: 2, fontSize: "0.8rem"}}>
                {list.asList.length} total matches
            </div>
        </div>
    )
}

const resultTableCss = css({
    width: "100%",
    borderCollapse: "collapse",

    "& > thead th": {
        borderBottom: "1px solid " + grey["300"],
        fontSize: "0.8rem",
        textAlign: "left",
    },

    "& td": {
        paddingLeft: 4,
        paddingRight: 4,
        padding: 2,
        fontSize: "0.9rem",
    },
    "& > tbody > tr:nth-child(even) > td": {
        backgroundColor: grey["100"]
    }
})

const filter = createFilterOptions<string>();

function QueryAutoComplete(props: {
    prop: string;
    value: ProductQuery;
    hwCategories: Category[];
    div10Categories: Category[];
    onChange(value: ProductQuery): void;
}) {
    const opts = useMemo(() => {

        const obj = propertyMap[props.prop] as any;

        if(obj && props.prop === hwCategoryKey) {
            obj.options = props.hwCategories.map(c => c.name);
        } else if(obj && props.prop === div10CategoryKey) {
            obj.options = props.div10Categories.map(c => c.name);
        }

        // @ts-ignore
        const baseOpts = propertyMap[props.prop]?.options || blankArray
        // @ts-ignore
        const list = props.value[props.prop] || blankArray;

        return distinct([...baseOpts, ...list]) as string[];
    }, [props.prop, props.value, props.hwCategories, props.div10Categories]);

    const prop = props.prop;

    const trueVar = true as any;

    return (
        <Autocomplete<string>
            multiple={trueVar}
            freeSolo={trueVar}
            options={opts}
            getOptionLabel={(option) => option}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                    filtered.push(`${inputValue}`);
                }

                return filtered;
            }}
            fullWidth
            defaultValue={
                // @ts-ignore
                props.value[props.prop] || blankArray}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Value"
                    placeholder="HM Door, D102..."
                />
            )}
            onChange={(e, value, reason) => {
                if(reason === "clear") {
                    const copy = {...props.value} as any;
                    // @ts-ignore
                    copy[prop] = [];
                    props.onChange(copy);
                    return;
                }

                const copy = {...props.value};

                // @ts-ignore
                if(value instanceof Array) {
                    // @ts-ignore
                    copy[prop] = value;
                } else {
                    // @ts-ignore
                    copy[prop] = [...copy[prop], value];
                }

                props.onChange(copy);
            }}
        />
    )
}

const blankArray = [] as any;

const tableCls = css({
    "& td": {
        padding: 4,
    }
})

export function PropertySelect(props: {
    used: string[];
    value: string;
    onChange(value: string): void
}) {

    return (
        <Select value={props.value} fullWidth onChange={e => {
            props.onChange(e.target.value as any);
        }}>
            {[
                <MenuItem value="-" onClick={() => {
                    props.onChange("-");
                }}>Filter By</MenuItem>,
                ...properties.filter(v => !props.used.includes(v) || v === props.value).map(p => <MenuItem key={p} value={p}>
                    {// @ts-ignore
                        propertyMap[p]?.name}
                </MenuItem>)
            ]}
        </Select>
    )
}

const hwCategoryKey = "hardwareCategory";
const div10CategoryKey = "div10Category";

type PropMap = {
    [key: string]: {
        name: string;
        options: string[];
    };
};

const propertyMap: PropMap = {
    "type": {
        name: "Type",
        options: ["Hardware", "Door", "Frame", "Div10"]
    },
    [hwCategoryKey]: {
        name: "Hardware Category",
        options: [], // filled by API
    },
    [div10CategoryKey]: {
        name: "Div10 Category",
        options: [],
    },
    "div10Type": {
        name: "Div10 Type",
        options: [
            "W/R Accessories",
            "W/R Partitions",
            "Lockers",
            "Mailboxes",
            "Corner Guards"
        ],
    },
    "openingNames": {
        name: "Opening Name",
        options: ["e.g. D102..."]
    },
    "openingLocationOne": {
        name: "Location One",
        options: ["e.g. Suite..."]
    },
    "openingLocationTwo": {
        name: "Location Two",
        options: ["e.g. Hall..."]
    },
    "floor": {
        name: "Floor",
        options: ["e.g. FL01"]
    },
    "doorSeries": {
        name: "Door Series",
        options: ["HM Door", "WD Door"]
    },
    "frameSeries": {
        name: "Frame Series",
        options: ["HM Frame", "WD Frame"]
    },
}

const properties = Object.keys(propertyMap);
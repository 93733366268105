import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {Page} from "../../../../misc/pdf/parts/Page";
import {table} from "../../../../misc/pdf/parts/TableStyle";
import {PDFHeader} from "../../../../misc/pdf/parts/PDFHeader";
import {FrameAndDoorDetail} from "./FrameDetails";
import {distinct, orderByAscending, selectMany} from "nate-react-api-helpers";
import {isNormalHingePrep, noPrepNeeded, PrepKey} from "../../../project/shopdrawing/extras/PrepMenuOptions";
import {useMemo} from "react";
import {PurchaseOrderItem} from "../../../../api/Logistics";

export function DoorDetails(props: {
    value: FrameAndDoorDetail[]
    onReady(tf: boolean): void;
}) {
    if(props.value.length === 0) {
        setTimeout(() => props.onReady(true), 100);
        return null;
    }

    return (
        <PageRenderProvider key="door-details" data={props.value} onReady={props.onReady}>
            {(info: DataSplitInfo<FrameAndDoorDetail>) =>
                <Page orientation="landscape" key={info.pageIndex.toString()}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                        <PDFHeader noAddress title="Door Detail" />
                        <table className={table}>
                            <tbody>
                            <tr key="head">
                                {tableCfg.map(r => <th key={r.name}>{r.name}</th>)}
                            </tr>
                            {info.rows.map((r, index) => <tr key={info.pageIndex + "-" + index.toString()}>
                                {tableCfg.map(col => <td key={col.name}>{col.selector(r)}</td>)}
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                </Page>
            }
        </PageRenderProvider>
    )
}

export type TblCfg<T> = {
    name: string
    selector: (r: T) => string
}

const tableCfg = [
    {name: "Opening", selector: (r) => r.name},
    {name: "Qty", selector: (r) => r.qty.toString()},
    {name: "Type", selector: (r) => r.type},
    {name: "Width", selector: (r) => r.purchaseOrderItem.dimWidth},
    {name: "Height", selector: (r) => r.purchaseOrderItem.dimHeight},
    {name: "Handing", selector: (r) => r.purchaseOrderItem.doorHanding},
    {name: "Label", selector: (r) => r.purchaseOrderItem.doorLabel},
    {name: "Series", selector: (r) => r.purchaseOrderItem.doorSeries},
    {name: "Material", selector: (r) => r.purchaseOrderItem.doorMaterial},
    {name: "Gauge", selector: (r) => r.purchaseOrderItem.doorGauge},
    {name: "Thickness", selector: (r) => r.purchaseOrderItem.doorThickness},
    {name: "Elevation", selector: (r) => r.purchaseOrderItem.doorElevation},
    {name: "Core", selector: (r) => r.purchaseOrderItem.doorCore},
    {name: "Seam", selector: (r) => r.purchaseOrderItem.doorSeam},
    {name: "Hardware Preps", selector: (r) => makeHardwarePreps(r)},
] as TblCfg<FrameAndDoorDetail>[]

function makeHardwarePreps(r: FrameAndDoorDetail) {
    return distinct(r.doorHardwarePreps.filter(v => {
        // leave v.name for historical products
        if(v.name === noPrepNeeded || v.prepKey === PrepKey.SurfaceAutoOpCloseReinf || v.prepKey === PrepKey.None || v.prepKey === PrepKey.CloserReinforcing) return false;

        const isActive = !!(r.purchaseOrderItem.openings?.find(v => {
            return v.subType === "active"
        }))

        // no electric strikes on active doors
        if (isActive) {
            if (v.prepKey === PrepKey.ElectricStrike || v.prepKey === PrepKey.ElectricStrikeASA) {
                return false;
            }
        }

        return true;
    }).map(v => {
        if(v.name.includes("Custom")) {
            return v.name + ": " + v.productCode;
        }

        const hasCustomHingeQty = !!(r.purchaseOrderItem.openings?.find(v => {
            if(v.subType === "active") {
                return !!v.hasCustomActiveHingeQty;
            }

            if(v.subType === "inactive") {
                return !!v.hasCustomInActiveHingeQty;
            }

            return false;
        }))

        if(hasCustomHingeQty && isNormalHingePrep(v.name, v.prepKey)) {
            return v.name + " (Custom Locations)";
        }

        return v.name
    })).join(", ")
}

export function doorDetailsCSV(items: FrameAndDoorDetail[]): string[][] {
    return [
        tableCfg.map(r => r.name),
        ...items.map(r =>
            tableCfg.map(col => col.selector(r))
        )
    ]
}

export function useDoorDetails(items: PurchaseOrderItem[]): FrameAndDoorDetail[] {
    return useMemo(() => {
        const dr: FrameAndDoorDetail[] = selectMany(items.filter(v => v.kind === "door").map(i => {
            if(!i.openings || i.openings.length === 0) return [];

            return i.openings.map((op): FrameAndDoorDetail => {

                let name = op.name
                if(op.type === "Pair") {
                    name = `${op.name} (${op.subType})`
                }

                return {
                    opening: op.id,
                    openingSequence: op.seqNumber,
                    openingLocationTransition: op.locationTransition,
                    openingType: op.type,
                    qty: op.qty,
                    name: name,
                    purchaseOrderItem: i,
                    type: op.type,
                    doorHardwarePreps: op.doorHardwarePreps,
                    frameHardwarePreps: [],
                }
            })
        }), v => v);

        orderByAscending(dr, v => v.openingSequence)
        return dr;
    }, [items]);
}
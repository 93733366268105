import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTableData} from "../../../../misc/scroller/Table";
import {currentTab, openingsTableName} from "../openings/Openings";
import {Opening} from "../../../../api/Openings";
import {useProjectId} from "../ProjectName";
import {useIsQuote} from "../../quote/QuoteContainer";
import {currentQuoteTab, quoteOpeningsTableName} from "../../quote/openings/QuoteOpenings";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {SzSubmittalLoader} from "./SzSubmittalLoader";

export function OpeningSizeSubmittal(props: {
    onDone(): void;
}) {
    const project = useProjectId();
    const quote = useIsQuote();
    const openings = useTableData(quote ? quoteOpeningsTableName(project, currentQuoteTab) : openingsTableName(project, currentTab)) as (Opening|QuoteOpening)[]

    const missingDimensions = openings.filter(o => {
        if(o.screenElevation !== "") {
            if(o.screenElevationOutsideHeight === "") return true;
            if(o.screenElevationOutsideWidth === "") return true;
        }

        return false;
    })

    if(openings.length === 0) return null;

    if(missingDimensions.length > 0) {
        const haveScreen: string[] = [];
        const byScreen = missingDimensions.filter(v => {
            if(haveScreen.includes(v.screenElevation)) return false;
            haveScreen.push(v.screenElevation);
            return true;
        })

        return (
            <Dialog open onClose={props.onDone}>
                <DialogTitle>
                    Opening Size Submittal
                </DialogTitle>
                <DialogContent>
                    <div>
                        The following openings are missing dimensions:
                        <ul>
                            {byScreen.map(o => <li key={o.id}>{o.screenElevation}</li>)}
                        </ul>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onDone}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return <SzSubmittalLoader onDone={props.onDone} />
}
import {Checkbox, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ColumnConfig} from "../../api/TableConfig";
import {Draggable} from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export function CustomizeColumnsCard(props: {
    index: number;
    id: any
    checked: boolean
    hasBefore: boolean;
    hasAfter: boolean
    column: ColumnConfig & {name: string};
    onCheckToggle(): void;
}) {
    return (
        <Draggable draggableId={props.id} index={props.index}>
            {(provided: any, snapshot: any) =>
                (<ListItemButton
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{...provided.draggableProps.style}}
                onClick={e => props.onCheckToggle()}
            >
                <DragIndicatorIcon />
                <ListItemIcon>
                    <Checkbox
                        size="small"
                        checked={props.checked}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText style={{margin: 0, paddingRight: 16}} primary={props.column.name}
                              secondary={props.checked ? "Visible" : "Hidden"} />
            </ListItemButton>)}
        </Draggable>
    );
}
import { DriverRoute} from "../../../api/Logistics";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {SnackContext} from "../../../misc/Snackbar";
import {api} from "../../../api/API";
import { Button, Divider, Grid} from "@mui/material";
import {DateField} from "../../../misc/DateField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {routePopupPanel} from "./RouteList";
import {usePopout} from "../../../misc/PopoutInteractor";
import {useSyncedRef} from "../../../misc/SyncedRef";

export function RouteDetailHeader(props: {
    value: DriverRoute;
    onReload(): void
}) {
    const selected = props.value;
    const onReload = useSyncedRef(props.onReload);

    const value = useMemo(() => {
        if(!selected) return {} as any;
        return Object.assign({}, selected);
    }, [selected]);
    const [lastUpdate, setLastUpdate] = useState<number>();

    const snack = useContext(SnackContext);

    const update = useCallback((prop: keyof DriverRoute) => {
        return async (newValue: any) => {
            // @ts-ignore
            value[prop] = newValue;
            setLastUpdate(Date.now());
        }
    }, [value]);

    useEffect(() => {
        const tm = setTimeout(async () => {
            if(Object.keys(value).length === 0) return;
            if(value.id <= 0) return;
            if(!lastUpdate) return;

            try {
                await api.logistics.updateRoute(value);
                onReload.current();
            } catch (e: any) {
                snack.error(e.toString())
            }
        }, 500);

        return () => clearTimeout(tm);
    }, [lastUpdate, onReload, value, snack]);

    const ctrl = usePopout(routePopupPanel);

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <form onSubmit={e => {
                e.preventDefault();
            }} style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <div style={{padding: 16}}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={1}>Route #{value.id}</Grid>
                        <Grid item xs={3}>
                            <DateField fullWidth label="Date" format="ddd, MMM D" initialValue={value.date} onChange={update("date")} />
                        </Grid>
                        <Grid item xs>
                            <Button variant="outlined" endIcon={<OpenInNewIcon />}
                                    onClick={() => {
                                        ctrl.open();
                                        ctrl.sendToClient({
                                            route: props.value.id,
                                        })
                                    }} disabled={!isEditable(props.value)}>
                                To Pickup/Deliver
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div style={{flex: 1, display: "flex"}}></div>
            </form>
        </div>
    )
}

function isEditable(value: DriverRoute) {
    return !value.completed
}
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../../api/API";
import {Company} from "../../../../../api/Companies";
import {useRef, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Grid
} from "@mui/material";
import {ProvinceSelector} from "../../../../clients/ProvinceSelector";

export function CreateMfgDialog(props: {
    value: string;
    onDone(c: Company): void;
    onCancel(): void;
}) {

    const onDoneRef = useRef(props.onDone)
    onDoneRef.current = props.onDone;

    const [name, setName] = useState(props.value);
    const [shortName, setShortName] = useState("");
    const [addressStreet, setAddressStreet] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressProvince, setAddressProvince] = useState("");
    const [addressPostal, setAddressPostal] = useState("");
    const [sourceHardware, setSourceHardware] = useState(true);
    const [sourceFrames, setSourceFrames] = useState(false);
    const [sourceDoors, setSourceDoors] = useState(false);
    const [sourceDoorsWood, setSourceDoorsWood] = useState(false);
    const [sourceDiv10, setSourceDiv10] = useState(false);

    const create = useAsyncAction(async (input: Partial<Company>) => {
        const co = await api.companies.upsert(input)
        onDoneRef.current(co)
    }, []);

    return (
        <Dialog open onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault()
                create.callback({
                    name: name,
                    shortName: shortName,
                    addressStreet: addressStreet,
                    addressCity: addressCity,
                    addressProvince: addressProvince,
                    addressPostal: addressPostal,
                    sourceHardware: sourceHardware,
                    sourceFrames: sourceFrames,
                    sourceDoors: sourceDoors,
                    sourceDoorsWood: sourceDoorsWood,
                    sourceDiv10: sourceDiv10,
                    kind: "supplier",
                })
            }}>
                <DialogTitle>
                    Create Manufacturer
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 4}} />
                    <Grid container spacing={1} direction="column">
                        <Grid item>
                            <TextField label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth />
                        </Grid>
                        <Grid item>
                            <TextField label="Short Name" value={shortName} onChange={e => setShortName(e.target.value)} />
                        </Grid>
                        <div style={{height: 10}} />
                        <Grid item>
                            <TextField label="Street Address" value={addressStreet} onChange={e => setAddressStreet(e.target.value)} fullWidth />
                        </Grid>
                        <Grid item>
                            <TextField label="City" value={addressCity} onChange={e => setAddressCity(e.target.value)} fullWidth />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <ProvinceSelector value={addressProvince} onChange={setAddressProvince} />
                                </Grid>
                                <Grid item>
                                    <TextField label="Postal" value={addressPostal} onChange={e => setAddressPostal(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{height: 10}} />
                    <FormControlLabel control={<Checkbox checked={sourceHardware} onChange={(_, checked) => setSourceHardware(checked)} />} label="Hardware" />
                    <FormControlLabel control={<Checkbox checked={sourceFrames} onChange={(_, checked) => setSourceFrames(checked)} />} label="Frames" />
                    <FormControlLabel control={<Checkbox checked={sourceDoors} onChange={(_, checked) => setSourceDoors(checked)} />} label="Doors" />
                    <FormControlLabel control={<Checkbox checked={sourceDoorsWood} onChange={(_, checked) => setSourceDoorsWood(checked)} />} label="WD Doors" />
                    <FormControlLabel control={<Checkbox checked={sourceDiv10} onChange={(_, checked) => setSourceDiv10(checked)} />} label="Div10" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">Create</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import {ToDeliverProps, toDeliverTableName} from "./ToDeliver";
import {useContext} from "react";
import {
    Column,
    DateCol,
    Nullable,
    NumberCol,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../misc/scroller/Table";
import {SnackContext} from "../../../misc/Snackbar";
import {ToDeliverItem} from "../../../api/Deliveries";
import {ProjectCol} from "../purchasing/PartsToSource";
import {CheckboxSelectorCol} from "../../project/shopdrawing/release/Checkbox";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import {api} from "../../../api/API";
import AddCircle from "@mui/icons-material/AddCircle";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";

export function Frame(props: ToDeliverProps) {
    const ctx = useContext(TableSelectionContext)
    const snack = useContext(SnackContext);

    return (
        <Table<ToDeliverItem>
            name={toDeliverTableName}
            overridePrefsName={toDeliverTableName + ".frame"}
            columns={[
                props.delivery ? CheckboxSelectorCol({}) : null,
                ViewOnly(ProjectCol("Project", "projectName", 100)),
                ViewOnly(StringCol("Opening", "openingName", 100)),
                ViewOnly(StringCol("Floor", "openingFloor", 70)),
                ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                ViewOnly(StringCol("Series", "frameSeries", 100)),
                ViewOnly(StringCol("Material", "frameMaterial", 70)),
                ViewOnly(StringCol("Gauge", "frameGauge", 70)),
                ViewOnly(StringCol("Screen Elev", "frameScreenElev", 100)),
                ViewOnly(StringCol("Profile", "frameProfile", 70)),
                ViewOnly(StringCol("Jamb Depth", "frameJambDepth", 100)),
                ViewOnly(StringCol("Construction", "frameConstruction", 100)),
                ViewOnly(StringCol("Handing", "frameHanding", 70)),
                ViewOnly(StringCol("Hardware Preps", "frameHardwarePreps", 130)),
                ViewOnly(StringCol("Label", "frameLabel", 100)),
                ViewOnly(StringCol("Type", "frameType", 70)),
                ViewOnly(NumberCol("Qty", "qty", 60)),
                AddCol(props, {
                    ctx, snack
                }),
            ]}
            fetch={ctx => getAll(ctx, offset => api.deliveries.partsToDeliver({
                offset,
                project: props.project,
                type: "frame",
            }))}
            fetchDeps={[props.project]}
        />
    )
}

export function AddCol(props: ToDeliverProps, extra: {
    ctx: any;
    snack: any;
}) {
    const {ctx, snack} = extra;
    return {
        name: "Add",
        render: (dt: ToDeliverItem, col: Column<ToDeliverItem>) => (props.delivery && <div>
            <SimpleIconButton onClick={async () => {
                if(!props.delivery) return;

                try {
                    await api.deliveries.updateDeliveryItem({
                        id: 0,
                        product: 0,
                        source: dt.source,
                        manufacturer: dt.manufacturer,
                        manufacturerName: dt.manufacturerName,
                        dimensions: dt.dimensions,
                        delivery: props.delivery,
                        description: dt.name,
                        code: dt.productCode,
                        qty: dt.qty,
                        inventoryRequests: dt.inventoryRequestIds,
                        archived: false,
                        updatedAt: (new Date()) as any,
                    });

                    deliveriesPopoutPanel.sendToServer("refresh-delivery")
                    ctx.refresh(toDeliverTableName);
                    snack.success("Added")
                } catch(e: any) {
                    snack.error(e.toString())
                }
            }}>
                <AddCircle />
            </SimpleIconButton>
        </div>),
        width: 60,
    }
}
import {PurchaseOrder} from "../../../api/Logistics";
import {Grid} from "@mui/material";
import {useCallback, useContext, useEffect, useState} from "react";
import {TextField2} from "../../../misc/TextField2";
import {paginated2options, Select2, select2list} from "../../../misc/Select2";
import {api} from "../../../api/API";
import {SnackContext} from "../../../misc/Snackbar";
import {Items} from "./Items";

export function EditPayable(props: {
    value: PurchaseOrder;
    onReload(): void;
}) {
    const value = props.value;
    const [lastUpdate, setLastUpdate] = useState<number>();

    const snack = useContext(SnackContext);

    const update = useCallback((prop: keyof PurchaseOrder) => {
        return async (newValue: any) => {
            // @ts-ignore
            value[prop] = newValue;
            setLastUpdate(Date.now());
        }
    }, [value]);

    useEffect(() => {
        const tm = setTimeout(async () => {
            if(Object.keys(value).length === 0) return;
            if(value.id <= 0) return;
            if(!lastUpdate) return;

            try {
                await api.logistics.updatePayable(value);
            } catch (e: any) {
                snack.error(e.toString())
            }
        }, 500);

        return () => clearTimeout(tm);
    }, [lastUpdate, value, snack]);

    return (
        <form onSubmit={e => {
            e.preventDefault();
        }} style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
        }}>
            <div style={{padding: 16}}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <TextField2 fullWidth label="PO #" disabled={value.payableStatus !== "draft"} initialValue={value.poNumber} value={value.poNumber} onChange={update("poNumber")} />
                    </Grid><Grid item xs={2}>
                        <TextField2 fullWidth label="Ref #" disabled={value.payableStatus !== "draft"} initialValue={value.payableRefNumber} value={value.payableRefNumber} onChange={update("payableRefNumber")} />
                    </Grid><Grid item xs={8}>
                        <Select2
                            label="Status" initialValue={value.payableStatus}
                            lookup={select2list(payableStatuses)}
                            onChange={e => update("payableStatus")(e.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{height: 4}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Select2 label="Supplier"
                                         initialValue={value.supplier as any}
                                         initialDisplay={value.supplierName}
                                         lookup={paginated2options("name", "id", search => api.companies.list({search: search}))}
                                         onChange={e => update("supplier")(e.value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                <Items order={props.value} />
            </div>
        </form>
    )
}

const payableStatuses = [
    {
        display: "Draft",
        value: "draft",
    },
    {
        display: "Paid",
        value: "paid",
    }
];
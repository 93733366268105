import React, {
    MutableRefObject,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {first, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {Card, Popper, Grid, IconButton, Divider} from "@mui/material";
import {Loader} from "../../../../misc/Loader";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {ProductSearchResult} from "../../../../api/Products";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {useSnackbar} from "../../../../misc/Snackbar";
import {CreateProduct} from "./CreateProduct";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {CategoryList} from "./CategoryList";
import {ShortCode} from "../../../../api/Library";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {IconCell} from "../hardware/library/IconCell";
import {ProductEdit} from "./ProductEdit";
import {useHwLibraryType} from "../hardware/library/HwLibraryType";
import {useAlternative} from "../../quote/alternative/Alternative";

export type ProductFind = ProductSearchResult
type PopoutRef = {
    close(): void;
}

export type ProductLookupKind = "hardware" | "div-10" | "hardware,div-10" | "frame-anchor" | "manufacture"

export function ProductLookup(props: {
    placeholder?: string;
    autoFocus?: boolean;
    anchor?: any;
    width?: number;
    align?: "right";
    kind?: ProductLookupKind;
    onBlur?(): void;
    onRender?(): void;
    initialValue?: string;
    resultsInline?: boolean
    isTableCell?: boolean;
    backgroundColor?: string;
    disabled?: boolean;
} & (
{
    onSelect(product: ProductSearchResult): Promise<void>
} | {
    onSelect(product: ProductSearchResult | ShortCode): Promise<void>
    includeShortCodesForProject: number;
})) {
    const [search, setSearch] = useState(props.initialValue || "")
    const [anchorRef, setAnchorRef] = useState<HTMLElement|null>(null)
    const [selected, setSelected] = useState(-1);
    const [focused, setFocused] = useState(false);
    const selectedValueRef = useRef<ProductFind|undefined|null>();
    const edit = useContext(EditContext);

    const inputRef = useRef<HTMLInputElement|null>(null);
    const curOnSelect = useSyncedRef(props.onSelect)
    const sn = useSnackbar();
    const popoutRef = useRef<PopoutRef|null>(null);

    const [createProduct, setCreateProduct] = useState(false);

    const reset = useCallback(() => {
        // @ts-ignore
        inputRef.current?.blur();
        setFocused(false);
        setSearch("");
        popoutRef.current?.close();
    }, [])

    const select = useCallback(async (input: ProductSearchResult | ShortCode) => {
        try {
            sn.loading()
            await curOnSelect.current(input as any)
            sn.hide();
            reset();
        } catch (e: any) {
            sn.error(e.toString());
        }
    }, [curOnSelect, sn, reset]);

    const [showEdit, setShowEdit] = useState<ProductFind>();
    const onBlurRef = useSyncedRef(props.onBlur);

    useEffect(() => {
        if(focused) return;
        if(createProduct) return
        if(showEdit) return;

        const tm = setTimeout(() => onBlurRef.current?.(), 500)
        return () => clearTimeout(tm);
    }, [onBlurRef, focused, createProduct, showEdit]);

    const hasAutoFocus = useRef(false);

    return (
            <div className={productCss} style={{height: "100%", width: "100%", flex: 1, display: "flex", flexDirection: "column"}} onClick={e => {
                e.stopPropagation(); // for blur detector
            }}>
                <form ref={setAnchorRef} onSubmit={e => {
                    e.preventDefault();
                }}>
                    <input
                        ref={v => {
                            inputRef.current  = v;
                            if(props.autoFocus && v && !hasAutoFocus.current) {
                                v.focus()
                                hasAutoFocus.current = true
                            }
                        }}
                        disabled={props.disabled}
                        type="text"
                        placeholder={props.placeholder || "Start typing product name or code"}
                        style={{border: "none",
                            width: "100%",
                            height: "100%",
                            padding: 4, paddingLeft: 6, paddingRight: 6,
                            backgroundColor: props.backgroundColor,
                        }}
                        onFocus={e => {
                            e.stopPropagation()
                            if(!props.isTableCell) {
                                edit.onSelect.emit(null); // just in case a cell is selected
                            }
                            setFocused(true)
                        }}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        onBlur={() => {
                            setFocused(false)
                        }}
                        onKeyDown={e => {
                            switch(e.key) {
                                case "ArrowDown":
                                    setSelected(selected+1);
                                    break;
                                case "ArrowUp":
                                    if(selected > 0) {
                                        setSelected(selected - 1);
                                    }
                                    break;
                                case "Enter":
                                    const val = selectedValueRef.current;
                                    if(!val) return;
                                    select(val);
                                    break;
                        }
                    }} value={search} onChange={e => setSearch(e.target.value)} />
                </form>
                {props.resultsInline && <Divider />}
                <ProductLookupResults
                    key="results"
                    align={props.align}
                    popoutRef={popoutRef}
                    selected={selected}
                    kind={props.kind}
                    selectedValue={selectedValueRef}
                    anchor={anchorRef}
                    resetSelected={() => setSelected(0)}
                    search={search}
                    onRender={props.onRender}
                    includeShortCodesForProject={(props as any).includeShortCodesForProject}
                    focused={focused}
                    onBlur={() => setSearch("")}
                    onSelect={select}
                    onEdit={(value) => {
                        reset();
                        setShowEdit(value)
                    }}
                    inline={props.resultsInline}
                    onCreateProduct={() => {
                        reset();
                        setCreateProduct(true)
                    }}
                />
                {createProduct && <CreateProduct mode={toCreateProductMode(props.kind)} onDone={value => {
                    setCreateProduct(false)
                    select(Object.assign({}, value, {
                        lastUnitCostCents: 0,
                    }))
                }} onCancel={() => {
                    setCreateProduct(false)
                    reset();
                }} />}
                {showEdit && <ProductEdit mode={toCreateProductMode(props.kind)} value={showEdit} onClose={product => {
                    setShowEdit(undefined);
                    if(!product) return;

                    reset()
                    select(Object.assign({}, product) as any)
                }} />}
            </div>
    )
}

function toCreateProductMode(input?: ProductLookupKind) {
    switch(input) {
        case "div-10": return "div-10" as "div-10";
        case "hardware": return "hardware" as "hardware";
        case "frame-anchor": return "frame-anchor" as "frame-anchor";
        default: return null;
    }
}

const productCss = css({
    "& input::placeholder": {
        color: grey["500"],
        fontStyle: "italic",
    }
})

export function ProductLookupResults(props: {
    search: string;
    inline?: boolean
    focused: boolean;
    selectedValue: {current: ProductFind | ShortCode | null | undefined};
    anchor: HTMLElement | null;
    kind?: ProductLookupKind;
    selected: number;
    includeShortCodesForProject?: number;
    popoutRef: MutableRefObject<PopoutRef|null>
    align?: "right";
    resetSelected(): void;
    onBlur(): void;
    onRender?(): void;
    onSelect(value: ProductFind | ShortCode): void;
    onEdit(value: ProductFind): void;
    onCreateProduct(): void;
}) {
    const [category, setCategory] = useState<number>();
    const resetSelected = useSyncedRef(props.resetSelected);

    const projectShortCode = props.includeShortCodesForProject;
    const [isOpen, setIsOpen] = useState(props.focused || props.search !== "");
    const shouldShow = props.focused || props.search !== "" || isOpen;
    const shouldShowRef = useSyncedRef(shouldShow);
    const libraryType = useHwLibraryType();
    const alternative = useAlternative();

    const results = useAsync2(async input => {
        if(!shouldShowRef.current) return [];

        resetSelected.current();
        const {alternative, projectShortCode, libraryType, ...other} = input;

        let shortCodes: ShortCode[] = [];

        const searchResult = api.products.search(other)
        if(projectShortCode) {
            shortCodes = await api.hardwareLibrary.getShortCodes({
                project: projectShortCode,
                alternative: alternative,
                search: input.productSearch,
                type: libraryType,
            });

            if(!input.productSearch) {
                let have = 0;

                // restrict the number of shortcodes during initial result if there's no search
                shortCodes = shortCodes.filter((c, index) => {
                    let count = c.items?.length || 0;
                    if (count + have > 8) {
                        return false;
                    }

                    have += count;
                    return true;
                })
            }
        }

        const productList = await searchResult;

        return [
            ...shortCodes.map(c => ({type: "shortcode" as "shortcode", value: c})),
            ...productList.data.map(c => ({type: "product" as "product", value: c})),
        ]
    }, {
        productSearch: props.search,
        kind: props.kind,
        category: category,
        projectShortCode: projectShortCode,
        libraryType: libraryType,
        alternative: alternative,
    }, [alternative, props.search, category, projectShortCode, shouldShow, props.kind, libraryType]);

    const onRenderRef = useRef(props.onRender);
    onRenderRef.current = props.onRender;

    useEffect(() => {
        const tm = setTimeout(() => {
            onRenderRef.current?.();
        }, 100);

        return () => clearTimeout(tm);
    }, [results.result]);

    props.selectedValue.current = first((results.result||[]).filter((item, index) => index === props.selected), v => true)?.value;

    useEffect(() => {
        if(shouldShow && !isOpen) {
            setIsOpen(true);
        }
    }, [shouldShow, isOpen]);

    const onBlur = useSyncedRef(props.onBlur);

    useEffect(() => {
        const click = () => {
            onBlur.current();
            setIsOpen(false);
        };

        window.addEventListener("click", click)

        return () => {
            window.removeEventListener("click", click)
        }
    }, [onBlur]);

    useEffect(() => {
        props.popoutRef.current = {
            close: () => {
                setIsOpen(false)
            },
        }

        return () => {
            props.popoutRef.current = null;
        }
    }, [props.popoutRef]);

    return (
        <AutoPopper inline={props.inline} open={shouldShow} anchorEl={props.anchor}
                placement={props.align === "right" ? "bottom-end": "bottom-start"}>
            <Card elevation={2} style={{fontSize: "0.9rem", height: "100%", flex: 1, display: 'flex', flexDirection: "column"}}
                  onClick={e => {
                        e.stopPropagation()
                    }}>
                <Loader {...results}>
                    {rs => <div style={{overflow: "auto"}}>
                        <table style={{borderCollapse: "collapse", width: "100%"}}>
                        <tbody>
                            {rs.map((d, index) => {
                                if(d.type === "product") {
                                    return <ProductRow key={index}
                                            value={d.value}
                                            onSelect={props.onSelect}
                                            onEdit={props.onEdit}
                                            selected={props.selected}
                                            index={index}
                                        />
                                }

                                return <ShortCodeRow
                                        key={index}
                                        value={d.value}
                                        onSelect={props.onSelect}
                                        selected={props.selected}
                                        index={index}
                                    />
                            })}
                        </tbody>
                        </table>
                    </div>}
                </Loader>
                <div style={{paddingLeft: 8, paddingRight: 8}}>
                    <Grid container wrap="nowrap" spacing={1}>
                        <Grid item>
                            Find:
                        </Grid>
                        <Grid item xs>
                            <CategoryList onSelect={setCategory} selected={category} search={props.search} />
                        </Grid>
                        <Grid item>
                            <button onClick={() => {
                                props.onCreateProduct()
                            }} className={categoryButtonStyle}>Create Product</button>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </AutoPopper>
    )
}

function AutoPopper(props: PropsWithChildren<{
    inline?: boolean;
    open: boolean
    anchorEl: any;
    placement: any;
}>) {
    const {inline, ...otherProps} = props;

    if(inline) {
        return (
            <>{props.children}</>
        )
    }

    return <Popper style={{zIndex: 1400}} {...otherProps}>
        {props.children}
    </Popper>
}

function ProductRow(props: {
    value: ProductSearchResult,
    onSelect(value: ProductSearchResult): void;
    onEdit(value: ProductSearchResult): void;
    selected: number;
    index: number;
}) {
    const d = props.value;
    return (
        <tr onClick={() => props.onSelect(d)}
            className={resultRowStyle}
            style={{
                backgroundColor: props.selected === props.index ? grey["300"] : undefined,
            }}>

            <td>{d.categoryName || ""}</td>
            <td>{d.productCode}</td>
            <td>{d.name}</td>
            <td>{d.finish}</td>
            <td>{d.manufacturerName}</td>
            <IconCell className="more-detail">
                <IconButton onClick={e => {
                    e.stopPropagation();
                    props.onEdit(props.value);
                }}>
                    <MoreHorizIcon fontSize="inherit" />
                </IconButton>
            </IconCell>
        </tr>
    )
}

function ShortCodeRow(props: {
    value: ShortCode,
    onSelect(value: ShortCode): void;
    selected: number;
    index: number;
}) {
    const [hover, setHover] = useState(false);
    return (
        <>
            {(props.value.items || []).map((v, index) =>
                <tr
                    key={props.index + "-" + v.id}
                    style={{
                        backgroundColor: hover ? grey["200"] : undefined,
                    }}
                    className={resultRowStyle}
                    onClick={() => props.onSelect(props.value)}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                >
                    {index === 0 && <td rowSpan={props.value.items?.length||1} style={{
                        borderRight: "1px solid " + grey["200"]
                    }}>
                        <div style={{fontSize: "0.7rem", color: grey["600"]}}>Shortcode</div>
                        {props.value.value}
                    </td>}
                    <td>
                        {v.productName}
                    </td>
                    <td>{v.productCode}</td>
                    <td>{v.finish}</td>
                    <td style={{textAlign: "right"}}>{v.qty}</td>
                    <td />
                </tr>)}
        </>
    )
}

export const categoryButtonStyle = css({
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 2,

    marginLeft: 8,
    marginRight: 8,
    marginBottom: 2,

    cursor: "pointer",

    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid",
    color: grey["700"]
})

const resultRowStyle = css({

  "& > td": {
      borderBottom: "1px solid " + grey["200"],
      paddingLeft: 8, paddingRight: 8,
      paddingTop: 4, paddingBottom: 4,
      fontSize: "0.8rem",
      cursor: "pointer",
  },

    "&:hover > td": {
        backgroundColor: grey["200"],
    },

    "& .more-detail": {
      opacity: 0,
    },

    "&:hover .more-detail": {
      opacity: 1,
    }
})

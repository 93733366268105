import {QuoteContainer} from "../QuoteContainer";
import {useAsync2} from "nate-react-api-helpers";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {api} from "../../../../api/API";
import {useNavigate} from "react-router-dom";

export function RedirectToAlternative() {
    const project = useProjectId();
    const nav = useNavigate();

    const request = useAsync2(async (input) => {
        if(input.project <= 0) throw new Error("Invalid project");

        const result = await api.quoteAlternatives.getDefault(input);
        nav(`/project/${input.project}/quote/${result.id}/`)
    }, {
            project: project
        }, [project]);

    return (
        <QuoteContainer name="Redirecting...">
            {request.LoadingOrErrorElement}
        </QuoteContainer>
    )
}
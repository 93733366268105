import React, {useMemo} from "react";
import {Page} from "../../../../misc/pdf/parts/Page";
import {FullSizeImage} from "../../../../misc/pdf/parts/FullSizeImage";
import {HardwareGroupWithContent} from "../../../../api/Hardware";
import {getHwItemDetail} from "./HardwarePrepTable";
import {FrameAndDoorDetail} from "./FrameDetails";

export function HardwarePrepImage(props: {
    value: HardwareGroupWithContent[]
    frames: FrameAndDoorDetail[]
    doors: FrameAndDoorDetail[]
}) {
    let pair = false;
    let single = false;
    const hasDoors = props.doors.length > 0
    const hasFrames = props.frames.length > 0;

    const doorOnly = hasDoors && !hasFrames;
    const frameOnly = hasFrames && !hasDoors;
    let qty = 3;
    let hasDeadbolt = false;

    const details = useMemo(() => {
        return getHwItemDetail(props.value, props.frames, props.doors)
    }, [props.value, props.frames, props.doors])

    details.map(d => {
        let itemQty = d.manager.getHingeQty()
        if(itemQty > qty) {
            qty = itemQty;
        }

        if(d.hasDeadbolt) {
            hasDeadbolt = true;
        }
    })

    props.value.map(v => {
        v.openings.map(o => {
            if(o.openingType === "Pair") {
                pair = true;
            } else if(o.openingType === "Single") {
                single = true;
            }
        })
    })

    const type = frameOnly ? "frame" : doorOnly ? "door" : "both"
    qty = Math.min(Math.max(qty, 3), 5);
    const dbd = hasDeadbolt ? "-dbd" : "";

    return (
        <>
            {single && <Page>
                <FullSizeImage src={`/images/door-hw-prep-diagram/single-h${qty}-${type}${dbd}.png`} />
            </Page>}
            {pair && <Page>
                <FullSizeImage src={`/images/door-hw-prep-diagram/pair-h${qty}-${type}${dbd}.png`} />
            </Page>}
        </>
    )
}
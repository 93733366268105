import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {SnackContext} from "../../../../misc/Snackbar";
import {useNavigate} from "react-router-dom";
import {Project} from "../../../../api/Projects";
import {AutoComplete} from "../../shopdrawing/openings/AutoComplete";
import {getAll} from "../../shopdrawing/openings/Openings";
import {Alternative} from "../../../../api/QuoteAlternatives";

export function AwardedProject(props: {
    project: Project;
}) {
    const snack = useContext(SnackContext);
    const nav = useNavigate();
    const project = props.project;

    const convert  = useAsyncAction(async (input) => {
        await api.projects.upsert(input)

        snack.success("Converted");
        nav(`/project/${project.id}/shop-drawing`);

    }, [project])

    const [show, setShow] = useState(false);
    const [alternative, setAlternative] = useState<Alternative|null>(null);

    useEffect(() => {
        setAlternative(null);
    }, [show])

    return (
        <>
            <Button size="small" fullWidth variant="outlined" onClick={() => {
                setShow(true)
            }}>
                Award Project
            </Button>
            {show && <Dialog open onClose={() => setShow(false)}>
                <form onSubmit={e => {
                    e.preventDefault()
                    if(!alternative) return;

                    convert.callback(Object.assign({}, project, {
                        stage: "in-progress",
                        quoteAlternative: alternative.id,
                    }))
                }}>
                    <DialogTitle>
                        Award Project
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Warning: This action cannot be undone. Only do this if you have a signed contract to do this project.
                        </DialogContentText>

                        <div style={{height: 16}} />

                        <AutoComplete<Alternative>
                            fullWidth
                            label="Choose Alternative"
                            search={async input => {
                                let result = await getAll(null, offset => api.quoteAlternatives.list({
                                    project: project.id,
                                    offset: offset,
                                }))

                                if(input !== "") {
                                    input = input.toLowerCase();
                                    result = result.filter(r => r.name.toLowerCase().indexOf(input) !== -1)
                                }

                                return result;
                            }}
                            value={alternative}
                            equals={(a, b) => a.id === b.id}
                            makeLabel={a => a.name}
                            onSelect={setAlternative}
                        />
                    </DialogContent>
                    <DialogActions>
                        {convert.LoadingElement}
                        <Button onClick={() => setShow(false)}>Cancel</Button>
                        <Button variant="contained" color="primary" disabled={!alternative} type="submit">Award</Button>
                    </DialogActions>
                </form>
            </Dialog>}
        </>
    )
}
import {Container} from "../../misc/Container";
import {WhiteColumn} from "../logistics/purchasing/WhiteColumn";
import {SectionHeader} from "../../misc/SectionHeader";
import {Alert, Button, Divider} from "@mui/material";
import React, {useState} from "react";
import {ProductSelector, ProductWithQty} from "./ProductSelector";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {api} from "../../api/API";
import {useGetAll} from "../logistics/routes/useGetAll";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../misc/SyncedRef";
import {useSnackbar} from "../../misc/Snackbar";
import {SmallGreyButton} from "../../misc/SmallGreyButton";
import {DoorBuilder} from "./DoorBuilder";
import {Product} from "../../api/Products";

export function RunMfgProcess() {
    const [output, setOutput] = useState<ProductWithQty|null>(null);
    const [inputs, setInputs] = useState<ProductWithQty[]>([]);

    const inventory = useGetAll(async (input) => {
        return await api.logistics.listInventory(input)
    }, {}, []);

    const inventoryReload = useSyncedRef(inventory.reload);
    const snack = useSnackbar();

    const submit = useAsyncAction(async (input) => {
        await api.manufacturing.convertProducts(input)
        inventoryReload.current();
        snack.success("Built 1 item!")
    }, []);

    const [showDoorBuilder, setShowDoorBuilder] = useState(false);
    const [doorProduct, setDoorProduct] = useState<Product>()

    return (
        <Container>
            <WhiteColumn>

                <SectionHeader name="Manufacturing" underName={<div>Convert raw materials into new products</div>}/>
                <Divider/>

                <div style={{display: "flex", flexDirection: "column"}}>

                    <table className={tableCss}>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Per Unit</th>
                            <th></th>
                            <th>Stock Qty</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th colSpan={3} className={cellWithPadding} style={{textAlign: "left"}}>Output Product</th>
                        </tr>
                        {doorProduct ? <tr>
                            <td className={cellWithPadding}>
                                {doorProduct.name} {doorProduct.dimensions}
                            </td>
                            <td style={{textAlign: "right", width: 100}}>1</td>
                            <td>
                                <SmallGreyButton onClick={() => setDoorProduct(undefined)}>Reset</SmallGreyButton>
                            </td>
                            <td style={{textAlign: "right"}}>{inventory.asList?.find(i => i.product === doorProduct.id)?.qty || null}</td>
                        </tr> :
                            <ProductSelector value={output} onChange={setOutput}
                                         showReset
                                         stockQty={inventory.asList?.find(i => i.product === output?.product?.id)?.qty || null}
                            />}
                        <tr>
                            <td colSpan={4}>
                                <SmallGreyButton onClick={() => setShowDoorBuilder(true)}>Build Door</SmallGreyButton>
                                {showDoorBuilder && <DoorBuilder onDone={(value) => {
                                    setDoorProduct(value);
                                    setShowDoorBuilder(false);

                                }} onCancel={() => setShowDoorBuilder(false)} />}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3} className={cellWithPadding} style={{textAlign: "left"}}>Input Products</th>
                        </tr>
                        {inputs.map((item, index) =>
                            <ProductSelector
                                key={index.toString()}
                                value={item}
                                onChange={value => {
                                    const newInputs = inputs.slice();
                                    newInputs[index] = value;
                                    setInputs(newInputs);
                                }}
                                onRemove={() => {
                                   setInputs(inputs.filter((_, i) => i !== index))
                               }}
                                stockQty={inventory.asList?.find(i => i.product === item.product.id)?.qty || null}
                            />
                        )}

                        <ProductSelector key='new' value={null} onChange={value => {
                            setInputs([...inputs, value])
                        }} />

                        </tbody>
                    </table>

                    <div style={{paddingTop: 25, display: "flex", flexDirection: "column", alignItems: "flex-start", padding: 16}}>
                        <Alert color="warning">
                            Pressing this button will convert the input products into the output product and update inventory counts
                        </Alert>

                        <div style={{height: 16}} />

                        {(submit.loading || submit.error) && submit.LoadingElement}
                        <Button variant="contained" color="primary" onClick={() => {
                            submit.callback({
                                output: {
                                    product: doorProduct ? doorProduct.id : output?.product?.id,
                                    qty: output?.qty || 1,
                                },
                                inputs: inputs.map(i => ({product: i.product.id, qty: i.qty}))
                            })
                        }}>Build Item</Button>
                    </div>
                </div>
            </WhiteColumn>
        </Container>
    )
}

const tableCss = css({
    borderCollapse: "collapse",
    fontSize: "0.8rem",

    "& thead th": {
        borderBottom: "1px solid " + grey["200"],
    },

    "td": {
        border: "1px solid " + grey["200"],
    }
})

export const cellWithPadding = css({
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 2,
    paddingBottom: 2,
})
import {createContext, PropsWithChildren, useCallback, useMemo, useRef, useState} from "react";
import {PriceLine, SimilarCost} from "../../../../api/Pricing";
import {EventEmitter} from "nate-react-api-helpers";

export const CostHelperContext = createContext({
    show: (value: PriceLine, callback: SimilarCostCallback) => {
        return () => {}
    },
    value: new EventEmitter<PriceLine|null>(),
    onSelectPrice(value: SimilarCost) {

    }
});

type SimilarCostCallback = (value: SimilarCost) => void;

export function CostHelperProvider(props: PropsWithChildren<{}>) {
    const [value] = useState(new EventEmitter<PriceLine|null>());
    const onSelectPriceRef = useRef<SimilarCostCallback|null>(null);

    const show = useCallback((newValue: PriceLine, onSelectPrice: SimilarCostCallback) => {
        value.emit(newValue);
        onSelectPriceRef.current = onSelectPrice;

        return () => {
            value.emit(null);
        }
    }, [value])

    const onSelectPrice = useCallback((value: SimilarCost) => {
        console.log("onSelectPrice", value, onSelectPriceRef.current)
        if(!onSelectPriceRef.current) return;
        onSelectPriceRef.current(value);
    }, [])

    const ctx = useMemo(() => {
        return {
            show: show,
            value: value,
            onSelectPrice: onSelectPrice,
        }
    }, [show, value, onSelectPrice])

    return (
        <CostHelperContext.Provider value={ctx}>
            {props.children}
        </CostHelperContext.Provider>
    )
}
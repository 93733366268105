import {useProjectId} from "../ProjectName";
import {QuoteSwitcher} from "../../quote/pricing/QuoteSwitcher";
import React from "react";

export function OpeningSwitcher() {
    const projectId = useProjectId();

    return (
        <QuoteSwitcher
            title="Openings"
            url={`/project/${projectId}/shop-drawing/openings`}
            quoteTitle="Quote Openings"
            quoteURL={`/project/${projectId}/quote/-1/openings`} />
    )
}
import {QtyWithPreview} from "./QtyWithPreview";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import Clear from "@mui/icons-material/Clear";
import {RouteStopGroup} from "../../../api/Logistics";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useSnackbar} from "../../../misc/Snackbar";
import {RefreshRouteItems} from "./RouteItems";
import {Grid, Checkbox, Button} from "@mui/material";
import {routePopupPanel} from "./RouteList";
import {Completed} from "./Completed";
import {css} from "@emotion/css";
import {useEffect, useState} from "react";
import {useFocusMode} from "../../project/shopdrawing/hardware/UseFocusMode";
import {useSyncedRef} from "../../../misc/SyncedRef";

export function PickupPoItemRow(props: {
    value: RouteStopGroup;
    isPurchaseOrderItemGoingToWarehouse(id: number): boolean
    routeCompleted: boolean;
}) {
    const snack = useSnackbar();
    const c = props.value;

    const [checked, setChecked] = useState<number[]>([]); // purchaseOrderItem ids
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState("")

    const isGoingToWarehouse = props.isPurchaseOrderItemGoingToWarehouse;
    const resetChecked = useSyncedRef(() => {
        let toCheck: number[] = [];
        props.value.items?.forEach(itm => {
            if(itm.purchaseOrderItem && isGoingToWarehouse(itm.purchaseOrderItem)) {
                toCheck.push(itm.purchaseOrderItem)
            }
        });

        setChecked(toCheck);
    })

    useEffect(() => {
        resetChecked.current();
    }, [props.value, isGoingToWarehouse, resetChecked]);

    useEffect(() => {
        setMode(checked.length === 0 ? "site" :
            checked.length === props.value.items?.length ?
                "warehouse" : "split")
    }, [checked, props.value]);

    const [headerRef, setHeaderRef] = useState<HTMLElement|null>(null)
    const [editing, setEditing] = useState(false)

    useFocusMode({
        enabled: editing,
        focusRef: headerRef,
        adjustFocus: (input, retryLater) => {
            const tbody = input.parentElement;
            if(!tbody) {
                retryLater();
                return [];
            }

            const rows = tbody.querySelectorAll("tr[data-pickup='" + props.value.id.toString() + "']");
            return [input, ...Array.from(rows)];
        }
    })

    const save = useAsyncAction(async (input: {src: RouteStopGroup, checked: number[]}) => {

        await api.logistics.reRouteGroup({
            pickupGroupId: input.src.id,
            purchaseOrderItemsToWarehouse: input.checked,
        })

        setEditing(false);
        RefreshRouteItems.emit(true);
    }, [])

    return (
        <>
            <tr ref={setHeaderRef}>
                <td>
                    PO #{c.pickupPoNumber}
                </td>
                <td>
                    <QtyWithPreview data={props.value.items || []} />
                </td>
                <td>
                    <Completed contents={props.value.items || []} complete={props.value.completed} routeComplete={props.routeCompleted} />
                </td>
                <td>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end"}}>
                        <div className="actions">
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <select disabled={props.value.completed} value={mode} onChange={(e) => {
                                        const value = e.target.value;
                                        setMode(value);
                                        setShow(true);
                                        setEditing(true)

                                        if(value === "warehouse") {

                                            // @ts-ignore
                                            setChecked((props.value.items || []).map(c => c.purchaseOrderItem).filter(c => c));
                                        } else if(value === "site") {
                                            setChecked([])
                                        }
                                    }}>
                                        <option value="split">Split PO</option>
                                        <option value="warehouse">All to warehouse</option>
                                        <option value="site">All to site</option>
                                    </select>
                                </Grid>
                                <Grid item>
                                    <SimpleIconButton disabled={props.value.completed} onClick={async () => {
                                        try {

                                            await api.logistics.upsertStopGroup(Object.assign({}, props.value, {
                                                archived: true,
                                            }))

                                        } catch (e: any) {
                                            snack.error(e.toString())
                                            return;
                                        }

                                        RefreshRouteItems.emit(null);
                                        routePopupPanel.sendToClient("refresh-to-move")
                                        snack.success("Updated")
                                    }}>
                                        <Clear />
                                    </SimpleIconButton>
                                </Grid>
                            </Grid>
                        </div>
                        {editing && <>
                            <div style={{width: 8}} />
                            <Button variant="outlined" style={{paddingTop: 2, paddingBottom: 2}} size="small"
                                    onClick={() => {
                                        setEditing(false);
                                        resetChecked.current();
                                    }}
                            >Cancel</Button>
                            <div style={{width: 8}} />
                            <Button variant="contained"
                                    style={{paddingTop: 2, paddingBottom: 2}} size="small"
                                    onClick={() => {
                                        save.callback({
                                            checked: checked,
                                            src: props.value,
                                        })
                                    }}
                            >Save</Button>
                            {save.LoadingElement}
                        </>}
                    </div>
                </td>
            </tr>
            {show && <tr data-pickup={props.value.id.toString()} key="pickup-header" className={pickupHeaderClass + " " + pickupDataRowClass}>
                <th>Item</th>
                <th style={{textAlign: "right"}}>Qty</th>
                <th style={{textAlign: "center"}}>To Warehouse</th>
                <th></th>
            </tr>}
            {show && (c.items||[]).map((content, index) => {
               return (<tr data-pickup={props.value.id.toString()} key={"pickup-choose" + index} className={pickupDataRowClass}>
                   <td>
                       {content.name}
                   </td>
                   <td>
                       {content.qty}
                   </td>
                   <td style={{paddingTop: 0, paddingBottom: 0, textAlign: "center"}}>
                       <Checkbox disableRipple size="small" checked={checked.indexOf(content.purchaseOrderItem || 0) !== -1} onChange={(e, isChecked) => {
                            if(!content.purchaseOrderItem) return;
                            setEditing(true);

                            if(isChecked) {
                                 setChecked([...checked, content.purchaseOrderItem]);
                            } else {
                                 setChecked(checked.filter(c => c !== content.purchaseOrderItem));
                            }
                       }} />
                   </td>
                   <td></td>
               </tr>)
            })}
        </>
    )
}

const pickupHeaderClass = css({
    "& th": {
        textAlign: "left",
    },
})

const pickupDataRowClass = css({
    "& .MuiCheckbox-root": {
        padding: 0,
    }
})
import {createContext, PropsWithChildren, useMemo, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export const ConfirmContext = createContext({
    confirm: (message: any) => Promise.resolve(true),
})

export function ConfirmationProvider(props: PropsWithChildren<{}>) {

    const [message, setMessage] = useState<any|null>(null);
    const [callback, setCallback] = useState({
        resolve: (tf: boolean) => {},
        reject: (tf: Error) => {},
    })

    const ctx = useMemo(() => ({
        confirm: (input: any) => {
            return new Promise<boolean>((resolve, reject) => {
                setMessage(input);
                setCallback({
                    resolve: resolve,
                    reject: reject
                });
            });
        }
    }), []);

    return (
        <ConfirmContext.Provider value={ctx}>
            <Dialog open={message !== null}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setMessage(null);
                        callback.reject(new Error("false"))
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        setMessage(null);
                        callback.resolve(true)
                    }}>Confirm</Button>
                </DialogActions>
            </Dialog>
            {props.children}
        </ConfirmContext.Provider>
    )
}
import { WhiteColumn } from "../purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {SmallSearchBox} from "../purchasing/SmallSearchBox";
import React, {useRef, useState} from "react";
import {InventoryItem} from "../../../api/Logistics";
import {
    Column,
    FreeCell,
    Group,
    NumberCol, PrefixSearch,
    selectRenderer,
    StringCol,
    Table,
    ViewOnly
} from "../../../misc/scroller/Table";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {RowOptions} from "../purchasing/RowOptions";
import {RestockStatus} from "./Restocking";
import { Dependants } from "./Dependants";
import {Grid, Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import {useQueryParam} from "../../project/quote/pricing/QueryParam";
import {ProjectCol} from "../purchasing/PartsToSource";
import {useSnackbar} from "../../../misc/Snackbar";
import {CreateStockingHardwareProduct} from "./CreateStockingHardwareProduct";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import GradingIcon from '@mui/icons-material/Grading';
import {useSnackAction} from "../../project/shopdrawing/hardware/SnackAction";
import {useNavigate} from "react-router-dom";

const nonStockingTab = "non-stocking"

export function Inventory() {
    const [search, setSearch] = useState("");
    const [dependants, setDependants] = useState<InventoryItem>();
    const [tab, setTab] = useQueryParam("tab", "per-project");
    const [tab2, setTab2] = useQueryParam("tab2", "hardware");
    const snack = useSnackbar();
    const isDiv10 = tab2 === "div-10";
    const tableName = perProject + "." + tab2;
    const [expandGroup, setExpandGroup] = useState<number|null>(null);
    const expandGroupRef = useRef(expandGroup)
    expandGroupRef.current = expandGroup;

    function showWhenExpanded(old: Column<InventoryItem>) {
        return Object.assign({}, old, {
            render: (v: InventoryItem, col: any) => {
                if(v.isGroup && v.baseProduct !== expandGroupRef.current) return ""
                return old.render(v, col)
            }
        })
    }

    function hideWhenExpanded(old: Column<InventoryItem>) {
        return Object.assign({}, old, {
            render: (v: InventoryItem, col: any) => {
                if(v.isGroup && v.baseProduct === expandGroupRef.current) return ""
                return old.render(v, col)
            }
        })
    }

    const nav = useNavigate()
    const startStockCount = useSnackAction(async () => {
        const stockCount = await api.logistics.startStockCount({})
        nav(`/logistics/stock-count/do/${stockCount.id}`)
    }, {
        success: "Stock count started",
    })

    return (
        <WhiteColumn>
            <SectionHeader
                name="Warehouse"
                right={<Grid container spacing={1}>
                    <Grid item><SmallGreyButton startIcon={<GradingIcon />} onClick={() => {
                        startStockCount.callback({});
                    }}>Start Stock Count</SmallGreyButton></Grid>
                    <Grid item><SmallSearchBox value={search} onChange={setSearch} /></Grid>
                </Grid>}
            />
            <TabWrapper>
                <Tabs value={tab} onChange={(_, v) => {
                    setTab(v)
                    setTab2("hardware");
                }}>
                    <Tab value={perProject} label="Per Project" />
                    <Tab value="stocking" label="Stocking" />
                    <Tab value="re-stocking" label="Re-Stocking" />
                    <Tab value={nonStockingTab} label="Non-Stocking" />
                </Tabs>
            </TabWrapper><TabWrapper>
                <Tabs value={tab2} onChange={(_, v) => setTab2(v)}>
                    <Tab value="frame" label="Frames" />
                    <Tab value="door" label="Doors" />
                    <Tab value="hardware" label="Hardware" />
                    <Tab value="div-10" label="Div 10" />
                    <Tab value="frame-anchor" label="Anchors" />
                    <Tab value="component" label="Components" />
                </Tabs>
            </TabWrapper>
            {dependants && <Dependants value={dependants} onDone={() => setDependants(undefined)} />}
            {tab === perProject ?
                <Table<InventoryItem>
                    key={"inventory.perProject." + tab2}
                    name={tableName}
                    columns={[
                        {
                            name: "",
                            render: dt => {

                                return (<RowOptions options={[{
                                        name: "Convert to Stocking",
                                        onClick: async (ctx) => {
                                            try {
                                                await api.logistics.setInventoryStocking({
                                                    product: dt.product,
                                                    stocking: true,
                                                });

                                                ctx.onClose();
                                                ctx.refreshTable(tableName)
                                                snack.success("Updated")
                                            } catch (e: any) {
                                                snack.error(e.toString());
                                            }
                                        },
                                    }, {
                                        name: `Show Dependant Projects (${dt.projects?.length || 0})`,
                                        onClick: ctx => {
                                            setDependants(dt);
                                            ctx.onClose();
                                        }
                                    }, {
                                        name: "Delete",
                                        danger: true,
                                        onClick: ctx => {
                                            return ctx.update(Object.assign({}, dt, {archived: true}))
                                        },
                                    }]}/>)
                            },
                            width: 35,
                        },
                        ViewOnly(ProjectCol("Project", "projectName", 200)),
                        !isDiv10 && ViewOnly(StringCol("Door", "door", 100)),
                        !isDiv10 && ViewOnly(StringCol("Floor", "floor", 100)),
                        ViewOnly(StringCol("Mfg", "manufacturerName", 100)),
                        ViewOnly(StringCol("Description", "name", 100)),
                        ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                        ViewOnly(StringCol("Dim.", "dimensions", 100)),
                        ViewOnly(NumberCol("Qty", "qty", 40)),
                    ]}
                    fetch={ctx => getAll(ctx, offset => api.logistics.listProjectInventory({
                                offset,
                                search,
                                subKind: tab2 as any,
                            }))}
                    fetchDeps={[search, tab, tab2]}
                /> :
                tab === nonStockingTab ?
                    <Table<InventoryItem>
                        key={"inventory." + tab}
                        name={"inventory." + tab}
                        columns={[
                            {
                                name: "",
                                render: dt => {

                                    return (<RowOptions options={[{
                                        name: `Show Dependant Projects (${dt.projects?.length || 0})`,
                                        onClick: ctx => {
                                            setDependants(dt);
                                            ctx.onClose();
                                        }
                                    }, {
                                        name: "Delete",
                                        danger: true,
                                        onClick: ctx => {
                                            return ctx.update(Object.assign({}, dt, {archived: true}))
                                        },
                                    }]}/>)
                                },
                                width: 35,
                            },
                            ViewOnly(StringCol("Mfg", "manufacturerName", 100)),
                            ViewOnly(StringCol("Description", "name", 100)),
                            ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                            {
                                name: "Type",
                                render: selectRenderer,
                                editKey: "isStocking",
                                width: 100,
                                editable: {
                                    type: "select",
                                    options: () => stocking2
                                },
                            },
                            ViewOnly(StringCol("Dim.", "dimensions", 100)),
                            ViewOnly(NumberCol("Qty", "nonProjectQty", 40)),
                        ]}
                        fetch={ctx => getAll(ctx, offset => api.logistics.listInventory({
                            offset,
                            search,
                            kind: tab as any,
                            subKind: tab2 as any,
                        }))}
                        fetchDeps={[search, tab, tab2]}
                        onChange={value => api.logistics.updateInventory(value)}
                    /> :
                <Table<InventoryItem>
                    key={"inventory." + tab}
                    name={"inventory." + tab}
                    columns={[
                        {
                            name: "",
                            render: dt => {
                                if(dt.isGroup && dt.baseProduct && expandGroupRef.current !== dt.baseProduct) {
                                    const isExpanded = expandGroupRef.current === dt.baseProduct;
                                    const baseProduct = dt.baseProduct;

                                    return (
                                        <div className={expanderClass} onClick={() => {
                                            if (isExpanded) {
                                                setExpandGroup(null);
                                            } else {
                                                setExpandGroup(baseProduct);
                                            }
                                        }}>
                                            <ChevronRightIcon style={{
                                                transformOrigin: "center center",
                                                transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                                            }} fontSize="inherit"/>
                                        </div>
                                    )
                                }

                                return (<RowOptions options={[{
                                    name: `Show Dependant Projects (${dt.projects?.length || 0})`,
                                    onClick: ctx => {
                                        setDependants(dt);
                                        ctx.onClose();
                                    }
                                }, {
                                    name: "Delete",
                                    danger: true,
                                    onClick: ctx => {
                                        return ctx.update(Object.assign({}, dt, {archived: true}))
                                    },
                                }]}/>)
                            },
                            width: 35,
                        },
                        hideWhenExpanded(ViewOnly(StringCol("Mfg", "manufacturerName", 100))),
                        hideWhenExpanded(ViewOnly(StringCol("Description", "name", 100))),
                        hideWhenExpanded(ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100)))),
                        {
                            name: "Type",
                            render: selectRenderer,
                            editKey: "isStocking",
                            width: 100,
                            editable: {
                                type: "select",
                                options: () => stocking
                            },
                            disabled: (v) => {
                                if(v.isGroup && v.baseProduct !== expandGroupRef.current) return true
                                return false;
                            }
                        },
                        RestockStatus(),
                        showWhenExpanded(ViewOnly(StringCol("Dim.", "dimensions", 200))),
                        ViewOnly(NumberCol("Qty", "qty", 40)),
                        Object.assign(NumberCol("Min Qty", "stockMinQty", 40), {
                            render: (v: any) => v.isStocking ? v.stockMinQty.toString() : "",
                        }),
                        Object.assign(NumberCol("Ideal Qty", "idealQty", 40), {
                            render: (v: any) => v.isStocking ? v.idealQty.toString() : "",
                        }),
                    ]}
                    fetch={ctx => getAll(ctx, offset => api.logistics.listInventory({
                        offset,
                        search,
                        kind: tab as any,
                        subKind: tab2 as any,
                        expandGroup: expandGroup,
                    }))}
                    fetchDeps={[search, tab, tab2, expandGroup]}
                    groupBy={{
                        groupFx: (input: InventoryItem) => (input.baseProduct || input.product).toString(),
                        hasBeforeGroup: (input) => {
                            return input.rows[0].isGroup &&
                                !!expandGroupRef.current &&
                                input.rows[0].baseProduct === expandGroupRef.current
                        },
                        beforeGroup: (input) => {
                            const isExpanded = true;

                            return <>
                                <FreeCell index={0}>
                                    <div className={expanderClass} onClick={() => {
                                        if (isExpanded) {
                                            setExpandGroup(null);
                                        }
                                    }}>
                                        <ChevronRightIcon style={{
                                            transformOrigin: "center center",
                                            transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                                        }} fontSize="inherit"/>
                                    </div>
                                </FreeCell>
                                <FreeCell index={1}>
                                    {input.rows[0].manufacturerName}
                                </FreeCell>
                                <FreeCell index={2}>
                                    {input.rows[0].name}
                                </FreeCell>
                                <FreeCell index={3}>
                                    {input.rows[0].productCode}
                                </FreeCell>
                                <FreeCell index={4} grow />
                            </>
                        },
                        groupSorter: (a: string, b: string, aGroup: Group<InventoryItem>, bGroup: Group<InventoryItem>) => {
                            return aGroup.rows[0].id - bGroup.rows[0].id
                        }
                    }}
                    onChange={value => api.logistics.updateInventory(value)}
                    insert={tab === "stocking" && tab2 === "hardware" ? {
                        buttonText: "New Row",
                        alignX: "left",
                        modal: input => <CreateStockingHardwareProduct onDone={input} />
                    } : undefined}
                />}
        </WhiteColumn>
    )
}

const perProject = "per-project";

const stocking = [{
    display: "Per Order",
    value: false,
}, {
    display: "Stocking",
    value: true,
}]

const stocking2 = [{
    display: "Non stocking",
    value: false,
}, {
    display: "Stocking",
    value: true,
}]

export const expanderClass = css({
    fontSize: "1rem",

    "& svg": {
        cursor: "pointer",
        borderRadius: "50%",

        "&:hover": {
            backgroundColor: grey["200"],
        }
    }
})
import {forwardRef} from "react";
import {useUser} from "../../../../misc/Permission";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {DataSplitInfo, PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {pdfTableStyle} from "../../../../misc/pdf/parts/Page";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {Product} from "../../../../api/Products";
import {GenericContainerPage} from "../../../../misc/pdf/parts/GenericContainerPage";


export const PriceRequestPDF = forwardRef(function (props: {
    products: Product[]
    onReady(): void;
}, ref: any) {
    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    return (
        <div ref={ref}>
            <PDF>
                <PageRenderProvider data={props.products} onReady={ready.section()}>
                    {(list: DataSplitInfo<Product>) =>
                        <GenericContainerPage>
                            <table className={pdfTableStyle}>
                                <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Code</th>
                                    <th>Finish</th>
                                    <th>Finish Desc</th>
                                </tr>
                                </thead>
                                <tbody>
                                {list.rows.map((v, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{v.categoryName}</td>
                                                <td>{v.productCode}</td>
                                                <td>{v.finish}</td>
                                                <td>{v.finishName}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                        </GenericContainerPage>}
                </PageRenderProvider>
            </PDF>
        </div>
    )
})

import {HTMLAttributes, PropsWithChildren, useContext, useRef} from "react";
import {styled, Tooltip} from "@mui/material";
import {ConfirmContext} from "../Confirm";
import {EditContext, TEditContext} from "./EditableTable";
import {grey} from "@mui/material/colors";

const Button = styled("div")<{disabled: boolean}>((props) => ({
    cursor: "pointer",
    borderRadius: "50%",
    width: "1rem",
    color: props.disabled ? grey["500"] : grey["700"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& svg.MuiSvgIcon-root": {
        fontSize: "1rem",
    },

    "&:hover": props.disabled ? {} : {
        backgroundColor: "hsla(0,0%,0%,0.1)",
    }
}))

export function SimpleIconButton(props: Omit<HTMLAttributes<HTMLDivElement>, "onClick"> & PropsWithChildren<{
    confirm?: any;
    tooltip?: string;
    disabled?: boolean;
    onClick?(props: {
        event: MouseEvent;
        editCtx: TEditContext,
    }): any;
}>) {

    const {confirm, tooltip, disabled, onClick, ...otherProps} = props;

    const dblClickActiveRef = useRef(false);

    const confirmCtx = useContext(ConfirmContext);
    const editCtx = useContext(EditContext);
    const btn =
        <Button disabled={!!props.disabled} onClick={async (e) => {
            if(props.disabled) return;

            if(props.confirm) {
                await confirmCtx.confirm(props.confirm);
            }

            if(props.onClick) {
                if(dblClickActiveRef.current) return;
                dblClickActiveRef.current = true;

                const rs = props.onClick({
                    event: e as any,
                    editCtx: editCtx,
                })

                if(rs instanceof Promise) {
                    try {
                        await rs;
                    } catch (e) {}
                }

                dblClickActiveRef.current = false;
            }

        }} {...otherProps}/>

    if(props.tooltip) {
        return <Tooltip title={props.tooltip}>
            {btn}
        </Tooltip>
    }

    return btn;
}
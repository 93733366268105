import {InventoryRequest} from "../../../../api/Logistics";
import {getAll, StatusCol} from "../openings/Openings";
import {api} from "../../../../api/API";
import {CheckboxSelectorCol} from "../release/Checkbox";
import {
    keyMatch,
    NumberCol,
    PrefixSearch, sortDate,
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {FakeLink} from "../../../../misc/FakeLink";
import moment from "moment/moment";
import {Tooltip} from "@mui/material";
import {logisticsTableName} from "./Logistics";
import {useState} from "react";
import {useProjectId} from "../ProjectName";
import {ChangeHistory} from "../openings/ChangeHistory";

export function Openings(props: {
    type: "door-hm" | "frame-hm" | "door-wd" | "hardware"
}) {
    const [showChangeHistoryForOpening, setShowChangeHistoryForOpening] = useState<{
        id: number; name: string, inventoryRequest?: {
            id: number;
            qty: number;
            status: string;
        }
    }>();
    const project = useProjectId();

    return (
        <>
            <Table<InventoryRequest>
                name={logisticsTableName(project, props.type)}
                globalPrefsName={logisticsTableName(0, props.type)}
                fetch={ctx => getAll(ctx, offset => api.logistics.listInventoryRequest({
                    project: project,
                    offset: offset,
                    category: props.type,
                }))}
                fetchDeps={[project, props.type]}
                columns={[
                    CheckboxSelectorCol(),
                    ViewOnly(StringCol("Opening", "openingName", 100)),
                    ViewOnly(StringCol("Product", "name", 100)),
                    ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                    ViewOnly(StringCol("Dim", "dimensions", 100)),
                    ViewOnly(NumberCol("Qty", "qty", 50)),
                    StatusCol("Status",  "status"),
                    {
                        name: "Meta",
                        render: (data) => {

                            return (
                                <>
                                    <FakeLink onClick={() => setShowChangeHistoryForOpening({
                                        id: data.opening,
                                        name: data.openingName,
                                        inventoryRequest: {
                                            id: data.id,
                                            status: data.status,
                                            qty: data.qty,
                                        }
                                    })}>
                                        {moment(data.updatedAt).format("MMM D")} by {data.updatedByName}
                                    </FakeLink>
                                    {data.followupRequest && <>
                                        <Tooltip title={`Expected to receive this entire order, but ${data.receivedQty} items came in. A follow up order request has been created.`}><div>*Warning</div></Tooltip>
                                    </>}
                                    {data.isWaste && <>
                                        <Tooltip title={`This item was marked as waste. See waste tab for more details`}><div>*Warning</div></Tooltip>
                                    </>}
                                </>
                            );
                        },
                        sort: (a, b) => {
                            const result = moment(a.updatedAt).unix() - moment(b.updatedAt).unix()
                            if(result === 0) return result;
                            if(result < 0) return -1;
                            return 1;
                        },
                        width: 120,
                    },
                    {
                        name: "Needed By",
                        render: (o: InventoryRequest) => {
                            const v = o.neededBy
                            if(!v) return "";

                            return (
                                <div>
                                    {moment.utc(v).format("MMMM D")}
                                    {" "}({o.neededByCalcManuallySet ? "Manual" : o.neededByCalcReleaseSet ? "Release" : "Timeline"})
                                </div>
                            )
                        },
                        editable: {type: "date", format: "MMMM D"},
                        editKey: "neededBy",
                        width: 200,
                        filter: keyMatch("neededBy"),
                        sort: sortDate("neededBy"),
                        nullable: true,
                    },
                ]}

                onChange={input => api.logistics.updateInventoryRequest(input)}
            />
            {showChangeHistoryForOpening && <ChangeHistory opening={showChangeHistoryForOpening} inventoryRequest={showChangeHistoryForOpening.inventoryRequest} onDone={() => setShowChangeHistoryForOpening(undefined)} />}
        </>
    )
}
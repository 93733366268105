import {useGetAll} from "../../../../logistics/routes/useGetAll";
import {api} from "../../../../../api/API";
import {AutoComplete} from "../../openings/AutoComplete";
import {Company} from "../../../../../api/Companies";
import {Loader} from "../../../../../misc/Loader";
import React, {useState} from "react";
import {first} from "nate-react-api-helpers";
import {useSnackbar} from "../../../../../misc/Snackbar";
import {CompanyShortCode} from "./CompanyShortCode";
import {CreateMfgDialog} from "./CreateMfgDialog";

export function GetOrCreateManufacturer2(props: {
    label: string;
    value?: number;
    onChange(value: Company): void;
    fullWidth?: boolean;
    kind: "div10" | "hardware";
    size?: "small";
    afterShortName?: any;
}) {
    const manufacturers = useGetAll(async input => {
        return api.companies.list({
            offset: input.offset,
            source: input.source,
            kind: "supplier",
        });
    }, {source: props.kind}, [props.kind]);

    const snack = useSnackbar();
    const [created, setCreated] = useState<number | null>(null);
    const [showCreate, setShowCreate] = useState<string|null>(null);

    return (
        <>
            {showCreate !== null && <CreateMfgDialog value={showCreate} onDone={(mfg) => {
                try {
                    manufacturers.reload();
                    props.onChange(mfg);
                    setCreated(mfg.id)
                    setShowCreate(null);
                } catch (e: any) {
                    snack.error(e.message);
                }
            }}  onCancel={() => setShowCreate(null)}/>}
            <Loader {...manufacturers}>
                {list => <>
                    <AutoComplete<Company>
                        label={props.label}
                        value={first(list, l => l.id === props.value)}
                        onSelect={v => {
                            props.onChange(v)
                            setCreated(null);
                        }}
                        equals={(a, b) => a.id === b.id}
                        makeLabel={a => a.name}
                        search={async search => {
                            return list.filter(v => v.name.toLowerCase().indexOf(search.toLowerCase()) === 0);
                        }}
                        onCreate={async value => {
                            setShowCreate(value);
                        }}
                        fullWidth={props.fullWidth}
                        size={props.size}
                    />

                    <div style={{height: 8}} />
                    <div style={{display: "flex"}}>
                        <CompanyShortCode disabled={created !== props.value}
                                          company={first(list, l => l.id === props.value)} />
                        {props.afterShortName && <div style={{width: 6}}></div>}
                        {props.afterShortName}
                    </div>
                </>}
            </Loader>
        </>
    )
}
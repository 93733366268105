import React, {CSSProperties, PropsWithChildren, useState} from "react";
import {MenuItem, Popover} from "@mui/material";
import {red} from "@mui/material/colors";
import {editLinkClass} from "../summary/EditLink";

export function MenuPopout(props: PropsWithChildren<{
    style?: CSSProperties
    className?: string;
    options: {
        display: string;
        danger?: boolean;
        onClick(): void;
    }[]
}>) {
    const [show, setShow] = useState<HTMLElement|null>(null);
    return (
        <>
            <div className={props.className || editLinkClass} style={props.style} onClick={e => setShow(e.currentTarget)}>
                {props.children}
            </div>
            {show && <Popover onClose={() => setShow(null)} open anchorEl={show}>
                {props.options.map(o => <MenuItem style={{
                    color: o.danger ? red["800"] : undefined,
                }} onClick={() => {
                    setShow(null);
                    o.onClick();
                }}>{o.display}</MenuItem>)}
            </Popover>}
        </>
    )
}
import {useEffect, useState} from "react";
import {ProjectFileSystem} from "./FileColumns";

export function useFileStickySelected(props: {
    project: number;
    kind: string;
    list: ProjectFileSystem;
    selectedId: number|null;
    selectId(id: number|null): void;
}) {
    const {list, selectId, selectedId} = props;

    const storageKey = `FileColumns.selected.${props.project}.${props.kind}`;
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if(ready) return
        if(list.directories.length === 0) return;

        setReady(true);

        const stored = localStorage.getItem(storageKey);
        if(!stored) {
            return;
        }

        const id = JSON.parse(stored);
        selectId(id);
    }, [list, ready, selectId, storageKey])

    useEffect(() => {
        if(!ready) return;
        localStorage.setItem(storageKey, JSON.stringify(selectedId))
    }, [ready, selectedId, storageKey]);
}
import {EditLink} from "./EditLink";
import {AddressEdit} from "./AddressEdit";
import {UsrEdit} from "./UsrEdit";
import {ClientEdit} from "./ClientEdit";
import React from "react";
import {css} from "@emotion/css";
import {Project} from "../../../../api/Projects";
import {AliasEdit} from "./AliasEdit";
import {UnitSystemEdit} from "./UnitSystemEdit";

export function SummarySection(props: {
    project: Project;
}) {
    const project = props.project;

    return (
        <table className={keyValueTable}>
            <tbody>
            <tr>
                <th>Alias</th>
                <td>
                    <EditLink editor={AliasEdit}>
                        {project.nameAlias || <i>Missing Alias</i>}
                    </EditLink>
                </td>
            </tr>
            <tr>
                <th>Address</th>
                <td>
                    <EditLink editor={AddressEdit}>
                        {project.addressStreet || <i>Missing street</i>}, {project.addressCity || <i>Missing city</i>}
                    </EditLink>
                </td>
            </tr>
            {project.stage === "quote" ? <>
                    <tr>
                        <th>Estimator</th>
                        <td>
                            <EditLink editor={UsrEdit}>
                                {project.internalRepName || <i>Missing Estimator</i>}
                            </EditLink>
                        </td>
                    </tr>
                </> : <>
                    <tr>
                        <th>Project Manager</th>
                        <td>
                            <EditLink editor={UsrEdit}>
                                {project.pmName || <i>Missing PM</i>}
                            </EditLink>
                        </td>
                    </tr>
                </>}
            <tr>
                <th>Owner</th>
                <td>
                    <EditLink editor={ClientEdit}>
                        {project.clientName || <i>Missing Owner</i>}
                    </EditLink>
                </td>
            </tr>
            <tr>
                <th>Unit System</th>
                <td>
                    <EditLink editor={UnitSystemEdit}>
                        {ucFirst(project.unitSystem)}
                    </EditLink>
                </td>
            </tr>
            <tr>
                <th>Current Version</th>
                <td>
                    {project.versionCode}
                </td>
            </tr>
            </tbody>
        </table>
    )
}

const keyValueTable = css({
    fontSize: "0.9rem",
    border: "none",
    borderSpacing: 0,

    "& th": {
        paddingRight: 20,
        paddingLeft: 0,
        textAlign: "left",
    }
})

export function ucFirst(value: string) {
    if(!value || value.length === 0) return value;
    return value.substring(0, 1).toUpperCase() + value.substring(1)
}
import { Button } from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {CheckboxContext} from "../../../project/shopdrawing/release/Checkbox";
import {api} from "../../../../api/API";
import {purchaseOrderItemsTableName} from "../Items";
import {toSourceTableName} from "../PartsToSource";
import {TableSelectionContext, useTableData} from "../../../../misc/scroller/Table";
import {SnackContext} from "../../../../misc/Snackbar";
import {ToSourceItem} from "../../../../api/Logistics";
import {groupByArr, sum} from "nate-react-api-helpers";
import {purchasingPopoutPanel} from "../PurchaseOrderList";
import {decorate} from "../../../../misc/pdf/parts/CoverPage";
import {humanizeDoorCore} from "../../../project/quote/pricing/QuotePDF";
import {findValues} from "../FindValues";

export function SubmitButton(props: {
    purchaseOrder?: number;
    isDraft: boolean;
    sourceMode: "stock" | "to-order";
}) {
    const {listenAll} = useContext(CheckboxContext);
    const [selected, setSelected] = useState<number[]>([]);

    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);

    useEffect(() => {
        const sub = listenAll(setSelected)
        return () => sub.cancel();
    }, [listenAll])

    const data = useTableData<ToSourceItem>(toSourceTableName)
    const isRestock = props.sourceMode === "stock";

    return (
        <div style={{padding: 4,}}>
            <Button onClick={async () => {
                if(!props.purchaseOrder) return;
                const purchaseOrder = props.purchaseOrder;

                try {
                    snack.loading();

                    const toAdd = data.filter(d => selected.indexOf(d.id) !== -1);

                    const updates = groupByArr(toAdd, item => item.product + "." + formatDescription(item)).map(grp => {
                        return api.logistics.updatePurchaseOrderItem({
                            id: 0,
                            purchaseOrder: purchaseOrder,
                            reference: "",
                            description: formatDescription(grp.values[0]),
                            code: grp.values[0].productCode,
                            qty: sum(grp.values.map(v => v.qty)),
                            unitPriceCents: grp.values[0].lastPrice || grp.values[0].lastPriceAtOthers || 0,
                            inventoryRequests: grp.values.map(v => {
                                const list: number[] = [];

                                if(v.inventoryRequestId) {
                                    list.push(v.inventoryRequestId)
                                }

                                if(v.frameAnchorDetail) {
                                    list.push(...findValues(v.frameAnchorDetail, v => v.inventoryRequestId));
                                }

                                return list;
                            }).flat(),
                            archived: false,
                            updatedAt: (new Date()) as any,
                            kind: grp.values[0].kind as any,
                            qtyOutstanding: 0,
                            createStockingInventoryRequestForProduct: isRestock ? grp.values[0].product : undefined,
                        })
                    });

                    await Promise.all(updates);

                    purchasingPopoutPanel.sendToServer("refresh-po");
                    ctx.refresh(toSourceTableName);
                    snack.success("Added")
                } catch(e: any) {
                    snack.error(e.toString())
                    ctx.refresh(purchaseOrderItemsTableName);
                    ctx.refresh(toSourceTableName);
                }
            }} variant="contained" disabled={!props.purchaseOrder || !props.isDraft || selected.length === 0}>Add to PO</Button>
        </div>
    );
}

export type DescriptionInput = {
    doorCore: string|null;
    doorMaterial: string|null;
    doorSeries: string|null;
    doorGauge: string|null;
    doorThickness: string|null;
    doorSeam: string|null;
    doorElevation: string|null;
    doorLabel: string|null;

    frameSeries: string |null;
    frameConstruction: string |null;
    frameType: string |null;
    frameMaterial: string |null;
    frameHanding: string |null;
    frameLabel: string |null;
    frameGauge: string|null;
    frameScreenElev: string|null;
    frameJambDepth: string|null;

    div10Category: string |null;
    div10LockerTiers: string |null;
    div10LockerCoating: string |null;
    div10LockerSloped: boolean |null;
    div10LockerEndPanels: boolean |null;
    div10MailboxLoading: string |null;
    div10MailboxMounting: string | null;
    div10MailboxTenantUnits: number |null;
    div10MailboxParcelUnits: number |null;
    div10PartitionBracing: string |null;
    div10PartitionCoating: string |null;
    div10Description: string |null;
    div10Note: string |null;

    frameAnchorName?: string | null;

    description: string;
    productCode: string;
    finish: string;
    dimensions: string;
    keyingDetail?: string | null;

    dimWidth: string;
    dimHeight: string;
    dimLength: string;
}

export function formatDescription(input: DescriptionInput, plural: boolean = false) {

    if(!!input.doorSeries) {
        const series = !input.doorSeries ? null : plural ? input.doorSeries.replace("Door", "Doors") : input.doorSeries;
        const core = humanizeDoorCore(input.doorCore, input.doorSeries);

        let list = [
            input.doorMaterial,
            core,
            series,
            "-",
            input.doorGauge ? input.doorGauge + "ga" : "",
            "-",
            input.dimWidth + " x " + input.dimHeight + " x " + input.doorThickness,
            "-",
            input.doorSeam,
            "-",
            input.doorElevation,
            "-",
            input.doorLabel,
        ]

        if(series?.indexOf("HM Door") !== -1) {
            list = [
                series,
                "-",
                input.doorGauge ? input.doorGauge + "ga" : "",
                core,
                "-",
                input.dimWidth + " x " + input.dimHeight + " x " + input.doorThickness,
                "-",
                input.doorSeam,
                "-",
                input.doorElevation,
                "-",
                input.doorLabel,
            ]
        }

        return list
            .filter(v => !!v)
            .join(" ")
            .replace(/( -){2,}/g, " -")
            .replace(/[- ]+$/g, "")
    }

    if(input.frameSeries !== null && input.frameSeries !== undefined) {

        const series = !input.frameSeries ? null : plural ? input.frameSeries.replace("Frame", "Frames") : input.frameSeries;

        return [
            input.frameType,
            series,
            "-",
            decorate(input.frameGauge, "", "ga"),
            input.frameConstruction,
            "-",
            `${input.dimWidth} x ${input.dimHeight} x ${input.frameJambDepth}`,
            "-",
            input.frameLabel,
            "-",
            input.frameScreenElev,
            "-",
            input.frameAnchorName,
        ]
            .filter(v => !!v)
            .join(" ")
            .replace(/( -){2,}/g, " -")
            .replace(/[- ]+$/g, "")
    }


    if(!!input.div10Category) {
        switch(input.div10Category) {
            case 'corner-guard':
                return [
                    "Corner Guard",
                    input.productCode,
                    input.dimWidth + "W x " + input.dimHeight + "H",
                ].filter(v => !!v).join(" ")
            case "locker":
                return [
                    "Locker",
                    input.div10LockerTiers,
                    input.div10LockerCoating,
                    input.div10LockerSloped ? "sloped" : "",
                    input.div10LockerEndPanels ? "with end panels" : "",
                    input.dimWidth + "W x " + input.dimHeight + "H x " + input.dimLength + "D",
                    input.div10Note,
                ].filter(v => !!v).join(" ")
            case "mailbox":
                return [
                    "Mailbox",
                    input.div10MailboxLoading,
                    input.div10MailboxMounting,
                    input.div10MailboxTenantUnits + " Tenant Units",
                    input.div10MailboxParcelUnits + " Parcel Units",
                    input.div10Note,
                ].filter(v => !!v).join(" ")
            case "wr-accessory":
                // use hardware format
                break;
            case "wr-partition":
                return [
                    "Partition",
                    input.div10PartitionBracing,
                    input.div10PartitionCoating,
                    input.div10Description,
                    input.div10Note,
                ].filter(v => !!v).join(" ")
            default:
                console.warn("unknown div10 category")
                break;
        }
    }

    return [
        input.description,
        "-",
        input.productCode,
        input.finish,
        decorate(input.keyingDetail, "Keying detail: ", "") || "",
        input.dimensions
    ].filter(v => !!v).join(" ")
}
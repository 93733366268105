import {InventoryRequest} from "../../../../api/Logistics";
import {getAll, StatusCol} from "../openings/Openings";
import {api} from "../../../../api/API";
import {CheckboxSelectorCol} from "../release/Checkbox";
import {DateCol, Nullable, NumberCol, PrefixSearch, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import moment from "moment/moment";
import {Tooltip} from "@mui/material";
import {logisticsTableName} from "./Logistics";
import {useProjectId} from "../ProjectName";

export function Div10() {
    const project = useProjectId();

    return (
        <>
            <Table<InventoryRequest>
                name={logisticsTableName(project, "div10")}
                globalPrefsName={logisticsTableName(0, "div10")}
                fetch={ctx => getAll(ctx, offset => api.logistics.listInventoryRequest({
                    project: project,
                    offset: offset,
                    category: "div10",
                }))}
                fetchDeps={[project]}
                columns={[
                    CheckboxSelectorCol(),
                    ViewOnly(StringCol("Room", "room", 100)),
                    ViewOnly(StringCol("Type", "category", 100)),
                    ViewOnly(StringCol("Product", "productCategoryName", 100)),
                    ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                    ViewOnly(NumberCol("Qty", "qty", 50)),
                    StatusCol("Status",  "status"),
                    {
                        name: "Meta",
                        render: (data) => {
                            return (
                                <>
                                    <span>
                                        {moment(data.updatedAt).format("MMM D")} by {data.updatedByName}
                                    </span>
                                    {data.followupRequest && <>
                                        <Tooltip title={`Expected to receive this entire order, but ${data.receivedQty} items came in. A follow up order request has been created.`}><div>*Warning</div></Tooltip>
                                    </>}
                                </>
                            );
                        },
                        width: 120,
                    },
                    Nullable(DateCol("Needed By", "neededBy", "MMMM D", 200))
                ]}

                onChange={input => api.logistics.updateInventoryRequest(input)}
            />
        </>
    )
}
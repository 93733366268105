import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import {sleep, useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useState} from "react";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";

export function WaitForAnalysisDialog(props:{
    value: string;
    onDone(): void;
}) {

    const [status, setStatus] = useState("Loading...");
    const [sampleTable, setSampleTable] = useState<string[][]>();
    const [issues, setIssues] = useState<string[]>();
    const loader = useAsync2(async (input) => {

        while(true) {
            const result = await api.extractor.analyzeStatus(input)
            setStatus(result.status);
            setIssues(result.issues);

            if(result.sampleTable) {
                setSampleTable(result.sampleTable)
            }

            if(result.done) {
                if(result.status !== "Done") {
                    throw new Error(result.status);
                }
                break;
            }

            await sleep(800);
        }

        return true;

    }, {
        id: props.value,
    }, [props.value])

    return (
        <Dialog open>
            <DialogTitle style={{minWidth: 400}}>
                Analyzing PDF
            </DialogTitle>

            <DialogContent>
                {loader.loading && <>
                    <div>{status}</div>
                    <LinearProgress variant="indeterminate" />
                </>}
                {loader.error && <Alert color="error">
                    {loader.error.toString()}
                    <Button onClick={() => loader.reload()}>Retry</Button>
                </Alert>}
                {issues && issues.length > 0 && <Alert color="warning" style={{marginTop: 16, marginBottom: 16}}>
                    <div>Analysis Issues:</div>
                    {issues.map(issue => <div>{issue}</div>)}
                </Alert>}

                {!loader.loadingOrError && !!loader.result && <>
                    <DialogContentText>
                        Analysis complete! Download your combined excel sheet below.
                    </DialogContentText>
                    <Button onClick={() => {
                        window.open(api.extractor.downloadURL({id: props.value}), "_blank");
                    }}>
                        Download Combined Excel Sheet
                    </Button>
                    {sampleTable && <div style={{marginTop: 16, maxHeight: 200, maxWidth: "100%", overflow: "auto"}}>
                        <div>Sample:</div>
                        <table className={tableCss}>
                            <tbody>
                            {sampleTable.map(row => {
                                return (
                                    <tr>
                                        {row.map(cell => <td>{cell}</td>)}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>}
                </>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onDone()} disabled={loader.loading}>Review Doc</Button>
                <Button onClick={() => {
                    window.location.reload();
                }} disabled={loader.loading}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

const tableCss = css({
    borderCollapse: "collapse",

    "& td": {
        border: "1px solid " + grey["300"],
    }
})
import {styled} from "@mui/material";
import {EditContext} from "./EditableTable";
import {useContext, useState} from "react";
import {TabCommand} from "./UseTabber";
import {TID} from "./ListCommon";


const Input = styled("input")(props => ({
    border: "none",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: "0.9rem",
    flex: 1,
    width: "auto",
    wordWrap: "break-word",
    wordBreak: "break-all",
    height: "100%"
}));

export function EditCell<T extends TID>(props: {
    row: T
    field: keyof T;
}) {
    const ctx = useContext(EditContext);
    const initial = props.row[props.field] as any as string;
    const [value, setValue] = useState(initial);
    const update = (input: TabCommand|null) => {
        ctx.upsert(Object.assign({}, props.row, {
            [props.field]: value
        }), {
            tab: input,
        })
    }

    return (
        <div style={{display: "flex"}}>
            <form style={{display: "flex", flex: 1}} onSubmit={e => {
                e.preventDefault();
                update("submit")
            }}>
                <Input
                    ref={r => {
                        r?.focus();
                    }}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onKeyDown={e => {
                        switch(e.key) {
                            case "Tab":
                                e.preventDefault();

                                if(e.shiftKey) {
                                    update("backward");
                                } else {
                                    update("forward");
                                }
                                break;
                            case "ArrowLeft":
                                if(e.currentTarget.selectionStart === 0) {
                                    update("backward");
                                }
                                break;
                            case "ArrowRight":
                                console.log(e.currentTarget.selectionEnd);
                                if(e.currentTarget.selectionEnd === value.length) {
                                    update("forward");
                                }
                                break;
                            case "ArrowUp":
                                update("up");
                                break;
                            case "ArrowDown":
                                update("down");
                                break;
                        }
                    }}
                    onBlur={() => update(null)}
                />
            </form>
        </div>
    )
}
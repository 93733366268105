import {SourceMode, ToSourceItem} from "../../../../api/Logistics";
import {
    Column,
    DateCol,
    Nullable,
    NumberCol, PrefixSearch,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {SimpleIconButton} from "../../../../misc/lists/SimpleIconButton";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import AddCircle from "@mui/icons-material/AddCircle";
import {getAll} from "../../../project/shopdrawing/openings/Openings";
import {ProjectCol, toSourceTableName} from "../PartsToSource";
import {useContext, useMemo} from "react";
import {SnackContext} from "../../../../misc/Snackbar";
import {CheckboxSelectorCol} from "../../../project/shopdrawing/release/Checkbox";
import {formatDescription} from "./SubmitButton";
import {purchasingPopoutPanel} from "../PurchaseOrderList";
import {InfoIcon} from "./InfoIcon";
import {DistributionMode} from "../DistributionModeToggle";

export function Component(props: {
    purchaseOrder?: number;
    supplier?: {
        id: number;
        name: string;
    }
    sourceMode: SourceMode;
    distributionMode: DistributionMode;
}) {
    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);
    const usr = useUser();

    const supplierId = props.supplier?.id || 0;
    const key = useMemo(() => {
        return props.purchaseOrder + "-" + supplierId + "-" + props.sourceMode;
    }, [props.purchaseOrder, supplierId, props.sourceMode])

    const supplier: any = [];
    if(!props.supplier) {
        supplier.push(ViewOnly(StringCol<ToSourceItem>("Supplier", "supplierName", 200)));
    }

    const isRestock = props.sourceMode === "stock"

    return (
        <Table<ToSourceItem>
            key={key}
            name={toSourceTableName}
            columns={[
                props.purchaseOrder ? CheckboxSelectorCol({
                    disabled: row => !hasPermission(usr, "CanReleaseAndPurchase") && usr?.id === row.releasedBy,
                }) : null,
                !isRestock && ViewOnly(ProjectCol("Project", "projectName", 100)),
                !isRestock && ViewOnly(StringCol("Opening", "openingName", 100)),
                !isRestock && ViewOnly(StringCol("Floor", "openingFloor", 70)),
                ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                ...supplier,
                ViewOnly({
                    name: "Name",
                    render: dt => {
                        if(dt.manufacturer === dt.supplier) {
                            return dt.name;
                        }

                        return dt.manufacturerName + ": " + dt.name;
                    },
                    width: 200,
                }),
                ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                ViewOnly(StringCol("Dim.", "dimensions", 60)),
                ViewOnly(NumberCol("Need", "qty", 60)),
                isRestock && ViewOnly(NumberCol("Stock", "stockQty", 60)),
                isRestock && ViewOnly(NumberCol("Rsrvd", "reservedQty", 60)),
                isRestock && ViewOnly(NumberCol("Pend.PO", "purchasedQty", 90)),
                isRestock && ViewOnly(NumberCol("Stock Min", "stockMinQty", 90)),
                isRestock && ViewOnly(NumberCol("Ideal Qty", "idealQty", 90)),
                {
                    name: "Actions",
                    render: (dt: ToSourceItem, col: Column<ToSourceItem>) => (
                        <div style={{display: "flex"}}>
                            <InfoIcon row={dt} />
                            {props.purchaseOrder && <div>
                                <SimpleIconButton disabled={!hasPermission(usr, "CanReleaseAndPurchase") && usr?.id === dt.releasedBy} onClick={async () => {
                                    if(!props.purchaseOrder) return;

                                    try {
                                        snack.loading();

                                        await api.logistics.updatePurchaseOrderItem({
                                            id: 0,
                                            purchaseOrder: props.purchaseOrder,
                                            reference: "",
                                            description: formatDescription(dt),
                                            code: dt.productCode,
                                            qty: dt.qty,
                                            unitPriceCents: dt.lastPrice || dt.lastPriceAtOthers || 0,
                                            inventoryRequests: dt.inventoryRequestId ? [dt.inventoryRequestId] : [],
                                            archived: false,
                                            updatedAt: (new Date()) as any,
                                            kind: "component",
                                            qtyOutstanding: 0,

                                            createStockingInventoryRequestForProduct: isRestock ? dt.product : undefined,
                                        });

                                        purchasingPopoutPanel.sendToServer("refresh-po");
                                        ctx.refresh(toSourceTableName);
                                        snack.success("Added")
                                    } catch(e: any) {
                                        snack.error(e.toString())
                                    }
                                }}>
                                    <AddCircle />
                                </SimpleIconButton>
                            </div>}
                        </div>),
                    width: 60,
                },
            ]}
            fetch={ctx => getAll(ctx, offset => api.logistics.listToSourceItems({
                offset,
                supplier: props.supplier?.id,
                type: "component",
                sourceMode: props.sourceMode,
                distributionMode: props.distributionMode,
            }))}
            fetchDeps={[props.supplier, props.sourceMode, props.distributionMode]}
        />
    )
}
import {useEffect, useState} from "react";
import {CheckboxProvider} from "../../project/shopdrawing/release/Checkbox";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import {Tab, Tabs} from "@mui/material";
import {SourceHeader, tabGroup} from "../purchasing/PartsToSource";
import {PickupTable, pickupTableName} from "./PickupTable";
import {AddItemsButton} from "./AddItemsButton";
import {DropTable, dropTableName} from "./DropTable";
import {useTableRefresher} from "../../../misc/scroller/Table";
import {routePopupPanel} from "./RouteList";

export type ToMoveProps = {
    route?: number;
};

export function ToMove(props: ToMoveProps) {
    const [tab, setTab] = useState("pickup")

    const refresh = useTableRefresher(tab === "pickup" ? pickupTableName : dropTableName);

    useEffect(() => {
        const sub = routePopupPanel.fromServer.subscribeAndFireLast(input => {
            if(input === "refresh-to-move") {
                refresh();
            }
        })

        return () => sub.cancel();
    }, [refresh]);

    return (
        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
            <SourceHeader>
                Items to Pickup or Deliver
            </SourceHeader>

            <CheckboxProvider>
                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                }}>
                    <TabWrapper>
                        <Tabs className={tabGroup} value={tab} onChange={(e, value) => setTab(value)}>
                            <Tab label="Pickup at Supplier" value="pickup" />
                            <Tab label="Deliver from Warehouse" value="drop" />
                            <div style={{flex: 1}} />
                            <div style={{padding: 4}}>
                                <AddItemsButton route={props.route} pickup={tab === "pickup"} />
                            </div>
                        </Tabs>
                    </TabWrapper>

                    <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "hidden"}}>
                        {tab === "pickup" ? <PickupTable route={props.route} /> :
                            <DropTable route={props.route} />
                        }
                    </div>
                </div>
            </CheckboxProvider>
        </div>

    )
}
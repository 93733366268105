import {EventEmitter} from "nate-react-api-helpers";
import {ImperialDistance} from "../../../../../misc/ImperialDistance";

export type PrepValues = {
    a: PrepValue;
    b: PrepValue;
    c: PrepValue;
    d: PrepValue;
    e: PrepValue;
    aStar: PrepValue;
    dbf: PrepValue;
    dbfStar: PrepValue;
    clf: PrepValue;
    clfStar: PrepValue;
    dbd: PrepValue;
    dbdStar: PrepValue;
    cld: PrepValue;
    cldStar: PrepValue;
    bs: PrepValue;
}

type PrepValue = {
    value: number;
    enabled: boolean;
}

export type InternalValue = {
    cValue: number; // Calculated/default value
    actualValue: number; // Actual value
    value?: number; // Set value
    enabled: boolean; // Show this value
    changed: EventEmitter<true>;
    label: string; // Label (calculated here)
}

export type PrepInternalOption = {
    name: string;
    value: InternalValue;
    onChange: (v: number | undefined, enabled: boolean) => void;
}

type InternalValues = {
    a: InternalValue;
    b: InternalValue;
    c: InternalValue;
    d: InternalValue;
    e: InternalValue;
    aStar: InternalValue;
    dbf: InternalValue;
    dbfStar: InternalValue;
    clf: InternalValue;
    clfStar: InternalValue;
    dbd: InternalValue;
    dbdStar: InternalValue;
    cld: InternalValue;
    cldStar: InternalValue;
    bs: InternalValue;
}

export class PrepCanvasManager {
    values: InternalValues;

    nominalHeight = 0;
    nominalWidth: number = 0;
    secondaryWidth: number = 0;
    overrideHingeQty: number = 0;

    pair: boolean = false;
    afterCalculate = new EventEmitter<true>();

    constructor(input: {
        nominalHeight: number,
        nominalWidth: number,
        secondaryWidth?: number,
        overrides: Partial<PrepValues> | undefined,
        pair: boolean
        overrideHingeQty: number | 0;
        hasDeadbolt: boolean;
    }) {
        const {nominalHeight, nominalWidth, secondaryWidth, overrides, pair, overrideHingeQty} = input;
        this.nominalHeight = nominalHeight;
        this.nominalWidth = nominalWidth;
        this.secondaryWidth = secondaryWidth || 0;
        this.pair = pair;
        this.overrideHingeQty = overrideHingeQty;

        this.values = {
            a: this.makeValue(overrides?.a, "A"),
            b: this.makeValue(overrides?.b, "B"),
            c: this.makeValue(overrides?.c, "C"),
            d: this.makeValue(overrides?.d, "D"),
            e: this.makeValue(overrides?.e, "E"),
            aStar: this.makeValue(overrides?.aStar, "A*"),
            dbf: this.makeValue(overrides?.dbf, "DBF"),
            dbfStar: this.makeValue(overrides?.dbfStar, "DBF*"),
            clf: this.makeValue(overrides?.clf, "CLF"),
            clfStar: this.makeValue(overrides?.clfStar, "CLF*"),
            dbd: this.makeValue(overrides?.dbd, "DBD"),
            dbdStar: this.makeValue(overrides?.dbdStar, "DBD*"),
            cld: this.makeValue(overrides?.cld, "CLD"),
            cldStar: this.makeValue(overrides?.cldStar, "CLD*"),
            bs: this.makeValue(overrides?.bs, "BS"),
        }

        if(!overrides?.bs) {
            this.values.bs.enabled = false;
        }

        if(!input.hasDeadbolt) {
            if (!overrides?.dbf && !overrides?.dbfStar && !overrides?.dbd && !overrides?.dbdStar) {
                this.values.dbf.enabled = false;
                this.values.dbfStar.enabled = false;
                this.values.dbd.enabled = false;
                this.values.dbdStar.enabled = false;
            }
        }

        this.calculate();
    }

    getHingeQty() {
        if(this.overrideHingeQty > 0) {
            return this.overrideHingeQty;
        }

        if(this.values.e.enabled) {
            return 5;
        }

        if(this.values.d.enabled) {
            return 4;
        }

        return 3;
    }

    makeValue(input: PrepValue | undefined, name: string) {
        return {
            cValue: 0,
            label: name,
            value: input?.value || undefined,
            actualValue: ifUndef(input?.value, 0),
            enabled: input?.enabled ?? true,
            changed: new EventEmitter<true>(),
        }
    }

    setEnabled(key: keyof InternalValues, enabled: boolean) {
        const v = this.values[key];
        if(v.enabled === enabled) return;

        v.enabled = enabled;
        v.changed.emit(true);
    }

    calculate() {
        this.values.a.cValue = universal("7.5\"")
        this.values.aStar.cValue = universal("7 3/8\"")

        const changes: InternalValue[] = [
            this.values.a,
            this.values.aStar
        ];

        if(this.isOverride("a")) {
            this.values.aStar.cValue = this.actualValue("a") - universal("1/8");
        } else if(this.isOverride("aStar")) {
            this.values.a.cValue = this.actualValue("aStar") + universal("1/8");
        }

        const height = this.lookupHingeSpacing(this.nominalHeight);

        this.values.b.cValue = height.hingeSpacing
        this.values.c.cValue = height.hingeSpacing
        this.values.d.cValue = height.hingeSpacing
        this.values.e.cValue = height.hingeSpacing

        if(this.overrideHingeQty === 2) {
            this.setEnabled("c", false)
        }

        const abcd = this.actualValue("a") + this.actualValue("b") + this.actualValue("c") + this.actualValue("d");
        if(abcd < this.nominalHeight || this.overrideHingeQty >= 4) {
            this.setEnabled("d", true)
        } else {
            this.setEnabled("d", false)
        }

        const abcde = abcd + this.actualValue("e")
        if(abcde < this.nominalHeight || this.overrideHingeQty >= 5) {
            this.setEnabled("e", true)
        } else {
            this.setEnabled("e", false)
        }

        this.values.clf.cValue = universal("40 5/16\"")
        this.values.clfStar.cValue = this.nominalHeight - this.values.clf.cValue;

        if(this.isOverride("clfStar")) {
            this.values.clf.cValue = this.nominalHeight - this.actualValue("clfStar");
            changes.push(this.values.clf)
        } else if(this.isOverride("clf")) {
            this.values.clfStar.cValue = this.nominalHeight - this.actualValue("clf");
            changes.push(this.values.clfStar)
        }

        this.values.cld.cValue = universal("39 9/16\"");
        this.values.cldStar.cValue = this.exactHeight() - this.values.cld.cValue;

        if(this.isOverride("cldStar")) {
            this.values.cld.cValue = this.nominalHeight - this.actualValue("cldStar");
            changes.push(this.values.cld)
        } else if(this.isOverride("cld")) {
            this.values.cldStar.cValue = this.nominalHeight - this.actualValue("cld");
            changes.push(this.values.cldStar)
        }

        // link clf and cld
        const hasClf = this.isOverride("clfStar") || this.isOverride("clf")
        const hasCld = this.isOverride("cldStar") || this.isOverride("cld")
        if(hasCld !== hasClf) {
            if(hasCld) {
                this.values.clf.cValue = this.actualValue("cld") + universal("3/4\"");
                changes.push(this.values.clf)
                this.values.clfStar.cValue = this.nominalHeight - this.actualValue("clf");
                changes.push(this.values.clfStar)
            } else {
                this.values.cld.cValue = this.actualValue("clf") - universal("3/4\"");
                changes.push(this.values.cld)
                this.values.cldStar.cValue = this.nominalHeight - this.actualValue("cld");
                changes.push(this.values.cldStar)
            }
        }

        this.values.dbf.cValue = universal("48\"");
        this.values.dbfStar.cValue = this.nominalHeight - this.values.dbf.cValue;

        if(this.isOverride("dbfStar")) {
            this.values.dbf.cValue = this.nominalHeight - this.actualValue("dbfStar");
            changes.push(this.values.dbf);
        } else if(this.isOverride("dbf")) {
            this.values.dbfStar.cValue = this.nominalHeight - this.actualValue("dbf");
            changes.push(this.values.dbfStar);
        }

        this.values.dbd.cValue = universal("47 1/4\"");
        this.values.dbdStar.cValue = this.exactHeight() - this.values.dbd.cValue;

        if(this.isOverride("dbdStar")) {
            this.values.dbd.cValue = this.nominalHeight - this.actualValue("dbdStar");
            changes.push(this.values.dbd)
        } else if(this.isOverride("dbd")) {
            this.values.dbdStar.cValue = this.nominalHeight - this.actualValue("dbd");
            changes.push(this.values.dbdStar)
        }

        // link dbf and dbd
        const hasDbf = this.isOverride("dbfStar") || this.isOverride("dbf")
        const hasDbd = this.isOverride("dbdStar") || this.isOverride("dbd")
        if(hasDbd !== hasDbf) {
            if(hasDbd) {
                this.values.dbf.cValue = this.actualValue("dbd") + universal("3/4\"");
                changes.push(this.values.dbf);
                this.values.dbfStar.cValue = this.nominalHeight - this.actualValue("dbf");
                changes.push(this.values.dbfStar);
            } else {
                this.values.dbd.cValue = this.actualValue("dbf") - universal("3/4\"");
                changes.push(this.values.dbd);
                this.values.dbdStar.cValue = this.nominalHeight - this.actualValue("dbd");
                changes.push(this.values.dbdStar);
            }
        }

        this.values.bs.cValue = universal("2 3/4\"");

        // If the value is set to the calculated value, then set it to undefined
        for(let i in this.values) {
            // @ts-ignore
            const val = this.values[i];

            if(val.value && val.cValue === val.value) {
                val.value = undefined;
                val.changed.emit(true);
            }

            val.actualValue = ifUndef(val.value, val.cValue);
        }

        this.afterCalculate.emit(true);
        changes.map(v => v.changed.emit(true))
    }

    isOverride(key: keyof InternalValues) {
        const v = this.values[key]
        return (v.value !== undefined)
    }

    actualValue(key: keyof InternalValues) {
        const v = this.values[key];
        return ifUndef(v.value, v.cValue);
    }

    options() {
        const list = [
            this.makeOption("a"),
            this.makeOption("aStar"),
            this.makeOption("b"),
            this.makeOption("c"),
            this.makeOption("d"),
            this.makeOption("e"),
            !this.pair && this.makeOption("dbf", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.dbfStar.value = undefined;
                }

                this.values.dbfStar.enabled = enabled;
                this.values.dbfStar.changed.emit(true);
            }),
            !this.pair && this.makeOption("dbfStar", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.dbf.value = undefined;
                }

                this.values.dbf.enabled = enabled;
                this.values.dbf.changed.emit(true);
            }),
            !this.pair && this.makeOption("clf", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.clfStar.value = undefined;
                }

                this.values.clfStar.enabled = enabled;
                this.values.clfStar.changed.emit(true);
            }),
            !this.pair && this.makeOption("clfStar", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.clf.value = undefined;
                }

                this.values.clf.enabled = enabled;
                this.values.clf.changed.emit(true);
            }),
            this.makeOption("cld", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.cldStar.value = undefined;
                }

                this.values.cldStar.enabled = enabled;
                this.values.cldStar.changed.emit(true);
            }),
            this.makeOption("cldStar", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.cld.value = undefined;
                }

                this.values.cld.enabled = enabled;
                this.values.cld.changed.emit(true);
            }),

            this.makeOption("dbd", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.dbdStar.value = undefined;
                }

                this.values.dbdStar.enabled = enabled;
                this.values.dbdStar.changed.emit(true);
            }),
            this.makeOption("dbdStar", (v: number | undefined, enabled: boolean) => {
                if(v !== undefined) {
                    this.values.dbd.value = undefined;
                }

                this.values.dbd.enabled = enabled;
                this.values.dbd.changed.emit(true);
            }),
            this.makeOption("bs"),
        ]

        return list.filter(v => !!v) as PrepInternalOption[];
    }

    makeOption(name: keyof PrepValues, onChange?: (v: number | undefined, enabled: boolean) => void): PrepInternalOption {
        return {
            name: name,
            value: this.values[name],
            onChange: (v: number | undefined, enabled: boolean) => {
                // @ts-ignore
                const val = this.values[name];

                if(v === 0) v = undefined;

                val.value = v;
                val.enabled = enabled;

                if(onChange) {
                    onChange(v, enabled);
                }

                this.calculate();
                val.changed.emit(true);
            }}
    }

    exactHeight() {
        return this.nominalHeight - universal("7/8\"")
    }

    calculateHingeSpacingForOverride(nominalHeight: number, qty: number) {
        const actual = nominalHeight - universal("7/8\"");
        const usable = actual - universal("14 3/4\"")
        const hingeSize = universal("4.5\"");
        const usableLessHinge = usable - hingeSize;
        const hingeSpacing = usableLessHinge / (qty - 1);
        const roundedTo64th = Math.round(hingeSpacing * 64) / 64;

        return {
            nominalHeight: nominalHeight,
            exactHeight: usable,
            hingeSpacing: roundedTo64th,
            hingeCount: qty,
        }
    }

    lookupHingeSpacing(nominalHeight: number) {
        if(this.overrideHingeQty) {
            return this.calculateHingeSpacingForOverride(nominalHeight, this.overrideHingeQty)
        }

        for(let i = 0; i < heights.length-1; i++) {
            if(heights[i+1].nominalHeight > nominalHeight) return heights[i];
        }

        return heights[heights.length - 1];
    }
}

const heights: {nominalHeight: number, exactHeight: number, hingeSpacing: number, hingeCount: number}[] = [
    {nominalHeight: universal("5'2"), exactHeight: universal("61 1/8"), hingeSpacing: universal("20 15/16"), hingeCount: 3},
    {nominalHeight: universal("5'4"), exactHeight: universal("63 1/8"), hingeSpacing: universal("21 15/16"), hingeCount: 3},
    {nominalHeight: universal("5'6"), exactHeight: universal("65 1/8"), hingeSpacing: universal("22 15/16"), hingeCount: 3},
    {nominalHeight: universal("5'8"), exactHeight: universal("67 1/8"), hingeSpacing: universal("23 15/16"), hingeCount: 3},
    {nominalHeight: universal("5'10"), exactHeight: universal("69 1/8"), hingeSpacing: universal("24 15/16"), hingeCount: 3},
    {nominalHeight: universal("6'0"), exactHeight: universal("71 1/8"), hingeSpacing: universal("25 15/16"), hingeCount: 3},
    {nominalHeight: universal("6'2"), exactHeight: universal("73 1/8"), hingeSpacing: universal("26 15/16"), hingeCount: 3},
    {nominalHeight: universal("6'4"), exactHeight: universal("75 1/8"), hingeSpacing: universal("27 15/16"), hingeCount: 3},
    {nominalHeight: universal("6'6"), exactHeight: universal("77 1/8"), hingeSpacing: universal("28 15/16"), hingeCount: 3},
    {nominalHeight: universal("6'8"), exactHeight: universal("79 1/8"), hingeSpacing: universal("29 15/16"), hingeCount: 3},
    {nominalHeight: universal("6'10"), exactHeight: universal("81 1/8"), hingeSpacing: universal("30 15/16"), hingeCount: 3},
    {nominalHeight: universal("7'0"), exactHeight: universal("83 1/8"), hingeSpacing: universal("31 15/16"), hingeCount: 3},
    {nominalHeight: universal("7'2"), exactHeight: universal("85 1/8"), hingeSpacing: universal("32 15/16"), hingeCount: 3},
    {nominalHeight: universal("7'4"), exactHeight: universal("87 1/8"), hingeSpacing: universal("33 15/16"), hingeCount: 3},
    {nominalHeight: universal("7'6"), exactHeight: universal("89 1/8"), hingeSpacing: universal("34 15/16"), hingeCount: 3},
    {nominalHeight: universal("7'8"), exactHeight: universal("91 1/8"), hingeSpacing: universal("23 61/64"), hingeCount: 4},
    {nominalHeight: universal("7'10"), exactHeight: universal("93 1/8"), hingeSpacing: universal("24 5/8"), hingeCount: 4},
    {nominalHeight: universal("8'0"), exactHeight: universal("95 1/8"), hingeSpacing: universal("25 19/64"), hingeCount: 4},
    {nominalHeight: universal("8'2"), exactHeight: universal("97 1/8"), hingeSpacing: universal("25 61/64"), hingeCount: 4},
    {nominalHeight: universal("8'4"), exactHeight: universal("99 1/8"), hingeSpacing: universal("26 5/8"), hingeCount: 4},
    {nominalHeight: universal("8'6"), exactHeight: universal("101 1/8"), hingeSpacing: universal("27 19/64"), hingeCount: 4},
    {nominalHeight: universal("8'8"), exactHeight: universal("103 1/8"), hingeSpacing: universal("27 61/64"), hingeCount: 4},
    {nominalHeight: universal("8'10"), exactHeight: universal("105 1/8"), hingeSpacing: universal("28 5/8"), hingeCount: 4},
    {nominalHeight: universal("9'0"), exactHeight: universal("107 1/8"), hingeSpacing: universal("29 19/64"), hingeCount: 4},
    {nominalHeight: universal("9'2"), exactHeight: universal("109 1/8"), hingeSpacing: universal("29 61/64"), hingeCount: 4},
    {nominalHeight: universal("9'4"), exactHeight: universal("111 1/8"), hingeSpacing: universal("30 5/8"), hingeCount: 4},
    {nominalHeight: universal("9'6"), exactHeight: universal("113 1/8"), hingeSpacing: universal("31 19/64"), hingeCount: 4},
    {nominalHeight: universal("9'8"), exactHeight: universal("115 1/8"), hingeSpacing: universal("31 61/64"), hingeCount: 4},
    {nominalHeight: universal("9'10"), exactHeight: universal("117 1/8"), hingeSpacing: universal("32 5/8"), hingeCount: 4},
    {nominalHeight: universal("10'0"), exactHeight: universal("119 1/8"), hingeSpacing: universal("33 19/64"), hingeCount: 4},
    {nominalHeight: universal("10'2"), exactHeight: universal("121 1/8"), hingeSpacing: universal("33 61/64"), hingeCount: 4},
    {nominalHeight: universal("10'4"), exactHeight: universal("123 1/8"), hingeSpacing: universal("34 5/8"), hingeCount: 4},
]

function ifUndef(a: number | undefined, b: number) {
    if(a === undefined) return b;
    return a;
}

function universal(value: string) {
    return ImperialDistance.parse(value).universalValue()
}
import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";

export type ListInput = {
    search: string;
    offset: number;
    archived?: boolean;
}
export type TID = { id: number };
export const TableHead = styled("thead")(props => ({
    "& th": {
        fontSize: "0.75rem",
        fontWeight: "bold",
        borderBottom: "1px solid " + grey["300"],
        paddingTop: 8,
    }
}));
export const TableBody = styled("tbody")(props => ({}))

export function ucWords(input: string) {
    return input.split(" ").map(i => {
        return i.substring(0, 1).toUpperCase() + i.substring(1)
    }).join(" ")
}
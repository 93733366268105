import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";

export class Keying extends SubAPI {
    list(props: {
        project: number,
        offset?: number,
        previewChangeset?: number;
    }) {
        return this.fetcher.get<Paginated<KeyOpening>>("/api/keying", props);
    }

    listNames(props: {project: number}) {
        return this.fetcher.get<KeyName[]>("/api/keying/names", props);
    }

    upsertName(input: KeyName) {
        return this.fetcher.post<KeyName>("/api/keying/name", input);
    }

    set(input: KeyOpening) {
        return this.fetcher.post("/api/keying/set-for-door", input);
    }

    getDetailsForPO(input: {
        id: number;
    }) {
        return this.fetcher.get<KeyDetails>("/api/keying/details-for-po", input)
    }
}

export type KeyDetails = {
    purchaseOrder: number;
    info: string | ""; // submission preference value
    names: KeyName[];
    rows: KeyOpening[]
}

export type KeyOpening = {
    id: number;
    seqNumber: number; // opening sequence number (not editable here)
    floor: string;
    manufacturer: number;
    manufacturerName: string;
    locationTwo: string;
    name: string;
    products: {id: number; name: string;}[];
    productsAreSet: boolean;
    allProducts: {
        id: number;
        name: string;
        keyed: boolean
        category2?: string;
        category3?: string;
    }[];
    keying: number[];
    keyed: boolean;

    modifiedInCurrentChangeset: boolean;
}

export type KeyName = {
    id: number;
    project: number;
    short: string;
    description: string;
    archived: boolean;
}
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import React, {useContext} from "react";
import {PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useAlternative} from "../alternative/Alternative";
import {useSubmittalPreferences} from "../../../../misc/pdf/useSubmittalPreferences";
import {getQuotePDF, QuotePDFData} from "./QuotePDF";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";
import {getContractor} from "../../shopdrawing/changeset/PrintSubmissions";

export function QuoteSubmittal() {
    const printCtx = useContext(PrintManagerContext);
    const project = useProjectId();
    const alternative = useAlternative();

    const prefs = useSubmittalPreferences({
        project: project,
        kind: "quote",
        onLoad: (list: {
            version: 1,
            hollowMetalNotes: string;
            woodNotes: string;
            aluminumNotes: string;
            hardwareNotes: string;
            div10Notes: string;
            autoOperatorNotes: string;
            installNotes: string;
            generalNotes: string;
        } | { version: 2 }) => {}
    });

    return (
        <>
            <SmallGreyButton startIcon={<AddCircleIcon />} onClick={() => {
                prefs.save({
                    version: 2,
                })

                printCtx.print2(getQuotePDF({
                    project: project,
                    data: async (): Promise<QuotePDFData> => {
                        const prices = getAll(null, offset =>
                            api.quotePricing.list({project: project, alternative, offset})
                        )

                        const misc = getAll(null, offset =>
                            api.quoteMiscPricing.list({project: project, alternative, offset})
                        );

                        const riders = getAll(null, offset => api.quotePricing.listRiders({
                            project: project, alternative, offset
                        }))

                        const notes = getAll(null, offset => api.quotePriceSectionNotes.list({
                            project: project, alternative, offset
                        }))

                        const quoteNumber = api.projects.getProjectNumber({project, alternative});
                        const projectObj = api.projects.get(project);

                        const project2 = await projectObj;
                        const contractor = getContractor(project2)

                        return {
                            prices: await prices,
                            riders: await riders,
                            miscPrices: await misc,
                            notes: await notes,
                            currentQuoteNumber: await quoteNumber,
                            contractor: await contractor,
                            project: await projectObj
                        }
                    },
                }))
            }}>
                Export Quote
            </SmallGreyButton>
        </>
    )
}
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {Button, Grid, MenuItem, Popover} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API"
import {Loader} from "../../../../misc/Loader";
import {Project} from "../../../../api/Projects";
import {useIsQuote} from "../QuoteContainer";

export function QuoteSwitcher(props: {
    title: string;
    quoteTitle: string;

    url: string;
    quoteURL: string;
}) {
    const [anchor, setAnchor] = useState<HTMLElement|null>(null);
    const projectId = useProjectId();
    const proj = useAsync2((input) => api.projects.get(input.id), {id: projectId}, [projectId]);
    const isQuote = useIsQuote();
    return (
        <div>
            <Button style={{
                textTransform: "none",
                color: "inherit",
                fontSize: "inherit",
                paddingTop: 0,
                paddingBottom: 0,
            }} onClick={e => {
                setAnchor(e.currentTarget);
            }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        {isQuote ? props.quoteTitle : props.title}
                    </Grid>
                    <Grid item>
                        <div style={{fontSize: "1em", height: "1.4em"}}>
                            <ArrowDropDown fontSize="inherit" />
                        </div>
                    </Grid>
                </Grid>
            </Button>
            {anchor && <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorEl={anchor}
                open
                onClose={() => setAnchor(null)}>
                <Loader {...proj}>
                    {(data: Project) => <>
                        <Link to={props.quoteURL} style={{color: "inherit", textDecoration: "none"}}>
                            <MenuItem selected={isQuote} onClick={() => setAnchor(null)}>
                                {props.quoteTitle}
                            </MenuItem>
                        </Link>
                        {data.stage === "in-progress" && <Link to={props.url} style={{color: "inherit", textDecoration: "none"}}>
                            <MenuItem selected={!isQuote} onClick={() => setAnchor(null)}>
                                {props.title}
                            </MenuItem>
                        </Link>}
                    </>}
                </Loader>
            </Popover>}
        </div>
    )
}
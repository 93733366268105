import {SourceMode, ToSourceItem} from "../../../../api/Logistics";
import {
    Column,
    DateCol,
    Nullable,
    NumberCol,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {SimpleIconButton} from "../../../../misc/lists/SimpleIconButton";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import AddCircle from "@mui/icons-material/AddCircle";
import {getAll} from "../../../project/shopdrawing/openings/Openings";
import {ProjectCol, toSourceTableName} from "../PartsToSource";
import {useContext} from "react";
import {SnackContext} from "../../../../misc/Snackbar";
import {CheckboxSelectorCol} from "../../../project/shopdrawing/release/Checkbox";
import {formatDescription} from "./SubmitButton";
import {purchasingPopoutPanel} from "../PurchaseOrderList";
import {InfoIcon} from "./InfoIcon";
import {findValues} from "../FindValues";
import {DistributionMode} from "../DistributionModeToggle";

export function Frame(props: {
    purchaseOrder?: number;
    supplier?: {
        id: number;
        name: string;
    }
    distributionMode: DistributionMode
    sourceMode: SourceMode
}) {
    const ctx = useContext(TableSelectionContext)
    const snack = useContext(SnackContext);
    const usr = useUser();

    const isRestock = props.sourceMode === "stock"

    return (
        <Table<ToSourceItem>
            name={toSourceTableName}
            overridePrefsName={toSourceTableName + ".frame." + props.sourceMode}
            columns={[
                props.purchaseOrder ? CheckboxSelectorCol({
                    disabled: row => !hasPermission(usr, "CanReleaseAndPurchase") && usr?.id === row.releasedBy,
                }) : null,
                !isRestock && ViewOnly(ProjectCol("Project", "projectName", 100)),
                !isRestock && ViewOnly(StringCol("Opening", "openingName", 100)),
                !isRestock && ViewOnly(StringCol("Floor", "openingFloor", 70)),
                ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                ViewOnly({
                    name: "Series",
                    width: 100,
                    render: (dt: ToSourceItem, col: Column<ToSourceItem>) => {
                        return dt.kind === "frame" ? dt.frameSeries : `Anchor: ${dt.productCode}`
                    }
                }),
                ViewOnly(StringCol("Material", "frameMaterial", 70)),
                ViewOnly(StringCol("Gauge", "frameGauge", 70)),
                ViewOnly(StringCol("Screen Elev", "frameScreenElev", 100)),
                ViewOnly(StringCol("Profile", "frameProfile", 70)),
                ViewOnly(StringCol("Jamb Depth", "frameJambDepth", 100)),
                ViewOnly(StringCol("Construction", "frameConstruction", 100)),
                ViewOnly(StringCol("Handing", "frameHanding", 70)),
                ViewOnly(StringCol("Hardware Preps", "frameHardwarePreps", 130)),
                ViewOnly(StringCol("Label", "frameLabel", 100)),
                ViewOnly(StringCol("Type", "frameType", 70)),
                ViewOnly(StringCol("Nom. Width", "dimWidth", 70)),
                ViewOnly(StringCol("Nom. Height", "dimHeight", 70)),
                ViewOnly(NumberCol("Qty", "qty", 60)),
                isRestock && ViewOnly(NumberCol("Stock", "stockQty", 60)),
                isRestock && ViewOnly(NumberCol("Rsrvd", "reservedQty", 60)),
                isRestock && ViewOnly(NumberCol("Pend.PO", "purchasedQty", 90)),
                isRestock && ViewOnly(NumberCol("Stock Min", "stockMinQty", 90)),
                isRestock && ViewOnly(NumberCol("Ideal Qty", "idealQty", 90)),
                {
                    name: "Actions",
                    render: (dt: ToSourceItem, col: Column<ToSourceItem>) => (<div style={{display: "flex"}}>
                        <InfoIcon row={dt} />
                        {props.purchaseOrder && <div>
                            <SimpleIconButton disabled={!hasPermission(usr, "CanReleaseAndPurchase") && usr?.id === dt.releasedBy} onClick={async () => {
                                if(!props.purchaseOrder) return;

                                try {
                                    snack.loading();
                                    const inventoryReqs = dt.inventoryRequestId ? [dt.inventoryRequestId] : [];
                                    if(dt.frameAnchorDetail) {
                                        inventoryReqs.push(...findValues(dt.frameAnchorDetail, d => d.inventoryRequestId));
                                    }

                                    await api.logistics.updatePurchaseOrderItem({
                                        id: 0,
                                        purchaseOrder: props.purchaseOrder,
                                        reference: "",
                                        description: formatDescription(dt),
                                        code: dt.productCode,
                                        qty: dt.qty,
                                        unitPriceCents: dt.lastPrice || dt.lastPriceAtOthers || 0,
                                        inventoryRequests: inventoryReqs,
                                        archived: false,
                                        updatedAt: (new Date()) as any,
                                        kind: "frame",
                                        qtyOutstanding: 0,
                                        createStockingInventoryRequestForProduct: isRestock ? dt.product : undefined,
                                    });

                                    purchasingPopoutPanel.sendToServer("refresh-po");
                                    ctx.refresh(toSourceTableName);
                                    snack.success("Added")
                                } catch(e: any) {
                                    snack.error(e.toString())
                                }
                            }}>
                                <AddCircle />
                            </SimpleIconButton>
                        </div>}
                    </div>),
                    width: 60,
                },
            ]}
            fetch={async (ctx) => {
                const list = await getAll(ctx, offset => api.logistics.listToSourceItems({
                    offset,
                    supplier: props.supplier?.id,
                    type: "frame",
                    sourceMode: props.sourceMode,
                    distributionMode: props.distributionMode,
                }))

                // remove frame anchors from the list and nest them in the frame they belong to
                return list.filter(l => {
                    if(l.kind === "frame-anchor" && l.partOfFrame && l.openingId) {
                        const frame = list.find(s => s.openingId === l.openingId && s.kind === "frame")
                        if(!frame) {
                            console.warn(l);
                            return true;
                        }

                        frame.frameAnchorName = l.productCode;

                        // push items b/c there may be 2 anchor groups for pair door frames.
                        if(!frame.frameAnchorDetail) frame.frameAnchorDetail = [];
                        frame.frameAnchorDetail.push(l);
                        return false;
                    }

                    return true;
                })
            }}
            fetchDeps={[props.supplier, props.sourceMode, props.distributionMode]}
        />
    )
}

export class Calculator {
    raw: string
    tokens: (string|number)[];

    constructor(value: string) {
        this.raw = value;
        this.tokens = this.tokenize();
    }

    tokenize() {
        let lastStartIndex = 0;
        let tokens : (string|number)[] = [];

        for(let i = 0; i < this.raw.length; i++) {
            let c = this.raw[i];
            if(c === " " || c === "$") { // ignore dollars
                if(lastStartIndex < i) {
                    tokens.push(parseFloat(this.raw.substring(lastStartIndex, i)));
                }

                lastStartIndex = i + 1
                continue;
            }

            if(c === "(" || c === ")" || c === "+" || c === "-" || c === "*" || c === "/") {
                if(lastStartIndex < i) {
                    tokens.push(parseFloat(this.raw.substring(lastStartIndex, i)));
                }

                tokens.push(c);
                lastStartIndex = i + 1;
            }
        }

        if(lastStartIndex < this.raw.length) {
            tokens.push(parseFloat(this.raw.substring(lastStartIndex, this.raw.length)));
        }

        if(tokens.some(v => typeof v === "number" && isNaN(v))) {
            throw new Error("Invalid number in expression")
        }

        return tokens;
    }

    calculate() {
        return this._calculate(this.tokens);
    }

    getSubExpression(tokens: (string|number)[], startIndex: number) {
        let depth = 1;
        let subExpression: (string|number)[] = [];

        for(let i = startIndex + 1; i < tokens.length; i++) {
            const tk = tokens[i];
            if(tk === "(") {
                depth++;
            } else if(tk === ")") {
                depth--;
                if(depth === 0) {
                    break
                }
            }

            subExpression.push(tk);
        }

        const skip = subExpression.length + 1
        return {subExpression, skip};
    }

    calculateParenthesis(tokens: (string|number)[]) {
        let list: (string|number)[] = [];

        for (let i = 0; i < tokens.length; i++) {
            const tk = tokens[i];
            if (i === 0) {
                if (typeof tk === "string") {
                    if (tk !== "(" && tk !== "-" && tk !== "+") {
                        throw new Error("Invalid expression")
                    }
                }
            }

            if (tk === "(") {
                const {skip, subExpression} = this.getSubExpression(tokens, i);
                i += skip;
                const result = this._calculate(subExpression);
                list.push(result);
                continue;
            }

            list.push(tk);
        }

        return list;
    }

    calculateMulDiv(tokens: (string|number)[]) {
        let list: (string|number)[] = [];
        for (let i = 0; i < tokens.length; i++) {
            const tk = tokens[i];
            if (i === 0) {
                if (typeof tk === "string") {
                    if (tk !== "(" && tk !== "-" && tk !== "+") {
                        throw new Error("Invalid expression")
                    }
                }
            }

            if (typeof tk === "string") {
                if (tk === "*" || tk === "/") {
                    const last = list.pop();
                    const next = tokens[i + 1];
                    if (typeof last !== "number") throw new Error("invalid expression")
                    if (typeof next !== "number") throw new Error("invalid expression")

                    if (tk === "*") {
                        list.push(last * next);
                    } else {
                        list.push(last / next);
                    }

                    i++;
                    continue
                }
            }

            list.push(tk);
        }

        return list;
    }

    calculateAddition(tokens: (string|number)[]) {
        let result = 0;
        let nextIsNegative = false;

        for(let i = 0; i < tokens.length; i++) {
            const tk = tokens[i];

            if (tk === "-") {
                nextIsNegative = true;
                continue;
            } else if (tk === "+") {
                nextIsNegative = false;
                continue;
            } else if (typeof tk !== "number") {
                throw new Error("Invalid expression")
            }

            result += nextIsNegative ? -tk : tk;
        }

        return result
    }

    _calculate(tokens: (string|number)[]) {

        // 2 + 1 + 3 * 2
        // 2 + 1 + 6
        // 9

        tokens = this.calculateParenthesis(tokens);
        tokens = this.calculateMulDiv(tokens);
        const result = this.calculateAddition(tokens);
        return result;
    }

    static eval(input: string) {
        return new Calculator(input).calculate();
    }
}
import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";

export class ProjectLog extends SubAPI {
    list(input: {
        opening?: number;
        miscProduct?: number;
        offset: number;
    }) {
        return this.fetcher.get<Paginated<ProjectLogItem>>("/api/project-log/list", input)
    }
}

export type ProjectLogItem = {
    id: number;
    createdAt: DateString;
    createdBy: number;
    createdByName: string;
    content: string;
    note?: string;
}
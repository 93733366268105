import {Button, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {grey} from "@mui/material/colors";
import {ucFirst} from "../../manage/summary/SummarySection";
import {first, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useIsQuote} from "../../quote/QuoteContainer";
import {api} from "../../../../api/API";
import {getAll} from "../openings/Openings";
import {useProjectId} from "../ProjectName";
import {useAlternative} from "../../quote/alternative/Alternative";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {PriceSectionNoteDefault} from "./PriceSectionNoteDefault";
import {Div10TypeToName} from "../div10/Div10Tabs";

export function PriceSectionNotes(props: {
    tab: string;
    subTab: string;
}) {
    const [value, setValue] = useState("")
    const isQuote = useIsQuote();
    const project = useProjectId();
    const alternative = useAlternative();

    const shouldUseSubTab = props.tab === "door" && props.subTab === "wd" || props.tab === "div10";

    const tab = shouldUseSubTab ? `${props.tab}/${props.subTab}` : props.tab;
    const tabName = props.tab === "door" && props.subTab === "wd" ? "Wood Doors" :
            props.tab === "div10" ? Div10TypeToName(props.subTab)
                : sectionName(props.tab);

    const get = useAsync2(async (input) => {
        const list = await getAll(null, offset => isQuote ? api.quotePriceSectionNotes.list({offset, ...input}) : api.priceSectionNotes.list({offset, ...input}))
        return first(list, l => l.keyName === input.tab);
    }, {
        project: project,
        alternative: alternative,
        tab: tab,
    }, [project, alternative, tab]);

    const fromServer = get.result;

    useEffect(() => {
        setValue(fromServer?.note || "");
    }, [fromServer])

    const timer = useRef<any>(null);
    const update = useAsyncAction(async (input) => {
        if(isQuote) {
            return api.quotePriceSectionNotes.upsert(input)
        }

        delete input.quoteAlternative;
        return api.priceSectionNotes.upsert(input)
    }, [isQuote])

    const [defaultAnchor, setDefaultAnchor] = useState<HTMLElement | null>(null);
    const textUpdated = (value: string, keyName: string) => {
        setValue(value)

        if(timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            timer.current = null;

            update.callback({
                project: project,
                quoteAlternative: alternative,
                keyName: keyName,
                note: value,
            })
        }, 1000);
    }

    if(tab === "misc") return null;

    return (
        <div style={{flex: 0, padding: 8}}>
            <div style={{fontSize: "0.8rem", color: grey["600"], paddingLeft: 4, paddingBottom: 4}}>
                Customer Price Notes for {tabName}
            </div>
            <div style={{minHeight: 150, position: "relative"}}>
                {get.LoadingOrErrorElement || <TextField multiline onChange={e => {
                    textUpdated(e.target.value, tab)

                }} value={value} rows={5} fullWidth />}
                <div style={{position: 'absolute', zIndex: 100, right: 8, top: 8}}>
                    <Button onClick={e => setDefaultAnchor(e.currentTarget)} endIcon={<ArrowDropDown />}>Add Default</Button>
                    {defaultAnchor && <PriceSectionNoteDefault keyName={tab} anchor={defaultAnchor}
                        onClose={(toAdd: string[]) => {
                            if(toAdd.length > 0) {
                                textUpdated((value + "\n" + toAdd.join("\n")).trim(), tab)
                            }

                            setDefaultAnchor(null);
                        }} />}
                </div>
            </div>

            {update.error ? update.LoadingElement : null}
        </div>
    )
}

export function sectionName(name: string) {
    switch (name) {
        case "summary": return "General";
        case "door": return "Doors";
        case "frame": return "Frames";
        case "hardware": return "Finishing Hardware";
        default:
            return ucFirst(name);
    }
}
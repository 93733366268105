import {stopName, useRouteInfo} from "./RouteDetail";
import {
    Alert,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton,
} from "@mui/material";
import {Loader} from "../../../misc/Loader";
import {MobileContainer} from "../../../misc/Container";
import {Link, useNavigate, useParams} from "react-router-dom";
import { css } from "@emotion/css";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import { StopHeader } from "./StopHeader";
import {rowStyle} from "./RouteList";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {CheckCircle} from "@mui/icons-material";
import {green} from "@mui/material/colors";
import {useState} from "react";
import {AddPODialog} from "./AddPODialog";


export function RouteStop() {
    const {
        stops,
        route,
        id
    } = useRouteInfo();
    const nav = useNavigate();
    const params = useParams<{stopId: string}>();
    const stopId = parseInt(params.stopId || "0");
    const stop = stops.asList.find(v => v.id === stopId);
    const groups = stop?.groups || []
    const hasPending = !!groups.find(v => !v.completed)
    const stopIndex = stops.asList.findIndex(v => v.id === stopId);
    const prevStop = stopIndex === 0 ? null : stopIndex - 1;
    const showFinish = stopIndex === stops.asList.length - 1;
    const [showConfirmNext, setShowConfirmNext] = useState(false);
    const nextStopId = stopIndex === stops.asList.length - 1 ? null : stops.asList[stopIndex + 1].id;
    const routeObj = route.result;

    const next = useAsyncAction(async () => {
        if(!nextStopId) {
            if(!routeObj) throw new Error("missing route obj");
            await api.logistics.updateRoute(Object.assign({}, routeObj, {
                completed: true,
            }))

            nav(`/logistics/driver/success`)
            return;
        }

        nav(`/logistics/driver/${id}/${nextStopId}`)
    }, [nextStopId, routeObj]);

    const [showAddPO, setShowAddPO] = useState(false);

    return (
        <MobileContainer topCenter={route.result ? "#" + route.result.id : "Loading.."} back={`/logistics/driver/${id}`}>
            <div style={{flex: 1, overflow: "auto"}}>
                <Loader {...stops} />
                <Loader {...next} />
                <Loader {...route}>
                    {() => {
                        if(!stop) {
                            return (
                                <div style={{padding: 8}}><Alert>Invalid URL, please go back and try again</Alert></div>
                            )
                        }

                        return <div style={{padding: 4}}>
                            <StopHeader>
                                {stopName(stop)}
                            </StopHeader>
                            <div style={{height: 4}} />
                            <div className={cardStyle} style={{flex:1, overflow: "auto", padding: 0}}>
                                {groups.map(c => {

                                    return (
                                        <Link to={`/logistics/driver/${id}/${stopId}/${c.id}`} style={{textDecoration: "none", color: "inherit"}}>
                                            <div className={rowStyle} style={{display: "flex"}}>
                                                <div style={{flex: 1}}>
                                                    {c.pickupPoNumber && `Pickup PO #${c.pickupPoNumber}`}
                                                    {c.delivery && `Delivery #${c.delivery} (${c.deliveryProjectName})`}
                                                    {c.dropoffPoNumber && `Drop off PO #${c.dropoffPoNumber}`}
                                                </div>
                                                <div>
                                                    {c.completed && <IconButton style={{color: green["700"]}}><CheckCircle /></IconButton>}
                                                    {!c.completed && <IconButton><ChevronRight /></IconButton>}
                                                </div>
                                            </div>
                                        </Link>
                                    )

                                })}
                            </div>
                        </div>
                    }}
                </Loader>
            </div>
            <div style={{padding: 8}}>
                <Grid container spacing={1}>
                    {stop?.manufacturer && <Grid item xs={12}>
                        <Button fullWidth variant="contained" color="secondary"
                                onClick={() => {
                                    setShowAddPO(true)
                                }}>
                            Add Purchase Order
                        </Button>
                    </Grid>}
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" disabled={prevStop === null} color="secondary"
                                onClick={() => {
                                    nav(`/logistics/driver/${id}/${prevStop}`)
                                }}>
                            Prev Stop
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={hasPending ? "warning" as "warning" :
                                "secondary" as "secondary"}
                            onClick={() => {
                                if(hasPending) {
                                    setShowConfirmNext(true);
                                    return;
                                }

                                next.callback({});
                            }}
                        >
                            {showFinish ? "Finish" : "Next Stop"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {showAddPO && stop && routeObj &&  <AddPODialog route={routeObj} stop={stop} onClose={() => {
                setShowAddPO(false);
            }} />}
            {showConfirmNext && <Dialog open>
                <DialogTitle>Do you want to skip part of this stop?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are still uncompleted pickups/deliveries on this stop.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="warning" onClick={() => {
                                setShowConfirmNext(false)
                                next.callback({});
                            }}>Ignore Stop</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth variant="contained" color="secondary" onClick={() => setShowConfirmNext(false)}>Go Back</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>}
        </MobileContainer>
    )
}

export const cardStyle = css({
    padding: 8,
    backgroundColor: "white",
    // border: "1px solid " + grey["300"],
    // borderRadius: 4,
})
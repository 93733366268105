import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {DebugPrintFx, PDFGenContext, PDFGenProvider, PrintFx} from "./PDFGenerator";
import {api} from "../../api/API";
import {EventEmitter, sleep} from "nate-react-api-helpers";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid, LinearProgress, Typography
} from "@mui/material";
import {DebugPDF} from "./DebugPDF";
import { PrintArgs } from "../../api/Print";
import {useSyncedRef} from "../SyncedRef";
import {SubmissionDate} from "./SubmissionDate";
import {makeConfigurator} from "../../pages/project/shopdrawing/changeset/PrintParallel";

type ContextType = {
    print: (name: string, render: PrintFx, serverInfo?: PrintArgs) => Promise<void>;
    print2: (input: PDFInfo) => Promise<void>;
    debug: (name: string, render: DebugPrintFx, serverInfo?: PrintArgs) => Promise<void>;
    debug2: (input: PDFInfo) => Promise<void>;
}

export type PDFInfo = {
    name: string;
    render: PrintFx;
    serverInfo: PrintArgs;
}

const defaultFx = () => {};

export const PrintManagerContext = createContext<ContextType>({
    print: defaultFx as any,
    print2: defaultFx as any,
    debug: defaultFx as any,
    debug2: defaultFx as any,
})

type InfoState = {
    status: string;
    error: boolean;
    show: boolean;
    done: boolean;
    submission?: number;
    ignoreSubmissionDate?: boolean;
}

export const printStatus = new EventEmitter<Partial<InfoState>>();

export function PrintManagerProvider(props: PropsWithChildren<{}>) {

    return (
        <PDFGenProvider>
            <PrintManagerProviderInner>
                {props.children}
            </PrintManagerProviderInner>
        </PDFGenProvider>
    )
}

const debugEmitter = new EventEmitter<{
    render: DebugPrintFx
}>();

function PrintManagerProviderInner(props: PropsWithChildren<{}>) {
    const printer = useContext(PDFGenContext);
    const print = useSyncedRef(printer.print);

    const print1 = useCallback(async (name: string, render: PrintFx, serverInfo?: PrintArgs) => {
        try {
            printStatus.emit({
                status: "Rendering...",
                show: true,
                error: false,
                done: false,
            })

            const content = await print.current(render, makeConfigurator(serverInfo || {}, v => {
                serverInfo = v;
            }));

            printStatus.emit({ status: "Packaging..."})
            const info = await api.print.print({
                content: content,
                name: name,
                args: serverInfo ? JSON.stringify(serverInfo) : "",
            })

            while(true) {
                await sleep(500);
                const st = await api.print.getStatus({uuid: info.uuid});
                printStatus.emit({ status: st.status })

                if(st.failed) {
                    printStatus.emit({ error: true, done: true })
                    return;
                }

                if(st.ready) {
                    api.print.download({uuid: info.uuid});
                    printStatus.emit({
                        done: true,
                        show: false,
                        submission: st.submission,
                        ignoreSubmissionDate: serverInfo && !!serverInfo.ignoreSubmissionDate || false,
                    })
                    return
                }
            }
        } catch (e: any) {
            console.error(e);
            printStatus.emit({
                status: e.toString(),
                show: true,
                error: true,
                done: true,
            })
        }
    }, [print]);

    const ctx = useMemo(() => ({
        debug: async (name: string, render: DebugPrintFx, serverInfo?: PrintArgs) => {
            debugEmitter.emit({render: render})
        },
        print2: async (input: PDFInfo) => {
            return print1(input.name, input.render, input.serverInfo);
        },
        print: print1,
        debug2: async (input: PDFInfo) => {
            debugEmitter.emit({render: input.render})
        },
    }), [print1]);

    return (
        <PrintManagerContext.Provider value={ctx}>
            {props.children}
            <PrintManagerStatus />
            <PrintDebugManager />
            <SubmissionDate />
        </PrintManagerContext.Provider>
    )
}

function PrintDebugManager(props: {}) {
    const [debug, setDebug] = useState<{render: DebugPrintFx}>();

    useEffect(() => {
        const sub = debugEmitter.subscribe(value => {
            setDebug(value);
        })

        return () => sub.cancel();
    }, []);

    if(!debug) return null;
    const render = debug.render

    return (
        <DebugPDF render={v => render(v, makeConfigurator({}, () => {}))} onClose={() => setDebug(undefined)} />
    )
}


export function PrintManagerStatus(props: {}) {

    const [state, setState] = useState<InfoState>({
        status: "",
        done: false,
        show: false,
        error: false,
    });

    useEffect(() => {
        const sub = printStatus.subscribe(update => {
            console.log(update);
            setState(old => Object.assign({}, old, update));
        })

        return () => sub.cancel();
    }, []);

    const update = useCallback((update: Partial<InfoState>) => {
        setState(old => Object.assign({}, old, update));
    }, []);

    if(!state.show) return null;

    const complete = state.error || state.done;

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <DialogTitle>
                {state.error ? "Print Failed" : "Printing"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={1} flexDirection="column">
                        {!complete ? <Grid item><LinearProgress variant="indeterminate" /></Grid> : null}
                        <Grid item>
                            <Typography variant="body1" color="initial">
                                {state.status}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => update({show: false})}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
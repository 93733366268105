import clsx from "clsx";
import {ProjectDir, ProjectFile} from "../../../../api/ProjectFiles";
import {Icon} from "./Icon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, {useContext, useRef, useState} from "react";
import {css} from "@emotion/css";
import {blue, red} from "@mui/material/colors";
import {isProjectFile, Rename} from "./Rename";
import {api} from "../../../../api/API";
import {useSnackbar} from "../../../../misc/Snackbar";
import {Divider, Menu, MenuItem} from "@mui/material";
import {ConfirmContext} from "../../../../misc/Confirm";
import {archive} from "./FileDetail";

export function FileItem(props: {
    value: ProjectDir|ProjectFile;
    selected: ProjectDir[];
    selectedFile?: ProjectFile|null;
    onDirSelect(value: ProjectDir): void;
    onFileSelect(value: ProjectFile): void;
    onChange(nextId?: number): void;
}) {
    const value = props.value;
    const [dragging, setDragging] = useState(false);
    const snack = useSnackbar();
    const [showContext, setShowContext] = useState<{top: number; left: number}|null>(null);
    const anchorRef = useRef<HTMLDivElement|null>(null);
    const confirm = useContext(ConfirmContext);
    const [showRename, setShowRename] = useState(false);

    return (
        <>
            <div
                className={clsx({
                    [fileClass]: true,

                    [fileSelectedClass]:
                        (!isProjectFile(value) && props.selected.indexOf(value) !== -1) ||
                        (props.selectedFile === value) ||
                        dragging
                })}
                 onClick={() => {
                     if(isProjectFile(value)) {
                         props.onFileSelect(value)
                     } else {
                         props.onDirSelect(value)
                     }
                 }}
                 onDoubleClick={() => {
                     if(isProjectFile(props.value)) {
                         window.open(api.projectFiles.downloadLink(props.value.id, "inline"), "_blank")
                     }
                 }}
                 draggable={true}
                 onDragStart={e => {
                     e.dataTransfer.setData("application/json", JSON.stringify(value))
                 }}
                 onDragEnter={e => {
                    if(isProjectFile(value)) return;
                     if(dataTransferIsFile(e)) return; // leave for column

                    e.preventDefault()
                    e.stopPropagation()
                    setDragging(true);
                    return true;
                 }}
                 onDragOver={e => {
                    if(isProjectFile(value)) return;
                     if(dataTransferIsFile(e)) return; // leave for column

                    setDragging(true);
                    e.preventDefault()
                    e.stopPropagation()
                    return true;
                 }}
                 onDragLeave={e => {
                     e.stopPropagation()

                    if(isProjectFile(value)) return;
                    if(dataTransferIsFile(e)) return;

                    e.preventDefault()
                    setDragging(false);
                    return true;
                 }}
                onDrop={async (e) => {
                    if(dataTransferIsFile(e)) return;

                    e.stopPropagation()
                    e.preventDefault()

                    setDragging(false);

                    try {
                        snack.loading("Moving...");

                        const value = e.dataTransfer.getData("application/json");
                        const obj = JSON.parse(value) as ProjectFile | ProjectDir;
                        if(obj.id === props.value.id) {
                            snack.hide();
                            return;
                        }

                        if(isProjectFile(obj)) {
                            if(!isProjectFile(props.value)) {
                                await api.projectFiles.upsert(Object.assign(obj, {
                                    directory: props.value.id,
                                }))
                            }
                        } else {
                            if(!isProjectFile(props.value)) {
                                await api.projectFiles.upsertDir(Object.assign(obj, {
                                    parent: props.value.id,
                                }))
                            }
                        }

                        snack.success("Updated")
                    } catch (e: any) {
                        snack.error(e.toString())
                    }

                    props.onChange(props.value.id);
                    return true;
                }}
                onContextMenu={e => {
                    e.preventDefault();
                    setShowContext({
                        top: e.clientY,
                        left: e.clientX,
                    })
                }}
                ref={anchorRef}
            >
                <Icon value={props.value} />
                <div style={{width: 4}} />
                <div style={{flex: 1}}>{value.name}</div>
                {!isProjectFile(value) && <div style={{height: "1.2em", overflow: "hidden"}}>
                    <ChevronRightIcon fontSize="inherit" />
                </div>}
                {showContext && <Menu onClose={() => setShowContext(null)} open={true}
                                      anchorReference="anchorPosition"
                                      anchorPosition={showContext}>
                    <MenuItem>
                        <div>
                            <div>Editing {props.value.name}</div>
                            <div style={{fontSize: "0.8rem"}}>Click the file to view more details in the right panel</div>
                        </div>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        setShowRename(true);
                        setShowContext(null)
                    }}>Rename</MenuItem>
                    <MenuItem
                        onClick={() => {
                            archive({
                                confirm: confirm.confirm,
                                file: props.value,
                                snack: snack,
                                onChange: props.onChange,
                            })
                            setShowContext(null)
                        }}
                        style={{color: red["700"]}}>Archive</MenuItem>
                </Menu> }
            </div>

            {showRename && <Rename value={props.value} onCancel={
                () => setShowRename(false)
            } onDone={() => {
                setShowRename(false)
                props.onChange(props.value.id);
            }} />}
        </>
    )
}

const fileClass = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    fontSize: "0.9rem",
    cursor: "pointer",
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 4,
})

const fileSelectedClass = css({
    color: "white",
    fontWeight: "500",
    backgroundColor: blue["800"],
})

function dataTransferIsFile(e: any) {
    const items = Array.from(e.dataTransfer.items) as DataTransferItem[];
    if(items.length === 0) return false;
    if(items[0].kind === "file") return true;
    return false;
}
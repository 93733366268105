import {distinct, groupByArr, useAsyncAction} from "nate-react-api-helpers";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import React, {useMemo, useRef} from "react";
import {CanvasRef, PrepCanvas} from "./PrepCanvas";
import {PrepControls} from "./PrepControls";
import {PrepCanvasManager, PrepValues} from "./PrepCanvasManager";
import {ImperialDistance} from "../../../../../misc/ImperialDistance";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {HardwareGroupWithContent} from "../../../../../api/Hardware";
import {PrepDetail} from "../../../../../api/QuoteHardware";
import {dbPrep, dbStrikePrep} from "../../extras/PrepMenuOptions";

export function usePrepManager(props: {
    value: HardwareGroupWithContent;
}) {
    const {heights, inactiveWidths, widths, pair, overrideHingeQty} = useMemo(() => {
        const heights = distinct(props.value.openings.map(o => o.nominalHeight));
        const widths = distinct(props.value.openings.map(o => o.nominalWidth));
        const inactiveWidths = distinct(props.value.openings.map(o => o.inactiveDoorWidth));
        const pair = distinct(props.value.openings.map(o => o.openingType === "Pair"));

        const hingeOverrideItem = props.value.hardware.find(h => h.overrideHingeActiveQty || h.overrideHingeInActiveQty);
        let overrideHingeQty = 0;
        if(hingeOverrideItem) {
            overrideHingeQty = (hingeOverrideItem.overrideHingeActiveQty || hingeOverrideItem.overrideHingeInActiveQty) || 0
        }

        return {heights, widths, inactiveWidths, pair, overrideHingeQty}
    }, [props.value]);

    const manager = useMemo(() => {
        let height = heights.find(v => !!v);
        if(!height) height = "8'";

        let widthStr = widths.find(v => !!v);
        if(!widthStr) widthStr = "4'";

        let inactiveDoorWidth = inactiveWidths.find(v => !!v) || "3'";

        const config = convertPrepConfig(props.value.prepConfig)
        const isPair = pair[0];

        return new PrepCanvasManager({
            nominalHeight: ImperialDistance.parse(height).universalValue(),
            nominalWidth: ImperialDistance.parse(widthStr).universalValue(),
            overrides: config,
            secondaryWidth: isPair ? ImperialDistance.parse(inactiveDoorWidth).universalValue() : undefined,
            pair: isPair,
            overrideHingeQty: overrideHingeQty,
            hasDeadbolt: props.value.hardware.some(h => {
                if(h.hwPrepActiveDoor === dbPrep || h.hwPrepActiveDoor === dbStrikePrep) return true;
                if(h.hwPrepInactiveDoor === dbPrep || h.hwPrepInactiveDoor === dbStrikePrep) return true;
                if(h.hwPrepFrame === dbPrep || h.hwPrepFrame === dbStrikePrep) return true;
                return false;
            })
        });
    }, [heights, widths, inactiveWidths, props.value, pair, overrideHingeQty])

    return {
        heights, widths, inactiveWidths, manager, overrideHingeQty
    }
}

export function convertPrepConfig(prepConfig: PrepDetail[]|undefined) {
    let config: Partial<PrepValues>|undefined = undefined;

    if(prepConfig) {
        config = {};
        for(const prep of prepConfig) {
            // @ts-ignore
            config[prep.name] = {
                enabled: prep.enabled,
                value: prep.value ? ImperialDistance.parse(prep.value).universalValue() : null
            }
        }
    }

    return config;
}

export function PrepManager(props: {
    value: HardwareGroupWithContent;
    onCancel(): void;
    onDone(preps: PrepDetail[]): Promise<void>;
}) {
    const {heights, manager} = usePrepManager({
        value: props.value
    });

    const onDoneRef = useSyncedRef(props.onDone)
    const canvasRef = useRef<null|CanvasRef>(null);

    const save = useAsyncAction(async (input: PrepDetail[]) => {
        await onDoneRef.current(input)
    }, []);

    const groupByType = groupByArr(props.value.openings, v => v.openingType)

    if(groupByType.length > 1) {
        return (
            <Dialog open>
                <DialogTitle>Customize Hardware Preps</DialogTitle>
                <DialogContent>
                    <Alert severity="error">
                        You have different opening types on the doors in this group. This will cause issues with the diagrams.
                        {groupByType.map(v =>
                            <div key={v.key}>{v.key + ": " + v.values.map(v => v.name).join(", ")}</div>
                        )}
                    </Alert>
                </DialogContent>
                <DialogContent>
                    <Button onClick={props.onCancel}>Close</Button>
                </DialogContent>
            </Dialog>
        );
    }

    if(heights.length > 1) {
        return (
            <Dialog open>
                <DialogTitle>Customize Hardware Preps</DialogTitle>
                <DialogContent>
                    <Alert severity="error">
                        You have different heights on the doors in this group. This will cause issues with the diagrams.
                        {props.value.openings.map(o => o.name + ": " + o.nominalHeight).join(", ")}
                    </Alert>
                </DialogContent>
                <DialogContent>
                    <Button onClick={props.onCancel}>Close</Button>
                </DialogContent>
            </Dialog>
        );
    }

    if(heights.filter(v => !!v).length === 0) {
        return (
            <Dialog open>
                <DialogTitle>Customize Hardware Preps</DialogTitle>
                <DialogContent>
                    <Alert severity="error">
                        You're missing nominal heights or doors in this group.
                        Found: {props.value.openings.map(o => o.name + ": " + (o.nominalHeight || "missing height")).join(", ")}
                    </Alert>
                </DialogContent>
                <DialogContent>
                    <Button onClick={props.onCancel}>Close</Button>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog open fullWidth maxWidth="lg">
            <DialogTitle>Customize Hardware Preps</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <PrepCanvas canvasRef={canvasRef} manager={manager} />
                    </Grid>
                    <Grid item>
                        <PrepControls manager={manager} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {save.LoadingElement}
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button onClick={() => {
                    save.callback(manager.options().map(o => ({
                        value: o.value.value ? ImperialDistance.showInches(o.value.value) : null,
                        name: o.name,
                        enabled: o.value.enabled
                    })))
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import FilterList from "@mui/icons-material/FilterList"
import { useState } from "react";
import {Select2Popout} from "../../../misc/Select2";
import {api} from "../../../api/API";
import {Option} from "../../../misc/scroller/Table";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import {Button} from "@mui/material";

export function ProjectFilter(props: {
    value: Option | null;
    onChange(value: Option|null): void;
}) {
    const [anchor, setAnchor] = useState<any>(null);
    const [open, setOpen] = useState(false);

    return (
        <>
            <SmallGreyButton ref={setAnchor} onClick={() => setOpen(true)} startIcon={<FilterList />}>
                {props.value ? props.value.display : "For Project"}
            </SmallGreyButton>
            <Select2Popout open={open} onClear={() => props.onChange(null)} lookup={async (search) => {
                const list = await api.projects.list({search, offset: 0});
                return list.data.map(l => ({
                    display: l.name,
                    value: l.id,
                }))
            }} onChange={props.onChange} onClose={() => setOpen(false)} initialValue={""} anchor={anchor} />
        </>
    )
}

export function ProjectChooser(props: {
    value: Option | null;
    onChange(value: Option|null): void;
}) {
    const [anchor, setAnchor] = useState<any>(null);
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button variant="outlined" ref={setAnchor} color="inherit" onClick={() => setOpen(true)} endIcon={<ArrowDropDown />}>
                {props.value ? props.value.display : "For Project"}
            </Button>
            {open && <ProjectSelectPopover anchor={anchor} onChange={props.onChange} onClose={() => setOpen(false)} />}
        </>
    )
}

export function ProjectSelectPopover(props: {
    anchor: any;
    onChange(value: Option | null): void;
    onClose(): void;
}) {
    return (
        <Select2Popout open onClear={() => props.onChange(null)} lookup={async (search) => {
            const list = await api.projects.list({search, offset: 0});
            return list.data.map(l => ({
                display: l.name,
                value: l.id,
            }))
        }} onChange={props.onChange} onClose={props.onClose} initialValue={""} anchor={props.anchor} />
    )
}

export function ProjectChooserSmall(props: {
    title: string;
    value: Option | null;
    onChange(value: Option|null): void;
}) {
    const [anchor, setAnchor] = useState<any>(null);
    const [open, setOpen] = useState(false);

    return (
        <>
            <SmallGreyButton endIcon={<ArrowDropDown />}
                    ref={setAnchor}
                    onClick={() => setOpen(true)}>
                {props.value ? props.value.display : props.title}
            </SmallGreyButton>
            {open && <ProjectSelectPopover anchor={anchor} onChange={props.onChange} onClose={() => setOpen(false)} />}
        </>
    )
}

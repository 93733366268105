import {EventEmitter} from "nate-react-api-helpers";
import {useEffect, useState} from "react";

export function useEventEmitter<T>(value?: EventEmitter<T>) {
    const [state, setState] = useState(value ? value.lastValue : undefined);

    useEffect(() => {
        if(!value) {
            setState(undefined);
            return;
        }

        setState(value.lastValue);
        const sub = value.subscribe(setState);
        return () => sub.cancel()
    }, [value]);

    return state;
}
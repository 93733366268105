import {createContext, PropsWithChildren, useMemo} from "react";

export const DoorSectionContext = createContext({
    tab: "<invalid context>",
})

export function DoorSectionProvider(props: PropsWithChildren<{
    value: string;
}>) {
    const ctx = useMemo(() => ({tab: props.value}), [props.value]);
    return <DoorSectionContext.Provider value={ctx}>
        {props.children}
    </DoorSectionContext.Provider>
}
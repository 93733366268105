import {styled} from "@mui/material";
import {yellow} from "@mui/material/colors";
import {Link, useParams} from "react-router-dom";
import React, {useContext} from "react";
import {useAlternative} from "../quote/alternative/Alternative";
import {ChangePreviewContext} from "./ShopDrawingChangePreview";

export const Outer = styled("div")(() => ({
    border: "1px solid hsla(0, 100%, 100%, 0.6)",
    borderRadius: 4,
    backgroundColor: "hsla(0, 100%, 100%, 0.4)",
    color: "white",
    display: "flex",
    position: "relative",
}))

export const Element = styled("div")<{
    selected?: boolean
}>(props => ({
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 5,
    fontSize: "0.7rem",
    textTransform: "uppercase",
    fontWeight: 500,

    color: props.selected ? yellow["600"] : undefined,
    borderBottom: props.selected ? "2px solid " + yellow["600"] : "2px solid transparent",
    marginBottom: -1,
    cursor: "pointer",

    "&:hover": {
        color: yellow["700"],
    },

    "&:first-of-type": {
        paddingLeft: 20,
    },
    "&:last-of-type": {
        paddingRight: 20,
    }
}))

export const Workspace = styled("div")(() => ({
    position: "absolute",
    fontSize: "0.7rem",
    top: -8,
    left: 14,
    zIndex: 1,
    paddingLeft: 2,
    paddingRight: 2,
}))

export const WorkspaceNotch = styled("div")(props => ({
    position: "absolute",
    width: "100%",
    left: 0,
    top: 7,
    height: 1,
    backgroundColor: props.theme.palette.primary.dark,
    zIndex: -1,
}))

export const ShopNav = React.memo(function ShopNav() {
    const ctx = useContext(ChangePreviewContext);
    if(ctx.enabled) {
        return (
            <Outer>
                <div style={{paddingLeft: 32, paddingRight: 32, paddingTop: 4, paddingBottom: 4}}>
                    Changeset Preview
                </div>
                <NavItem name="Openings" isIndex />
                <NavItem name="Keying" />
                <NavItem name="Hardware" />
                <NavItem name="Div10" />
                <NavItem name="Extras" />
                <NavItem name="Pricing" />
            </Outer>
        )
    }

    return <Outer>
        <Workspace>
            Workspace
            <WorkspaceNotch />
        </Workspace>
        <NavItem name="Openings" isIndex />
        <NavItem name="Hardware" />
        <NavItem name="Keying" />
        <NavItem name="Div10" />
        <NavItem name="Misc" />
        <NavItem name="Extras" />
        <NavItem name="Pricing" />
        <NavItem name="Release" />
        <NavItem name="Logistics" />
    </Outer>
});

export function NavItem(props: {
    name: string;
    isIndex?: boolean;
    quote?: boolean;
}) {
    const params = useParams<{workspace: string, project: string}>();
    const routeKey = props.name.toLowerCase().replace(" ", "-");
    const alt = useAlternative();
    const previewChangeset = useContext(ChangePreviewContext);

    let to = `/project/${params.project}/shop-drawing/${routeKey}`
    if(props.quote) {
        to = `/project/${params.project}/quote/${alt}/${routeKey}`
    } else if(previewChangeset.enabled) {
        to = `/project/${params.project}/shop-drawing/changeset/${previewChangeset.sessionId}/preview/${routeKey}`
    }

    return <Link style={{textDecoration: "none", color: "inherit"}} to={to}>
        <Element selected={params.workspace === routeKey || (!params.workspace  && props.isIndex)}>
            {props.name}
        </Element>
    </Link>
}
import {IconButton, InputAdornment, TextField} from "@mui/material";
import moment, {Moment} from "moment";
import {useEffect, useState} from "react";
import {DateString} from "../api/Projects";
import Clear from "@mui/icons-material/Clear";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const defaultFormat = "MMM D, YYYY";

export function DateField(props: {
    nullable?: boolean;
    initialValue: DateString | null;
    format?: string
    label: string;
    fullWidth?: boolean
    required?: boolean
    onChange(value: DateString | null): void;
    size?: any;
    disabled?: boolean;
}) {
    const [anchor, setAnchor] = useState<HTMLDivElement|null>(null)
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<Moment|null>(null)

    useEffect(() => {
        setValue(props.initialValue ? moment(props.initialValue) : null);
    }, [props.initialValue]);

    return (
        <div ref={setAnchor}>
            <TextField
                fullWidth={props.fullWidth}
                size={props.size || "small"}
                label={props.label}
                disabled={props.disabled}
                required={props.required}
                value={value?.format(props.format || defaultFormat) || ""}
                onClick={() => {
                    if(props.disabled) return;
                    setOpen(true)
                }}
                InputProps={{
                    endAdornment: props.nullable ? <InputAdornment position="end">
                        <IconButton disabled={props.disabled} onClick={e => {
                            e.stopPropagation();
                            setValue(null);
                            props.onChange(null);
                        }}>
                            <Clear />
                        </IconButton>
                    </InputAdornment> : undefined,
                }}
            />
            {anchor && open && <DatePicker
                open={true}
                value={value}
                PopperProps={{
                    anchorEl: anchor,
                }}
                onClose={() => setOpen(false)}
                onChange={value => {
                    if(!value) {
                        setOpen(false);
                        return;
                    }

                    setOpen(false);
                    setValue(value);
                    props.onChange((value as Moment).toJSON());
                }}
                renderInput={props => {
                    return <div ref={props.ref}></div>
                }} />}
        </div>
    )
}
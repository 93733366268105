import {createContext, useEffect, useMemo, useState} from "react";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useSnackbar} from "../../../../misc/Snackbar";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {ProductEdit} from "../extras/ProductEdit";
import {useReloader} from "./ReloadContext";
import {CategoryKind} from "../extras/CreateProduct";

export const EditProductContext = createContext({
    show: (id: number, dimensionScreen?: boolean) => {},
})

export function EditProduct(props: {
    children: any;
    mode: CategoryKind
}) {
    const [edit, setEdit] = useState<{id: number, dimensionScreen: boolean}>();

    const ctx = useMemo(() => {
        return {
            show: (id: number, dimensionScreen?: boolean) => setEdit({
                id: id,
                dimensionScreen: !!dimensionScreen,
            })
        }
    }, []);

    const reloaderRef = useReloader();

    return (
        <EditProductContext.Provider value={ctx}>
            {props.children}
            {edit && <EditProductDialog id={edit.id} mode={props.mode} dimensionScreen={edit.dimensionScreen} onClose={(value) => {
                setEdit(undefined)

                if(value) {
                    reloaderRef.current();
                }
            }} />}
        </EditProductContext.Provider>
    )
}

function EditProductDialog(props: {
    id: number;
    dimensionScreen: boolean;
    mode: CategoryKind;
    onClose(value?: any): void;
}) {

    const result = useAsync2(input => api.products.search(input), {id: props.id, productSearch: ""}, [props.id]);
    const snackRef = useSyncedRef(useSnackbar());

    useEffect(() => {
        if(result.loading) {
            snackRef.current.loading();
            return;
        }

        if(result.error) {
            snackRef.current.error(result.error);
            return;
        }

        snackRef.current.hide();
    }, [snackRef, result.loading, result.error]);

    if(!result.result) return null;
    const val = result.result.data[0];
    const hasDims = !!(val.dimLengthFormula||val.dimHeightFormula||val.dimHeightFormula)
    return <ProductEdit mode={props.mode} value={val} skipToDimensionScreen={props.dimensionScreen && hasDims} onClose={props.onClose}  />
}
import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import React, {useState} from "react";

export function UnitSystemEdit(props: {
    value: Project;
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async (input: Project) => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const [value, setValue] = useState(props.value.unitSystem)

    return (
        <Dialog open>
            <DialogTitle>Unit System</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <Select value={value} size="small"  onChange={(e, value) => setValue(e.target.value as any)}>
                    <MenuItem value="imperial">Imperial</MenuItem>
                    <MenuItem value="metric">Metric</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        unitSystem: value,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
import {Container} from "../../../misc/Container";
import {SectionTitle} from "../../../misc/SectionTitle";
import React from "react";
import {api} from "../../../api/API";
import {NumberCol, StringCol, Table, ViewOnly} from "../../../misc/scroller/Table";
import {TabSection} from "./Tabs";
import {Catalog} from "../../../api/ProductCatalogs";
import {CreateCatalog} from "./CreateCatalog";

export const catalogTable = "product-catalog"

export function Catalogs(props: {
    tab: string;
    onTabChange(value: string): void;
}) {

    return <Container>
        <div style={{
            backgroundColor: "white",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
        }}>
            <div style={{padding: 16}}>
                <SectionTitle>
                    Product Settings
                </SectionTitle>
            </div>
            <TabSection value={props.tab} onChange={props.onTabChange} />

            <Table<Catalog>
                name={catalogTable}
                key={catalogTable}
                fetch={ctx => api.productCatalogs.list({})}
                columns={[
                    ViewOnly(StringCol("Manufacturer", "manufacturerName")),
                    StringCol("Name", "name", 200),
                    ViewOnly(NumberCol("Product Count", "productCount")),
                ]}
                fetchDeps={[]}
                onChange={input => api.productCatalogs.upsert(input)}
                insert={{
                    modal: (onDone) => <CreateCatalog onDone={onDone} />,
                }}
            />
        </div>
    </Container>
}
import {createContext, useCallback, useContext, useEffect, useMemo} from "react";
import {EventEmitter} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";

export const ReloadContext = createContext({
    triggerReload() {
        console.error('invalid context')
    },
    emitter: new EventEmitter(),
});

export function ReloadContextProvider(props: {children: any}) {
    const emit = useMemo(() => new EventEmitter(), []);

    const ctx = useMemo(() => {
        return {
            triggerReload: () => emit.emit(true),
            emitter: emit,
        }
    }, [emit]);

    return (
        <ReloadContext.Provider value={ctx}>
            {props.children}
        </ReloadContext.Provider>
    )
}

export function useReload(cb: () => void, cbDeps: any[]) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(cb, cbDeps);

    const ctx = useContext(ReloadContext);
    useEffect(() => {
        const sub = ctx.emitter.subscribe(callback)
        return () => sub.cancel();
    }, [ctx.emitter, callback])
}

export function useReloader() {
    const ctx = useContext(ReloadContext);
    return useSyncedRef(ctx.triggerReload)
}
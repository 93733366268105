import {Container} from "../../../misc/Container";
import {useNavigate} from "react-router-dom";
import {useSnackAction} from "../../project/shopdrawing/hardware/SnackAction";
import {api} from "../../../api/API";
import {WhiteColumn} from "../purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {Grid} from "@mui/material";
import {CentsCol, DateCol, NumberCol, StringCol, Table, ViewOnly} from "../../../misc/scroller/Table";
import {StockCount} from "../../../api/Logistics";
import {Checkbox2Col} from "../../project/shopdrawing/ShopTableCol";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import React from "react";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import GradingIcon from "@mui/icons-material/Grading";

export function StockCounts() {
    return (
        <Container>
            <StockCountsInner />
        </Container>
    )
}

function StockCountsInner() {
    const nav = useNavigate()
    const startStockCount = useSnackAction(async () => {
        const stockCount = await api.logistics.startStockCount({})
        nav(`/logistics/stock-count/do/${stockCount.id}`)
    }, {
        success: "Stock count started",
    })

    return (
        <WhiteColumn>
            <SectionHeader
                name="Stock Counts"
                right={<Grid container spacing={1}>
                    <Grid item><SmallGreyButton startIcon={<GradingIcon />} onClick={() => {
                        startStockCount.callback({});
                    }}>Start Stock Count</SmallGreyButton></Grid>
                </Grid>}
            />
            <Table<StockCount>
                key={"stockCounts"}
                name={"stockCounts"}
                columns={[
                    ViewOnly(DateCol("Date", "createdAt", "MMM DD, YYYY")),
                    ViewOnly(StringCol("Created By", "createdByName")),
                    ViewOnly(Checkbox2Col<StockCount>("Completed", "completed", {
                        disabled: () => false, // enable even tho you can't actually do anything
                    })),
                    ViewOnly(NumberCol("Checked", "qtyChecked")),
                    ViewOnly(NumberCol("Adjusted", "qtyChanged")),
                    ViewOnly(NumberCol("Skipped", "qtySkipped")),
                    ViewOnly(CentsCol("Loss ($)", "lossCents")),
                    ViewOnly(CentsCol("Gain ($)", "gainCents")),
                ]}
                onRowClick={row => {
                    nav(`/logistics/stock-count/review/${row.id}`);
                }}
                fetch={ctx => getAll(ctx, offset => api.logistics.listStockCounts({
                    offset,
                }))}
                fetchDeps={[]}
            />
        </WhiteColumn>
    )
}
import {range, selectMany} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {css} from "@emotion/css";
import {blue, green, grey, red} from "@mui/material/colors";
import {useContext, useEffect, useState} from "react";
import {Cropper, Rect} from "./Cropper";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {ConfirmContext} from "../../misc/Confirm";
import {AnalyzeBBoxInput, AnalyzePageInput} from "../../api/Extractor";
import {SnackContext} from "../../misc/Snackbar";
import {WaitForAnalysisDialog} from "./WaitForAnalysisDialog";
import {fixRectPoints, rotatePoint} from "./CropLassoCreator";

export type Value = {
    rects: Rect[];
    rotation: Rotation;
    checked: boolean;
    ignore: boolean;
}

export type Rotation = 0 | 90 | 180 | 270;

export function ExtractorSession(props: {
    id: number;
    nPages: number;
}) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [values, setValues] = useState<{[index: number]: Value}>({});
    const [scrollWrapperRef, setScrollWrapperRef] = useState<HTMLDivElement|null>(null);
    const confirmCtx = useContext(ConfirmContext);
    const snack = useContext(SnackContext);

    useEffect(() => {
        if(!scrollWrapperRef) return;

        scrollWrapperRef.children.item(selectedIndex)?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
    }, [selectedIndex, scrollWrapperRef])

    const [waitForAnalysis, setWaitForAnalysis] = useState<string>();

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            height: "100%",
            overflow: "hidden",
        }}>
            <div ref={setScrollWrapperRef} style={{
                flex: 0,
                borderRight: "1px solid " + grey["400"],
                height: "100%",
                width: 120,
                flexBasis: "120px",
                overflowY: "auto",
                backgroundColor: grey["300"],
                paddingRight: 10,
            }}>
                {range(props.nPages).map(i => {
                    const value = values[i] || {
                        checked: false,
                        ignore: false,
                        rects: [],
                    };

                    return (
                        <div key={i.toString()}
                             className={thumbnailCss + " " + (i === selectedIndex ? "selected-thumb" : "")}
                             onClick={() => setSelectedIndex(i)}
                             style={{
                                backgroundImage: "url('" + api.extractor.imageURL({session: props.id, index: i}) + "')",
                             }}>
                            {value.checked && <>
                                {value.ignore ? <div key="check-icon" className={iconCss}>
                                    <RemoveCircleIcon style={{color: red["700"]}} />
                                </div> : <div key="check-icon" className={iconCss}>
                                    <CheckCircleIcon style={{color: green["700"]}} />
                                </div>}
                            </>}

                            {value.rects.length > 0 && !value.checked && <div key="uncheck-icon" className={iconCss}>
                                <RadioButtonUncheckedIcon style={{color: grey["700"]}} />
                            </div>}
                            {value.checked && <div className={coverCss + " " + (value.ignore ? "cover-ignored" : "")}></div>}
                            <div className="thumb-page-number">
                                Page {i + 1}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Cropper key={"crop-" + selectedIndex.toString()} index={selectedIndex}
                     value={values[selectedIndex] || null}
                     onApplyToAll={(rect, rotation) => {
                         let obj = values;

                         range(props.nPages).map(i => {
                            if(obj[i]?.ignore === true) return;

                            obj = Object.assign(obj, {
                                [i]: {
                                    rects: [rect],
                                    checked: i === selectedIndex,
                                    rotation: rotation,
                                }
                            })

                            return null;
                         })

                         setValues(Object.assign({}, obj))

                         if(selectedIndex + 1 < props.nPages) {
                             setSelectedIndex(selectedIndex + 1)
                         }
                     }}
                     onChange={value => {
                        const obj = Object.assign({}, values, {
                            [selectedIndex]: value,
                        })

                        setValues(obj)
                    }}
                     session={props.id}
                     hasNext={selectedIndex < props.nPages - 1}
                     onNext={() => {
                         if(selectedIndex >= props.nPages - 1) return;
                         let index = selectedIndex;

                         while(index + 2 < props.nPages && values[index + 1]?.ignore === true) {
                             index++;
                         }

                         setSelectedIndex(index + 1)
                     }}
                     onDone={async () => {
                         let allChecked = true;
                         for(let i in values) {
                            if(values[i].checked) continue
                            if(i.toString() === selectedIndex.toString()) continue

                            allChecked = false;
                            break;
                         }

                         if(!allChecked) {
                             const ok = await confirmCtx.confirm("You haven't reviewed every page. Are you sure you want to continue?");
                             if(!ok) return;
                         }

                         const ok = await confirmCtx.confirm("Are you ready to submit this document for table extraction analysis?");
                         if(!ok) return;

                         const pages = selectMany(range(props.nPages).map(i => {
                            const v = values[i];

                            // if nothing defined, we include it by default and crop to use entire page
                            if(!v) {
                                return [{
                                    bbox: makeBBox(undefined, 0),
                                    pageIndex: i,
                                    rotation: 0,
                                }]
                            }

                            if(v.ignore) return [];
                            if(v.rects.length === 0) {
                                return [{
                                    bbox: makeBBox(undefined, 0),
                                    pageIndex: i,
                                    rotation: rotationAbs(v.rotation),
                                }]
                            }

                            return v.rects.map(r => ({
                                bbox: makeBBox(r, v.rotation),
                                pageIndex: i,
                                rotation: rotationAbs(v.rotation),
                            }))
                         }), v => v) as AnalyzePageInput[];

                         try {
                             snack.loading();
                             const {id} = await api.extractor.analyze({
                                 session: props.id,
                                 pages: pages,
                             });
                             snack.success("Analysis started successfully");
                             setWaitForAnalysis(id);
                         } catch(e: any) {
                             snack.error(e.toString())
                         }
                     }}
            />
            {waitForAnalysis && <WaitForAnalysisDialog value={waitForAnalysis} onDone={() => setWaitForAnalysis(undefined)} />}
        </div>
    )
}

function makeBBox(input: Rect | null | undefined, rotation?: number): AnalyzeBBoxInput {
    rotation = rotation || 0;

    if(!input) {
        return {
            min: {
                x: 0,
                y: 0,
            },
            max: {
                x: 1,
                y: 1,
            }
        };
    }

    let min = {
        x: input.start.x / input.totalWidth,
        y: input.start.y / input.totalHeight,
    };

    let max = {
        x: input.end.x / input.totalWidth,
        y: input.end.y / input.totalHeight,
    }

    min = rotatePoint(min, {height: 1, width: 1}, -rotation)
    max = rotatePoint(max, {height: 1, width: 1}, -rotation)
    const fixed = fixRectPoints(min, max)

    console.log("bbox", input.start.x, input.start.y, input.totalWidth, input.totalHeight, fixed);

    return {
        min: fixed.topLeft,
        max: fixed.bottomRight,
    }
}

const coverCss = css({
    position: "absolute",
    zIndex: 2,
    backgroundColor: green["700"],
    opacity: 0.3,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    "&.cover-ignored": {
        backgroundColor: red["700"],
    }
})

const iconCss = css({
    position: "absolute",
    zIndex: 3,
    top: 0,
    right: 0,
    padding: 4,
    opacity: 0.8
})

const thumbnailCss = css({
    position: "relative",

    height: 120,
    margin: 2,
    cursor: "pointer",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    border: "2px solid " + grey["300"],
    boxSizing: "border-box",
    backgroundColor: "white",

    "&:hover": {
        opacity: 0.75,
    },

    "&.selected-thumb": {
        border: "2px solid " + blue["400"],
    },

    "& .thumb-page-number": {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        textAlign: "center",
        fontSize: "0.6rem",
    }
})


export function rotationAbs(input: number): Rotation {
    while(input < 0) {
        input = 360 + input
    }

    return input % 360 as Rotation;
}
import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";
export class PriceSectionNotes extends SubAPI {
    list(props: {
        project: number
        offset: number;
    }) {
        return this.fetcher.get<Paginated<PriceSectionNote>>("/api/price-section-notes", props)
    }

    upsert(props: Partial<PriceSectionNote>) {
        return this.fetcher.post<PriceSectionNote>("/api/price-section-note", props)
    }

    setDefaults(input: {
        keyName: string;
        notes: PriceSectionNoteDefault[];
    }) {
        return this.fetcher.post<{}>("/api/price-section-note/defaults", input)
    }

    getDefaults(props: {}) {
        return this.fetcher.get<PriceSectionNoteDefault[]>("/api/price-section-note/defaults", props)
    }
}

export type PriceSectionNote = {
    id: number;
    project: number;
    quoteAlternative: number;

    keyName: string;
    note: string;

    backup?: PriceSectionNote|null;
}

export type PriceSectionNoteDefault = {
    id: number;
    keyName: string;
    note: string;
}
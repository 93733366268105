import {forwardRef} from "react";
import {Project} from "../../../../../api/Projects";
import {Company} from "../../../../../api/Companies";
import {useUser} from "../../../../../misc/Permission";
import {PDF} from "../../../../../misc/pdf/parts/PDF";
import {PDFProjectContext} from "../../openings/OpeningsPDF";
import {useReadyCheck} from "../../../../../misc/pdf/UseReadyCheck";
import {CoverPage} from "../../../../../misc/pdf/parts/CoverPage";

export const Div10PDF = forwardRef(function(props: {
    lastSubmittal: string|null;
    project: Project;
    client?: Company;
    onReady(): void;
}, ref: any) {
    const usr = useUser();

    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    return (
        <div ref={ref}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <CoverPage client={props.client} title="DIV 8 & DIV 10 CUT SHEETS" lastSubmittal={props.lastSubmittal} />
                </PDFProjectContext.Provider>
            </PDF>
        </div>);
});
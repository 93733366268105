import {EventEmitter, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useCallback, useEffect} from "react";
import {
    DriverRoute,
    PurchaseOrder, PurchaseOrderItem
} from "../../../api/Logistics";
import {Loader} from "../../../misc/Loader";
import Business from "@mui/icons-material/Business";
import {PickupPoItemRow} from "./PickupPoItemRow";
import LocalShipping from "@mui/icons-material/LocalShipping";
import {RouteItemRow} from "./RouteItemRow";
import {DropPoItemRow} from "./DropPoItemRow";
import {DropDeliveryRow} from "./DropDeliveryRow";
import {routePopupPanel} from "./RouteList";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {grey} from "@mui/material/colors";
import {Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {MenuButton} from "../purchasing/MenuButton";
import {usePrintParallel} from "../../project/shopdrawing/changeset/PrintParallel";
import {PackingSlipPDF} from "../deliveries/PackingSlipPDF";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {Delivery, DeliveryItem} from "../../../api/Deliveries";

export const RefreshRouteItems = new EventEmitter();

export function RouteItems(props: {
    route: number;
    routeObj?: DriverRoute;
}) {
    const route = props.route;

    const list = useAsync2((props: { route: number}) => {
        return api.logistics.listRouteStops(props);
    }, {route: route}, [route]);

    const reloadList = useSyncedRef(list.reload)

    useEffect(() => {
        const sub = routePopupPanel.fromClient.subscribeAndFireLast(v => {
            if (v === "refresh-route") {
                reloadList.current();
            }
        })

        return () => sub.cancel();
    },[reloadList]);

    useEffect(() => {
        const sub = RefreshRouteItems.subscribe(() => {
            reloadList.current();
        });

        return () => sub.cancel()
    }, [reloadList])

    const ctx = usePrintParallel();

    const data = list.asList;
    const packingSlipGroups = data.filter(v => !!v.project && v.groups && v.groups.length > 0)

    const isPurchaseOrderItemGoingToWarehouse = useCallback((purchaseOrderItem: number) => {
        for(let i = 0; i < data.length; i++) {
            const stop = data[i];
            if(!stop.groups) continue
            if(!stop.tykelWarehouse) continue

            for(let j = 0; j < stop.groups.length; j++) {
                const group = stop.groups[j]
                if(!group.dropoffPurchaseOrder) continue;

                const item = (group.items || [])?.find(g => g.purchaseOrderItem === purchaseOrderItem)
                if(item) return true;
            }
        }

        return false;
    }, [data]);

    return (
        <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "auto"}}>
            <Loader {...list} />
            {data.map((d, index) => {
                const prevGroup = index > 0 ? data[index-1] : null;
                const nextGroup = index === data.length -1 ? null : data[index+1];

                return (
                    <RouteItemRow value={d} icon={
                        d.manufacturer ? <Business fontSize="inherit" /> :
                            d.project ? <LocalShipping fontSize="inherit" /> :
                                <LocalShipping fontSize="inherit" />
                    } title={
                        d.manufacturer ? `Pickup at ${d.manufacturerName}` :
                            d.project ? `Deliver to ${d.projectName}` :
                                `Tykel Warehouse`
                    } next={nextGroup}
                      prev={prevGroup}
                      disabled={props.routeObj?.completed}
                    >
                        <table>
                            {d.groups?.map(c => {
                                if(c.pickupPurchaseOrder) return <PickupPoItemRow
                                    value={c} routeCompleted={props.routeObj?.completed || false}
                                    isPurchaseOrderItemGoingToWarehouse={isPurchaseOrderItemGoingToWarehouse}
                                />
                                if(c.dropoffPurchaseOrder) return <DropPoItemRow value={c} routeCompleted={props.routeObj?.completed || false} />
                                if(c.delivery) return <DropDeliveryRow
                                    value={c}
                                    routeCompleted={props.routeObj?.completed || false}
                                    canRemove={!d.tykelWarehouse} /* remove from drop side */
                                />
                                return null;
                            })}
                        </table>
                    </RouteItemRow>
                )
            })}
            <div style={{flex: 1}} />

            {ctx.loading && <Dialog open>
                <DialogTitle>Printing...</DialogTitle>
                <DialogContent>
                    <Loader loading={true} error={null} />
                </DialogContent>
            </Dialog>}

            <div style={{padding: 8, paddingRight: 16, borderTop: "1px solid " + grey["300"],backgroundColor: grey["200"]}}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <MenuButton
                            options={[{
                                name: "Export Packing Slip",
                                disabled: packingSlipGroups.length === 0,
                                onClick: async () => {
                                    packingSlipGroups
                                        .map(gr => {
                                            if(!gr.project) return;

                                            const projectId = gr.project;
                                            const projectP = api.projects.get(projectId);

                                            gr.groups?.map(async (ch) => {

                                                let value: Delivery | PurchaseOrder;
                                                let items: (DeliveryItem|PurchaseOrderItem)[]

                                                const dropDel = ch.delivery;
                                                const dropPO = ch.dropoffPurchaseOrder;

                                                if(dropDel) {
                                                    const delP = api.deliveries.getDelivery({id: dropDel});
                                                    const delItemsP = getAll(null, offset => api.deliveries.listDeliveryItems({delivery: dropDel, offset}));
                                                    value = await delP;
                                                    items = await delItemsP;

                                                    items = items.filter(v => {
                                                        if(!ch.items) return false;
                                                        const routeItem = ch.items.find(i => i.deliveryItem === v.id)
                                                        if(!routeItem) return false;
                                                        v.qty = routeItem.qty;
                                                        return true;
                                                    })
                                                } else if(dropPO) {
                                                    const poP = api.logistics.getPO({id: dropPO});
                                                    const poItemsP =  getAll(null, offset => api.logistics.listPurchaseOrderItems({purchaseOrder: dropPO, type: "purchase-order", offset}));

                                                    value = await poP;
                                                    items = await poItemsP;

                                                    items = items.filter(v => {
                                                        if(!ch.items) return false;
                                                        const routeItem = ch.items.find(i => i.purchaseOrderItem === v.id)
                                                        if(!routeItem) return false;
                                                        v.qty = routeItem.qty;
                                                        return true;
                                                    })
                                                } else {
                                                    return null;
                                                }

                                                const project = await projectP;

                                                ctx.printer({
                                                    name: `packing-slip-${sanitizeFileName(project.name)}.pdf`,
                                                    render: async onReady => <PackingSlipPDF project={project} items={items} value={value} onReady={onReady} />,
                                                    download: true,
                                                    serverInfo: {
                                                        submissionForProject: projectId,
                                                        submissionType: "route-packing-slip",
                                                        ignoreSubmissionDate: true,
                                                    }
                                                })
                                            })
                                        })

                                }
                            }]}
                        >
                            Export
                        </MenuButton>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

function sanitizeFileName(input: string): string {
    return input.replace(/[^A-z0-9- .]+/g, "-").replace(/[-]{2,}/g, "-")
}

import {first, useAsync2} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {Permission, PermissionInfo} from "../../api/Users";
import {
    Checkbox,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";

const permissionItems: {
    permission: Permission;
    description: string;
    title: string;
}[] = [
    {
        permission: "CanReleaseAndPurchase",
        title: "Release and Purchase",
        description: "Can both release and purchase the same item (most people can only do one of those things).",
    },
    {
        permission: "CanViewProjectPricingProfit",
        title: "Project Price and Profit",
        description: "Can view project pricing and profit",
    },
    {
        permission: "EditShopDrawingWhenLockedToManager",
        title: "Edit When Locked",
        description: "Can edit shop drawings even when locked to manager",
    },
    {
        permission: "ProjectManager",
        title: "Project Manager",
        description: "Can manage projects (project mode)",
    },
    {
        permission: "Estimator",
        title: "Estimator",
        description: "Can estimate projects (quote mode)",
    },
]

export function PermissionsOverride(props: {
    initialRole?: string;
    value: PermissionInfo[];
    onChange(value: PermissionInfo[]): void;
}) {
    const rolePermissions = useAsync2((input) => api.users.effectivePermissions(input), {
        role: props.initialRole as string,
    }, [props.initialRole]);

    if(rolePermissions.loadingOrError) {
        return rolePermissions.LoadingOrErrorElement;
    }

    return (
        <div>
            <List dense>
                {permissionItems.map((p, index) => {
                    const roleChecked = rolePermissions.asList.some(v => v.permission === p.permission && v.enabled);
                    const userOverride = first(props.value, v => v.permission === p.permission);
                    const checked = userOverride ? userOverride.enabled : roleChecked;

                    return (
                        <ListItemButton key={index.toString()} onClick={() => {
                            let want = true;
                            if(checked) {
                                want = false;
                            }

                            if(want === roleChecked) {
                                // remove p.permission, since no override is needed to capture desired value
                                props.onChange(props.value.filter(v => v.permission !== p.permission));
                            } else {
                                const toUpdate = props.value.filter(v => v.permission === p.permission);
                                if (toUpdate.length === 0) {
                                    props.onChange([...props.value, {
                                        permission: p.permission,
                                        enabled: want,
                                    }])
                                } else {
                                    // update existing value
                                    props.onChange(props.value.map(v => {
                                        if (v.permission === p.permission) {
                                            return {
                                                permission: p.permission,
                                                enabled: want,
                                            }
                                        }

                                        return v;
                                    }))
                                }
                            }
                        }}>
                            <ListItemIcon>
                                <Checkbox checked={checked} />
                            </ListItemIcon>
                            <ListItemText
                                primary={p.title}
                                secondary={p.description}
                            />
                                <div style={{fontSize: "0.75rem"}}>
                                    {userOverride && <div>Overridden</div>}
                                </div>
                        </ListItemButton>
                    );
                })}

            </List>
        </div>
    )
}
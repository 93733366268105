import {InventoryItem, StockCountItem} from "../../../api/Logistics";
import {CellWithBg} from "../purchasing/CellWithBg";
import {green, orange, red} from "@mui/material/colors";

export function RestockStatus() {
    return {
        name: "Status",
        width: 50,
        filter: (row: InventoryItem | StockCountItem, value: string) => {
            if(value.toLowerCase() === "ok") {
                return row.qty >= row.stockMinQty;
            }

            if(value.toLowerCase() === "low") {
                return row.qty < row.idealQty;
            }

            if(value.toLowerCase() === "reorder") {
                return row.qty < row.stockMinQty;
            }

            return true;
        },
        render: (data: InventoryItem | StockCountItem) => {
            let name = "Ok";
            let color = "white";
            let bgColor = green["500"] as string;

            if(data.qty < data.stockMinQty || data.warnReorder) {
                bgColor = red["500"];
                name = "Reorder";
            } else if(data.qty < data.idealQty || data.warnLow) {
                bgColor = orange["500"];
                name = "Low";
            }

            return <CellWithBg color={color} backgroundColor={bgColor}>
                {name}
            </CellWithBg>
        }
    }
}
import {Container} from "../../misc/Container";
import {SectionHeader} from "../../misc/SectionHeader";
import {StringCol, Table} from "../../misc/scroller/Table";
import {removeRowOption, RowOptionCol} from "../logistics/purchasing/RowOptions";
import {getAll} from "../project/shopdrawing/openings/Openings";
import {api} from "../../api/API";
import {WhiteColumn} from "../logistics/purchasing/WhiteColumn";
import {Company, CompanyKind} from "../../api/Companies";
import {AutoInsert} from "../project/shopdrawing/openings/AutoInsertOpening";
import React from "react";
import {useQueryParam} from "../project/quote/pricing/QueryParam";
import {TabSection} from "./TabSection";

const tableName = "customer-list"

export function ClientList() {
    const [tab, setTab] = useQueryParam("t", "contractor");
    const kind = tab as CompanyKind;

    return <Container>
        <WhiteColumn>
            <SectionHeader name="Customers" />

            <TabSection value={tab} onChange={setTab} />

            <Table<Company>
                name={tableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [tableName],
                                confirm: true,
                            })
                        ],
                    }),
                    StringCol("Name", "name", 100),
                    StringCol("Street", "addressStreet", 100),
                    StringCol("City", "addressCity", 100),
                    StringCol("Province", "addressProvince", 100),
                    StringCol("Postal", "addressPostal", 100),
                ]}
                fetch={ctx => getAll(ctx, offset => api.companies.list({
                    offset,
                    kind: kind,
                }))}
                onChange={input => api.companies.upsert(input)}
                fetchDeps={[kind]}
                insert={{
                    buttonText: "New Row",
                    alignX: "left",
                    modal: input => <AutoInsert onDone={input} getInput={() => {
                        return ({
                            kind: kind,
                        })
                    }} />
                }}
            />
        </WhiteColumn>
    </Container>
}
import {ProjectLogItem} from "../../../../../api/ProjectLog";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import moment from "moment/moment";
import {AsyncHelper} from "../AsyncHelper";
import {useGetAll} from "../../../../logistics/routes/useGetAll";
import {api} from "../../../../../api/API";

export function ProjectManagement(props: {
    opening?: number;
    miscProduct?: number;
}) {
    const data = useGetAll(input => api.projectLog.list(input), {
        opening: props.opening,
        miscProduct: props.miscProduct
    }, [props.opening, props.miscProduct]);

    return (
        <AsyncHelper<ProjectLogItem[]> {...data}>
            {list => <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Detail</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(l => (<TableRow key={l.id}>
                        <TableCell>{moment(l.createdAt).format("MMM DD [at] Ha")}</TableCell>
                        <TableCell>{l.createdByName}</TableCell>
                        <TableCell>
                            {l.content}

                            {l.note && <div style={{paddingTop: 8}}>
                                Note: {l.note}
                            </div>}
                        </TableCell>
                    </TableRow>))}
                    {list.length === 0 && <TableRow key="no-data">
                        <TableCell colSpan={3}>
                            No History
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>}
        </AsyncHelper>
    )
}
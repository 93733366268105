import {EditProduct} from "./EditProduct";
import {Grid} from "@mui/material";
import {grey} from "@mui/material/colors";
import {HardwareTable} from "./HardwareTable";
import {Library} from "./library/Library";
import React, {useCallback, useRef} from "react";
import {useIsQuote} from "../../quote/QuoteContainer";
import {api} from "../../../../api/API";
import {HwLibraryTypeProvider} from "./library/HwLibraryType";
import {cellInActive} from "../pricing/cellconst";
import {HwEditingContextProvider} from "./HwEditingContextProvider";

export function useHwAPI() {
    const isQuote = useIsQuote();
    return isQuote ? api.quoteHardware : api.hardware;
}

export function HardwareGeneric() {
    const [lockScroll, setLockScroll] = React.useState(false);
    const scrollRef = useRef<HTMLDivElement|null>(null)
    const autoScroll = useCallback((input: HTMLElement | null) => {
        if(!input) return;
        if(!scrollRef.current) return;

        const scroller = scrollRef.current;
        const beforeOverflowY = scroller.style.overflowY
        scroller.style.overflowY = "auto";
        input.scrollIntoView(true)
        scroller.style.overflowY = beforeOverflowY;

    }, [])

    return (
        <HwLibraryTypeProvider value="hardware">
            <EditProduct mode="hardware">
                <Grid container style={{borderTop: "1px solid " + grey["300"], flex: 1, overflow: "auto"}} direction="row" alignItems="stretch">
                    <Grid item xs ref={scrollRef} style={{overflowY: lockScroll ? "hidden" : "auto", background: cellInActive, flex: 1, height: "100%"}}>
                        <HwEditingContextProvider onLockScroll={setLockScroll} onAutoScroll={autoScroll}>
                            <HardwareTable key="-" />
                        </HwEditingContextProvider>
                    </Grid>
                    <Grid item style={{width: 400, height: "100%", overflowY: "auto"}}>
                        <div style={{
                            borderLeft: "1px solid " + grey["300"], height: "100%",
                            display: "flex", flexDirection: "column",
                        }}>
                            <Library />
                        </div>
                    </Grid>
                </Grid>
            </EditProduct>
        </HwLibraryTypeProvider>
    )
}
import {WhiteColumn} from "../purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {useEffect, useState} from "react";
import {SmallSearchBox} from "../purchasing/SmallSearchBox";
import {BoolCol, DateCol, NumberCol, Table, ViewOnly} from "../../../misc/scroller/Table";
import { DriverRoute} from "../../../api/Logistics";
import {removeRowOption, RowOptionCol} from "../purchasing/RowOptions";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import {CreateRoute} from "./CreateRoute";
import {useNavigate} from "react-router-dom";
import { Container } from "../../../misc/Container";
import {PopoutInteractor} from "../../../misc/PopoutInteractor";
import {ToMoveProps} from "./ToMove";
import {Grid} from "@mui/material";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import {OpenInNew} from "@mui/icons-material";

export const routeListTableName = "routes.list"
export const routePopupPanel = new PopoutInteractor<ToMoveProps | "refresh-to-move", "refresh-route">("routing.to-move", "/logistics/routes/to-move-popout");

export function RouteList() {
    const [search, setSearch] = useState("")
    const nav = useNavigate();

    useEffect(() => {
        routePopupPanel.sendToClient({});
        return () => routePopupPanel.close();
    }, [])

    return (
        <Container>
            <WhiteColumn>
                <SectionHeader name="Routes" right={
                    <Grid container spacing={1} flexWrap="nowrap">
                        <Grid item>
                            <SmallGreyButton onClick={() => routePopupPanel.open()} startIcon={<OpenInNew />}>
                                To Pickup/Deliver
                            </SmallGreyButton>
                        </Grid>
                        <Grid item>
                            <SmallSearchBox value={search} onChange={setSearch} fullWidth />
                        </Grid>
                    </Grid>
                } />

                <Table<DriverRoute>
                    name={routeListTableName}
                    columns={[
                        RowOptionCol({
                            options: (dt) => [
                                removeRowOption(dt, {
                                    refreshTables: [routeListTableName],
                                    confirm: true,
                                })
                            ],
                        }),
                        ViewOnly(NumberCol("#", "id", 80)),
                        ViewOnly(DateCol("Date", "date", "ddd, MMM D", 100)),
                        ViewOnly(BoolCol("Completed", "completed", 250)),
                    ]}
                    fetch={ctx => getAll(ctx, offset => api.logistics.listRoutes({
                        offset,
                        search,
                    }))}
                    onChange={input => api.logistics.updateRoute(input)}
                    fetchDeps={[search]}
                    onRowClick={item => {
                        nav("/logistics/routes/" + item.id)
                    }}
                    insert={{
                        modal: (onDone) => <CreateRoute onDone={onDone} />,
                    }}
                />
            </WhiteColumn>
        </Container>
    )
}

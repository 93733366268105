import {Alert, CircularProgress, Grid, IconButton, Snackbar as MSnackbar} from "@mui/material";
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";
import Close from "@mui/icons-material/Close"

export const SnackContext = createContext({
    error: (input: string) => {},
    loading: (message?: string) => {},
    success: (message: string) => {},
    hide: () => {},
})

export function useSnackbar() {
    return useContext(SnackContext);
}

type QueueAction = "error" | "loading" | "success" | "hide"

type QueueItem = {
    type: QueueAction,
    message?: string;
}

function newItem(type: QueueAction, message?: string) {
    return {
        type,
        message,
    }
}

const minTime = 0;

function since(tm: number) {
    return Date.now() - tm
}

export function Snackbar(props: PropsWithChildren<{
    timeout: number;
}>) {
    const [severity, setSeverity] = useState<AlertColor>("info");
    const [message, setMessage] = useState<string|null>(null);
    const [loading, setLoading] = useState(false);
    const tLast = useRef(0);

    useEffect(() => {
        if(loading) return;

        let tm = setTimeout(() => {
            setMessage(null);
        }, props.timeout);

        return () => clearTimeout(tm);
    }, [loading, message, severity, props.timeout]);

    const queue = useRef<QueueItem[]>([]);
    const [hasQueued, setHasQueued] = useState(false);

    const processItem = useCallback((item: QueueItem) => {
        tLast.current = Date.now();

        switch(item.type) {
            case "error":
                setSeverity("error")
                setLoading(false)
                setMessage(item.message || "Unknown Error");
                break;
            case "loading":
                setSeverity("info")
                setLoading(true);
                setMessage(item.message || "Loading...");
                break;
            case "success":
                setSeverity("success")
                setLoading(false)
                setMessage(item.message || "Success");
                break;
            case "hide":
                setLoading(false)
                setMessage(null);
                break;
        }
    }, []);

    useEffect(() => {
        if(!hasQueued) return;

        const i = setInterval(() => {
            for(let i = 0; i < 1000; i++) {
                const item = queue.current.shift();
                if (!item) {
                    setHasQueued(false)
                    return;
                }

                if(queue.current.length > 1 && item.type === "loading") {
                    continue
                }

                processItem(item)
                return;
            }
        }, minTime);

        return () => clearInterval(i);
    }, [hasQueued, processItem]);

    const loadingRef = useRef(false);
    loadingRef.current = loading;

    const ctx = useMemo(() => {

        function handle(item: QueueItem) {
            const enoughTimePassed = (since(tLast.current) > minTime || loadingRef.current);

            if(enoughTimePassed && queue.current.length === 0) {
                processItem(item)
            } else {
                queue.current.push(item);
            }

            // always start queue, just in case another item comes in
            setHasQueued(true)
        }

        return ({
            error: (message: string) =>  {
                handle(newItem("error", message));
            },
            loading: (message?: string) => {
                handle(newItem("loading", message));
            },
            success: (message: string) => {
                handle(newItem("success", message));
            },
            hide: () => {
                handle(newItem("hide"));
            },
        })
    }, [processItem]);

    return (
        <SnackContext.Provider value={ctx}>
            {props.children}
            <MSnackbar open={message !== null}>
                <Alert variant="filled" severity={severity} action={
                    loading && (
                        <div style={{height: "84%", display: "flex", alignItems: "center"}}>
                            <span style={{color: "white", width: "1em", height: "1em"}}><CircularProgress size="small" color="inherit" style={{display: "block"}} /></span>
                        </div>)
                } onClose={!loading ? () => setMessage(null) : undefined}>
                    <Grid container spacing={1} alignItems="center" style={{minWidth: 175}}>
                        <Grid item xs>
                            {message}
                        </Grid>
                        {!loading && <Grid item>
                            <IconButton style={{color: "white", margin: -10}} size="small" onClick={() => setMessage(null)}>
                                <Close fontSize="inherit" color="inherit" />
                            </IconButton>
                        </Grid>}
                    </Grid>

                </Alert>
            </MSnackbar>
        </SnackContext.Provider>
    )
}
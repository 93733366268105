import CoffeeMaker from "@mui/icons-material/CoffeeMaker";
import NotificationsPaused from "@mui/icons-material/NotificationsPaused";
import Phishing from "@mui/icons-material/Phishing";
import {useState} from "react";
import {Grid} from "@mui/material";
import {grey} from "@mui/material/colors";

export function BlankList() {
    const [value] = useState(() => Math.round(2 * Math.random()))
    return (
        <Grid container spacing={1} alignItems="middle" justifyContent="center" style={{marginTop: 20, color: grey["700"]}}>
            <Grid item>
                {value === 0 && <CoffeeMaker color='inherit' />}
                {value === 1 && <Phishing color='inherit' />}
                {value === 2 && <NotificationsPaused color='inherit' />}
            </Grid>
            <Grid item style={{fontSize: "0.9rem"}}>
                Nothing here
            </Grid>
        </Grid>
    )
}
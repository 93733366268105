import React, {
  createContext,
  PropsWithChildren, RefObject,
  useCallback,
  useEffect,
  useMemo, useRef,
  useState,
} from "react";
import { pdfPageElementClassName } from "./parts/Page";
import { getClassNameForSubstitutionType } from "./PDFSubstitution";
import { PDFHelper } from "./PDFHelper";
import {PrintArgs} from "../../api/Print";

enum WindowState {
  READY,
  RENDERING,
  RENDER_COMPLETE,
}

export type DebugPrintFx = (onReady: () => void, config: AdjustConfig) => Promise<React.ReactElement>;
export type PrintFx = (onReady: () => void, config: AdjustConfig) => Promise<React.ReactElement>;
export type Printer = (input: PrintFx, config: AdjustConfig) => Promise<string>;

export type AdjustConfig = {
  adjust(cb: (input: PrintArgs) => PrintArgs): void;
}

interface Context {
  print: Printer;
  isPrinting: boolean;
  isPrintingRef: RefObject<boolean>
}

export const PDFGenContext = createContext<Context>({
  print: () => Promise.reject("not setup yet"),
  isPrintingRef: { current: false },
  isPrinting: false,
});

const debug = false;

export const pdfWrapper = debug
  ? {}
  : {
      // use visibility instead of display: "none" b/c some renderers
      // require clientBoundingRect() to give non-zero values
      visibility: "hidden" as "hidden",
      height: 0,
    };

function getStateName(w: WindowState) {
  switch (w) {
    case WindowState.READY: return "ready";
    case WindowState.RENDERING: return "rendering";
    case WindowState.RENDER_COMPLETE: return "render-complete";
  }
}

export function PDFGenProvider(props: PropsWithChildren<{}>) {
  const [windowState, setWindowState] = useState(WindowState.READY);
  const [childToRender, setChildToRender] = useState<React.ReactElement>();
  const [childRef, setChildRef] = useState<HTMLElement>();
  const [showPopupBlockedModal, setShowPopupBlockedModal] = useState(false);

  const [onComplete, setOnComplete] = useState<{
    resolve: (html: string) => void;
    reject: (err: Error) => void;
  }>();

  const isPrinting = windowState !== WindowState.READY;

  const isPrintingRef = useRef(false)
  isPrintingRef.current = isPrinting;


  console.log("PDFGen", getStateName(windowState));

  const renderComplete = useCallback(
    () =>
      setWindowState((prev) => {
        if (prev !== WindowState.RENDERING) {
          console.warn("invalid state", prev);
          return prev;
        }

        return WindowState.RENDER_COMPLETE;
      }),
    []
  );

  const print = useCallback(
    (render: (onReady: () => void, cfg: AdjustConfig) => Promise<JSX.Element>, cfg: AdjustConfig) => {
      if (windowState !== WindowState.READY) {
        const err = new Error("invalid window-state " + windowState)
        console.error(err)
        return Promise.reject(err);
      }

      // set isPrintingRef immediately after function call to prevent callers
      // from calling print() again while we're still rendering
      isPrintingRef.current = true;

      return new Promise<string>(async (resolve, reject) => {
        setWindowState(WindowState.RENDERING);
        setOnComplete({ resolve: resolve, reject: reject });

        try {
          const element = await render(renderComplete, cfg);
          setChildToRender(element);
        } catch (e) {
          console.error(e);
          setWindowState(WindowState.READY);
          reject(e);
        }
      });
    },
    [renderComplete, windowState]
  );

  useEffect(() => {
    if (windowState !== WindowState.RENDER_COMPLETE) return;
    if (!childRef) return;
    if (!onComplete) return;

    if (!debug) setChildToRender(undefined);

    doPageSubstitutions(childRef);

    const styles = Array.from(document.getElementsByTagName("style")).map(
      (p) => {
        if(p.innerHTML && p.innerHTML.length > 0) return p.outerHTML;

        const sht = p.sheet as CSSStyleSheet;
        if(sht.cssRules && sht.cssRules.length > 0) {
          let content = Array.from(sht.cssRules).map(r => r.cssText).join("\n")
          return p.outerHTML.replace("</style>", content + "</style>");
        }

        return p.outerHTML
      }
    );

    // eslint-disable-next-line no-restricted-globals
    const host = location.protocol + "//" + location.host

    // links needed for font-refs
    const links = Array.from(document.getElementsByTagName("link")).map(
      (p) => {
        return p.outerHTML.replace(/(href=["'])\//g, "$1" + host + "/")
      }
    );

    // fix page size so that page breaks work correctly
    styles.push(
      "<style>body { height: auto; width: auto; position: relative; }</style>"
    );

    const body = childRef.innerHTML;
    const content = [
      document.doctype
        ? new XMLSerializer().serializeToString(document.doctype)
        : "", // doctype matters for default user-agent stylesheets
      "<html>",
      `<meta charset="utf-8" />`, // to deal with promo characters
      ...links,
      ...styles,
      `<body style="-webkit-print-color-adjust:exact; margin: 0; padding: 0;">`,
      body,
      `</body></html>`,
    ].join("\n");

    try {
      onComplete.resolve(content);
    } catch (e) {}

    setWindowState(WindowState.READY);
    isPrintingRef.current = false;
  }, [windowState, childRef, onComplete]);

  const ctx = useMemo(() => {
    return {
      print: print,
      isPrinting: isPrinting,
      isPrintingRef: isPrintingRef,
    };
  }, [print, isPrinting]);

  return (
    <React.Fragment>
      <PDFHelper
        childToRender={childToRender}
        setChildRef={setChildRef}
        setShowPopupBlockedModal={setShowPopupBlockedModal}
        showPopupBlockedModal={showPopupBlockedModal}
      />
      <PDFGenContext.Provider value={ctx}>
        {props.children}
      </PDFGenContext.Provider>
    </React.Fragment>
  );
}

function doPageSubstitutions(ref: HTMLElement) {
  const elements = Array.from(
    ref.getElementsByClassName(pdfPageElementClassName)
  );
  const pageCount = elements.length;

  elements.map((e, index) => {
    let placeholders = Array.from(
      e.getElementsByClassName(getClassNameForSubstitutionType("page-no"))
    );
    placeholders.map((el) => (el.innerHTML = (index + 1).toString()));

    placeholders = Array.from(
      e.getElementsByClassName(getClassNameForSubstitutionType("page-count"))
    );
    placeholders.map((el) => (el.innerHTML = pageCount.toString()));

    return null;
  });
}

import {Grid} from "@mui/material";
import React, {useContext} from "react";
import {PrintManagerContext} from "../../../misc/pdf/PrintManager";
import {Delivery} from "../../../api/Deliveries";
import {PackingSlipPDF} from "./PackingSlipPDF";
import {grey} from "@mui/material/colors";
import {api} from "../../../api/API";
import {MenuButton} from "../purchasing/MenuButton";
import {PickingSlipPDF} from "./PickingSlipPDF";
import {useRequireDetails} from "./RequireDetails";
import {getAll} from "../../project/shopdrawing/openings/Openings";

export function ItemsFooter(props: {
    left?: any;
    delivery: Delivery;
}) {
    const ctx = useContext(PrintManagerContext);
    const requireDetails = useRequireDetails();

    return (
        <div style={{padding: 8, paddingRight: 16, borderTop: "1px solid " + grey["300"]}}>
            {requireDetails.Element}
            <Grid container>
                <Grid item xs>
                    {props.left}
                </Grid>
                <Grid item>
                    <MenuButton
                        options={[{
                            name: "Export Packing Slip",
                            onClick: async () => {
                                const deliveryItems = await getAll(null, offset => api.deliveries.listDeliveryItems({
                                    offset,
                                    delivery: props.delivery.id,
                                }))
                                const project = await api.projects.get(props.delivery.project);
                                ctx.print(`packing-slip-${props.delivery.id}.pdf`, async onReady => <PackingSlipPDF
                                    value={props.delivery}
                                    items={deliveryItems}
                                    project={project}
                                    onReady={onReady} />)
                            }
                        }, {
                            name: "Export Picking Slip",
                            onClick: async () => {
                                let delivery = await api.deliveries.getDelivery({
                                    id: props.delivery.id,
                                });
                                
                                if(!delivery.pickByDate) {
                                    await requireDetails.show<Delivery>({
                                        title: "Pick By Date Required",
                                        message: "Please set a pick by date before exporting a picking slip.",
                                        inputs: [{
                                            type: "date",
                                            label: "Pick By Date",
                                            key: "pickByDate",
                                            required: true,
                                        }],
                                        onUpdate: async (value: Partial<Delivery>) => {
                                            delivery = await api.deliveries.updateDelivery(Object.assign({}, props.delivery, value))
                                        }
                                    })
                                }

                                const deliveryItems = await getAll(null, offset => api.deliveries.listDeliveryItems({
                                    offset,
                                    delivery: props.delivery.id,
                                }))

                                const project = await api.projects.get(delivery.project);
                                ctx.print(`picking-slip-${delivery.id}.pdf`, async onReady => <PickingSlipPDF
                                    value={delivery}
                                    items={deliveryItems}
                                    project={project}
                                    onReady={onReady} />)
                            }
                        }]}
                    >
                        Export
                    </MenuButton>
                </Grid>
            </Grid>
        </div>
    )
}
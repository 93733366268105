import {css} from "@emotion/css";

export const tableish = css({
    "& > *": {
        border: "1px solid black",
        padding: 10,
        minWidth: 300,

        "&:not(:last-child)": {
            borderBottom: "none",
        }
    }
})

export const table = css({
    border: "1px solid black",
    borderCollapse: "collapse",
    fontSize: "0.8rem",

    minWidth: 300,
    "& td": {
        border: "1px solid black",
        padding: 10,
    }
})
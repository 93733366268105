import {Contact} from "../../../../api/Contacts";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField
} from "@mui/material";
import {autoFormat} from "./AutoFormat";
import {useProjectId} from "../../shopdrawing/ProjectName";

export function EditContact(props: {
    value: Contact;
    onDone(value: Contact): void;
}) {

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        const result = await api.contacts.upsert(input)
        onDoneRef.current(result);
    }, [])

    const [name, setName] = useState(props.value.name);
    const [phone, setPhone] = useState(props.value.phone);
    const [email, setEmail] = useState(props.value.email);
    const [title, setTitle] = useState(props.value.title);
    const [siteContact, setSiteContact] = useState(props.value.siteContact);
    const projectId = useProjectId();

    return (
        <Dialog open>
            <DialogTitle>Edit Contact</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} /></Grid>
                    <Grid item>
                        <TextField fullWidth label="Title" value={title} onChange={e => setTitle(e.target.value)} /></Grid>
                    <Grid item>
                        <TextField label="Email" value={email} onChange={e => setEmail(autoFormat(e.target.value, "email"))} />
                    </Grid>
                    <Grid item>
                        <TextField label="Phone" value={phone} onChange={e => setPhone(autoFormat(e.target.value, "phone"))} />
                    </Grid>
                    {projectId && <Grid item>
                        <FormControlLabel control={<Switch checked={siteContact} onChange={(_, checked) => setSiteContact(checked)} />} label="Site Contact" />
                    </Grid>}
                </Grid>

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={() => props.onDone(props.value)}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        name: name,
                        title: title,
                        email: email,
                        phone: phone,
                        siteContact: siteContact,
                        linkToProject: projectId ? projectId : undefined,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
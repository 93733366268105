import {EventEmitter, useAsyncAction} from "nate-react-api-helpers";
import {Button, Grid} from "@mui/material";
import {useEventEmitter} from "../../../../misc/UseEventEmitter";
import {useTableData, useTableRefresher} from "../../../../misc/scroller/Table";
import {deliveryItemsTableName, DeliveryRow} from "../DeliveryItems";
import {useState} from "react";
import {UpsertBox} from "./UpsertBox";
import {DeliveryBox, DeliveryItem} from "../../../../api/Deliveries";
import {api} from "../../../../api/API";
import {MenuButton} from "../../purchasing/MenuButton";

const blankList: number[] = []

export function BoxSetup(props: {
    selector: EventEmitter<number[]>
    delivery: number;
}) {
    const selector = props.selector
    const list = useEventEmitter(selector)
    const rawDeliveryRows = (useTableData(deliveryItemsTableName) as DeliveryRow[])
    const boxes = rawDeliveryRows.map(d => d.box).filter(v => !!v) as DeliveryBox[];
    const deliveryData = rawDeliveryRows.map(d => d.delivery).filter(v => !!v) as DeliveryItem[];

    const [showBox, setShowBox] = useState(false)
    const refresh = useTableRefresher(deliveryItemsTableName);

    const list2 = list || blankList

    const rows = deliveryData.filter(d => {
        return list2.includes(d.id)
    })
    
    const qty = rows.reduce((acc, row) => acc + row.qty, 0)

    const update = useAsyncAction(async (input) => {
        await api.deliveries.updateBox(input)
        refresh();
    }, [])
    
    return (
        <>
            <div style={{
                fontSize: "0.9rem",
            }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Button size="small"
                                variant="contained"
                                color="primary"
                                disabled={rows.length === 0}
                                onClick={() => setShowBox(true)}
                        >Create Box</Button>
                    </Grid>
                    <Grid item>
                        <MenuButton
                            size="small"
                            color="primary"
                            disabled={rows.length === 0}
                            options={boxes.map(b => ({
                                name: b.label,
                                onClick: () => {
                                    update.callback(Object.assign({}, b, {
                                        addItems: rows.map(r => r.id),
                                    }))
                                }
                            }))}
                        >
                            Add to Box
                        </MenuButton>
                    </Grid>
                    <Grid item>
                        <Button size="small"
                                variant="outlined"
                                disabled={rows.length === 0}
                                onClick={() => selector.emit([])}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {qty} items
                    </Grid>
                    {update.LoadingElement ? <Grid item>{update.LoadingElement}</Grid> : null}
                </Grid>
            </div>
            {showBox && <UpsertBox
                delivery={props.delivery}
                rows={rows}
                onClose={(doRefresh) => {
                    if(doRefresh) refresh();
                    setShowBox(false)
                    props.selector.emit([]);
                }} />}
        </>
    )
}
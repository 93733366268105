import {PriceLine2} from "./Frames";
import {Removal} from "./priceEditor/Removal";
import {Replace} from "./priceEditor/Replace";
import {Addition} from "./priceEditor/Addition";
import {Change} from "./priceEditor/Change";

export function ExtendedPriceEditor(props: {
    value: PriceLine2;
    onDone(update: PriceLine2): Promise<void>
    onCancel(): void;
    anchor: any;
}) {
    const v = props.value;
    if(v.kind === "previous") return null;

    switch(v.proposingTag) {
        case "remove":
            return <Removal {...props} />
        case "replace":
            return <Replace {...props} />
        case "change":
            return <Change {...props} />
        case "new":
            return <Addition {...props} />
        default:
            return null;
    }
}


import {ProjectFile} from "../../../../api/ProjectFiles";
import React, {useState} from "react";
import ApprovalIcon from '@mui/icons-material/Approval';
import {css} from "@emotion/css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {EditApprovals} from "./EditApprovals";

export function Approvals(props: {
    value: ProjectFile;
    onChange(): void;
}) {
    const [showEdit, setShowEdit] = useState(false);

    return (
        <>
            <div className={header} onClick={() => {
                setShowEdit(true)
            }}>
                <ApprovalIcon />
                <div style={{width: 4}} />
                <div style={{flex: 1}}>
                    {props.value.status === "pending"?<>
                        <div style={{fontSize: "0.75rem"}}>PENDING APPROVAL</div>
                        <div>Needs approval from {props.value.needsApprovalFrom}</div>
                    </> : props.value.status === "approved" ? <>
                        <div style={{fontSize: "0.75rem"}}>APPROVED</div>
                        <div>Approved by {props.value.approvedBy}</div>
                    </> : props.value.status === "rejected" ? <>
                        <div style={{fontSize: "0.75rem"}}>REJECTED</div>
                    </> : <>
                        Setup Approvals
                    </>}
                </div>
                <div style={{height: "1.2em", overflow: "hidden"}}>
                    <ChevronRightIcon fontSize="inherit" />
                </div>
            </div>

            {showEdit && <EditApprovals value={props.value} onDone={() => {
                setShowEdit(false)
                props.onChange();
            }} onCancel={() => setShowEdit(false)} />}
        </>
    )
}

const header = css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
})
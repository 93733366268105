import {Alert, Button, Divider, Grid, IconButton, Menu, MenuItem, styled} from "@mui/material";
import {useAsync2, useAsyncAction, useAuthenticated} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {Loader} from "../Loader";
import {Task} from "../../api/Tasks";
import {grey} from "@mui/material/colors";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Link} from "react-router-dom";
import {DateString} from "../../api/Projects";
import moment from "moment";
import MarkEmailRead from "@mui/icons-material/MarkEmailRead";
import Delete from "@mui/icons-material/Delete";
import {useSyncedRef} from "../SyncedRef";

export function TaskMenu(props: {
    onClose(): void;
    anchorEl: any;
}) {
    const list = useAsync2(() => api.tasks.list(), {}, []);
    const reloadRef = useSyncedRef(list.reload);
    const auth = useAuthenticated()
    const authRef = useSyncedRef(auth);

    const archive = useAsyncAction(async (id) => {
        await api.tasks.archive(id);
        reloadRef.current();
        authRef.current.forcePing()
    }, []);

    const markRead = useAsyncAction(async (id) => {
        await api.tasks.markAsRead(id);
        reloadRef.current();
        authRef.current.forcePing()
    }, []);

    const markAllRead = useAsyncAction(async () => {
        await api.tasks.markAllAsRead();
        reloadRef.current();
        authRef.current.forcePing()
    }, []);

    return (
        <Menu open anchorEl={props.anchorEl} onClose={props.onClose} style={{minHeight: 200}}>
            <div style={{paddingLeft: 8, paddingRight: 6, paddingBottom: 8, width: 400}}>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <div style={{fontSize: "0.8rem", color: grey["700"]}}>
                            Outstanding tasks
                        </div>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => markAllRead.callback(null)} size="small" variant="outlined">Mark all as Read</Button>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            <Loader {...markRead} />
            <Loader<Task[]> {...list}>
                {data => <>{
                    data.map(l => (
                        <MenuItem key={l.id}>
                            <div style={{width: 24}}>
                                <Dot read={l.read} />
                            </div>
                            <div style={{flex: 1}}>
                                <div>{l.content}</div>
                                <div style={{fontSize: "0.7rem"}}>{createdAt(l.createdAt)}</div>
                            </div>
                            {l.userArchiveable && <div>
                                <IconButton size="small" onClick={() => {
                                    archive.callback(l.id);
                                }}>
                                    <Delete />
                                </IconButton>
                            </div>}
                            {!l.read && <div>
                                <IconButton size="small" onClick={() => {
                                    markRead.callback(l.id);
                                }}>
                                    <MarkEmailRead />
                                </IconButton>
                            </div>}
                            {l.url && <div>
                                <Link to={l.url} style={{textDecoration: "none"}}>
                                    <IconButton size="small">
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Link>
                            </div>}
                        </MenuItem>
                    ))}
                    {data.length === 0 && <div>
                        <Alert color="info">
                            No Tasks
                        </Alert>
                    </div>}
                   </>
                }
            </Loader>

        </Menu>
    )
}

export function createdAt(input: DateString) {
    const tm = moment(input);
    const sinceMs = Date.now() - tm.valueOf();
    if(sinceMs < 30 * 60 * 1000) {
        return "Created a few minutes ago";
    }

    if(tm.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
        return "Created today";
    }

    return "Created " + tm.diff(moment(), "days") + " days ago"
}

const Dot = styled("div")<{read: boolean}>(props => ({
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: props.read ? grey["400"] : props.theme.palette.primary.main,
}))
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useProjectId} from "../ProjectName";
import {useContext} from "react";
import {ChangesetContext} from "../changeset/ChangesetContext";
import {Alert} from "@mui/material";

export function WarnUnresolvedPropsedChanges() {
    const project = useProjectId();
    const lookup = useAsync2(input => api.projectChangeset.list(input), {
        project: project,
        status: "pending" as "pending",
    }, [project])
    const ctx = useContext(ChangesetContext)

    if(ctx.proposing) {
        return null;
    }

    const show = lookup.asList.length > 0;
    if(!show) return null;

    return (
        <Alert severity="warning">
            You have unresolved proposed changes. You should probably resolve those before releasing.
        </Alert>
    )
}
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogTitle, List, ListItemButton, ListItemIcon, ListItemText,
    MenuItem,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import React, {useRef, useState} from "react";
import {first, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useNavigate, useParams} from "react-router-dom";
import {TabWrapper} from "../../shopdrawing/release/Release";
import ClearIcon from '@mui/icons-material/Clear';
import {Select3} from "./Select3";

export function CreateAlternative(props: {
    onCancel(): void;
    onDone(): void;
}) {
    const project = useProjectId();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const onDoneRef = useSyncedRef(props.onDone)
    const nav = useNavigate();
    const projectRef = useSyncedRef(project);
    const params = useParams<{workspace: string}>();
    const paramsRef = useSyncedRef(params);

    const submit = useAsyncAction(async (input) => {
        const result = await api.quoteAlternatives.upsert(input)
        nav(`/project/${projectRef.current}/quote/${result.id}/${paramsRef.current.workspace}`);
        onDoneRef.current();
    }, []);

    const list = useAsync2((input) =>
        api.quoteAlternatives.list(input),
        {project: project}, [project]);

    const [tab, setTab] = useState("blank");
    const [from, setFrom] = useState<number[]>([]);
    const checkedRef = useRef<{[from: number]: {
        [key: string]: boolean
    }}>({})

    return (
        <Dialog open={true} onClose={props.onCancel}>
            <form style={{height: "100%", overflow: "hidden", display: "flex", flexDirection: "column"}} onSubmit={e => {
                e.preventDefault()

                if(tab === "mirror" && from.length === 0) {
                    alert("Please select at least one alternative to mirror")
                    return;
                }

                if(!name) {
                    return;
                }

                const fromList = from.map(fromId => {
                    let keys: string[] = [];
                    const subs = checkedRef.current[fromId];
                    for(let i in subs) {
                        if(subs[i] === true) {
                            keys.push(i);
                        }
                    }

                    return {
                        id: fromId,
                        sections: keys,
                    }
                });

                submit.callback({
                    project: project,
                    name: name,
                    from: fromList,
                    description: description,
                });
            }}>
                <DialogTitle>
                    Create Quote Alternative
                </DialogTitle>
                <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "hidden"}}>
                    <TabWrapper>
                        <Tabs value={tab} onChange={(e, value) => {
                            setTab(value)
                            setFrom([]);
                            checkedRef.current = {};
                        }}>
                            <Tab label="Blank Slate" value="blank" />
                            <Tab label="Mirror Existing" value="mirror" />
                            <Tab label="Merge Multiple" value="merge" />
                        </Tabs>
                    </TabWrapper>

                    <div style={{padding: 24, flex: 1, display: "flex", flexDirection: "column", overflow: "hidden"}}>
                        <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} />
                        <div style={{height: 8}} />
                        <TextField fullWidth label="Description"
                                   value={description}
                                   onChange={e => setDescription(e.target.value)}
                                   multiline
                                   rows="3"
                        />
                        <div style={{height: 16}} />

                        {tab === "blank" && <div>
                        </div>}
                        {tab === "mirror" && <Select3 fullWidth
                            label="Copy from"
                             value={from.length === 0 ? "-" : from[0].toString()}
                             onChange={e => {
                                 const fromId = parseInt(e.target.value as any, 10)
                                 setFrom([fromId])
                                 checkedRef.current[fromId] = {};
                                 sections.map(s => {checkedRef.current[fromId][s.key] = true});
                             }}>
                                <MenuItem value="-">Select item</MenuItem>
                            {list.result?.data.map(d =>
                                <MenuItem value={d.id.toString()} key={d.id.toString()}>{d.name}</MenuItem>)}
                        </Select3>}
                        {tab === "merge" && <>
                            <div style={{overflow: "auto", flex: 1}}>
                                {from.map(id => (
                                    <>
                                        <MenuItem key={id.toString()}>
                                            <ListItemText>{first(list.result?.data||[], l => l.id === id)?.name}</ListItemText>
                                            <ListItemIcon onClick={() => setFrom(old => old.filter(i => i !== id))}>
                                                <ClearIcon />
                                            </ListItemIcon>
                                        </MenuItem>
                                        <List dense>
                                            {sections.map(s => <>
                                                <ListItemButton key={s.key}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            size="small"
                                                            onChange={(e, checked) => {
                                                                if(!checkedRef.current[id]) {
                                                                    checkedRef.current[id] = {};
                                                                }

                                                                checkedRef.current[id][s.key] = checked;
                                                            }}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        style={{margin: 0, paddingRight: 16}}
                                                        primary={s.name}
                                                    />
                                                </ListItemButton>
                                            </>)}
                                        </List>
                                    </>
                                ))}
                            </div>
                            <div style={{height: 16}} />
                            <Select3 fullWidth label="Choose Alternative To Add"
                                    value="-"
                                    onChange={e => {
                                         const val = parseInt(e.target.value as any, 10);
                                         setFrom([...from, val]);
                                     }}>
                                <MenuItem value="-">Select item to add</MenuItem>
                                {list.result?.data.filter(d => from.indexOf(d.id) === -1)
                                    .map(d => <MenuItem value={d.id.toString()} key={d.id.toString()}>{d.name}</MenuItem>)}
                            </Select3>
                        </>}
                    </div>
                </div>
                <DialogActions>
                    {submit.LoadingElement}
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button color="primary" variant="outlined" type="submit">Create</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

const sections = [
    {name: "Div 10", key: "div10"},
    {name: "Openings", key: "openings"},
    {name: "Hardware", key: "hardware"},
    {name: "Misc", key: "misc"},
];
import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";
import {Cents} from "nate-react-api-helpers";
import {DateString} from "./Projects";

export class QuoteMiscPricing extends SubAPI {
    list(props: {
        project: number
        alternative: number;
        offset: number;
    }) {
        return this.fetcher.get<Paginated<QuoteMiscPrice>>("/api/quote-misc-prices", props)
    }

    upsert(props: Partial<QuoteMiscPrice>) {
        return this.fetcher.post<QuoteMiscPrice>("/api/quote-misc-price", props)
    }

    reOrder(props: {
        project: number;
        alternative?: number;
        idOrder: number[];
    }) {
        return this.fetcher.post("/api/quote-misc-price/reorder", props)
    }
}

export type QuoteMiscPrice = {
    id: number;
    project: number;
    quoteAlternative: number;

    name: string;
    publicNote: string;
    internalNote: string;

    markupPercent?: number;
    markupCents?: Cents;
    unitCostCents: Cents;
    extendedPrice: Cents;

    updatedAt: DateString;
    backup?: QuoteMiscPrice|null;
}
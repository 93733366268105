import {FreeCell} from "../../../../misc/scroller/Table";
import { groupTitle} from "./BeforeGroup";
import {IndexCounter} from "./IndexCounter";

export function PlainHeader(props: {
    name: string;
}) {
    const name = props.name.slice(props.name.lastIndexOf("/") + 1);
    const counter = new IndexCounter();

    return (
        <>
            <FreeCell index={counter.incrBy(2)} colSpan={2}>
                <div className={groupTitle}>
                    {name}
                </div>
            </FreeCell>
            <FreeCell index={counter.value} grow />
        </>
    )
}
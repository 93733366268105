import {User} from "../../api/Users";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    InputAdornment,
    TextField
} from "@mui/material";
import {useState} from "react";
import CopyAll from "@mui/icons-material/CopyAll"
import {useSnackbar} from "../../misc/Snackbar";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useSyncedRef} from "../../misc/SyncedRef";

export function SetPasswordModal(props: {
    row: User;
    onDone(): void;
}) {

    const [password, setPassword] = useState("")
    const onDoneRef = useSyncedRef(props.onDone);
    const action = useAsyncAction(async (input) => {
        await api.users.upsert(input)
        onDoneRef.current()
    }, []);
    const snack = useSnackbar();

    return (
        <Dialog open>
            <form onSubmit={async (e) => {
                e.preventDefault();
                action.callback(Object.assign({}, props.row, {password}));
            }}>
                <DialogTitle>
                    Set Password
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Setting the password for {props.row.name}
                    </DialogContentText>
                    <div style={{height: 8}} />
                    <TextField label="New Password" fullWidth
                               value={password}
                               onChange={e => setPassword(e.target.value)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                       <IconButton onClick={() => {
                                           navigator.clipboard.writeText(password)
                                           snack.success("Copied to clipboard");
                                       }}>
                                           <CopyAll />
                                       </IconButton>
                                   </InputAdornment>
                               }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {HardwareGroupWithContent, HwOpening} from "./Hardware";
import {EditHardwareGroupWithContent} from "../pages/project/shopdrawing/hardware/HwEditingContextProvider";
import {ShortCodeItem} from "./Library";

export type ProductReplacement = {
    fromProduct: number;
    toProduct: number;
}

export class QuoteHardware extends SubAPI {
    reSequence(input: {
        project: number;
        alternative: number;
    }) {
        return this.fetcher.post("/api/quote-hardware/re-sequence", input);
    }

    replaceProducts(input: {
        project: number;
        replacements: ProductReplacement[]
    }) {
        return this.fetcher.post("/api/quote-hardware/replace-product", input)
    }

    updateHardwareGroupLink(input: {
        opening: number;
        group: number;
        link: boolean
    }) {
        return this.fetcher.post("/api/quote-hardware/edit-opening-link", input)
    }

    listWithContents(input: {
        project: number
        alternative: number;
    } | {
        groups: number[];
    }) {
        return this.fetcher.get<HardwareGroupWithContent[]>("/api/quote-hardware/with-content", input)
    }

    list(input: {
        project: number;
        alternative: number;
        search?: string;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<HardwareGroup>>("/api/quote-hardware", input)
    }

    upsert(input: HardwareGroupUpsert) {
        return this.fetcher.post<HardwareGroupUpsertResponse>("/api/quote-hardware", input)
    }

    mergeCandidates(input: {
        project: number;
        alternative: number;
    }) {
        return this.fetcher.get<MergeCandidate[]>("/api/quote-hardware/merge-candidates", input)
    }

    merge(input: {
        project: number;
        alternative: number;
        groups: MergeGroup[];
    }) {
        return this.fetcher.post("/api/quote-hardware/merge", input)
    }
}

export type MergeGroup = {
    hwGroup: number;
    hwGroupName: string;
    shortCodeName: string;
    shortCode: number;
    openings: number[];
}

export type MergeCandidate = {
    id: number;
    openings: (HwOpening & {
        doorName: string;
        hwGroupName: string;
        hwGroupId: number;
        shortCodeName: string;
        shortCodeId: number;
    })[];

    shortCodeItems: {
        product: number;
        productName: string;
        productCode: string;
        productFinishName: string;
        productFinishCode: string;
        productCategoryName?: string;
        qty: number;
    }[]
}

export type PrepDetail = {
    name: string;
    value: string | null; // if set to null, falls back to pre-calculated value
    enabled: boolean
}

export type HardwareGroupUpsertResponse = {
    updated: true;
    id: number;
    name: string;
} | {
    updated: false;
    confirmShortcodeChanges: ConfirmShortcodeChange
}

export type ConfirmShortcodeChange = {
    existingId?: number;
    existingName?: string;
    existingModifiesGroupNames: string[] | null;
    requiredChanges: ShortcodeItemChange[] | null;

    exactMatches: {id: number; name: string;}[] | null;
}

export type ShortcodeItemChange = Omit<ShortCodeItem, "id"> & {
    id?: number;
    newQty: number;
}

export type HardwareGroupUpsert = {
    value: EditHardwareGroupWithContent

    createShortcode?: {
        name: string;
        description: string;
    };
    updateShortcode?: number;
    linkToShortcode?: number;
}

export type HardwareGroup = {
    id: number;
    name: string;
    project: number;
    prepConfig: PrepDetail[];
    quoteAlternative: number;
}
import {SubAPI} from "./SubAPI";

export class ProjectFiles extends SubAPI {
    list(props: {
        project: number;
        kind: ProjectFileKind
    }) {
        return this.fetcher.get<{
            files: ProjectFile[],
            directories: ProjectDir[];
        }>("/api/project-files", props)
    }

    upload(props: {
        project: number;
        file: File;
        kind: ProjectFileKind
        directory: number;
    }) {
        return this.fetcher.postFormData("/api/project-file/upload", {
            file: props.file,
            meta: JSON.stringify({
                project: props.project,
                kind: props.kind,
                directory: props.directory,
            })
        })
    }

    upsertDir(input: Partial<ProjectDir>) {
        return this.fetcher.post("/api/project-file/directory", input);
    }

    upsert(props: Partial<ProjectFile>) {
        return this.fetcher.post("/api/project-file", props);
    }

    downloadLink(id: number, kind?: "inline" | "attachment") {
        return "/api/project-file/download?id=" + id + "&kind="+ (kind || "attachment");
    }
}

export type ProjectFileKind = "contract" | "default";

export type ProjectFile = {
    id: number;
    name: string;
    directory: number;

    kind: ProjectFileKind;

    status: "pending" | "approved" | "not-set" | "rejected";
    needsApprovalFrom: string;
    approvedBy: string;

    createdAt: string;
    createdBy: number;
    createdByName: string;

    linkedChangeSet?: number;
}

export type ProjectDir = {
    id: number;
    name: string;
    kind: ProjectFileKind;
    project: number;
    archived: boolean;
    parent?: number;
}
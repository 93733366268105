import {Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../release/Release";
import React from "react";
import {PriceDetail} from "../pricing/UnPricedItems";
import {showNumber} from "../pricing/Pricing";

export const defaultDiv10Tab = "wr-accessory"
export type TDiv10Type = "corner-guard" | "locker" | "mailbox" | "wr-accessory" | "wr-partition"

export function Div10Tabs(props: {
    unpriced?: PriceDetail;
    tab: string;
    onChange(value: TDiv10Type): void;
}) {
    return (
        <TabWrapper>
            <Tabs value={props.tab} onChange={(e, value) => props.onChange(value)}>
                {div10TabKeys.map(k =>
                    <Tab key={k} label={<>{showNumber(Div10TypeToName(k), props.unpriced?.div10Detail[k].unpriced)}</>} value={k} />
                )}
            </Tabs>
        </TabWrapper>
    )
}

export const div10TabKeys: TDiv10Type[] = [
    "wr-accessory",
    "wr-partition",
    "locker",
    "mailbox",
    "corner-guard",
];

// keep in sync with product/main.go:Div10TypeToName
export function Div10TypeToName(input: string) {
    switch(input) {
        case "wr-accessory": return "W/R Accessories";
        case "wr-partition": return "W/R Partitions";
        case "locker": return "Lockers";
        case "mailbox": return "Mailboxes";
        case "corner-guard": return "Corner Guards";
        default:
            return "UNKNOWN";
    }
}
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";
import { pdfWrapper } from "./PDFGenerator";

export const PDFHelper = React.memo(function (props: {
  childToRender: any;
  showPopupBlockedModal: boolean;
  setChildRef(input: any): void;
  setShowPopupBlockedModal(value: boolean): void;
}) {
  const {
    childToRender,
    setChildRef,
    showPopupBlockedModal,
    setShowPopupBlockedModal,
  } = props;

  return (
    <>
      <div style={pdfWrapper}>
        {childToRender !== undefined &&
          React.cloneElement(childToRender, { ref: setChildRef })}
      </div>
      <Dialog open={showPopupBlockedModal}>
        <DialogTitle>Printing window blocked</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It looks like your browser or ad-blocker is preventing us from printing.{" "}
            You can fix this by clicking on the notice in the address bar or following one of the documentation links below .
          </DialogContentText>
          <DialogContentText>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://support.google.com/chromebook/answer/95472?co=GENIE.Platform%3DDesktop&hl=en"
            >
              Chrome Help
            </a>{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting#w_pop-up-blocker-settings"
            >
              Firefox Help
            </a>{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://answers.microsoft.com/en-us/edge/forum/all/block-or-allow-pop-ups-in-microsoft-edge-windows/353fd8f4-0998-40ee-a73f-f613ce59caaa"
            >
              Edge Help
            </a>{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPopupBlockedModal(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

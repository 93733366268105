import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useEffect, useState} from "react";
import {AutoComplete} from "../../shopdrawing/openings/AutoComplete";
import {Contact} from "../../../../api/Contacts";
import {EditContact} from "./EditContact";

export function ProjectDefaultContactEdit(props: {
    value: Project;
    onDone(): void;
}) {

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const contactRequest = useAsync2(async input => {
            if(!input.id) return null;
            return api.contacts.get(input);
        },
        {id: props.value.clientContact || 0},
        [props.value.clientContact]);

    useEffect(() => {
        if(contactRequest.result) {
            setContact(contactRequest.result);
        }
    }, [contactRequest.result])

    const [contact, setContact] = useState<Contact|null>(null);
    const [showEdit, setShowEdit] = useState(false);

    if(props.value.clientContact && !contact) {
        return <Dialog open>
            {contactRequest.LoadingOrErrorElement}
        </Dialog>
    }

    if(showEdit && contact) {
        return <EditContact value={contact} onDone={value => {
            setContact(value);
            setShowEdit(false);
        }} />
    }

    return (
        <Dialog open>
            <DialogTitle>Choose Client Contact</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />

                <AutoComplete<Contact>
                    label="Find Contact"
                    search={async input => {
                        const result = await api.contacts.list({
                            search: input,
                            company: props.value.client,
                        })

                        return result.data;
                    }}
                      value={contact}
                      equals={(a, b) => a.id === b.id}
                      makeLabel={a => a.name}
                      onSelect={setContact}
                      onCreate={async input => {
                          const value = await api.contacts.upsert({
                              name: input,
                              company: props.value.client,
                          })

                          setContact(value);
                          setShowEdit(true);
                      }}
                />

                {contact && <div style={{padding: 8}}>
                    <div>{contact.phone || <i>Missing phone</i>}</div>
                    <div>{contact.email || <i>Missing email</i>}</div>
                    <div>
                        <Button onClick={() => setShowEdit(true)}>Edit Details</Button>
                    </div>
                </div>}
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        clientContact: contact?.id || null,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
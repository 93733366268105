import {ProgressCallback} from "nate-react-api-helpers";
import {SubAPI} from "./SubAPI";
import {DateString} from "./Projects";

export class Extractor extends SubAPI {

    session(input: {
        file: File;
    }, onProgress: ProgressCallback) {
        return this.fetcher.postFormDataWithProgress<{
            id: number; // session id
        }>("/api/extractor/session", {
            file: input.file,
        }, onProgress);
    }

    sessionStatus(input: {
        id: number;
    }) {
        return this.fetcher.get<{
            id: number;
            ready: boolean;
            nPages: number;
            error?: string;
        }>("/api/extractor/session/status", input)
    }

    imageURL(input: {
        session: number;
        index: number;
    }) {
        const params = new URLSearchParams();
        params.append("session", input.session.toString());
        params.append("index", input.index.toString());
        return "/api/extractor/session/image?" + params.toString()
    }

    analyze(input: {
        session: number;
        pages: AnalyzePageInput[]
    }) {
        return this.fetcher.post<{
            id: string;
        }>("/api/extractor/analyze", input)
    }

    analyzeStatus(input: {id: string}) {
        return this.fetcher.get<{
            status: string;
            done: boolean;
            lastUpdated: DateString;
            sampleTable?: string[][]
            issues?: string[];
        }>("/api/extractor/analyze/status", input)
    }

    downloadURL(input: {
        id: string;
    }) {
        const params = new URLSearchParams();
        params.append("id", input.id);
        return "/api/extractor/analyze/result?" + params.toString();
    }
}

export type AnalyzePageInput = {
    pageIndex: number;
    rotation: 0 | 90 | 180 | 270;
    bbox: AnalyzeBBoxInput;
}

export type AnalyzeBBoxInput = {
    min: {
        x: number; // float 0..1
        y: number; // float 0..1
    },
    max: {
        x: number; // float 0..1
        y: number; // float 0..1
    }
}
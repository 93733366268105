import {CSSProperties, PropsWithChildren} from "react";

export function TableClickBoundary(props: PropsWithChildren<{
    style?: CSSProperties | undefined
    enabled?: boolean
}>) {
    const enabled = props.enabled !== false;
    return (
        <div data-ignore-clicks={enabled} onClick={e => {
            if(enabled) {
                e.stopPropagation();
            }
        }}>
            {props.children}
        </div>
    )
}
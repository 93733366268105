import { EventEmitter } from "nate-react-api-helpers";
import {createContext} from "react";

export type LoadingInfo = {
    loading: boolean,
    error: null | string
    loadingOrError: boolean;
}

export const ListContext = createContext({
    pageSize: 100,
    offset: 0,
    search: "",
    count: 0,
    data: [] as any[],
    loadingStatus: new EventEmitter<LoadingInfo>(),
    onReload: new EventEmitter<any>(),
    setOffset: (value: number) => {},
    setSearch: (value: string) => {},
    reload: () => {},
    showArchived: false,
    setShowArchived: (value: boolean) => {},
})
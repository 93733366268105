import React from 'react';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from './pages/NotFoundPage';
import { LoginPage } from './pages/login/LoginPage';
import { LoginRedirector } from './misc/LoginRedirector';
import { AuthProvider } from 'nate-react-api-helpers';
import {api} from "./api/API";
import {UsersPage} from "./pages/users/UsersPage";
import {ForgotPasswordPage} from "./pages/login/ForgotPasswordPage";
import {ResetPasswordPage} from "./pages/login/ResetPasswordPage";
import {ConfirmationProvider} from "./misc/Confirm";
import {Snackbar} from "./misc/Snackbar";
import {Redirect} from "./misc/Redirect";
import {ProjectList} from "./pages/project/ProjectList";
import {Project} from "./pages/project/Project";
import {ShopDrawing} from "./pages/project/shopdrawing/ShopDrawing";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {Warehouse} from "./pages/logistics/warehouse/Warehouse";
import {Deliveries} from "./pages/logistics/deliveries/Deliveries";
import {PrintManagerProvider} from "./misc/pdf/PrintManager";
import {LogisticsDashboard} from "./pages/logistics/dashboard/LogisticsDashboard";
import { RouteList } from './pages/logistics/driver/RouteList';
import { RouteStop } from './pages/logistics/driver/RouteStop';
import { RouteDetail } from './pages/logistics/driver/RouteDetail';
import {amber} from "@mui/material/colors";
import {Receiving} from "./pages/logistics/receiving/Receiving";
import { Damaged } from './pages/logistics/damaged/Damaged';
import { Delivery } from './pages/logistics/deliveries/Delivery';
import {PurchaseOrderList} from "./pages/logistics/purchasing/PurchaseOrderList";
import {PurchaseOrder} from "./pages/logistics/purchasing/PurchaseOrder";
import {RouteList as DesktopRouteList} from "./pages/logistics/routes/RouteList";
import {RouteDetail as DesktopRouteDetail} from "./pages/logistics/routes/RouteDetail";
import {ToSourcePopout} from "./pages/logistics/purchasing/PartsToSource";
import { PopoutContainer } from './misc/Container';
import { ToDeliverPopout } from './pages/logistics/deliveries/ToDeliverPopout';
import { ToMovePopout } from './pages/logistics/routes/ToMovePopout';
import {PayableList} from "./pages/finance/payables/PayableList";
import { Payable } from './pages/finance/payables/Payable';
import {Quote} from "./pages/project/quote/Quote";
import {ProductsAndManufacturers} from "./pages/settings/productsAndManufacturers/ProductsAndManufacturers";
import {RouteSuccess} from "./pages/logistics/driver/RouteSuccess";
import {Extractor} from "./pages/extractor/Extractor";
import {ClientList} from "./pages/clients/ClientList";
import {RedirectToAlternative} from "./pages/project/quote/alternative/RedirectToAlternative";
import {TestingBanner} from "./misc/TestingBanner";
import {ReviewPricing} from "./pages/project/shopdrawing/changeset/ReviewPricing";
import {PrintSubmissions} from "./pages/project/shopdrawing/changeset/PrintSubmissions";
import {ReceiveAcceptance} from "./pages/project/shopdrawing/changeset/ReceiveAcceptance";
import {SaveTrackingSession} from "./pages/project/shopdrawing/changeset/SaveTrackingSession";
import {WebSocketProvider} from "./misc/WebSocketProvider";
import {ShopDrawingChangePreview} from "./pages/project/shopdrawing/ShopDrawingChangePreview";
import {ReviewProducts} from "./pages/project/shopdrawing/releasedchange/ReviewProducts";
import {SummarizeChanges} from "./pages/project/shopdrawing/changeset/SummarizeChanges";
import {StartReviewReleasedChanges} from "./pages/project/shopdrawing/releasedchange/StartReviewReleasedChanges";
import {RunMfgProcess} from "./pages/manufacturing/RunMfgProcess";
import {Waste} from "./pages/logistics/waste/Waste";
import {StockCount} from "./pages/logistics/stockCount/StockCount";
import {StockCountReview} from "./pages/logistics/stockCount/StockCountReview";
import {StockCounts} from "./pages/logistics/stockCount/StockCounts";
import {RouteStopGroup} from "./pages/logistics/driver/RouteStopGroup";
import {Receive} from "./pages/logistics/receiving/Receive";

const authIgnore = [
    "/forgot-password",
    "/reset-password",
    "/login"
]

const theme = createTheme({
    palette: {
        secondary: amber,
    },
});

function App() {
  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
          <ThemeProvider theme={theme}>
              <AuthProvider onPing={() => api.users.ping()}>
                  <WebSocketProvider>
                <TestingBanner />
                <ConfirmationProvider>
                    <Snackbar timeout={10 * 1000}>
                            <PrintManagerProvider>
                            <LoginRedirector ignore={authIgnore} />
                            <Routes>
                                <Route index element={<Redirect to="/projects" />} />
                                <Route path="/users" element={<UsersPage />} />
                                <Route path="/projects" element={<ProjectList />} />
                                <Route path="/project/*">
                                    <Route path=":project" element={<Project />} />
                                    <Route path=":project/shop-drawing" element={<ShopDrawing />} />
                                    <Route path=":project/shop-drawing/changeset/:changesetId/">
                                        <Route path="review-pricing" element={<ReviewPricing />} />
                                        <Route path="summarize-changes" element={<SummarizeChanges />} />
                                        <Route path="save-session" element={<SaveTrackingSession />} />
                                        <Route path="print-submissions" element={<PrintSubmissions />} />
                                        <Route path="receive-acceptance" element={<ReceiveAcceptance />} />
                                        <Route path="preview" element={<ShopDrawingChangePreview />} />
                                        <Route path="preview/:workspace" element={<ShopDrawingChangePreview />} />
                                        <Route path="preview/:workspace/:subspace" element={<ShopDrawingChangePreview />} />
                                    </Route>
                                    <Route path=":project/shop-drawing/change-released/:changesetId/">
                                        <Route path="start" element={<StartReviewReleasedChanges />} />
                                        <Route path="review-products" element={<ReviewProducts />} />
                                    </Route>

                                    <Route path=":project/shop-drawing/:workspace" element={<ShopDrawing />} />
                                    <Route path=":project/shop-drawing/:workspace/:subspace" element={<ShopDrawing />} />
                                    <Route path=":project/quote" element={<RedirectToAlternative />} />
                                    <Route path=":project/quote/:alternative" element={<Quote />} />
                                    <Route path=":project/quote/:alternative/:workspace" element={<Quote />} />
                                    <Route path=":project/quote/:alternative/:workspace/:subspace" element={<Quote />} />
                                    <Route path="*" element={<NotFoundPage />} />
                                </Route>
                                <Route path="/customers" element={<ClientList />} />
                                <Route path="/finance/*">
                                    <Route path="payables" element={<PayableList />} />
                                    <Route path="payables/:id" element={<Payable />} />
                                    <Route path="*" element={<NotFoundPage />} />
                                </Route>
                                <Route path="/logistics/*">
                                    <Route index element={<LogisticsDashboard />} />
                                    <Route path="routes" element={<DesktopRouteList />} />
                                    <Route path="routes/to-move-popout" element={
                                        <PopoutContainer>
                                            <ToMovePopout />
                                        </PopoutContainer>
                                    } />
                                    <Route path="routes/:id" element={<DesktopRouteDetail />} />
                                    <Route path="purchasing" element={<PurchaseOrderList />} />
                                    <Route path="purchasing/to-source-popout" element={
                                        <PopoutContainer>
                                            <ToSourcePopout />
                                        </PopoutContainer>
                                    } />
                                    <Route path="purchasing/:id" element={<PurchaseOrder />} />
                                    <Route path="warehouse" element={<Warehouse />} />

                                    <Route path="stock-count/do/:id" element={<StockCount />} />
                                    <Route path="stock-count/review/:id" element={<StockCountReview />} />
                                    <Route path="stock-count/review" element={<StockCounts />} />

                                    <Route path="packing" element={<Deliveries />} />
                                    <Route path="packing/to-pack-popout" element={
                                        <PopoutContainer>
                                            <ToDeliverPopout />
                                        </PopoutContainer>
                                    } />
                                    <Route path="packing/:id" element={<Delivery />} />
                                    <Route path="driver" element={<RouteList />} />
                                    <Route path="driver/success" element={<RouteSuccess />} />
                                    <Route path="driver/:id" element={<RouteDetail />} />
                                    <Route path="driver/:id/:stopId" element={<RouteStop />} />
                                    <Route path="driver/:id/:stopId/:groupId" element={<RouteStopGroup />} />
                                    <Route path="receiving/:id" element={<Receive />} />
                                    <Route path="receiving" element={<Receiving />} />
                                    <Route path="damaged" element={<Damaged />} />
                                    <Route path="damaged/:id" element={<Damaged />} />
                                    <Route path="waste" element={<Waste />} />
                                    <Route path="waste/:id" element={<Waste />} />
                                    <Route path="*" element={<NotFoundPage />} />
                                </Route>
                                <Route path="/manufacturing/*">
                                    <Route path="process" element={<RunMfgProcess />} />
                                    <Route path="*" element={<Redirect to="process" />} />
                                </Route>
                                <Route path="/settings/*">
                                    <Route path="products-and-manufacturers" element={<ProductsAndManufacturers />} />
                                    <Route path="*" element={<Redirect to="products-and-manufacturers" />} />
                                </Route>
                                <Route path="/extractor" element={<Extractor />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                <Route path="/reset-password" element={<ResetPasswordPage />} />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                            </PrintManagerProvider>
                    </Snackbar>
                </ConfirmationProvider>
                  </WebSocketProvider>
            </AuthProvider>
          </ThemeProvider>
      </LocalizationProvider>
  );
}

export default App;

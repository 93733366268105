import { Grid } from "@mui/material";
import {DashCard} from "./DashCard";
import {green, grey, orange} from "@mui/material/colors";
import { useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward"

export function SummaryNumbers() {
    const data = useAsync2(() => api.logistics.dashSummary(), {}, []);

    return (
        <DashCard xs={4}>
            <div style={{padding: 8}}>
                <Loader {...data}>
                    {data => <Grid container spacing={1}>
                        <Stat name="To Order" value={data.qtyToOrder} />
                        <Stat name="Active Orders" value={data.activeOrderCount} />
                        <Stat name="Needs Restock" value={data.qtyToRestock} />
                        <StatVersus name="Ordered (7d)" value={data.centsOrdered7d} versus={data.centsOrdered7dPrev} />
                        <StatVersus name="Picked (7d)" value={data.centsPicked7d} versus={data.centsPicked7dPrev} />
                        <StatVersus name="Delivered (7d)" value={data.centsDelivered7d} versus={data.centsDelivered7dPrev} />
                    </Grid>}
                </Loader>
            </div>
        </DashCard>
    )
}

export function StatVersus(props: {
    name: string;
    value: number;
    versus: number;
}) {
    let percent = (props.value - props.versus) / ifZero(props.value, 1) * 100;
    percent = Math.round(percent);

    let value = "";
    let isUp = percent > 0;

    if(percent >= 1000) {
        value = "∞";
    } else if(percent <= -1000) {
        value = "∞";
    } else {
        value = percent.toFixed(0) + "%";
    }

    return <Stat name={props.name} value={<div style={{display: "flex"}}>
        <div style={{alignSelf: "flex-start", fontSize: "0.8rem"}}>$</div>
        <div style={{lineHeight: "1em"}}>{toSI(props.value/100)}</div>

        <div style={{
            color: isUp ? green["800"] : orange["800"],
            fontSize: "0.8rem",
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
            paddingLeft: 8,
        }}>
            <div style={{height: "1.2em"}}>{isUp ? <ArrowUpward fontSize="inherit" color="inherit" /> : <ArrowDownward fontSize="inherit" color="inherit" />}</div>
            <div style={{paddingLeft: 1}}>{value}</div>
        </div>
    </div>} />
}

function toSI(value: number) {
    let str = value.toFixed(0);

    const format = function (count: number) {
        if(count === 0) return str;
        str = str.slice(0, -count + 1);
        if(str[str.length-1] === '0') {
            return str.slice(0,-1)
        } else {
            return str.slice(0,-1) + "." + str[str.length-1]
        }
    }

    switch(Math.floor((str.length - 1) / 3)) {
        case 0:
            return format(0);
        case 1:
            return format(3) + "k";
        case 2:
            return format(6) + "m";
        case 3:
            return format(9) + "g";
    }
}

function ifZero(value: number, defaultValue: number) {
    if(value === 0) return defaultValue;
    return value;
}

function Stat(props: {
    name: string;
    value: any;
}) {
    return (
        <Grid item xs={4} style={{display: "flex", justifyContent: "flex-start"}}>
            <div style={{display: 'inline-block'}}>
                <div style={{fontSize: "0.8rem"}}>
                    {props.name}
                </div>
                <div style={{fontSize: "1.5rem", textAlign: "right", color: grey["800"]}}>
                    {props.value}
                </div>
            </div>
        </Grid>
    )
}
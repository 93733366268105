import {ProjectFileSystem} from "./FileColumns";
import {css} from "@emotion/css";
import {Button, LinearProgress} from "@mui/material";
import React, {useMemo, useState} from "react";
import {ProjectDir, ProjectFile, ProjectFileKind} from "../../../../api/ProjectFiles";
import {blue} from "@mui/material/colors";
import clsx from "clsx";
import {CreateDir} from "./CreateDir";
import {api} from "../../../../api/API";
import {useSnackbar} from "../../../../misc/Snackbar";
import {isProjectFile} from "./Rename";
import {FileItem} from "./FileItem";
export function FileColumn(props: {
    value: ProjectDir
    selected: ProjectDir[];
    selectedFile: ProjectFile|null;
    content: ProjectFileSystem;
    project: number;
    kind: ProjectFileKind;
    onChange(selectId?: number): void;
    onDirSelect(dir: ProjectDir): void;
    onFileSelect(file: ProjectFile): void;
}) {

    const [showCreate, setShowCreate] = useState(false);
    const list = useMemo(() => {
        return [
            ...props.content.directories
            .filter(d => d.parent === props.value.id),
            ...props.content.files
            .filter(d => d.directory === props.value.id)
        ] as (ProjectDir|ProjectFile)[];
    }, [props.content, props.value])

    const [dragging, setDragging] = useState(false);
    const snack = useSnackbar();
    const [loading, setLoading] = useState(false);

    return (
        <div className={columnOuter}>
            {loading ? <div>
                <LinearProgress variant="indeterminate" />
            </div> :
            <div
                className={clsx({
                    [dragOverClass]: dragging,
                })}
                onDrop={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDragging(false);

                    const files = Array.from(e.dataTransfer.files);
                    if(files.length > 0) {
                        try {
                            setLoading(true);
                            snack.loading("Uploading...");
                            await Promise.all(files.map(f => api.projectFiles.upload({
                                project: props.project,
                                file: f,
                                kind: props.kind,
                                directory: props.value.id,
                            })));

                            snack.success("Uploaded!")
                        } catch (e: any) {
                            snack.error(e.toString())
                        }

                        props.onChange(props.value.id);
                        setLoading(false);
                        return;
                    }

                    try {
                        setLoading(true);
                        snack.loading("Moving...");

                        const value = e.dataTransfer.getData("application/json")
                        const obj = JSON.parse(value) as ProjectFile | ProjectDir;

                        if(isProjectFile(obj)) {
                            if(obj.directory !== props.value.id) {
                                await api.projectFiles.upsert(Object.assign(obj, {
                                    directory: props.value.id,
                                }))
                            }
                        } else {
                            if(obj.parent !== props.value.id && obj.id !== props.value.id) {
                                await api.projectFiles.upsertDir(Object.assign(obj, {
                                    parent: props.value.id,
                                }))
                            }
                        }

                        snack.success("Updated")
                    } catch (e: any) {
                        snack.error(e.toString())
                    }

                    props.onChange(props.value.id);
                    setLoading(false);
                    return;
                }}
                onDragEnter={e => {
                    e.preventDefault()
                    setDragging(true);
                    console.log("drag-enter")
                    return true;
                }}
                onDragOver={e => {
                    e.preventDefault()
                    return true
                }}
                onDragLeave={e => {
                    setDragging(false);
                }}
                onDragEnd={() => {
                    setDragging(false);
                }}
            >
                {list.map(c =>
                  <FileItem key={c.id.toString()}
                            value={c}
                            onFileSelect={props.onFileSelect}
                            onDirSelect={props.onDirSelect}
                            selected={props.selected}
                            selectedFile={props.selectedFile}
                            onChange={props.onChange}
                  />
                )}
                {list.length === 0 && <div style={{textAlign: "center"}}>
                    No files here
                </div>}

                <div style={{flex: 1}} />

                <div style={{fontSize: "0.9rem", textAlign: "center"}}>Drop a file here to upload</div>
                <Button onClick={() => setShowCreate(true)} fullWidth size="small">Create Directory</Button>
                {showCreate && <CreateDir
                    project={props.project}
                    value={props.value}
                    onCancel={() => setShowCreate(false)}
                    onDone={() => {
                        setShowCreate(false)
                        props.onChange();
                    }} />}
            </div>}
        </div>
    )
}

const dragOverClass = css({
    border: "2px dashed " + blue["800"] + " !important",
})

export const columnOuter = css({
    borderRight: "1px solid",
    height: "100%",
    display: "flex",
    flexShrink: 0,
    width: 250,
    overflow: "auto",

    "& > div": {
        height: "100%",
        fontSize: "0.9rem",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        border: "2px solid transparent",
        borderRadius: 4,
    }
})
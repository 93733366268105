import {api} from "../../../../api/API";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {Project} from "../../../../api/Projects";
import {Loader} from "../../../../misc/Loader";
import {Contact} from "../../../../api/Contacts";
import {ProjectContact} from "./ProjectContact";
import {Button} from "@mui/material";
import {useState} from "react";
import {FindOrCreateContact} from "./FindOrCreateContact";

export function ProjectContacts(props: {
    project: Project;
}) {
    const projectId = useProjectId();
    const listRequest = useGetAll(input => api.contacts.list(input), {project: projectId}, [projectId])
    const [showCreate, setShowCreate] = useState(false);

    return (
        <div>
            <Loader {...listRequest}>
                {(list: Contact[]) => list.map(l => <ProjectContact
                    project={projectId}
                    value={l}
                    onReload={listRequest.reload}
                />)}
            </Loader>

            <div style={{height: 8}} />
            {showCreate && <FindOrCreateContact project={projectId} onDone={value => {
                setShowCreate(false);
                if(value) {
                    listRequest.reload();
                }
            }} />}
            <Button variant="outlined" size="small" fullWidth onClick={() => setShowCreate(true)}>Add Contact</Button>
        </div>
    )
}
import {Tooltip} from "@mui/material";
import {css} from "@emotion/css";
import {useState} from "react";
import {HingeAutoDimensionOverride} from "./HingeAutoDimensionOverride";

export function HingeAutoDimensionDisplay(props: {
    qtys: number[]
    overrideQty: number | null
    onOverride?(value: number | null): Promise<void>
}) {
    const [show, setShow] = useState(false);
    const overrideQty = props.overrideQty

    return (
        <>
            <Tooltip title={
                overrideQty !== null ? "Hinges auto-dimension overridden: " + overrideQty :
                "Hinges are auto-dimensioned based on door height"
            }>
                <button className={clickable} disabled={!props.onOverride} onClick={() => setShow(true)}>
                    {overrideQty !== null ? <>{overrideQty};</> : <>
                    {props.qtys.length === 0 ? "(auto)" : props.qtys.map(h => h.toString()).join(", ")}*
                    </>}
                </button>
            </Tooltip>
            {show && <HingeAutoDimensionOverride qtys={overrideQty !== null ? [overrideQty] : []} onClose={() => setShow(false)} onDone={props.onOverride || fallbackCallback} />}
        </>
    )
}

const fallbackCallback = (input: any) => Promise.reject(new Error("Missing callback"));

const clickable = css({
    background: "none",
    border: "none",
    cursor: "pointer",
    marginRight: -5,

    "&:hover": {
        textDecoration: "underline"
    }
})
import {Project} from "../../../../api/Projects";
import {Button, Card, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {green} from "@mui/material/colors";
import {css} from "@emotion/css";
import {ProjectContacts} from "./ProjectContacts";
import {ProjectContact} from "./ProjectContact";
import {ProjectContext} from "../../ProjectProvider";
import {AwardedProject} from "./AwardedProject";
import {ViewQuoteButton} from "./ViewQuoteButton";

export function ProjectLinks(props: {
    project: Project;
}) {
    const project = props.project;
    const ctx = useContext(ProjectContext);

    return (
        <Card style={{padding: 16, maxWidth: 300, flex: 1}}>
            <Grid container spacing={1} alignItems="center">
                {project.stage === "quote" && <>
                    <Grid item xs={12}>
                        <ViewQuoteButton project={props.project} />
                    </Grid>
                    <Grid item xs={12}>
                        <AwardedProject project={props.project} />
                    </Grid>
                </>}
                {project.stage === "in-progress" && <Grid item xs={12}>
                    <Link target="_blank" to={`/project/${project.id}/shop-drawing`} style={{textDecoration: "none"}}>
                        <Button size="large"
                                endIcon={<OpenInNewIcon />}
                                variant="contained"
                                fullWidth
                                style={{
                                    backgroundColor: green["700"],
                                }}>
                            View Shop Drawing
                        </Button>
                    </Link>
                </Grid>}
                <Grid item xs={12}>
                    <div className={titleLabelClass}>Contacts</div>
                    <ProjectContact
                        project={project.id}
                        value={project.clientContactName ? {
                            id: project.client,
                            name: project.clientContactName,
                            email: project.clientContactEmail,
                            phone: project.clientContactPhone,
                            archived: false,
                        } as any : undefined}
                        onReload={() => ctx.reload()}
                        isClientContact
                    />
                    <ProjectContacts project={project} />
                </Grid>
            </Grid>
        </Card>
    )
}

export const titleLabelClass = css({
    fontWeight: "bold",
    fontSize: "0.9rem",
    paddingBottom: 4,
})

export const badge = css({
    background: green["300"],
    padding: 0,
    display: "inline-block",
    paddingLeft: 8, paddingRight: 8,
    borderRadius: 2,
    opacity: "0.8",
    fontSize: "0.75rem",
})
import {ChangesetContainer} from "../changeset/ChangesetContainer";
import {ChangeReleasedReviewItem, InventoryItemUpdate, ReviewAction} from "../../../../api/ProjectChangeset";
import {statusWords} from "../openings/Openings";
import {api} from "../../../../api/API";
import {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ChangesetContext} from "../changeset/ChangesetContext";
import {Button, MenuItem, Select, Grid, TextField, Card} from "@mui/material";
import {orderByStrAscending, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useNavigate} from "react-router-dom";
import {useProjectId} from "../ProjectName";
import {green, grey, orange, yellow} from "@mui/material/colors";

export function ReviewProducts() {

    return (
        <ChangesetContainer stage={"review-products"} backgroundColor={grey["200"]}>
            <ReviewProductsInner />
        </ChangesetContainer>
    )
}

function ReviewProductsInner() {
    const changeCtx = useContext(ChangesetContext)
    const nav = useNavigate();

    const sessionIdRef = useRef(changeCtx.sessionId);
    sessionIdRef.current = changeCtx.sessionId;

    const projectId = useProjectId();

    const update = useAsyncAction(async (input: InventoryItemUpdate[]) => {
        await api.projectChangeset.updateChangedReleaseList({
            project: projectId,
            session: sessionIdRef.current || 0,
            list: input,
        });

        nav(`/project/${projectId}/shop-drawing/changeset/${sessionIdRef.current}/review-pricing`)
    }, []);

    const list = useAsync2((input) => {
        return api.projectChangeset.listChangeReleasedItems(input)
    }, {
        changeReleasedGroup: changeCtx.changeReleasedGroup || 0,
        project: projectId,
    }, [changeCtx.changeReleasedGroup, projectId])

    const filteredList = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const kinds: string[] = [];

        if(!!params.get("doors")) {
            kinds.push("door")
        }

        if(!!params.get("frames")) {
            kinds.push("frame", "frame-anchor")
        }

        if(!!params.get("hardware")) {
            kinds.push("hardware")
        }

        if(!!params.get("div10")) {
            kinds.push("div10")
        }

        const openingsStr = params.get("opening")
        let openingList: number[] = [];

        if(!!openingsStr) {
            openingList = openingsStr.split(",").map(v => parseInt(v, 10))
        }

        if(!list.result) return [];

        const filteredOld = list.result.oldItems.filter(v => {
            if(!kinds.includes(v.kind)) return false;
            if(openingList.length > 0 && !openingList.includes(v.opening)) return false;
            return true;
        })

        const filteredNew = list.result.newItems.filter(v => {
            if(!kinds.includes(v.kind)) return false;
            if(openingList.length > 0 && !openingList.includes(v.opening)) return false;
            return true;
        })

        const list2: GroupItem[] = [];

        filteredOld.map(o => {
            let op = list2.find(l => l.opening === o.opening);
            if(!op) {
                op = {
                    opening: o.opening,
                    openingName: o.openingName,
                    oldItems: [],
                    newItems: [],
                }

                list2.push(op)
            }

            op.oldItems.push(o);
        })

        filteredNew.map(o => {
            let op = list2.find(l => l.opening === o.opening);
            if(!op) {
                op = {
                    opening: o.opening,
                    openingName: o.openingName,
                    oldItems: [],
                    newItems: [],
                }

                list2.push(op)
            }

            op.newItems.push(o);
        })

        orderByStrAscending(list2, a => a.openingName);
        return list2;
    }, [list.result])

    const dataSetRef = useRef<InventoryItemUpdate[]>([]);
    const reviewItemCtx = useMemo(() => {
        return {
            updateContext: (id: number, action: ReviewAction, qty: string, otherItemIds: number[]) => {

                let qtyInt = parseInt(qty)
                if(isNaN(qtyInt)) qtyInt = 0;
                if(qtyInt < 0) qtyInt = 0;

                dataSetRef.current = dataSetRef.current.filter(v => v.id !== id);
                dataSetRef.current.push({
                    id,
                    action,
                    qty: qtyInt,
                    otherItems: otherItemIds,
                })
            },
        }
    }, [])

    if(!changeCtx.changeReleasedGroup) return null;

    return (
        <div style={{display: "flex", flexDirection: "column", flex: 1, overflow: "hidden"}}>
            {list.LoadingOrErrorElement}
            <div style={{display: "flex", justifyContent: "center", flex: 1, overflow: "hidden"}}>
                <Card style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                }}>
                    <ReviewItemContext.Provider value={reviewItemCtx}>
                        <div style={{flex: 1, overflow: 'auto'}}>
                            {filteredList.map(f => <ReviewItem value={f} />)}
                        </div>
                    </ReviewItemContext.Provider>
                    <div style={{
                        padding: 16,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <Button onClick={() => {
                            nav(`/project/${projectId}/shop-drawing/change-released/${sessionIdRef.current}/start`)
                        }}>Back</Button>
                        <Button color="primary" variant="contained" onClick={() => {
                            update.callback(dataSetRef.current)
                        }}>Submit</Button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

const ReviewItemContext = createContext({
    updateContext(id: number, action: ReviewAction, qty: string, otherItemIds: number[]) {
        console.error('invalid context')
    }
})

type GroupItem = {
    opening: number;
    openingName: string;
    oldItems: ChangeReleasedReviewItem[]
    newItems: ChangeReleasedReviewItem[];
}

function ReviewItem(props: {
    value: GroupItem;
}) {

    const doorOld = props.value.oldItems.filter(v => v.kind === "door")
    const doorNew = props.value.newItems.filter(v => v.kind === "door")
    const hasDoor = doorOld.length > 0 || doorNew.length > 0;

    const frameOld = props.value.oldItems.filter(v => v.kind === "frame" || v.kind === "frame-anchor")
    const frameNew = props.value.newItems.filter(v => v.kind === "frame" || v.kind === "frame-anchor")
    const hasFrame = frameOld.length > 0 || frameNew.length > 0;

    const hardwareOld = props.value.oldItems.filter(v => v.kind === "hardware")
    const hardwareNew = props.value.newItems.filter(v => v.kind === "hardware")
    const hasHardware = hardwareOld.length > 0 || hardwareNew.length > 0;

    return (
        <div style={{
            paddingBottom: 8,
            paddingTop: 8,
        }}>
            <div style={{fontWeight: "500",
                paddingLeft: 16,
                paddingRight: 16,
            }}>Opening {props.value.openingName}</div>

            {hasDoor && <div style={{display: "flex", alignItems: "stretch", width: "100%"}}>
                <div style={{backgroundColor: green["200"], position: "relative", width: 10}}></div>
                <div style={{
                    flex: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                }}>
                    {doorOld.map(d =>
                        <RowItem value={d} isNew={false} others={doorNew} />
                    )}
                    {doorNew.filter(d => !doorOld.find(v => v.productId === d.productId)).map(d =>
                        <RowItem value={d} isNew={true} others={doorOld} />
                    )}
                </div>
            </div>}
            {hasFrame && <div style={{display: "flex", alignItems: "stretch", width: "100%"}}>
                <div style={{backgroundColor: yellow["200"], position: "relative", width: 10}}></div>
                <div style={{
                    flex: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                }}>
                    {frameOld.map(d =>
                        <RowItem value={d} isNew={false} others={frameNew} />
                    )}
                    {frameNew.filter(d => !frameOld.find(v => v.productId === d.productId)).map(d =>
                        <RowItem value={d} isNew={true} others={frameOld} />
                    )}
                </div>
            </div>}
            {hasHardware && <div style={{display: "flex", alignItems: "stretch", width: "100%"}}>
                <div style={{backgroundColor: orange["200"], position: "relative", width: 10}}></div>
                <div style={{
                    flex: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                }}>
                    {hardwareOld.map(d =>
                        <RowItem value={d} isNew={false} others={hardwareNew} isHardware />
                    )}
                    {hardwareNew.filter(d => !hardwareOld.find(v => v.productId === d.productId && v.productCode === d.productCode)).map(d =>
                        <RowItem value={d} isNew={true} others={hardwareOld} isHardware />
                    )}
                </div>
            </div>}
        </div>
    )
}

function RowItem(props: {
    value: ChangeReleasedReviewItem;
    isNew: boolean;
    isHardware?: boolean;
    others: ChangeReleasedReviewItem[];
}) {
    const {updateContext} = useContext(ReviewItemContext)

    const d = props.value;
    let defaultActionName: ReviewAction = "keep"
    let defaultQty = "0"

    if(!d.released) {
        if(props.isNew) {
            defaultActionName = "keep"
        } else {
            defaultActionName = "accept-update";
        }
    } else if(!props.isNew) {
        const newQty = props.others.find(o => {
            if(props.isHardware) return o.name === d.name && o.productCode === d.productCode;
            return o.name === d.name
        })?.qty;

        defaultActionName = "remove";

        if(newQty !== undefined && newQty > d.qty) {
            defaultActionName = "order-more"
            defaultQty = (newQty - d.qty).toString();
        }
    }

    const [actionName, setActionName] = useState<ReviewAction>(defaultActionName);
    const [qty, setQty] = useState(defaultQty);

    const valueName = props.value.name;
    useEffect(() => {
        updateContext(d.id, actionName, qty, props.others.filter(o => o.name === valueName).map(o => o.id))
    }, [qty, actionName, d.id, valueName, props.others, updateContext]);

    return (
        <div style={{paddingBottom: 6}}>
            <Grid container spacing={1}>
                <Grid item xs style={{minWidth: 300}}>
                    <div>{d.name}</div>
                    <div>{props.isNew ? "New" : statusWords(d.status)} ({d.qty})</div>
                </Grid>
                {actionName === "order-more" && <Grid item>
                    <TextField label="Qty" size="small" style={{width: 100}} value={qty} onChange={e => {
                        setActionName("order-more");
                        setQty(e.target.value);
                    }} />
                </Grid>}
                <Grid item>
                    <Select value={actionName} size="small" renderValue={v => {
                        switch(v) {
                            case "remove":
                                if(d.released) {
                                    return "Leave as-is"
                                } else if(props.isNew) {
                                    return "Ignore"
                                } else {
                                    return "Leave Original Item"
                                }
                            case "keep":
                                return "Order"
                            case "order-more":
                                return "Order # More"
                            case "not-needed":
                                return "Not Needed"
                            case "accept-update":
                                return "Use Modified"
                            default:
                                return v as any;
                        }
                    }} onChange={e => setActionName(e.target.value as any)}>
                        {d.released ?
                            props.isNew ? <MenuItem value="keep">
                                    <MyMenuItem title="Order" description="Queue this item to be released for purchasing" />
                                </MenuItem> :
                                    <MenuItem value="order-more">
                                        <MyMenuItem title="Order # More" description="Queue new replacement part(s) to be released for purchasing" />
                                    </MenuItem> : null}
                        {d.released && <MenuItem value="remove">
                            <MyMenuItem title="Leave as-is" description="Don't make any changes, allow product to continue through the process" />
                        </MenuItem>}
                        {d.released && !props.isNew && <MenuItem value="not-needed">
                            <MyMenuItem title="Not Needed" description="Flag this item to be put in the waste products workflow (see Project/Logistics tab)" />
                        </MenuItem>}
                        {!d.released ?
                            props.isNew ?
                                    <MenuItem value="keep">
                                        <MyMenuItem title="Order" description="Queue this item to be released for purchasing" />
                                    </MenuItem> :
                                    <MenuItem value="accept-update">
                                        <MyMenuItem title="Use Modified" description="Update queued product to use the changes made" />
                                    </MenuItem> : null}

                        {!d.released && <MenuItem value="remove">{
                            props.isNew ?
                                <MyMenuItem title="Ignore" description="Remove this item, we don't need it" />
                                :
                                <MyMenuItem title="Leave Original Item" description="Preserve the original item, don't remove or update it" />}</MenuItem>}
                    </Select>
                </Grid>

            </Grid>
        </div>
    )
}

function MyMenuItem(props: {
    title: string
    description: string;
}) {
    return (
        <div>
            <div>{props.title}</div>
            <div style={{color: grey["600"], fontSize: "0.8rem"}}>
                {props.description}
            </div>
        </div>
    )
}
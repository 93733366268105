import {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef} from "react";
import {EventEmitter} from "nate-react-api-helpers";

export const UndoContext = createContext({
    push: (undo: UndoCallback) => {
        console.error("invalid undo context")
    },
    onCanUndo: new EventEmitter<boolean>(),
    undo: () => {

    },
})

type UndoCallback = () => Promise<any>

export function UndoProvider(props: PropsWithChildren<any>) {
    const onCanUndo = useMemo(() => new EventEmitter<boolean>(), []);
    const undoListRef = useRef<UndoCallback[]>([]);
    const inProgressRef = useRef(false);

    const undo = useCallback(async () => {
        if(inProgressRef.current) return;

        const last = undoListRef.current.pop();
        if(undoListRef.current.length === 0) {
            onCanUndo.emit(false);
        }

        if(!last) {
            return;
        }

        inProgressRef.current = true;

        try {
            await last();
        } catch (e: any) {}

        inProgressRef.current = false;
    }, [onCanUndo]);

    useEffect(() => {
        const undoKeyCommand = (e: KeyboardEvent) => {
            if(e.key === "z" && (e.ctrlKey || e.metaKey)) {
                undo();
            }
        }

        document.body.addEventListener("keydown", undoKeyCommand)

        return () => {
            document.body.removeEventListener("keypress", undoKeyCommand)
        }
    }, [undo]);

    const push = useCallback((input: UndoCallback) => {
        undoListRef.current.push(input)
        if(undoListRef.current.length === 1) {
            onCanUndo.emit(true);
        }
    }, [onCanUndo, undoListRef])

    const ctx = useMemo(() => ({
        push: push,
        onCanUndo: onCanUndo,
        undo: undo,
    }), [onCanUndo, undo, push])

    return (
        <UndoContext.Provider value={ctx}>
            {props.children}
        </UndoContext.Provider>
    )
}
import React from "react";

type PDFSubstitutionType = "page-no" | "page-count";

export function getClassNameForSubstitutionType(type: PDFSubstitutionType) {
  return "pdf-substitute-" + type;
}

export function PDFSubstitution(props: { type: PDFSubstitutionType }) {
  return (
    <span className={getClassNameForSubstitutionType(props.type)}>&nbsp;</span>
  );
}

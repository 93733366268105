import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ChangesetContainer} from "./ChangesetContainer";
import {PricingGeneric} from "../pricing/Pricing";
import {Button, DialogActions} from "@mui/material";
import {useProjectId} from "../ProjectName";
import {useChangesetId} from "./ChangesetId";
import {ChangesetContext} from "./ChangesetContext";

export function ReviewPricing() {
    return (
        <ChangesetContainer stage="review-pricing">
            <ReviewPricingInner />
        </ChangesetContainer>
    )
}

export function ReviewPricingInner() {
    const nav = useNavigate();
    const projectId = useProjectId();
    const sessionId = useChangesetId();
    const [tab, setTab] = useState("summary");
    const [subTab, setSubTab] = useState("");
    const ctx = useContext(ChangesetContext);

    const projectInfo = useAsync2(input => api.projects.getShopDrawingMeta(input), {project: projectId}, [projectId]);

    useEffect(() => {
        if(projectInfo.result && projectInfo.result.changesetEnabled === false) {
            nav(`/project/${projectId}/shop-drawing`)
        }
    }, [projectInfo.result, nav, projectId])

    const deleter = useAsyncAction(async (input) => {
        await api.projectChangeset.cancel(input)
        nav(`/project/${projectId}/shop-drawing`);
    }, []);

    return (
        <>
            <PricingGeneric
                tab={tab} onTabChange={setTab}
                subTab={subTab} onSubTabChange={setSubTab}
            />
            <DialogActions>
                {!ctx.canChangeReleased ? <>
                    {deleter.error ? <Button color="error" onClick={() => {
                        deleter.callback({
                            project: projectId,
                            force: true,
                            ignoreValidation: true,
                        });
                    }}>Danger: Force Close</Button> : null}
                    <Button variant="text" color="error" onClick={async () => {
                        deleter.callback({
                            project: projectId,
                            force: true,
                        });
                    }}>Delete Session</Button>
                    {deleter.LoadingElement}
                </> : null}

                <div style={{flex: 1}} />
                {ctx.canChangeReleased ? <Button onClick={() => nav(`/project/${projectId}/shop-drawing/change-released/${sessionId}/review-products`)}>Back</Button> : <Button onClick={() => nav(`/project/${projectId}/shop-drawing`)}>Cancel</Button>}
                <Button variant="contained" color="primary" onClick={async () => {
                    nav(`/project/${projectId}/shop-drawing/changeset/${sessionId}/summarize-changes`)
                }}>Next</Button>
            </DialogActions>
        </>
    )
}
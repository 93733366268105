import {ShopContainer} from "../ShopContainer";
import {
    AdjustCol,
    CentsCol,
    DateCol,
    FreeCell,
    GroupProps,
    NumberCol,
    StringCol,
    Table,
} from "../../../../misc/scroller/Table";
import {api} from "../../../../api/API";
import {getAll} from "../openings/Openings";
import { useProjectId } from "../ProjectName";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {useContext, useState} from "react";
import {ManagerLockContext} from "../ProjectActions";
import { MiscProduct } from "../../../../api/MiscProducts";
import {EventEmitter, formatCents, sum} from "nate-react-api-helpers";
import {byFixedOrder, isNullOrUndefined, MarkupEditCell, parseMarkup, profit} from "../pricing/Pricing";
import {ProductLookup} from "./ProductLookup";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {OnClickParams, removeRowOption, RowOptionCol} from "../../../logistics/purchasing/RowOptions";
import {FilterContext} from "../../../../misc/scroller/Filter";
import {PriceLine} from "../../../../api/Pricing";
import {DimensionCol} from "./DimensionCol";
import {ManualDimension} from "./ManualDimension";
import {ListItemText, Typography} from "@mui/material";
import {ChangeHistory} from "../openings/ChangeHistory";
import {changeHistoryCircleNumber} from "../openings/RowActions";
import {ProductSearchResult} from "../../../../api/Products";
import {CostFocuser} from "../pricing/CostFocuser";
import {projectTablePrefName} from "../TablePrefName";

export function miscTableName(project: number) {
    return projectTablePrefName("project.misc", project)
}

export function Extra() {
    const project = useProjectId();

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    if(!u) return null;

    const tableName = miscTableName(project);

    return <ShopContainer name="Extra Products">
        <div style={{
            flex: 1, display: "flex",
            flexDirection: "column",
            overflow: "auto"
        }}>
            <Table<MiscProduct>
                locked={locked}
                name={tableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            historyOption(dt),
                            removeRowOption(dt, {
                                refreshTables: [tableName],
                                confirm: true,
                            }),
                        ],
                    }),
                    StringCol("Product", "productName", 200),
                    DimensionCol<MiscProduct>("Dim.","dimensions"),
                    NumberCol("Qty", "qty"),
                    DateCol("Needed By", "neededBy", "MMM D, YYYY"),
                    AdjustCol(CentsCol("Cost (ea)", "unitCostCents", 80), {
                        showHintOnFocus: CostFocuser,
                    }),
                    {
                        name: "Total Cost",
                        render: dt => formatCents(dt.unitCostCents * dt.qty),
                        alignRight: true,
                        width: 80,
                    },
                    {
                        name: "Markup",
                        render: dt => {
                            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                            return "auto"
                        },
                        editable: {
                            type: "custom",
                            copy: dt => {
                                if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                                if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                                return "auto"
                            },
                            paste: (rw, value) => {
                                Object.assign(rw, parseMarkup(value))
                            },
                            render: MarkupEditCell,
                        },
                        alignRight: true,
                        width: 80,
                    },
                    CentsCol("Price", "extendedPrice", 70),
                    hasPermission(u, "CanViewProjectPricingProfit") && {
                        name: "Proj. Profit",
                        render: dt => {
                            return formatCents(profit(dt))
                        },
                        alignRight: true,
                        width: 90,
                    },
                    hasPermission(u, "CanViewProjectPricingProfit") && {
                        name: "Act. Profit",
                        render: dt => {
                            if(isNullOrUndefined(dt.revenueDelivered) || isNullOrUndefined(dt.costDelivered)) return "";
                            return formatCents(dt.revenueDelivered - dt.costDelivered);
                        },
                        alignRight: true,
                        width: 90,
                    },
                    {
                        name: "Proj. Margin",
                        render: dt => {
                            let profit = dt.extendedPrice - dt.unitCostCents * dt.qty;
                            if(profit === 0) return "0%"
                            let margin = Math.round(profit / dt.extendedPrice * 100);
                            return margin + "%"
                        },
                        alignRight: true,
                        width: 100,
                    },
                    {
                        name: "Suggested Markup",
                        render: dt => {
                            return "35%"
                        },
                        alignRight: true,
                        width: 150,
                    },
                ]}
                fetch={ctx => getAll(ctx, offset => api.miscProducts.list({project: project, offset}))}
                fetchDeps={[project]}
                onChange={(input) => api.miscProducts.upsert(input)}
                groupBy={{
                    fixedGroups: ["main"],
                    groupFx: row => "main",
                    groupSorter: byFixedOrder(["main"]),
                    beforeGroup: BeforeGroup,
                    afterGroup: AfterGroup,
                }}
            />
        </div>
    </ShopContainer>
}

function historyOption(input: MiscProduct) {
    return {
        onClick: (ctrl: OnClickParams<MiscProduct>) => {
            ctrl.display(onDone => {
                return <ChangeHistory miscProduct={{
                    id: input.id,
                    name: input.productName,
                }} onDone={onDone} />
            })
        },
        name: (<>
            <ListItemText>Show Change History</ListItemText>
            {input.historyCount > 0 && <Typography variant="body2" color="text.secondary"
                                                   className={changeHistoryCircleNumber}>
                {input.historyCount}
            </Typography>}
        </>),
    }
}

function BeforeGroup(props: GroupProps<MiscProduct>) {
    const tbl = useContext(EditContext);
    const project = useProjectId();
    const [showManualDimensions, setShowManualDimensions] = useState<MiscProduct>();

    return (
        <>
            <FreeCell index={0} />
            <FreeCell index={1} grow noPadding>
                {showManualDimensions && <ManualDimension
                    value={showManualDimensions}
                    onCancel={() => setShowManualDimensions(undefined)}
                    onDone={async v => {
                        await tbl.update(v)
                        setShowManualDimensions(undefined);
                    }}
                />}
                <ProductLookup onSelect={async (v: ProductSearchResult) => {
                    let obj: MiscProduct = {
                        id: -1,
                        product: v.id,
                        productName: v.name,
                        dimensions: "",
                        dimWidth: "",
                        dimHeight: "",
                        dimLength: "",
                        qty: 1,
                        neededBy: undefined,
                        unitCostCents: v.lastUnitCostCents,
                        extendedPrice: -1,
                        markupPercent: 35,
                        markupCents: undefined,
                        revenueDelivered: -1,
                        costDelivered: -1,
                        project: project,
                        updatedAt: "2006-01-02T15:04:05Z", // choose a random time, backend will fill it in
                        updatedBy: -1,
                        historyCount: -1,
                    };

                    const rs = await tbl.update(obj)

                    if(v.dimHeightFormula || v.dimWidthFormula || v.dimHeightFormula) {
                        setShowManualDimensions(rs as any);
                    }
                }} />
            </FreeCell>
        </>
    )
}

const blankArray = [] as any;

function AfterGroup(props: GroupProps<MiscProduct>) {
    const tbl = useContext(FilterContext);
    const rows = (EventEmitter.reactValue(tbl.srcData) || blankArray) as PriceLine[];


    let profitValue = sum(rows.map(profit));
    const price = sum(rows.map(r => r.extendedPrice));
    let margin = profitValue === 0 ? 0 : Math.round(profitValue / price * 100);

    return (
        <>
            <FreeCell index={0} colSpan={6}>
            </FreeCell>
            <FreeCell index={6}>
                <div style={{textAlign: "right"}}>
                    {formatCents(sum(props.rows.map(r => r.unitCostCents * r.qty)))}
                </div>
            </FreeCell>
            <FreeCell index={7}></FreeCell>
            <FreeCell index={8}>
                <div style={{textAlign: "right"}}>
                    {formatCents(sum(props.rows.map(r => r.extendedPrice)))}
                </div>
            </FreeCell>
            <FreeCell index={9}>
                <div style={{textAlign: "right"}}>
                    {formatCents(profitValue)}
                </div>
            </FreeCell>
            <FreeCell index={10}>
                <div style={{textAlign: "right"}}>
                    {formatCents(sum(props.rows.map(r => r.revenueDelivered - r.costDelivered)))}
                </div>
            </FreeCell>
            <FreeCell index={11}>
                <div style={{textAlign: "right"}}>
                    {margin}%
                </div>
            </FreeCell>
            <FreeCell index={12} grow></FreeCell>
        </>
    )
}
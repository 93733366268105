import React from "react";
import {NavItem, Outer, Workspace, WorkspaceNotch} from "../shopdrawing/ShopNav";

export const QuoteNav = React.memo(function ShopNav() {
    return <Outer>
        <Workspace>
            Workspace
            <WorkspaceNotch />
        </Workspace>
        <NavItem name="Openings" quote isIndex />
        <NavItem name="Hardware" quote />
        <NavItem name="Div 10" quote />
        <NavItem name="Misc" quote />
        <NavItem name="Pricing" quote />
    </Outer>
});
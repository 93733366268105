import {MetricParser} from "./MetricParser";
import {ImperialDistance} from "./ImperialDistance";

export class MetricDistance {
    mm: number;

    // // see dim/main.go
    static unitsPerMM = 640;

    constructor(mm: number) {
        this.mm = Math.round(mm);
    }

    toString(): string {
        return this.mm.toString() + " mm";
    }

    static parse(value: string): MetricDistance {
        const parsed = MetricParser.parse(value);
        return new MetricDistance(parsed.mm);
    }

    add(other: MetricDistance | ImperialDistance): MetricDistance {
        return MetricDistance.fromUniversal(other.universalValue() + this.universalValue());
    }

    universalValue() {
        return this.mm * MetricDistance.unitsPerMM
    }

    static fromUniversal(n: number) {
        return new MetricDistance(n / MetricDistance.unitsPerMM);
    }
}
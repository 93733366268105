import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import {useSnackAction} from "../../shopdrawing/hardware/SnackAction";
import {useHwAPI} from "../../shopdrawing/hardware/HardwareGeneric";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useReloader} from "../../shopdrawing/hardware/ReloadContext";
import {useAlternative} from "../alternative/Alternative";
import CachedIcon from '@mui/icons-material/Cached';

export function HardwareReSequence() {

    const hwapi = useHwAPI();
    const project = useProjectId();
    const reload = useReloader();
    const alternative = useAlternative();

    const action = useSnackAction(async (input: {
        project: number;
        alternative?: number;
    }) => {
        await hwapi.reSequence(input as any)
        reload.current();
    }, {
        success: "Updated sequence",
    })

    return (
        <SmallGreyButton startIcon={<CachedIcon />} onClick={() => action.callback({
            project: project,
            alternative: alternative,
        })}>Resequence</SmallGreyButton>
    )
}
import {useUser} from "../../../../misc/Permission";
import React, {useContext, useState} from "react";
import {PendingHardware} from "./PendingHardware";
import {WorkingHardware} from "./WorkingHardware";
import {cellInActive} from "../pricing/cellconst";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {HardwareViewModeContext} from "../../quote/hardware/HardwareViewMode";

export const colCount = 12;
export const indentWidth = 30;

export function HardwareTable() {

    const viewMode = useContext(HardwareViewModeContext).viewMode;
    const previewChangeset = useContext(ChangePreviewContext);
    const [hidePending, setHidePending] = useState(false);
    const u = useUser();

    if(!u) return null;

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", backgroundColor: cellInActive}}>
            <WorkingHardware onFilter={setHidePending} />
            {hidePending || previewChangeset.enabled || viewMode !== "default" ? null : <PendingHardware />}
        </div>
    )
}
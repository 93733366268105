import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List} from "@mui/material";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import {ColumnConfigByName} from "../../api/TableConfig";
import {LookupConfigByName, sortColumns, TablePersonalizeContext} from "./TablePersonalize";
import {Column} from "./Table";
import {CustomizeColumnsCard} from "./CustomizeColumnsCard";

export function CustomizeColumns(props: {
    columns: Column<any>[];
    hasGlobal: boolean
}) {
    const [show, setShow] = useState(false);
    const {showCustomizeColumns, onChange, ...otherCtx} = useContext(TablePersonalizeContext);

    useEffect(() => {
        showCustomizeColumns.current = () => setShow(true)
        return () => {
            showCustomizeColumns.current = () => {};
        }
    }, [showCustomizeColumns]);

    const [checked, setChecked] = useState<{[columnName: string]: boolean}>({});


    const allColumns = useMemo(()=> cloneColumns(props.columns, otherCtx.columns, otherCtx.lookupByName), [otherCtx.columns, props.columns, otherCtx.lookupByName])

    const [columns, setColumns] = useState(allColumns.filter(v => !!v.name));

    const lookupByNameRef = useRef(otherCtx.lookupByName)
    lookupByNameRef.current = otherCtx.lookupByName;

    useEffect(() => {
        if(show) return;
        setColumns(allColumns.filter(v => !!v.name));
        setChecked(props.columns
            .reduce((acc, col) => {
                acc[col.name] = !lookupByNameRef.current(col.name, col).hidden;
                return acc;
            }, {} as {[k: string]: boolean}))
    }, [allColumns, show, props.columns])

    const [refreshKey, setRefreshKey] = useState(0)

    if(!show) return null;

    const update = (global: boolean) => {
        const byName: ColumnConfigByName = {};
        const blanks = allColumns.filter(v => !v.name)

        blanks.map((c, index) => {
            const col = props.columns.find(c2 => c2.name === c.name);
            byName[c.name] = Object.assign({}, otherCtx.lookupByName(c.name, col, index), {
                hidden: false,
                index: index,
            });
        })

        columns.map((c, index) =>{
            const col = props.columns.find(c2 => c2.name === c.name);
            index = blanks.length + index;

            byName[c.name] = Object.assign({}, otherCtx.lookupByName(c.name, col, index), {
                hidden: !checked[c.name],
                index: index,
            })
        })

        onChange({
            byName
        }, global)

        setShow(false);
    }

    return (
        <Dialog open onClose={() => setShow(false)}>
            <DialogTitle>
                Customize Columns
            </DialogTitle>
            <DialogContent>
                <DragDropContext key={refreshKey.toString()} onDragEnd={(input: DropResult) => {
                    if(!input.destination) return;

                    const list = columns.slice(0);
                    const [removed] = list.splice(input.source.index, 1);
                    list.splice(input.destination.index, 0, removed);

                    setColumns(list);
                    setRefreshKey(Date.now());
                }}>
                    <Droppable droppableId="droppable">
                        {(provided: any, snapshot: any) => (
                            <div {...provided.droppableProps}
                                ref={provided.innerRef}>

                                <List dense>
                                    {columns.map((c, index) => {
                                        return (
                                            <CustomizeColumnsCard
                                                key={c.name || "blank"}
                                                id={c.name || "blank"}
                                                index={index}
                                                hasBefore={index > 0}
                                                hasAfter={index !== columns.length-1}
                                                column={c}
                                                checked={!!checked[c.name]}
                                                onCheckToggle={() => {
                                                    setChecked(Object.assign({}, checked, {
                                                        [c.name]: !checked[c.name]
                                                    }))
                                                }}
                                            />
                                        )
                                    })}
                                </List>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    {props.hasGlobal && <Grid item>
                        <Button onClick={() => update(true)}>Save as Global Style</Button>
                    </Grid>}
                    <Grid item xs />
                    <Grid item>
                        <Button onClick={() => setShow(false)}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => {

                            const byName: ColumnConfigByName = {};

                            props.columns.map((c, index) =>{
                                byName[c.name] = Object.assign({}, otherCtx.lookupByName(c.name, c, index), {
                                    hidden: false,
                                    index: index,
                                    width: Math.max(c.width || 0, 50),
                                })
                            })

                            onChange({
                                byName,
                            })

                            setShow(false);
                        }}>Reset</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => update(false)}>Update</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}


function cloneColumns(columns: Column<any>[], cfg: ColumnConfigByName, lookupByName: LookupConfigByName) {

    const sorted = sortColumns(columns, cfg)
    return sorted.map(s => {
        return Object.assign({}, lookupByName(s.name, s), {
            name: s.name
        });
    })
}

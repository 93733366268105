import { ShopContainer } from "../ShopContainer";
import React from "react";
import {HardwareGeneric} from "./HardwareGeneric";
import {HardwareSwitcher} from "../../quote/hardware/HardwareSwitcher";
import {HardwareTemplateBtn} from "../../quote/hardware/HardwareTemplateBtn";
import {Grid} from "@mui/material";
import {HardwareScheduleBtn} from "../../quote/hardware/HardwareScheduleBtn";
import {HardwareDistributorOverride} from "./HardwareDistributorOverride";
import {HardwareReSequence} from "../../quote/hardware/HardwareReSequence";
import {HardwareViewMode, HardwareViewModeProvider} from "../../quote/hardware/HardwareViewMode";
import {MergeGroupsBtn} from "../../quote/hardware/MergeGroups";
import {EventEmitter} from "nate-react-api-helpers";
import {TransferProducts} from "../../quote/hardware/TransferProducts";

export const HwForceUpdate = new EventEmitter<number>()

export function Hardware() {
    const cacheBuster = EventEmitter.reactValue(HwForceUpdate)

    return (<HardwareViewModeProvider key={cacheBuster}>
        <ShopContainer name={<HardwareSwitcher />} actions={<Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
                <HardwareDistributorOverride />
            </Grid>
            <Grid item>
                <TransferProducts />
            </Grid>
            <Grid item>
                <HardwareScheduleBtn />
            </Grid>
            <Grid item>
                <HardwareTemplateBtn />
            </Grid>
            <Grid item>
                <HardwareReSequence />
            </Grid>
            <Grid item>
                <MergeGroupsBtn />
            </Grid>
            <Grid item>
                <HardwareViewMode />
            </Grid>
        </Grid>}>
            <HardwareGeneric />
        </ShopContainer>
    </HardwareViewModeProvider>);
}
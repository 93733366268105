import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, {useState} from "react";
import {IconButton, Popover} from "@mui/material";

export function PopoutMenu(props: {
    children: (onClose: () => void) => any
    disabled?: boolean;
    size?: "small"
}) {
    const [show, setShow] = useState<HTMLElement|null>(null);

    return (
        <>
            <IconButton disabled={props.disabled} size={props.size} onClick={e => setShow(e.currentTarget)}>
                <ArrowDropDownIcon />
            </IconButton>
            {show && <Popover open anchorEl={show} onClose={() => setShow(null)}>
                {props.children(() => setShow(null))}
            </Popover>}
        </>
    )
}
import {api} from "../../../../api/API";
import {FileLookup} from "./FileLookup";

export type FileItemSelection = {
    id: number;
    name: string;
    pages: string;
}

export function ProductCatalogLookup(props: {
    manufacturer?: number;
    category?: number;
    onDone(value: FileItemSelection | null): Promise<void>;
    value: FileItemSelection | null;
    name: string;
}) {
    return (
        <FileLookup
            title={"Select " + props.name}
            placeholder={props.name}
            fileLink={id => api.productCatalogs.fileLink(id, "inline")}
            uploadFx={input => {
                return api.productCatalogs.upload({
                    file: input.file,
                    manufacturer: input.manufacturer,
                });
            }}
            list={input => api.productCatalogs.list(input)}
            onDone={props.onDone}
            value={props.value}
            manufacturer={props.manufacturer}
            category={props.category}
        />
    )
}

import {PurchaseOrder} from "../../../api/Logistics";
import { useState} from "react";
import {
    Option, sortString,
    StringCol,
    Table,
    ViewOnly
} from "../../../misc/scroller/Table";
import {removeRowOption, RowOptionCol} from "../../logistics/purchasing/RowOptions";
import {formatCents} from "nate-react-api-helpers";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import {useNavigate} from "react-router-dom";
import {Container} from "../../../misc/Container";
import {WhiteColumn} from "../../logistics/purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {Grid} from "@mui/material";
import {ProjectFilter} from "../../logistics/purchasing/ProjectFilter";
import {SmallSearchBox} from "../../logistics/purchasing/SmallSearchBox";
import {grey, orange} from "@mui/material/colors";
import {CellWithBg} from "../../logistics/purchasing/CellWithBg";
import {ucWords} from "../../../misc/lists/ListCommon";

export const purchaseOrderTableName = "finance.payables";

export function PayableList() {
    const [search, setSearch] = useState("");
    const [project, setProject] = useState<Option|null>(null)
    const navigate = useNavigate();

    return <Container>
        <WhiteColumn>
            <SectionHeader name="Payables" right={
                <Grid container spacing={1} flexWrap="nowrap">
                    <Grid item>
                        <ProjectFilter value={project} onChange={setProject} />
                    </Grid>
                    <Grid item>
                        <SmallSearchBox value={search} onChange={setSearch} fullWidth />
                    </Grid>
                </Grid>
            } />

            <Table<PurchaseOrder>
                name={purchaseOrderTableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [purchaseOrderTableName],
                                confirm: true,
                            })
                        ],
                    }),
                    ViewOnly(StringCol("PO #", "poNumber", 100)),
                    {
                        name: "Status",
                        render: (dt, col) => {

                            let bgColor = "";
                            let color = "inherit"
                            switch (dt.payableStatus) {
                                default:
                                case "draft":
                                    bgColor = orange["400"];
                                    break;
                                case "paid":
                                    color = grey["400"];
                                    break;
                            }

                            return (
                                <CellWithBg color={color} backgroundColor={bgColor}>
                                    {ucWords(dt.payableStatus)}
                                </CellWithBg>
                            );
                        },
                        filter: (row, value) => {
                            value = value.toLowerCase();
                            return value.indexOf(row.payableStatus) !== -1
                        },
                        sort: sortString("status"),
                        width: 70,
                    },
                    ViewOnly(StringCol("Company", "supplierName", 100)),
                    {
                        name: "Amount",
                        render: dt => formatCents(dt.totalCents),
                        alignRight: true,
                        width: 70,
                        filter: (row, value) => {
                            return formatCents(row.totalCents).indexOf(value) === 0;
                        },
                    }
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listPurchaseOrders({
                    offset,
                    search,
                    forPayables: true,
                    project: project?.value || undefined,
                }))}
                onChange={input => api.logistics.updatePurchaseOrder(input)}
                fetchDeps={[search, project]}
                onRowClick={(item) => {
                    navigate("/finance/payables/" + item.id);
                }}
            />
        </WhiteColumn>
    </Container>
}
import {ToDeliverProps, toDeliverTableName} from "./ToDeliver";
import {useContext} from "react";
import {
    DateCol,
    Nullable,
    NumberCol,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../misc/scroller/Table";
import {SnackContext} from "../../../misc/Snackbar";
import {ToDeliverItem} from "../../../api/Deliveries";
import {ProjectCol} from "../purchasing/PartsToSource";
import {CheckboxSelectorCol} from "../../project/shopdrawing/release/Checkbox";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {AddCol} from "./Frame";

export function Door(props: ToDeliverProps) {
    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);

    return (
        <Table<ToDeliverItem>
            name={toDeliverTableName}
            overridePrefsName={toDeliverTableName + ".door"}
            columns={[
                props.delivery ? CheckboxSelectorCol({}) : null,
                ViewOnly(ProjectCol("Project", "projectName", 100)),
                ViewOnly(StringCol("Opening", "openingName", 100)),
                ViewOnly(StringCol("Floor", "openingFloor", 70)),
                ViewOnly(Nullable(DateCol("Due", "neededBy", "MMM D, YYYY"))),
                ViewOnly(StringCol("Series", "doorSeries", 70)),
                ViewOnly(StringCol("Material", "doorMaterial", 100)),
                ViewOnly(StringCol("Finish", "doorFinish", 100)),
                ViewOnly(StringCol("Gauge", "doorGauge", 70)),
                ViewOnly(StringCol("Elevation", "doorElevation", 70)),
                ViewOnly(StringCol("Core", "doorCore", 70)),
                ViewOnly(StringCol("Seam", "doorSeam", 70)),
                ViewOnly(StringCol("Handing", "doorHanding", 70)),
                ViewOnly(StringCol("Label", "doorLabel", 120)),
                ViewOnly(NumberCol("Qty", "qty", 60)),
                AddCol(props, {
                    ctx, snack
                }),
            ]}
            fetch={ctx => getAll(ctx, offset => api.deliveries.partsToDeliver({
                offset,
                project: props.project,
                type: "door",
            }))}
            fetchDeps={[props.project]}
        />
    )
}
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import {useReloader} from "./ReloadContext";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useHwAPI} from "./HardwareGeneric";
import {EditHardwareGroupWithContent} from "./HwEditingContextProvider";
import {HardwareGroupUpsert} from "../../../../api/QuoteHardware";

export function RenameHardwareGroup(props: {
    value: EditHardwareGroupWithContent;
    onClose(): void;
}) {
    const [value, setValue] = useState<string>(props.value.name || "");
    const reloadRef = useReloader();
    const onCloseRef = useSyncedRef(props.onClose);
    const hapi = useHwAPI();

    const update = useAsyncAction(async (input: HardwareGroupUpsert) => {
        await hapi.upsert(input)
        reloadRef.current();
        onCloseRef.current();
    }, [hapi]);

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault()
                update.callback({
                    value: Object.assign({}, props.value, {name: value})
                })
            }}>
                <DialogTitle>
                    Rename Hardware Heading
                </DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <TextField value={value} onChange={e => setValue(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    {update.LoadingElement}
                    <Button onClick={props.onClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
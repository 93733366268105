import {Company, CompanyKind} from "../../api/Companies";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import {ActionResult} from "../../misc/ActionResult";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useSyncedRef} from "../../misc/SyncedRef";
import {ProvinceSelector} from "./ProvinceSelector";

export function CreateClientDialog(props: {
    name: string;
    kind: CompanyKind;
    onDone(value: Company|null): void;
}) {
    const done = useSyncedRef(props.onDone);

    const action = useAsyncAction(async (input: Partial<Company>) => {
        const co = await api.companies.upsert(input)
        done.current(co);
    }, []);

    const [name, setName] = useState(props.name);
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [prov, setProv] = useState("");
    const [postal, setPostal] = useState("");

    return (
        <Dialog open onClose={() => props.onDone(null)}>
            <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation()
                action.callback({
                    name: name,
                    addressStreet: street,
                    addressCity: city,
                    addressProvince: prov,
                    addressPostal: postal,
                    kind: props.kind
                })
            }}>
                <DialogTitle>
                    Create Client
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextField label="Name" fullWidth
                                       value={name}
                                       onChange={e => setName(e.target.value)}
                                       style={{width: 300}}
                            />
                        </Grid>
                        <Grid item>
                            <TextField label="Street Address" fullWidth
                                       value={street}
                                       onChange={e => setStreet(e.target.value)}
                                       style={{width: 300}}
                            />
                        </Grid><Grid item>
                            <TextField label="City" fullWidth
                                       value={city}
                                       onChange={e => setCity(e.target.value)}
                                       style={{width: 300}}
                            />
                        </Grid><Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <TextField label="Postal" fullWidth
                                               value={postal}
                                               onChange={e => setPostal(e.target.value)}
                                               style={{width: 100}}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <ProvinceSelector value={prov} onChange={setProv} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <ActionResult action={action} />
                        </Grid>
                        <Grid item>
                            <Button onClick={() => props.onDone(null)}>Cancel</Button>
                            <Button type="submit">Create</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}
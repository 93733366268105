import {MenuItem, Select} from "@mui/material";
import {css} from "@emotion/css";
export type DistributionMode = "all" | "focused"

export function DistributionModeToggle(props: {
    value: DistributionMode;
    onChange: (value: DistributionMode) => void;
}) {
    return (
        <>
            <Select size="small" value={props.value} className={selectCls} onChange={e => {
                props.onChange(e.target.value as any)
            }}>
                <MenuItem value="focused">Focused</MenuItem>
                <MenuItem value="all">All</MenuItem>
            </Select>
        </>
    )
}

const selectCls = css({
    "& div.MuiInputBase-input": {
        minHeight: "0.2rem",
        paddingTop: 1,
        paddingBottom: 1,
        fontSize: "0.8rem",
    }
})
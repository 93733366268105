import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../misc/SyncedRef";


export function Archive(props: {
    onDone(): void;
    onArchive(): Promise<void>
}) {
    const archiveRef = useSyncedRef(props.onArchive);
    const doneRef = useSyncedRef(props.onDone);

    const action = useAsyncAction(async () => {
        await archiveRef.current();
        doneRef.current();
    }, []);

    return (
        <Dialog open>
            <DialogTitle>
                Confirm Deletion
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this? You cannot undo this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {action.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button color="error" onClick={() => action.callback(null)}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}
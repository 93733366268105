import {useCallback, useEffect, useRef} from "react";

type Timer = any;

export function useReadyCheck(onReady: () => void) {

    const list = useRef<boolean[]>([]);
    const timer = useRef<Timer|null>(null);
    const ready = useRef(false);
    const onReadyRef = useRef(onReady);
    onReadyRef.current = onReady;

    const id = useRef(Date.now() % 1000);

    const requiredMissing = useRef(false);
    requiredMissing.current = false;

    // reset the ready flag. doing another render means we have to start over
    ready.current = false;

    // clear timer if set
    if(timer.current !== null) {
        clearTimeout(timer.current as any);
        timer.current = null;
    }

    const hasPending = useCallback(() => {
        return list.current.filter(l => !l).length > 0
    }, []);

    let i = -1;
    const set = useCallback((index: number, value: boolean) => {
        console.log("use-ready", id.current, "set", index, value)

        if(ready.current) return;
        if(requiredMissing.current) return;

        list.current[index] = value;

        // if has pending items, stop here
        if(hasPending()) {
            if(timer.current) {
                clearTimeout(timer.current as any);
                timer.current = null;
            }

            return;
        }

        if(timer.current === null) {
            timer.current = setTimeout(() => {
                if(hasPending() || requiredMissing.current) {
                    timer.current = null;
                    return;
                }

                console.log("use-ready", id.current, "send-it!")
                ready.current = true;
                onReadyRef.current();
            }, 100);
        }

    }, [hasPending]);

    const section = () => {
        i++
        const sectionId = i;

        console.log("use-ready", id.current, "register-section", sectionId)
        list.current[sectionId] = false;

        return (tf: boolean = true) => {
            set(sectionId, tf);
        }
    };

    // mock a section finishing in case there are no sections
    const defaultSection = section();
    useEffect(() => {
        const tm = setTimeout(() => {
            defaultSection(true);
        }, 200);

        return () => clearTimeout(tm);
    }, [defaultSection])

    return {
        section: section,
        require: (tf: boolean) => {
            console.log("use-ready", id.current, "require", tf)
            if(!tf) requiredMissing.current = true;
        },
    }
}
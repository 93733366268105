import React from "react";
import {useNavigate} from "react-router-dom";
import {ChangesetContainer} from "./ChangesetContainer";
import {Button, DialogActions, Grid, IconButton} from "@mui/material";
import {useProjectId} from "../ProjectName";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {green} from "@mui/material/colors";
import {useChangesetId} from "./ChangesetId";
import {Center} from "./Center";

export function ReceiveAcceptance() {
    const nav = useNavigate();
    const projectId = useProjectId();
    const sessionId = useChangesetId();

    return (
        <ChangesetContainer stage="receive-acceptance">
            <Center>
                <Grid container spacing={2} flexWrap="nowrap">
                    <Grid item>
                        <IconButton style={{color: green["700"]}}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        Your proposal and related submittals have been tracked in the project files section under "Changes".
                        When you receive confirmation one way or another, you can mark the proposal as approved or rejected.
                        For now, we'll hide your proposed changes until you approve the proposal file.

                        <div style={{height: 8}} />
                        <img src="/images/project-proposal-approve-screenshot.png" style={{width: 400}} alt="Project Files Proposal Screenshot" />
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button onClick={() => nav(`/project/${projectId}/shop-drawing/changeset/${sessionId}/print-submissions`)}>Back</Button>
                    <Button variant="contained" color="primary" onClick={() => nav(`/project/${projectId}/`)}>Done</Button>
                </DialogActions>
            </Center>
        </ChangesetContainer>
    )

}
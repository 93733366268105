import {ProjectFile} from "../../../../api/ProjectFiles";
import React, {useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab, Tabs,
    TextField,
    Typography,
    Alert
} from "@mui/material";
import { css } from '@emotion/css'
import {blue} from "@mui/material/colors";

export function EditApprovals(props: {
    value: ProjectFile;
    onCancel(): void;
    onDone(): void;
}) {
    const [status, setStatus] = useState(props.value.status);
    const [responsible, setResponsible] = useState(() => {
        return status === "pending" ? props.value.needsApprovalFrom : props.value.approvedBy
    });

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projectFiles.upsert(input)
        onDoneRef.current();
    }, []);

    const locked = props.value.status === "approved" && !!props.value.linkedChangeSet;

    return (
        <Dialog open onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault()

                const obj: Partial<ProjectFile> = {
                    status: status as any,
                }

                if(status === "pending") {
                    obj.needsApprovalFrom = responsible;
                } else if(status === "approved") {
                    obj.approvedBy = responsible;
                }

                update.callback(Object.assign({}, props.value, obj))
            }}>
                <DialogTitle>
                    Update Approvals
                </DialogTitle>
                <DialogContent>

                    <div style={{height: 8}} />

                    <Typography variant="body2" style={{paddingLeft: 8, paddingBottom: 4}}>
                        Status
                    </Typography>
                    <Tabs className={tabs} value={status} onChange={(e, value) => {
                        if(locked) return;
                        setStatus(value)
                    }}>
                        <Tab disabled={locked} label="Unknown" value="not-set" />
                        <Tab disabled={locked} label="Needs Approval" value="pending" />
                        <Tab disabled={locked} label="Approved" value="approved" />
                        <Tab disabled={locked} label="Rejected" value="rejected" />
                    </Tabs>

                    <div style={{height: 16}} />

                    {status !== "not-set" &&
                        <TextField disabled={locked} fullWidth label={status === "pending" ?
                            "Need Approval From" :
                            status === "approved" ? "Approved By" : "Rejected By"
                        } value={responsible}
                               onChange={e => setResponsible(e.target.value)} />
                    }

                    {props.value.linkedChangeSet && <>
                        <div style={{height: 16}} />
                        {props.value.status === "approved" ? <>
                            <Alert severity="warning">You can't edit the approval status of this file because the linked changes cannot be undone.</Alert>
                        </> : (// @ts-ignore
                            props.value.status !== "approved" && status === "approved") ? <>
                            <Alert severity="warning">The linked changes will be applied to the project. This can not be undone.</Alert>
                        </> : status === "rejected" ? <>
                            <Alert severity="warning">This will also reject the linked changes. You can edit this later.</Alert>
                        </> : null}
                    </>}
                </DialogContent>
                <DialogActions>
                    {update.LoadingElement}
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button disabled={locked} type="submit">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

const tabs = css({
    "div&": {
        minHeight: "32px",
    },

    "& .MuiTab-root.Mui-selected": {
        backgroundColor: blue["700"],
        color: "white",
    },

    "& .MuiTab-root": {
        textTransform: "none",
        height: "auto",
        minHeight: "32px",
        border: "1px solid " + blue["700"],
        color: blue["700"],
        borderRadius: 4,
        marginRight: 4,
    },
    "& .MuiTabs-indicator": {
        display: "none",
    }
})
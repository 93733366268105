
export class IndexCounter {
    value: number;

    constructor() {
        this.value = 0;
    }

    incrBy(count: number) {
        const before = this.value;
        this.value += count;
        return before;
    }

    next() {
        return this.incrBy(1);
    }
}
import {useEffect, useRef} from "react";

export function useCropDrag(props: {
    imageRef: HTMLImageElement|null;
    enable: boolean;
    value: XY;
    onChange(value: XY): void;
}) {
    const onChangeRef = useRef(props.onChange);
    onChangeRef.current = props.onChange;
    const valueRef = useRef(props.value);
    valueRef.current = props.value;

    useEffect(() => {
        if(!props.enable) return;
        if(!props.imageRef) return;

        const img = props.imageRef;
        const wr = img.parentElement?.parentElement;
        if(!wr) return;

        const container = wr;

        let dragging = false;
        let start = {x: 0, y: 0};
        let startValue = {x: 0, y: 0};

        const onMouseDown = (e: MouseEvent) => {
            dragging = true;

            start = {
                x: e.clientX,
                y: e.clientY,
            }

            startValue = {
                x: valueRef.current.x,
                y: valueRef.current.y,
            }
        }

        const onMouseMove = (e: MouseEvent) => {
            if(!dragging) return;

            const deltaX = e.clientX - start.x;
            const deltaY = e.clientY - start.y;

            onChangeRef.current({
                x: startValue.x + deltaX,
                y: startValue.y + deltaY,
            })
        }

        const onMouseUp = (e: MouseEvent) => {
            console.log("mouse up")
            dragging = false;
        }

        img.addEventListener("mousedown", onMouseDown);
        container.addEventListener("mousemove", onMouseMove);
        container.addEventListener("mouseup", onMouseUp);

        return () => {
            img.removeEventListener("mousedown", onMouseDown);
            container.removeEventListener("mousemove", onMouseMove);
            container.removeEventListener("mouseup", onMouseUp);
        }
    }, [props.imageRef, props.enable])
}

type XY = {
    x: number;
    y: number;
}
import {Grid} from "@mui/material";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import React, {useContext} from "react";
import {PDFInfo, PrintManagerContext} from "../../../../misc/pdf/PrintManager";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {PricingPDF, PricingPDFInner} from "./PricingPDF";
import {api} from "../../../../api/API";
import {getAll} from "../openings/Openings";
import {useProposingKit} from "./ProposingKit";
import {Project} from "../../../../api/Projects";
import {ProjectContext} from "../../ProjectProvider";
import {PriceLine2} from "./Frames";
import {Company} from "../../../../api/Companies";
import {getContractor, transformMiscPricesForPDF} from "../changeset/PrintSubmissions";
import {PriceSectionNote} from "../../../../api/PriceSectionNotes";
import {getQuotePDF, Item, QuotePDFData} from "../../quote/pricing/QuotePDF";
import {Section} from "../../../../api/ProjectChangeset";
import {PriceRequestSubmittal} from "../../quote/pricing/PriceRequestSubmittal";
import {MiscPrice} from "../../../../api/MiscPricing";
import {PriceRider} from "../../../../api/Pricing";

export function PricingActions(props: {
    tab: string;
    subTab: string;
}) {
    const projectCtx = useContext(ProjectContext);
    const project = projectCtx.value;
    const printCtx = useContext(PrintManagerContext);
    const proposal = useProposingKit();

    return (
        <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item>
                <PriceRequestSubmittal overrideName="Export Summary" tab={props.tab} subTab={props.subTab} submissionTypeOverride="product-summary" />
            </Grid>
            <Grid item>
                <SmallGreyButton startIcon={<AddCircleIcon />} onClick={() => {

                    if(!proposal.proposing) {
                        printCtx.print2(getQuotePDF({
                            project: project.id,
                            data: async () => {
                                const listP = getAll(null, offset =>
                                    api.pricing.list({project: project.id, offset}))
                                const miscListP = getAll(null, offset =>
                                    api.miscPricing.list({project: project.id, offset}))

                                const list = await listP;
                                const miscList = await miscListP;
                                const contractor = getContractor(project)
                                const quoteNumber = api.projects.getProjectNumber({project: project.id});
                                const notesP = getAll(null, offset => api.priceSectionNotes.list({offset, project: project.id}))
                                const riders = getAll(null, offset => api.pricing.listRiders({ project: project.id, offset }))

                                let obj: QuotePDFData = {
                                    prices: list,
                                    miscPrices: miscList,
                                    project: project,
                                    contractor: await contractor,
                                    currentQuoteNumber: await quoteNumber,
                                    notes: await notesP,
                                    riders: await riders,
                                }

                                return obj;
                            },
                        }))

                        return;
                    }

                    printCtx.print2(getPricingPDF({
                        project: project,
                        data: async () => {
                            const listP = getAll(null, offset =>
                                    api.pricing.list({project: project.id, offset}))

                            const miscP = getAll(null, offset =>
                                    api.miscPricing.list({project: project.id, offset}))

                            const contractor = getContractor(project)
                            const quoteNumber = api.projects.getProjectNumber({project: project.id});
                            const notesP = getAll(null, offset => api.priceSectionNotes.list({offset, project: project.id}))
                            const riders = getAll(null, offset => api.pricing.listRiders({ project: project.id, offset }))

                            const misc = transformMiscPricesForPDF(await miscP);
                            const list = await listP;

                             return {
                                 data: proposal.transformRows(list as any, {
                                     printout: true,
                                 }),
                                 misc: misc,
                                 project: project,
                                 contractor: await contractor,
                                 currentQuoteNumber: await quoteNumber,
                                 notes: await notesP,
                                 riders: await riders,
                             }
                        },
                    }))
                }}>
                    Export Submittal
                </SmallGreyButton>
            </Grid>
        </Grid>
    )
}

export function getPricingPDF(props: {
    project: Project;
    data: () => Promise<{
        data: PriceLine2[]
        misc: MiscPrice[]
        project: Project;
        contractor: Company|null;
        currentQuoteNumber: string;
        notes: PriceSectionNote[];
        riders: PriceRider[];
    }>;
}): PDFInfo {
    return {
        name: `pricing.pdf`,
        render: async onReady => {
            const data = await props.data();
            return <PricingPDF data={data} onReady={onReady} />
        },
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "quote",
        }
    }
}

export function sectionToItem(sections: Section[]) {
    let data: Item[] = [];
    let lastGroup = "";
    let totalCents = 0;

    sections.map(s => {
        data.push({
            type: "title",
            value: s.name,
            group: s.name,
        })

        data.push({
            type: "footer",
            totalCents: s.amountCents,
            notes: s.description.split("\n"),
            group: s.name,
        })

        totalCents += s.amountCents
        lastGroup = s.name;
    })

    data.push({
        type: "total",
        totalCents: totalCents,
        group: lastGroup,
    })

    return data;
}

export function getPricingPDFCustom(props: {
    project: Project;
    data: () => Promise<{
        data: Item[]
        title: string;
        project: Project;
        contractor: Company|null;
        currentQuoteNumber: string;
    }>;
}): PDFInfo {
    return {
        name: `pricing.pdf`,
        render: async onReady => {
            const data = await props.data();
            return <PricingPDFInner data={data} onReady={onReady} />
        },
        serverInfo: {
            submissionForProject: props.project.id,
            submissionType: "quote",
        }
    }
}
import {FormControlLabel, Grid, Switch} from "@mui/material";
import { grey } from "@mui/material/colors";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useProjectId} from "./ProjectName";
import {ActionLoading, Loader} from "../../../misc/Loader";
import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {hasPermission, useUser} from "../../../misc/Permission";
import HistoryIcon from '@mui/icons-material/History';
import {SubmittalHistory} from "./SubmittalHistory";
import {SmallGreyButton} from "../../../misc/SmallGreyButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useSyncedRef} from "../../../misc/SyncedRef";
import {useNavigate} from "react-router-dom";
import {ChangesetContext} from "./changeset/ChangesetContext";
import {ChangePreviewContext} from "./ShopDrawingChangePreview";
import {SessionTakeover} from "./changeset/SessionTakeover";
import {MakeOpeningSizeSubmittal} from "./openingSizeSubmittal/MakeOpeningSizeSubmittal";

export const ManagerLockContext = createContext({
    locked: false,
    onChange: (value: boolean) => {}
})

export function ManagerLockProvider(props: PropsWithChildren<{}>) {
    const [locked, setLocked] = useState(false);

    const ctx = useMemo(() => ({
        locked: locked,
        onChange: setLocked,
    }), [locked]);

    return (
        <ManagerLockContext.Provider value={ctx}>
            {props.children}
        </ManagerLockContext.Provider>
    );
}

export type SubmittalCreator = (props: {onDone(): void}) => any;

export function ProjectActions(props: {
    submittalCreator?: SubmittalCreator
}) {
    const project = useProjectId();
    const info = useAsync2(input => api.projects.getShopDrawingMeta(input), {project: project}, [project]);

    const reloadRef = useSyncedRef(info.reload);
    const update = useAsyncAction(async (input) => {
        await api.projects.updateShopDrawingMeta(input);
        reloadRef.current();
    }, []);

    const ctx = useContext(ManagerLockContext);
    const managerLockChanged = ctx.onChange;
    const managerLockEnabled = info.result?.managerLockEnabled;
    useEffect(() => {
        if(managerLockEnabled === undefined) return;
        managerLockChanged(managerLockEnabled)
    }, [managerLockChanged, managerLockEnabled]);

    const u = useUser()

    const [showSubmittalHistory, setShowSubmittalHistory] = useState(false);
    const [showCreateSubmittal, setShowCreateSubmittal] = useState(false);
    const [showSessionTakeover, setShowSessionTakeover] = useState(false);

    const SubittalCreator = props.submittalCreator

    const nav = useNavigate();
    const changeset = useContext(ChangesetContext);
    const preview = useContext(ChangePreviewContext);

    if(preview.enabled) return null;

    const lockedToSomeoneElse = changeset.proposing && !changeset.isMine;
    return (
        <>
            {(!!SubittalCreator) && <Grid item>
                <SmallGreyButton onClick={() => setShowCreateSubmittal(true)} startIcon={<AddCircleIcon />}>
                    Create Submittal
                </SmallGreyButton>
                {showCreateSubmittal && <SubittalCreator onDone={() => setShowCreateSubmittal(false)} />}
            </Grid>}
            <Grid item>
                <MakeOpeningSizeSubmittal />
            </Grid>
            <Grid item>
               <SmallGreyButton onClick={() => setShowSubmittalHistory(true)} startIcon={<HistoryIcon />}>
                   Submittal History
               </SmallGreyButton>
                {showSubmittalHistory && <SubmittalHistory onClose={() => setShowSubmittalHistory(false)} />}
            </Grid>
            {showSessionTakeover && <SessionTakeover onClose={() => setShowSessionTakeover(false)} />}
            <Loader {...info}>
                {value => (
                    <>
                        <Grid item>
                            <ActionLoading {...update}>
                                <SwitchWithTopLabel disabled={!hasPermission(u, "EditShopDrawingWhenLockedToManager")} value={value.managerLockEnabled} label="Manager Lock" onChange={newValue => {
                                    update.callback(Object.assign({}, value, {
                                        managerLockEnabled: newValue,
                                    }))
                                }} />
                            </ActionLoading>
                        </Grid>
                        <Grid item>
                            {lockedToSomeoneElse ?
                                <SwitchWithTopLabel value={true} label={"Locked by " + changeset.lockedToName} onChange={() => {
                                    setShowSessionTakeover(true)
                                }} /> :
                                <SwitchWithTopLabel value={changeset.proposing} label={changeset.canChangeReleased ? "Change Released" : "Proposing Changes"} onChange={async (newValue) => {
                                    if(!newValue) {
                                        try {
                                            await api.projectChangeset.cancel({
                                                project: project,
                                                force: false,
                                            })
                                        } catch (e) {
                                            const info = await changeset.reload()
                                            const sessionId = info?.id
                                            const groupId = info?.changeReleasedGroup;

                                            if(sessionId) {
                                                if(groupId) {
                                                    nav(`/project/${project}/shop-drawing/change-released/${sessionId}/start`)
                                                } else {
                                                    nav(`/project/${project}/shop-drawing/changeset/${sessionId}/review-pricing`)
                                                }
                                            }
                                        }
                                        return;
                                    }

                                    await api.projectChangeset.start({project: project})
                                }} />}
                        </Grid>
                    </>
                )}
            </Loader>
        </>
    )
}

function SwitchWithTopLabel(props: {
    value: boolean;
    label: string;
    disabled?: boolean;
    onChange(value: boolean): void;
}) {
    return (
        <FormControlLabel
            style={{alignItems: "flex-start"}}
            labelPlacement="top"
            control={<Switch disabled={props.disabled} onChange={(e, checked) => props.onChange(checked)} size="small" checked={props.value} />}
            label={<div style={{color: grey["600"], fontSize: "0.8rem"}}>{props.label}</div>} />
    )
}
import {KeyName} from "../../../../api/Keying";
import {useState} from "react";
import {EditColumn} from "./EditColumn";
import {useTableRefresher} from "../../../../misc/scroller/Table";

export function EditableColHeader(props: {
    column: KeyName;
    tableName:string;
    onChange(): void;
}) {
    const [show, setShow] = useState(false);
    const refresh = useTableRefresher(props.tableName)

    return (
        <div onContextMenu={e => {
            e.preventDefault()
            e.stopPropagation()
            setShow(true)
        }}>
            {props.column.short}
            {show && <EditColumn value={props.column} onCancel={() => setShow(false)} onDone={() => {
                props.onChange();
                refresh();
                setShow(false);
            }} />}
        </div>
    );
}
import {useEffect, useRef} from "react";
import {Point, Rect} from "./Cropper";
import {rotatePoint, scalePoint} from "./CropLassoCreator";

export function useDragger(props: {
    lasso: HTMLDivElement | null;
    handle: HTMLDivElement | null;
    vertical: "top" | "bottom" | "both",
    horizontal: "left" | "right" | "both";
    value: Rect;
    rotation: number;
    zoom: number;
    disable?: boolean;
    onChange(value: Rect): void;
}) {

    const {lasso, handle, vertical, horizontal, disable} = props;
    const onChangeRef = useRef(props.onChange);
    onChangeRef.current = props.onChange;

    const rectRef = useRef(props.value);
    rectRef.current = props.value;

    useEffect(() => {
        if(!lasso) return;
        if(!handle) return;
        if(disable) return;

        let mouseStart: Point | null = null;
        let lassoStart: DOMRect | null = null;
        let lassoTopLeft: Point | null = null;

        const mouseMove = (e: MouseEvent) => {
            if(!mouseStart) return;
            if(!lassoStart) return;
            if(!lassoTopLeft) return;

            let shift = {
                x: e.clientX - mouseStart.x,
                y: e.clientY - mouseStart.y,
            }

            shift = rotatePoint(shift, {width: 0, height: 0}, props.rotation)
            shift = scalePoint(shift, props.zoom);

            if(vertical === "top") {
                lasso.style.top = (lassoTopLeft.y + shift.y) + "px";
                lasso.style.height = (lassoStart.height - shift.y) + "px";
            } else if(vertical === "bottom"){
                lasso.style.height = (lassoStart.height + shift.y) + "px";
            } else {
                lasso.style.top = (lassoTopLeft.y + shift.y) + "px";
            }

            if(horizontal === "left") {
                lasso.style.left = (lassoTopLeft.x + shift.x) + "px";
                lasso.style.width = (lassoStart.width - shift.x) + "px";
            } else if(horizontal === "right") {
                lasso.style.width = (lassoStart.width + shift.x) + "px";
            } else {
                lasso.style.left = (lassoTopLeft.x + shift.x) + "px";
            }
        }

        const mouseUp = (e: MouseEvent) => {
            if(!mouseStart) return;
            if(!lassoStart) return;

            let shift = {
                x: e.clientX - mouseStart.x,
                y: e.clientY - mouseStart.y,
            }

            shift = rotatePoint(shift, {width: 0, height: 0}, props.rotation)
            shift = scalePoint(shift, props.zoom);

            const rect = cloneRect(rectRef.current);

            if(vertical === "top") {
                rect.start.y += shift.y;
            } else if(vertical === "bottom") {
                rect.end.y += shift.y;
            } else {
                rect.start.y += shift.y;
                rect.end.y += shift.y;
            }

            if(horizontal === "left") {
                rect.start.x += shift.x;
            } else if(horizontal === "right") {
                rect.end.x += shift.x;
            } else {
                rect.start.x += shift.x;
                rect.end.x += shift.x;
            }

            mouseStart = null;
            lassoStart = null;
            onChangeRef.current(rect);
        }

        const mouseDown = (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();

            mouseStart = {
                x: e.clientX,
                y: e.clientY,
            }

            lassoStart = lasso.getBoundingClientRect();
            lassoTopLeft = {
                x: lasso.offsetLeft,
                y: lasso.offsetTop,
            }
        }

        const catchIt = (e: MouseEvent) => {
            e.stopPropagation()
        }

        document.addEventListener("mousemove", mouseMove)
        document.addEventListener("mouseup", mouseUp)
        handle.addEventListener("mousedown", mouseDown)
        handle.addEventListener("click", catchIt)

        return () => {
            document.removeEventListener("mousemove", mouseMove)
            document.removeEventListener("mouseup", mouseUp)
            handle.removeEventListener("mousedown", mouseDown)
            handle.removeEventListener("click", catchIt)
        }

    }, [lasso, handle, vertical, horizontal, disable, props.rotation, props.zoom]);
}

function cloneRect(rect: Rect): Rect {
    return {
        start: {
            x: rect.start.x,
            y: rect.start.y,
        },
        end: {
            x: rect.end.x,
            y: rect.end.y,
        },
        totalWidth: rect.totalWidth,
        totalHeight: rect.totalHeight,
    }
}
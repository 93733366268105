
export type SourceItem<T, U> = {
    type: U;
    index?: number;
    obj: T;
}

export type CombinedItem<T, U extends string> = {
    [K in U]: {
    fromIndex: number;
    toIndex: number;
    slice<V>(from: V[]): V[];
} | undefined
} & {
    obj: T;
}

export function rebuildSplitPrefItems<T, U extends string>(list: SourceItem<T, U>[]): CombinedItem<T, U>[] {

    let out: CombinedItem<T, U>[] = [];
    let current: CombinedItem<T, U> | null = null;
    list.map(l => {
        if(current) {
            if(current.obj !== l.obj) {
                current = null;
            }
        }

        if(!current) {
            // @ts-ignore
            current = {
                obj: l.obj,
            };

            out.push(current as any);
        }

        if(current === null) throw new Error("invalid state")

        let prop = current[l.type] as any;
        if(!prop) {
            prop = {
                fromIndex: l.index === undefined ? -1 : l.index,
                toIndex: l.index === undefined ? -1 : l.index,
                slice: (from: any[]) => {
                    return from.slice(prop!.fromIndex, prop!.toIndex + 1);
                }
            }

            current[l.type] = prop as any;
        }

        if(l.index !== undefined && prop.toIndex < l.index) {
            prop.toIndex = l.index;
        }
    })

    return out;
}

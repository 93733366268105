import {PropsWithChildren} from "react";
import {Grid} from "@mui/material";
import { css } from "@emotion/css";
import {grey} from "@mui/material/colors";

const card = css({
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "white",
    border: "1px solid " + grey["200"],
    height: "100%",
    display: "flex",
    flexDirection: "column"
})

export function DashCard(props: PropsWithChildren<{
    title?: string;
    xs: any;
    height?: any;
}>) {
    return (
        <Grid item xs={props.xs} style={{height: props.height}}>
            <div className={card}>
                {props.title && <div style={{fontSize: "0.8rem", paddingLeft: 8}}>{props.title}</div>}
                {props.children}
            </div>
        </Grid>
    )
}
import {Project} from "../../../../api/Projects";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {api} from "../../../../api/API";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import React, {useState} from "react";
import {FindOrCreateCompany} from "./FindOrCreateCompany";

export function ContractorEdit(props: {
    value: Project;
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async input => {
        await api.projects.upsert(input)
        onDoneRef.current();
    }, [])

    const [contractor, setContractor] = useState<null | {id: number; name: string;}>(props.value.contractor ? {
        id: props.value.contractor,
        name: props.value.contractorName || "",
    } : null);

    return (
        <Dialog open>
            <DialogTitle>Project Contractor</DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <FindOrCreateCompany
                    value={contractor}
                    kind={"contractor"}
                    label="General Contractor"
                    onChange={setContractor} />
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}

                <Button onClick={props.onDone}>Cancel</Button>
                <Button variant='contained' color="primary" onClick={() => {
                    update.callback(Object.assign({}, props.value, {
                        contractor: contractor?.id,
                    }));
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
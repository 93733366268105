import {ExtraOptions} from "./ShopTable";
import {CheckBoxHeader} from "./release/Checkbox";
import {useContext} from "react";
import {EditContext} from "../../../misc/scroller/EditProvider";

export function CheckboxCol<T>(misc?: ExtraOptions<T>) {
    return {
        name: "",
        key: "id" as any,
        buildFilter: (str: string) => {
            return () => true;
        },
        renderHeader: () => <CheckBoxHeader />,
        extra: Object.assign(misc || {}, {
            checkbox: true,
        }),
    }
};

export function Checkbox2Col<T>(name: string, key: keyof T, opts?: {
    disabled?: (input: T) => boolean
}) {
    return {
        name: name,
        key: key,
        width: 80,
        render: (data: T) => {
            return <CheckboxEl
                checked={!!data[key]}
                disabled={opts?.disabled?.(data)}
                onChange={checked => Object.assign({}, data, {
                    [key]: checked
                })} />
        },
        sort: (a: T, b: T) => {
            // @ts-ignore
            return a[key] === b[key] ? 0 : a[key] ? 1 : -1
        },
    }
}

export function CheckboxEl<T>(props: {
    checked: boolean;
    disabled?: boolean;
    onChange(checked: boolean): T
}) {
    const edit = useContext(EditContext);
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <input disabled={props.disabled} type="checkbox" checked={props.checked} onChange={e => {
                edit.update(props.onChange(e.currentTarget.checked))
            }} />
        </div>
    )
}

export function StringCol<T>(name: string, key: keyof T, misc?: ExtraOptions<T>) {
    return {
        name: name,
        key: key,
        buildFilter: (str: string) => {
            if(str === "") return () => true;
            return (obj: T) => (obj[key] as any as string).indexOf(str) !== -1;
        },
        extra: misc,
    }
}

export function NumberCol<T>(name: string, key: keyof T, misc?: ExtraOptions<T>) {
    return {
        name: name,
        key: key,
        buildFilter: (str: string) => {
            if(str === "") return () => true;

            const value = parseInt(str);
            return (obj: T) => parseInt(obj[key] as any as string) === value;
        },
        formatUpdate: (str: string) => {
            return parseInt(str)
        },
        extra: misc,
    }
}
import {blue} from "@mui/material/colors";
import {Loader} from "../../../../misc/Loader";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {api} from "../../../../api/API";
import {categoryButtonStyle} from "./ProductLookup";
import {Category} from "../../../../api/Products";
import {orderByAscending} from "nate-react-api-helpers";

export function CategoryList(props: {
    search: string;
    onSelect: (value?: number) => void;
    selected?: number;
}) {
    const categories = useGetAll((input) => api.products.categories(input), {}, []);

    return (
        <Loader {...categories}>
            {list => {
                if(props.search) {
                    list = list.filter((l: Category) => l.name.toLowerCase().indexOf(props.search.toLowerCase()) === 0)
                }

                orderByAscending(list, l => l.parentCategory === undefined ? 0 : 1)
                list = list.slice(0, 3)

                return <div style={{display: "flex"}}>
                    {list.map(c => <button key={c.id} onClick={() => props.onSelect(c.id)} className={categoryButtonStyle} style={{
                        color: props.selected === c.id ? blue["800"] : undefined,
                    }}>{c.name}</button>)}
                    <button style={{
                        color: props.selected === undefined ? blue["800"] : undefined,
                    }} onClick={() => props.onSelect(undefined)} className={categoryButtonStyle}>Any Category</button>
                </div>
            }}
        </Loader>
    )
}
import {DriverRoutePendingItem} from "../../../api/Logistics";
import {CheckboxSelectorCol} from "../../project/shopdrawing/release/Checkbox";
import {StringCol, Table, ViewOnly} from "../../../misc/scroller/Table";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import { QtyWithPreview } from "./QtyWithPreview";

export const dropTableName = "logistics.routes.drop";

export function DropTable(props: {
    route?: number;
}) {
    return (
        <Table<DriverRoutePendingItem>
            name={dropTableName}
            columns={[
                props.route ? CheckboxSelectorCol({}) : null,
                ViewOnly(StringCol("Project", "dropProjectName", 100)),
                {
                    name: "Pack #",
                    width: 100,
                    render: d => d.pickupDelivery ? "#" + d.pickupDelivery : null
                },
                {
                   name: "Items",
                   render: data => <QtyWithPreview data={data.contents} />,
                   width: 100,
                },
            ]}
            fetch={ctx => getAll(ctx, offset => api.logistics.listRoutableItems({
                offset,
                type: "drop",
            }))}
            fetchDeps={[]}
        />
    )
}
import {ShopContainer} from "../ShopContainer";
import {CheckboxProvider} from "./Checkbox";
import {ReleaseBox} from "./ReleaseBox";
import React, {useState} from "react";
import {useProjectId} from "../ProjectName";
import {styled, Tab, Tabs} from "@mui/material";
import {grey} from "@mui/material/colors";
import {HardwareTable} from "./HardwareTable";
import {FrameTable} from "./FrameTable";
import {DoorTable} from "./DoorTable";
import {useQueryParam} from "../../quote/pricing/QueryParam";
import {Div10Table} from "./Div10Table";
import {defaultDiv10Tab, Div10Tabs} from "../div10/Div10Tabs";
import {defaultDoorTab, DoorTabs} from "../../quote/pricing/DoorTabs";
import {WarnUnresolvedPropsedChanges} from "./WarnUnresolvedPropsedChanges";
import {WarnMissingScreenElevation} from "./WarnMissingScreenElevation";
import {FrameAnchorTable} from "./FrameAnchorTable";

export const TabWrapper = styled("div")(props => ({
    backgroundColor: grey["100"],
    borderBottom: "1px solid " + grey["300"],
    display: "flex",

    "& .MuiTabs-root": {
        minHeight: 20,
    },

    "& .MuiTab-root": {
        paddingTop: 4,
        paddingBottom: 4,
        minHeight: 20,
    }
}))

export function Release() {
    const project = useProjectId();
    const [key, setKey] = useState(Date.now().toString())
    const [tab, setTab] = useQueryParam("tab", "frame");
    const [tab2, setTab2] = useQueryParam("tab2", "");

    return (
        <ShopContainer name="Release" key={key}>
            <CheckboxProvider>
                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                }}>
                    <TabWrapper>
                        <Tabs value={tab} onChange={(e, value) => {
                            setTab(value)

                            switch(value) {
                                case "div10":
                                    setTab2(defaultDiv10Tab)
                                    break;
                                case "door":
                                    setTab2(defaultDoorTab)
                                    break;
                                default:
                                    setTab2("")
                                    break;
                            }
                        }}>
                            <Tab label="Frames" value="frame" />
                            <Tab label="Doors" value="door" />
                            <Tab label="Hardware" value="hardware" />
                            <Tab label="Div 10" value="div10" />
                            <Tab label="Anchors (only)" value="frame-anchor" />
                        </Tabs>
                    </TabWrapper>
                    {tab === "div10" && tab2 && <Div10Tabs tab={tab2} onChange={setTab2} />}
                    {tab === "door" && tab2 && <DoorTabs tab={tab2} onChange={setTab2} />}
                    <ReleaseBox
                        onUpdated={() => setKey(Date.now().toString())}
                        type={tab}
                    />
                    <WarnUnresolvedPropsedChanges />
                    {(tab === "door" || tab === "frame") && <WarnMissingScreenElevation />}

                    <div style={{flex: 1, display: "flex", overflow: "hidden"}}>
                        {tab === "frame" ? <FrameTable project={project} /> :
                            tab === "door" ? <DoorTable tab={tab2} project={project} /> :
                                tab === "hardware" ? <HardwareTable tab={tab} project={project} /> :
                                tab === "frame-anchor" ? <FrameAnchorTable project={project} /> :
                                    <Div10Table tab={tab2} project={project} />
                        }
                    </div>
                </div>
            </CheckboxProvider>
        </ShopContainer>
    )
}

export const fakeUpsert = (input: any) => Promise.resolve(input);
export const viewOnly = {
    viewOnly: true,
}
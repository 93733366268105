import React, {useCallback, useContext, useMemo, useState} from "react";
import {Opening} from "../../../../api/Openings";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {PrepManager} from "../../shopdrawing/hardware/prepCanvas/PrepManager";
import {useHwAPI} from "../../shopdrawing/hardware/HardwareGeneric";
import {EditContext} from "../../../../misc/scroller/EditProvider";
import {first, useAsync2} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {hasCustomPrep} from "./QuoteOpenings";

type PromiseCallback<T> = {
    resolve: (value: T) => void
    reject: (err: Error) => void;
}

export function PrepLocations<T extends (Opening | QuoteOpening)>(props: {
    value: T;
    onDone(value: T): void;
    onCancel(): void;
}) {
    const hw = useHwAPI();
    const [shouldUpdateExistingPromise, setShouldUpdateExistingPromise] = useState<PromiseCallback<boolean>&{
        affects: string[]
    }|null>(null);

    const shouldWeUpdateExisting = useCallback((affects: string[]) => {
        return new Promise<boolean>((resolve, reject) => setShouldUpdateExistingPromise({
            affects: affects,
            resolve: resolve,
            reject: reject,
        }));
    }, [])

    const edit = useContext(EditContext);

    const hardware = useAsync2(() => {
        return hw.listWithContents({
            groups: props.value.hardwareGroup ? [props.value.hardwareGroup] : []
        })
    }, {}, []);

    const hardwareItems = first(hardware.result || [], v => true)?.hardware

    const show = useMemo(() => {
        return {
            id: props.value.hardwareGroup || 0,
            name: props.value.hardwareGroupName || "",
            project: props.value.project,
            openings: [props.value as any],
            hardware: hardwareItems || [],
            prepConfig: props.value.hardwareGroupPrepConfig || [],
        };
    }, [props.value, hardwareItems]);

    const editRef = useSyncedRef(edit);
    const onDoneRef = useSyncedRef(props.onDone)
    const onDone = useCallback((input: T) => {
        onDoneRef.current(input);
        editRef.current.onSelect.emit(null)
        editRef.current.reload();
    }, [onDoneRef, editRef]);

    if(hardware.loadingOrError) {
        return (
            <Dialog open>
                <DialogTitle>Loading Hardware</DialogTitle>
                <DialogContent>
                    {hardware.LoadingOrErrorElement}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        shouldUpdateExistingPromise?.reject(new Error("User Cancelled"));
                        setShouldUpdateExistingPromise(null);
                    }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            {shouldUpdateExistingPromise && <Dialog open>
                <DialogTitle>Update Existing Hardware Heading?</DialogTitle>
                <DialogContent>
                    {shouldUpdateExistingPromise.affects.length > 0 ?
                        <div>Would you like to update the existing hardware heading (and {shouldUpdateExistingPromise.affects.join(", ")}), or create a new one?</div> :
                        <div>Would you like to update the existing hardware heading (no other openings affected), or create a new one?</div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        shouldUpdateExistingPromise?.reject(new Error("User Cancelled"));
                        setShouldUpdateExistingPromise(null);
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        shouldUpdateExistingPromise?.resolve(true);
                        setShouldUpdateExistingPromise(null);
                    }}>Update Existing</Button>
                    <Button onClick={() => {
                        shouldUpdateExistingPromise?.resolve(false);
                        setShouldUpdateExistingPromise(null);
                    }}>Create New</Button>
                </DialogActions>
            </Dialog>}
            <PrepManager
                value={show}
                  onCancel={props.onCancel}
                  onDone={async cfg => {
                      if(!props.value.hardwareGroup) {

                          const rs = await hw.upsert({
                                value: {
                                    id: 0,
                                    project: props.value.project,
                                    openings: [props.value as any],
                                    hardware: [],
                                    prepConfig: cfg,
                                    prepConfigIsCustom: hasCustomPrep(cfg),

                                    // @ts-ignore
                                    quoteAlternative: props.value.quoteAlternative,
                                }
                            });

                          if(!rs.updated) throw new Error("Failed to update hardware heading")

                          onDone(Object.assign({}, props.value, {
                              hardwareGroup: rs.id,
                              hardwareGroupName: rs.name,
                          }));
                          return;
                      }


                      const list = await hw.listWithContents({
                          groups: [props.value.hardwareGroup as number],
                      });

                      const merge = await shouldWeUpdateExisting(
                          list[0].openings.map(v => v.name).filter(v => v !== props.value.name)
                      );

                      const value = list[0]
                      const withShortCode = first(value.hardware, h => !!h.shortcode)

                      if(merge) {

                          const rs = await hw.upsert({
                              linkToShortcode: withShortCode?.shortcode || undefined,
                              value: Object.assign({}, value, {
                                  prepConfig: cfg,
                                  prepConfigIsCustom: hasCustomPrep(cfg),
                              })
                          });

                          if(!rs.updated) throw new Error("Failed to update hardware heading")
                          onDone(Object.assign({}, props.value, {
                              hardwareGroup: rs.id,
                              hardwareGroupName: rs.name,
                          }));
                          return;
                      }

                      const rs = await hw.upsert({
                          value: Object.assign({}, value, {
                              id: 0,
                              name: "",
                              openings: [props.value as any],
                              prepConfig: cfg,
                              prepConfigIsCustom: hasCustomPrep(cfg),
                          }),
                          linkToShortcode: withShortCode?.shortcode || undefined,
                      });

                      if(!rs.updated) throw new Error("Failed to update hardware group")
                      onDone(Object.assign({}, props.value, {
                          hardwareGroup: rs.id,
                          hardwareGroupName: rs.name,
                      }));
                  }} />
        </>
    )
}
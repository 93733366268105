import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useSnackbar} from "../../../../misc/Snackbar";
import {useCallback} from "react";

export function useSnackAction<T, U>(
    callback: (input: any) => Promise<U>,
    props: {
        success: string;
    }) {

    const callbackRef = useSyncedRef(callback);
    const successRef = useSyncedRef(props.success);
    const snack = useSnackbar()

    const action = useAsyncAction(async (input) => {
        try {
            snack.loading();
            await callbackRef.current(input);
            snack.success(successRef.current)
        } catch (e: any) {
            snack.error(e.message);
        }
    }, []);

    const actionCallbackRef = useSyncedRef(action.callback)
    const cb = useCallback((input: T) => actionCallbackRef.current(input), [actionCallbackRef])

    return {
        callback: cb,
    }
}
import React, {MutableRefObject, useEffect, useState} from "react";
import {ProductReplacement} from "../../../../api/QuoteHardware";
import {ProductSearchResult} from "../../../../api/Products";
import {ShortCode} from "../../../../api/Library";
import {isShortCode} from "../../shopdrawing/hardware/PendingHardware";
import {ProductLookup} from "../../shopdrawing/extras/ProductLookup";
import {HwLibraryTypeProvider} from "../../shopdrawing/hardware/library/HwLibraryType";
import {SmallGreyButton} from "../../../../misc/SmallGreyButton";

export function ProductSelector(props: {
    product: number
    div10?: boolean
    value: MutableRefObject<ProductReplacement|null>
}) {

    const [showProduct, setShowProduct] = useState(false);
    const [value, setValue] = useState<ProductSearchResult|null>(null);
    useEffect(() => {
        if(value === null) {
            props.value.current = null;
            return;
        }

        props.value.current = {
            fromProduct: props.product,
            toProduct: value.id,
        }
    }, [props.value, value, props.product])

    if(showProduct) {
        return (
            <HwLibraryTypeProvider value={props.div10 ? "div10/wr-accessory" : "hardware"}>
                <div style={{cursor: "pointer", display: "flex", alignItems: "center", paddingRight: 4}}>
                    <ProductLookup
                        placeholder="Enter Shortcode or start typing product name"
                        kind={props.div10 ? "div-10" : "hardware"}
                        onSelect={async (result: ProductSearchResult | ShortCode) => {
                            if(isShortCode(result)) return;

                            setValue(result)
                            setShowProduct(false)
                        }}
                    />
                    <SmallGreyButton onClick={() => setShowProduct(false)}>
                        Cancel
                    </SmallGreyButton>
                </div>
            </HwLibraryTypeProvider>
        )
    }

    if(value) {
        return (
            <div
                style={{cursor: "pointer", display: "flex", alignItems: "center", padding: 4}}
                onClick={() => setShowProduct(true)}>

                <div style={{flex: 1}}>{value.manufacturerName} {value.categoryName} - {value.productCode}</div>
                <SmallGreyButton onClick={() => setValue(null)}>
                    Clear
                </SmallGreyButton>
            </div>
        )
    }

    return (
        <div style={{cursor: "pointer", display: "flex", alignItems: "center", padding: 4}} onClick={() => setShowProduct(true)}>
            Keep the same
        </div>
    )
}
import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {Div10, Door, Frame} from "./Pricing";
import {Cents} from "nate-react-api-helpers";
import {DateString} from "./Projects";

export class QuotePricing extends SubAPI {
    list(props: {
        project: number;
        alternative: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<QuotePriceLine>>("/api/quote-pricing", props);
    }

    upsert(input: QuotePriceLine) {
        return this.fetcher.post<QuotePriceLine>("/api/quote-pricing/item", input)
    }

    scaleSection(props: {
        project: number;
        alternative: number;
        type: SectionName
        totalPriceCents: number;
    }) {
        return this.fetcher.post("/api/quote-pricing/section-total-price", props);
    }

    listRiders(props: {
        project: number;
        alternative: number;
        offset: number;
    }) {
        return this.fetcher.get<Paginated<QuotePriceRider>>("/api/quote-price-rider", props)
    }

    upsertRider(input: QuotePriceRider) {
        return this.fetcher.post<QuotePriceRider>("/api/quote-price-rider/item", input)
    }
}

export type QuotePriceRider = {
    id: number;
    project: number;
    quoteAlternative: number;
    productType: string;
    sectionKey: string;
    name: string;
    unitCostCents: Cents;

    markupPercent?: number
    markupCents?: Cents;
    extendedPrice: Cents;

    qty: number;
    createdAt: DateString;
    updatedAt: DateString;
    updatedBy: number;
}

export type QuotePriceLine = {
    id: number;
    project: number;
    productType: string;
    product: number;
    productName: string;
    categoryName: string;
    categorySortOrder?: number;
    productCode: string;
    itemName: string | null;
    doorSeries: string | null;
    finish: string;
    productDimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;
    qty: number;
    unitCostCents: Cents;
    markupPercent?: number;
    markupCents?: Cents;
    extendedPrice: Cents;
    revenueDelivered?: Cents;
    costDelivered?: Cents;
    createdAt: DateString;
    updatedAt: DateString;
    updatedBy: number;
    archived: boolean;

    manufacturer: number;
    manufacturerName: string;
    
    supplier: number;
    supplierName: string;

    linkedQuotePrice: number[]
    frame: Frame;
    door: Door;
    div10?: Div10;

    projectChangeset?: number;
    projectChangesetNote?: string;
    projectChangesetActive?: boolean;
    projectChangesetApproved?: boolean;
}

export type SectionName =
    "hardware" | "frame" | "frame-anchor" | "frame-prep" |
    "door-hm" | "door-prep" |
    "door-wd" |
    "door-other" |
    'div-10/corner-guard' |
    'div-10/locker' |
    'div-10/mailbox' |
    'div-10/wr-accessory' |
    'div-10/wr-partition'
import {ShopContainer} from "../ShopContainer";
import {grey} from "@mui/material/colors";
import {Grid} from "@mui/material";
import {Library} from "../hardware/library/Library";
import React, {useContext, useEffect} from "react";
import {Div10Table, useDiv10Api} from "./Div10Table";
import {ReloadContextProvider} from "../hardware/ReloadContext";
import {EditProduct} from "../hardware/EditProduct";
import {Div10Switcher} from "../../quote/div10/Div10Switcher";
import {HwLibraryTypeProvider} from "../hardware/library/HwLibraryType";
import {useQueryParam} from "../../quote/pricing/QueryParam";
import {MakeSubmittal} from "./pdf/MakeSubmittal";
import {EventEmitter, first, useAsync2} from "nate-react-api-helpers";
import {useAlternative} from "../../quote/alternative/Alternative";
import {div10TabKeys} from "./Div10Tabs";
import {useProjectId} from "../ProjectName";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {ReleasedAdjustmentProvider} from "../../quote/openings/OnsiteAdjustmentsProvider";

export const Div10ForceUpdate = new EventEmitter<number>()

export function Div10() {
    const cacheBuster = EventEmitter.reactValue(Div10ForceUpdate)

    return (
        <ShopContainer key={cacheBuster} name={<Div10Switcher />} submittalCreator={MakeSubmittal}>
            <ReleasedAdjustmentProvider>
                <Div10Inner />
            </ReleasedAdjustmentProvider>
        </ShopContainer>
    )
}

export function Div10Inner() {
    const [tab, setTab] = useQueryParam("tab", "wr-accessory");
    const dapi = useDiv10Api();
    const alternative = useAlternative();
    const projectId = useProjectId();
    const previewChangeset = useContext(ChangePreviewContext);

    // choose first tab with qty > 0
    const summaryRq = useAsync2(input => dapi.summary(input), {
        project: projectId,
        alternative: alternative,
        previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined
    }, [projectId, alternative, previewChangeset.enabled, previewChangeset.sessionId]);
    const data = summaryRq.result;
    useEffect(() => {
        if(!data) return;
        for(let i = 0; i < div10TabKeys.length; i++) {
            const k = div10TabKeys[i]
            const result = first(data, d => d.category === k)?.qty || 0;
            if(result > 0) {
                setTab(k);
                return;
            }
        }

    }, [data, setTab])

    return (
        <ReloadContextProvider>
            <EditProduct mode="div-10">
                <HwLibraryTypeProvider value="div10/wr-accessory">
                    <Grid container style={{borderTop: "1px solid " + grey["300"], flex: 1, overflow: "auto"}} direction="row" alignItems="stretch">
                        <Grid item xs style={{overflowY: "auto", flex: 1, height: "100%"}}>
                            <Div10Table key="-" tab={tab} onTabChange={setTab} />
                        </Grid>
                        {tab === "wr-accessory" && <Grid item style={{width: 400}}>
                            <div style={{borderLeft: "1px solid " + grey["300"], height: "100%"}}>
                                <Library />
                            </div>
                        </Grid>}
                    </Grid>
                </HwLibraryTypeProvider>
            </EditProduct>
        </ReloadContextProvider>
    )
}
import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";
import {Cents} from "nate-react-api-helpers";

export class MiscPricing extends SubAPI {
    list(props: {
        project: number
        offset: number;
    }) {
        return this.fetcher.get<Paginated<MiscPrice>>("/api/quote-misc-prices", props)
    }

    upsert(props: Partial<MiscPrice>) {
        return this.fetcher.post<MiscPrice>("/api/quote-misc-price", props)
    }

    reOrder(props: {
        project: number;
        alternative?: number;
        idOrder: number[];
    }) {
        return this.fetcher.post("/api/quote-misc-price/reorder", props)
    }
}

export type MiscPrice = {
    id: number;
    project: number;

    name: string;
    publicNote: string;
    internalNote: string;
    
    markupPercent?: number;
    markupCents?: Cents;
    unitCostCents: Cents;
    extendedPrice: Cents;

    backup?: MiscPrice|null;
}
import {Container} from "../../misc/Container";
import {useEffect, useRef, useState} from "react";
import {WhiteColumn} from "../logistics/purchasing/WhiteColumn";
import { FileUploader } from "react-drag-drop-files";
import {Alert, LinearProgress, Typography} from "@mui/material";
import {sleep, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {ExtractorSession} from "./ExtractorSession";


export function Extractor() {
    const [progress, setProgress] = useState<null|number>(null);
    const progressRef = useRef(progress);
    const [hasSession, setHasSession] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState("");

    const importer = useAsyncAction(async (input) => {
        setProgress(0);
        progressRef.current = 0;

        setLoadingStatus("Uploading PDF...")

        const session = await api.extractor.session(input, prog => {
            const value = Math.round(prog.loaded / prog.total * 100 * 0.9);
            if (progressRef.current === value) return;
            setProgress(value);
            progressRef.current = value;
        });

        setProgress(90);
        setLoadingStatus("Converting PDF to editable format...")

        while (true) {
            const info = await api.extractor.sessionStatus({id: session.id})
            if (info.ready) {
                if (info.error) {
                    throw new Error(info.error);
                }

                return info;
            }

            await sleep(500);
        }
    }, []);

    useEffect(() => {
        if(importer.result && !importer.error) {
            setHasSession(true);
        }
    }, [importer.result, importer.error])

    if(hasSession && importer.result) {
        return (
            <Container>
                <WhiteColumn>
                    <ExtractorSession
                        id={importer.result.id}
                        nPages={importer.result.nPages}
                    />
                </WhiteColumn>
            </Container>
        )
    }

    return (
        <Container>
            <WhiteColumn>
                <div style={{padding: 32}}>
                    {progress !== null && <>
                        <div style={{maxWidth: 300, width: "100%"}}>
                            <Typography variant="h6">
                                Setting up your edit session...
                            </Typography>
                            <Typography variant="body2">{loadingStatus}</Typography>
                            <LinearProgress variant={progress < 90 ? "determinate" : "indeterminate"} value={progress} />
                        </div>
                    </>}

                    {!hasSession && !importer.loading && <>
                        <Typography variant="h6">
                            Choose a PDF to Extract
                        </Typography>
                        <Typography variant="body2">
                            Any PDF will work, just drop it in the box below.
                        </Typography>
                        <div style={{height: 16}}></div>
                        <FileUploader
                            handleChange={(input: File | Array<File> | FileList) => {
                                if(!input) return;
                                const files = Array.isArray(input) ? input : [input];
                                if(files.length === 0) return;

                                const file = files[0];
                                importer.callback({file: file});
                            }}
                            name="file"
                            types={fileTypes}
                        />
                    </>}

                    {importer.error && <Alert color="error">{importer.error}</Alert>}
                </div>
            </WhiteColumn>
        </Container>
    )
}

const fileTypes = ["PDF"];
import {Grid} from "@mui/material";
import {pdfInfo} from "./CoverPage";
import {grey} from "@mui/material/colors";
import {User} from "../../../api/Users";

const salesEmail = "sales@tykel.ca"
const salesPhone = "(905) 930-1123 Ext 1"

export function PDFHeader2(props: {
    title: string;
    user?: User | null;
    sales?: boolean
}) {
    const email = props.sales ? salesEmail : props.user?.email || "tyler@tykel.ca";
    const phone = props.sales ? salesPhone : props.user?.phone || "(905) 512-7461";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <img alt="Tykel Logo" src={pdfInfo.logo} style={{width: "100%", paddingLeft: 20}} />
                </Grid>
                <Grid item xs style={{color: grey["700"], fontSize: 14}} alignSelf="center">
                    <div>www.tykel.ca - {email}</div>
                    <div>Phone {phone}</div>
                </Grid>
                <Grid item alignSelf="flex-end">
                    <div style={{
                        fontSize: 30, fontWeight: "bold",
                        textTransform: "uppercase", marginBottom: 20,
                        color: grey["700"],
                    }}>{props.title}</div>
                </Grid>
            </Grid>

            <div style={{height: 16}} />
        </>
    )
}

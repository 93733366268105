import {AdjustCol, BoolCol, NumberCol, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import {InventoryRequest} from "../../../../api/Logistics";
import {CheckboxSelectorCol} from "./Checkbox";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {useUser} from "../../../../misc/Permission";
import {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {DimensionCol} from "../extras/DimensionCol";
import {projectTablePrefName} from "../TablePrefName";

export function HardwareTable(props: {
    project: number
    tab: string;
}) {
    const {project, tab} = props;

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    if(!u) return null;

    return (
        <Table<InventoryRequest>
            key={tab}
            locked={locked}
            name={projectTablePrefName("project.release.hardware", project)}
            globalPrefsName={projectTablePrefName("project.release.hardware", 0)}
            columns={[
                CheckboxSelectorCol(),
                ViewOnly(StringCol("Floor", "floor", 80)),
                AdjustCol(ViewOnly(StringCol("Opening", "openingName", 80)), {
                    fixed: true
                }),
                ViewOnly(StringCol("Name", "name", 150)),
                ViewOnly(StringCol("Code", "productCode", 150)),
                ViewOnly(StringCol("Finish", "finish", 150)),
                ViewOnly(NumberCol("Qty", "qty", 50)),
                ViewOnly(StringCol("Elevation", "doorElevation", 150)),
                ViewOnly(DimensionCol("Length", "dimLength", 50)),
                ViewOnly(DimensionCol("Width", "dimWidth", 50)),
                ViewOnly(DimensionCol("Height", "dimHeight", 50)),
                ViewOnly(StringCol("HW #", "hardwareGroup", 100)),
                ViewOnly(BoolCol("Keyed", "keyed"))
            ]}
            fetch={async (ctx) => getAll(ctx, offset => api.logistics.listInventoryRequest({
                project: project,
                offset: offset,
                kind: "hardware",
                state: "not-released",
            }))}
            fetchDeps={[project, tab]}
        />
    )
}
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import {api} from "../../../../api/API";
import {useState} from "react";
import {PriceSectionNoteDefault} from "../../../../api/PriceSectionNotes";
import Delete from "@mui/icons-material/Delete";


export function NoteCustomizeDefault(props: {
    keyName: string;
    value: PriceSectionNoteDefault[];
    onDone(): void;
}) {

    const onDoneRef = useSyncedRef(props.onDone)
    const [list, setList] = useState(props.value);

    const action = useAsyncAction(async (input) => {
        await api.priceSectionNotes.setDefaults(input)
        onDoneRef.current();
    }, [])

    return (
        <Dialog open fullWidth maxWidth="lg">
            <form onSubmit={e => {
                e.preventDefault()

                action.callback({
                    keyName: props.keyName,
                    notes: list,
                })
            }}>
                <DialogTitle>
                    Customize Defaults
                </DialogTitle>
                <DialogContent>

                    {list.map(l => (
                        <div key={l.id} style={{padding: 10, display: "flex", alignItems: "center"}}>
                            <TextField fullWidth variant="outlined" value={l.note} onChange={e => {
                                setList(list.map(old => {
                                    if(l.id === old.id) {
                                        return {...old, note: e.target.value}
                                    }

                                    return old;
                                }))
                            }} />
                            <div style={{width: 10}} />
                            <IconButton onClick={() => {
                                setList(list.filter(v => v.id !== l.id))
                            }}>
                                <Delete />
                            </IconButton>
                        </div>
                    ))}

                    <Button onClick={() => {
                        setList([...list, {
                            id: -(list.length+1),
                            keyName: props.keyName,
                            note: "",
                        }])
                    }}>Add Row</Button>

                </DialogContent>
                <DialogActions>
                    {action.LoadingElement}
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
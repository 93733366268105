import {Product, ProductSearchResult} from "../../api/Products";
import {ShortCode} from "../../api/Library";
import {isShortCode} from "../project/shopdrawing/hardware/PendingHardware";
import {ProductLookup} from "../project/shopdrawing/extras/ProductLookup";
import React from "react";
import {SmallGreyButton} from "../../misc/SmallGreyButton";
import {cellWithPadding} from "./RunMfgProcess";

export type ProductWithQty = {
    product: Product;
    qty: number;
}

export function ProductSelector(props: {
    value: ProductWithQty | null;
    stockQty?: number | null;
    showReset?: boolean;
    onChange(value: ProductWithQty) : void;
    onRemove?(): void;
}) {
    const [showProduct, setShowProduct] = React.useState(false);
    const p = props.value?.product;
    const showLookup = !p || showProduct;

    return (
        <tr>
            <td style={{minWidth: 400}} className={showLookup ? undefined : cellWithPadding}>
                {showLookup ? <ProductLookup
                    placeholder="Start typing product name"
                    kind={"manufacture"}
                    onSelect={async (result: ProductSearchResult | ShortCode) => {
                        if (isShortCode(result)) return;

                        setShowProduct(false)
                        props.onChange(Object.assign({qty: 1}, props.value, {
                            product: result,
                        }))
                    }}
                /> : <div style={{display: "flex", fontSize: "0.9rem", alignItems: "center"}}>
                    <div style={{paddingRight: 8, flex: 1}}>{p?.manufacturerName} {p?.categoryName} - {p?.productCode}</div>
                </div>}
            </td>
            <td>
                <input
                    type="number"
                    style={{textAlign: "right", width: 100, border: "none", paddingTop: 4, paddingBottom: 4}}
                    disabled={!props.value}
                    value={(props.value?.qty || 0).toString()}
                    onChange={e => {
                        const qty = parseInt(e.target.value);
                        props.onChange(Object.assign({}, props.value, {
                            qty: qty,
                        }))
                    }}
                />
            </td>
            <td>
                {props.showReset && <SmallGreyButton onClick={() => setShowProduct(true)}>Reset</SmallGreyButton>}
                {props.onRemove && <SmallGreyButton onClick={props.onRemove}>Remove</SmallGreyButton>}
            </td>
            <td style={{textAlign: "right"}}>
                {props.stockQty}
            </td>
        </tr>
    )
}
import {TextField, TextFieldProps} from "@mui/material";
import {useEffect, useState} from "react";

export function TextField2(props: {
    initialValue: string;
    onChange(value: string): void;
} & TextFieldProps) {
    const {initialValue, onChange, ...otherProps} = props;
    const [value, setValue] = useState(props.initialValue);

    useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue])

    return <TextField value={value} size="small" onChange={e => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    }} {...otherProps} />
}
import {Column} from "./Table";
import {createContext, PropsWithChildren, useCallback, useMemo, useRef} from "react";
import {contains, first} from "nate-react-api-helpers";
import {ColumnConfig, ColumnConfigByName, TableProjectConfig} from "../../api/TableConfig";

import {CustomizeColumns} from "./CustomizeColumns";

export type SortFx = (a: any, b: any) => number;
export type LookupConfigByName = (name: string, column?: Column<any>|null, index?: number) => ColumnConfig;

export const TablePersonalizeContext = createContext({
    columns: {} as {[name: string]: ColumnConfig},
    sortBy: null as null | undefined | {
        column: string;
        reverse: boolean;
    },
    customSort: undefined as (SortFx | undefined),
    maxFixedIndex: -1,
    showCustomizeColumns: {current: () => {}},
    onChange: (input: Partial<TableProjectConfig>, global: boolean = false) => {},
    lookupByName: (name: string, column?: Column<any>|null, index?: number) => {
        return {} as any as ColumnConfig;
    },
    getTableColumns: (input: Column<any>[], opts?: {
        includeHidden?: boolean;
    }) => {
        return {
            columns: [] as Column<any>[],
            standardColumns: [] as Column<any>[],
            fixedColumns: [] as Column<any>[],
            lastVisibleName: null as string | null,
            fixedWidth: 0 as number,
            standardWidth: 0 as number,
        }
    }
})

export function TablePersonalizeProvider(props: PropsWithChildren<{
    name: string | null;
    value: TableProjectConfig|null|undefined;
    columns: Column<any>[];
    sort?: SortFx;
    onChange(cfg: TableProjectConfig, global?: boolean): any;
    hasGlobal?: boolean;
}>) {
    const showCustomizeColumns = useRef(() => {});

    const valueRef = useRef(props.value);
    valueRef.current = props.value;
    const propsOnChange = useRef(props.onChange);
    propsOnChange.current = props.onChange;

    const onChange = useCallback((partial: Partial<TableProjectConfig>, global: boolean = false) => {
        const value = Object.assign({}, valueRef.current, partial);
        propsOnChange.current(value, global);
    }, []);

    const maxFixedIndex = useMemo(() => {
        return props.value ? coalesce(props.value.maxFixedIndex, 0) : (Math.max(...props.columns.map((c, index) => c.fixed ? index : -1)))
    }, [props.value, props.columns])

    const lookupByName = useCallback((name: string, column: Column<any> | null = null, index: number = -1) => {
        let cfg = valueRef.current?.byName[name]
        if(!cfg) {
            cfg = {
                width: column?.width || 200,
                hidden: false,
                index: index,
            }
        }

        return cfg;
    }, []);

    const getTableColumns = useCallback((input: Column<any>[], opts?: { includeHidden?: boolean }) => {
        const includeHidden = opts?.includeHidden || false;

        // first sort b/c maxFixedIndex is based on the sorted, non-hidden index
        const sorted = sortColumns(input, props.value?.byName || {});

        // split up groups
        const fixedRaw = sorted.slice(0, maxFixedIndex+1)
        const standardRaw = sorted.slice(maxFixedIndex+1)

        // filter for hidden if necessary
        const fixed = includeHidden ? fixedRaw : fixedRaw.filter(c => !lookupByName(c.name, c).hidden);
        const standard = includeHidden ? standardRaw : standardRaw.filter(c => !lookupByName(c.name, c).hidden);

        const lastVisibleName = standard.length === 0 ? null : standard[standard.length-1].name;

        const fixedWidth = fixed.reduce((acc, c) => acc + lookupByName(c.name, c).width, 0);
        const standardWidth = standard.reduce((acc, c) => acc + lookupByName(c.name, c).width, 0);

        return {
            standardColumns: standard,
            fixedColumns: fixed,
            lastVisibleName: lastVisibleName,
            fixedWidth: fixedWidth,
            standardWidth: standardWidth,
            columns: [...fixed, ...standard]
        }
    }, [props.value, lookupByName, maxFixedIndex]);

    const ctx = useMemo(() => {
        let sortBy = props.value?.sortBy;
        if(sortBy) {
            const col = first(props.columns, c => c.name === sortBy?.column);
            if(!col?.sort) {
                sortBy = null;
            }
        }

        if(props.sort) {
            sortBy = null;
        }

        return ({
            lookupByName,
            canPersonalize: !!props.name,
            columns: props.value?.byName || {},
            maxFixedIndex: maxFixedIndex,
            showCustomizeColumns: showCustomizeColumns,
            sortBy: sortBy,
            customSort: props.sort,
            onChange: onChange,
            getTableColumns,
        })
    }, [onChange, props.columns, props.name, props.value, props.sort, lookupByName, getTableColumns, maxFixedIndex]);

    return <TablePersonalizeContext.Provider value={ctx}>
        {props.children}
        <CustomizeColumns columns={props.columns} hasGlobal={!!props.hasGlobal} />
    </TablePersonalizeContext.Provider>
}

export function sortColumns(input: Column<any>[], cfg: ColumnConfigByName) {
    let output: (Column<any> | null)[] = input.map(c => null);

    // prioritize cfg.index, fallback to input order

    // find a valid cfg.index to start us off
    const foundIndexes: number[] = [];
    for(let i = 0; i < input.length; i++) {
        const colConfig = cfg[input[i].name];

        if(colConfig && colConfig.index >= 0) {
            output[colConfig.index] = input[i];
            foundIndexes.push(i);
            break;
        }
    }

    if(foundIndexes.length === 0) {
        // preserve input order b/c nothing has indexes set
        return input
    }

    // fill in the rest
    input.map((c, index) => {
        if (contains(foundIndexes, index)) return; // already handled

        let cfgIndex = coalesce(cfg[c.name]?.index, -1)
        if (cfgIndex < 0) {
            const lastFoundIndex = foundIndexes[foundIndexes.length - 1]
            cfgIndex = lastFoundIndex + 1
        }

        if(output[cfgIndex] === null) {
            output[cfgIndex] = c;
            foundIndexes.push(index);
            return;
        }

        // warn: this shouldn't happen
        output.splice(cfgIndex, 0, c);
        foundIndexes.push(index);
        return
    })

    return output.filter(v => v !== null) as Column<any>[];
}

function coalesce<T>(value: T | null | undefined, defaultValue: T) {
    if(value === null || value === undefined) return defaultValue;
    return value;
}
import {Grid, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {Opening} from "../../../../../api/Openings";
import {openingColumns, ExportColumn} from "../MakeSubmittal";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {useEffect, useState} from "react";
import {QuoteOpening} from "../../../../../api/QuoteOpenings";

export function ColumnTab(props: {
    value: ExportColumn[]
    onChange(value: ExportColumn[]): void;
    sample: Opening|QuoteOpening;
}) {
    const selected = props.value;
    const setSelected = props.onChange;
    const data = props.sample;

    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        setRefreshKey(Date.now());
    }, [selected])

    return (
        <>
            <div style={{paddingBottom: 8, fontWeight: "bold"}}>Columns</div>
            <Grid container spacing={1}>
                <Grid item style={{width: 300}}>
                    <DragDropContext key={refreshKey.toString()} onDragEnd={(input: DropResult) => {
                        if(!input.destination) return;

                        const list = selected.slice(0);
                        const [removed] = list.splice(input.source.index, 1);
                        list.splice(input.destination.index, 0, removed);
                        setSelected(list);
                    }}>
                        <Droppable droppableId="droppable">
                            {(provided: any, snapshot: any) => (
                                <div {...provided.droppableProps}
                                     ref={provided.innerRef}>
                                    <List dense>
                                        {selected.map((c, index) =>
                                            <Draggable draggableId={c.key} index={index}>
                                                {(provided: any, snapshot: any) =>
                                                    (<ListItem
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{...provided.draggableProps.style}}
                                                    >
                                                        <DragIndicatorIcon />
                                                        <ListItemText>{c.display}</ListItemText>
                                                        <ListItemIcon onClick={() => setSelected(selected.filter(s => s !== c))}>
                                                            <RemoveCircleOutlineIcon />
                                                        </ListItemIcon>
                                                    </ListItem>)}
                                            </Draggable>
                                        )}
                                    </List>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
                <Grid item xs={4}>
                    <List dense>
                        {openingColumns.filter(c => selected.filter(s => s.key ===c.key).length === 0).map(c =>
                            <ListItem key={c.key}>
                                <ListItemText>{c.display}</ListItemText>
                                <ListItemIcon onClick={() => setSelected([...selected, c])}>
                                    <AddCircleOutlineIcon />
                                </ListItemIcon>
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>

            <div style={{height: 16}} />
            <div style={{paddingBottom: 8, fontWeight: "bold"}}>Preview</div>
            <div style={{marginLeft: -24, marginRight: -24, overflowX: "auto"}}>
                <table className={previewTableClass}>
                    <tbody>
                    <tr>
                        {selected.map(s => <td>{s.display}</td>)}
                    </tr>
                    <tr>
                        {selected.map(s => <td style={{paddingBottom: 24}}>{data ?
                            (data[s.key] as any) : <i>(none)</i>}</td>)}
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

const previewTableClass = css({
    borderCollapse: "collapse",
    width: "100%",
    borderTop: "1px solid " + grey["400"],
    borderBottom: "1px solid " + grey["400"],

    "& td": {
        fontSize: "0.9rem",
        padding: "2px 10px",
        border: "1px solid " + grey["400"],
        whiteSpace: "nowrap",

        "&:first-child": {
            borderLeft: "none",
        },
        "&:last-child": {
            borderRight: "none",
        }
    }
})
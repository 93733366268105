import {useParams} from "react-router-dom";

export function useChangesetId() {
    const params = useParams<{changesetId: string}>();
    try {
        if(!params.changesetId) return 0;

        const id = parseInt(params.changesetId, 10)
        if(isNaN(id)) return 0;

        return id;
    } catch (e) {
        return 0;
    }
}
import {Grid, Paper, Popper, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";
import UpdateIcon from "@mui/icons-material/Update";
import React from "react";

export function PreviousValuePopup(props: {
    anchor: HTMLElement|null;
    width: number;
    value: any;
}) {
    return (
        <Popper open anchorEl={props.anchor}>
            <Paper elevation={3} style={{padding: 4, paddingLeft: 8, paddingRight: 8, minWidth: props.width, overflow: "hidden"}}>
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid item style={{color: orange["800"]}}>
                        <div style={{height: 4}} />
                        <UpdateIcon color="inherit" />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" style={{lineHeight: "0.75em"}}>
                            Previous Value
                        </Typography>
                        <div>
                            {props.value || <i>blank</i>}
                        </div>
                    </Grid>
                </Grid>

            </Paper>
        </Popper>
    )
}
import {api} from "../../../api/API";
import moment from "moment";
import {DashCardData} from "./DashCardData";
import {formatCents} from "nate-react-api-helpers";


export function OrdersReadyForPickup() {
    return (
        <DashCardData
            lookup={() => api.logistics.listPurchaseOrders({
                readyForPickup: true,
            })}
            title="Orders Ready for Pickup"
            itemTitle={d => "#" + d.poNumber + " - " + d.supplierName}
            itemSubtext={d => `${d.updatedByName} - ${moment(d.updatedAt || undefined).format("MMMM D")}`}
            itemRight={d => "$" + formatCents(d.totalCents)}
            link="/logistics/purchasing"
        />
    )
}


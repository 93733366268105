import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {Loader} from "../../../misc/Loader";
import {Link} from "react-router-dom";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {MobileContainer} from "../../../misc/Container";
import {grey} from "@mui/material/colors";
import moment from "moment";
import {css} from "@emotion/css";
import {Alert} from "@mui/material";

export function RouteList() {
    const list = useAsync2(() => api.logistics.listRoutes({active: true}), {}, []);

    return (
        <MobileContainer topCenter="Deliveries">
            <Loader {...list} />
            {list.result?.data.map(d => (
                <Link to={"/logistics/driver/" + d.id} style={{color: "inherit", textDecoration: "none"}}>
                    <div className={rowStyle}>
                        <div style={{flex: 1}}>
                            <div>#{d.id}</div>
                            <div className={rowSecondaryTextStyle}>{moment(d.date).format("ddd, MMM D")}</div>
                        </div>
                        <div style={{height: "1.2em", fontSize: "1.3rem"}}>
                            <ChevronRight fontSize="inherit" />
                        </div>
                    </div>
                </Link>
            ))}
            {!list.loadingOrError && list.result?.data.length === 0 && <div style={{display: "flex", justifyContent: "center"}}>
                <Alert style={{width: "100%", maxWidth: 400, marginTop: 10}}>
                    No Deliveries
                </Alert>
            </div>}
        </MobileContainer>
    )
}

export const rowSecondaryTextStyle = css({
    fontSize: "0.9rem"
})

export const rowStyle = css({
    padding: 16,
    backgroundColor: "white",
    fontSize: "1.3rem",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid " + grey["200"],

    "&:active": {
        backgroundColor: grey["200"],
    }
})
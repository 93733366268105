import React, {useContext} from "react";
import {Container} from "../../misc/Container";
import {Card, Grid} from "@mui/material";
import {ProjectContext, ProjectProvider} from "./ProjectProvider";
import {SummarySection} from "./manage/summary/SummarySection";
import {ProjectLinks} from "./manage/links/ProjectLinks";
import {Files} from "./manage/files/Files";
import {Timeline} from "./manage/timeline/Timeline";
import {EditLink} from "./manage/summary/EditLink";
import {ContractorEdit} from "./manage/summary/OwnerEdit";
import {ProjectNameEdit} from "./manage/summary/ProjectNameEdit";

export function Project() {
    return (
        <ProjectProvider>
            <ProjectInner />
        </ProjectProvider>
    )
}

function ProjectInner() {

    const ctx = useContext(ProjectContext);
    const project = ctx.value;


    if(ctx.LoadingOrErrorElement || !project) {
        return (
            <Container>
                {ctx.LoadingOrErrorElement}
            </Container>
        )
    }

    return (
            <Container>
                <div style={{padding: 8}}>
                    <Grid container spacing={1} flexDirection="column">
                        <Grid item style={{overflow: "auto", display: "flex"}}>
                            <Grid container spacing={1} alignItems="stretch">
                                <Grid item xs={9} style={{height: "auto", display: "flex"}}>
                                    <Card style={{padding: 16, flex: 1}}>
                                        <div style={{marginBottom: 8, fontSize: "1.2rem", fontWeight: "500"}}>
                                            <div>
                                                <EditLink editor={ProjectNameEdit}>
                                                    {project.name || <i>Missing Project Name</i>}
                                                </EditLink>
                                            </div>
                                            <div style={{fontSize: "0.75em"}}>
                                                <EditLink editor={ContractorEdit}>
                                                    {project.contractorName || <i>Missing Contractor</i>}
                                                </EditLink>
                                            </div>
                                        </div>
                                        <SummarySection project={project} />
                                    </Card>
                                </Grid>
                                <Grid item xs={3} style={{display: "flex", height: "auto"}}>
                                    <Timeline />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} style={{flex: 1}} alignItems="stretch">
                                <Grid item xs={9}>
                                    <Grid container spacing={1}
                                          flexDirection="column"
                                          flexWrap="nowrap"
                                    >
                                        <Grid item>
                                            <Files title="Files and Revisions" project={project} kind="default" noApproval />
                                        </Grid>
                                        <Grid item>
                                            <Files small title="Contracts" project={project} kind="contract" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} style={{display: "flex", flex: 1, height: "auto"}}>
                                    <ProjectLinks project={project} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Container>
    )
}


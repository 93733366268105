import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    DialogContentText
} from "@mui/material";
import {Link} from "react-router-dom";
import {InventoryItem} from "../../../api/Logistics";
import {logisticsTableName} from "../../project/shopdrawing/logistics/Logistics";
import {flatten} from "nate-react-api-helpers";

export function Dependants(props: {
    value: InventoryItem;
    onDone(): void;
}) {
    return (
        <Dialog open>
            <DialogTitle>
                Projects Dependant on Inventory Item
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>{props.value.name} - {props.value.productCode}</div>
                    <div>{props.value.manufacturerName}</div>
                    <div>{props.value.dimensions}</div>
                </DialogContentText>

                <div style={{height: 8}}></div>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Project</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fixArray(props.value.projects).map(p => <TableRow key={p.projectId.toString()}>
                            <TableCell>{p.projectName}</TableCell>
                            <TableCell>{p.qty}</TableCell>
                            <TableCell padding="checkbox" style={{width: 120}}>
                                <Link to={`/project/${p.projectId}/shop-drawing/logistics?${logisticsTableName(p.projectId, "openings")}=${p.inventoryRequestId}`} style={{textDecoration: "none"}}><Button size="small">View One</Button></Link>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDone}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

function fixArray(item: any): any[] {
    if (!item) return []
    if (item[0] instanceof Array) {
        return flatten(item)
    }

    return item;
}
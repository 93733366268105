import React, {CSSProperties, useContext, useEffect, useState} from "react";
import {Button, Tooltip} from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import {Insert} from "./Table";
import {EditContext} from "./EditProvider";

export function TableInsert(props: Insert) {
    const [showCreate, setShowCreate] = useState(false);
    const {hasPopout} = useContext(EditContext);
    const key = props.shortcut?.key || "+"

    useEffect(() => {
        if(hasPopout) return;

        const listener = (e: KeyboardEvent) => {

            const ctrl = e.ctrlKey || e.metaKey;
            let shiftOk = true;
            let keyOk = e.key === key;

            // keypress doesn't give resultant char when ctrl is pressed
            if(key === "+") {
                shiftOk = e.shiftKey
                keyOk = e.key === "=";
            }

            if(ctrl && shiftOk && keyOk) {
                e.preventDefault()
                setShowCreate(true);
            }
        }

        document.addEventListener("keypress", listener)
        return () => document.removeEventListener("keypress", listener)
    }, [hasPopout, key])

    return (
        <>
            <div style={Object.assign({
                position: "absolute",
                bottom: 40,
            } as CSSProperties, props.alignX === "left" ? {
                left: 8,
            } : {
                right: 8,
            })}>
                <Tooltip title={<>Shortcut: ctrl + '{key}'</>}>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutline />}
                        color="primary"
                        onClick={() => setShowCreate(true)}>{props.buttonText || "New"}
                    </Button>
                </Tooltip>
                {props.options ? props.options({}) : null}
            </div>
            {showCreate && props.modal(() => setShowCreate(false) as any)}
        </>
    )
}
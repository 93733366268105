import {useCheckboxSelection} from "../release/Checkbox";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {Button, Grid, Menu, TextField} from "@mui/material";
import {useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useTableData, useTableRefresher} from "../../../../misc/scroller/Table";
import {logisticsTableName} from "./Logistics";
import {useProjectId} from "../ProjectName";
import { Loader } from "../../../../misc/Loader";
import {InventoryRequest} from "../../../../api/Logistics";
import {tabToCategory} from "../release/DoorTable";
import {DateString} from "../../../../api/Projects";

export function BulkUnrelease(props: {
    type: string
}) {
    const sel = useCheckboxSelection()
    const [open, setOpen] = useState<HTMLElement|null>(null);
    const [value, setValue] = useState("")
    const project = useProjectId();
    const refresher = useTableRefresher(logisticsTableName(project, props.type));
    const data = useTableData(logisticsTableName(project, props.type)) as InventoryRequest[]

    const values = data.filter(d => sel.indexOf(d.id) !== -1 && (d.status === "released" || (d.isStocking && d.status === "in-warehouse")));

    const update = useAsyncAction(async (input: {
        authorizedBy: string;
        neededBy: null | DateString,
        note: string;
        type: string;
        project: number;
        requests: number[]
        doorForOpenings: number[],
        frameForOpenings: number[],
        hardwareForOpenings: number[],
        div10Items: number[],
        unRelease: boolean;
    }) => {
        await api.openings.release(input);
        setOpen(null);
        refresher()
    }, [refresher]);

    return <div style={{paddingLeft: 8}}>

        <Button onClick={e => setOpen(e.currentTarget)} size="small" variant="outlined" color="inherit" disabled={values.length === 0} endIcon={<ArrowDropDown />}>
            UnRelease ({values.length})
        </Button>

        {open && <Menu open anchorEl={open} onClose={() => setOpen(null)}>
            <Loader {...update} />
            <div style={{padding: 16, maxWidth: 400}}>
                This will unrelease the selected items and remove them from purchasing screens.
                <div style={{height: 16}} />
                <TextField onChange={e => setValue(e.target.value)} value={value} fullWidth label="Note" />
            </div>
            <Grid container spacing={1} justifyContent="flex-end" style={{paddingRight: 10, paddingBottom: 8}}>
                <Grid item>
                    <Button onClick={() => setOpen(null)} size="small">Cancel</Button>
                </Grid>
                <Grid item>
                    <Button onClick={() => update.callback({
                        requests: values.map(v => v.id),
                        neededBy: null,
                        doorForOpenings: values.filter(v => v.type === "door" && !!v.opening).map(v => v.opening),
                        frameForOpenings: values.filter(v => v.type === "frame" && !!v.opening).map(v => v.opening),
                        hardwareForOpenings: values.filter(v => v.type === "hardware" && !!v.opening).map(v => v.opening),
                        div10Items: values.filter(v => v.type === "div-10" && !!v.opening).map(v => v.opening),
                        note: value,
                        type: tabToCategory(props.type) as any,
                        project: project,
                        authorizedBy: "",
                        unRelease: true,
                    })} variant="contained" size="small">UnRelease</Button>
                </Grid>
            </Grid>
        </Menu>}
    </div>
}
import {Delivery, DeliveryBox, DeliveryItem} from "../../../api/Deliveries";
import {removeRowOption, RowOptionCol} from "../purchasing/RowOptions";
import {
    Column,
    prefixMatch,
    sortStringInner,
    Table,
    TableSelectionContext
} from "../../../misc/scroller/Table";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import React, {useContext, useEffect, useRef} from "react";
import {deliveriesPopoutPanel} from "./ToDeliverPopout";
import {isEditable} from "./DeliveryDetail";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {EventEmitter} from "nate-react-api-helpers";
import {BoxingColumn} from "./boxing/BoxingColumn";
import {yesNo} from "../../project/quote/pricing/PriceRequestPDF";
import {EditBoxColumn} from "./boxing/EditBoxColumn";

export const deliveryItemsTableName = "logistics.delivery-items";

export function DeliveryItems(props: {
    delivery: Delivery;
    combined?: boolean;
    selection: EventEmitter<number[]>
    onReload(): void;
}) {
    const ctx = useContext(TableSelectionContext);
    const refreshRef = useSyncedRef(ctx.refresh);
    const onReloadRef = useSyncedRef(props.onReload);
    const deliveryIsEditable = isEditable(props.delivery);
    const deliveryId = props.delivery.id;
    const projectId = props.delivery.project;
    const selection = props.selection;

    useEffect(() => {
        if(!deliveryIsEditable) return;

        deliveriesPopoutPanel.open();

        deliveriesPopoutPanel.sendToClient({
            delivery: deliveryId,
            project: projectId,
        });

        const sub = deliveriesPopoutPanel.fromClient.subscribeAndFireLast(input => {
            if(input === "refresh-delivery") {
                refreshRef.current(deliveryItemsTableName)
            }
        });

        const sub2 = deliveriesPopoutPanel.fromClient.subscribe(input => {
            if(input === "refresh-delivery") {
                onReloadRef.current();
            }
        });

        return () => {
            sub2.cancel();
            sub.cancel();
            deliveriesPopoutPanel.close();
        }
    }, [refreshRef, deliveryIsEditable, deliveryId, projectId, onReloadRef]);

    useEffect(() => {
        selection.emit([])
    }, [deliveryId, selection]);

    const allDeliveryItems = useRef<DeliveryItem[]>([]);

    const calcHasEnoughInventory = (d: DeliveryRow) => {
        if(d.delivery) return yesNo(d.delivery.hasEnoughInWarehouse)
        if(d.box) {
            const box = d.box
            const hasMissing = allDeliveryItems.current.filter(v => v.deliveryBox === box.id)
                .find(v => !v.hasEnoughInWarehouse)

            return yesNo(!hasMissing)
        }

        return yesNo(undefined)
    }

    return (
        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
            <Table<DeliveryRow>
                name={deliveryItemsTableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [deliveryItemsTableName],
                                onComplete: () => deliveriesPopoutPanel.sendToClient("refresh-to-source"),
                            })
                        ],
                    }),
                    {
                        name: "Box",
                        width: 40,
                        render: (d) => {

                            if(d.box) {
                                return <EditBoxColumn box={d.box} rows={allDeliveryItems} />
                            }

                            if(!d.delivery) return null;
                            if(!props.combined) {
                                return d.delivery.deliveryBoxLabel
                            }

                            return (
                                <BoxingColumn id={d.delivery.id} selectionManager={selection} />
                            );
                        }
                    } as Column<DeliveryRow>,
                    {
                        name: "Description",
                        width: 200,
                        render: (d) => {
                            if(d.box) {
                                return d.box.boxType + ": " + d.box.label + ` (${d.box.itemCount} items)`;
                            }

                            return d.delivery?.description;
                        },
                    }, {
                        name: "Code",
                        width: 80,
                        render: (d) => d.delivery?.code,
                        sort: (a, b) => sortStringInner(a.delivery?.code, b.delivery?.code),
                        filter: (v, q) => prefixMatch(v.delivery?.code, q),
                    }, {
                        name: "Dim.",
                        width: 80,
                        render: (d) => d.delivery?.dimensions,
                        sort: (a, b) => sortStringInner(a.delivery?.dimensions, b.delivery?.dimensions),
                        filter: (v, q) => prefixMatch(v.delivery?.dimensions, q),
                    }, {
                        name: "Qty",
                        width: 80,
                        render: (d) => {
                            if(d.delivery) return d.delivery.qty
                            return 1;
                        },
                    },
                    {
                        name: "Source",
                        width: 80,
                        render: (d) => d.delivery?.source,
                        sort: (a, b) => sortStringInner(a.delivery?.source, b.delivery?.source),
                        filter: (v, q) => prefixMatch(v.delivery?.source, q),
                    },
                    {
                        name: "Supplier",
                        width: 80,
                        render: (d) => d.delivery?.manufacturerName,
                        sort: (a, b) => sortStringInner(a.delivery?.manufacturerName, b.delivery?.manufacturerName),
                        filter: (v, q) => prefixMatch(v.delivery?.manufacturerName, q),
                    },
                    {
                        name: "Has Enough Inventory",
                        width: 80,
                        render: (d) => calcHasEnoughInventory(d),
                        sort: (a, b) => sortStringInner(calcHasEnoughInventory(a), calcHasEnoughInventory(b)),
                    },
                ]}
                fetch={async (ctx) => {
                    const deliveries = getAll(ctx, offset => api.deliveries.listDeliveryItems({
                        offset,
                        delivery: props.delivery.id,
                    }))

                    let boxes: Promise<DeliveryBox[]>;
                    if(props.combined) {
                        boxes = api.deliveries.listBoxes({
                            delivery: props.delivery.id,
                        })
                    } else {
                        boxes = Promise.resolve([]);
                    }

                    const [deliveriesData, boxesData] = await Promise.all([deliveries, boxes] as any);
                    const rows: DeliveryRow[] = [];
                    allDeliveryItems.current = deliveriesData.slice(0)

                    for(const box of boxesData) {
                        rows.push({
                            id: -box.id,
                            type: "box",
                            box,
                            archived: false,
                        })
                    }

                    for(const delivery of deliveriesData) {
                        if(props.combined) {
                            if(delivery.deliveryBox) continue;
                        }

                        rows.push({
                            id: delivery.id,
                            type: "delivery",
                            delivery,
                            archived: false,
                        })
                    }

                    return rows;
                }}
                fetchDeps={[props.delivery.id]}
                onChange={async v => {
                    if(v.box) {
                        if(v.archived) {
                            v.box.archived = true;
                        }

                        await api.deliveries.updateBox(v.box)
                        return null;
                    }

                    if(v.delivery) {
                        if(v.archived) {
                            v.delivery.archived = true;
                        }

                        const r = await api.deliveries.updateDeliveryItem(v.delivery)
                        onReloadRef.current();
                        return r;
                    }

                    return null;
                }}
                noHorizontalScroller
                noFooter
            />
        </div>
    )
}

export type DeliveryRow = {
    id: number;
    type: "delivery" | "box"
    delivery?: DeliveryItem;
    box?: DeliveryBox;
    archived: boolean;
}


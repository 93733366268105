import {SmallGreyButton} from "../../../../misc/SmallGreyButton";
import CropFreeIcon from "@mui/icons-material/CropFree";
import {OpeningSizeSubmittal} from "./OpeningSizeSubmittal";
import React from "react";

export function MakeOpeningSizeSubmittal() {
    const [showCreateOpeningSizes, setShowCreateOpeningSizes] = React.useState(false);
    return (
        <>
            <SmallGreyButton onClick={() => setShowCreateOpeningSizes(true)} startIcon={<CropFreeIcon />}>
                Opening Size Submittal
            </SmallGreyButton>
            {showCreateOpeningSizes && <OpeningSizeSubmittal onDone={() => setShowCreateOpeningSizes(false)} />}
        </>
    )
}
import {
    Button,
    Dialog,
    DialogActions,
    FormControlLabel,
    List,
    ListItem,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import {useState} from "react";
import {RouteStopGroupItem} from "../../../api/Logistics";
import {WarehouseReceiveItem} from "../../../api/Receiving";

type TItem = RouteStopGroupItem | WarehouseReceiveItem

export function EditReceivingItem<T extends TItem>(props: {
    value: T;
    onChange(value: T): void;
    onCancel(): void;
}) {
    const [opt, setOpt] = useState("");
    const [received, setReceived] = useState("");
    const [damaged, setDamaged] = useState("");

    return (
        <Dialog open>
            <div style={{overflow: "auto"}}>
                <RadioGroup value={opt} onChange={e => setOpt(e.target.value)}>
                    <List style={{minWidth: 300}}>
                        <ListItem>
                            <div>
                                <FormControlLabel value="back-order" control={<Radio />} label="Back Order" />
                                {opt === "back-order" && <div style={{paddingLeft: 32}}>
                                    <TextField label="# Received" value={received} onChange={e => setReceived(e.target.value)} />
                                </div>}
                            </div>
                        </ListItem>
                        <ListItem>
                            <div>
                                <FormControlLabel value="damaged" control={<Radio />} label="Damaged" />
                                {opt === "damaged" && <div style={{paddingLeft: 32}}>
                                    <TextField label="# Damaged" value={damaged} onChange={e => setDamaged(e.target.value)} />
                                    <div style={{height: 8}} />
                                    <TextField label="# Ok" value={received} onChange={e => setReceived(e.target.value)} />
                                </div>}
                            </div>
                        </ListItem>
                    </List>
                </RadioGroup>
            </div>

            <DialogActions>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <Button variant="contained" color="secondary" onClick={() => {
                        props.onChange(Object.assign({}, props.value,{
                            qtyReceived: coalesceNaN(parseInt(received), 0),
                            qtyDamaged: coalesceNaN(parseInt(damaged), 0),
                        }))
                    }}>Save</Button>
                    <Button variant="contained" color="inherit" onClick={() => props.onCancel()}>Cancel</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

function coalesceNaN(input: number, defaultValue: number) {
    if(isNaN(input) || input === undefined) return defaultValue
    return input;
}
import React, {useContext, useRef, useState} from "react";
import {useSnackbar} from "../../misc/Snackbar";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid, MenuItem, Select,
    TextField
} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useNavigate} from "react-router-dom";
import { ActionResult } from "../../misc/ActionResult";
import {Project} from "../../api/Projects";
import {ListContext} from "../../misc/lists/ListContext";
import {Company} from "../../api/Companies";
import {ProvinceSelector} from "../clients/ProvinceSelector";
import {useUser} from "../../misc/Permission";
import {FindOrCreateCompany} from "./manage/summary/FindOrCreateCompany";

export function AddProjectModal(props: {
    edit?: Project;
    onClose(): void;
}) {

    const ctx = useContext(ListContext);
    const reload = useRef(ctx.reload);
    reload.current = ctx.reload;

    const [name, setName] = useState(props.edit?.name || "")
    const [street, setStreet] = useState(props.edit?.addressStreet || "")
    const [city, setCity] = useState(props.edit?.addressCity || "")
    const [prov, setProv] = useState(props.edit?.addressProvince || "")
    const [postal, setPostal] = useState(props.edit?.addressPostal || "")
    const [unitSystem, setUnitSystem] = useState(props.edit?.unitSystem || "imperial");
    const snack = useSnackbar();
    const navigate = useNavigate();

    const action = useAsyncAction(async (input) => {
        snack.loading();
        try {
            const result = await api.projects.upsert(input)
            props.onClose()

            if(!input.id) {
                snack.success("Created project");
                navigate("/project/" + result.id);
            } else {
                snack.success("Updated");
                reload.current();
            }

        } catch (e) {
            snack.hide();
            throw e;
        }
    }, [navigate, props.onClose]);

    const [client, setClient] = useState<Company|null>(null);
    const [contractor, setContractor] = useState<Company|null>(null);

    const me = useUser();

    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault();
                const changes: Partial<Project> = {
                    name: name,
                    client: client?.id,
                    contractor: contractor?.id,
                    unitSystem: unitSystem,
                    addressStreet: street,
                    addressCity: city,
                    addressProvince: prov,
                    addressPostal: postal,
                };

                if(!props.edit && me) {
                    changes.internalRep = me.id;
                }

                action.callback(Object.assign(props.edit || {}, changes))
            }}>
                <DialogTitle>
                    {props.edit ? "Edit Project" : "Create Project"}
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField label="Project Name" fullWidth
                                       value={name}
                                       onChange={e => setName(e.target.value)}
                                       style={{width: 300}}
                                       required
                            />
                        </Grid>
                        <Grid item>
                            <FindOrCreateCompany
                                label="General Contractor"
                                kind="contractor"
                                value={contractor}
                                onChange={setContractor}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                        <TextField size="small" label="Project Street Address" fullWidth
                                                   value={street}
                                                   onChange={e => setStreet(e.target.value)}
                                                   style={{width: 300}}
                                        />
                                    </Grid><Grid item>
                                        <TextField size="small" label="City" fullWidth
                                                   value={city}
                                                   onChange={e => setCity(e.target.value)}
                                                   style={{width: 300}}
                                        />
                                </Grid><Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <TextField size="small" label="Postal" fullWidth
                                                       value={postal}
                                                       onChange={e => setPostal(e.target.value)}
                                                       style={{width: 100}}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <ProvinceSelector size="small" value={prov} onChange={setProv} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FindOrCreateCompany
                                label="Owner"
                                kind="client"
                                value={client}
                                onChange={setClient}
                            />
                        </Grid>
                        <Grid item>
                            <Select value={unitSystem} size="small"  onChange={(e, value) => setUnitSystem(e.target.value as any)}>
                                <MenuItem value="imperial">Imperial</MenuItem>
                                <MenuItem value="metric">Metric</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        {props.edit && <Grid item xs>
                            <Button color="error" onClick={() => {
                                if(!props.edit) return;

                                action.callback(Object.assign({}, props.edit, {
                                    archived: !props.edit.archived,
                                }))
                            }}>{props.edit.archived ? "Unarchive" : "Archive"}</Button>
                        </Grid>}
                        <Grid item>
                            <ActionResult action={action} />
                        </Grid>
                        <Grid item>
                            <Button onClick={props.onClose}>Cancel</Button>
                            <Button type="submit">{props.edit ? "Update" : "Create"}</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ChangesetContainer} from "./ChangesetContainer";
import {Button, DialogActions, TextField, Grid} from "@mui/material";
import {useProjectId} from "../ProjectName";
import {useChangesetId} from "./ChangesetId";
import {Center} from "./Center";

export function SaveTrackingSession() {
    const nav = useNavigate();
    const projectId = useProjectId();
    const [value, setValue] = useState("")
    const [name, setName] = useState("");
    const sessionId = useChangesetId();

    const get = useAsync2(input => api.projectChangeset.get(input), {id: sessionId}, [sessionId]);
    const result = get.result;

    useEffect(() => {
        setValue(result?.content || "");
        setName(result?.name || "");
    }, [result])

    const update = useAsyncAction(async (input) => {
        await api.projectChangeset.save(input)
        nav(`/project/${projectId}/shop-drawing/changeset/${sessionId}/print-submissions`)
    }, []);

    return (
        <ChangesetContainer stage="save-session">
            <Center>
                <form onSubmit={e => {
                    e.preventDefault();

                    update.callback({
                        project: projectId,
                        content: value,
                        name: name,
                    })
                }}>
                    <div style={{padding: 16, paddingTop: 0}}>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    helperText="Something short we can use as a file name"
                                />
                            </Grid>
                            <Grid item>
                                <TextField multiline fullWidth label="Description" rows="4" value={value} onChange={e => setValue(e.target.value)} />
                            </Grid>
                        </Grid>
                    </div>

                    <DialogActions>
                        {update.LoadingElement}
                        <Button onClick={() => nav(`/project/${projectId}/shop-drawing/changeset/${sessionId}/review-pricing`)}>Back</Button>
                        <Button variant="contained" color="primary" type="submit">Next</Button>
                    </DialogActions>
                </form>
            </Center>
        </ChangesetContainer>
    )

}
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    MenuItem
} from "@mui/material";
import {AutoComplete} from "../../openings/AutoComplete";
import {api} from "../../../../../api/API";
import {useEffect, useState} from "react";
import {Project} from "../../../../../api/Projects";
import {useProjectId} from "../../ProjectName";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../../misc/SyncedRef";
import {useHwLibraryType} from "./HwLibraryType";
import {useAlternative} from "../../../quote/alternative/Alternative";
import {Select3} from "../../../quote/alternative/Select3";
import {LibraryType} from "../../../../../api/Library";

export function CopyLibraryFromProject(props: {
    onDone(): void;
}) {
    const [value, setValue] = useState<Project>();
    const [alt, setAlt] = useState<string>("-");

    const project = useProjectId();
    const alternative = useAlternative();
    const onDoneRef = useSyncedRef(props.onDone)
    const update = useAsyncAction(async (input: {
        from: number;
        fromQuoteAlternative: string;
        to: number;
        type: LibraryType;
        toQuoteAlternative: number | null;
    }) => {
        const {fromQuoteAlternative, ...otherProps} = input;

        await api.hardwareLibrary.copyFromProject(Object.assign({}, otherProps, {
            fromQuoteAlternative: fromQuoteAlternative === "-" ? null : parseInt(fromQuoteAlternative),
        }))
        onDoneRef.current();
    }, [])
    const type = useHwLibraryType();

    const alts =  useAsync2(async (input: {
        project: number | null
    }) => {
        if(!input.project) return [];
        const list = await api.quoteAlternatives.list({project: input.project, offset: 0, limit: 1000})
        return list.data;
    }, {
        project: value?.id || null,
    }, [value]);

    useEffect(() => {
        setAlt(value ? "project" : "-")
    }, [value])

    return (
        <Dialog open={true}>
            <DialogTitle>
                Copy Library from Project
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Overwrite the current hardware library with the hardware library from the project selected.
                </DialogContentText>

                <div style={{height: 10}} />

                <AutoComplete<Project>
                    fullWidth
                    search={async search => {
                        const rs = await api.projects.list({search: search, offset: 0, stage: "in-progress"});
                        return rs.data.filter(d => d.id !== project);
                    }}
                    value={value}
                    equals={(a, b) => a.id === b.id}
                    makeLabel={a => a.name}
                    onSelect={setValue} />

                <div style={{height: 10}} />

                {alts.LoadingOrErrorElement}

                <Select3 value={alt} onChange={(e) => setAlt(e.target.value as any)}>
                    <MenuItem value="-">Select Source</MenuItem>
                    <MenuItem value="project">Awarded Project</MenuItem>
                    <Divider />
                    {alts.asList.map(a => <MenuItem key={a.id} value={a.id.toString()}>Quote: {a.name}</MenuItem>)}
                </Select3>
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}
                <Button onClick={props.onDone}>Cancel</Button>
                <Button disabled={!value || alt === "-"} onClick={() => {


                    update.callback({
                        from: value?.id || 0,
                        fromQuoteAlternative: alt,
                        to: project,
                        type: type,
                        toQuoteAlternative: alternative ? alternative : null,
                    })
                }}>Overwrite</Button>
            </DialogActions>
        </Dialog>
    )
}
import {Button, Menu, MenuItem} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {green} from "@mui/material/colors";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Project} from "../../../../api/Projects";
import {orderByAscending, useAsync2} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {css} from "@emotion/css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Alternative} from "../../../../api/QuoteAlternatives";

const blankArray = [] as any;

export function ViewQuoteButton(props: {
    project: Project
}) {
    const project = props.project;
    const list = useAsync2(input => api.quoteAlternatives.list(input), {
        project: project.id,
    }, [project.id])

    const result = (list.result?.data || blankArray) as Alternative[];

    const [selected, setSelected] = useState<Alternative|null>(null);
    useEffect(() => {
        if(result.length === 0) return;

        orderByAscending(result, (x: any) => x.id);
        const last = result[result.length - 1];
        setSelected(last)
    }, [result]);

    const arrowRef = useRef<HTMLElement|null>(null);

    const [showOptions, setShowOptions] = useState(false);

    if(list.loading) {
        return <Button size="large" variant="contained" disabled fullWidth>Loading...</Button>
    }

    if(result.length === 0) {
        return (
            <Link target="_blank" to={`/project/${project.id}/quote`} style={{textDecoration: "none"}}>
                <Button size="large"
                        endIcon={<OpenInNewIcon />}
                        variant="contained"
                        fullWidth
                        style={{
                            backgroundColor: green["700"],
                        }}
                >
                    View Quote

                </Button>
            </Link>
        )
    }

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                width: "100%",
                boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)`,
            }}>
                <Link target="_blank" to={`/project/${project.id}/quote/${selected?.id}`} style={{textDecoration: "none", flex: 1, display: "flex"}}>
                    <button className={buttonCls} style={{
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                borderRight: "1px solid " + green["800"],
                                flex: 1,
                            }}
                    >
                        <div style={{
                            fontSize: "0.9375rem",
                            textTransform: "uppercase"
                        }}>
                            View Quote
                        </div>
                        <div style={{fontSize: "0.8rem", fontWeight: "normal"}}>
                            {selected?.name}
                        </div>
                    </button>
                </Link>
                <button ref={r => arrowRef.current = r} className={buttonCls} onClick={() => setShowOptions(true)} style={{
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                }}>
                    <ArrowDropDownIcon />
                </button>
            </div>
            <Menu open={showOptions} anchorEl={arrowRef.current} onClose={() => setShowOptions(false)}>
                {result.map(r => {
                    return (
                        <MenuItem key={r.id} onClick={() => {
                            setSelected(r);
                            setShowOptions(false);

                            window.open(`/project/${project.id}/quote/${r.id}`, "_blank")
                        }}>
                            {r.name}
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

const buttonCls = css({
    backgroundColor: green["700"],
    borderRadius: 4,
    color: "white",
    border: "none",
    textAlign: "left",
    fontWeight: "600",
    padding: 14,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: "pointer",

    "&:hover": {
        backgroundColor: green["800"],
    }
})
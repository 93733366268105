import {Button, Dialog, DialogActions, CircularProgress, DialogContent, DialogTitle, TextField} from "@mui/material";
import {first, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useEffect, useState} from "react";
import {useProjectId} from "../ProjectName";
import {KeyName} from "../../../../api/Keying";
import {KeyCount, useKeyingSubmittalProps} from "./submittal/KeyingSubmittal";

export function EditColumn(props: {
    value?: KeyName
    onCancel(): void;
    onDone(): void;
}) {
    const onDoneRef = useSyncedRef(props.onDone)
    const submit = useAsyncAction(async (input) => {
        const result = await api.keying.upsertName(input);

        const keyCount = first(prefsKeyCountRef.current, k => k.keyNameId === props.value?.id)
        let newCount: KeyCount[];
        if(!keyCount) {
            newCount = [...prefsKeyCountRef.current, {
                keyNameId: result.id,
                name: result.short,
                value: countRef.current,
            }]
        } else {
            newCount = prefsKeyCountRef.current.map(k => k === keyCount ? {
                ...k,
                name: result.short,
                value: countRef.current,
            } : k)
        }

        await prefs.save(newCount);

        onDoneRef.current();
    }, []);

    const [short, setShort] = useState(props.value?.short || "")
    const [long, setLong] = useState(props.value?.description || "")
    const project = useProjectId();

    const prefs = useKeyingSubmittalProps({})
    const prefsKeyCountRef = useSyncedRef(prefs.keyCount)
    const [count, setCount] = useState("");
    const countRef = useSyncedRef(count);

    useEffect(() => {
        const key = props.value?.id;
        if(!key) {
            console.log("missing props.value.id", props.value)
            return;
        }

        const count = first(prefs.keyCount, k => k.keyNameId === key);
        if(!count) {
            console.log("missing keyNameId", key, prefs.keyCount)
            return;
        }

        console.log("found key:", key, "'" + count.value + "'")

        setCount(count.value);
    }, [prefs.keyCount, props.value]);



    return (
        <Dialog open maxWidth="sm" fullWidth>
            <DialogTitle>
                {props.value ? "Edit Keying Type" : "Create Keying Type"}
            </DialogTitle>
            <form onSubmit={e => {
                e.preventDefault()

                submit.callback({
                    id: props.value?.id,
                    project: project,
                    short: short,
                    description: long,
                })
            }}>
                <DialogContent>
                    {prefs.loading ? <CircularProgress /> :
                    <>
                        <TextField style={{width: 200}} label="Short Name" value={short} onChange={e => setShort(e.target.value)} />
                        <div style={{height: 12}} />
                        <TextField fullWidth label="Long Name" value={long} onChange={e => setLong(e.target.value)} />
                        <div style={{height: 12}} />
                        <TextField style={{width: 200}} label="Key Count"
                                   value={count}
                                   onChange={e => setCount(e.target.value)} />
                    </>}
                </DialogContent>
                <DialogActions>
                    {props.value && <Button color="error" onClick={() => {
                        submit.callback({
                            id: props.value?.id,
                            project: project,
                            archived: true,
                        })
                    }}>Archive</Button>}
                    {submit.LoadingElement}

                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                        {props.value ? "Update" : "Add"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
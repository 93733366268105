import {TabWrapper} from "../release/Release";
import {Tab, Tabs} from "@mui/material";
import {defaultDiv10Tab, div10TabKeys, Div10Tabs} from "../div10/Div10Tabs";
import {defaultDoorTab, doorTabKeys, DoorTabs} from "../../quote/pricing/DoorTabs";
import {UnPricedItems, useUnPricedItems} from "./UnPricedItems";
import React from "react";
import {showNumber} from "./Pricing";
import {css} from "@emotion/css";

export function PricingTabs(props: {
    tab: string;
    subTab: string;
    onTabChange(value: string): void;
    onSubTabChange(value: string): void;
}) {
    const unpriced = useUnPricedItems();

    return (
        <>
            <TabWrapper>
                <Tabs className={tabClass} value={props.tab} onChange={(e, value) => {
                    props.onTabChange(value)

                    switch(value) {
                        case "div10":
                            // pick the first tab that has non-zero qty
                            for(let i = 0; i < div10TabKeys.length; i++) {
                                const k = div10TabKeys[i];
                                if(unpriced.details.div10Detail[k].total > 0) {
                                    props.onSubTabChange(k);
                                    return
                                }
                            }

                            // fallback to default
                            props.onSubTabChange(defaultDiv10Tab)
                            return
                        case "door":

                            // pick the first tab that has non-zero qty
                            for(let i = 0; i < doorTabKeys.length; i++) {
                                const k = doorTabKeys[i];
                                if(unpriced.details.doorsDetail[k].total > 0) {
                                    props.onSubTabChange(k);
                                    return
                                }
                            }

                            props.onSubTabChange(defaultDoorTab)
                            return;
                        default:
                            props.onSubTabChange("")
                            break;
                    }
                }}>
                    <Tab label="Summary" value="summary" />
                    <Tab label={<>{showNumber("Frames", unpriced.details.frames)}</>} value="frame" />
                    <Tab label={<>{showNumber("Doors", unpriced.details.doors)}</>} value="door" />
                    <Tab label={<>{showNumber("Hardware", unpriced.details.hardware)}</>} value="hardware" />
                    <Tab label={<>{showNumber("Div 10", unpriced.details.div10)}</>} value="div10" />
                    <Tab label={<>{showNumber("Misc", 0)}</>} value="misc" />
                    <UnPricedItems />
                </Tabs>
            </TabWrapper>
            {props.tab === "div10" && props.subTab && <Div10Tabs unpriced={unpriced.details} tab={props.subTab} onChange={props.onSubTabChange} />}
            {props.tab === "door" && props.subTab && <DoorTabs unpriced={unpriced.details} tab={props.subTab} onChange={props.onSubTabChange} />}
        </>
    )
}

const tabClass = css({
    "& .MuiTabs-flexContainer": {
        alignItems: "center",
    },

    "& .MuiTab-root.MuiButtonBase-root": {
        flexWrap: "nowrap"
    }
})
import {createContext, PropsWithChildren, useContext, useMemo} from "react";
import {FormControlLabel, Switch} from "@mui/material";
import {css} from "@emotion/css";
import {useQueryParam} from "../pricing/QueryParam";

export type ViewMode = "default" | "by-opening"

export const HardwareViewModeContext = createContext({
    viewMode: "default" as ViewMode,
    setViewMode: (mode: ViewMode) => {}
})

export function HardwareViewModeProvider(props: PropsWithChildren<{}>) {
    const [viewMode, setViewMode] = useQueryParam("hwViewMode", "default");

    const ctx = useMemo(() => {
        return {
            viewMode: viewMode as ViewMode,
            setViewMode: setViewMode as any
        }
    }, [viewMode, setViewMode])

    return (
        <HardwareViewModeContext.Provider value={ctx}>
            {props.children}
        </HardwareViewModeContext.Provider>
    )
}

export function HardwareViewMode() {
    const ctx = useContext(HardwareViewModeContext);

    return (
        <div style={{paddingLeft: 4}}>
            <FormControlLabel className={labelCls} control={<Switch size="small" checked={ctx.viewMode === "by-opening"} onChange={(_, checked) => {
                ctx.setViewMode(checked ? "by-opening" : "default")
            }} />} label="By Opening" />
        </div>
    )
}

const labelCls = css({
    "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        paddingLeft: 4,
    },
})
import {api} from "../../../api/API";
import moment from "moment";
import {DashCardData} from "./DashCardData";;

export function ReadyToPurchase() {
    return (
        <DashCardData
            lookup={() => api.logistics.listToSourceItems({})}
            title="Ready to Purchase"
            itemTitle={d => d.name}
            itemSubtext={d => d.neededBy ? "Due " + moment(d.neededBy).format("MMMM D") : "No due date"}
            itemRight={d => d.qty + " items"}
            link="/logistics/purchasing"
        />
    )
}


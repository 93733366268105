import {useContext} from "react";
import {ToDeliverProps, toDeliverTableName} from "./ToDeliver";
import {
    BoolCol,
    Column,
    NumberCol,
    StringCol,
    Table,
    TableSelectionContext,
    ViewOnly
} from "../../../misc/scroller/Table";
import {SnackContext} from "../../../misc/Snackbar";
import {ToDeliverItem} from "../../../api/Deliveries";
import {TDiv10Type} from "../../project/shopdrawing/div10/Div10Tabs";
import {AddCol} from "./Frame";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";

export function Div10(props: ToDeliverProps & {
    div10Type: TDiv10Type;
}) {
    const ctx = useContext(TableSelectionContext);
    const snack = useContext(SnackContext);

    return (
        <Table<ToDeliverItem>
            key={"div10" + props.div10Type}
            overridePrefsName={toDeliverTableName + ".div10"}
            name={toDeliverTableName}
            columns={[
                ...perSectionColumns(props.div10Type),
                ViewOnly(NumberCol("Qty", "qty", 60)),
                AddCol(props, {
                    ctx, snack
                }),
            ]}
            fetch={ctx => getAll(ctx, offset => api.deliveries.partsToDeliver({
                offset,
                project: props.project,
                type: "div10",
                div10Type: props.div10Type,
            }))}
            fetchDeps={[props.project, props.div10Type]}
        />
    )
}

function perSectionColumns(tab: TDiv10Type): Column<ToDeliverItem>[] {
    switch(tab) {
        case "wr-accessory":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Code", "productCode")),
                ViewOnly(StringCol("Category", "name")),
                ViewOnly(StringCol("Description", "description")),
                ViewOnly(StringCol("Finish", "finish")),
                ViewOnly(StringCol("Note", "div10Note")),
            ]
        case "wr-partition":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Mounting", "div10PartitionBracing")),
                ViewOnly(StringCol("Material", "div10PartitionCoating")),
                ViewOnly(StringCol("Description", "div10Description")),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        case "locker":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Tiers", "div10LockerTiers")),
                ViewOnly(StringCol("Material", "div10LockerCoating")),
                ViewOnly(BoolCol("Sloped Tops", "div10LockerSloped")),
                ViewOnly(BoolCol("End Panels", "div10LockerEndPanels")),
                ViewOnly(BoolCol("Base Trim", "div10LockerBaseTrim")),
                ViewOnly(StringCol("Width", "dimWidth", 60)),
                ViewOnly(StringCol("Height", "dimHeight", 60)),
                ViewOnly(StringCol("Depth", "dimLength", 60)),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        case "mailbox":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(NumberCol("Tenant Units", "div10MailboxTenantUnits")),
                ViewOnly(NumberCol("Parcel Units", "div10MailboxParcelUnits")),
                ViewOnly(StringCol("Loading", "div10MailboxLoading")),
                ViewOnly(StringCol("Mounting", "div10MailboxMounting")),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        case "corner-guard":
            return [
                ViewOnly(StringCol("Room", "div10Room")),
                ViewOnly(StringCol("Type", "div10CornerGuardType")),
                ViewOnly(StringCol("Code", "productCode")),
                ViewOnly(StringCol("Finish", "div10CornerGuardFinish")),
                ViewOnly(StringCol("Width", "dimWidth")),
                ViewOnly(StringCol("Height", "dimHeight")),
                ViewOnly(StringCol("Notes", "div10Note")),
            ]
        default:
            return [];
    }
}
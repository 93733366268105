import {ProjectDir, ProjectFile} from "../../../../api/ProjectFiles";
import {columnOuter} from "./FileColumn";
import {api} from "../../../../api/API";
import React from "react";
import {isProjectFile} from "./Rename";
import {Icon} from "./Icon";
import {Approvals} from "./Approvals";
import moment from "moment";
import {FileDetailChangesetActions} from "./FileDetailChangesetActions";
import {FileDetailActions} from "./FileDetailActions";
import {Typography} from "@mui/material";

export function FileDetail(props: {
    file: ProjectFile|ProjectDir;
    noApproval?: boolean;
    onChange(nextId?: number): void;
}) {
    const isChangeSetFile = (isProjectFile(props.file) && !!props.file.linkedChangeSet)

    return (
        <div className={columnOuter} style={{width: 300}}>
            <div style={{padding: 20, display: "flex",
                flexDirection: "column",
                alignItems: "stretch"
            }}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Icon value={props.file} />
                    <div style={{width: 6}} />
                    {props.file.name}
                </div>

                <div style={{height: 10, flexShrink: 0, flexBasis: 10}} />

                {isProjectFile(props.file) && <>
                    {props.noApproval ? null : <>
                        <Approvals value={props.file} onChange={props.onChange} />
                        <div style={{height: 10, flexShrink: 0, flexBasis: 10}} />
                    </>}

                    <div>
                        <div>
                            <div><Typography variant="caption">Created</Typography></div>
                            {moment(props.file.createdAt).format("YYYY-MMM-DD HH:mm a")}</div>
                        <div>
                            <div><Typography variant="caption">By</Typography></div>
                            {props.file.createdByName}
                        </div>
                    </div>

                    <div style={{height: 10, flexShrink: 0, flexBasis: 10}} />

                    {isChangeSetFile && isProjectFile(props.file) ?
                        <FileDetailChangesetActions file={props.file} /> :
                        <FileDetailActions file={props.file} onChange={props.onChange} />}
                </>}
            </div>
        </div>
    )
}

export async function archive(props: {
    confirm: (input: string) => Promise<boolean>;
    file: ProjectFile|ProjectDir
    snack: {
        loading(): void
        error(input: any): void;
        success(input: any): void;
    }
    onChange(nextId?: number): void;
}) {
    if(isProjectFile(props.file)) {
        const ok = await props.confirm(`Are you sure you want to archive ${props.file.name}?`)
        if (!ok) return;
    } else {
        const ok = await props.confirm(`Are you sure you want to archive ${props.file.name} and it's contents?`)
        if (!ok) return;
    }

    try {
        props.snack.loading();

        if(isProjectFile(props.file)) {
            await api.projectFiles.upsert(Object.assign({}, props.file, {
                archived: true,
            }))
        } else {
            await api.projectFiles.upsertDir(Object.assign({}, props.file, {
                archived: true,
            }))
        }

        props.snack.success("Removed");
    } catch (e: any) {
        props.snack.error(e.toString())
    }

    if(isProjectFile(props.file)) {
        props.onChange(props.file.directory)
    } else {
        props.onChange(props.file.parent)
    }
}
import {ConfirmShortcodeChange} from "../../../../api/QuoteHardware";
import React, {useEffect, useState} from "react";
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    MenuItem,
    RadioGroup,
    Select,
    TextField
} from "@mui/material";
import {ShortCodeDetailSection} from "./ShortCodeDetailSection";
import {grey, red} from "@mui/material/colors";
import {css} from "@emotion/css";

export function ResolveShortcodeChangesDialog(props: {
    value: ConfirmShortcodeChange
    error: string|null;
    loading?: boolean;
    onCancel(): void;
    onDone(value: {
        createShortcode?: {name: string; description: string};
        updateShortcode?: number;
        linkToShortcode?: number;
    }): void
}) {
    const v = props.value;

    // what would you like to do?
    // - create new shortcode
    // - update existing shortcode
    // - link to existing shortcode that is identical

    const [mode, setMode] = useState<string>("create");
    const [linkTo, setLinkTo] = useState<number>(-1);
    const [newShortCode, setNewShortCode] = useState<string>("");
    const [newShortCodeDesc, setNewShortCodeDesc] = useState<string>("");

    useEffect(() => {
        if(v.exactMatches && v.exactMatches.length > 0) {
            setLinkTo(v.exactMatches[0].id);
        }
    }, [v.exactMatches]);

    return (
        <Dialog open>
            <DialogTitle>Update Shortcode Detail</DialogTitle>
            <Divider />
            <DialogContent style={{backgroundColor: grey["200"]}}>
                <div style={{height: 8}} />
                <RadioGroup value={mode} onChange={(e, value) => setMode(value)}>
                    {v.existingId && <ShortCodeDetailSection title={`Update shortcode '${v.existingName}'`} value="update">
                        <b>{v.existingName}</b> is already linked to this hardware heading and can be updated.
                        {v.requiredChanges && v.requiredChanges.length > 0 && <div style={{marginTop: 8}}>
                            The shortcode will be updated as follows to match.
                            <div style={{height: 4}} />
                            <table className={tableClass}>
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Finish</th>
                                    <th>Old Qty</th>
                                    <th>New Qty</th>
                                </tr>

                            {v.requiredChanges.map(r => {
                                return <tr key={r.product.toString()}>
                                    <td>{r.productName}</td>
                                    <td>{r.productCode}</td>
                                    <td>{r.finish}</td>
                                    <td style={{textAlign: "right"}}>{formatQty(r, r.qty)}</td>
                                    <td style={{textAlign: "right"}}>{formatQty(r, r.newQty)}</td>
                                </tr>
                            })}
                                </tbody>
                            </table>
                        </div>}

                        {v.existingModifiesGroupNames && v.existingModifiesGroupNames.length > 0 && <div style={{marginTop: 16}}>
                            Updating the shortcode will also affect groups <b>{v.existingModifiesGroupNames.join(", ")}</b> because they are also linked to this shortcode.
                        </div>}
                    </ShortCodeDetailSection>}
                    {v.exactMatches && v.exactMatches.length > 0 && <ShortCodeDetailSection title="Link to existing shortcode" value="link">
                        <div style={{height: 8}} />
                        <Select disabled={mode !== "link"} value={linkTo} onChange={(e, value) => setLinkTo(e.target.value as number)}>
                            {v.exactMatches.map(m =>
                                <MenuItem key={m.id} value={m.id.toString()}>{m.name}</MenuItem>)}
                        </Select>
                    </ShortCodeDetailSection>}
                    <ShortCodeDetailSection title="Create new shortcode for item list" value="create">
                        <div style={{height: 8}} />
                        <TextField disabled={mode !== "create"} label="Short Code" style={{width: 120}} value={newShortCode} onChange={e => setNewShortCode(e.target.value.toUpperCase())} />
                        <div style={{height: 8}} />
                        <TextField disabled={mode !== "create"} fullWidth label="Description" value={newShortCodeDesc} onChange={e => setNewShortCodeDesc(e.target.value)} />
                    </ShortCodeDetailSection>
                </RadioGroup>
            </DialogContent>
            <Divider />
            <DialogActions>
                {props.loading ? <CircularProgress /> : null}
                {props.error && <div style={{display: "inline-block", color: red["700"]}}>{props.error}</div>}
                <Button onClick={() => props.onCancel()}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => {
                    if(mode === "create" && newShortCode) {
                        props.onDone({
                            createShortcode: {
                                name: newShortCode,
                                description: newShortCodeDesc,
                            }
                        });
                        return;
                    }

                    if(mode === "update" && v.existingId) {
                        props.onDone({
                            updateShortcode: v.existingId
                        });
                        return;
                    }

                    if(mode === "link" && linkTo) {
                        props.onDone({
                            linkToShortcode: linkTo
                        });

                        return;
                    }

                }}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

const tableClass = css({
    width: "100%",
    border: "1px solid " + grey["400"],
    borderCollapse: "collapse",

    "& tr:nth-child(odd) *": {
        backgroundColor: grey["200"],
    },

    "& th": {
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: "0.8rem",
        fontWeight: "normal",
        textAlign: "left",
    },

    "& td": {
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
    }
})

function formatQty(r: {productIsAutoQtyHinge: boolean}, qty: number) {
    if(qty === 0) return "0"
    return r.productIsAutoQtyHinge ? "(auto)": qty;
}
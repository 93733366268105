import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";

export type CompanyListInput =  {
    offset?: number;
    search?: string;
    source?: "hardware" | "div10" | "frames" | "doors"
    kind?: CompanyKind | "client-or-contractor";
}

export class Companies extends SubAPI {
    list(props?: CompanyListInput) {
        return this.fetcher.get<Paginated<Company>>("/api/companies", props);
    }

    get(props: {
        id: number;
    }) {
        return this.fetcher.get<Company>("/api/company", props);
    }

    upsert(input: Partial<Company>) {
        return this.fetcher.post<Company>("/api/company", input)
    }
}

export type Company = {
    id: number;
    name: string;

    shortName?: string;

    defaultDistributor?: number;
    defaultDistributorName?: string;

    sourceHardware: boolean;
    sourceFrames: boolean;
    sourceDoors: boolean;
    sourceDoorsWood: boolean;
    sourceDiv10: boolean;

    addressStreet: string;
    addressCity: string;
    addressProvince: string;
    addressPostal: string;

    kind: CompanyKind;

    archived: boolean;
}

export type CompanyKind = "client" | "supplier" | "contractor";
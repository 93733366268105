import {Container} from "../../../misc/Container";
import React from "react";
import {StockCountItem} from "../../../api/Logistics";
import {useQueryParam} from "../../project/quote/pricing/QueryParam";
import {
    NumberCol,
    PrefixSearch,
    StringCol,
    Table,
    ViewOnly
} from "../../../misc/scroller/Table";
import {api} from "../../../api/API";
import {WhiteColumn} from "../purchasing/WhiteColumn";
import {SectionHeader} from "../../../misc/SectionHeader";
import {Button, Grid, Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../../project/shopdrawing/release/Release";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {RestockStatus} from "../warehouse/Restocking";
import {useNavigate, useParams} from "react-router-dom";
import {Checkbox2Col} from "../../project/shopdrawing/ShopTableCol";
import {useSnackAction} from "../../project/shopdrawing/hardware/SnackAction";
import {grey} from "@mui/material/colors";

export function StockCount() {
    return (
        <Container focused>
            <StockCountInner />
        </Container>
    )
}

function StockCountInner() {
    const [tab2, setTab2] = useQueryParam("tab2", "hardware");
    const params = useParams<{id: string}>();
    const stockCount = parseInt(params.id || "0");
    const nav = useNavigate();

    const complete = useSnackAction(async (input) => {
        await api.logistics.completeStockCount(input)
        nav("/logistics/warehouse");
    }, {
        success: "Stock count submitted",
    });

    return (
        <WhiteColumn>
            <SectionHeader
                primary
                name="Stock Count"
                right={<Grid container spacing={1}>
                    <Grid item><Button
                        color="inherit"
                        onClick={() => {
                            nav("/logistics/warehouse");
                        }}>Cancel</Button></Grid>
                    <Grid item>
                        <Button variant="contained" style={{backgroundColor: "white", color: grey["800"]}} color="inherit" onClick={() => {
                            complete.callback({
                                id: stockCount,
                            });
                        }}>Complete</Button>
                    </Grid>
                </Grid>}
            />
            <TabWrapper>
                <Tabs value={tab2} onChange={(_, v) => setTab2(v)}>
                    <Tab value="frame" label="Frames" />
                    <Tab value="door" label="Doors" />
                    <Tab value="hardware" label="Hardware" />
                    <Tab value="div-10" label="Div 10" />
                    <Tab value="frame-anchor" label="Anchors" />
                    <Tab value="component" label="Components" />
                </Tabs>
            </TabWrapper>
            <Table<StockCountItem>
                key={"stockCount." + tab2}
                name={"stockCount." + tab2}
                columns={[
                    ViewOnly(StringCol("Mfg", "manufacturerName", 100)),
                    ViewOnly(StringCol("Description", "name", 100)),
                    ViewOnly(PrefixSearch(StringCol("Code", "productCode", 100))),
                    ViewOnly(StringCol("Dim.", "dimensions", 200)),
                    ViewOnly(NumberCol("Expect Qty", "qtyExpected", 60)),
                    NumberCol("Actual Qty", "qtyCounted", 60),
                    Checkbox2Col("Checked", "checked"),
                    RestockStatus(),
                    ViewOnly(NumberCol("Min Qty", "stockMinQty", 40)),
                    ViewOnly(NumberCol("Ideal Qty", "idealQty", 40)),
                ]}
                fetch={ctx => getAll(ctx, offset => api.logistics.listStockCountItems({
                    offset,
                    kind: tab2 as any,
                    stockCount,
                }))}
                fetchDeps={[tab2, stockCount]}
                onChange={value => api.logistics.updateStockCountItem(value)}
            />
        </WhiteColumn>
    )
}
import {api} from "../../../../api/API";
import {useGetAll} from "../../../logistics/routes/useGetAll";
import {useProjectId} from "../ProjectName";
import {distinct} from "nate-react-api-helpers";
import {Alert} from "@mui/material";

export function WarnMissingScreenElevation() {
    const projectId = useProjectId()

    const list = useGetAll(input => api.openings.list(input as any), {
        project: projectId,
        type: "for-release",
    }, [
        projectId
    ]);

    const missing = list.asList.filter(v => isDoor(v.openingType) && v.screenElevation && !v.screenElevationFile)
    if(missing.length === 0) return null;

    const names = missing.map(v => v.name).join(", ")
    const elevNames = distinct(missing.map(v => v.screenElevation).filter(v => !!v)).join(", ")

    return (
        <Alert severity="warning">
            Missing a screen elevation file on the following openings: {names}.<br />
            {elevNames.length > 0 && <>Check elevation names: "{elevNames}"</>}
        </Alert>
    )
}

function isDoor(typ: string) {
    switch(typ) {
        case "Single":
        case "Pair":
            return true;
        default:
            return false;
    }
}
import {ProductQuery, TimelineItem} from "../../../../api/ProjectTimeline";
import React, {useState} from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {StaticDatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {MakeProductQuery} from "./MakeProductQuery";

export function EditTimelineItem(props: {
    project: number;
    value?: TimelineItem;
    onDone(value?: TimelineItem): void;
}) {
    const [title, setTitle] = useState(props.value?.name || "");
    const [date, setDate] = useState<string|null>(() => moment.utc(props.value?.date).format("YYYY-MM-DD") || null);
    const [query, setQuery] = useState<ProductQuery|null>(props.value?.useAsReleaseDateForQuery || null);
    const [minMatchingDate, setMinMatchingDate] = useState<Date|null>(null);

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction(async (input) => {
        const result = await api.projectTimeline.upsert(input);
        onDoneRef.current(result);
    }, [])

    return (
        <Dialog open>
            <DialogTitle>
                {props.value ? "Edit Timeline Item" : "Create Timeline Item"}
            </DialogTitle>
            <DialogContent>
                <div style={{height: 8}} />
                <TextField label="Title" fullWidth value={title} onChange={e => setTitle(e.target.value)} />
                <div style={{height: 8}} />

                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={date}
                    onChange={(newValue) => {
                        setDate(newValue ? moment(newValue).utc().format("YYYY-MM-DD") : null);
                    }}
                    renderInput={(params) => <TextField {...params as any} />}
                />

                <FormControlLabel control={<Switch
                    checked={query !== null}
                    onChange={(e, checked) => {
                        setQuery(checked ? {} : null);
                    }} />} label="Use as due date for logistics" />

                {query && <MakeProductQuery value={query} onChange={setQuery} onMinMatchingDate={setMinMatchingDate} />}

            </DialogContent>
            <DialogActions>
                {update.LoadingElement}
                {minMatchingDate && date && query !== null && minMatchingDate.valueOf() < moment(date).valueOf() && <Alert color="warning">
                    Some due dates are before {moment(date).utc().format("YYYY-MMM-DD")} and will be pushed back.
                </Alert>}
                <Button onClick={() => props.onDone()}>Cancel</Button>
                <Button variant="contained" disabled={!title || !date} color="primary"
                        onClick={() => {
                            let base = props.value || {};

                            update.callback(Object.assign({}, base, {
                                name: title,
                                date: date, // should be YYYY-MM-DD format
                                project: props.value?.project || props.project,
                                useAsReleaseDateForQuery: query,
                            }))

                        }}>{props.value ? "Update" : "Create"}</Button>
            </DialogActions>
        </Dialog>
    )
}
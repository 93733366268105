import {useEffect} from "react";

export function useCropImageAutoResize(imageRef: HTMLImageElement | null) {// resize image to fill 90% of the available space
    useEffect(() => {
        if(!imageRef) return;
        const wrapper = imageRef.parentElement;
        if(!wrapper) return;

        wrapper.style.width = "90%";
        wrapper.style.height = "90%";

        let hasInit = false;

        const observe = new ResizeObserver(rects => {
            if(!hasInit) {
                if(!wrapper.parentElement) return;
                const maxRect = wrapper.parentElement.getBoundingClientRect();
                const rect = rects[0];

                if(maxRect.width === 0) return;
                if(maxRect.height === 0) return;
                if(rect.contentRect.width === 0) return;
                if(rect.contentRect.height === 0) return;

                wrapper.style.width = Math.min(rect.contentRect.width, maxRect.width) + "px";
                wrapper.style.height = Math.min(rect.contentRect.height, maxRect.height) + "px";
                hasInit = true;
                observe.disconnect();
                return;
            }
        });

        observe.observe(imageRef);

        return () => {
            observe.disconnect();
        }
    }, [imageRef])
}
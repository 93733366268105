import React from "react";
import {css} from "@emotion/css";

const imageWrapper = css({
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    flex: 1,
})

const image = css({
    maxWidth: "100%",
    maxHeight: "100%",
})

export function FullSizeImage(props: { src: string }) {
  if (!props.src.startsWith("data:")) {
    // use <img so that chrome will wait for the load complete before printing
    // and referenced files are "hopefully" have more predicable sizes
    return (
      <div className={imageWrapper}>
        <img
          src={fixPdfPath(props.src)}
          className={image}
          alt="full page screenshot"
        />
      </div>
    );
  }

  // use background image for data-urls b/c they have all different dimensions (from canvas screenshots)
  return (
    <div
      className={imageWrapper}
      style={{ backgroundImage: "url('" + fixPdfPath(props.src) + "')" }}
    />
  );
}

export function fixPdfPath(input: string) {
    if(input.startsWith("/")) {
        let route = window.location.protocol + "//" + window.location.host + input
        console.log("route-fixed", route);
        return route;
    }

    return input;
}
import {AdjustCol, NominalWidth, NumberCol, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import {Opening} from "../../../../api/Openings";
import {CheckboxSelectorCol} from "./Checkbox";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {useUser} from "../../../../misc/Permission";
import {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {hollowMetalDoor, woodDoor} from "../../quote/openings/QuoteOpenings";
import {projectTablePrefName} from "../TablePrefName";
import {useProjectUnitSystem} from "../ProjectName";

export function DoorTable(props: {
    project: number
    tab: string;
}) {
    const {tab, project} = props;

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    const sys = useProjectUnitSystem();
    if(!u) return null;
    if(!sys) return null;

    return (
        <Table<Opening>
            key={tab}
            locked={locked}
            name={projectTablePrefName("project.release.doors", project)}
            globalPrefsName={projectTablePrefName("project.release.doors", 0)}
            columns={[
                CheckboxSelectorCol(),
                ViewOnly(StringCol("Floor", "floor", 80)),
                AdjustCol(ViewOnly(StringCol("Opening", "name", 80)), {
                    fixed: true
                }),
                ViewOnly(StringCol("Type", "openingType", 150)),
                ViewOnly(NumberCol("Qty", "qty", 50)),
                ViewOnly(NominalWidth<Opening>("Nominal Width", sys, "nominalWidth", "inactiveDoorWidth")),
                ViewOnly(StringCol("Nom. Height", "nominalHeight", 150)),
                ViewOnly(StringCol("Elevation", "doorElevation", 150)),
                ViewOnly(StringCol("Handing", "handing", 150)),
                ViewOnly(StringCol("Label", "label", 150)),
                ViewOnly(StringCol("Series", "doorSeries", 150)),
                ViewOnly(StringCol("Material", "doorMaterial", 150)),
                ViewOnly(StringCol("Finish", "doorFinish", 150)),
                ViewOnly(StringCol("Gauge", "doorGauge", 80)),
                ViewOnly(StringCol("Thickness", "doorThickness", 100)),
                ViewOnly(StringCol("Elevation", "doorElevation", 100)),
                ViewOnly(StringCol("Core", "doorCore", 70)),
                ViewOnly(StringCol("Seam", "doorSeam", 70)),
            ]}
            fetch={async (ctx) => {
                const list = await getAll(ctx, offset => api.openings.list({
                    project: project,
                    type: "for-release",
                    category: "door",
                    offset,
                }))

                return list.filter(l => {
                    if(l.doorSeries === hollowMetalDoor) {
                        return tab === "hm";
                    }

                    if(l.doorSeries === woodDoor) {
                        return tab === "wd";
                    }

                    return false;
                })
            }}
            fetchDeps={[project, tab]}
        />
    )
}

export function tabToCategory(tab: string) {
    if(tab.startsWith("door-")) {
        return "door"
    }

    return tab
}
import {ViewMode} from "./HardwareViewMode";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@mui/material";
import {useAsyncAction} from "nate-react-api-helpers";
import {useRef, useState} from "react";
import {SelectWithLabel} from "./SelectWithLabel";

export function CustomizeHardwareSchedule(props: {
    onHide(): void;
    submit(config: {
        viewMode: ViewMode;
    }): Promise<any>
}) {

    const [viewMode, setViewMode] = useState<ViewMode>("default")

    const submitRef = useRef(props.submit);
    submitRef.current = props.submit;

    const onHideRef = useRef(props.onHide);
    onHideRef.current = props.onHide;

    const update = useAsyncAction(async (input) => {
        await submitRef.current(input)
        onHideRef.current()
    }, []);

    return (
        <Dialog open onClose={props.onHide}>
            <DialogTitle>Customize Hardware Schedule</DialogTitle>
            <DialogContent>
                <div style={{height: 4}} />
                <SelectWithLabel label="Mode" fullWidth value={viewMode} onChange={e => setViewMode(e.target.value as any)}>
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="by-opening">By Opening</MenuItem>
                </SelectWithLabel>
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}
                <Button onClick={props.onHide}>Cancel</Button>
                <Button variant="contained" onClick={() => update.callback({
                    viewMode: viewMode,
                })}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}
import {useContext} from "react";
import {TablePersonalizeContext} from "./TablePersonalize";
import {TableContext} from "./Table";

export function useColumnIndex(columnName: string) {
    const tbl = useContext(TableContext);
    const personal = useContext(TablePersonalizeContext)

    const cols = personal.getTableColumns(tbl.columns)
    const col = cols.standardColumns.find(s => s.name === columnName)
    if(!col) {
        console.error("can't find column name", columnName)
        return -1;
    }

    return cols.standardColumns.indexOf(col);
}
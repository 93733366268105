import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Table, TableBody,
    TableCell, TableHead, TableRow
} from "@mui/material";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import moment from "moment";
import {DriverRoute, RouteStop} from "../../../api/Logistics";
import {useNavigate} from "react-router-dom";

export function AddPODialog(props: {
    route: DriverRoute
    stop: RouteStop;
    onClose(): void;
}) {
    const manufacturer = props.stop.manufacturer;
    const list = useAsync2(async (input) => {
        if(!input.supplier) return [];

        return getAll(null, offset =>
            api.logistics.listPurchaseOrders({
                offset,
                supplier: input.supplier,
                forRouting: true,
            })
        );
    }, {
        supplier: manufacturer,
    }, [manufacturer]);

    const nav = useNavigate();

    const update = useAsyncAction(async (poId: number) => {
        await api.logistics.addRouteItems({
            route: props.route.id,
            items: [{
                pickupPo: poId,
                stop: props.stop.id,
            }]
        });

        const stops = await api.logistics.listRouteStops({
            route: props.route.id,
        })

        const stop2 = stops.find(s => s.id === props.stop.id);
        if(!stop2) {
            throw new Error("unable to link po to stop, refresh the page and try again");
        }

        if(!stop2.groups) {
            throw new Error("stop has no groups, refresh the page and try again");
        }

        const group = stop2.groups.find(g => g.pickupPurchaseOrder === poId);
        if(!group) {
            throw new Error("unable to link po to stop, refresh the page and try again");
        }

        nav(`/logistics/driver/${props.route.id}/${props.stop.id}/${group.id}`)
    }, [props.route, props.stop])

    return (
        <Dialog open>
            <DialogTitle>Add PO</DialogTitle>
            <DialogContent style={{paddingLeft: 0, paddingRight: 0}}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontSize: "1.3rem"}}>
                                PO #
                            </TableCell>
                            <TableCell style={{fontSize: "1.3rem"}}>
                                Submitted On
                            </TableCell>
                            <TableCell padding="checkbox">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.asList.map(l => (<TableRow key={l.id}>
                            <TableCell style={{fontSize: "1.3rem"}}>
                                {l.poNumber}
                            </TableCell>
                            <TableCell style={{fontSize: "1.3rem"}}>
                                {moment(l.submittedAt).format("YYYY-MMM-DD")}
                            </TableCell>
                            <TableCell padding="checkbox">
                                <Button onClick={() => {
                                    update.callback(l.id);
                                }}>
                                    Receive
                                </Button>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
                {list.LoadingOrErrorElement}
            </DialogContent>
            <DialogActions>
                {update.LoadingElement}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Button fullWidth variant="outlined" disabled={update.loading} onClick={props.onClose}>Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
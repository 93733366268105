import {useContext, useState} from "react";
import {EditContext} from "../../../misc/scroller/EditProvider";
import {TableSelectionContext} from "../../../misc/scroller/Table";
import {SimpleIconButton} from "../../../misc/lists/SimpleIconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import {MenuItem, Popover} from "@mui/material";
import {red} from "@mui/material/colors";
import {Archive} from "./Archive";
import {DragIcon} from "../../project/quote/openings/RowActions";

export type RowOption<T> = false | null | {
    name: any;
    onClick: OnClick<T>,
    danger?: boolean;
}

export type OnClick<T> = (ctrl: OnClickParams<T>) => void;
export type OnClickParams<T> = {
    onClose(): void
    refreshTable(name: string): void;
    update(input: T): Promise<any>,
    display(input: ModalRender): void;
};

export function RowOptionCol<T>(input: {
    options: (dt: T) => RowOption<T>[]
    draggable?: boolean;
}) {
    return {
        name: "",
        render: (dt: T) => <RowOptions draggable={input.draggable} options={input.options(dt)} />,
        width: 35,
    }
}

export function removeRowOption<T>(dt: T, opts: {
    refreshTables: string[],
    onComplete?: () => void,
    confirm?: boolean;
}) {
    return {
        name: "Remove",
        danger: true,
        onClick: async (input: OnClickParams<T>) => {
            input.onClose();

            const doArchive = async () => {
                await input.update(Object.assign({}, dt, {
                    archived: true,
                }))

                opts.refreshTables.map(name => input.refreshTable(name))
                if(opts.onComplete) {
                    opts.onComplete();
                }
            }

            if(opts.confirm) {
                input.display((onDone) => <Archive onDone={onDone} onArchive={doArchive} />)
                return;
            }

            await doArchive();
        }
    }
}

export type ModalRender = (onDone: () => void) => any;

export function RowOptions<T>(props: {
    draggable?: boolean;
    options: RowOption<T>[];
}) {
    const [anchor, setAnchor] = useState<HTMLDivElement|null>(null);
    const edit = useContext(EditContext);
    const ctx = useContext(TableSelectionContext);
    const [display, setDisplay] = useState<{
        render: ModalRender;
    } | null>(null);

    return (
        <div onClick={e => {
            e.stopPropagation() // prevent row-clicks when this cell is clicked
        }} style={{display: "flex", alignItems: "center"}}>
            {props.draggable && <DragIcon style={{marginTop: 0, marginLeft: 0}} />}
            <SimpleIconButton onClick={(e) => {
                setAnchor(e.event.target as any);
            }}>
                <MoreHoriz />
            </SimpleIconButton>
            {anchor && <Popover open={true} anchorEl={anchor} onClose={() => setAnchor(null)}>
                {props.options.map(o => {
                    if(!o) return null;
                    const value = o;
                    return (
                        <MenuItem
                            style={{color: o.danger ? red["700"] : undefined}}
                            onClick={() => {
                                value.onClick({
                                    onClose: () => setAnchor(null),
                                    refreshTable: (name) => ctx.refresh(name),
                                    update: (input) => edit.update(input),
                                    display: (input: ModalRender) => setDisplay({
                                        render: input,
                                    }),
                                });
                            }}>{o.name}</MenuItem>
                    )
                })}
            </Popover>}
            {display && display.render(() => setDisplay(null))}
        </div>
    )
}
import {AdjustCol, NumberCol, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import {Opening} from "../../../../api/Openings";
import {CheckboxSelectorCol} from "./Checkbox";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {useUser} from "../../../../misc/Permission";
import {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {projectTablePrefName} from "../TablePrefName";

export function FrameTable(props: {
    project: number
}) {
    const {project} = props;

    const u = useUser();
    const {locked} = useContext(ManagerLockContext)
    if(!u) return null;

    return (
        <Table<Opening>
            key="frame"
            locked={locked}
            name={projectTablePrefName("project.release.frames", project)}
            globalPrefsName={projectTablePrefName("project.release.frames", 0)}
            columns={[
                CheckboxSelectorCol(),
                ViewOnly(StringCol("Floor", "floor", 80)),
                AdjustCol(ViewOnly(StringCol("Opening", "name", 80)), {
                    fixed: true
                }),
                ViewOnly(NumberCol("Qty", "qty", 50)),
                ViewOnly(StringCol("Type", "openingType", 150)),
                ViewOnly(StringCol("Nom. Width", "nominalWidth")),
                ViewOnly(StringCol("Nom. Height", "nominalHeight")),
                ViewOnly(StringCol("Elevation", "doorElevation", 150)),
                ViewOnly(StringCol("Handing", "handing", 150)),
                ViewOnly(StringCol("Label", "label", 150)),
                ViewOnly(StringCol("Series", "frameSeries", 100)),
                ViewOnly(StringCol("Material", "frameMaterial", 70)),
                ViewOnly(StringCol("Gauge", "frameGauge", 50)),
                ViewOnly(StringCol("Screen Elev", "screenElevation", 100)),
                ViewOnly(StringCol("Profile", "frameProfile", 150)),
                ViewOnly(StringCol("Jamb", "jambDepth", 80)),
                ViewOnly(StringCol("Construction", "frameConstruction", 100)),
                ViewOnly(StringCol("HW. Preps", "frameHardwarePreps", 150)),
            ]}
            fetch={async (ctx) => getAll(ctx, offset => api.openings.list({
                project: project,
                type: "for-release",
                category: "frame",
                offset,
            }))
            }
            fetchDeps={[project]}
        />
    )
}
import {ProjectDir} from "../../../../api/ProjectFiles";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

export function CreateDir(props: {
    project: number;
    value: ProjectDir;
    onCancel(): void;
    onDone(): void;
}) {
    const onDone = useSyncedRef(props.onDone)
    const action = useAsyncAction(async (input: Partial<ProjectDir>) => {
        await api.projectFiles.upsertDir({
            id: 0,
            archived: false,
            project: props.project,
            name: input.name || "",
            kind: props.value.kind,
            parent: input.parent,
        })
        onDone.current();
    }, []);
    const [dirName, setDirName] = useState("");

    return (
        <Dialog open onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault()
                action.callback({
                    name: dirName,
                    parent: props.value.id,
                })
            }}>
            <DialogTitle>Create Directory</DialogTitle>
            <DialogContent>
                <TextField style={{width: 300}} value={dirName} onChange={e => setDirName(e.target.value)}  />
            </DialogContent>
            <DialogActions>
                {action.LoadingElement}
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" type="submit">Create</Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
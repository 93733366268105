import {Checkbox} from "@mui/material";
import {css} from "@emotion/css";
import {deepOrange, grey} from "@mui/material/colors";
import {RouteStopGroupItem} from "../../../api/Logistics";
import {isNumber} from "../routes/QtyWithPreview";
import {sum} from "nate-react-api-helpers";

export function ReceiveItem(props: {
    value: RouteStopGroupItem
    isPickup?: boolean;
    onLongPress?(): void;
    onEdit?(item: RouteStopGroupItem): void;
}) {
    const v = props.value;
    const isBackOrder = v.qtyReceived !== null && v.qtyReceived < v.qty && v.qtyDamaged === 0;
    const isDamaged = isNumber(v.qtyReceived) && !isBackOrder && isNumber(v.qtyDamaged) && v.qtyDamaged > 0;
    const isOddQty = !isBackOrder && !isDamaged && v.qtyReceived !== null && v.qtyReceived !== v.qty && v.qtyDamaged === 0;

    let qtyReceived = v.qtyReceived;
    let qtyDamaged = v.qtyDamaged;
    let isDamagedBox = false;

    if(props.value.boxDamageDetail) {
        isDamagedBox = true;
        qtyDamaged = sum(props.value.boxDamageDetail.map(b => b.qtyDamaged || 0))
        qtyReceived = sum(props.value.boxDamageDetail.map(b => b.qtyReceived || 0))
    }

    return (
        <tr className={rowStyle + " " + (!props.onEdit ? disabledStyle : "")}
            onContextMenu={e => {
                e.preventDefault();
                if(props.onLongPress) props.onLongPress();
            }}
            onClick={() => {
                const onEdit = props.onEdit;
                if(!onEdit) return;

                if(props.value.qtyReceived === null || props.value.qtyReceived === 0) {
                    onEdit(Object.assign({}, props.value, {
                        qtyReceived: props.value.qty,
                        qtyDamaged: 0,
                    }))
                } else {
                    if(props.isPickup) {
                        onEdit(Object.assign({}, props.value, {
                            qtyReceived: 0,
                            qtyDamaged: 0,
                        }));
                    } else {
                        onEdit(Object.assign({}, props.value, {
                            qtyReceived: 0,
                            qtyDamaged: props.value.qty,
                        }))
                    }
                }
            }}>
            <td>
                <div>{v.name}</div>
                {isBackOrder && isNumber(v.qtyReceived) && <div className={itemDetails}>Received {v.qtyReceived} ({v.qty - v.qtyReceived} on back order)</div>}
                {isDamaged && <div className={itemDetails}>{qtyReceived} {isDamagedBox ? "items " : null}correct, {qtyDamaged} damaged </div>}
                {isOddQty && <div className={itemDetails}>{v.qtyReceived} items </div>}
            </td>
            <td style={{width: 100}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{maxWidth: 60, textAlign: "left", fontSize: "1rem", color: grey["800"], overflow: "hidden", textOverflow: "ellipsis"}}>
                        {v.uom}{isDamagedBox ? "*": null}
                    </div>
                    <div style={{width: 40, textAlign: "right"}}>
                        {v.qty}
                    </div>
                </div>
            </td>
            <td style={{width: 54}}>
                <div style={{width: 54}}>
                {!!props.onEdit && <>
                        {(isDamaged || isBackOrder || isOddQty) ?
                            <Checkbox color="error" indeterminate={true} /> :
                            <Checkbox checked={v.qty === v.qtyReceived} />
                        }
                    </>}
                </div>
            </td>
        </tr>
    )
}

const itemDetails = css({
    fontSize: "0.9rem",
    color: deepOrange["700"],
})

const rowStyle = css({
    fontSize: "1.3rem",

    "&:nth-child(even)": {
        backgroundColor: grey["100"],
    },

    "& > td": {
        paddingTop: 4,
        paddingBottom: 4,
    },

    "& > td:nth-child(1)": {
        paddingLeft: 4,
        paddingRight: 4,
    }
})

const disabledStyle = css({
    backgroundColor: grey["100"],
})
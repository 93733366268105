import React, {PropsWithChildren} from "react";
import {pageClass} from "./Page";

export function PDF(props: PropsWithChildren<{}>) {
    return (
        <div>
            <style>{`
                @page wide {
                    size: 11in 8.5in;
                    margin: 10mm;
                }
                
                @page narrow {
                    size: 8.5in 11in;
                    margin: 10mm;
                }
                
                @page square {
                    size: 11in 11in;
                    margin: 10mm;
                }
            `}</style>

            <div className={pageClass} style={{page: "square"} as any}>blank page to remove</div>
            {props.children}
        </div>
    )
}
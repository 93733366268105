import {useLocation, useNavigate, useParams} from "react-router-dom";
import {QuoteOpenings} from "./openings/QuoteOpenings";
import React, {useEffect} from "react";
import {QuotePricing} from "./pricing/QuotePricing";
import {QuoteHardware} from "./hardware/QuoteHardware";
import {Div10} from "./div10/Div10";
import {api} from "../../../api/API";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {QuoteMisc} from "./misc/QuoteMisc";

export function Quote() {
    const params = useParams<{workspace: string; project: string; alternative: string}>();
    const key = params.project + "-" + params.alternative;
    const nav = useNavigate();
    const loc = useLocation();
    const locRef = useSyncedRef(loc);

    // redirect to correct alternative if necessary
    useEffect(() => {
        if(params.alternative !== "-1") return;

        const projectId = parseInt(params.project || "");
        if(isNaN(projectId)) return;

        let cancel = false;

        (async () => {
            const project = await api.projects.get(projectId);
            if(!project.quoteAlternative) return;

            if(cancel) return;
            nav(locRef.current.pathname.replace("/-1/", "/" + project.quoteAlternative.toString() + "/"))
        })()

        return () => {
            cancel = true;
        }
    }, [params.alternative, params.project, locRef, nav])

    if(params.alternative === "-1") return null;

    switch(params.workspace) {
        case "openings":
            return <QuoteOpenings key={key} />
        case "pricing":
            return <QuotePricing key={key} />
        case "hardware":
            return <QuoteHardware key={key} />
        case "div-10":
            return <Div10 key={key} />
        case "misc":
            return <QuoteMisc key={key} />
        default:
            return <QuoteOpenings key={key} />
    }
}
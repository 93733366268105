import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    TextField
} from "@mui/material";
import React, {useContext, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../api/API";
import {useSyncedRef} from "../../misc/SyncedRef";
import {useRoleAndOverride} from "./RoleAndOverride";
import {EditContext} from "../../misc/scroller/EditProvider";

const blankArray: any[] = [];

export function CreateUser(props: {
    onDone(): void;
}) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const onDoneRef = useSyncedRef(props.onDone);
    const action = useAsyncAction(async (input) => {
        await api.users.upsert(input);
        editCtx.reload();
        onDoneRef.current();
    }, []);

    const editCtx = useContext(EditContext);

    const {Overrides, role, permOverride, Role} = useRoleAndOverride({
        role: "user",
        permissionOverrides: blankArray,
    });
    
    return (
        <Dialog open>
            <form onSubmit={e => {
                e.preventDefault()
               action.callback({
                    name,
                    email,
                   phone,
                    password,
                    role,
                   permissionOverrides: permOverride,
                   updatePermissions: true,
               })
            }}>
                <DialogTitle>Create User</DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    
                    <Grid container spacing={1} direction="column">
                        <Grid item><TextField label="Name" fullWidth size="small" value={name} onChange={e => setName(e.target.value)} /></Grid>
                        <Grid item><TextField label="Phone" fullWidth size="small" value={phone} onChange={e => setPhone(e.target.value)} /></Grid>
                        <Grid item>
                            <div style={{height: 16}} />
                        </Grid>
                        <Grid item><TextField label="Email" fullWidth size="small" value={email} onChange={e => setEmail(e.target.value)} /></Grid>
                        <Grid item><TextField label="Initial Password" fullWidth size="small" value={password} onChange={e => setPassword(e.target.value)} /></Grid>
                        <Grid item>
                            <div style={{height: 32}} />
                            {Role}
                        </Grid>
                    </Grid>

                    <div style={{height: 16}} />
                    <div style={{fontSize: "0.8rem", paddingLeft: 16}}>
                        Permission Overrides
                    </div>
                    {Overrides}
                </DialogContent>
                <DialogActions>
                    {action.LoadingElement}
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import { SubAPI } from "./SubAPI";

export class TableConfig extends SubAPI {

    get(input: {
        name: string;
    }) {
        return this.fetcher.get<TableProjectConfig|null>("/api/table-config/get", input)
    }

    set(input: {
        name: string
        value: TableProjectConfig;
    }) {
        return this.fetcher.post("/api/table-config/set", input)
    }
}

type TableProjectConfigOld = {
    widths: number[];
    hidden: number[];
    columnOrder?: number[]; // list of new index positions
    maxFixedIndex: number;
    sortBy?: {
        column: string;
        reverse: boolean;
    } | null;
}

export type ColumnConfig = {
    width: number;
    hidden: boolean;
    index: number;
}

export type ColumnConfigByName = {
    [name: string]: ColumnConfig
}

export type TableProjectConfig = {
    version: 2,
    byName: ColumnConfigByName

    maxFixedIndex: number;

    sortBy?: {
        column: string;
        reverse: boolean;
    } | null;
}

export type TableProjectConfigResult = TableProjectConfigOld | TableProjectConfig
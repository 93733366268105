import { SubAPI } from "./SubAPI";

export class Manufacturing extends SubAPI {

    convertProducts(input: {
        output: {
            product: number
            qty: number;
        }
        input: {
            product: number
            qty: number;
        }[]
    }) {
        return this.fetcher.post<{}>("/api/manufacturing/convert-products", input)
    }
}
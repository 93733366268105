import {Checkbox, Divider, Grid, List, MenuItem} from "@mui/material";
import {red} from "@mui/material/colors";
import {PopoutMenu} from "./library/PopoutMenu";
import React, {useContext} from "react";
import {ConfirmContext} from "../../../../misc/Confirm";
import {EditHardwareItem} from "./HwEditingContextProvider";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";

export function HardwareItemMenu(props: {
    value: EditHardwareItem;
    disabled?: boolean;
    onChange(value: EditHardwareItem): void;
}) {
    const confirm = useContext(ConfirmContext);
    const locked = useContext(ChangePreviewContext).enabled;

    return (
        <PopoutMenu size="small" disabled={props.disabled}>
            {onClose => <>
                <List>
                    <MenuItem disabled={locked} style={{padding: 4, paddingRight: 24}}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Checkbox disabled={locked} checked={props.value.customerSupplied} onChange={async (e, checked) => {
                                    onClose();

                                    if(checked) {
                                        const ok = await confirm.confirm("Mark as provided by others? This will remove the product from the pricing and delivery sections.");
                                        if (!ok) return;
                                    }

                                    props.onChange(Object.assign({}, props.value, {customerSupplied: checked}))
                                }} />
                            </Grid>
                            <Grid item>
                                Provided by Others
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <Divider />
                    <MenuItem disabled={locked} style={{color: red["800"]}} onClick={async () => {
                        onClose();

                        const ok = await confirm.confirm("Are you sure you want to remove this group?")
                        if(!ok) return;

                        props.onChange(Object.assign({}, props.value, {
                            activeQty: 0,
                            inactiveQty: 0,
                            commonQty: 0,
                        }));

                    }}>Remove</MenuItem>
                </List>
            </>}
        </PopoutMenu>
    )
}